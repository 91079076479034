import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import { Backdrop } from '@material-ui/core';
import { CloseOutlineIcon  } from '../../../../../assets/icons/iconRepository';
import IconPlacer from '../../../../../components/IconPlacer';
import Modal from "../../../../../components/globals/Modal";
import Pipers from '../../../../../components/Pipers/Pipers';
import { Button } from '../../../../../components/buttons';
import { SECONDARY } from '../../../../../components/buttons/constants';
import UpgradeConfirmation from './UpgradeConfirmation';
import { upgradeToBusinessBenefits } from '../constants/constants';
import roles from '../../../../../utils/constants/roles';
import { colors } from '../../../../../styled/theme';
import { heading, paragraph } from '../../../../../styled/mixins';
import mediaQueries from '../../../../../utils/mediaQueries';

const UpgradeToBusinessModal = ({ openModal, setOpenModal, onUpgrade, handleTransition }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfullyUpgraded, setWasSuccessfullyUpgraded] = useState(false);

    const handleUpgrade = () => {
      onUpgrade(setIsLoading, setWasSuccessfullyUpgraded)
    }
    
    const closeModal = () => {
      setOpenModal(false);
    };

    return (
      <>
            <UpgradeModal
              open={openModal}
              onClose={closeModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
            >
            <>
                <IconPlacer
                    onClick={closeModal}
                    path={CloseOutlineIcon}
                    size={25}
                    style={{ marginLeft: 'auto', marginRight: 0, cursor: 'pointer' }}
                />
                <ImagePiperHead />
                <Heading textAlign="center">
                    <FormattedMessage
                        id="upgradeToBusinessModal.title"
                        defaultMessage="Business account"
                    />
                </Heading>
                <ListContainer>
                <Paragraph textAlign="center" fontWeight="600">
                    <FormattedMessage
                        id="upgradeToBusinessModal.subtitle"
                        defaultMessage="Advantages:"
                    />
                </Paragraph>
                <List>
                    {upgradeToBusinessBenefits.map((benefit, index) => (
                        <Item key={index}>
                            <Text>{benefit.text}</Text>
                        </Item>
                    ))}
                </List>
                </ListContainer>
                <Paragraph footer={true} textAlign="center" fontWeight="600" color="#000000">
                    <FormattedMessage
                        id="upgradeToBusinessModal.footer.1"
                        defaultMessage="The first 30 days of your subscription to your Social Piper Business account will be free of charge."
                    />
                </Paragraph>
                <Paragraph footer={true} textAlign="center" fontWeight="400" color="#000000">
                    <FormattedMessage
                        id="upgradeToBusinessModal.footer.2"
                        defaultMessage="Upgrading your account to a business account will invalidate any coupons you currently have applied."
                    />
                </Paragraph>
                <ButtonModalWrapper>
                    <ButtonModal onClick={closeModal}  type={SECONDARY}>
                        <FormattedMessage
                            id="upgradeToBusinessModal.buttons.cancel"
                            defaultMessage="Cancel"
                        />
                    </ButtonModal>
                    <ButtonModal isLoading={isLoading} onClick={handleUpgrade}>
                        <FormattedMessage
                            id="upgradeToBusinessModal.buttons.upgrade"
                            defaultMessage="Upgrade to a business account"
                        />
                    </ButtonModal>
                </ButtonModalWrapper>
            </>  
            </UpgradeModal>
            <UpgradeConfirmation 
             open={wasSuccessfullyUpgraded}
             setOpen={setWasSuccessfullyUpgraded}
             handleTransition={handleTransition}
             role={roles.BUSINESS_MAIN}
            />
            </>
    );
}

UpgradeToBusinessModal.propTypes = {
  onUpgrade: PropTypes.func,
  openModal: PropTypes.bool, 
  setOpenModal: PropTypes.func,
  handleTransition: PropTypes.func
};

export default UpgradeToBusinessModal;


const Heading = styled.h3`
  ${heading}
  font-size: 22px;
  margin: 20px 0 12px;
`;

const Paragraph = styled.p`
  ${paragraph}
  font-size: ${(props) => props.footer ? '14px' : '18px'};
  color: ${(props) => props.color ? props.color : colors.Brand1CTA};
`;

const ImagePiperHead = styled(Pipers.Piper1)`
    width: 95px;
    height: 95px;
    align-self: center;
`;

const UpgradeModal = styled(Modal)`
    [class*='MuiPaper-root'] {
      height: auto;
      min-width: 978px;
      width: 1208px;
    }
  `

const ButtonModal = styled(Button)`
  && {
    height: 40px;
    position: unset;
    top: 0px;
    right: 0px;
  }
`;

const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.screenBackground};
  padding: 10px, 0px, 10px, 0px;
  margin-bottom: 10px;
`

const List = styled.ul`
    width: 100%;
    color: #000000;
    font-size: 9px;
`;

const Item = styled.li`
display: flex;
align-items: left;
font-family: Open Sans;
font-size: 12px;
font-weight: 400;
text-align: justify;
margin-left: 10px;
line-height: 25px;
&::before {
  content: '';
  background: #000000;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  margin-right: 5px;
  margin-top: 12px;
}
`;

const Text = styled.span`
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  color: #000000;
  @media ${mediaQueries.mdUp} {
    font-size: 12px;
  }
  @media ${mediaQueries.xlPlusUp} {
    font-size: 14px;
  }
`;

const ButtonModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 35px;
  margin-top: 25px;
`;