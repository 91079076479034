import React, { useState, useContext } from 'react';
import { injectStripe } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog as MuiDialog } from '@material-ui/core';
import styled from 'styled-components';
import { flexBox, heading2, paragraph } from '../../styled/mixins';
import GlobalTheme from '../../styled/GlobalTheme';
import PiperAdvice from '../globals/PiperAdvice';
import CardDetails from './CardDetails';
import { Button } from '../buttons';
import { SECONDARY } from '../buttons/constants';
import { BillingProvider } from '../../services/entities';
import { AlertsContext } from '../../contexts/AlertsContext';
import { colors } from '../../styled/theme';
import useCallGA from '../../hooks/useCallGA';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { LayoutContext } from '../../contexts/LayoutContext';

export const StyledAddPaymentModal = styled(MuiDialog)`
  &&{
    .MuiPaper-root{
      min-width: 500px;
      padding: 16px 40px;
      ${GlobalTheme.breakpoints.down('md')}{
        min-width: 0;
        width: calc(100% - 32px);
        max-width: 500px;
      }
      ${GlobalTheme.breakpoints.down('sm')}{
        width: 100%;
        background: ${colors.screenBackground};
      }
    }
  }
`;

const AddPaymentContainer = styled.div`
  ${flexBox}
  flex-wrap: wrap;
`;

const Title = styled.h2`
  ${heading2}
  flex: 0 0 100%;
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  flex: 0 0 100%;
  display: flex;
  align-items: center;
`;

const StyledCardDetails = styled(CardDetails)`
  flex: 0 0 100%;
`;

const AddPaymentActions = styled.div`
  margin-top: 20px;
  flex: 0 0 100%;
  ${flexBox}
  justify-content: flex-end;
  align-items: center;
  ${GlobalTheme.breakpoints.down('sm')}{
    padding-bottom: 42px;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
`;

const AddPaymentButton = styled(Button)`
  && {
    margin-left: 24px;
    ${GlobalTheme.breakpoints.down('sm')}{
      margin: 0 0 24px;
    }
  }
`;

const RedNote = styled.div`
  ${paragraph}
  background-color: ${(props) => props.theme.colors.orange};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  padding: 1em .5em;
  margin-top: 16px;
  font-size: 14px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  };
`;

const AddPaymentModalContent = ({
  open,
  handleClose,
  setPaymentMethods,
  stripe,
  dataLayer,
}) => {
  const intl = useIntl();
  const { showAlert, showBackendError } = useContext(AlertsContext);
  const callGA = useCallGA();
  const [contactInfo, setContactInfo] = useState({
    cardholderName: '',
    country: '',
    billingAddressLineOne: '',
    billingAddressLineTwo: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
  });
  const [loading, setLoading] = useState(false);
  const { dataLayerPush } = useContext(AnalyticsContext);
  const { user } = useContext(LayoutContext);

  const setCardHolderName = (event) => {
    setContactInfo({
      ...contactInfo,
      cardholderName: event.target.value,
    });
  };

  const handleAddPaymentMethod = async () => {
    setLoading(true);
    contactInfo.cardholderName = `${user.firstName} ${user.lastName}`;
    contactInfo.email = user.email;
    const response = await BillingProvider.addPaymentMethod(stripe, contactInfo);
    if (response.success) {
      callGA({ action: 'Add payment method', label: '(button)' });
      const validatefounds = await BillingProvider.validatefounds();
      if (validatefounds.success) {
        setPaymentMethods(response.data);
        showAlert(intl.formatMessage({
          id: 'alert.success.save.female',
          defaultMessage: '{item} saved successfully',
        }, {
          item: intl.formatMessage({
            id: 'k.card',
            defaultMessage: 'Card',
          }),
        }));
      } else {
        showBackendError(validatefounds.message);
      }
      handleClose(true);
    } else {
      showBackendError(response.message);
    }
    setLoading(false);
  };
  const handleEnter = () => {
    dataLayerPush({
      ...dataLayer,
      event: 'pv',
    });
  };
  return (
    <StyledAddPaymentModal
      fullScreen={window.innerWidth < GlobalTheme.breakpoints.values.md}
      open={open}
      onEntered={handleEnter}
    >
      <AddPaymentContainer>
        <Title>
          <FormattedMessage
            id="addPayment.title"
            defaultMessage="Add Payment Method"
          />
        </Title>
        <StyledPiperAdvice>
          <FormattedMessage
            id="addPayment.advice"
            defaultMessage="Your information will be safe, so you don’t have to worry"
          />
        </StyledPiperAdvice>
        <StyledCardDetails
          cardholderName={contactInfo.cardholderName}
          setCardHolderName={setCardHolderName}
          intl={intl}
        />
        <RedNote>
          <FormattedMessage
            id="onboarding.billing.charging.validateFoundsNote"
            defaultMessage="To verify your method payment, you’ll be charged $1 USD, which will be reimbursed once the payment proceeds."
          />
        </RedNote>
        <AddPaymentActions>
          <Button
            onClick={() => handleClose()}
            type={SECONDARY}
            disabled={loading}
          >
            <FormattedMessage
              id="k.cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <AddPaymentButton
            onClick={(e) => {
              callGA({ action: 'Add a payment method', label: '(on billing & plan)(submit)(button)' });
              handleAddPaymentMethod(e);
            }}
            isLoading={loading}
          >
            <FormattedMessage
              id="addPayment.button"
              defaultMessage="Add Payment"
            />
          </AddPaymentButton>
        </AddPaymentActions>
      </AddPaymentContainer>
    </StyledAddPaymentModal>
  );
};

AddPaymentModalContent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setPaymentMethods: PropTypes.func.isRequired,
  stripe: PropTypes.instanceOf(Object).isRequired,
  dataLayer: PropTypes.instanceOf(Object),
};

AddPaymentModalContent.defaultProps = {
  dataLayer: {},
};

export default injectStripe(AddPaymentModalContent);
