import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  materialShadows,
  borderTop,
  heading3,
  flexBox,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import PiperAdvice from '../../../../../components/globals/PiperAdvice';
import { Button } from '../../../../../components/buttons';
import DisableAccountModal from './DisableAccountModal';
import { UserProvider } from '../../../../../services/entities';
import { AlertsContext } from '../../../../../contexts/AlertsContext';
import useCallGA from '../../../../../hooks/useCallGA';
import AuthService from '../../../../../services/auth/AuthService';

const DisableAccountCard = styled.div`
  ${materialShadows};
  ${borderTop()};
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  ${flexBox}
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.down('md')}{
    padding: 24px 16px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    justify-content: center;
  }
`;

const Title = styled.h3`
  flex: 0 0 100%;
  ${heading3}
  text-align: left;
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  margin: 16px 0 0;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: left;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex: 0 0 100%;
    margin-bottom: 16px;
  }
`;

const ContainerButtonDisabled = styled.div`
  position: initial;
  ${(props) => (props.isPlanApple) && `
    ${GlobalTheme.breakpoints.down('sm')}{
      position: relative;
      width: 100%;
      text-align: center;
      height: 0px;
    }
  `}
`;

const ButtonDisabled = styled(Button)`
  position: initial;

  ${(props) => (props.isPlanApple) && `
    ${GlobalTheme.breakpoints.down('sm')}{
      position: absolute;
      bottom: -80px;
      margin-left: -75px;
    }
  `}
`;

const ContainerTextCancelApp = styled.div`
  color: #909090;
  width: 100%;
  font-size: 14px;
  margin-left: 5%;

  ${GlobalTheme.breakpoints.down('sm')}{
    margin-bottom: 15%;
    margin-top: 5%;
  }
`;

const TextCancelApp = styled.div`
  display:inline;
  ${GlobalTheme.breakpoints.down('sm')}{
    display:grid;
  }
`;

const LinkTextCancelApp = styled.a`
  color: #027686;
  text-decoration: underline;
`;

const DisableAccount = ({
  className,
  isPlanApple,
  isPlanAndroid,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(AlertsContext);
  const callGA = useCallGA();
  const intl = useIntl();
  const urlLinkApple = intl.locale === 'en' ? 'https://support.apple.com/en-us/HT204939?out_app' : 'https://support.apple.com/es-mx/HT202039?out_app';
  const urlLinkAndroid = intl.locale === 'es' ? 'https://support.google.com/googleplay/answer/7018481?hl=es-419&co=GENIE.Platform%3DAndroid&out_app' : 'https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid&out_app';

  const handleConfirm = async () => {
    setLoading(true);

    const { success, message } = await UserProvider.disableAccount();
    if (success) {
      setShowConfirmationModal(false);
      AuthService.logout();
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const manageOnClick = () => {
    callGA({
      action: 'open disable account modal',
      label: '(button)',
    });
    setShowConfirmationModal(true);
  };

  return (
    <>
      <DisableAccountCard
        className={className}
      >
        <Title>
          <FormattedMessage
            id="settings.billing.disableAccount.title"
            defaultMessage="Disable your account"
          />
        </Title>
        <StyledPiperAdvice>
          <FormattedMessage
            id="settings.billing.disableAccount.question"
            defaultMessage="Do you want to permanently delete your account? We're sure we will miss you."
          />
        </StyledPiperAdvice>

        <ContainerButtonDisabled isPlanApple={isPlanApple}>
          <ButtonDisabled
            disableElevation
            danger
            onClick={manageOnClick}
            isPlanApple={isPlanApple}
          >
            <FormattedMessage
              id="k.disableAccount"
              defaultMessage="Disable Account"
            />
          </ButtonDisabled>
        </ContainerButtonDisabled>

        {((isPlanApple || isPlanAndroid) ? (
          <>
            <ContainerTextCancelApp>
              <TextCancelApp>
                <FormattedMessage
                  id="settings.billing.planInformation.cancelSubscription.app.text"
                  defaultMessage="To cancel your subscription, you must do it from the App Store. "
                />
              </TextCancelApp>
              <LinkTextCancelApp href={(isPlanApple) ? urlLinkApple : urlLinkAndroid}>
                <FormattedMessage
                  id="settings.billing.planInformation.cancelSubscription.app.link"
                  defaultMessage="Follow these steps to do it."
                />
              </LinkTextCancelApp>
            </ContainerTextCancelApp>
          </>
        ) : <></>)}
      </DisableAccountCard>
      <DisableAccountModal
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        handleConfirm={handleConfirm}
        loading={loading}
        dataLayer={{
          pagePath: '/settings/billing/disable-your-account',
          pathTitle: 'Settings - Billing-Plan - Disable-your-account',
        }}
      />
    </>
  );
};

DisableAccount.propTypes = {
  className: PropTypes.string,
  isPlanApple: PropTypes.bool,
  isPlanAndroid: PropTypes.bool,
};

DisableAccount.defaultProps = {
  className: '',
  isPlanApple: false,
  isPlanAndroid: false,
};

export default DisableAccount;
