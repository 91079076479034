import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dialog } from '@material-ui/core';
import { SocialProfilesProvider } from '../../../services/entities';
import { piper } from '../../../assets/illustrations/illustrationRepository';
import GlobalTheme from '../../../styled/GlobalTheme';
import { Icon } from '../../globals/ColoredIcon';
import { CloseOutlineIcon } from '../../../assets/icons/iconRepository';
import { FACEBOOK, INSTAGRAM } from '../../../utils/constants/globals';
import { Button } from '../../buttons';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { SocialContext } from '../../../contexts/SocialContext';

const CustomDialog = styled(Dialog)`
&&{
    .MuiPaper-root{
      max-width: 660px;
      max-height: 90%;
      height: fit-content;
    }
  }
`;

const CloseIcon = styled(Icon)`
  margin-right: 15px;
  margin-top: 10px;
  cursor: pointer;
  && {
    color: white;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 1em;
    ${GlobalTheme.breakpoints.up('md')}{
      padding: 0 1em;
    }
    ${GlobalTheme.breakpoints.up('lg')}{
      padding: 0 1em;
    }
`;

const TitleCont = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    ${GlobalTheme.breakpoints.up('md')}{
      flex-direction: row;
      align-items:center;
    }
    ${GlobalTheme.breakpoints.up('lg')}{
      font-size: 32px;
    }
`;

const Title = styled.p`
font-weight: 600;
font-size: 20px;
text-align: center;
${GlobalTheme.breakpoints.up('md')}{
  font-size: 20px;
  width: 340px;
}
${GlobalTheme.breakpoints.up('lg')}{
  font-size: 32px;
  width: 100%;
}
`;

const Colored = styled.span`
  color: ${({ snType }) => (GlobalTheme.colors[snType])};
`;

const ImageBox = styled.div`
  height: 80px;
  margin-right: 10px;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;    
    height: 60px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Body = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.up('md')}{
    padding: 0 3em;
  }
`;

const AccountSelector = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 1em 0;
  border-bottom: 1px solid ${GlobalTheme.colors.grayE9};
  ${GlobalTheme.breakpoints.up('md')}{
    margin: 1em 0;
    border: 0;
    flex-flow: row nowrap;
  }
`;
const AccountHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${({ logoUrl }) => (logoUrl)});
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 1em;
  border-radius: 50%;
`;

const AccountName = styled.div`
  width: calc(100% - 80px);
  word-break: break-all;
  font-weight: 600;
  font-size: 24px;
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: px;
    word-break: normal;
  }
`;

const ButtonsHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${GlobalTheme.breakpoints.up('md')}{
    width: 50%;
    justify-content: flex-end;
  }
`;

const LinerProgresHolder = styled.div`
  width: 100%;
  heigth: 1em;
`;

const MultiAccountModal = () => {
  const intl = useIntl();
  const { showError } = useContext(AlertsContext);
  const {
    fetchSocialNetworksData,
    showMultiAccount,
    closePageSelector,
    selectorType,
  } = useContext(SocialContext);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(false);
  const fetchPages = async () => {
    setIsLoading(true);
    try {
      if (selectorType === FACEBOOK) {
        const fetchFBP = await SocialProfilesProvider.fetchFacebookPages2Setup();
        if (fetchFBP && fetchFBP.success) {
          const { data } = fetchFBP;
          setPages(data);
        }
      }
      if (selectorType === INSTAGRAM) {
        const fetchIBP = await SocialProfilesProvider.fetchIGPages2Setup();
        if (fetchIBP && fetchIBP.success) {
          const { data } = fetchIBP;
          setPages(data);
        }
      }
    } catch {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const activatePage = async (pageID) => {
    setIsLoading(true);
    try {
      if (selectorType === FACEBOOK) {
        const activate = await SocialProfilesProvider.activateFacebookPage(pageID);
        if (activate && activate.success) {
          const fetchData = await SocialProfilesProvider.initFacebookData();
          if (fetchData) {
            await fetchSocialNetworksData();
          } else {
            throw new Error('Problem with FB Fetch Data');
          }
        } else {
          throw new Error('Problem with FB Activation');
        }
      }
      if (selectorType === INSTAGRAM) {
        const activate = await SocialProfilesProvider.activateIGPage(pageID);
        if (activate && activate.success) {
          const fetchData = await SocialProfilesProvider.initInstagramData();
          if (fetchData) {
            await fetchSocialNetworksData();
          } else {
            throw new Error('Problem with FB Fetch Data');
          }
        } else {
          throw new Error('Problem with FB Activation');
        }
      }
      setIsLoading(false);
      closePageSelector();
    } catch (err) {
      showError(intl.formatMessage({
        id: 'alert.error.social.connection',
        defaultMessage: 'There was an error connecting your social media account',
      }));
      setIsLoading(false);
      closePageSelector();
    }
  };

  useEffect(() => {
    if (showMultiAccount) {
      fetchPages();
    }
  }, [showMultiAccount, selectorType]);

  return (
    <CustomDialog
      open={showMultiAccount}
    >
      <Header>
        <CloseIcon
          src={CloseOutlineIcon}
          color={GlobalTheme.colors.black}
          size="20px"
          onClick={() => closePageSelector()}
          isLoading={isLoading}
        />
      </Header>
      <Container>
        <TitleCont>
          <ImageBox>
            <Image
              src={piper.Piper1}
            />
          </ImageBox>
          <Title>
            <FormattedMessage
              id="socialProfiles.multiAccount.modal.title"
              defaultMessage="Selecciona la <colored>{page}</colored> que quieres usar"
              values={{
                page: (intl.formatMessage({
                  id: `socialProfiles.multiAccount.modal.${selectorType}`,
                })),
                colored: (chunks) => (<Colored snType={selectorType}>{chunks}</Colored>),
              }}
            />
          </Title>
        </TitleCont>
        <Body>
          {(pages) ? (
            pages.map((page) => (
              <>
                <AccountSelector>
                  <AccountHolder>
                    <Avatar logoUrl={page.picture} />
                    <AccountName>
                      {page.name}
                    </AccountName>
                  </AccountHolder>
                  <ButtonsHolder>
                    <Button
                      isLoading={isLoading}
                      onClick={() => activatePage(page.id)}
                    >
                      {intl.formatMessage({
                        id: 'k.connect',
                        defaultMessage: 'Conectar',
                      })}
                    </Button>
                  </ButtonsHolder>
                </AccountSelector>
              </>
            ))
          ) : (
            <LinerProgresHolder>
              <LinearProgress color="primary" />
            </LinerProgresHolder>
          )}
        </Body>
      </Container>
    </CustomDialog>
  );
};

export default MultiAccountModal;
