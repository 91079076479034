import templates from './views/templates/es';
import pricing from './views/pricing/es';
import community from './views/community/es';
import createAccount from './views/create-account/es';
import onboarding from './views/onboarding/es';
import verifyEmail from './views/VerifyEmail/es';
import boost from './components/boost/es';
import addPaymentModal from './components/addPaymentModal/es';
import socialProfiles from './components/socialProfiles/es';
import trialEnds from './components/trialEnds/es';
import subscriptionModal from './components/subscriptionModal/es';
import cancelSubscriptionModal from './components/cancelSubscriptionModal/es';
import login from './views/login/es';
import dashboard from './views/dashboard/es';
import global from './global/es';
import boostManager from './views/boostManager/es';
import postFlow from './views/postFlow/es';
import socialLogin from './views/socialLogin/es';
import landing from './views/landing/es';
import scheduler from './views/scheduler/es';
import settings from './views/settings/es';
import successVerifyEmail from './views/successVerifyEmail/es';
import backendErrors from './backend/errors/es';
import promos from './backend/promos/es';
import tutorialModal from './components/tutorialModal/es';
import planOption from './components/planOption/es';
import proOfferModal from './components/proOfferModal/es';
import comparePlans from './components/compareDetails/es';
import newPricing from './views/newPricing/es';
import welcomeModal from './components/welcomeModal/es';
import landingInstagram from './views/landingInstagram/es';
import inAppTouchModal from './components/inAppTouchModal/es';
import upgradeToBusinessModal from './components/upgradeToBusinessModal/es';
import downgradeToProModal from './components/downgradeToProModal/es';
import tools from './views/tools/es';
import metrics from './views/metrics/es';
import onboardingBiz from './views/onboarding-biz/es';

const es = {
  // Views
  ...boostManager,
  ...templates,
  ...pricing,
  ...community,
  ...onboarding,
  ...login,
  ...dashboard,
  ...postFlow,
  ...socialLogin,
  ...landing,
  ...scheduler,
  ...settings,
  ...verifyEmail,
  ...metrics,
  // Components
  ...boost,
  ...addPaymentModal,
  ...socialProfiles,
  ...trialEnds,
  ...subscriptionModal,
  ...cancelSubscriptionModal,
  ...tutorialModal,
  ...planOption,
  ...proOfferModal,
  ...comparePlans,
  ...inAppTouchModal,
  ...upgradeToBusinessModal,
  ...downgradeToProModal,
  // Globals
  ...global,
  ...createAccount,
  // Backend
  ...backendErrors,
  ...promos,
  ...successVerifyEmail,
  ...newPricing,
  ...welcomeModal,
  ...landingInstagram,
  ...tools,
  ...onboardingBiz
};

export default es;
