import { useContext, useEffect } from 'react';

import { useIntl } from 'react-intl';
import { OpenAIContext } from '../contexts/OpenAIProiver';
import { AudienceProvider } from '../services/entities';
import BusinessProvider from '../services/entities/BusinessProvider';
import {
  MissingConfig,
  appsConnectedParser,
  isLengthZero,
  parseGender,
} from '../utils/openAiHelpers';
import useCompany from './useCompany';

export default () => {
  const intl = useIntl();
  const originalLanguage = intl.locale;
  const {
    loading,
    companyId,
    socialNetworks,
    infoState: [businessInfo],
  } = useCompany();
  const { missingConfig, setMissingConfig, isFetching, setIsFetching, config, setConfig } =
    useContext(OpenAIContext);

  useEffect(() => {
    if (!loading) refetch();
  }, [loading, businessInfo, socialNetworks]);

  useEffect(() => {
    if (!isFetching) {
      if (config?.appsConnected?.length === 0) {
        setMissingConfig(MissingConfig.NoAppsConnected);
        return;
      }

      if (
        isLengthZero(config?.company?.name) ||
        isLengthZero(config?.company?.location) ||
        isLengthZero(config?.company?.categories)
      ) {
        setMissingConfig(MissingConfig.NoCompanyInfo);
        return;
      }

      setMissingConfig(MissingConfig.None);
    }
  }, [config, isFetching, companyId]);

  const companyHandler = () => {
    let initialState = {
      company: {
        logo: null,
        name: null,
        location: null,
        categories: [],
      },
    };

    if (businessInfo) {
      const categories = businessInfo.category ? businessInfo.category.map((val) => val.label) : [{ id: "", label: "", value: ""}];

      initialState = {
        ...initialState,
        company: {
          logo: businessInfo.logo,
          name: businessInfo.companyName,
          location: businessInfo.location?.label,
          categories,
        },
      };
    }

    return Promise.resolve({ ...initialState });
  };

  const networksHandler = async () => {
    let initialState = {
      language: 'es',
      appsConnected: [],
      facebookConfig: null,
      linkedinConfig: null,
    };

    const audienceHandler = async (appsConnected) => {
      let initialState = {
        facebookConfig: null,
        linkedinConfig: null,
      };

      if (appsConnected?.includes('Facebook')) {
        const facebookResponse = await AudienceProvider.fetch(intl, undefined, false);
        if (
          facebookResponse.success &&
          facebookResponse.data?.interests &&
          facebookResponse.data?.locations
        ) {
          initialState = {
            ...initialState,
            facebookConfig: {
              topics: facebookResponse.data?.interests.map((t) => t.name),
              clientCountries: facebookResponse.data?.locations.map((l) => l.label),
              gender: parseGender(facebookResponse.data?.gender),
              ageRange: facebookResponse.data?.ages,
            },
          };
        }
      }

      if (appsConnected?.includes('Linkedin')) {
        const linkedinResponse = await AudienceProvider.fetch(intl, undefined, true);

        if (
          linkedinResponse.success &&
          linkedinResponse.data?.linkedinInterests &&
          linkedinResponse.data?.linkedinLocations
        ) {
          initialState = {
            ...initialState,
            linkedinConfig: {
              topics: linkedinResponse.data?.linkedinInterests.map((t) => t.label),
              clientCountries: linkedinResponse.data?.linkedinLocations.map((l) => l.label),
              gender: parseGender(linkedinResponse.data?.gender),
              ageRange: linkedinResponse.data?.ages,
            },
          };
        }
      }

      return { ...initialState };
    };

    if (socialNetworks) {
      const rawConnAccounts = socialNetworks;
      const appsConnected = appsConnectedParser(rawConnAccounts);

      initialState = {
        ...initialState,
        appsConnected,
      };
    }

    return Promise.resolve({ ...initialState /* ...audienceState */ });
  };

  const refetch = (language = originalLanguage) => {
    setIsFetching(true);

    Promise.all([companyHandler(), networksHandler()])
      .then(([companyState, networkState]) => {
        setConfig({ ...companyState, ...networkState, language });
      })
      .catch(console.error)
      .finally(() => {
        setIsFetching(false);
      });
  };

  const autoLabelPrompt = (base = '') => {
    if (base) {
      return `${base}. Generate one post about my place called ${
        config.company.name
      }, and my tags are ${config.company.categories.join(', ')}`;
    }
    return `Generate one post about my place called ${
      config.company.name
    }, and my tags are ${config.company.categories.join(', ')}`;
  };

  const autoImagePrompt = (base = '') => {
    if (base) {
      return `${base}, ${config.company.categories.join(', ')}, high quality photograpy`;
    }
    return `${config.company.categories.join(' ')}, high quality photograpy`;
  };

  return {
    refetch,
    config,
    missingConfig,
    autoImagePrompt,
    autoLabelPrompt,
  };
};
