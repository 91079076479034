import { lazy } from 'react';
import Landing from '../views/landing';
import ROUTES from '../utils/constants/routes';
import BusinessOnBoarding from '../views/BusinessOnBoarding';

const pricingPromise = import('../views/Pricing/Pricing' /* webpackChunkName: "pricing" */);
const Pricing = lazy(() => pricingPromise);
const callbackPromise = import('../views/auth/Callback' /* webpackChunkName: "callback" */);
const Callback = lazy(() => callbackPromise);
const loginPromise = import('../views/Login' /* webpackChunkName: "login" */);
const Login = lazy(() => loginPromise);
const createAccountPromise = import('../views/CreateAccount' /* webpackChunkName: "create-account" */);
const CreateAccount = lazy(() => createAccountPromise);
const createAccountIOSPromise = import('../views/CreateAccountIOS' /* webpackChunkName: "create-account" */);
const CreateAccountIOS = lazy(() => createAccountIOSPromise);

const loginAppPromise = import('../views/LoginApp' /* webpackChunkName: "login" */);
const LoginApp = lazy(() => loginAppPromise);

const signUpPromise = import('../views/SignUp' /* webpackChunkName: "login" */);
const SignUp = lazy(() => signUpPromise);

const createAccountAppPromise = import('../views/CreateAccountApp' /* webpackChunkName: "create-account-app" */);
const CreateAccountApp = lazy(() => createAccountAppPromise);
const landingInstagramPromise = import('../views/LandingInstagram' /* webpackChunkName: "instagram" */);
const LandingInstagram = lazy(() => landingInstagramPromise);
const toolsPromise = import('../views/Tools' /* webpackChunkName: "tools" */);
const Tools = lazy(() => toolsPromise);

const routes = [
  {
    layout: '/',
    path: ROUTES.LANDING,
    title: 'Homepage',
    component: Landing,
    props: { },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.EN,
    title: 'Homepage',
    component: Landing,
    props: { },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.ES,
    title: 'Homepage',
    component: Landing,
    props: { },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.CALLBACK,
    title: 'Callback',
    component: Callback,
    props: { },
    exact: true,
    menu: true,
    color: 'inherit',
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.PRICING,
    title: 'Pricing',
    component: Pricing,
    props: { },
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
    color: 'inherit',
  },
  {
    layout: '/',
    path: ROUTES.JOIN_PRICING,
    title: 'Pricing',
    component: Pricing,
    props: { },
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
    color: 'inherit',
  },
  {
    layout: '/',
    path: ROUTES.LOGIN,
    title: 'Login',
    component: Login,
    props: { },
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.CREATE_ACCOUNT,
    title: 'Create Account',
    component: CreateAccount,
    props: { banner: true },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.CREATE_ACCOUNT_APP,
    title: 'Create Account',
    component: CreateAccountApp,
    props: { banner: true },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.CREATE_ACCOUNT_PRO,
    title: 'Create Account',
    component: CreateAccount,
    props: { banner: true },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: ROUTES.CREATE_ACCOUNT,
    path: '/no-banner',
    title: 'Create Account',
    component: CreateAccount,
    props: { banner: false },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/login-app',
    path: '',
    title: 'Login',
    component: LoginApp,
    props: { },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: ROUTES.CREATE_ACCOUNT_IOS,
    path: '',
    title: 'Create Account',
    component: CreateAccountIOS,
    props: { banner: false },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: ROUTES.SIGN_UP,
    path: '',
    title: 'Create Account',
    component: SignUp,
    props: { banner: false },
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.LANDINGINSTAGRAM,
    title: 'Instagram',
    component: LandingInstagram,
    props: {},
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.TOOLS,
    title: 'Tools',
    component: Tools,
    props: {},
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.__TEST__,
    title: '__test__',
    component: BusinessOnBoarding,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
];

export default routes;
