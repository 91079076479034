import React, { useContext } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const ToggleButtonSty = styled(ToggleButton)`
  & {
    width: 100%;
    display: flex;
    flex-direction: row nowrap;
    color: ${({ theme }) => theme.colors.black};
    ${({ column }) => column && `
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    `}
  }

  &&:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &&.Mui-selected {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
    color: ${({ theme }) => theme.colors.black};
  }
  &&.Mui-selected:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  &&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    ${({ column }) => column && `
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      margin-left: -1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    `}
  }
  @media  (min-device-width: 768px) and (orientation: landscape) {
    height: ${(props) => (props.isApp ? '35px' : 'initial')};
  }
`;

const ToggleButtonGroupSty = styled(ToggleButtonGroup)`
&& {
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-flow: ${({ column }) => ((!column) ? 'row nowrap' : 'column nowrap')};
}
`;

const SwitchSelector = ({
  values,
  value,
  onChange,
  exclusive,
  column,
  isApp,
}) => {
  const { dataLayerPush } = useContext(AnalyticsContext);
  const handleToggle = (_, newValue) => {
    onChange(newValue);
  };

  return (
    <ToggleButtonGroupSty
      value={value}
      exclusive={exclusive}
      onChange={handleToggle}
      column={column}
    >
      {values.filter(v => !v.disabled).map(({
        value, element, selected, GaAction,
      }) => (
        <ToggleButtonSty
          value={value}
          aria-label={value}
          selected={selected}
          onClick={() => {
            dataLayerPush({
              pagePath: '/postflow/schedule',
              pathTitle: 'Post flow',
              dataCategory: 'Post flow',
              dataAction: GaAction,
              event: 'action',
            });
          }}
          isApp={isApp}
          column={column}
        >
          {element}
        </ToggleButtonSty>
      ))}
    </ToggleButtonGroupSty>
  );
};

SwitchSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  values: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func,
  exclusive: PropTypes.bool,
  column: PropTypes.bool,
  isApp: PropTypes.bool,
};

SwitchSelector.defaultProps = {
  value: null,
  onChange: () => {},
  exclusive: false,
  column: false,
  isApp: false,
};

export default SwitchSelector;
