import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import NotificationModal from '../../../../../components/notifications/Modal';
import Pipers from '../../../../../components/Pipers/Pipers';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { Button } from '../../../../../components/buttons';
import { heading3, paragraph } from '../../../../../styled/mixins';
import { colors } from '../../../../../styled/theme';

const StyledModal = styled(NotificationModal)`
  &&{
    .MuiPaper-root{
      width: 80%;
      max-width: 1016px;
      padding-top: 80px;
      margin: 32px 0;
      ${GlobalTheme.breakpoints.down('lg')} {
        width: 90%;
      }
    }
  }
`;

const StyledPiper = styled(Pipers.CelebrateConfetti)`
  height: 180px;
  ${GlobalTheme.breakpoints.down('lg')} {
    height: 140px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    height: 120px;
  }
`;

const Title = styled.h3`
  ${heading3}
  text-align: center;
  margin-top: 24px;
`;

const Subtitle = styled.p`
  ${paragraph}
  font-size: 20px;
  color: ${colors.text02};
  text-align: center;
  margin: 8px 0 48px;
`;

const SuccessfulSubscriptionModal = ({
  open, setOpen, handleAccept,
}) => (
  <StyledModal
    open={open}
    setOpen={setOpen}
  >
    <StyledPiper />
    <Title>
      <FormattedMessage
        id="successfulSubscriptionModal.title"
        defaultMessage="Congrats! You have successfully subscribe to Social Piper!"
      />
    </Title>
    <Subtitle>
      <FormattedMessage
        id="successfulSubscriptionModal.subtitle"
        defaultMessage="We are excited to have you onboard! Keep rocking on Social Media!"
      />
    </Subtitle>
    <Button
      onClick={() => {
        setOpen(false);
        handleAccept();
      }}
    >
      <FormattedMessage
        id="k.ok"
        defaultMessage="OK"
      />
    </Button>
  </StyledModal>
);

SuccessfulSubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleAccept: PropTypes.func,
};

SuccessfulSubscriptionModal.defaultProps = {
  handleAccept: () => {},
};

export default SuccessfulSubscriptionModal;
