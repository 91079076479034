import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Fade, Backdrop, Modal } from '@material-ui/core';
import BaseInformation from '../BaseInformation';
import { FACEBOOK } from '../../../utils/constants/globals';
import { capitalize } from '../../../utils';
import AdAccountsDescription from './AdAccountsDescription';
import { FacebookLogo } from '../../../assets/social/socialRepository';
import { SocialProfilesProvider } from '../../../services/entities';
import RocketLoader from '../../globals/RocketLoader';
import VideoCreateAdAccountModal from './VideoCreateAdAccountModal';
import useCompany from '../../../hooks/useCompany';

const FacebookInformation = ({
  className,
  avatar,
  pageName,
  companyName,
  requireAttention,
  businessAssociationCb
}) => {
  const intl = useIntl();
  const [adAccounts, setAdAccounts] = useState(null);
  const [openVideoAdAccount, setOpenVideoAdAccount] = useState(false);
  const {companyId} = useCompany()

  const fetchAdAccounts = async () => {
    const response = await SocialProfilesProvider.fetchFacebookAdAccounts();
    if (response.success) {
      setAdAccounts(response.data);
    } else {
      setAdAccounts([]);
    }
  };

  useEffect(() => {
    fetchAdAccounts();
  }, [companyId]);

  const handleCreateOne = () => {
    const auxWindow = window.open('https://www.facebook.com/pages/creation/', '_blank');
    auxWindow.focus();
  };

  const handleCloseVideoAdAccount = () => {
    setOpenVideoAdAccount(false);
    window.location.reload();
  };
  const helpLink = {
    text: intl.formatMessage({
      id: 'socialProfiles.facebook.help',
      defaultMessage: 'Why should I have a business page?',
    }),
    handleClick: () => {
      // TODO: redirect user to help
    },
  };

  if (!adAccounts) {
    return <RocketLoader />;
  }

  return (
    <>
      <Modal
        open={openVideoAdAccount}
        onClose={handleCloseVideoAdAccount}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openVideoAdAccount}>
          <VideoCreateAdAccountModal
            onCloseModal={handleCloseVideoAdAccount}
            setOpenVideoAdAccount={setOpenVideoAdAccount}
          />
        </Fade>
      </Modal>
      <BaseInformation
        className={className}
        avatar={avatar}
        pageName={pageName}
        requireAttention={requireAttention}
        type={FACEBOOK}
        socialLogo={FacebookLogo}
        connectionTitle={intl.formatMessage(
          {
            id: companyName
              ? 'socialProfiles.connectionTitle'
              : 'socialProfiles.connectionTitle.noCompanyName',
            defaultMessage: "Connect {companyName}'s {socialNetwork} account",
          },
          {
            companyName,
            socialNetwork: capitalize(FACEBOOK),
          },
        )}
        hint={intl.formatMessage({
          id: 'socialProfiles.hint.oneAccount',
          defaultMessage: 'At the moment you can connect 1 account',
        })}
        handleCreateOne={handleCreateOne}
        helpLink={helpLink}
        fetchAdAccounts={fetchAdAccounts}
        businessAssociationCb={businessAssociationCb}
      >
        {pageName && (
          <AdAccountsDescription
            adAccounts={adAccounts}
            setAdAccounts={setAdAccounts}
            fetchAdAccounts={fetchAdAccounts}
            socialNetwork={FACEBOOK}
            setOpenVideoAdAccount={setOpenVideoAdAccount}
          />
        )}
      </BaseInformation>
    </>
  );
};

FacebookInformation.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  pageName: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  requireAttention: PropTypes.bool.isRequired,
};

FacebookInformation.defaultProps = {
  className: '',
  avatar: '',
  pageName: '',
};

export default FacebookInformation;
