import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import NotificationModal from '../../../../../components/notifications/Modal';
import Pipers from '../../../../../components/Pipers/Pipers';
import { heading, paragraph } from '../../../../../styled/mixins';
import { colors } from '../../../../../styled/theme';
import { Button } from '../../../../../components/buttons';
import { SECONDARY } from '../../../../../components/buttons/constants';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { AnalyticsContext } from '../../../../../contexts/AnalyticsContext';

const StyledSubscriptionModal = styled(NotificationModal)`
&&{
  .MuiPaper-root{
    padding: 24px 15px;
    ${GlobalTheme.breakpoints.down('sm')} {
      min-width: 350px;
    }
  }
}
`;

const StyledPiper = styled(Pipers.Fail1)`
  width: 200px;
  height: auto;
`;

const Heading = styled.h3`
  ${heading}
  font-size: 22px;
  margin: 32px 0 12px;
`;

const Paragraph = styled.p`
  ${paragraph}
  font-size: 18px;
  color: ${colors.red};
`;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  width: 100%;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-direction: column-reverse;
  }
`;

const StyledContinueButton = styled(Button)`
  && {
    margin-left: 24px;
    ${GlobalTheme.breakpoints.down('sm')}{
      margin-left: 0;
      margin-bottom: 24px;
    }
  }
`;

const CancelSubscriptionModal = ({
  open,
  setOpen,
  handleCancel,
  isPlanApple,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dataLayerPush } = useContext(AnalyticsContext);
  const handleEnter = () => {
    dataLayerPush({
      pagePath: '/settings/billing/cancel-subscription',
      pathTitle: 'Settings - Billing - Cancel subscription',
      event: 'pv',
    });
  };
  return (
    <StyledSubscriptionModal
      open={open}
      setOpen={setOpen}
      minWidth="850px"
      onEntered={handleEnter}
    >
      <StyledPiper />
      <Heading>
        <FormattedMessage
          id="cancelSubscription.title"
          defaultMessage="Are you sure you want to cancel your subscription?"
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="cancelSubscription.description"
          defaultMessage="By doing this, you will loose your special price with the promotional code applied."
        />
      </Paragraph>

      {(isPlanApple) && (
        <Paragraph>
          <FormattedMessage
            id="cancelSubscription.description.apple"
            defaultMessage="Para completar el proceso, deberás cancelar la subscripción en tu dispositivo."
          />
        </Paragraph>
      )}

      <ModalActions>
        <Button
          type={SECONDARY}
          onClick={() => setOpen(false)}
        >
          <FormattedMessage
            id="k.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <StyledContinueButton
          onClick={() => {
            handleCancel(setIsLoading);
          }}
          isLoading={isLoading}
        >
          <FormattedMessage
            id="cancelSubscription.understandButton"
            defaultMessage="I understand. Continue to Cancel my subscription"
          />
        </StyledContinueButton>
      </ModalActions>
    </StyledSubscriptionModal>
  );
};

CancelSubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isPlanApple: PropTypes.bool.isRequired,
};

export default CancelSubscriptionModal;
