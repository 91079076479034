export const INSTAGRAM_SCOPES = [
  'instagram_basic',
  'pages_show_list',
  'instagram_manage_comments',
  'instagram_manage_insights',
  'instagram_content_publish',
];

export const FACEBOOK_SCOPES = [
  'pages_manage_ads',
  'pages_manage_metadata',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_manage_posts',
  'pages_manage_engagement',
  'pages_messaging',
  'read_insights',
  'ads_management',
];

export const FB_PAGES_CONFIG_ID = process.env.REACT_APP_FACEBOOK_FP_CONFIG || "";

export const IG_PAGES_CONFIG_ID = process.env.REACT_APP_FACEBOOK_IGP_CONFIG || "";

export const LINKEDIN_SCOPES = [
  'r_liteprofile',
  // 'r_member_social',
  'rw_organization_admin',
  'r_organization_social',
  'w_organization_social',
  'w_member_social',
  'r_ads',
  'rw_ads',
  'r_ads_reporting',
];

export const TIKTOK_SCOPES = [
  'user.info.basic',
  'video.list',
  'video.upload',
];
