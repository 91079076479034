import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import InstagramProfile from '../../components/SocialProfiles/InstagramProfile';
import LinkedInProfile from '../../components/SocialProfiles/LinkedInProfile';
import TwitterProfile from '../../components/SocialProfiles/TwitterProfile';
import FacebookProfile from '../../components/SocialProfiles/facebook/FacebookProfile';
import useCompany from '../../hooks/useCompany';
import { SocialMediaAttemptProvider } from '../../services/entities';
import { materialShadows, borderTop } from '../../styled/mixins';
import GlobalTheme from '../../styled/GlobalTheme';
import { SocialContext } from '../../contexts/SocialContext';

import Spinner from '../../components/Spinner';

const BusinessInformationCard = styled.div`
  ${(props) => props.insideACard && css`
    ${materialShadows};
    ${borderTop('speechBubble')};
    background: ${({ theme }) => theme.colors.white};
    padding: 24px 32px; 
    flex-wrap: wrap;
    position: relative;
    ${GlobalTheme.breakpoints.down('md')} {
      padding: 24px 16px;
    }
  `}
`;

const BusinessSocialNetworks = ({ insideACard, overrideTitle }) => {
  const [showReConnectFacebookModal, setShowReConnectFacebookModal] = useState(false);
  const { selectedCompany, socialNetworks } = useCompany()

  const businessAssociationCb = async (network) => {
    await SocialMediaAttemptProvider.create({
      companyId: selectedCompany.id,
      socialMediaType: network      
    })
  }

  if (!socialNetworks) {
    return <Spinner />;
  }

  return (
    <BusinessInformationCard insideACard={insideACard}>
      <FacebookProfile
        facebookData={socialNetworks.facebook}
        companyName={selectedCompany?.companyName}
        businessAssociationCb={businessAssociationCb}
        comingSoon
      />
      <InstagramProfile
        instagramData={socialNetworks.instagram}
        facebookData={socialNetworks.facebook}
        companyName={selectedCompany?.companyName}
        setShowReConnectFacebookModal={setShowReConnectFacebookModal}
        businessAssociationCb={businessAssociationCb}
        comingSoon
      />
      {/* <TiktokProfile
        tiktokData={socialNetworks.tiktok}
        companyName={companyName}
      /> */}
      <TwitterProfile
        twitterData={socialNetworks.twitter}
        companyName={selectedCompany?.companyName}
        businessAssociationCb={businessAssociationCb}
        comingSoon
      />
      <LinkedInProfile
        linkedinData={socialNetworks.linkedin}
        companyName={selectedCompany?.companyName}
        businessAssociationCb={businessAssociationCb}
        comingSoon
      />
    </BusinessInformationCard>  
  )
}

export default BusinessSocialNetworks;