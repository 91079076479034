import React from 'react';
import { FormattedMessage } from 'react-intl';

export const upgradeToBusinessBenefits = [
  {
    text: (
      <FormattedMessage
        id="upgradeToBusinessModal.listItem.1"
        defaultMessage="Add as many businesses or products as you want, connect your social networks for each one."
        values={{
          s: (...chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
  {
    text: (
      <FormattedMessage
        id="upgradeToBusinessModal.listItem.2"
        defaultMessage="Add team members to manage your businesses’s social networks."
        values={{
          s: (...chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
  {
    text: (
      <FormattedMessage
        id="upgradeToBusinessModal.listItem.3"
        defaultMessage="Supervise shceduled posts and boosts with the approbal system."
        values={{
          s: (...chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
  {
    text: (
      <FormattedMessage
        id="upgradeToBusinessModal.listItem.4"
        defaultMessage="Check at a glance how are your Social Ads performing and manage your advertising Budget on each of your business or products."
        values={{
          s: (...chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
];