import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  materialShadows,
  borderTop,
  heading3,
  flexBox,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { ButtonLink } from '../../../../../components/buttons';
import PasswordInput from '../../../../../components/globals/PasswordInput';
import NewPasswordInput from './NewPasswordInput';
import UserProvider from '../../../../../services/entities/UserProvider';
import { AlertsContext } from '../../../../../contexts/AlertsContext';

const UpdatePasswordCard = styled.div`
  ${materialShadows};
  ${borderTop('speechBubble')};
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  ${flexBox}
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
  ${GlobalTheme.breakpoints.down('lg')}{
    padding: 16px;
  }
`;

export const Title = styled.h3`
  ${heading3}
  margin-bottom: 24px;
  width: 100%;
`;

const Input = styled(PasswordInput)`
  width: 100%;
  margin: 0 0 24px;
`;

const StyledNewPasswordInput = styled(NewPasswordInput)`
  width: 100%;
  margin: 0 0 24px;
`;

const UpdatePassword = ({
  className,
}) => {
  const intl = useIntl();
  const [passwords, setPasswords] = useState({
    password: '',
    confirmPassword: '',
  });

  const [validForm, setValidForm] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    if (isPasswordValid) {
      setValidForm(passwords.password === passwords.confirmPassword);
    } else {
      setValidForm(false);
    }
  }, [isPasswordValid, passwords.confirmPassword]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setPasswords({
      ...passwords,
      [id]: value,
    });
  };

  const { showAlert, showError } = useContext(AlertsContext);
  const [isLoading, setIsLoading] = useState(false);
  const handleSave = async () => {
    setIsLoading(true);
    const { success, message } = await UserProvider.updatePassword(passwords.password);
    if (success) {
      setIsPasswordValid(false);
      setPasswords({
        password: '',
        confirmPassword: '',
      });
      showAlert(intl.formatMessage({
        id: 'alert.success.update.female',
        defaultMessage: '{item} updated successfully',
      }, {
        item: intl.formatMessage({
          id: 'k.password',
          defaultMessage: 'Password',
        }),
      }));
    } else {
      showError(message);
    }
    setIsLoading(false);
  };

  return (
    <UpdatePasswordCard
      className={className}
    >
      <Title>
        <FormattedMessage
          id="settings.personal.updatePassword.title"
          defaultMessage="Update password"
        />
      </Title>
      <StyledNewPasswordInput
        id="password"
        onChange={handleInputChange}
        label={intl.formatMessage({
          id: 'settings.personal.updatePassword.newPassword',
          defaultMessage: 'New password',
        })}
        value={passwords.password}
        setIsValid={setIsPasswordValid}
        isValid={isPasswordValid}
      />
      <Input
        id="confirmPassword"
        onChange={handleInputChange}
        label={intl.formatMessage({
          id: 'settings.personal.updatePassword.confirm',
          defaultMessage: 'Confirm new password',
        })}
        value={passwords.confirmPassword}
        type="password"
      />
      <ButtonLink
        disabled={!validForm}
        onClick={handleSave}
        isLoading={isLoading}
      >
        <FormattedMessage
          id="k.saveChanges"
          defaultMessage="Save Changes"
        />
      </ButtonLink>
    </UpdatePasswordCard>
  );
};

UpdatePassword.propTypes = {
  className: PropTypes.string,
};

UpdatePassword.defaultProps = {
  className: '',
};

export default UpdatePassword;
