const colors = {
  black: '#231F20',
  black3E: '#3E3E3E',
  text02: '#675458',
  text03: '#333',
  primary: '#027686',
  primaryDark: 'rgb(1, 82, 93)',
  primaryLight: '#00899B',
  primaryLighter: '#009DB2',
  primaryMoreLighter: '#00B4CC',
  secondary: '#FFDD00',
  speechBubble: '#009DB2',
  secondaryDark: '#FEBD11',
  rocketPiperBackground: '#FED601',
  screenBackground: '#F8F8F8',
  headerTabInactive: '#FFEE80',
  purple: '#8D4A80',
  lightPurple: '#B962A8',
  lightYellow: 'rgba(255, 221, 0, 0.25)',
  darkYellow: '#FFD570',
  yellow: '#FFF29D',
  yellow2: '#FFB800',
  orange: '#FF7E4E',
  orange02: '#FAA819',
  orange03: '#EF845A',
  white: '#FFFFFF',
  grayF1: '#F1F1F1',
  grayF2: '#F2F2F2',
  gray01: '#f4f4f4',
  gray02: '#909090',
  grayE3: '#E3E3E3',
  grayE5: '#E5E5E5',
  grayE9: '#E9E9E9',
  grey700: '#5F6D7C',
  gray90: '#909090',
  gray74: '#747474',
  gray38: '#383F48',
  grayf43: '#F4F3F3',
  grayC6: '#C6C6C6',
  grayEF: '#EFEFEF',
  grayMediaLibrary: '#3C3C3C',
  grayF5: '#F5F5F5',
  gray7E: '#7E7E7E',
  grayDF: '#DFDFDF',
  grayD1: '#D1D1D1',
  gray6: '#666666',
  chartGray: '#675458',
  badge: '#EB5B62',
  error: '#ED1C24',
  green: '#00BF62',
  green02: '#00C156',
  green03: '#6FCF97',
  green04: '#00A8C0',
  green05: '#56BB6C',
  red: '#FF4D5D',
  red02: '#D0355A',
  redDark: '#bf2e3b',
  blue: '#415DA5',
  blue02: '#369CD5',
  blueLi: '#0E76A8',
  brand5: '#F053AD',
  brand8: '#00D6C8',
  Brand1CTA: '#027686', 
  previewBack: '#3B3B3B',
  grayedPurple: '#B1A2A2',
  lightBlue: '#00B4CC',
  pink: '#DC3275',
  facebook: '#43629F',
  instagram: '#DC3275',
  twitter: '#303030',
  linkedin: '#0E76A8',
  storiesIG: '#DC3275',
  facebookOpacity8: 'rgba(67, 98, 159, 0.8)',
  instagramOpacity8: 'rgba(220, 50, 117, 0.8)',
  twitterOpacity8: 'rgba(79, 177, 221, 0.8)',
  linkedinOpacity8: 'rgba(0, 113, 161, 0.8)',
  tiktokOpacity8: 'rgba(62, 62, 62, 0.8)',
  facebookOpacity2: 'rgba(67, 98, 159, 0.2)',
  instagramOpacity2: 'rgba(220, 50, 117, 0.2)',
  twitterOpacity2: 'rgba(79, 177, 221, 0.2)',
  linkedinOpacity2: 'rgba(0, 113, 161, 0.2)',
  tiktokOpacity2: 'rgba(62, 62, 62, 0.2)',
  linkedin02: '#0071A1',
  tiktok: '#000',
  orangeInstagram: '#FF833D',
  pinkInstagram: '#E7407B',
  blueInstagram: '#0028FF',
  yellowInstagram: '#F9ED41',
};

export default colors;
