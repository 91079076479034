import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PasswordInput from '../../../../../components/globals/PasswordInput';
import PasswordHelper from '../../../../../components/globals/passwordHelper';

const Container = styled.div`
  position: relative;
`;

const StyledPasswordHelper = styled(PasswordHelper)`
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 10;
  display: ${({ isShowing }) => (isShowing ? 'block' : 'none')};
`;

const NewPasswordInput = ({
  id,
  value,
  isValid,
  setIsValid,
  onChange,
  label,
  className,
}) => {
  const [isHelperShowing, setIsHelperShowing] = useState(false);

  return (
    <Container
      className={className}
    >
      <PasswordInput
        id={id}
        onChange={onChange}
        label={label}
        value={value}
        type="password"
        onFocus={() => setIsHelperShowing(true)}
        onBlur={() => setIsHelperShowing(false)}
      />
      <StyledPasswordHelper
        password={value}
        setIsValid={setIsValid}
        isShowing={isHelperShowing && !isValid}
      />
    </Container>
  );
};

NewPasswordInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  setIsValid: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NewPasswordInput.defaultProps = {
  className: '',
};

export default NewPasswordInput;
