import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AvatarStyled = styled.figure`
  margin: 0;
  border-radius:50%;
  width: ${(props) => props.size || '100%'};
  height: ${(props) => props.size};
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Avatar = ({
  src, fullName, className, size,
}) => (
  <AvatarStyled className={className} size={size}>
    <Image src={src} alt={fullName} />
  </AvatarStyled>
);

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
  fullName: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
  fullName: '',
  size: '40px',
};

export default Avatar;
