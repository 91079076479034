import { AUDIENCE_SIZE, LENGTHS } from './constants/settings';
import { colors } from '../styled/theme';

const calculateReachPercentage = (size, sn) => {
  let percent = 1;
  let lowerRangeColor = colors.grayF1;
  let middleColor = colors.grayF1;
  let upperRangeColor = colors.red;

  if (size <= AUDIENCE_SIZE[sn].SMALL) {
    lowerRangeColor = colors.red;
    upperRangeColor = colors.grayF1;
    percent = (size / AUDIENCE_SIZE[sn].SMALL) * LENGTHS[0];
  } else if (size <= AUDIENCE_SIZE[sn].MIDDLE) {
    middleColor = colors.secondary;
    upperRangeColor = colors.grayF1;
    percent = ((size / AUDIENCE_SIZE[sn].MIDDLE) * LENGTHS[1]) + LENGTHS[0];
  } else {
    percent = ((size / AUDIENCE_SIZE[sn].BIG) * LENGTHS[2]) + LENGTHS[0] + LENGTHS[1];
  }
  percent = percent > 1 ? 1 : percent;

  return [
    percent,
    lowerRangeColor,
    middleColor,
    upperRangeColor,
  ];
};

export default calculateReachPercentage;
