/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { flexBox, heading3 } from '../../../../../styled/mixins';
import PiperAdvice from '../../../../../components/globals/PiperAdvice';
import { ButtonLink } from '../../../../../components/buttons';
import GlobalTheme from '../../../../../styled/GlobalTheme';

const Header = styled.div`
  flex: 0 0 100%;
  ${flexBox}
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
`;

const TitleSection = styled.div`
  flex: 0 0 calc(100% - 32px);
  display: flex;
  flex-direction: column;
  ${GlobalTheme.breakpoints.down('md')}{
    flex: 0 0 100%;
  }
`;

const Title = styled.h3`
  ${heading3}
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: left;
  margin: 16px 0 0;
`;

const StyledButtonLink = styled(ButtonLink)`
  flex: 0 0 calc(50% - 32px);
  max-width: 290px;
  text-align: right;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex: 0 0 100%;
    text-align: center;
    padding: 0 10%;
    font-size: 12px;
    margin-top: 24px;
  }
`;

const CardInformationHeader = ({
  title,
  advice,
  prefill,
}) => (
  <Header>
    <TitleSection>
      <Title>
        {title}
      </Title>
      <StyledPiperAdvice>
        {advice}
      </StyledPiperAdvice>
    </TitleSection>
  </Header>
);

CardInformationHeader.propTypes = {
  title: PropTypes.string.isRequired,
  advice: PropTypes.string.isRequired,
  prefill: PropTypes.string.isRequired,
};

export default CardInformationHeader;
