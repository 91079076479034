import React from "react";
import styled from "styled-components";
import { piper } from "../../assets/illustrations/illustrationRepository";
import colors from "../../styled/colors";

/** @type  {(props: { message: string, orientation: 'center' | 'flex-start' | 'flex-end' }) => JSX.Element} */
export const PiperDogStandAlert = (props) => {
  return (
    <Hero orientation={props.orientation}>
      <DogVector src={piper.PiperAdviseHolder} />

      <AlertContainer>
        <TriangleIndicator
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M32 32L32 0L3.81596e-07 32L32 32Z"
            fill={colors.primaryLighter}
          />
        </TriangleIndicator>
        <AlertText>{props.message}</AlertText>
      </AlertContainer>
    </Hero>
  );
};

const Hero = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: flex-start;
  justify-content: ${(props) => props.orientation};
  margin-bottom: 30px;
`;

const TriangleIndicator = styled.svg`
  position: absolute;
  top: 9px;
  left: -23px;
`;

const DogVector = styled.img`
  width: 109px;
  height: 90px;
`;

const AlertContainer = styled.div`
  display: flex;
  position: relative;
  width: 400px;
  height: 75px;
  padding: 16px 16px 19px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.primaryLighter};
`;

const AlertText = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
`;
