import { FormControlLabel } from "@material-ui/core";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { text } from "../text";

/** @type {(props: unknown) => React.NamedExoticComponent<typeof props>} */
export const PlanCard = memo((props) => {
  const iterateList = (list) => {
    return list.map((li) => {
      let [txt, comingSoon] = li instanceof Array ? li : [li, false];

      return (
        <li>
          <p>
            <DotIcon /> {text(txt)}
          </p>
          {comingSoon && <p className="commingSoon">Coming Soon</p>}
        </li>
      );
    });
  };

  return (
    <StyledPlanCard hex={props.color} onClick={props.onClick}>
      <div className="top">
        <p className="title">{text(props.title)}</p>
        <p className="description">{text(props.description)}</p>
      </div>
      <div className="bottom">
        <span>
          <i className="price">${props.price}</i>
          <i className="currency">USD</i>
          <i className="period">/MO</i>
        </span>
        <ul>{iterateList(props.list)}</ul>
        <Link
          to={props.to}
          className="seemore"
          onClick={(ev) => ev.stopPropagation()}
        >
          See more features
        </Link>
      </div>
    </StyledPlanCard>
  );
});

const DotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <circle cx="5" cy="5" r="2.5" fill="#3E3E3E" />
  </svg>
);

const StyledPlanCard = styled.div`
  cursor: pointer;

  ul {
    list-style: none;
  }

  ul li p {
    color: ${(props) => props.theme.colors.black3E};
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;
  }

  ul li:first-child {
    border-top: 1px solid ${(props) => props.theme.colors.grayE9};
  }

  ul li {
    border-bottom: 1px solid ${(props) => props.theme.colors.grayE9};
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  div.top {
    height: 165px;
    width: 255px;
    border-radius: 6px 6px 0px 0px;
    background-color: ${(props) => props.hex};
    padding: 14px;
  }

  div.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 230px;
    width: 255px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 6px 6px;
  }

  div.bottom span {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 9px;
    padding-bottom: 5px;
  }

  p.commingSoon {
    color: ${(props) => props.theme.colors.gray02};
    text-align: center;
    font-size: 8px;
    font-weight: 400;
    white-space: break-spaces;
  }

  p.title {
    color: ${(props) => props.theme.colors.white};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
  }

  p.description {
    color: ${(props) => props.theme.colors.white};
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }

  a.seemore {
    display: block;
    width: 100%;
    color: ${(props) => props.theme.colors.primaryMoreLighter};
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    text-decoration-line: underline;
    margin-top: auto;
    margin-bottom: 18px;
    cursor: pointer;
  }

  i {
    font-style: normal;
  }

  i.price {
    color: ${(props) => props.hex};
    text-align: center;
    font-size: 30px;
    font-weight: 400;
  }

  i.currency {
    color: ${(props) => props.hex};
    font-size: 12px;
    font-weight: 400;
  }

  i.period {
    color: ${(props) => props.theme.colors.gray90};
    font-size: 10px;
    font-weight: 400;
  }
`;

export const TrialText = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: calc(0px - 20px);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
`;

export const RadioCheck = styled(RadioButtonChecked)`
  color: ${(props) => props.theme.colors.primary};
`;

export const RadioUncheck = styled(RadioButtonUnchecked)`
  color: ${(props) => props.theme.colors.primary};
`;

export const RadioControl = styled(FormControlLabel)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const PlanWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;

  span {
    color: ${(props) => props.theme.colors.black3E};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const PlansGlobalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 45px;
`;
