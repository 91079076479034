import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { flexBox, heading3, paragraph } from '../../styled/mixins';
import { colors } from '../../styled/theme';
// Return nex lines whenever want to return the help links (with the correct link)
// import BaseInformationDescription from './BaseInformationDescription';
import ConnectButton from './ConnectButton';
import GlobalTheme from '../../styled/GlobalTheme';

const BaseInformationContainer = styled.div`
  flex: 1;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas: "information button"
                        "description null"
                        "note null";
  ${GlobalTheme.breakpoints.down('md')}{
    grid-template-areas: "information button"
                        "description description"
                        "note note";
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    grid-template-columns: 40px auto;
    grid-gap: 16px;
    grid-template-areas: "social information"
                        "description description"
                        "note note"
                        "button button";
  }
`;

const SocialLogoImg = styled.img`
  grid-area: social;
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
  margin-bottom: auto;
  display: none;
  ${GlobalTheme.breakpoints.down('sm')}{
    display: block;
  }
`;

const Page = styled.div`
  grid-area: information;
  ${flexBox}
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const AvatarImg = styled.img`
  width: 40px;
  height: 40px;
  object-position: center;
  border-radius: 100%;
  margin-right: 8px;
`;

const Name = styled.h3`
  ${heading3}
  font-size: 20px;
`;

const Hint = styled.p`
  ${paragraph};
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  color: ${colors.text02};
`;

const StyledConnectButton = styled(ConnectButton)`
  && {
    margin-top: 10px;
    ${GlobalTheme.breakpoints.down('sm')}{
      margin-top: -16px;
      width: 100%;
    }
  }
`;

const PageGray = styled.div`
  background-color: ${GlobalTheme.colors.screenBackground};
  padding: 1em;
  grid-area: information;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const Warning = styled.div`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: ${colors.red};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '!';
    color: ${colors.white};
    position: absolute;
    font-size: 12px;
  }
`;

const WrapperButton = styled.div`
  grid-area: button;
`;

const BaseInformation = ({
  className,
  avatar,
  pageName,
  connectionTitle,
  hint,
  comingSoon,
  // handleCreateOne,
  // helpLink,
  connectDisabled,
  children,
  type,
  socialLogo,
  fetchAdAccounts,
  setLinkedinPages,
  setLinkInModalOpen,
  setShowReConnectFacebookModal,
  requireAttention,
  businessAssociationCb
}) => (
  <BaseInformationContainer
    className={className}
  >
    <SocialLogoImg src={socialLogo} alt={type} />
    {(!requireAttention) && (
      <Page>
        {pageName ? (
          <>
            {avatar && (
              <AvatarImg src={avatar?.replace(/&amp;/g, '&')} alt="avatar" />
            )}
            <Name>
              {pageName}
            </Name>
          </>
        ) : (
          <>
            <Name>
              {connectionTitle}
            </Name>
            {hint && (
              <Hint>
                {hint}
              </Hint>
            )}
          </>
        )}
      </Page>
    )}
    {(requireAttention) && (
      <PageGray>
        <FormattedMessage
          id="requireAttention.settings"
          tagName="p"
          values={{
            warni: <Warning />,
          }}
        />
      </PageGray>
    )}
    <WrapperButton>
      <StyledConnectButton
        isConnected={!!pageName}
        socialNetwork={type}
        comingSoon={comingSoon}
        disabled={connectDisabled}
        fetchAdAccounts={fetchAdAccounts}
        setLinkedinPages={setLinkedinPages}
        setLinkInModalOpen={setLinkInModalOpen}
        setShowReConnectFacebookModal={setShowReConnectFacebookModal}
        requireAttention={requireAttention}
        businessAssociationCb={businessAssociationCb}
      />
    </WrapperButton>
    {pageName && !requireAttention && children}
    {/* Return nex lines whenever want to return the help links (with the correct link) */}
    {/* {!pageName && !comingSoon && (
      <BaseInformationDescription
        handleCreateOne={handleCreateOne}
        helpLink={helpLink}
      />
    )} */}
  </BaseInformationContainer>
);

BaseInformation.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  pageName: PropTypes.string,
  connectionTitle: PropTypes.string.isRequired,
  hint: PropTypes.string,
  children: PropTypes.node,
  comingSoon: PropTypes.bool,
  requireAttention: PropTypes.bool,
  connectDisabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  socialLogo: PropTypes.string.isRequired,
  fetchAdAccounts: PropTypes.func,
  setLinkedinPages: PropTypes.func,
  setLinkInModalOpen: PropTypes.func,
  setShowReConnectFacebookModal: PropTypes.func,
};

BaseInformation.defaultProps = {
  className: '',
  avatar: '',
  pageName: '',
  hint: '',
  children: null,
  comingSoon: false,
  connectDisabled: false,
  requireAttention: false,
  fetchAdAccounts: () => { },
  setLinkedinPages: () => { },
  setLinkInModalOpen: () => { },
  setShowReConnectFacebookModal: () => { },
};

export default BaseInformation;
