import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';
import { flexBox, heading3 } from '../../styled/mixins';
import StripeCardFormElements from './StripeCardFormElements';
import GlobalTheme from '../../styled/GlobalTheme';
import {
  stripeIcon,
  visaIcon,
  masterCardIcon,
  americanExpressIcon,
  discoverIcon,
} from '../../assets/icons/iconRepository';
import mediaQueries from '../../utils/mediaQueries';

const CardDetailsContainer = styled.div`
  ${flexBox}
  flex-wrap: wrap;
`;

const Title = styled.h3`
  ${heading3}
  margin-bottom: 16px;
  flex: 0 0 100%;
`;

const ContainerImageCard = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
`;

const ContainerImageCardInner = styled.div`
  display: flex;
  margin-left: auto;
  background-color: #E9E9E9;
  border-radius: 5px;
`;

const CardsImgStr = styled.img`
  width: 75px;
  height: 50px;
  margin: 0;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 40px;
    height: 25px;
  };
`;

const CardsImg = styled.img`
  width: 45px;
  height: 30px;
  margin: 10px 15px;
  ${GlobalTheme.breakpoints.down('md')}{
    margin: 10px 10px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 25px;
    height: 15px;
    margin: 10px 4px;
  };
`;

const ContainerTextCard = styled.div`
  font-size: 11px;
  width: 80px;
  text-align: center;
  padding-top: 5px;
  font-weight: bold;
  margin-top: 5px;
  padding-left: 10px;
  ${GlobalTheme.breakpoints.down('sm')}{
    padding-top: 0;
    font-size: 8px;
    padding-left: 0;
  }
`;

const CardDetails = ({
  className,
  intl,
  cardholderName,
  setCardHolderName,
}) => (
  <CardDetailsContainer
    className={className}
  >
    <Title>
      <FormattedMessage
        id="k.card"
        defaultMessage="Card"
      />
    </Title>

    <ContainerImageCard>
      <CardsImgStr src={stripeIcon} alt="cards" width={{ width: '75px', height: '45px' }} />
      <ContainerImageCardInner>
        <Media queries={mediaQueries}>
          {(matches) => (
            <ContainerTextCard>
              <FormattedMessage
                id={matches.smDown ? 'settings.modal.only.credit.text.sm' : 'settings.modal.only.credit.text'}
                defaultMessage="Only Credit Cards"
                values={{
                  lineBreak: <br />,
                }}
              />
            </ContainerTextCard>
          )}
        </Media>
        <CardsImg src={visaIcon} alt="cards" />
        <CardsImg src={masterCardIcon} alt="cards" />
        <CardsImg src={americanExpressIcon} alt="cards" />
        <CardsImg src={discoverIcon} alt="cards" />
      </ContainerImageCardInner>
    </ContainerImageCard>

    <StripeCardFormElements
      intl={intl}
      cardholderName={cardholderName}
      setCardHolderName={setCardHolderName}
    />
  </CardDetailsContainer>
);

CardDetails.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.instanceOf(Object).isRequired,
  cardholderName: PropTypes.string.isRequired,
  setCardHolderName: PropTypes.func.isRequired,
};

CardDetails.defaultProps = {
  className: '',
};

export default CardDetails;
