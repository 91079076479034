/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { flexBox } from '../../../../../styled/mixins';
import PaymentRow from './PaymentRow';
import PaymentHeadersRow from './PaymentHeadersRow';
import Pipers from '../../../../../components/Pipers/Pipers';
import PiperTip from '../../../../../components/PiperTips/PiperTip';

const PaymentTable = styled.div`
  ${flexBox}
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const EmptyTable = styled.div`
  display: flex;
  width: 100%;
  max-width: 438px;
  align-items: center;
  justify-content: center;
  min-height: 190px;
  margin: 0 auto;
`;

const PaymentHistoryTable = ({
  chargeOptions,
  chargeFilter,
  handleChargeChange,
  dateFilterOptions,
  dateFilter,
  handleDateChange,
  setPaymentOverlay,
  payments,
  intl,
}) => ((payments && payments.length > 0) ? (
  <PaymentTable>
    <PaymentHeadersRow />
    {payments.map((payment) => (
      <PaymentRow
        intl={intl}
        payment={payment}
        setPaymentOverlay={setPaymentOverlay}
      />
    ))}
  </PaymentTable>
) : (
  <EmptyTable>
    <PiperTip piperWidth="80px" tipWidth="auto" Piper={Pipers.Piper2} position="right" trianglePosition="bottom">
      <FormattedMessage
        id="settings.billing.payments.empty"
        defaultMessage="Once you start with the One Plan Subscription and create a Boost, here you will see your payment history."
      />
    </PiperTip>
  </EmptyTable>
));

PaymentHistoryTable.propTypes = {
  chargeOptions: PropTypes.instanceOf(Array).isRequired,
  chargeFilter: PropTypes.string.isRequired,
  handleChargeChange: PropTypes.func.isRequired,
  dateFilterOptions: PropTypes.instanceOf(Array).isRequired,
  dateFilter: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  payments: PropTypes.instanceOf(Array).isRequired,
  intl: PropTypes.instanceOf(Object).isRequired,
  setPaymentOverlay: PropTypes.func.isRequired,
};

export default PaymentHistoryTable;
