import React, { useContext } from 'react';

import { Profiles } from '../..';
import BusinessAudienceForm from '../../../../business/components/BusinessAudienceForm';
import BusinessSocialNetworks from '../../../../business/components/BusinessSocialNetworks';
import { Button } from '../../../../components/buttons';
import { text } from '../../../../components/text';
import { LayoutContext } from '../../../../contexts/LayoutContext';
import useCompany from '../../../../hooks/useCompany';
import AutoResponseInformation from './components/AutoResponseSettings';
import BusinessInformation from './components/BusinessInformation';
import DeleteBusinessSection from './components/DeleteBusinessSection';
import {
  BusinessContainer,
  BusinessItemWrapper,
  BusinessSelectionWrapper,
  StyledPiperAdvice,
} from './index.styled';
// import Social from '../Social';

export default () => {
  const { user } = useContext(LayoutContext);
  const {
    addDummyCompany,
    deleteCompany,
    hasConnectedAccounts,
    disableAddCompanyButton,
    hasExistingCompanyOnDraft,
  } = useCompany();

  return (
    <BusinessContainer>
      <StyledPiperAdvice>{text('settings.business.advice')}</StyledPiperAdvice>

      {(user.profileId === Profiles.MASTER || user.profileId === Profiles.DEFAULT) && (
        <>
          {user.profileId === Profiles.MASTER && (
          <BusinessSelectionWrapper>
            <BusinessItemWrapper></BusinessItemWrapper>

              <Button onClick={() => addDummyCompany()} disabled={disableAddCompanyButton}>
                {text('settings.business.add')}
              </Button>
          </BusinessSelectionWrapper>
          )}
          <BusinessInformation />
          {(!hasExistingCompanyOnDraft && user.profileId === Profiles.MASTER) && (
            <>
              <BusinessSocialNetworks insideACard />
              {hasConnectedAccounts && <BusinessAudienceForm insideACard />}
            </>
          )}
        </>
      )}

      {/* <PrimaryTarget /> */}
      {!hasExistingCompanyOnDraft && (
        <>
          <AutoResponseInformation />
          {user.profileId === Profiles.MASTER && (
            <DeleteBusinessSection onDelete={() => deleteCompany()} />
          )}
        </>
      )}
    </BusinessContainer>
  );
};
