import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import RocketLoader from "../../../../../components/globals/RocketLoader";
import GlobalTheme from '../../../../../styled/GlobalTheme';

const LoaderContainer = styled.div`
  max-width: 90%;
  text-align: center;
  margin: 0 auto 0;
`;

const LoaderTitle = styled.p`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  ${GlobalTheme.breakpoints.up('sm')} {
    font-size: 48px;
  }
`;

const LoaderText = styled.p`
  font-size: 14px;
  ${GlobalTheme.breakpoints.up('sm')} {
    font-size: 20px;
  }
`;

const UpgradeLoader = () => {
    return (
        <RocketLoader>
            <LoaderContainer>
            <LoaderTitle>
                <FormattedMessage
                id="settings.upgradeToBusiness.loader.title"
                />
            </LoaderTitle>
            <LoaderText>
                <FormattedMessage
                id="settings.upgradeToBusiness.loader.text"
                values={{
                    bold: (chunks) => (<b>{chunks}</b>),
                }}
                />
            </LoaderText>
            </LoaderContainer>
        </RocketLoader>
    )
};

export default UpgradeLoader;