const en = {
  'community.comments.piperTip': 'There are no comments on this post yet.',
  'community.inbox.comments.piperTip': 'Conversations with your clients are shown here.{lineBreak}Select one and start interacting!{lineBreak}Remember that you have a period of 24 hours to reply to the last message from your client.',
  'community.conversation.cta.counter': '{hours} hours',
  'community.conversation.cta.text': 'Respond within the next',
  'community.conversation.cta.smalltext': 'Respond within',
  'community.conversation.list.order.post.chronological': 'Chronological',
  'community.conversation.list.order.post.unread': 'Posts with unread comments',
  'community.conversation.list.search.placeholder': 'Search comments',
  'community.conversation.button': 'Mark as read all my comments',
  'community.facebookMessenger': 'Reply via Facebook Messenger',
  'community.inbox.noReply': 'You can reply to direct messages through Social Piper up to after 24 hours <r>after receiving the last message from {name}</r>.  Don’t lose the conversation:',
  'community.markAsRead': 'Read',
  'community.readMore': 'See full comment',
  'community.readLess': 'Shorten comment',
  'community.noSocialMedia.piperTip': 'Connect your business social media profiles to see your messages.',
  'community.inbox.noSocialMedia.piperTip': 'Connect your business social media profiles to see a feed of all of your latest messages.',
  'community.piper.comment.quick': 'Don’t leave your customers waiting too long. Quick responses increase brand trust',
  'community.piper.comment.replyMore': 'Reply to more comments! So your audience stick around and trust you',
  'community.piper.comment.suggestedTime': 'Suggested time to respond',
  'community.post.card.addBoost': 'Add Boost to this Post',
  'community.post.card.addBoost.again': 'Add Boost again',
  'community.post.card.no-description': 'Description not available',
  'community.conversation.title': 'Posts',
  'community.comments.title': 'Comments history',
  'community.details.title': 'Details',
  'community.inbox.title': 'Inbox',
  'community.massages.title': 'Messages history',
  'community.conversation.list.order.post.withComments': 'Posts with comments',
  'community.post.commentsHeader.boost.button': 'Boost this post',
  'community.config.title': 'To understand more about your business and generate better answers, please provide de following information.',
  'community.config.subtitle': 'You can also edit this information in the configuration section.',
  'community.config.hint': 'The fields marked with an * are mandatory:',
  'community.config.companyName.label': 'What is the name of the business?',
  'community.config.companyName.placeholder': 'E.g. Piper Pizza',
  'community.config.companyCategories.placeholder': 'E.g. Pizza place',
  'community.config.companyLocation.placeholder': 'E.g. Palo Alto, California',
  'community.config.products.label': 'Describe your business products and/or services. You can add up to 3 descriptions.',
  'community.config.products.placeholder': 'E.g. Pizza delivery',
  'community.config.atentionHours.label': 'What are your business hours of operation?',
  'community.config.atentionHours.placeholder': '',
  'community.config.emailContact.label': 'What is your email to serve your customers?',
  'community.config.emailContact.placeholder': 'E.g. piperpizza@gmail.com',
  'community.config.phoneContact.label': 'What is the customer service phone number?',
  'community.config.phoneContact.placeholder': 'E.g. 5555-555555 ',
  'community.config.addressContact.label': 'Do you have a physical address? Detail it here.',
  'community.config.addressContact.placeholder': 'E.g. 772 Hansen Way, Palo Alto, California',
  'community.config.faqs.label': 'Please add the most frequently asked questions and their answers. You can add up to 3 items.',
  'community.config.faqs.placeholderQ': 'E.g. Does the delivery service have a cost?',
  'community.config.faqs.placeholderA': 'E.g. The delivery service is free of charge and tip is optional',
  'community.config.buttons.cancel': 'Cancel',
  'community.config.buttons.submit': 'Save and continue',
  'community.config.faqs.toast.success': 'Additional company configuration saved successfully!'
};

export default en;
