import React, { createContext, useMemo, useReducer } from "react";
import { reducer } from "./index.reducer";
import struct from "./index.struct";

export const PlanCtx = createContext(null);

const PlanProvider = (props) => {
  const [plans, dispatch] = useReducer(reducer, struct);

  const value = useMemo(() => ({ plans, dispatch }), [plans, dispatch]);

  return <PlanCtx.Provider value={value}>{props.children}</PlanCtx.Provider>;
};

export default PlanProvider;
