import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CloseOutlineIcon } from '../../../../../assets/icons/iconRepository';
import mediaQueries from '../../../../../utils/mediaQueries';
import cleanURL from '../utils/cleanURL';
import CompetitorsProvider from '../../../../../services/entities/CompetitorsProvider';
import { FACEBOOK } from '../../../../../utils/constants/globals';
import { AlertsContext } from '../../../../../contexts/AlertsContext';

const Container = styled.div`
  margin-top: 30px;
  @media ${mediaQueries.mdUp} {
    margin-top: 40px;
  }
`;
const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  @media ${mediaQueries.mdUp} {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-row-gap: 28px;
  }
  @media ${mediaQueries.lgPlusUp} {
    grid-template-columns: repeat(auto-fill, 170px);
    grid-column-gap: 20px;
  }
  @media ${mediaQueries.xlPlusUp} {
    grid-template-columns: repeat(auto-fill, 192px);
  }
`;
const Competitor = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayE9};
  border-radius: 2px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 0 8px 12px;
  position: relative;
  @media ${mediaQueries.mdUp} {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 16px;
    padding-left: 0;
    padding-bottom: 12px;
  }
  @media ${mediaQueries.xlPlusUp} {
    padding: 16px 0;
  }
`;
const CompetitorImageContainer = styled.div`
  width: 58px;
  height: 58px;
  @media ${mediaQueries.mdUp} {
    width: 85px;
    height: 85px;
  }
`;
const CompetitorImage = styled.img`
  width: 100%;
`;
const CompetitorName = styled.span`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 16px;
  line-height: 22px;
  margin-left: 9px;
  @media ${mediaQueries.mdUp} {
    display: block;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }
`;
const CloseIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
`;
const CloseIcon = styled.img`
  width: 100%;
`;

const CompetitorsList = ({ type, competitors, setCompetitors }) => {
  const { showBackendError } = useContext(AlertsContext);
  const handleDeleteCompetitor = async (id) => {
    let deleteMethod = CompetitorsProvider.deleteInstagramCompetitor;
    if (type === FACEBOOK) {
      deleteMethod = CompetitorsProvider.deleteFacebookCompetitor;
    }

    const response = await deleteMethod(id, competitors);
    if (!response.success) {
      showBackendError(response.message);
    } else {
      setCompetitors(response.data);
    }
  };
  return (
    <Container>
      <List>
        {competitors.map((competitor) => (
          <Competitor>
            <CompetitorImageContainer>
              <CompetitorImage src={cleanURL(competitor.picture)} />
            </CompetitorImageContainer>
            <CompetitorName>{competitor.label}</CompetitorName>
            <CloseIconContainer onClick={() => handleDeleteCompetitor(competitor.value)}>
              <CloseIcon src={CloseOutlineIcon} />
            </CloseIconContainer>
          </Competitor>
        ))}
      </List>
    </Container>
  );
};

CompetitorsList.propTypes = {
  type: PropTypes.string.isRequired,
  competitors: PropTypes.arrayOf(
    PropTypes.shape({
      socialId: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      picture: PropTypes.string,
    }),
  ).isRequired,
  setCompetitors: PropTypes.func.isRequired,
};

export default CompetitorsList;
