import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  FacebookColorIcon,
  InstagramColorIcon,
} from '../../../../../assets/icons/iconRepository';
import mediaQueries from '../../../../../utils/mediaQueries';
import { SOCIAL_MEDIA } from '../../../../../utils/constants/globals';
import SearchCompetitors from './SearchCompetitors';
import CompetitorsList from './CompetitorsList';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 32px;
  grid-template-areas:
    'logo title'
    'content content';
  grid-column-gap: 12px;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 34px;
  &:not(:first-child) {
    padding-top: 30px;
    @media ${mediaQueries.mdUp} {
      padding-top: 28px;
    }
    @media ${mediaQueries.lgPlusUp} {
      padding-top: 34px;
    }
    @media ${mediaQueries.xlPlusUp} {
      padding-top: 50px;
    }
    .title {
      &:before {
        content: "";
        background: ${({ theme }) => theme.colors.grayE9};
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        right: 0;
        @media ${mediaQueries.mdUp} {
          right: auto;
        }
      }
    }
  }
  @media ${mediaQueries.mdUp} {
    grid-template-columns: max-content;
    grid-template-areas:
      'logo title'
      '. content';
    grid-column-gap: 30px;
    padding-bottom: 50px;
  }
  @media ${mediaQueries.lgPlusUp} {
    padding-bottom: 58px;
  }
`;
const LogoContainer = styled.div`
  grid-area: logo;
`;
const IconContainer = styled.div`
  width: 34px;
  height: 34px;
  @media ${mediaQueries.mdUp} {
    width: 60px;
    height: 60px;
  }
`;
const Icon = styled.img`
  width: 100%;
`;
const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  grid-area: title;
  @media ${mediaQueries.mdUp} {
    font-size: 24px;
    line-height: 32px;
  }
`;
const Content = styled.div`
  grid-area: content;
  width: 100%;
`;
const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 16px;
  line-height: 22px;
  margin-top: 24px;
  margin-bottom: 8px;
  @media ${mediaQueries.mdUp} {
    font-size: 18px;
    line-height: 26px;
    margin-top: 0;
    margin-bottom: 6px;
  }
`;
const Tip = styled.p`
  color: ${({ theme }) => theme.colors.primaryLighter};
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  @media ${mediaQueries.mdUp} {
    font-size: 18px;
    line-height: 26px;
  }
`;
const TipHighlight = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

const SocialProfile = ({
  type, competitors, setCompetitors, hasPageConnected,
}) => (
  <Wrapper>
    <LogoContainer>
      <IconContainer>
        <Icon src={type === SOCIAL_MEDIA.facebook ? FacebookColorIcon : InstagramColorIcon} />
      </IconContainer>
    </LogoContainer>
    <Title className="title">
      <FormattedMessage
        id="competitors.socialprofile.title"
        defaultMessage="{socialNetwork} Competitors"
        values={{
          socialNetwork: type,
        }}
      />
    </Title>
    <Content>
      <Subtitle>
        <FormattedMessage
          id="competitors.socialprofile.subtitle"
          defaultMessage="Start typing the exact name of the competitor, including special characters ( like -_+ @ ) and select from the options displayed. <s>Only Business pages will be displayed.</s>"
          values={{
            s: (...chunks) => <strong>{chunks}</strong>,
          }}
        />
      </Subtitle>
      <Tip>
        <FormattedMessage
          id={
            type === SOCIAL_MEDIA.facebook
              ? 'competitors.socialprofile.tip.facebook'
              : 'competitors.socialprofile.tip.instagram'
          }
          defaultMessage='<h>TIP:</h> To find out if a page is valid, check to see if it has a "like" button.'
          values={{
            h: (...chunks) => <TipHighlight>{chunks}</TipHighlight>,
          }}
        />
      </Tip>
      <SearchCompetitors
        type={type.toLowerCase()}
        setCompetitors={setCompetitors}
        competitors={competitors}
        hasPageConnected={hasPageConnected}
      />
      <CompetitorsList
        type={type.toLowerCase()}
        competitors={competitors}
        setCompetitors={setCompetitors}
      />
    </Content>
  </Wrapper>
);

SocialProfile.propTypes = {
  type: PropTypes.string,
  competitors: PropTypes.instanceOf(Array).isRequired,
  setCompetitors: PropTypes.func.isRequired,
  hasPageConnected: PropTypes.bool.isRequired,
};

SocialProfile.defaultProps = {
  type: '',
};

export default SocialProfile;
