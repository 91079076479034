import React from 'react';
import styled from 'styled-components';
import { CrossMark } from '../../../assets/icons/iconRepository';
import GlobalTheme from '../../../styled/GlobalTheme';

const PricingSubTitle = styled.span`
  display: inline;
  font-weight: 700;
  color: ${(props) => props.theme.colors.black};
`;

export const PricingCancelPrice = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.gray90};
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background-image: url(${CrossMark});
    width: 24px;
    height: 24px;
    top: 7px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const PricingRedPrice = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-weight: 700;
`;

export const GreenIndicator = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.theme.colors.white)};
  background: ${(props) => (props.theme.colors.lightBlue)};
  padding: 5px 2px;
  border-radius: 3px;
`;

const GreenColor = styled.span`
  color: ${(props) => props.theme.colors.lightBlue};
  font-weight: 700;
`;

const GreenBackground = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.theme.colors.white)};
  background: ${(props) => (props.theme.colors.lightBlue)};
`;

const OrderedList = styled.ol`
  padding-inline-start: 12px;
  margin-left: 5px;
`;

const PricingInfo = styled.span`
  ${GlobalTheme.breakpoints.up('md')}{
    display: block;
    margin-bottom: -20px;
  }
`;

const intlPlanConversor = {
  title: (...chunks) => <PricingSubTitle>{chunks}</PricingSubTitle>,
  no: (...chunks) => <PricingCancelPrice>{chunks}</PricingCancelPrice>,
  red: (...chunks) => <PricingRedPrice>{chunks}</PricingRedPrice>,
  lineBreak: <br />,
  info: (...chunks) => <PricingInfo>{chunks}</PricingInfo>,
  sup: (...chunks) => <sup>{chunks}</sup>,
  green: (...chunks) => (<GreenColor>{chunks}</GreenColor>),
  greenBackground: (...chunks) => (<GreenBackground>{chunks}</GreenBackground>),
  greenIndicator: (...chunks) => <GreenIndicator>{chunks}</GreenIndicator>,
  up: (...chunks) => <sup>{chunks}</sup>,
  p: (...chunks) => <p>{chunks}</p>,
  ol: (...chunks) => <OrderedList>{chunks}</OrderedList>,
  li: (...chunks) => <li>{chunks}</li>,
  strong: (...chunks) => <strong>{chunks}</strong>,
  small: (...chunks) => <small>{chunks}</small>,
};

export default intlPlanConversor;
