import reducerMapper from "../../helpers/reducerMapper";

export const reducer = (state, action) => {
  switch (action.type) {
    case "change_period":
      return reducerMapper(state, action, {
        anyfunction: (pkg) => {
          let price = pkg.price;

          if (
            pkg.period === "annually" &&
            action.payload.period === "monthly"
          ) {
            price = price / 10;
          }
          if (
            pkg.period === "monthly" &&
            action.payload.period === "annually"
          ) {
            price = price * 10;
          }

          return {
            ...pkg,
            price,
            period: action.payload.period,
          };
        },
        iffunction: undefined,
        elsefunction: undefined,
      });
    case "set_selected":
      return reducerMapper(state, action, {
        iffunction: (pkg) => ({
          ...pkg,
          checked: true,
        }),
        elsefunction: (pkg) => ({ ...pkg, checked: false }),
        anyfunction: undefined,
      });
    default:
      throw "unhandled action type!";
  }
};
