import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Fade,
  Backdrop,
  Modal,
} from '@material-ui/core';
import { LinkedinLogo } from '../../assets/social/socialRepository';
import BaseProfile from './BaseProfile';
import { LINKEDIN } from '../../utils/constants/globals';
import BaseInformation from './BaseInformation';
import { SOCIAL_MEDIA } from '../../views/Community/PostComments/constants';
import AdAccountsDescription from './facebook/AdAccountsDescription';
import SocialProfilesProvider from '../../services/entities/SocialProfilesProvider';
import CreateAdAccountModal from './linkedIn/CreateAdAccountModal';
import StepsAddPaymentModal from './linkedIn/StepsAddPaymentMethodModal';
import LinkedInModal from './linkedIn/linkedInModal'
import useCompany from '../../hooks/useCompany';

const LinkedInProfile = ({
  linkedinData,
  companyName,
  businessAssociationCb,
  comingSoon
}) => {
  const intl = useIntl();
  const [adAccounts, setAdAccounts] = useState([]);
  const [openCreateAdAccount, setOpenCreateAdAccount] = useState(false);
  const [openStepsPaymentModal, setOpenStepsPaymentModal] = useState(false);
  const [linkInModalOpen, setLinkInModalOpen] = useState(false);
  const [linkedInPages, setLinkedinPages] = useState({
    professional: {
      linkedinId: '',
      name: '',
      avatar: '',
    },
    pages: [],
  });
  const {companyId} = useCompany()

  const fetchAdAccounts = async () => {
    const response = await SocialProfilesProvider.fetchLinkedinAdAccount();
    if (response.success) {
      setAdAccounts(response.data);
    }
  };

  const handleCloseCreateAdAccount = () => setOpenCreateAdAccount(false);
  const handleCloseStepsPaymentModal = () => {
    setOpenStepsPaymentModal(false);
    window.location.reload();
  };

  useEffect(() => {
    if (linkedinData.isOrganization) {
      fetchAdAccounts();
    }
  }, [linkedinData.pageName, linkedinData.isOrganization, companyId]);

  const validateLinkedInData = async () => {
    const linkedinPayload = await SocialProfilesProvider.initLinkedInData();
    if (linkedinPayload?.success) {
      setLinkedinPages(linkedinPayload.data.fetchLinkedInData);
      setLinkInModalOpen(true);

      setTimeout(() => {
        window.localStorage.removeItem('linkedinConnection');
      }, 3000);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (window.localStorage.getItem('linkedinConnection')) {
      validateLinkedInData();
    }
  }, []);

  return (
    <>
      {(linkInModalOpen) && (
        <LinkedInModal
          linkedInPages={linkedInPages}
          open={linkInModalOpen}
          setOpen={setLinkInModalOpen}
          updateLiAdAccounts={fetchAdAccounts}
        />
      )}
      {(!!linkedinData.pageName) && (
        <Modal
          open={openCreateAdAccount}
          onClose={handleCloseCreateAdAccount}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >

          <Fade in={openCreateAdAccount}>
            <CreateAdAccountModal
              onCloseModal={handleCloseCreateAdAccount}
              setOpenStepsPaymentModal={setOpenStepsPaymentModal}
            />
          </Fade>
        </Modal>
      )}
      {(!!linkedinData.pageName) && (
        <Modal
          open={openStepsPaymentModal}
          onClose={handleCloseStepsPaymentModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openStepsPaymentModal}>
            <StepsAddPaymentModal onCloseModal={handleCloseStepsPaymentModal} />
          </Fade>
        </Modal>
      )}
      <BaseProfile
        type={LINKEDIN}
        socialLogo={LinkedinLogo}
        isConnected={!!linkedinData.pageName}
        comingSoon={comingSoon}
      >
        <BaseInformation
          avatar={linkedinData.avatar}
          pageName={linkedinData.pageName}
          requireAttention={linkedinData.requireAttention}
          type={LINKEDIN}
          socialLogo={LinkedinLogo}
          setLinkedinPages={setLinkedinPages}
          setLinkInModalOpen={setLinkInModalOpen}
          connectionTitle={intl.formatMessage(
            {
              id: companyName
                ? 'socialProfiles.connectionTitle'
                : 'socialProfiles.connectionTitle.noCompanyName',
              defaultMessage: "Connect {companyName}'s {socialNetwork} account",
            },
            {
              companyName,
              socialNetwork: SOCIAL_MEDIA.linkedin,
            },
          )}
          hint={intl.formatMessage({
            id: 'socialProfiles.hint.oneAccount',
            defaultMessage: 'At the moment you can connect 1 account',
          })}
          businessAssociationCb={businessAssociationCb}
        >
          {linkedinData.pageName && !linkedinData.requireAttention&& linkedinData.isOrganization && (
            <AdAccountsDescription
              adAccounts={adAccounts}
              setAdAccounts={setAdAccounts}
              fetchAdAccounts={fetchAdAccounts}
              socialNetwork={LINKEDIN}
              setOpenCreateAdAccount={setOpenCreateAdAccount}
              setOpenStepsPaymentModal={setOpenStepsPaymentModal}
            />
          )}
        </BaseInformation>
      </BaseProfile>
    </>
  );
};

LinkedInProfile.propTypes = {
  linkedinData: PropTypes.instanceOf(Object).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default LinkedInProfile;
