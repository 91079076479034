export const reducer = (state, action) => {
  /** @type {{ [key: string]: () => any[] }} */
  const handlers = {
    init() {
      if (!Array.isArray(action.payload)) return; // type check

      const collaborators = action.payload.map((collaborator) => {
        const { __typename, ...collaboratorRest } = collaborator;

        collaboratorRest.assignments = collaboratorRest.assignments.map((assignment) => {
          const { __typename, ...assignmentRest } = assignment;
          return { ...assignmentRest };
        });

        return {
          ...collaboratorRest,
          draft: false,
          collapsed: true,
        };
      });

      return collaborators;
    },

    alter_draft_collaborator() {
      if (Array.isArray(action.payload)) return; // type check

      let { path, value, id: collaboratorId } = action.payload;

      const collaborators = state.map((collaborator) => {
        if (collaborator.id === collaboratorId) {
          return _deepCollabCloning({ ...collaborator }, { path, value });
        }

        return collaborator;
      });

      return [...collaborators];
    },

    create_collaborator() {
      if (Array.isArray(action.payload)) return; // type check

      const { id, collaborator } = action.payload;

      const newCollaborator = {
        id,
        ...collaborator,
        draft: false,
        collapsed: true,
      };

      return [...state, { ...newCollaborator }];
    },

    destroy_collaborator() {
      if (Array.isArray(action.payload)) return; // type check

      const { id: collaboratorId } = action.payload;

      const collaborators = state.filter((collaborator) => collaborator.id !== collaboratorId);

      return [...collaborators];
    },

    collapse() {
      if (Array.isArray(action.payload)) return; // type check

      const { id: collaboratorId } = action.payload;

      const collaborators = state.map((collaborator) => {
        if (collaborator.id === collaboratorId) {
          return { ...collaborator, collapsed: !collaborator.collapsed };
        }

        return collaborator;
      });

      return [...collaborators];
    },

    cancel() {
      if (Array.isArray(action.payload)) return; // type check

      const collaborators = state.map((collaborator) => {
        if (collaborator.id === action.payload.id) {
          return { ...action.payload };
        }

        return collaborator;
      });

      return [...collaborators];
    },
  };

  return (handlers[action.type] || (() => []))();
};

export const _deepCollabCloning = (o, { path, value }) => {
  const match = path.match(/\[(\d+)\]/);
  const assignments = [...o.assignments];

  if (match === null) {
    return {
      ...o,
      [path]: value,
    };
  }

  const [, num] = match;
  const [, key] = path.split('].');
  const i = Number(num);

  if (['hasPost', 'hasBoost', 'hasCommunity'].includes(key)) {
    for (const assignment of assignments) {
      assignment[key] = value;
    }
  } else {
    assignments[i][key] = value;
  }

  return {
    ...o,
    assignments: [...assignments],
  };
};
