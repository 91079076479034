import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchSelect from './SearchSelect';
import mediaQueries from '../../../../../utils/mediaQueries';
import CompetitorsProvider from '../../../../../services/entities/CompetitorsProvider';
import { FACEBOOK, INSTAGRAM } from '../../../../../utils/constants/globals';
import { AlertsContext } from '../../../../../contexts/AlertsContext';
import useCallGA from '../../../../../hooks/useCallGA';

const Container = styled.div`
  margin-top: 40px;
  @media ${mediaQueries.mdUp} {
    display: flex;
    align-items: baseline;
    margin-top: 34px;
  }
`;
const SearchContainer = styled.div``;
const HelpText = styled.span`
  color: ${({ theme }) => theme.colors.gray02};
  display: block;
  font-size: 16px;
  line-height: 22px;
  margin-top: 6px;
  @media ${mediaQueries.mdUp} {
    font-size: 18px;
    line-height: 26px;
    margin-top: 0;
  }
`;
const SearchCompetitors = ({
  type, setCompetitors, competitors, hasPageConnected,
}) => {
  const [selectedCompetitor, setSelectedCompetitor] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const { showBackendError } = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);
  const callGA = useCallGA();
  const intl = useIntl();

  const filterCompetitors = async (inputValue) => {
    const response = await CompetitorsProvider.search(inputValue, type);
    if (!response.success) return [];
    return response.data;
  };

  const handleCompetitorChange = async (_, value) => {
    setSelectedCompetitor(value);
    if (value) {
      setLoading(true);
      let addMethod = CompetitorsProvider.addInstagramCompetitor;
      if (type === FACEBOOK) {
        addMethod = CompetitorsProvider.addFacebookCompetitor;
      }
      const response = await addMethod(value, competitors);
      if (!response.success) {
        showBackendError(response.message);
        setLoading(false);
      } else {
        setCompetitors(response.data);
        setSelectedCompetitor('');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (type === FACEBOOK) {
      if (!hasPageConnected) {
        setPlaceholder(intl.formatMessage({
          id: 'competitors.search.facebook.disabled.placeholder',
          defaultMessage: 'First connect your Facebook Business page',
        }));
      }
    } else if (type === INSTAGRAM) {
      if (!hasPageConnected) {
        setPlaceholder(intl.formatMessage({
          id: 'competitors.search.instagram.disabled.placeholder',
          defaultMessage: 'Please connect your Instagram business account',
        }));
      }
    }
  }, [hasPageConnected, type]);

  return (
    <Container>
      <SearchContainer>
        <SearchSelect
          value={selectedCompetitor}
          onChange={handleCompetitorChange}
          isSearchable
          isClearable
          isAsync
          isLoading={loading}
          isDisabled={!hasPageConnected}
          loadOptions={filterCompetitors}
          placeholder={placeholder}
          onMenuClose={() => {
            callGA({ action: 'Add a competitor', label: '(on social profile)' });
          }}
        />
        {hasPageConnected && (
          <HelpText>
            <FormattedMessage
              id="competitors.socialprofile.search.helptext"
              defaultMessage="For example type “CocaCola_Official”"
            />
          </HelpText>
        )}
      </SearchContainer>
    </Container>
  );
};

SearchCompetitors.propTypes = {
  type: PropTypes.string.isRequired,
  setCompetitors: PropTypes.func.isRequired,
  competitors: PropTypes.instanceOf(Array).isRequired,
  hasPageConnected: PropTypes.bool.isRequired,
};

export default SearchCompetitors;
