import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { container, heroTitle } from '../../styled/mixins';
import { colors } from '../../styled/theme';
import GlobalTheme from '../../styled/GlobalTheme';
import Pipers from '../../components/Pipers/Pipers';

const Hero = styled.div`
  min-height: 216px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: ${(props) => props.theme.space * 4}px;
  background: ${colors.secondary};
`;

const GridContainer = styled.div`
  ${container}
  display: grid;
  grid-template-columns: 1fr 2fr;
  ${GlobalTheme.breakpoints.down('sm')}{
    grid-template-columns: 1fr;
    grid-template-rows: 49px 124px 30px;
    grid-gap: ${(props) => props.theme.space * 2}px;
  }
`;

const Title = styled.h1`
  ${heroTitle}
`;

const CustomPiperSettings = styled(Pipers.Settings)`
  height: 135px;
  width: auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin-top: ${(props) => props.theme.space * -1}px;
    height: 124px;
    padding: 0 ${(props) => props.theme.space}px;
  }
`;

const SettingsHero = () => (
  <Hero alignItems="flex-end">
    <GridContainer>
      <Title>
        <FormattedMessage
          id="k.settings"
          defaultMessage="Settings"
        />
      </Title>
      <CustomPiperSettings />
    </GridContainer>
  </Hero>
);

export default SettingsHero;
