import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Dialog } from '@material-ui/core';
import { LinkedinProvider, SocialProfilesProvider } from '../../../services/entities';
import { piper } from '../../../assets/illustrations/illustrationRepository';
import GlobalTheme, { breakpointValues } from '../../../styled/GlobalTheme';
import { Button } from '../../buttons';
import Avatar from '../../Avatar';
import { AlertsContext } from '../../../contexts/AlertsContext';
import { SocialContext } from '../../../contexts/SocialContext';
import colors from '../../../styled/colors';

const CustomDialog = styled(Dialog)`
&&{
    .MuiPaper-root{
      width: ${({ itemWidth }) => (itemWidth)}px;
      max-width: 1016px;
      max-height: 90%;
      height: fit-content;
    }
  }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 47px 25px 0 25px;
  ${GlobalTheme.breakpoints.down('md')}{
    margin: 47px 100px 0 100px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 20px;
    flex-direction: column;
    margin: 47px 0 0 0;
  }
`;

const Title = styled.h2`
font-weight: 600;
font-size: 30px;
line-height: 44px;
text-align: center;
${GlobalTheme.breakpoints.down('md')}{
  line-height: 100%;
  width: 340px;
  padding: 0 32px 10px 32px;
}
${GlobalTheme.breakpoints.down('sm')}{
  font-size: 20px;
  width: 100%;
  padding: 0 20px 10px 20px;
}
`;

const ImageBox = styled.div`
  height: 80px;
  width: 80px;
  margin-right: 10px;
  ${GlobalTheme.breakpoints.down('sm')}{
    height: 60px;
    width: 123px;
    margin-right: 0px;
    margin-bottom: 18px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 47px 0;
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.down('md')}{
    padding: 30px 90px 47px 90px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    padding: 20px 20px 47px 20px;
  }
`;

const Box = styled.div`
  width: 49%;
  padding: 30px 62px 0 62px;
  ${GlobalTheme.breakpoints.down('md')}{
    width: 100%;
    border-top: 1px solid ${({ theme }) => (theme.colors.grayE9)};
    padding: 25px 0 0 0;
  }
  ${GlobalTheme.breakpoints.down('md')}{
    padding: 20px 0 0 0;
  }
`;

const Subtitle = styled.h3`
  font-size: 18px;
  line-height: 26px;
  text-align: ${({ center }) => (center ? 'center' : 'start')};
  color:${({ theme }) => (theme.colors.lightPurple)};
  font-weight: bold;
  margin-bottom: 5px;
  ${GlobalTheme.breakpoints.down('sm')}{
    text-align: center;
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  height: 120px;
  text-align: ${({ center }) => (center ? 'center' : 'start')};
  ${GlobalTheme.breakpoints.down('md')}{
    height: auto;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    text-align: center;
  }
`;

const BoxLine = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
  padding: 50px 0 0 0;
  ${GlobalTheme.breakpoints.down('md')}{
    display: none;
  }
`;
const Line = styled.div`
 height: 233px;
 border-left: 1px solid ${({ theme }) => (theme.colors.grayE9)};
`;

const Page = styled.div`
  margin: 15px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: ${({ center }) => (center ? 'center' : 'start')};
  ${GlobalTheme.breakpoints.down('sm')}{
    justify-content: center;
  }
`;

const PageTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
  ${GlobalTheme.breakpoints.down('md')}{
    width: 270px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    width: fit-content;
    max-width: 150px;
    text-align: center;
  }
`;
const OnePageTitle = styled.div`
font-weight: 600;
font-size: 24px;
line-height: 32px;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
max-width: 270px;
width: fit-content;
${GlobalTheme.breakpoints.down('sm')} {
  max-width: 150px;
  text-align: center;
}
`;

const OnePageBox = styled.div`
font-weight: 600;
font-size: 24px;
line-height: 32px;
${GlobalTheme.breakpoints.down('sm')}{
  text-align: center;
}
`;

const ProfesionalProfile = styled.div`
  padding: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonBox = styled.div`
  padding: 50px 0 0 0;
`;
const CustomAvatar = styled(Avatar)`
  margin-right: 8px;
`;
const PageInfoBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
    justify-content: center;
    padding: 15px 0;
  }
`;
const ColorTitle = styled.span`
  color: ${({ color }) => (color || '')};
`;

const OptionModal = styled.b`
  display: flex;
  &::before {
    content: "-";
    margin-right: 2px;
  }
`;

const LinkedInModal = ({
  linkedInPages, open, setOpen, updateLiAdAccounts,
}) => {
  const {
    socialNetworks,
    setSocialNetworks,
  } = useContext(SocialContext);
  const intl = useIntl();
  const { showError } = useContext(AlertsContext);
  const updateLinkedIn = async (isPersonalPage, linkedinId, name, image) => {
    // eslint-disable-next-line max-len
    const result = await LinkedinProvider.updateSelectedPage(isPersonalPage, linkedinId, name, image);
    const { success } = result;
    let avatar = '';
    let pageName = '';
    let isOrganization = false;
    if (!success) {
      showError(intl.formatMessage({
        id: 'alert.error.updateLinkedIn',
        defaultMessage: "We couldn't connect you linkedIn account. Please try again or contact support@socialpiper.com.",
      }));
      await SocialProfilesProvider.disconnect('linkedin', socialNetworks);
    } else {
      avatar = isPersonalPage ? linkedInPages.professional.avatar : image;
      pageName = isPersonalPage ? linkedInPages.professional.name : name;
      isOrganization = !isPersonalPage;
    }
    setSocialNetworks({
      ...socialNetworks,
      linkedin: {
        avatar,
        pageName,
        isOrganization,
      },
    });
    if (updateLiAdAccounts) {
      updateLiAdAccounts();
    }
    setOpen(false);
  };
  return (
    <Media queries={{
      sm: `(max-width: ${breakpointValues.md - 1}px)`,
      md: `(max-width: ${breakpointValues.lg - 1}px)`,
      lg: `(min-width: ${breakpointValues.lg}px)`,
    }}
    >
      {(matches) => {
        let itemWidth = linkedInPages.pages.length ? 1016 : 600;
        if (matches.md) itemWidth = linkedInPages.pages.length ? 690 : 500;
        if (matches.sm) itemWidth = 310;
        return (
          <CustomDialog
            itemWidth={itemWidth}
            open={open}
          >
            <Container>
              <Header>
                <ImageBox>
                  <Image
                    src={piper.Piper1}
                  />
                </ImageBox>
                <Title>
                  {linkedInPages.pages.length ? (
                    <FormattedMessage
                      id="socialProfiles.linkedIn.modal.title"
                      defaultMessage="Select the <r>LinkedIn account</r> you want to use:"
                      values={{
                        r: (...chunks) => (<ColorTitle color={colors.blueLi}>{chunks}</ColorTitle>),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="socialProfiles.linkedIn.modal.titleOnePage"
                      defaultMessage="Take note!"
                    />
                  )}
                </Title>
              </Header>
              <Body>
                {linkedInPages.pages.length ? (
                  <>
                    <Box>
                      <Subtitle>
                        <FormattedMessage
                          id="socialProfiles.linkedIn.modal.profeProfile"
                          defaultMessage="LinkedIn Personal Profiles:"
                        />
                      </Subtitle>
                      <Text>
                        <FormattedMessage
                          id="socialProfiles.linkedIn.modal.accountText"
                          defaultMessage="With this type of account, you’ll be able to:"
                        />
                        <br />
                        <OptionModal>
                          <FormattedMessage
                            id="socialProfiles.linkedIn.modal.option1"
                            defaultMessage="Create posts"
                          />
                        </OptionModal>
                        <OptionModal>
                          <FormattedMessage
                            id="socialProfiles.linkedIn.modal.option4"
                            defaultMessage="Have recommendations on what day and time to publish your posts."
                          />
                        </OptionModal>
                      </Text>
                      <Page>
                        <PageInfoBox>
                          <CustomAvatar
                            src={linkedInPages.professional.avatar || ''}
                            fullName={linkedInPages.professional.name}
                          />
                          <PageTitle hasNotPages={!!linkedInPages.pages.length}>
                            {linkedInPages.professional.name}
                          </PageTitle>
                        </PageInfoBox>
                        <Button onClick={() => updateLinkedIn(true)}>
                          <FormattedMessage
                            id="k.connect"
                            defaultMessage="Connect"
                          />
                        </Button>
                      </Page>
                    </Box>
                    <BoxLine>
                      <Line />
                    </BoxLine>
                    <Box>
                      <Subtitle>
                        <FormattedMessage
                          id="socialProfiles.linkedIn.modal.business"
                          defaultMessage="LinkedIn Business profiles:"
                        />
                      </Subtitle>
                      <Text>
                        <FormattedMessage
                          id="socialProfiles.linkedIn.modal.accountText"
                          defaultMessage="With this type of account, you’ll be able to:"
                        />
                        <br />
                        <OptionModal>
                          <FormattedMessage
                            id="socialProfiles.linkedIn.modal.option1"
                            defaultMessage="Create posts"
                          />
                        </OptionModal>
                        <OptionModal>
                          <FormattedMessage
                            id="socialProfiles.linkedIn.modal.option4"
                            defaultMessage="Have recommendations on what day and time to publish your posts."
                          />
                        </OptionModal>
                        <OptionModal>
                          <FormattedMessage
                            id="socialProfiles.linkedIn.modal.option5"
                            defaultMessage="Easily answer the comments from your posts."
                          />
                        </OptionModal>
                      </Text>
                      {linkedInPages.pages.map((page) => (
                        <Page key={page.linkedinId}>
                          <PageInfoBox>
                            <CustomAvatar
                              src={page.avatar || ''}
                              fullName={page.name}
                            />
                            <PageTitle>
                              {page.name}
                            </PageTitle>
                          </PageInfoBox>
                          <Button
                            disabled={page.alreadyTaken}
                            onClick={() => updateLinkedIn(false, page.linkedinId, page.name, page.avatar || '')}
                          >
                            <FormattedMessage
                              id="k.connect"
                              defaultMessage="Connect"
                            />
                          </Button>
                        </Page>
                      ))}
                    </Box>
                  </>
                ) : (
                  <ProfesionalProfile>
                    <OnePageBox>
                      <Subtitle center>
                        <FormattedMessage
                          id="socialProfiles.linkedIn.modal.profeProfile"
                          defaultMessage="LindekIn Professional Profiles"
                        />
                      </Subtitle>
                      <Text center>
                        <FormattedMessage
                          id="socialProfiles.linkedIn.modal.accountText"
                          defaultMessage="With this type of account, you’ll be able to:"
                        />
                        <br />
                        <b>
                          <FormattedMessage
                            id="socialProfiles.linkedIn.modal.option1"
                            defaultMessage="- Create posts"
                          />
                        </b>
                      </Text>
                    </OnePageBox>
                    <Page>
                      <PageInfoBox>
                        <CustomAvatar
                          src={linkedInPages.professional.avatar || ''}
                          fullName={linkedInPages.professional.name}
                        />
                        <OnePageTitle>
                          {linkedInPages.professional.name}
                        </OnePageTitle>
                      </PageInfoBox>
                    </Page>
                    <ButtonBox>
                      <Button onClick={() => updateLinkedIn(true)}>
                        <FormattedMessage
                          id="k.ok"
                          defaultMessage="Ok"
                        />
                      </Button>
                    </ButtonBox>
                  </ProfesionalProfile>
                )}
              </Body>
            </Container>
          </CustomDialog>
        );
      }}
    </Media>
  );
};

LinkedInModal.propTypes = {
  linkedInPages: PropTypes.oneOfType([PropTypes.shape({
    professional: PropTypes.string,
    avatar: PropTypes.string,
    pages: PropTypes.oneOfType([PropTypes.shape({
      linkedinId: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.string,
    }), PropTypes.func]),
  }), PropTypes.func]).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  updateLiAdAccounts: PropTypes.func.isRequired,
};

export default LinkedInModal;
