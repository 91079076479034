/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import GliderComponent from 'react-glider-carousel';
import styled from 'styled-components';
import { ChevronLeftIcon, ChevronRightIcon } from '../../assets/icons/iconRepository';

const GliderWrapper = styled.div`
  .glider-contain {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .glider {
    margin: 0 auto;
    position: relative;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    transform: translateZ(0);
  }
  .glider-track {
    transform: translateZ(0);
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
  }
  .glider.draggable {
    user-select: none;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .glider.draggable .glider-slide img {
    user-select: none;
    pointer-events: none;
  }
  .glider.drag {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .glider-slide {
    user-select: none;
    justify-content: center;
    align-content: center;
    width: 100%;
  }
  .glider-slide img {
    max-width: 100%;
  }
  .glider::-webkit-scrollbar {
    opacity: 0;
    height: 0;
  }
  .glider-prev,.glider-next {
    text-indent: -9999px;
    width: 36px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;
    position: absolute;
    outline: none;
    background-color: rgba(246, 246, 246, 0.75);
    border-radius: 50%;
    z-index: 2;
    font-size: 40px;
    text-decoration: none;
    left: -23px;
    border: 0;
    top: 30%;
    cursor: pointer;
    color: #666;
    opacity: 1;
    transition: opacity .5s cubic-bezier(.17,.67,.83,.67),
                color .5s cubic-bezier(.17,.67,.83,.67);
  }
  .glider-prev{
    background-image: url(${ChevronLeftIcon});
    padding: 0;
    position: absolute;
    width: 36px;
    height: 36px;
    left: 23px!important;
    right: auto!important;
    margin-left: -22px;
    top: calc(50% - 20px);
  }
  .glider-next{
    background-image: url(${ChevronRightIcon});
    padding: 0;
    width: 36px;
    height: 36px;
    position: absolute;
    right: 23px!important;
    left: auto!important;
    margin-right: -22px;
    top: calc(50% - 20px);
  }
  .glider-prev:hover,
  .glider-next:hover,
  .glider-prev:focus,
  .glider-next:focus {
    color: #a89cc8;
  }
  .glider-next {
    right: -23px;
    left: auto;
  }
  .glider-next.disabled,
  .glider-prev.disabled {
    opacity: .25;
    color: #666;
    cursor: default;
  }
  .glider-slide {
    min-width: 100px;
  }
  .glider-hide {
    opacity: 0;
  }
  .glider-dots {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
  }
  .glider-dot {
    background: none;
    border: 0;
    padding: 0;
    user-select: none;
    outline: none;
    display: block;
    cursor: pointer;
    color: #ccc;
    border-radius: 999px;
    background: #ccc;
    width: 12px;
    height: 12px;
    margin: 7px;
  }
  .glider-dot:hover,
  .glider-dot:focus,
  .glider-dot.active {
    background: #a89cc8;
  }
  @media(max-width: 36em){
    .glider::-webkit-scrollbar {
      opacity: 1;
      -webkit-appearance: none;
      width: 7px;
      height: 3px;
    }
    .glider::-webkit-scrollbar-thumb {
      opacity: 1;
      border-radius: 99px;
      background-color: rgba(156, 156, 156, 0.25);
      box-shadow: 0 0 1px rgba(255,255,255,.25);
    }
  }
`;
export default function GliderCarousel({
  children, settings, className, ...props
}) {
  return (
    <GliderWrapper className={className}>
      <GliderComponent
        hasArrows
        settings={{
          draggable: true,
          exactWidth: true,
          slidesToScroll: 1,
          ...settings,
        }}
        {...props}
      >
        {children}
      </GliderComponent>
    </GliderWrapper>
  );
}

GliderCarousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  settings: PropTypes.instanceOf(Object),
};

GliderCarousel.defaultProps = {
  children: null,
  settings: null,
  className: '',
};
