import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationModal from '../../../../../components/notifications/Modal';
import PricingBanner from '../../../../CreateAccount/PricingBanner';

const StyledPricingModal = styled(NotificationModal)`
  && {
    .MuiPaper-root{
      min-width: 0;
      max-width: 350px;
      padding: 0;
    }
  }
`;

const Container = styled.div`
  overflow: scroll;
`;

const PricingModal = ({ open, setOpen }) => (
  <StyledPricingModal
    open={open}
    setOpen={setOpen}
  >
    <Container>
      <PricingBanner isModal />
    </Container>
  </StyledPricingModal>
);

PricingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default PricingModal;
