export const availableCurrencies = [
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'BRL',
    label: 'BRL',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
  {
    value: 'DKK',
    label: 'DKK',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'HKD',
    label: 'HKD',
  },
  {
    value: 'INR',
    label: 'INR',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'NOK',
    label: 'NOK',
  },
  {
    value: 'NZD',
    label: 'NZD',
  },
  {
    value: 'SEK',
    label: 'SEK',
  },
  {
    value: 'SGD',
    label: 'SGD',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'ZAR',
    label: 'ZAR',
  },
  {
    value: 'CNY',
    label: 'CNY',
  },
];
