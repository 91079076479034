import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ButtonLink, Button } from '../buttons';
import { SocialProfilesProvider } from '../../services/entities';
import {
  FACEBOOK, TWITTER, INSTAGRAM, LINKEDIN, MULTISELECTOR, TIKTOK,
} from '../../utils/constants/globals';
import { SocialContext } from '../../contexts/SocialContext';
import { AlertsContext } from '../../contexts/AlertsContext';
import { LayoutContext } from '../../contexts/LayoutContext';
import AuthService from '../../services/auth/AuthService';
import { authAppSync } from '../../services/auth';
import { authRedirect } from '../../graphql/mutations';
import {
  FB_PAGES_CONFIG_ID, IG_PAGES_CONFIG_ID, LINKEDIN_SCOPES, TIKTOK_SCOPES,
} from '../../utils/constants/scopes';
import socialLoginResultSubscription from '../../graphql/subscriptions';
import popupCenter from '../../utils/popupCenter';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import IgConnectInfoModal from './instagram/IgConnectInfoModal';
import { WebViewContext } from '../../contexts/WebViewContext';
import { sleep } from '../../utils';

const StyledButton = styled(ButtonLink)`
  text-align: center;
`;

const ConnectButton = ({
  className,
  socialNetwork,
  isConnected,
  comingSoon,
  fetchAdAccounts,
  disabled,
  setLinkedinPages,
  setLinkInModalOpen,
  setShowReConnectFacebookModal,
  requireAttention,
  businessAssociationCb
}) => {
  const intl = useIntl();
  const { dataLayerPush } = useContext(AnalyticsContext);
  const {
    socialNetworks,
    fetchSocialNetworksData,
    openPageSelector,
  } = useContext(SocialContext);
  const { user } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const [igModalOpen, setIGModalOpen] = useState(false);
  const { pathname } = useLocation();
  const { showError, showBackendError } = useContext(AlertsContext);
  const { isApp } = useContext(WebViewContext);

  const {
    REACT_APP_FACEBOOK_APP_ID,
    REACT_APP_FACEBOOK_URL,
    REACT_APP_FACEBOOK_REDIRECT_URL,
    REACT_APP_LINKEDIN_URL,
    REACT_APP_LINKEDIN_APP_ID,
    REACT_APP_LINKEDIN_REDIRECT_URL,
    REACT_APP_TIKTOK_URL,
    REACT_APP_TIKTOK_CLIENT_KEY,
    REACT_APP_TIKTOK_CLIENT_SECRET,
    REACT_APP_TIKTOK_REDIRECT_URL,
  } = process.env;

  const handleClick = async (reconect) => {
    const path = pathname.slice(1).replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, ' ');
    const pathTitle = path.charAt(0).toUpperCase() + path.slice(1);

    if (businessAssociationCb && !reconect) {
      try {
        await businessAssociationCb(socialNetwork)
      } catch (error) {
        console.log("Error: ", error)
      }
    }

    dataLayerPush({
      pagePath: pathname,
      pathTitle,
      dataCategory: 'Connect Social network',
      dataAction: `connect ${socialNetwork}`,
      event: 'action',
      dataLabel: '(button)(Connect Social network)',
    });
    if (
      socialNetwork === INSTAGRAM
      && !!user.notifications.length
      && user.notifications[0].name === FACEBOOK
    ) {
      setShowReConnectFacebookModal(true);
    } else {
      setLoading(true);
      if (isConnected && !reconect) {
        await SocialProfilesProvider.disconnect(socialNetwork, socialNetworks);
        await fetchSocialNetworksData();
        if (requireAttention) {
          await sleep(1000);
          handleClick(true);
        }

        setLoading(false);
      } else {
        let url = '';
        if (socialNetwork === TWITTER) {
          const redirectResponse = await authAppSync.client.mutate({
            mutation: authRedirect,
          });
          url = redirectResponse.data.createTwitterAuthRedirect.url;
        } else if (socialNetwork === LINKEDIN) {
          const encodedClientData = btoa(JSON.stringify({
            userId: AuthService.getAuthID(),
          }));
          url = `${REACT_APP_LINKEDIN_URL}/oauth/v2/authorization?response_type=code&client_id=${REACT_APP_LINKEDIN_APP_ID}&redirect_uri=${REACT_APP_LINKEDIN_REDIRECT_URL}&scope=${LINKEDIN_SCOPES.join(',')}&state=${encodedClientData}`;
        } else if (socialNetwork === TIKTOK) {
          const encodedClientData = btoa(JSON.stringify({
            userId: AuthService.getAuthID(),
          }));
          const ramdomStr = Math.random().toString(36).substring(2);
          const codeChallenge = Buffer.from(ramdomStr).toString('base64');
          url = `${REACT_APP_TIKTOK_URL}?client_key=${REACT_APP_TIKTOK_CLIENT_KEY}&client_secret=${REACT_APP_TIKTOK_CLIENT_SECRET}&redirect_uri=${REACT_APP_TIKTOK_REDIRECT_URL}&response_type=code&scope=${TIKTOK_SCOPES.join(',')}&state=${encodedClientData}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        } else {
          const encodedClientData = btoa(JSON.stringify({
            userId: AuthService.getAuthID(),
            type: socialNetwork,
          }));
          const scopes = socialNetwork === FACEBOOK ? FB_PAGES_CONFIG_ID : IG_PAGES_CONFIG_ID;
          url = `${REACT_APP_FACEBOOK_URL}/dialog/oauth?client_id=${REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${REACT_APP_FACEBOOK_REDIRECT_URL}&config_id=${scopes}&state=${encodedClientData}`;
        }
        authAppSync.client.subscribe({
          fetchPolicy: 'no-cache',
          query: socialLoginResultSubscription,
          variables: {
            authId: AuthService.getAuthID(),
            network: socialNetwork,
          },
        }).subscribe(async ({ data }) => {
          if (data.socialLoginResult.network === socialNetwork) {
            let cancelUpdate = false;
            if (data.socialLoginResult.status) {
              if (data.socialLoginResult.network === FACEBOOK) {
                if (data.socialLoginResult.reason === MULTISELECTOR) {
                  cancelUpdate = true;
                  openPageSelector(FACEBOOK);
                } else {
                  await SocialProfilesProvider.initFacebookData();
                  await fetchAdAccounts();
                }
              }
              if (data.socialLoginResult.network === TWITTER) {
                SocialProfilesProvider.initTwitterData();
              }
              if (data.socialLoginResult.network === INSTAGRAM) {
                if (data.socialLoginResult.reason === MULTISELECTOR) {
                  cancelUpdate = true;
                  openPageSelector(INSTAGRAM);
                } else {
                  await SocialProfilesProvider.initInstagramData();
                }
              }
              if (data.socialLoginResult.network === LINKEDIN) {
                const linkedinPayload = await SocialProfilesProvider.initLinkedInData();
                if (linkedinPayload.success) {
                  setLinkedinPages(linkedinPayload.data.fetchLinkedInData);
                  setLinkInModalOpen(true);
                } else {
                  showBackendError(linkedinPayload.message);
                }
              }
              if (!cancelUpdate) {
                await fetchSocialNetworksData();
              }
            } else {
              showError(intl.formatMessage({
                id: 'alert.error.social.connection',
                defaultMessage: 'There was an error connecting your social media account',
              }));
            }
          }
          setLoading(false);
        }, (error) => {
          showError(error);
          fetchSocialNetworksData();
          setLoading(false);
        });
        popupCenter(url, isApp, socialNetwork);
      }
    }
  };

  const text = () => {
    if (isConnected) {
      if (!requireAttention) {
        return (
          <FormattedMessage
            id="k.disconnect"
            defaultMessage="Disconnect"
          />
        );
      }
      return (
        <FormattedMessage
          id="k.reconnect"
          defaultMessage="Reconnect"
        />
      );
    }
    return (
      <FormattedMessage
        id="k.connect"
        defaultMessage="Connect"
      />
    );
  };

  return (
    <>
      <StyledButton
        as={!isConnected && !comingSoon && Button}
        className={className}
        disabled={comingSoon || disabled}
        isLoading={loading}
        onClick={() => {
          if (!isConnected && socialNetwork === INSTAGRAM) {
            setIGModalOpen(true);
          } else {
            handleClick();
          }
        }}
      >
        {text()}
      </StyledButton>
      {(!isConnected && socialNetwork === INSTAGRAM) && (
        <IgConnectInfoModal
          isOpen={igModalOpen}
          setOpen={setIGModalOpen}
          cbFunc={handleClick}
        />
      )}
    </>
  );
};

ConnectButton.propTypes = {
  className: PropTypes.string,
  socialNetwork: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  fetchAdAccounts: PropTypes.func,
  setLinkedinPages: PropTypes.func,
  setLinkInModalOpen: PropTypes.func,
  setShowReConnectFacebookModal: PropTypes.func,
};

ConnectButton.defaultProps = {
  className: '',
  isConnected: false,
  comingSoon: false,
  disabled: false,
  fetchAdAccounts: () => { },
  setLinkedinPages: () => { },
  setLinkInModalOpen: () => { },
  setShowReConnectFacebookModal: () => { },
};

export default ConnectButton;
