import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationModal from '../../../../../components/notifications/Modal';
import BillingSectionApps from '../../../../onBoarding/BillingSectionApps';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import RoleContext from '../../../../../contexts/RolesContext';

const StyledSubscriptionAppsModal = styled(NotificationModal)`
  && {
    .MuiPaper-root{
      min-width: 400px;
      ${GlobalTheme.breakpoints.down('sm')}{
        width: 100%;
      }
    }
    .MuiDialog-paperFullScreen{
      ${GlobalTheme.breakpoints.down('sm')}{
              height: auto;
      }
    }
    
  }
`;

const Container = styled.div`
  width: 100%;
  height: 350px;
  padding: 0 24px;
  ${GlobalTheme.breakpoints.down('sm')}{
    height: 100%;
    padding: 0;
  }
`;

const SubscriptionAppsModal = ({ open, setOpen, fetchSubscription }) => {
  const { fetchAndUpdate } = useContext(RoleContext);

  return (
    <StyledSubscriptionAppsModal
      open={open}
      setOpen={setOpen}
    >
      <Container>
        <BillingSectionApps nextHandler={async () => {
          await fetchAndUpdate();
          await fetchSubscription();
          setOpen(false);
        }}
        />
      </Container>
    </StyledSubscriptionAppsModal>
  );
};

SubscriptionAppsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
};

export default SubscriptionAppsModal;
