import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TiktokLogo } from '../../assets/social/socialRepository';
import BaseProfile from './BaseProfile';
import { TIKTOK } from '../../utils/constants/globals';
import BaseInformation from './BaseInformation';
import { capitalize } from '../../utils';

const TiktokProfile = ({ tiktokData, companyName }) => {
  const intl = useIntl();

  return (
    <BaseProfile
      type={TIKTOK}
      socialLogo={TiktokLogo}
      isConnected={!!tiktokData.pageName}
    >
      <BaseInformation
        type={TIKTOK}
        socialLogo={TiktokLogo}
        avatar={tiktokData.avatar}
        hint={intl.formatMessage({ id: 'socialProfiles.connectionTitleTiktokDisable' })}
        pageName={tiktokData.pageName}
        connectionTitle={intl.formatMessage(
          {
            id: companyName
              ? 'socialProfiles.connectionTitle'
              : 'socialProfiles.connectionTitle.noCompanyName',
            defaultMessage: "Connect {companyName}'s {socialNetwork} account",
          },
          {
            companyName,
            socialNetwork: capitalize(TIKTOK),
          },
        )}
        requireAttention={tiktokData.requireAttention}
      />
    </BaseProfile>
  );
};

TiktokProfile.propTypes = {
  tiktokData: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    requireAttention: PropTypes.bool,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default TiktokProfile;
