import React from 'react';
import PropTypes from 'prop-types';
import { FacebookLogo } from '../../../assets/social/socialRepository';
import FacebookInformation from './FacebookInformation';
import MultiAccountSelector from './MultiAccountSelector';
import BaseProfile from '../BaseProfile';
import { FACEBOOK } from '../../../utils/constants/globals';

const FacebookProfile = ({
  facebookData,
  companyName,
  businessAssociationCb,
  comingSoon
}) => {
  const { toSetup, requireAttention } = facebookData;
  return (
    <>
      <BaseProfile
        type={FACEBOOK}
        socialLogo={FacebookLogo}
        isConnected={!!facebookData.pageName}
        comingSoon={comingSoon}
      >
        {!toSetup ? (
          <FacebookInformation
            avatar={facebookData.avatar}
            pageName={facebookData.pageName}
            companyName={companyName}
            requireAttention={requireAttention}
            businessAssociationCb={businessAssociationCb}
          />
        ) : (
          <MultiAccountSelector
            socialLogo={FacebookLogo}
            type={FACEBOOK}
          />
        )}
      </BaseProfile>
    </>
  );
};

FacebookProfile.propTypes = {
  facebookData: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    toSetup: PropTypes.bool,
    requireAttention: PropTypes.bool,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default FacebookProfile;
