import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { flexBox } from '../../styled/mixins';
import { colors } from '../../styled/theme';
import GlobalTheme from '../../styled/GlobalTheme';

const BaseProfileContainer = styled.div`
  ${flexBox}
  justify-content: flex-start;
  margin-top: 32px;
  align-items: center;
`;

const SocialLogoImg = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
  object-position: center;
  margin-right: 24px;
  margin-bottom: auto;
  ${(props) => (props.type === 'tiktok') && `
    width: 60px;
    height: 60px;
    margin-left: 6px;
  `}
  ${GlobalTheme.breakpoints.down('sm')}{
    display: none;
  }
`;

const ProfileInformation = styled.div`
  flex: 1;
  min-height: 72px;
  box-sizing: content-box;
  ${flexBox}
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.grayE9};
  ${(props) => props.comingSoon && `
    padding-bottom: 0;
    border-bottom: 0;
  `}
`;

const BaseProfile = ({
  type,
  socialLogo,
  comingSoon,
  children,
}) => (
  <BaseProfileContainer>
    <SocialLogoImg src={socialLogo} alt={type} type={type} />
    <ProfileInformation
      comingSoon={comingSoon}
    >
      {children}
    </ProfileInformation>
  </BaseProfileContainer>
);

BaseProfile.propTypes = {
  type: PropTypes.string.isRequired,
  socialLogo: PropTypes.string.isRequired,
  children: PropTypes.node,
  comingSoon: PropTypes.bool,
};

BaseProfile.defaultProps = {
  comingSoon: false,
  children: null,
};

export default BaseProfile;
