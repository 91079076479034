const es = {
  'community.comments.piperTip': 'Aún no hay comentarios en esta publicación ',
  'community.inbox.comments.piperTip': 'Las conversaciones con tus clientes se mopstrarán aquí. {LineBreak}¡Seleccione una y comience a interactuar! {LineBreak}Recuerda que tienes un período de 24 horas para responder al último mensaje de su cliente.',
  'community.conversation.cta.counter': '{hours} horas',
  'community.conversation.cta.text': 'Responde este mensaje en las siguientes',
  'community.conversation.cta.smalltext': 'Responde en las siguientes',
  'community.conversation.list.order.post.chronological': 'Cronológico',
  'community.conversation.list.order.post.unread': 'Comentarios sin responder',
  'community.conversation.list.search.placeholder': 'Buscar comentarios',
  'community.conversation.button': 'Marcar como leído todos mis comentarios',
  'community.facebookMessenger': 'Responde en Facebook Messenger',
  'community.inbox.noReply': 'Puedes responder mensajes directos a través de Social Piper hasta 24 horas <r>después de recibir el último mensaje de of {name}</r>.  No pierdas la conversación:',
  'community.markAsRead': 'Leído',
  'community.readMore': 'Ver comentario completo',
  'community.readLess': 'Ver menos',
  'community.noSocialMedia.piperTip': 'Conecta las redes sociales de tu empresa para ver los mensajes',
  'community.inbox.noSocialMedia.piperTip': 'Conecta las redes sociales de tu empresa para ver un feed de todos tus mensajes más recientes.',
  'community.piper.comment.quick': 'No dejes a tus clientes esperando por mucho tiempo. El responder pronto aumenta la confianza en la marca',
  'community.piper.comment.replyMore': 'Responde a más comentarios! Así tus clientes se mantienen interesados y aumenta la confianza en tu marca',
  'community.piper.comment.suggestedTime': 'Tiempo sugerido para responder',
  'community.post.card.addBoost': 'Convierte esta publicación en una campaña',
  'community.post.card.addBoost.again': 'Convierte en campaña nuevamente',
  'community.post.card.no-description': 'Descripción no disponible',
  'community.conversation.title': 'Publicaciones',
  'community.comments.title': 'Historial de comentarios',
  'community.details.title': 'Detalle',
  'community.inbox.title': 'Inbox',
  'community.massages.title': 'Historial de mensajes',
  'community.conversation.list.order.post.withComments': 'Publicaciones con comentarios',
  'community.post.commentsHeader.boost.button': 'Crear campaña',
  'community.config.title': 'Para entender más sobre tu negocio y generar mejores respuestas, por favor proporciona la siguiente información.',
  'community.config.subtitle': 'También podrás editar esta información en la sección Configuración.',
  'community.config.hint': 'Los campos marcados con un * son obligatorios:',
  'community.config.companyName.label': '¿Cuál es el nombre del negocio?',
  'community.config.companyName.placeholder': 'Ej. Pizzas Piper',
  'community.config.companyCategories.placeholder': 'Ej. Pizza place',
  'community.config.companyLocation.placeholder': 'Ej. Palo Alto, California',
  'community.config.products.label': 'Describe los productos y/o servicios de tu negocio. Puede agregar hasta 3 descripciones.',
  'community.config.products.placeholder': 'Ej. Pizzas entregadas a domicilio',
  'community.config.atentionHours.label': '¿Qué horarios de atención tiene tu negocio?',
  'community.config.atentionHours.placeholder': '',
  'community.config.emailContact.label': '¿Cuál es el e-mail para brindar atención a tus clientes?',
  'community.config.emailContact.placeholder': 'Ej. pizzaspiper@gmail.com',
  'community.config.phoneContact.label': '¿Cuál es el teléfono con el que atiendes a tus clientes?',
  'community.config.phoneContact.placeholder': 'Ej. 5555-555555',
  'community.config.addressContact.label': '¿Tienes una dirección física? Detállala aquí.',
  'community.config.addressContact.placeholder': 'Ej. Avenida Campeche #23 Col. Roma Norte, Ciudad de México ',
  'community.config.faqs.label': 'Por favor agrega las preguntas más frecuentes y sus respuestas. Puedes agregar hasta 3 elementos.',
  'community.config.faqs.placeholderQ': 'Ej. ¿Tiene costo el servicio a domicilio?',
  'community.config.faqs.placeholderA': 'Ej. El servicio a domicilio no tiene costo y la propina es opcional',
  'community.config.buttons.cancel': 'Cancelar',
  'community.config.buttons.submit': 'Guardar y continuar',
  'community.config.faqs.toast.success': '¡La configuración adicional para la empresa se ha guardado correctamente!'
};

export default es;
