const roles = {
  ADMIN_SOCIALPIPER: 'AS',
  BASIC: 'B',
  PRO: 'P',
  BUSINESS_MAIN: 'BM',
  BUSINESS_PLAN: 'BS',
  BUSINESS_CHILD: 'BC',
  LITE: 'L',
};

export default roles;
