import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { PiperBlockBussiness } from '../../../../../assets/icons/iconRepository';
import CardSectionBlock from '../../../../../components/CardSectionBlock';
import { ButtonLink } from '../../../../../components/buttons';
import RocketLoader from '../../../../../components/globals/RocketLoader';
import { AlertsContext } from '../../../../../contexts/AlertsContext';
import { LayoutContext } from '../../../../../contexts/LayoutContext';
import useCompany from '../../../../../hooks/useCompany';
import useOpenAI from '../../../../../hooks/useOpenAI';
import { UserProvider } from '../../../../../services/entities';
import BusinessProvider from '../../../../../services/entities/BusinessProvider';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import colors from '../../../../../styled/colors';
import { borderTop, flexBox, materialShadows } from '../../../../../styled/mixins';
import BusinessInformationForm from './BusinessInformationForm';
import CardInformationHeader from './CardInformationHeader';
import CompaniesProvider from '../../../../../business/services/CompanyProvider';

const BusinessInformation = ({ className, withoutCard, wasSaved }) => {
  const intl = useIntl();
  const {
    getAllCompanies,
    selectedCompany,
    setCompanyId,
    infoState: [businessInfo, setBusinessInfo],
  } = useCompany();
  const [loading, setLoading] = useState(false);
  const [isNewLogo, setIsNewLogo] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [listPending, setListPending] = useState([]);
  const { showAlert, showBackendError, showError } = useContext(AlertsContext);
  const { setBusinessImage } = useContext(LayoutContext);
  const { refetch } = useOpenAI();

  const messagesNotifications = useMemo(
    () => ({
      interest: {
        text: intl.formatMessage({
          id: 'settings.business.card.block.interest',
          defaultMessage: 'Add a category of your business.',
        }),
      },
      company: {
        text: intl.formatMessage({
          id: 'settings.business.card.block.company',
          defaultMessage: 'Add the name of your business.',
        }),
      },
      logo: {
        text: intl.formatMessage({
          id: 'settings.business.card.block.logo',
          defaultMessage: 'Upload your logo so I can apply it to the templates for you.',
        }),
      },
      location: {
        text: intl.formatMessage({
          id: 'settings.business.card.block.location',
          defaultMessage: 'Add the location of your business.',
        }),
      },
    }),
    [],
  );

  const handlePrefill = () => {
    // analytics
    // TODO: prefill info
  };

  const fetchNotification = async (cache) => {
    setShowBox(false);
    const response = await UserProvider.getListNotification(cache);
    if (response.success) {
      response.data.forEach((notification) => {
        if (notification.type === 2) {
          setShowBox(true);
          const messagePending = [];
          notification.dataPending.forEach((Pending) => {
            messagePending.push(messagesNotifications[Pending].text);
          });
          setListPending(messagePending);
        }
      });
    }
  };

  const handleSave = async () => {
    let avatar = null;

    const avatarHandler = async () => {
      if (!isNewLogo) return;
      const avatar = await BusinessProvider.uploadLogo(businessInfo);
      if (!avatar.success) {
        // showBackendError(avatar.message);
        return;
      }
      setBusinessImage(avatar.data);
      return avatar.data;
    };
    const companyHandler = async (avatar) => {
      const isNewEntry = 'draft' in selectedCompany && selectedCompany.draft;
      let company = null;
      let payload = businessInfo;

      if (isNewEntry) {
        setBusinessInfo((info) => {
          const { id, ...rest } = info;
          payload = { ...rest, logoUrl: avatar };
          return rest;
        });

        company = await BusinessProvider.create(payload);
      } else {
        company = await BusinessProvider.update(payload, avatar ?? businessInfo.logo);
      }

      if (!company.success) {
        showBackendError(company.message);
        return;
      }

      setBusinessInfo((info) => {
        const { id, draft, ...rest } = info;
        // turn draft company into a real company
        return { ...rest, id: company.data.id };
      });

      return company.data.id;
    };

    if (isNewLogo) {
      avatar = await avatarHandler();
    }

    await companyHandler(avatar)
      .then(async (companyId) => {
        if (wasSaved) wasSaved();
        await Promise.all([
          getAllCompanies(companyId),
          CompaniesProvider.setCurrentCompany(companyId),
          refetch(),
        ]);

        setCompanyId(companyId);
        localStorage.setItem('local:companyId', companyId);

        showAlert(
          intl.formatMessage(
            {
              id: 'alert.success.update.female',
              defaultMessage: '{item} updated successfully',
            },
            {
              item: intl.formatMessage({
                id: 'k.company',
                defaultMessage: 'Company',
              }),
            },
          ),
        );
      })
      .catch(() => {
        showError(
          intl.formatMessage({
            id: 'alert.error.company.update',
          }),
        );
      })
      .finally(() => {
        fetchNotification(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchNotification(true);
  }, []);

  if (!businessInfo) {
    return <RocketLoader />;
  }

  return (
    <BusinessInformationCard withoutCard={withoutCard} className={className}>
      {!withoutCard && (
        <>
          <CardInformationHeader
            title={intl.formatMessage({
              id: 'settings.business.businessInformation.title',
              defaultMessage: 'Business Information',
            })}
            advice={intl.formatMessage({
              id: 'settings.business.businessInformation.advice',
              defaultMessage:
                '“This content will help me provide insights and tips specifically tailored to your business”',
            })}
            prefill={intl.formatMessage({
              id: 'settings.business.businessInformation.prefill',
              defaultMessage: 'Pre-fill this data from your Facebook Business profile',
            })}
            handlePrefill={handlePrefill}
          />
          <CardSectionBlock
            showBox={showBox}
            setShowBox={setShowBox}
            title={intl.formatMessage({
              id: 'settings.business.card.block.title',
              defaultMessage: 'Complete your business profile!',
            })}
            message={
              listPending.length > 3 ? (
                <FormattedMessage
                  id='settings.business.card.block.message'
                  defaultMessage='Add the name, location, and category of your business.{br}Upload your logo so I can apply it to the templates for you.'
                  values={{
                    br: <br />,
                  }}
                />
              ) : (
                <ListMessage margin={listPending.length}>
                  {listPending.map((message) => (
                    <MessagePending>{message}</MessagePending>
                  ))}
                </ListMessage>
              )
            }
            textButton={intl.formatMessage({
              id: 'settings.business.card.block.button',
              defaultMessage: 'Complete your profile',
            })}
            piper={PiperBlockBussiness}
            waveBottomColor={colors.secondaryDark}
            backgroundColor={colors.lightBlue}
          />
        </>
      )}

      <BusinessInformationForm setIsNewLogo={setIsNewLogo} />
      <ButtonWrapper>
        <ButtonLink onClick={handleSave} isLoading={loading}>
          <FormattedMessage id='k.saveChanges' defaultMessage='Save Changes' />
        </ButtonLink>
      </ButtonWrapper>
    </BusinessInformationCard>
  );
};

BusinessInformation.propTypes = {
  className: PropTypes.string,
};

BusinessInformation.defaultProps = {
  className: '',
};

const BusinessInformationCard = styled.div`
  ${(props) =>
    !props.withoutCard &&
    css`
      ${materialShadows};
      ${borderTop('speechBubble')};
      background: ${({ theme }) => theme.colors.white};
      padding: 24px 32px;
      ${flexBox}
      flex-wrap: wrap;
      position: relative;
      ${GlobalTheme.breakpoints.down('md')} {
        padding: 24px 16px;
      }
    `}
`;

const ButtonWrapper = styled.div`
  flex: 0 0 100%;
  ${flexBox}
  justify-content: center;
`;

const ListMessage = styled.ul`
  padding-left: 0;
  margin-top: ${({ margin }) => (3 - margin) * 10}px;
`;

const MessagePending = styled.li`
  list-style: none;
`;

export default BusinessInformation;
