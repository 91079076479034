import gql from 'graphql-tag';

export const getPromos = (attr = `
  plans {
    id  
    name
    description
    isMonthly
    realPrice
    yearlyMonthlyPrice
    monthlyPrice
    isDefault
    currencyCode
    countryCode
    trialDuration
    defaultRoleId
  }
  isValid
  promocode
`) => gql`
  query getPromos($codePlan: String, $currencyCode: String, $countryCode: String) {
    getPromos(codePlan: $codePlan, currencyCode: $currencyCode, countryCode: $countryCode) {
      ${attr}
    }
  }
`;

export default {};
