const resizeAndEncode = (img, dimensions) => {
  const heightScale = img.height > dimensions.height ? dimensions.height / img.height : 1;
  const widthScale = img.width > dimensions.width ? dimensions.width / img.width : 1;
  const scale = Math.min(heightScale, widthScale);
  const dstHeight = img.height * scale;
  const dstWidth = img.width * scale;
  const dstCanvas = document.createElement('canvas');

  dstCanvas.height = dstHeight + 1;
  dstCanvas.width = dstWidth + 1;
  const ctx = dstCanvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, 0, 0, dstWidth, dstHeight);
  }
  return dstCanvas.toDataURL();
};

export default resizeAndEncode;
