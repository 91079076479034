const en = {
  "postflow.create.AddimageTittle": "Add Images",
  "postflow.create.alertMissingSocial": "Select at least one Social Network.",
  "postflow.create.caption": "Add Caption",
  "postflow.create.label.notInspired": "Not inspired?",
  "postflow.create.label.usePiper": "Use Piper",
  "postflow.create.CaptionsPlaceHolder": "Your caption goes here...",
  "postflow.create.facebookCheck": "Add Facebook",
  "postflow.create.instagramCheck": "Add Instagram",
  "postflow.create.missingCaptionEmpty": "The caption can't be empty.",
  "postflow.create.missing.image": "The image can't be empty",
  "postflow.create.caption.overflow":
    "In order to post to Facebook and/or Instagram, you need to reduce the number of characters to a maximum of 1,000.",
  "postflow.create.caption.overflow.twitter":
    "In order to post to Twitter, you need to reduce the number of characters to a maximum of 280.",
  "postflow.create.image.limit":
    "{socialNetwork} only allows up to {limit} images. Deselect some to create a multiple post.",
  "postflow.create.PreviewComment": "Comment",
  "postflow.create.PreviewJustNow": "Just now...",
  "postflow.create.PreviewLike": "Like",
  "postflow.create.PreviewPlaceholder": "Your caption goes here...",
  "postflow.create.PreviewShare": "Share",
  "postflow.create.tittle": "Create post",
  "postflow.create.twitterCheck": "Add Twitter",
  "postflow.create.where2Post": "Select where to post",
  "postflow.create.tooltip.imageIndicator.title":
    "Add multiple images to your post.",
  "postflow.create.tooltip.imageIndicator.text1":
    "You can add more than one image to your post. This visual guide allows you to see how many images you can upload depending on the network:",
  "postflow.create.tooltip.imageIndicator.limit.facebook":
    "On <HighlightNetwork>Facebook</HighlightNetwork>, you can upload up to <bold>9</bold> images.",
  "postflow.create.tooltip.imageIndicator.limit.instagram":
    "On <HighlightNetwork>Instagram</HighlightNetwork>, you can post <bold>1</bold> image.",
  "postflow.create.tooltip.imageIndicator.limit.twitter":
    "For <HighlightNetwork>Twitter</HighlightNetwork>, you can upload up to <bold>9</bold> images.",
  "postflow.create.tooltip.imageIndicator.limit.linkedin":
    "On <HighlightNetwork>LinkedIn</HighlightNetwork>, you can use <bold>9</bold> images per post.",
  "postflow.create.tooltip.imageIndicator.text2":
    "<HighlightText>The limit of images in your publication will depend on the networks you hace selected.</HighlightText> For example, if you select Instagram and Twitter, you can only post 1 image on the on the two networks.",
  "postflow.editor.accept": "Save",
  "postflow.editor.acceptPost": "Save and Post",
  "postflow.editor.alertSaveFail":
    "The image couldn't be saved. Please try again or contact support@socialpiper.com",
  "postflow.editor.cancel": "Cancel",
  "postflow.editor.continueButton": "Continue",
  "postflow.editor.noChangesWarning": "No Changes to save",
  "postflow.editor.sizeUploadFail": "Image size upload limit is 3 MB",
  "postflow.editor.saveSuccess": "The image was saved!",
  "postflow.editor.startSaveStatus": "Saving your template",
  "postflow.editor.tittle": "Edit your image",
  "postflow.editor.proMessage":
    "This is a Pro Plan feature. Edit the image, text, add new images, reposition your logo and more!",
  "postflow.editorModal.cancelButton": "Cancel",
  "postflow.editorModal.confirmButton": "Confirm",
  "postflow.editorModal.helperLenght": "Title cannot be empty",
  "postflow.editorModal.saveTittle": "Save your creation",
  "postflow.editorModal.titleForm": "Title",
  "postflow.editorModal.titlePlaceHolder": "Template title",
  "postflow.editorModal.titleTags": "Tags",
  "postflow.minilibrary.compacTitle": "CREATIVE CENTER",
  "postflow.minilibrary.pipertip":
    "Sorry. There are no templates that match your search. Try using similar words or concepts.",
  "postflow.minilibrary.placeholderMyCreations": "Search tag",
  "postflow.minilibrary.placeholderOtherImages": "Search ...",
  "postflow.minilibrary.typeCaptionPlaceholder":
    "Type something about the image",
  "postflow.minilibrary.buttonGenerateImage": "Generate",
  "postflow.minilibrary.placeholderTemplates": "Search",
  "postflow.minilibrary.tab1": "Templates",
  "postflow.minilibrary.tab2": "My Creations",
  "postflow.minilibrary.tab3": "Photos",
  "postflow.minilibrary.tab4": "Use Piper",
  "postflow.minilibrary.tab5": "Hospital Angeles",
  "postflow.minilibrary.title": "CREATIVE CENTER",
  "postflow.navbar.cancel": "Cancel",
  "postflow.navbar.step1": "SELECT IMAGES",
  "postflow.navbar.mobileStep1": "SELECT",
  "postflow.navbar.step2": "CREATE POST",
  "postflow.navbar.mobileStep2": "CREATE",
  "postflow.navbar.step3": "SCHEDULE IT",
  "postflow.navbar.mobileStep3": "SCHEDULE",
  "postflow.navbar.title": "New Post",
  "postflow.preview.edit": "Edit Image",
  "postflow.preview.regenerate": "Generate again",
  "postflow.preview.title": "Preview post",
  "postflow.preview.previewContainerText":
    "Social networks change constantly. Your post may appear slightly different when published.",
  "postflow.schedule.backButton": "Back",
  "postflow.schedule.basicRoleQuota":
    "You still have {posts} posts. Use them before {date}. Consider that each post regardless of the platform counts as one.  Do you want to publish unlimitedly?",
  "postflow.schedule.basicRoleQuota.noLeft":
    "You have reached your scheduled posts limit for this month. Do you want to program more, have more more content and feature?",
  "postflow.schedule.finishButton": "Publish",
  "postflow.schedule.withBoost.finishButton": "Post & Boost it!",
  "postflow.schedule.isMostActive": "This is when your audience is most active",
  "postflow.schedule.analyzing.audience": "“I’m analyzing your audience”",
  "postflow.schedule.msgModalPublished": "Published",
  "postflow.schedule.msgModalScheduled": "Scheduled",
  "postflow.schedule.msgModalApproval": "Awaiting Approval",
  "postflow.schedule.subtittleError":
    "Please, check if your social networks are connected and try again",
  "postflow.schedule.time2post": "Select a time to post",
  "postflow.schedule.titleBoost": "Boost",
  "postflow.schedule.titleFacebook": "Facebook",
  "postflow.schedule.titleInstagram": "Instagram",
  "postflow.schedule.titleModalFail": "Your post are ready!",
  "postflow.schedule.titleModalFailAll": "We were unable to create your post",
  "postflow.schedule.titleModalSuccess": "All your post are ready!",
  "postflow.schedule.titleModalSuccess.whith.stories": "We´re almost ready!",
  "postflow.schedule.titleWaitingModal": "I’m preparing your posts",
  "postflow.schedule.titleLazyingDoggo": "I´m generating your content.",
  "postflow.schedule.subtitleLazyingDoggo":
    "This could take up to several minutes. {br} Please be patient.",
  "postflow.schedule.subTitleWaitingModal": "Be patient with me!",
  "postflow.schedule.subTitleModalFail": "But there's a problem.",
  "postflow.schedule.warning":
    "Oops! We couldn't schedule one of your post. Looks like something went wrong with your <facebook>Facebook</facebook><instagram>Instagram</instagram><twitter>Twitter</twitter><linkedin>LinkedIn</linkedin> account. Please Try again. If the problem continues, please contact us at <WarningEmail>support@socialpiper.com</WarningEmail>",
  "postflow.schedule.titleTwitter": "Twitter",
  "postflow.schedule.titleLinkedin": "Linkedin",
  "postflow.schedule.tittle": "Schedule post",
  "postflow.schedule.wordNow": " Just Now",
  "postflow.schedule.alertMissingRequirement":
    "To continue, you need to <link>add a payment method to your Social Piper account.</link>",
  "postflow.schedule.setAudience":
    "Tell me about your audience and will recommend you the best Boost settings.",
  "postflow.ScheduleOptions.custom": "Custom",
  "postflow.ScheduleOptions.immediately": "Immediately",
  "postflow.ScheduleOptions.piperRecommendation": "Piper Recommends",
  "postFlow.selectImage.dragDropText":
    "Click here to upload an image or drag it from your device",
  "postFlow.selectImage.dragDropText.mobile":
    "Touch here to select an image from your device",
  "postflow.selectimage.pipermessage":
    "Choose a template or image from our Creative Center, or upload an image or video of your own!",
  "postflow.selectimage.tittle":
    "Select from Creative Center <s>{or}</s> Upload your visual content:",
  "postflow.selectimage.mobileTittle": "Select what to post",
  "postflow.selectimage.cancelButton": "Cancel add new image",
  "settings.create.advice":
    "“Connect all the social media profiles for your business to start posting”",
  "postflow.editor.WELCOME_TITLE":
    "How would you like to start creating your template?",
  "postflow.editor.WELCOME_LOADING_IMAGE": "Loading...",
  "postflow.editor.WELCOME_EMPTY_TEXT": "Start with a brand new canvas.",
  "postflow.editor.WELCOME_UPLOAD_TEXT": "I want to upload my own image.",
  "postflow.editor.WELCOME_UPLOAD_HELP":
    "You can drag & drop here a JPG or PNG file or just click here to select it from your device.",
  "postflow.editor.WELCOME_UPLOAD_FAIL":
    "Can drop only one file at the same time",
  "postflow.editor.HELP_EDIT_1": "To edit this element just double click",
  "postflow.editor.HELP_TEXT_INSERT":
    "Click on the canvas to place a text line",
  "postflow.editor.HELP_TEXT_TYPING":
    "Start typing, if you want finish click on another place",
  "postflow.editor.HELP_OBJECT_DRAW": "Click on the canvas to start drawing",
  "postflow.editor.HELP_IMAGE_DRAW": "Click on the canvas to place the image",
  "postflow.editor.HELP_POLYGON_CLOSE":
    "you don't want to close the polygon press enter.",
  "postflow.editor.PANEL_FONT_STYLE": "Font Style",
  "postflow.editor.PANEL_FONT_TEXT": "Text",
  "postflow.editor.PANEL_POSITION": "Position",
  "postflow.editor.PANEL_ROTATION": "Rotation",
  "postflow.editor.PANEL_SIZE": "Size:",
  "postflow.editor.PANEL_FILL": "Fill",
  "postflow.editor.PANEL_STROKE": "Stroke",
  "postflow.editor.PANEL_WIDTH": "Width:",
  "postflow.editor.PANEL_HEIGHT": "Height:",
  "postflow.editor.PANEL_BORDER_RADIUS": "Border Radius:",
  "postflow.editor.PANEL_OPACITY": "Opacity",
  "postflow.editor.PANEL_IMAGE": "Image",
  "postflow.editor.PANEL_IMAGE_DROP": "Click or Drop Here",
  "postflow.editor.PANEL_ARRANGE_STB": "Send to Back",
  "postflow.editor.PANEL_ARRANGE_STF": "Send to Front",
  "postflow.editor.WELCOME_UPLOAD_SIZE_FAIL": "File upload limit is",
  "postflow.boost.unactive.title": "It might not be enough to just publish.",
  "postflow.boost.multiImage.cta":
    "Soon you will be able to create Boosts with more than 1 image.",
  "postflow.boost.unactive.text":
    "Remember that the algorithm at Facebook, Instagram or other social media platforms give more visibility and results to a boosted post.{br}<bb>Now with 0% service fee for limited time!</bb>",
  "postflow.boost.unactive.cta": "Show me how!",
  "postflow.boost.unactive.again.title":
    "Next time, try to add a Boost to your post!",
  "postflow.boost.unactive.again.text":
    "As you saw, even with the minimum spent you can reach many people and get more clients.",
  "postflow.boost.unactive.again.cta": "Show me again!",
  "postflow.selectimage.pipermessage.mobil":
    "Select an image or photo from your device",
  "postflow.instagram.connect.modal.tip":
    "To connect your Instagram business account, <TipHighlight>first connect your Facebook business page.</TipHighlight>",
  "postflow.instagram.connect.modal.help":
    "Don’t have a Facebook business page?",
  "postflow.instagram.connect.modal.help.link": "Create one now",
  "postflow.instagram.connect.modal.tip.facebookConnected":
    "You can only connect 1 Instagram business account. <TipHighlight>You don't know if you have a business account? <TipLink>Review these steps.</TipLink></TipHighlight>",
  "postflow.instagram.connect.button": "Connect Instagram",
  "postflow.create.file.selected.not.permited": "Media file is not supported",
  "postflow.create.file.not.selected": "Media file not selected",
  "postflow.create.file.social.duration.max":
    "The video exceeds the length allowed by {socialNetwork}. Your video should not be longer than 1 min.",
  "postflow.create.file.twitter.duration.max":
    "The video exceeds the length allowed by Twitter. Your video should not be longer than 2 min. 15 sec.",
  "postflow.create.file.size.max":
    "The video exceeds the size allowed by Instagram. Your video should not be more than 100 MB.",
  "postflow.create.file.permited.type":
    "The format of your video is not supported. Only video in MOV, MP4 or M4V format are accepted.",
  "postflow.create.file.dimension.limit":
    "Instagram does not allow video dimensions, try to upload a video with other dimensions.",
  "postflow.schedule.progress.message.upload": "Uploading video",
  "postflow.schedule.progress.message.posting": "Posting your video",
  "postflow.schedule.progress.message.post.facebook": "Posting to Facebook",
  "postflow.schedule.progress.message.post.instagram": "Posting to Instagram",
  "postflow.schedule.progress.message.schedule.facebook": "Scheduling Facebook",
  "postflow.schedule.progress.message.schedule.instagram":
    "Scheduling Instagram",
  "postflow.schedule.progress.message.schedule.storiesig":
    "Scheduling Instagram Stories",
  "postFlow.selectImage.dragDropText.video": "Now you can upload videos too!",
  "postflow.create.file.selected.not.dimension":
    "Please check the format of your video",
  "postflow.create.modal.instagram.wanrning.title":
    "The format of your video is vertical and exceeds the size allowed by Instagram. When published, it will be cropped automatically to make it fit properly.",
  "postflow.create.modal.instagram.wanrning.title.mobile":
    "The format of your video is vertical and exceeds the size allowed by Instagram. When published, it will be cropped automatically to make {lineBreak}it fit properly.",
  "postflow.create.modal.instagram.wanrning.subtitle":
    "You can continue or try again with another video.",
  "postflow.create.modal.instagram.wanrning.subtitle.mobile":
    "You can continue or try again {lineBreak}with another video.",
  "postflow.create.upload.other.video": "Upload another video",
  "postflow.preview.video.warning":
    "This video preview hasn’t been cropped yet to fit on Instagram and it will adjust it when published. Please be sure that all the important content in the video it’s at the center of it.",
  "postFlow.schedule.progress.information":
    "This may take a while, according of how long is your video.",
  "postFlow.dragDrop.validation.mutimedia":
    "You can only post 1 video or images.",
  "postflow.create.modal.instagram.install.title":
    "Be sure to have the Instagram app installed in your device.",
  "postflow.create.modal.instagram.install.subtitle": "If you don’t have it, ",
  "postflow.create.modal.instagram.install.subtitle.link": "install here.",
  "postflow.schedule.publish.stories":
    "Now, lets go to publish your Instagram Story!",
  "postflow.create.placeholder.storiesIG": "Text is not required",
  "postflow.schedule.storie.message.default":
    "Let's post your scheduled Instagram story",
  "postflow.editor.modal.piper.text":
    "You haven't uploaded your logo yet.{lineBreak}Do it now! So you can view all the templates with your logo embedded.{lineBreak}",
  "postflow.editor.modal.piper.text.small":
    "You can change it later on Settings.",
  "postflow.create.modal.tiktok.wanrning.title.publishNow":
    "Shortly, please open the TikTok app and go the Inbox where you will find a notification to end the process of publication. This is due to a current limiting of TikTok.",
  "postflow.create.modal.tiktok.wanrning.title.schedule":
    "Due to a limitation with TikTok, at the time and date of publishing your video, you will receive an email (and a notification in your Social Piper app) to enter the Inbox in the application from TikTok, it will allow you to finish the publication.",
  "postflow.create.modal.tiktok.checkbox": "Do not show this message again.",
  "postflow.schedule.publish.tiktok": "Publish your TikTok now",
  "postflow.schedule.progress.message.post.tiktok": "Posting to TikTok",
  "postflow.schedule.progress.message.schedule.tiktok": "Scheduling TikTok",
  "postflow.scheduleModal.okbutton": "OK",
  "postflow.create.video.is.upload": "Wait a moment, your video is loading",
  "postflow.schedule.title.video":
    "Continue doing other activities while the publishing process finishes.",
  "postflow.schedule.subtitle.video":
    "Check the progress in the Scheduler section. We will send you an email when is finished.",
  "postflow.schedule.file.upload.fail":
    "The media file did not load correctly, please try again.",
  "postflow.schedule.piper.post.video.success": "“I'll take it from here!”",
  "postflow.create.modals.caption.title": "Your caption will talk about:",
  "postflow.create.modals.caption.generate": "Generate caption",
  "postflow.create.modals.caption.cancel": "Cancel",
  "postflow.create.modals.confirmCaption.title": "Here is an idea:",
  "postflow.create.modals.confirmCaption.cancel": "Cancel",
  "postflow.create.modals.confirmCaption.goBack": "Start over",
  "postflow.create.modals.confirmCaption.regenerate": "Generate another idea",
  "postflow.create.modals.confirmCaption.confirm": "Use this caption",
  "postflow.create.modals.loading.create": "I’m generating your post.",
  "postflow.create.modals.loading.subtitlecreate": "This could take up minutes. {br} Please be patient.",
  "postflow.create.modals.loading.add": "I’m generating your new image",
  "postflow.alerts.missingConfigs.label":
    "You must complete your commercial profile. ",
  "postflow.alerts.missingConfigs.redirect": "Go to settings",
};
export default en;
