import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { flexBox, paragraph } from '../../../styled/mixins';
import { WarningIcon } from '../../../assets/icons/iconRepository';
import { colors } from '../../../styled/theme';
import { FACEBOOK } from '../../../utils/constants/globals';

const Warning = styled.div`
  ${flexBox}
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${(props) => (props.marginTop)};
  max-width: 550px;
`;

const WarningImg = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
  margin-right: 8px;
  margin-top: 2px;
`;

const WarningText = styled.p`
  ${paragraph}
  flex: 1;
  font-size: 14px;
`;

const AddAdAccountPaymentMethod = styled.a`
  cursor: pointer;
  color: ${colors.speechBubble};
  text-decoration: none;
`;

const presentPaymentPopUp = (accountId, fetchAdAccounts) => {
  window.FB.ui({
    account_id: `${accountId}`,
    display: 'popup',
    method: 'ads_payment',
  }, () => {
    fetchAdAccounts();
  });
};

const MissingPaymentWarning = ({
  accountId,
  marginTop,
  fetchAdAccounts,
  socialNetwork,
  setOpenStepsPaymentModal,
}) => (
  <Warning marginTop={marginTop}>
    <WarningImg src={WarningIcon} alt="warning" />
    <WarningText>
      {socialNetwork === FACEBOOK ? (
        <FormattedMessage
          id="adAccount.missingFundingSource"
          defaultMessage="In order to create a Boost (a Facebook advertising campaign) you will need to <a>add a payment method to your Facebook Ad Account</a>"
          values={{
            a: (...chunks) => (
              <AddAdAccountPaymentMethod
                onClick={() => presentPaymentPopUp(accountId, fetchAdAccounts)}
              >
                {chunks}
              </AddAdAccountPaymentMethod>
            ),
          }}
        />
      ) : (
        <FormattedMessage
          id="socialProfiles.linkedIn.adAccount.missingFundingSource"
          defaultMessage="In order to create a Boost (a LinkedIn advertising campaign) you will need to <a>add a payment method to your LinkedIn Ad Account</a>"
          values={{
            a: (...chunks) => (
              <AddAdAccountPaymentMethod
                onClick={() => setOpenStepsPaymentModal(true)}
              >
                {chunks}
              </AddAdAccountPaymentMethod>
            ),
          }}
        />
      )}
    </WarningText>
  </Warning>
);

MissingPaymentWarning.propTypes = {
  accountId: PropTypes.string.isRequired,
  fetchAdAccounts: PropTypes.func.isRequired,
  marginTop: PropTypes.string,
};

MissingPaymentWarning.defaultProps = {
  marginTop: '16px',
};

export default MissingPaymentWarning;
