import { Checkbox } from '@material-ui/core';
import React, { useMemo } from 'react';

import useCollaborators from '../../hooks/useCollaborators';
import useCompany from '../../hooks/useCompany';
import { text } from '../text';
import {
  CancelText,
  CheckboxWrapper,
  CloseButton,
  CollaboratorCollapsableHero,
  CollaboratorFullName,
  CollaboratorWrapper,
  CollaboratorsWrapper,
  CollapsedToggler,
  DeleteMemberButton,
  Input,
  InputsWrapper,
  RestrictionName,
  RestrictionWrapper,
  RestrictionsCheckboxesWrapper,
  SaveMemberTouchable,
  SocialAndRestictionSubWrapper,
  SocialAndRestrictionsWrapper,
  SocialBrandName,
  SocialBusinessName,
  SocialCheckboxesWrapper,
  SocialOrRestrictionTitle,
  SocialWrapper,
  TinyLogos,
} from './index.styled';

export default () => {
  const { collaborators } = useCollaborators();
  const { companies } = useCompany();

  return (
    <CollaboratorsWrapper>
      <StandaloneCollaboratorCollapsable disableRemove companies={companies} />
      {collaborators.map((collaborator, i) => (
        <CollaboratorCollapsable key={i} collaborator={collaborator} companies={companies} />
      ))}
    </CollaboratorsWrapper>
  );
};

const StandaloneCollaboratorCollapsable = ({ companies, disableRemove = false }) => {
  const { saveOrUpdate, standalone } = useCollaborators();

  /** @type {(path: string) => (ev: React.SyntheticEvent) => void} */
  const handleChange = (path) => (ev) => {
    // @ts-ignore
    const value = ev.currentTarget.value;
    const payload = {
      path,
      value,
    };

    standalone.alter(payload);
  };

  /** @type {(path: string) => (ev: React.SyntheticEvent) => void} */
  const handleChecked = (path) => (ev) => {
    // @ts-ignore
    const value = !!ev.currentTarget.checked;
    const payload = {
      path,
      value,
    };

    standalone.alter(payload);
  };

  const saveCurrentColaborator = async () => {
    const payload = {
      body: {
        ...standalone.collaborator,
        assignments: [...standalone.collaborator.assignments],
      },
      isDraft: standalone.collaborator.draft,
    };

    await saveOrUpdate(payload); // always create

    standalone.reset();
  };

  const collapsable = useMemo(() => false, []);

  const disableSave = useMemo(
    () => !standalone.collaborator.email,
    [standalone.collaborator.email],
  );

  return (
    <CollaboratorCollapsableHero collapsable={collapsable} onClick={undefined}>
      <CancelText onClick={undefined} draft={standalone.collaborator.draft}>
        {text('k.cancel')}
        <CloseButton draft={standalone.collaborator.draft} />
      </CancelText>

      <form>
        <InputsWrapper>
          <Input
            id='firstName'
            onChange={handleChange('firstName')}
            label={text('onboarding.creteAccount.firstname')}
            placeholder=' '
            value={standalone.collaborator?.firstName}
          />
          <Input
            id='lastName'
            onChange={handleChange('lastName')}
            label={text('onboarding.creteAccount.lastname')}
            placeholder=' '
            value={standalone.collaborator?.lastName}
          />
          <Input
            id='email'
            showRequired
            onChange={handleChange('email')}
            label={text('onboarding.creteAccount.email')}
            placeholder='address@domain.com'
            value={standalone.collaborator?.email}
          />
          <DeleteMemberButton disabled={disableRemove} onClick={undefined}>
            {text('settings.personal.teams.button.delete')}
          </DeleteMemberButton>
        </InputsWrapper>
        <SocialAndRestrictionsWrapper>
          <SocialWrapper>
            <SocialOrRestrictionTitle>
              {text('onboarding.biz.step4.social')}
            </SocialOrRestrictionTitle>
            <SocialAndRestictionSubWrapper>
              {companies.map((company, i) => {
                const assignment = standalone.collaborator.assignments[i];
                return (
                  <NetworkPerBusinessCheckboxes
                    i={i}
                    key={i}
                    businessName={company.companyName}
                    assignment={assignment}
                    handleChecked={handleChecked}
                  />
                );
              })}
            </SocialAndRestictionSubWrapper>
          </SocialWrapper>
          <RestrictionWrapper>
            <SocialOrRestrictionTitle>
              {text('onboarding.biz.step4.restrictions')}
            </SocialOrRestrictionTitle>
            <SocialAndRestictionSubWrapper lastchild>
              <RestrictionsCheckboxesWrapper>
                {/* -- checkboxes -- */}
                <CheckboxWrapper>
                  <Checkbox
                    color='primary'
                    style={{ padding: 0 }}
                    checked={standalone.collaborator?.assignments[0]?.hasPost}
                    onChange={handleChecked('assignments[0].hasPost')}
                  />
                  <RestrictionName>
                    {text('onboarding.biz.step4.restriction.post', {
                      b: (...chunks) => <b>{chunks}</b>,
                    })}
                  </RestrictionName>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    color='primary'
                    style={{ padding: 0 }}
                    checked={standalone.collaborator?.assignments[0]?.hasBoost}
                    onChange={handleChecked('assignments[0].hasBoost')}
                  />
                  <RestrictionName>
                    {text('onboarding.biz.step4.restriction.boost', {
                      b: (...chunks) => <b>{chunks}</b>,
                    })}
                  </RestrictionName>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    color='primary'
                    style={{ padding: 0 }}
                    checked={standalone.collaborator?.assignments[0]?.hasCommunity}
                    onChange={handleChecked('assignments[0].hasCommunity')}
                  />
                  <RestrictionName>
                    {text('onboarding.biz.step4.restriction.community', {
                      b: (...chunks) => <b>{chunks}</b>,
                    })}
                  </RestrictionName>
                </CheckboxWrapper>
                {/* -- checkboxes -- */}
              </RestrictionsCheckboxesWrapper>
            </SocialAndRestictionSubWrapper>
          </RestrictionWrapper>
        </SocialAndRestrictionsWrapper>

        <SaveMemberTouchable
          disabled={disableSave}
          onClick={!disableSave ? saveCurrentColaborator : undefined}
        >
          {text('onboarding.biz.steps.save')}
        </SaveMemberTouchable>
      </form>
    </CollaboratorCollapsableHero>
  );
};

const CollaboratorCollapsable = ({ collaborator, companies, disableRemove = false }) => {
  const { alterById, deleteById, saveOrUpdate, collapse, cancel } = useCollaborators();

  const undirtyCollaborator = useMemo(() => collaborator, []);

  const handleCancel = () => {
    if (!collaborator.draft) {
      toggleCollapsed();
    }

    cancel(undirtyCollaborator);
  };

  const toggleCollapsed = () => collapse(collaborator.id);

  /** @type {(path: string) => (ev: React.SyntheticEvent) => void} */
  const handleChange = (path) => (ev) => {
    // @ts-ignore
    const value = ev.currentTarget.value;
    const payload = {
      path,
      value,
    };

    alterById(collaborator.id, payload);
  };

  /** @type {(path: string) => (ev: React.SyntheticEvent) => void} */
  const handleChecked = (path) => (ev) => {
    // @ts-ignore
    const value = !!ev.currentTarget.checked;
    const payload = {
      path,
      value,
    };

    alterById(collaborator.id, payload);
  };

  const saveCurrentColaborator = async () => {
    const payload = {
      body: {
        ...collaborator,
        assignments: [...collaborator.assignments],
      },
      isDraft: collaborator.draft,
    };

    await saveOrUpdate(payload);
  };

  const destroyCollaborator = async () => {
    const payload = {
      id: collaborator.id,
      isDraft: collaborator.draft,
    };

    await deleteById(payload);
  };

  /** @type {string} */
  const collaboratorFullName = useMemo(() => {
    let fullName = '';

    if (collaborator.firstName.length) {
      fullName = collaborator.firstName;
    }

    if (collaborator.lastName.length) {
      fullName += ` ${collaborator.lastName}`;
    }

    if (!collaborator.firstName.length && !collaborator.lastName.length) {
      fullName = collaborator.email;
    }

    return fullName.trim();
  }, [collaborator.firstName, collaborator.lastName, collaborator.email]);

  /** @type {boolean} */
  const collapsed = useMemo(() => collaborator.collapsed, [collaborator.collapsed]);

  /** @type {boolean} */
  const collapsable = useMemo(
    () => !collaborator.draft && collapsed,
    [collaborator.draft, collapsed],
  );

  const disableSave = useMemo(() => !collaborator.email, [collaborator.email]);

  return (
    <CollaboratorCollapsableHero
      collapsable={collapsable}
      onClick={collapsable ? toggleCollapsed : undefined}
    >
      {!collapsed && (
        <CancelText onClick={handleCancel} draft={collaborator.draft}>
          {text('k.cancel')}
          <CloseButton draft={collaborator.draft} />
        </CancelText>
      )}

      {!collapsed && (
        <form>
          <InputsWrapper>
            <Input
              id='firstName'
              onChange={handleChange('firstName')}
              label={text('onboarding.creteAccount.firstname')}
              placeholder=' '
              value={collaborator?.firstName}
            />
            <Input
              id='lastName'
              onChange={handleChange('lastName')}
              label={text('onboarding.creteAccount.lastname')}
              placeholder=' '
              value={collaborator?.lastName}
            />
            <Input
              id='email'
              showRequired
              onChange={handleChange('email')}
              label={text('onboarding.creteAccount.email')}
              placeholder='address@domain.com'
              value={collaborator?.email}
            />
            <DeleteMemberButton disabled={disableRemove} onClick={destroyCollaborator}>
              {text('settings.personal.teams.button.delete')}
            </DeleteMemberButton>
          </InputsWrapper>
          <SocialAndRestrictionsWrapper>
            <SocialWrapper>
              <SocialOrRestrictionTitle>
                {text('onboarding.biz.step4.social')}
              </SocialOrRestrictionTitle>
              <SocialAndRestictionSubWrapper>
                {companies.map((company, i) => {
                  const assignment = collaborator.assignments[i];
                  return (
                    <NetworkPerBusinessCheckboxes
                      i={i}
                      key={i}
                      businessName={company.companyName}
                      assignment={assignment}
                      handleChecked={handleChecked}
                    />
                  );
                })}
              </SocialAndRestictionSubWrapper>
            </SocialWrapper>
            <RestrictionWrapper>
              <SocialOrRestrictionTitle>
                {text('onboarding.biz.step4.restrictions')}
              </SocialOrRestrictionTitle>
              <SocialAndRestictionSubWrapper lastchild>
                <RestrictionsCheckboxesWrapper>
                  {/* -- checkboxes -- */}
                  <CheckboxWrapper>
                    <Checkbox
                      color='primary'
                      style={{ padding: 0 }}
                      checked={collaborator?.assignments[0]?.hasPost}
                      onChange={handleChecked('assignments[0].hasPost')}
                    />
                    <RestrictionName>
                      {text('onboarding.biz.step4.restriction.post', {
                        b: (...chunks) => <b>{chunks}</b>,
                      })}
                    </RestrictionName>
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <Checkbox
                      color='primary'
                      style={{ padding: 0 }}
                      checked={collaborator?.assignments[0]?.hasBoost}
                      onChange={handleChecked('assignments[0].hasBoost')}
                    />
                    <RestrictionName>
                      {text('onboarding.biz.step4.restriction.boost', {
                        b: (...chunks) => <b>{chunks}</b>,
                      })}
                    </RestrictionName>
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <Checkbox
                      color='primary'
                      style={{ padding: 0 }}
                      checked={collaborator?.assignments[0]?.hasCommunity}
                      onChange={handleChecked('assignments[0].hasCommunity')}
                    />
                    <RestrictionName>
                      {text('onboarding.biz.step4.restriction.community', {
                        b: (...chunks) => <b>{chunks}</b>,
                      })}
                    </RestrictionName>
                  </CheckboxWrapper>
                  {/* -- checkboxes -- */}
                </RestrictionsCheckboxesWrapper>
              </SocialAndRestictionSubWrapper>
            </RestrictionWrapper>
          </SocialAndRestrictionsWrapper>

          <SaveMemberTouchable
            disabled={disableSave}
            onClick={!disableSave ? saveCurrentColaborator : undefined}
          >
            {text('onboarding.biz.steps.save')}
          </SaveMemberTouchable>
        </form>
      )}

      {collapsed && (
        <CollaboratorWrapper>
          <CollaboratorFullName>{collaboratorFullName}</CollaboratorFullName>
          <CollapsedToggler onClick={toggleCollapsed} />
        </CollaboratorWrapper>
      )}
    </CollaboratorCollapsableHero>
  );
};

const NetworkPerBusinessCheckboxes = ({ businessName, assignment, i, handleChecked }) => {
  return (
    <React.Fragment>
      <SocialBusinessName>{businessName}</SocialBusinessName>
      <SocialCheckboxesWrapper>
        <CheckboxWrapper>
          <Checkbox
            color='primary'
            style={{ padding: 0 }}
            checked={assignment?.facebookPermission}
            onChange={handleChecked(`assignments[${i}].facebookPermission`)}
          />
          <TinyLogos.Facebook />
          <SocialBrandName>Facebook</SocialBrandName>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            color='primary'
            style={{ padding: 0 }}
            checked={assignment?.instagramPermission}
            onChange={handleChecked(`assignments[${i}].instagramPermission`)}
          />
          <TinyLogos.Instagram />
          <SocialBrandName>Instagram</SocialBrandName>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            color='primary'
            style={{ padding: 0 }}
            checked={assignment?.twitterPermission}
            onChange={handleChecked(`assignments[${i}].twitterPermission`)}
          />
          <TinyLogos.Twitter />
          <SocialBrandName>Twitter</SocialBrandName>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            color='primary'
            style={{ padding: 0 }}
            checked={assignment?.linkedinPermission}
            onChange={handleChecked(`assignments[${i}].linkedinPermission`)}
          />
          <TinyLogos.Linkedin />
          <SocialBrandName>LinkedIn</SocialBrandName>
        </CheckboxWrapper>
      </SocialCheckboxesWrapper>
    </React.Fragment>
  );
};
