import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import { Title, ButtonOnBoarding } from './components/OnboardingComponents';
import { heading, paragraph } from '../../styled/mixins';
import { colors } from '../../styled/theme';
import { PlanProvider, UserProvider } from '../../services/entities';
import PlanSelection from './components/PlanSelection';
import { AlertsContext } from '../../contexts/AlertsContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { WebViewContext } from '../../contexts/WebViewContext';
import TransactionFailModal from './components/TransactionFailModal';
import GlobalTheme from '../../styled/GlobalTheme';
import { SEVERITY } from '../../utils/constants/alerts';

const TitleApp = styled(Title)`
  && {
    width: 100%;
    @media (width: 896px){
      margin-top: 30px;
    };

    @media (width: 812px){
      margin-top: 30px;
    };

    @media (width: 844px){
      margin-top: 30px;
    };

    @media (width: 926px){
      margin-top: 30px;
    };
  }
`;

const Subtitle = styled.h3`
  ${heading}
  font-size: 28px;
  margin-top: 24px;
  color: ${colors.primary};
  width: 100%;
  text-align: center;
`;

const PlanSelectionApple = styled(PlanSelection)`
  && {
    // display: ${({ display }) => ((display) ? 'flex;' : 'none;')};
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 40px;
  }
`;

const Note = styled.div`
  ${paragraph}
  text-align: justify;
  margin-bottom: 8px;
  font-size: 14px;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin: 0 15px;
  };
`;

const ContainerButtonContinue = styled.div`
  text-align: center;
  width: 100%;
  padding: 10px 100px;
  ${GlobalTheme.breakpoints.down('sm')}{
    padding: 25px 15px;
  };
`;

const ButtonContinueApple = styled(ButtonOnBoarding)`
  && {
    width: 100%;
  }
`;

const StyledLink = styled.a`
  display: none;
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`;

const ContainerLoader = styled.div`
  width: 100%;
  height: 130px;
  padding-bottom: 200px;
  display: ${({ display }) => ((display) ? 'initial;' : 'none;')};
`;

const BillingSectionApps = ({
  nextHandler,
}) => {
  const intl = useIntl();
  const { showBackendError, showError, showAlert } = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);
  const { dataLayerPush } = useContext(AnalyticsContext);
  const [PromoCode, setPromoCode] = useState({
    plans: [],
    code: '',
  });
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);
  const [contactInfo, setContactInfo] = useState({
    cardholderName: '',
    country: '',
    billingAddressLineOne: '',
    billingAddressLineTwo: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
  });

  const [planSelection, setPlanSelection] = useState('inapp://socialpiper*1');

  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const { isAppIOS, isApp } = useContext(WebViewContext);
  const isAppAndroid = isApp && !isAppIOS;

  const fetchUserInfo = async () => {
    const response = await UserProvider.fetch(`
      id
      firstName
      lastName
      hasTrial
      email
      remainingTrialDays
      currency
      stillActive
    `, false);
    return response;
  };

  useEffect(() => {
    dataLayerPush({
      pagePath: '/onboarding/subscription-apps',
      pathTitle: 'Onboarding - Purchase subscription',
      event: 'pv',
    });
    const fetchPlans = async () => {
      let response = {};
      if (isAppAndroid) {
        let infoProductObject = false;
        const infoProductAndroid = window.Android.getInfoProducts();
        /* FOR TESTING IN WEB LOCAL */
        // eslint-disable-next-line max-len, object-curly-newline
        // const infoProductAndroid = '{"monthlyPrice": "$24.00", "monthlyCurrency": "MXN", "yearlyPrice": "$47.00", "yearlyCurrency": "MXN"}';
        try {
          infoProductObject = JSON.parse(infoProductAndroid);
          // eslint-disable-next-line no-empty
        } catch (error) { }
        response = await PlanProvider.getAllPlansAndroid();

        if (infoProductObject) {
          response.data.plans = response.data.plans.map((currentPlan) => {
            if (currentPlan.isMonthly) {
              const priceString = infoProductObject.monthlyPrice.replace('$', '').replace(',', '');
              // eslint-disable-next-line no-param-reassign
              currentPlan.currencyCode = infoProductObject.monthlyCurrency;
              // eslint-disable-next-line no-param-reassign
              currentPlan.realPrice = parseInt(priceString, 10);
            } else {
              const priceString = infoProductObject.yearlyPrice.replace('$', '').replace(',', '');
              // eslint-disable-next-line no-param-reassign
              currentPlan.currencyCode = infoProductObject.yearlyCurrency;
              // eslint-disable-next-line no-param-reassign
              currentPlan.realPrice = parseInt(priceString, 10);
            }
            return currentPlan;
          });
        }
      }
      if (isAppIOS) {
        const currencyCode = window.localStorage.getItem('currencyCode');
        const countryCode = window.localStorage.getItem('countryCode');
        response = await PlanProvider.getAllPlansIOS(currencyCode, countryCode);
      }
      if (response.success) {
        setPromoCode({
          plans: response.data.plans,
          code: response.data.promocode,
        });
      }
      setShowLoader(false);
    };
    const fetchContactInfo = async () => {
      const response = await fetchUserInfo();
      if (response.success) {
        setContactInfo({
          ...contactInfo,
          email: response.data.email,
        });
      }
    };

    fetchContactInfo();
    fetchPlans();
  }, []);

  const handleClick = async () => {
    if (isAppIOS) {
      if (selectedPlanIndex !== null) {
        const idPlan = PromoCode.plans[selectedPlanIndex].id;
        setPlanSelection(`inapp://socialpiper*${PromoCode.plans[selectedPlanIndex].productIdApp}*${contactInfo.email}*${idPlan}`);
        setLoading(true);
        setTimeout(() => { document.getElementById('run-shop').click(); }, 500);
        setTimeout(() => { setLoading(false); }, 3000);
        return;
      }
    } else if (selectedPlanIndex !== null) {
      const productId = PromoCode.plans[selectedPlanIndex].productIdApp;
      setLoading(true);
      window.Android.initPurchase(productId);
      /* FOR TESTING IN WEB LOCAL */
      /* const jsonPurchase = {
        token: 'ADD TOKEN VALID',
        responseCode: 0,
        debugMessage: '',
        sku: 'socialpiper.monthly.demo',
        period: 'P1M',
        periodTrial: 'P2W',
        price: '$24.00',
        currency: 'MXN',
      };
      window.androidPurchaseCompleted(jsonPurchase);// */
      return;
    }

    showError(intl.formatMessage({
      id: 'alert.error.onboarding.billingSection.selectPlan',
      defaultMessage: 'Please a select a plan',
    }));
  };

  useEffect(() => {
    window.applePurchaseCompleted = async (idPlan) => {
      setLoading(false);
      if (idPlan >= 0) {
        const response = await PlanProvider.subscribeToPlanIOS(idPlan);
        if (response.success) {
          nextHandler(3);
        } else {
          showBackendError(intl.formatMessage({
            id: 'alert.error.onboarding.billingSection.app.transaction',
            defaultMessage: 'The payment process could not be completed. Please try again or contact support@socialpiper.com',
          }));
        }
        return;
      }
      showBackendError('The payment process could not be completed. Please try again or contact support@socialpiper.com');
    };

    window.closeModalTransaction = async () => {
      setShowTransactionModal(false);
      return '';
    };
  }, []);

  useEffect(() => {
    window.androidPurchaseCompleted = async (jsonPurchase) => {
      try {
        const response = await PlanProvider.subscribeToPlanAndroid(jsonPurchase);
        if (response.success) {
          nextHandler(3);
        } else {
          setLoading(false);
          showBackendError(intl.formatMessage({
            id: 'alert.error.onboarding.billingSection.app.transaction',
            defaultMessage: 'The payment process could not be completed. Please try again or contact support@socialpiper.com',
          }));
        }
        return;
      } catch (error) {
        setLoading(false);
        showBackendError(intl.formatMessage({
          id: 'alert.error.onboarding.billingSection.app.transaction',
          defaultMessage: 'The payment process could not be completed. Please try again or contact support@socialpiper.com',
        }));
      }
    };
    window.androidShowWarning = async () => {
      setLoading(false);
      showAlert(intl.formatMessage({
        id: 'alert.warning.onboarding.billingSection.apps.purchase.invalid',
        defaultMessage: 'The payment process could not be completed. Please try again or contact support@socialpiper.com',
      }), SEVERITY.INFO);
    };
  }, []);

  return (
    <>
      <TitleApp>
        <FormattedMessage
          id="onboarding.billing.heading"
          defaultMessage="Social Piper"
        />
      </TitleApp>

      <Subtitle>
        <FormattedMessage
          id="onboarding.billing.selectPlan"
          defaultMessage="Select your Billing Plan"
        />
      </Subtitle>

      <ContainerLoader display={showLoader}>
        <StyledCircularProgress size={35} />
      </ContainerLoader>
      <PlanSelectionApple
        id="planSelection"
        plans={PromoCode.plans}
        selectedPlanIndex={selectedPlanIndex}
        setSelectedPlanIndex={setSelectedPlanIndex}
      // display={!showLoader}
      />

      <Note>
        <FormattedMessage
          id="onboarding.billing.charging.note"
          defaultMessage="At the end of your trial period, you will be charged automatically. You can cancel at any time."
        />
      </Note>
      <ContainerButtonContinue>
        <ButtonContinueApple
          handlerClick={handleClick}
          isLoading={loading}
        >
          <FormattedMessage
            id="onboarding.billing.apple.continueButton"
            defaultMessage="Pay & Continue"
          />
        </ButtonContinueApple>
      </ContainerButtonContinue>

      <StyledLink id="run-shop" href={planSelection} />

      <div>
        <TransactionFailModal
          open={showTransactionModal}
        />
      </div>
    </>
  );
};

BillingSectionApps.propTypes = {
  nextHandler: PropTypes.func.isRequired,
};

export default BillingSectionApps;
