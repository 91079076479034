import React from 'react';
import styled, { css, StyledComponent } from 'styled-components';

/** @type {StyledComponent<"span", any, { size: number, path: string }, never>} */
export default styled.span`
  display: block;

  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;

  background-image: url(${(props) => props.path});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;
