import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { authAppSync } from '../../services/auth';
import { getApprovalNotifications } from './querys/notifications.query';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';

export default class ApprovalProvider {
    static async getApprovalNotifications() {
        let result = {
            message: '',
            success: false,
            data: null,
          };
        
        try {
            if (ENV === ENVIRONMENTS.local) {
                return {
                  data: {},
                  message: 'success',
                  success: true,
                };
            }
            const response = await authAppSync.client.query({
                query: getApprovalNotifications,
                fetchPolicy: 'no-cache',
            });
            result = {
                message: 'success',
                success: true,
                data: response.data
            }
        } catch (err) {
            Sentry.captureException(err);
            result.message = err.message;
        }
        return result;
    }
}