/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { useLocation, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';

import { MenuIcon, ProfilePlaceholderColored } from '../../../assets/icons/iconRepository';
import { DoggoLogo, FullLogo, LettersLogo } from '../../../assets/logos/logoRepository';
import AuthService from '../../../services/auth/AuthService';
import { Button, NavBarButton } from '../../buttons';
// eslint-disable-next-line import/no-cycle
import { LayoutContext } from '../../../contexts/LayoutContext';
import PromoCodeContext from '../../../contexts/PromoCodeContext';
import { SocialContext } from '../../../contexts/SocialContext';
import { UserSettingsContext } from '../../../contexts/UserSettingsContext';
import { WebViewContext } from '../../../contexts/WebViewContext';
import { UnreadCommentsContext } from '../../../contexts/useUnreadCommentsContext';
import useProRole from '../../../hooks/useProRole';
import { ENV, ENVIRONMENTS } from '../../../utils/constants/globals';
import routes, { ACADEMY_ROUTE } from '../../../utils/constants/routes';
import { MEDIA_LIBRARY_TITLE } from '../../../utils/constants/settings';
import mediaQueries from '../../../utils/mediaQueries';
import { ApprovalsCtx } from '../../../views/Approvals/contexts/ApprovalsContext';
import sanitizePlans from '../../../views/CreateAccount/sanitizePlans';
import { Profiles } from '../../../views/Settings';
import SelectCompany from '../../SelectCompany';
import AnalyticsLink from '../../buttons/AnalyticsLink';
import {
  AppBar,
  AuthenticatedLogo,
  Avatar,
  BigLogo,
  CustomAnalyticsAnchor,
  CustomAnalyticsLink,
  DropdownMenu,
  IconsContainer,
  LinkContainer,
  LinksCentered,
  MenuButton,
  MobileAnalyticsAnchor,
  MobileAnalyticsIntercom,
  MobileAnalyticsLink,
  OptionContainer,
  OptionHeader,
  PiperLogo,
  Shader,
  SloganText,
  TabletLogo,
  Toolbar,
  TotalMessage,
  TotalMessageBox,
  UserLogoContainer,
  WarningErrorBoostPost,
} from './NavBar.styles';
import NavBarIconButton from './NavBarIconButton';
import ReduceFormatted from './ReduceFormatted';
import { menuDictionaryWithAuth, navBarIconsDictionary } from './menuDictionaries';

const StyledCircularProgress = styled(CircularProgress)`
  && {
    opacity: ${(props) => (props.display ? '1' : '0')};
    margin-left: auto;
  }
`;

const PiperTextLogo = () => (
  <>
    <PiperLogo src={DoggoLogo} marginTop='-12px' />
    <PiperLogo src={LettersLogo} />
  </>
);

const userAgentString = navigator.userAgent;

export const renderCenterLinks = (dictionary) =>
  dictionary.map((element) => {
    if (element.archor) {
      return (
        <CustomAnalyticsAnchor href={element.to} gaData={element.gaData} target={element.target}>
          <NavBarButton hideDown={element.hideDown}>{element.title}</NavBarButton>
        </CustomAnalyticsAnchor>
      );
    }

    return (
      <CustomAnalyticsLink
        exact={element.exact}
        navLink
        disabled={element.disabled}
        to={element.to}
        gaData={element.gaData}
        hint={element.hint}
      >
        <NavBarButton hideDown={element.hideDown}>
          {element.unreads > 0 && (
            <TotalMessageBox>
              <TotalMessage>{element.unreads}</TotalMessage>
            </TotalMessageBox>
          )}
          {element.hasError && <WarningErrorBoostPost isDesktop />}
          {element.title}
        </NavBarButton>
      </CustomAnalyticsLink>
    );
  });

const renderIconButtons = (dictionary) =>
  dictionary.map((element) => <NavBarIconButton element={element} />);

const RenderLinksWithAuth = ({ businessImage, totalMessagesUnread, totalNotifications }) => {
  const intl = useIntl();
  const { user, collaboratorPermissions } = useContext(LayoutContext);
  const { hasError } = useContext(UserSettingsContext);
  const rawMenu = menuDictionaryWithAuth(totalMessagesUnread, totalNotifications, intl, hasError).filter((menuDictionary) => {
    if (user.profileId === Profiles.COLLAB && !collaboratorPermissions.hasCommunity) {
      return menuDictionary.to !== routes.COMMUNITY;
    } else {
      return menuDictionary;
    }
  });
  const menu = rawMenu.filter((x) => x.allowedProfiles.some((y) => y === user.profileId));

  return (
    <>
      <LinksCentered> {renderCenterLinks(menu)} </LinksCentered>
      <UserLogoContainer>
        <Media queries={mediaQueries}>
          {(matches) => (
            <>
              {matches.xlUp && (
                <IconsContainer>
                  {[Profiles.MASTER, Profiles.COLLAB].includes(user.profileId) ? (
                    <SelectCompany />
                  ) : (
                    <AnalyticsLink
                      to='/settings/business'
                      gaData={{ action: 'Settings', label: '(button)(navBar)(menuItem)' }}
                    >
                      <Avatar size='30px' src={businessImage || ProfilePlaceholderColored} />
                    </AnalyticsLink>
                  )}
                  {renderIconButtons(navBarIconsDictionary(intl))}
                </IconsContainer>
              )}
            </>
          )}
        </Media>
      </UserLogoContainer>
    </>
  );
};

RenderLinksWithAuth.propTypes = {
  businessImage: PropTypes.string,
  totalMessagesUnread: PropTypes.number,
  totalNotifications: PropTypes.number,
};

RenderLinksWithAuth.defaultProps = {
  businessImage: '',
  totalMessagesUnread: 0,
  totalNotifications: 0,
};

const RenderLinksWithoutAuth = () => {
  const { boot } = useIntercom();
  const { PromoCode } = useContext(PromoCodeContext);
  const [trialDays, setTrialDays] = useState(0);
  const isMobile = window.matchMedia(mediaQueries.sm);
  const location = useLocation();
  const { pathname } = location;
  const [showLinkSignup, setShowLinkSignup] = useState(false);
  const [showLinkPricing, setShowLinkPricing] = useState(true);
  const [showLinkRegister, setShowLinkRegister] = useState(false);

  useEffect(() => {
    const [monthlyPlan] = sanitizePlans(PromoCode);
    setTrialDays(monthlyPlan.trialDuration);

    if (!userAgentString.includes('Convertify') && !isMobile.matches) {
      boot();
    }
  }, [PromoCode, trialDays]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    if (
      pathname === routes.CREATE_ACCOUNT_PRO ||
      pathname.includes(routes.SIGN_UP) ||
      pathname.includes(routes.PRICING)
    ) {
      setShowLinkSignup(true);
      if (pathname.includes(routes.SIGN_UP) || pathname.includes(routes.PRICING)) {
        setShowLinkPricing(true);
      }
      if (pathname === routes.CREATE_ACCOUNT_PRO) {
        window.localStorage.setItem('isLandingPro', '1');
      }
    } else {
      window.localStorage.removeItem('isLandingPro');
    }
  }, []);

  useEffect(() => {
    if (pathname === routes.CREATE_ACCOUNT) {
      setShowLinkRegister(true);
    } else {
      setShowLinkRegister(false);
    }
  }, [pathname]);
  return (
    <>
      <CustomAnalyticsLink
        navLink
        to={routes.TOOLS}
        gaData={{
          action: 'Tools',
          label: '(nav bar)',
        }}
      >
        <NavBarButton navLink color='inherit'>
          <ReduceFormatted languageKey='tools.header.title' />
        </NavBarButton>
      </CustomAnalyticsLink>
      {showLinkPricing && (
        <CustomAnalyticsLink
          navLink
          to={routes.PRICING}
          gaData={{
            action: 'PRICING',
            label: '(nav bar)',
          }}
        >
          <NavBarButton navLink color='inherit'>
            <ReduceFormatted languageKey='k.pricing' />
          </NavBarButton>
        </CustomAnalyticsLink>
      )}

      <CustomAnalyticsLink
        navLink
        to={routes.LOGIN}
        gaData={{
          action: 'Log in',
          label: '(nav bar)',
        }}
      >
        <NavBarButton navLink color='inherit'>
          <ReduceFormatted languageKey='k.login' />
        </NavBarButton>
      </CustomAnalyticsLink>
      <CustomAnalyticsLink
        navLink
        to={routes.CREATE_ACCOUNT}
        gaData={{
          action: 'Create account',
          label: '(nav bar)',
        }}
      >
        <NavBarButton navLink color='inherit'>
          <ReduceFormatted languageKey='k.register' />
        </NavBarButton>
      </CustomAnalyticsLink>

      {showLinkSignup && (
        <>
          {showLinkRegister ? (
            <CustomAnalyticsLink
              navLink
              to={routes.CREATE_ACCOUNT}
              gaData={{
                action: 'Create account',
                label: '(nav bar)',
              }}
            >
              <NavBarButton navLink color='inherit'>
                <ReduceFormatted languageKey='k.register' />
              </NavBarButton>
            </CustomAnalyticsLink>
          ) : (
            <CustomAnalyticsLink
              navLink
              to={pathname === routes.PRICING ? routes.CREATE_ACCOUNT : routes.PRICING}
              gaData={{
                action: 'Lets get started',
                label: '(button)(nav bar)',
              }}
            >
              <Button>
                <FormattedMessage
                  id={trialDays > 0 ? 'k.mainCta' : 'plan.pro.without.trial'}
                  defaultMessage='Start trial'
                />
              </Button>
            </CustomAnalyticsLink>
          )}
        </>
      )}
    </>
  );
};

const MobileWithoutAuthLinks = ({ handleChange, isApp, isAppIOS }) => {
  const { boot, show, shutdown } = useIntercom();

  const isMobile = window.matchMedia(mediaQueries.sm);
  const location = useLocation();
  const { pathname } = location;
  const [showLinkPricing] = useState(true);
  const [isSignUpLanding, setIsSignUpLanding] = useState(false);

  useEffect(() => {
    if (isMobile.matches) {
      setTimeout(() => {
        shutdown();
      }, 6000);
    }

    if (pathname.includes(routes.SIGN_UP)) {
      setIsSignUpLanding(true);
    }
    if (pathname.includes(routes.PRICING)) {
      setIsSignUpLanding(true);
    }
  }, []);

  const openIntercom = (e) => {
    window.closeMenu();
    boot();
    show();
    e.preventDefault();
  };

  return (
    <>
      <MobileAnalyticsLink
        navLink
        to={routes.TOOLS}
        gaData={{
          action: 'Tools',
          label: '(nav bar)',
        }}
        onClick={handleChange}
      >
        <OptionContainer>
          <OptionHeader>
            <ReduceFormatted languageKey='tools.header.title' />
          </OptionHeader>
        </OptionContainer>
      </MobileAnalyticsLink>
      {showLinkPricing && (
        <MobileAnalyticsLink
          navLink
          to={routes.PRICING}
          gaData={{
            action: 'pRICING',
            label: '(nav bar)',
          }}
          onClick={handleChange}
        >
          <OptionContainer>
            <OptionHeader>
              <ReduceFormatted languageKey='k.pricing' />
            </OptionHeader>
          </OptionContainer>
        </MobileAnalyticsLink>
      )}
      <MobileAnalyticsLink
        navLink
        to={!isApp ? routes.LOGIN : routes.LOGIN_APP}
        gaData={{
          action: 'Log in',
          label: '(nav bar)',
        }}
        onClick={handleChange}
      >
        <OptionContainer>
          <OptionHeader>
            <ReduceFormatted languageKey='k.login' />
          </OptionHeader>
        </OptionContainer>
      </MobileAnalyticsLink>
      {!isAppIOS && !isSignUpLanding && (
        <MobileAnalyticsLink
          navLink
          to={routes.CREATE_ACCOUNT}
          gaData={{
            action: 'Lets get started',
            label: '(button)(nav bar)',
          }}
          onClick={handleChange}
        >
          <OptionContainer>
            <OptionHeader>
              <ReduceFormatted languageKey='k.signup' />
            </OptionHeader>
          </OptionContainer>
        </MobileAnalyticsLink>
      )}
      <MobileAnalyticsIntercom
        onClick={openIntercom}
        navLink
        to={routes.CHAT}
        isActive={false}
        gaData={{ action: 'Intercom', label: '(button)(navbar)' }}
      >
        <OptionContainer>
          <OptionHeader>
            <ReduceFormatted languageKey='k.intercom.link.text' />
          </OptionHeader>
        </OptionContainer>
      </MobileAnalyticsIntercom>
    </>
  );
};

MobileWithoutAuthLinks.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isApp: PropTypes.bool.isRequired,
  isAppIOS: PropTypes.bool.isRequired,
};

const MobileWithAuthLinks = ({
  totalUnread,
  totalNotifications,
  handleChange,
  isApp,
  isAppIOS,
}) => {
  const { boot, show, update } = useIntercom();
  const { locale } = useContext(UserSettingsContext);
  const [display, setDisplay] = useState(false);
  const matchesLandscape = window.matchMedia(
    '(max-device-width: 1023px) and (orientation: landscape)',
  );
  const { user, collaboratorPermissions } = useContext(LayoutContext);
  const proRole = useProRole();
  const { hasError } = useContext(UserSettingsContext);

  if (user.id > 0) {
    const typePlan = proRole ? 'PRO' : 'LITE';
    const socialNetwork = user.connectedAccounts;
    const haveSocialNetwork =
      socialNetwork.hasOwnProperty('facebook') ||
      socialNetwork.hasOwnProperty('instagram') ||
      socialNetwork.hasOwnProperty('linkedin') ||
      socialNetwork.hasOwnProperty('twitter');
    const dataUser = {
      name: user.firstName,
      email: user.email,
      customAttributes: {
        PlanType: typePlan,
        SubscriptionStatus: user.subscriptionStatus,
        ConnectedAccount: haveSocialNetwork,
        TrialRemainingDays: user.remainingTrialDays,
        isOptIn: user.isOptIn,
      },
    };

    if (!userAgentString.includes('Convertify')) {
      update(dataUser);
    } else {
      boot(dataUser);
    }
  }

  const goToAcademy = () => {
    setDisplay(true);
    setTimeout(() => {
      setDisplay(false);
      window.closeMenu();
    }, 5000);
  };

  const openIntercom = (e) => {
    window.closeMenu();

    const typePlan = proRole ? 'PRO' : 'LITE';
    const socialNetwork = user.connectedAccounts;
    const haveSocialNetwork =
      socialNetwork.hasOwnProperty('facebook') ||
      socialNetwork.hasOwnProperty('instagram') ||
      socialNetwork.hasOwnProperty('linkedin') ||
      socialNetwork.hasOwnProperty('twitter');

    const dataUser = {
      name: user.firstName,
      email: user.email,
      customAttributes: {
        PlanType: typePlan,
        SubscriptionStatus: user.subscriptionStatus,
        ConnectedAccount: haveSocialNetwork,
        TrialRemainingDays: user.remainingTrialDays,
        isOptIn: user.isOptIn,
      },
    };
    boot(dataUser);
    show();
    e.preventDefault();
  };

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <>
          <MobileAnalyticsLink
            navLink
            exact
            onClick={handleChange}
            to={routes.DASHBOARD}
            gaData={{ pathname: '/dashboard', action: 'Dashboard', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.dashboard' />
              </OptionHeader>
            </OptionContainer>
          </MobileAnalyticsLink>
          <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={routes.APPROVALS}
            gaData={{ action: 'Approvals', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.approvals' />
              </OptionHeader>
              {totalNotifications > 0 && (
                <TotalMessageBox className='mobileUnreadBox'>
                  <TotalMessage>{totalNotifications}</TotalMessage>
                </TotalMessageBox>
              )}
            </OptionContainer>
          </MobileAnalyticsLink>
          <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={routes.SCHEDULER}
            gaData={{ action: 'Scheduler', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.scheduler' />
              </OptionHeader>
              {hasError && <WarningErrorBoostPost />}
            </OptionContainer>
          </MobileAnalyticsLink>
          <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={routes.BOOST_MANAGER}
            gaData={{ action: 'Boost Manager', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.boostManager' />
              </OptionHeader>
            </OptionContainer>
          </MobileAnalyticsLink>
          <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={routes.METRICS}
            gaData={{ action: 'Metrics', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.metrics.title' />
              </OptionHeader>
            </OptionContainer>
          </MobileAnalyticsLink>
          <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={routes.MEDIA_LIBRARY}
            gaData={{ action: MEDIA_LIBRARY_TITLE, label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.creativityCenter' />
              </OptionHeader>
            </OptionContainer>
          </MobileAnalyticsLink>
          {user.profileId === Profiles.COLLAB && !collaboratorPermissions.hasCommunity ? (
            <></>
          ) : (
            <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={`${routes.COMMUNITY}/post`}
            gaData={{ action: 'Community', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.community' />
              </OptionHeader>
              {totalUnread > 0 && (
                <TotalMessageBox className='mobileUnreadBox'>
                  <TotalMessage>{totalUnread}</TotalMessage>
                </TotalMessageBox>
              )}
            </OptionContainer>
          </MobileAnalyticsLink>
          )}
          <MobileAnalyticsAnchor href={`${ACADEMY_ROUTE}/${locale}`} onClick={goToAcademy}>
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.academy' />
              </OptionHeader>
              <StyledCircularProgress display={display} />
            </OptionContainer>
          </MobileAnalyticsAnchor>

          <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={routes.SETTINGS}
            gaData={{ action: 'Settings', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.settings' />
              </OptionHeader>
            </OptionContainer>
          </MobileAnalyticsLink>
          {(isApp || isAppIOS) && (matches.mdDownLess || matchesLandscape.matches) ? (
            <MobileAnalyticsLink
              onClick={handleChange}
              navLink
              to={routes.ABOUTAPP}
              gaData={{ action: 'About', label: '(button)(navbar)' }}
            >
              <OptionContainer>
                <OptionHeader>
                  <ReduceFormatted languageKey='k.about.link' />
                </OptionHeader>
              </OptionContainer>
            </MobileAnalyticsLink>
          ) : null}

          <MobileAnalyticsIntercom
            onClick={openIntercom}
            navLink
            to={routes.CHAT}
            gaData={{ action: 'Intercom', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.intercom.link.text' />
              </OptionHeader>
            </OptionContainer>
          </MobileAnalyticsIntercom>
          <MobileAnalyticsLink
            onClick={handleChange}
            navLink
            to={routes.LOGOUT}
            gaData={{ action: 'LogOut', label: '(button)(navbar)' }}
          >
            <OptionContainer>
              <OptionHeader>
                <ReduceFormatted languageKey='k.logout' />
              </OptionHeader>
            </OptionContainer>
          </MobileAnalyticsLink>
        </>
      )}
    </Media>
  );
};

MobileWithAuthLinks.propTypes = {
  handleChange: PropTypes.func.isRequired,
  totalUnread: PropTypes.number.isRequired,
  totalNotifications: PropTypes.number.isRequired,
  isApp: PropTypes.bool.isRequired,
  isAppIOS: PropTypes.bool.isRequired,
};

const LogoWithAuth = ({ action }) => (
  <CustomAnalyticsLink to='/' gaData={{ action: 'HomePage', label: '(logo)(navbar)' }}>
    <AuthenticatedLogo onClick={action}>
      <PiperTextLogo />
    </AuthenticatedLogo>
  </CustomAnalyticsLink>
);

LogoWithAuth.propTypes = {
  action: PropTypes.func.isRequired,
};

const LogoWithoutAuth = () => {
  const [defaultRoute, setDefRoute] = useState(routes.LANDING);
  const [isNavLink, setNavlink] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let newRef = '';
    const referal = document.referrer;
    const urlParams = new URLSearchParams(location.search);
    const referalPath = urlParams.get('ref');
    if (referalPath && referal) {
      newRef = `${referal}${referalPath}`;
    }
    if (ENV === ENVIRONMENTS.production && !newRef.includes('join.socialpiper.com')) {
      newRef = false;
    }
    if (newRef) {
      setDefRoute(newRef);
      setNavlink(true);
    }
  }, []);

  return !isNavLink ? (
    <CustomAnalyticsLink to={defaultRoute} gaData={{ action: 'HomePage', label: '(logo)(navbar)' }}>
      <BigLogo src={FullLogo} alt='logo' />
      <SloganText>Social media made easy.</SloganText>
      <TabletLogo>
        <PiperTextLogo />
      </TabletLogo>
    </CustomAnalyticsLink>
  ) : (
    <CustomAnalyticsAnchor
      href={defaultRoute}
      gaData={{ category: 'Main navbar', action: 'Go Back Landing', label: '(logo)(navbar)' }}
      target='_self'
    >
      <BigLogo src={FullLogo} alt='logo' />
      <SloganText>Social media made easy.</SloganText>
      <TabletLogo>
        <PiperTextLogo />
      </TabletLogo>
    </CustomAnalyticsAnchor>
  );
};

const AuthSection = ({ businessImage, totalMessagesUnread, totalNotifications }) => {
  const { setRedirectDashboard } = useContext(SocialContext);
  const updateState = () => {
    setRedirectDashboard(false);
  };
  return (
    <>
      <CustomAnalyticsLink to='/' gaData={{ action: 'HomePage', label: '(logo)(navbar)' }}>
        <LogoWithAuth action={updateState} />
      </CustomAnalyticsLink>
      <RenderLinksWithAuth
        businessImage={businessImage}
        totalMessagesUnread={totalMessagesUnread}
        totalNotifications={totalNotifications}
      />
    </>
  );
};

AuthSection.propTypes = {
  businessImage: PropTypes.string,
  totalMessagesUnread: PropTypes.number,
  totalNotifications: PropTypes.number,
};

AuthSection.defaultProps = {
  businessImage: '',
  totalMessagesUnread: 0,
  totalNotifications: 0,
};

const WithoutAuthSection = () => (
  <>
    <LogoWithoutAuth />
    <LinkContainer>
      <RenderLinksWithoutAuth />
    </LinkContainer>
  </>
);

const NavBar = ({ businessImage }) => {
  const location = useLocation();
  const { unreadComments } = useContext(UnreadCommentsContext);
  const { notifications } = useContext(ApprovalsCtx);
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const [collapsed, setCollapse] = useState(false);
  const [totalUnread, setTotalUnread] = useState(0);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [isAuthenticated, setAuthenticated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLanding, setIsLanding] = useState(false);
  const totalNotificationsOnMobileMenu = totalNotifications + totalUnread;

  useEffect(() => {
    setIsLanding(location.pathname === routes.LANDING);
  }, [location]);
  const handleChange = () => {
    setCollapse(!collapsed);
  };

  useEffect(() => {
    setAuthenticated(AuthService.isAuthenticated());
  }, [location]);

  useEffect(() => {
    const getTotalUnreadComments = () => {
      if (unreadComments) {
        const { messagesCount, fbCommentsCount, twRepliesCount, igCommentsCount, liCommentsCount } =
          unreadComments;
        // eslint-disable-next-line max-len
        setTotalUnread(
          messagesCount + fbCommentsCount + twRepliesCount + igCommentsCount + liCommentsCount,
        );
      } else {
        setTotalUnread(0);
      }
    };
    getTotalUnreadComments();
  }, [unreadComments]);

  useEffect(() => {
    const getTotalApprovalNotifications = () => {
      if (isAuthenticated) {
        const { posts, boosts } = notifications;
        const total = posts + boosts;
        setTotalNotifications(total);
      }
    };
    getTotalApprovalNotifications();
  }, [notifications, isAuthenticated]);

  useEffect(() => {
    window.closeMenu = () => {
      const enterClass = document.getElementsByClassName('enter');
      if (enterClass.length === 1) {
        const element = document.querySelector('.enter');
        element.classList.remove('enter');
        element.classList.add('enter');
        setCollapse(false);
      }
    };
  }, []);

  return (
    <>
      <AppBar position='relative' color='secondary' isAppIOS={isAppIOS}>
        <Toolbar>
          {isAuthenticated && (
            <AuthSection
              businessImage={businessImage}
              totalMessagesUnread={totalUnread}
              totalNotifications={totalNotifications}
            />
          )}
          {!isAuthenticated && <WithoutAuthSection />}
          <MenuButton onClick={handleChange}>
            {totalNotificationsOnMobileMenu > 0 && (
              <TotalMessageBox>
                <TotalMessage>{totalNotificationsOnMobileMenu}</TotalMessage>
              </TotalMessageBox>
            )}
            <img src={MenuIcon} alt='menu' />
          </MenuButton>
        </Toolbar>
      </AppBar>
      <Shader>
        <DropdownMenu className={collapsed ? 'enter' : 'exit'} menuHeight={2}>
          {isAuthenticated ? (
            <MobileWithAuthLinks
              totalUnread={totalUnread}
              totalNotifications={totalNotifications}
              handleChange={handleChange}
              isApp={isApp}
              isAppIOS={isAppIOS}
            />
          ) : (
            <MobileWithoutAuthLinks handleChange={handleChange} isApp={isApp} isAppIOS={isAppIOS} />
          )}
        </DropdownMenu>
      </Shader>
    </>
  );
};

NavBar.propTypes = {
  businessImage: PropTypes.string,
};

NavBar.defaultProps = {
  businessImage: '',
};

export default NavBar;
