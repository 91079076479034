import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationModal from '../../../../../components/notifications/Modal';
import { heading2, flexBox } from '../../../../../styled/mixins';
import PiperTip from '../../../../../components/PiperTips/PiperTip';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import Pipers from '../../../../../components/Pipers/Pipers';
import { Button } from '../../../../../components/buttons';
import { SECONDARY } from '../../../../../components/buttons/constants';
import { AnalyticsContext } from '../../../../../contexts/AnalyticsContext';
import { UserSettingsContext } from '../../../../../contexts/UserSettingsContext';
import useHotJarTrigger from '../../../../../hooks/useHotJarTrigger';
import HOTJAR_SURVEYS from '../../../../../utils/constants/hotjarValues';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px;
`;

const ModalTitle = styled.h2`
  ${heading2}
  text-align: center;
`;

const PiperTipWrapper = styled.div`
  max-width: 465px;
  width: fit-content;
  justify-items: center;
  align-items: center;
  ${GlobalTheme.breakpoints.down('sm')}{
    max-width: 260px;
    margin: 0 auto;
    padding: ${(props) => props.theme.space * 3}px 0;
    & .piper-tip {
      grid-template-rows: auto auto;
    }
  }
`;

const ModalActions = styled.div`
  ${flexBox}
  justify-content: center;
  align-items: center;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-direction: column-reverse;
  }
`;

const ConfirmButton = styled(Button)`
  && {
    margin-left: 32px;
    ${GlobalTheme.breakpoints.down('sm')}{
      margin: 0 0 24px;
    }
  }
`;

const DisableAccountModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  handleConfirm,
  loading,
  dataLayer,
}) => {
  const { dataLayerPush } = useContext(AnalyticsContext);
  const { locale } = useContext(UserSettingsContext);
  const disableHJ = useHotJarTrigger(() => { }, HOTJAR_SURVEYS.disableUser, locale);

  const handleEnter = () => {
    dataLayerPush({
      ...dataLayer,
      event: 'pv',
    });
  };
  const confirm = () => {
    disableHJ();
    handleConfirm();
  };

  return (
    <NotificationModal
      open={showConfirmationModal}
      setOpen={setShowConfirmationModal}
      onEntered={handleEnter}
    >
      <Container>
        <ModalTitle>
          <FormattedMessage
            id="settings.billing.disableAccount.modal.title"
            defaultMessage="You are about to delete your account!"
          />
        </ModalTitle>
        <PiperTipWrapper>
          <PiperTip
            Piper={Pipers.Fail2}
            position={window.innerWidth < GlobalTheme.breakpoints.values.md ? 'top' : 'right'}
            piperWidth="160px"
            trianglePosition="bottom"
          >
            <FormattedMessage
              id="settings.billing.disableAccount.modal.piperTip"
              defaultMessage="Are you really sure you want to delete it?"
            />
          </PiperTip>
        </PiperTipWrapper>
        <ModalActions>
          <Button
            type={SECONDARY}
            onClick={() => setShowConfirmationModal(false)}
          >
            <FormattedMessage
              id="k.nevermindCancel"
              defaultMessage="Nevermind, cancel"
            />
          </Button>
          <ConfirmButton
            danger
            onClick={confirm}
            isLoading={loading}
            disableElevation
          >
            <FormattedMessage
              id="settings.billing.disableAccount.modal.confirm"
              defaultMessage="Yes, disable account"
            />
          </ConfirmButton>
        </ModalActions>
      </Container>
    </NotificationModal>
  );
};

DisableAccountModal.propTypes = {
  showConfirmationModal: PropTypes.bool.isRequired,
  setShowConfirmationModal: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  dataLayer: PropTypes.instanceOf(Object),
};
DisableAccountModal.defaultProps = {
  dataLayer: {},
  handleConfirm: () => {},
};

export default DisableAccountModal;
