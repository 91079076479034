import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { flex, borderTop, materialShadows } from '../../../../styled/mixins';
import PiperTip from '../../../../components/PiperTips/PiperTip';
import Pipers from '../../../../components/Pipers/Pipers';
import colors from '../../../../styled/colors';
import SocialProfile from './components/SocialProfile';
import { SOCIAL_MEDIA } from '../../../../utils/constants/globals';
import mediaQueries from '../../../../utils/mediaQueries';
import CompetitorsProvider from '../../../../services/entities/CompetitorsProvider';
import RocketLoader from '../../../../components/globals/RocketLoader';
import { SocialContext } from '../../../../contexts/SocialContext';

const Wrapper = styled.section`
  ${flex}
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 16px;
  @media ${mediaQueries.mdUp} {
    padding-bottom: 98px;
  }
  @media ${mediaQueries.xlPlusUp} {
    padding-bottom: 84px;
  }
`;
const Container = styled.div`
  ${borderTop(colors.orange)};
  ${materialShadows};
  background: ${({ theme }) => theme.colors.white};
  padding: 16px 15px 0;
  width: 100%;
  @media ${mediaQueries.mdUp} {
    padding: 60px 32px 0;
  }
  @media ${mediaQueries.lgPlusUp} {
    padding: 24px 34px 0;
  }
  @media ${mediaQueries.lgPlusUp} {
    padding-top: 32px;
  }
  @media ${mediaQueries.xxlUp} {
    padding-right: 58px;
  }
`;
const Header = styled.div`
  @media ${mediaQueries.mdUp} {
    display: flex;
  }
`;
const Title = styled.h3`
  color: ${colors.black3E};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  @media ${mediaQueries.mdUp} {
    font-size: 24px;
    line-height: 32px;
  }
`;
const PiperTipWrapper = styled.div`
  margin-top: 14px;
  @media ${mediaQueries.mdUp} {
    flex: 1;
    margin-top: 0;
    margin-left: 20px;
  }
  @media ${mediaQueries.lgPlusUp} {
    margin-left: 78px;
  }
`;
const CustomPiperTip = styled(PiperTip)`
  && {
    grid-gap: 13px;
    @media (min-width: 400px) {
      grid-gap: 32px;
    }
    @media ${mediaQueries.mdUp} {
      grid-gap: 16px;
    }
    @media ${mediaQueries.xlPlusUp} {
      grid-template-columns: 1fr;
    }
    .piper-wrapper {
      max-width: 79px;
      position: relative;
      bottom: -10px;
      @media (min-width: 400px) {
        bottom: 0;
      }
      @media ${mediaQueries.mdUp} {
        max-width: 100px;
      }
    }
    .tip {
      @media ${mediaQueries.lgPlusUp} {
        align-self: baseline;
        max-width: 377px;
      }
    }
    .bodyTip {
      background: ${({ theme }) => theme.colors.primaryLighter};
      @media ${mediaQueries.lgPlusUp} {
        min-height: auto;
      }
    }
    .triangle {
      border-bottom-color: ${({ theme }) => theme.colors.primaryLighter};
    }
  }
`;
const SocialProfiles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 40px;
  @media ${mediaQueries.mdUp} {
    margin-top: 6px;
  }
  @media ${mediaQueries.xlPlusUp} {
    margin-top: 18px;
  }
`;

const Competitors = () => {
  const [facebookCompetitors, setFacebookCompetitors] = useState([]);
  const [instagramCompetitors, setInstragamCompetitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const { socialNetworks } = useContext(SocialContext);

  const fetchCompetitors = async () => {
    setLoading(true);
    const response = await CompetitorsProvider.fetch();
    if (response.success) {
      setFacebookCompetitors(response.data[0]);
      setInstragamCompetitors(response.data[1]);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCompetitors();
  }, []);

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>
            <FormattedMessage
              id="connectCompetitors.title"
              defaultMessage="Connect your competitors"
            />
          </Title>
          <PiperTipWrapper>
            <CustomPiperTip
              Piper={Pipers.NudgingPaw1}
              position="left"
              trianglePosition="bottom"
            >
              <FormattedMessage
                id="competitors.piperTip.text"
                defaultMessage="Add competitors to watch their activity and get inspiration for your posts."
              />
            </CustomPiperTip>
          </PiperTipWrapper>
        </Header>
        <SocialProfiles>
          <SocialProfile
            type={SOCIAL_MEDIA.facebook}
            competitors={facebookCompetitors}
            setCompetitors={setFacebookCompetitors}
            hasPageConnected={!!socialNetworks.facebook.pageName}
          />
          <SocialProfile
            type={SOCIAL_MEDIA.instagram}
            competitors={instagramCompetitors}
            setCompetitors={setInstragamCompetitors}
            hasPageConnected={!!socialNetworks.instagram.pageName}
          />
        </SocialProfiles>
      </Container>
    </Wrapper>
  );
};

export default Competitors;
