import React from 'react';
import propTypes from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';

const atEndCurrency = {
  EUR: true,
};

const getCurrencyAndPosition = (currencyCode) => !(currencyCode in atEndCurrency);

export const currencyFormat = (
  value,
  currencyCode = 'MXN',
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
) => {
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  const beginning = getCurrencyAndPosition(currencyCode);
  let formated = new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
  formated = beginning ? `${currencySymbol}${formated}` : `${formated}${currencySymbol}`;
  return formated;
};

const Currency = ({
  value, currency, minimumFractionDigits, maximumFractionDigits,
}) => (
  <>
    { currencyFormat(value, currency, minimumFractionDigits, maximumFractionDigits) }
  </>
);

Currency.propTypes = {
  value: propTypes.string.isRequired,
  currency: propTypes.string.isRequired,
  minimumFractionDigits: propTypes.number,
  maximumFractionDigits: propTypes.number,
};

Currency.defaultProps = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export default Currency;
