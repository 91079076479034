import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  materialShadows,
  borderTop,
  heading3,
  flexBox,
  paragraph,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import PlanBasicDetails from './PlanBasicDetails';
import PlanDetailsSubscribed from './PlanDetailsSubscribed';
import useBasicRole from '../../../../../hooks/useBasicRole';
import { LayoutContext } from '../../../../../contexts/LayoutContext';
import { WebViewContext } from '../../../../../contexts/WebViewContext';
import { colors } from '../../../../../styled/theme';
import { PLAN_NAME } from '../../../../../utils/constants/settings';

const PlanInformationCard = styled.div`
  ${materialShadows};
  ${borderTop('speechBubble')};
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  ${flexBox}
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  ${GlobalTheme.breakpoints.down('md')}{
    padding: 24px 16px;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const Title = styled.h3`
  ${heading3}
  margin-right: 24px;
`;

const PlansButton = styled.a`
  ${paragraph}
  font-weight: 600;
  text-decoration: underline;
  color: ${colors.primary};
  font-size: 14px;
  cursor: pointer;
`;

const PlanInformation = ({
  subscription,
  paymentPayload,
  handleShowCancelSubscriptionModal,
  handleShowUpgradeToBusinessModal,
  handleShowDowngradeToProModal,
  userProfile,
  intl,
  className,
  isPlanApple,
  isPlanAndroid,
}) => {
  const { basicRole } = useBasicRole();
  const { setShowBecomeProModal } = useContext(LayoutContext);
  const { isApp } = useContext(WebViewContext);
  const details = () => {
    if (basicRole) {
      return (
        <PlanBasicDetails
          subscription={subscription}
        />
      );
    }
    return (
      <PlanDetailsSubscribed
        intl={intl}
        paymentPayload={paymentPayload}
        subscription={subscription}
        handleShowCancelSubscriptionModal={handleShowCancelSubscriptionModal}
        handleShowUpgradeToBusinessModal={handleShowUpgradeToBusinessModal}
        handleShowDowngradeToProModal={handleShowDowngradeToProModal}
        userProfile={userProfile}
        isPlanApple={isPlanApple}
        isPlanAndroid={isPlanAndroid}
      />
    );
  };
  return (
    <PlanInformationCard
      className={className}
    >
      <Header>
        <Title>
          <FormattedMessage
            id="settings.billing.planInformation.title"
            defaultMessage="Your Plan Information"
          />
        </Title>
        {(subscription.planName === PLAN_NAME.BASIC && !isApp) && (
          <PlansButton
            onClick={() => setShowBecomeProModal(true)}
          >
            <FormattedMessage
              id="settings.billing.planInformation.plansButton"
              defaultMessage="Check out Social Piper plans"
            />
            {' >'}
          </PlansButton>
        )}
      </Header>
      {details()}
    </PlanInformationCard>
  );
};
PlanInformation.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.instanceOf(Object).isRequired,
  paymentPayload: PropTypes.instanceOf(Object).isRequired,
  handleShowCancelSubscriptionModal: PropTypes.func.isRequired,
  handleShowUpgradeToBusinessModal: PropTypes.func.isRequired,
  handleShowDowngradeToProModal: PropTypes.func.isRequired,
  userProfile: PropTypes.string.isRequired,
  intl: PropTypes.instanceOf(Object).isRequired,
  isPlanApple: PropTypes.bool,
  isPlanAndroid: PropTypes.bool,
};

PlanInformation.defaultProps = {
  className: '',
  isPlanApple: false,
  isPlanAndroid: false,
};

export default PlanInformation;
