import styled from 'styled-components';
import { ChevronDownIcon } from '../../assets/icons/iconRepository';
import { colors } from '../../styled/theme';
import { flex } from '../../styled/mixins';
import GlobalTheme from '../../styled/GlobalTheme';

export const TabsHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;
  padding: 0 ${(props) => props.theme.space * 2}px;
  border-radius: ${(props) => props.theme.space}px ${(props) => props.theme.space}px 0 0;
  background: ${(props) => (props.isActive ? colors.screenBackground : colors.headerTabInactive)};
  margin-right: ${(props) => (props.marginRight || `${props.theme.space}px`)};
  font-family: ${({ theme }) => theme.font.font1};
  font-size: 20px;
  font-weight: 600;
  line-height: 110%;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 20px;
    text-align: center;
  }
  @media screen and (max-width:350px){
    font-size: 18px;
  }
  ${(props) => props.isDropdown && `
    position: relative;
    ${GlobalTheme.breakpoints.down('sm')}{
      position: static;
    }
    &:after{
      content: '';
      width: 20px;
      height: 20px;
      margin-left: 8px;
      background-image: url('${ChevronDownIcon}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transform: rotate(${(props.isShowingDropdown ? '180deg' : '0')});
    }
  `}
`;

export const TabsHeadersContainer = styled.div`
  ${flex};
  position: relative;
`;

export const TabsMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: ${(props) => (props.isShowing ? 'flex' : 'none')};
  flex-direction: column;
  padding: 16px;
  background: ${colors.headerTabInactive};
  white-space:nowrap;
  border-radius: 0 2px 2px 2px;
  z-index: 200;
  ${GlobalTheme.breakpoints.down('sm')}{
    right: 0;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -48px;
`;

export const TabsComponent = styled.div`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  background: ${colors.screenBackground};
  padding-top: 32px;
  ${GlobalTheme.breakpoints.down('lg')}{
    padding-top: 16px;
  }
  ${GlobalTheme.breakpoints.down('md')}{
    padding-top: 24px;
  }
`;

export default {};
