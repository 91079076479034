import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FormController from '../../../../../components/globals/FormController';
import SwitchSelector from '../../../../../components/globals/SwitchSelector';

const AudienceGenderSwitch = ({
  genders,
  gender,
  setGender,
  className,
  required,
  showRequired,
  switchRef,
}) => (
  <FormController
    className={className}
    label={(
      <FormattedMessage
        id="onboarding.AboutAudience.gender"
        description="Audience Gender"
        defaultMessage="Gender"
      />
      )}
    required={required}
    showRequired={showRequired}
  >
    <div
      ref={switchRef}
    >
      <SwitchSelector
        values={genders}
        value={gender}
        onChange={setGender}
        exclusive
      />
    </div>
  </FormController>
);

AudienceGenderSwitch.propTypes = {
  className: PropTypes.string,
  gender: PropTypes.number.isRequired,
  genders: PropTypes.instanceOf(Array).isRequired,
  required: PropTypes.bool,
  setGender: PropTypes.func.isRequired,
  showRequired: PropTypes.bool,
  switchRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
};

AudienceGenderSwitch.defaultProps = {
  className: '',
  required: false,
  showRequired: false,
  switchRef: null,
};

export default AudienceGenderSwitch;
