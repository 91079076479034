const HOTJAR_SURVEYS = {
  leftOnboard: 'LEFT_ONBOARDING',
  cancelSubs: 'CANCEL_SUBSCRIPTION',
  disableUser: 'DISABLE_USER',
  empySN: 'NO_SOCIAL_NETWORKS',
  firstPost: 'FIRST_POST',
};

export const DELETE_SUB_SURVEY = {
  es: 'https://docs.google.com/forms/d/e/1FAIpQLScckNaSjpCuPBCvWHZD-LBmu4OTGVUk5z1TOV2VH6mT-L8NwQ/viewform',
  en: 'https://docs.google.com/forms/d/e/1FAIpQLSfmWr7zvWw-NePa_Zbjp4GsD9qIz5tEmTiuGbZJ2A1mijHLhA/viewform',
}; 
export const FIRST_TIME_POST = 'firstTimePost';
export default HOTJAR_SURVEYS;
