import React from 'react'
import styled, { css } from 'styled-components';

import { useIntl } from "react-intl";
import useAudienceInfo from '../hooks/useBusinessAudienceInfo';
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";
import AudienceForm from "../../views/Settings/tabs/Business/components/AudienceForm";
import { useContext } from "react";
import { AlertsContext } from "../../contexts/AlertsContext";
import useCallGA from "../../hooks/useCallGA";
import { AudienceProvider } from "../../services/entities";
import { materialShadows, flexBox, borderTop } from '../../styled/mixins';
import GlobalTheme from '../../styled/GlobalTheme';

const BusinessInformationCard = styled.div`
  ${(props) => props.insideACard && css`
    ${materialShadows};
    ${borderTop('speechBubble')};
    background: ${({ theme }) => theme.colors.white};
    padding: 24px 32px; 
    flex-wrap: wrap;
    position: relative;
    ${GlobalTheme.breakpoints.down('md')} {
      padding: 24px 16px;
    }
  `}
`;

const BusinessAudienceForm = ({ insideACard }) => {
  const intl = useIntl();
  const {
    audienceInfo,
    liAudienceInfo,
    potentialAudience,
    linkedinPotentialAudience,
    genders,
    setGender,
    setLocations,
    setInterests,
    setLinkedinLocations,
    setLinkedinInterests,
    setAges,
    handleUpdateSize,
    valid,
    liValid,
    shouldShow,
    isLoading,
    setIsLoading,
  } = useAudienceInfo({
    pagePath: '/business-onBoarding',
    pathTitle: 'Settings - Business-Audience',
    dataCategory: 'Settings',
  });

  const { showBackendError, showError, showAlert } = useContext(AlertsContext)
  const callGA = useCallGA()

  const handleSave = async (isLinkedin) => {
    const resValidation = isLinkedin ? liValid : valid;
    const snAudience = isLinkedin ? liAudienceInfo : audienceInfo;
    if (!resValidation) {
      showError(intl.formatMessage({
        id: 'alert.error.form.incomplete',
        defaultMessage: 'Please complete the information.',
      }))
      return
    }
    callGA({
      title: 'Post flow - About your customer audience',
      category: 'Post flow',
      action: 'Continue',
      pagePath: '/business-onBoarding',
    });
    setIsLoading(true)
    const response = await AudienceProvider.update({
      ...snAudience,
    }, false, isLinkedin);
    if (!response.success) {
      showBackendError(response.message)
    } else {
      showAlert(intl.formatMessage({
        id: 'alert.success.update.female',
        defaultMessage: '{item} updated successfully',
      }, {
        item: intl.formatMessage({
          id: 'k.audience',
          defaultMessage: 'Audience',
        }),
      }));
    }
    setIsLoading(false)
  }

  useDidUpdateEffect(() => {
    handleUpdateSize();
  }, [
    audienceInfo.locations,
    audienceInfo.interests,
    audienceInfo.gender,
    audienceInfo.ages,
  ]);

  useDidUpdateEffect(() => {
    handleUpdateSize(true);
  }, [
    liAudienceInfo.linkedinLocations,
    liAudienceInfo.linkedinInterests,
    liAudienceInfo.gender,
    liAudienceInfo.ages,
  ]);

  return (
    <BusinessInformationCard insideACard={insideACard}>
      <AudienceForm
        audienceInfo={audienceInfo}
        liAudienceInfo={liAudienceInfo}
        potentialAudience={potentialAudience}
        linkedinPotentialAudience={linkedinPotentialAudience}
        setGender={setGender}
        setLocations={setLocations}
        setInterests={setInterests}
        setLinkedinLocations={setLinkedinLocations}
        setLinkedinInterests={setLinkedinInterests}
        setAges={setAges}
        genders={genders}
        intl={intl}
        shouldShow={shouldShow}
        handleSave={handleSave}
        valid={valid}
        liValid={liValid}
        isLoading={isLoading}
      />
    </BusinessInformationCard>
  )
}

export default BusinessAudienceForm;