import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { ButtonLink } from '../../../../../components/buttons';
import { AlertsContext } from '../../../../../contexts/AlertsContext';
import { getCompany } from '../../../../../helpers/getCompany';
import useCompany from '../../../../../hooks/useCompany';
import BusinessProvider from '../../../../../services/entities/BusinessProvider';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { flexBox } from '../../../../../styled/mixins';
import CommunityInput from '../../../../Community/modals/components/CommunityInput';
import CommunityInputDate from '../../../../Community/modals/components/CommunityInputDate';
import CommunityInputFaq from '../../../../Community/modals/components/CommunityInputFaq';

const AutoResponseSettingsForm = () => {
  // eslint-disable-next-line no-unused-vars
  const intl = useIntl();
  const { companyId, selectedCompany } = useCompany();
  const { showAlert, showError } = useContext(AlertsContext);

  const [isFetching, setIsFetching] = useState(false);
  const [faqsInfo, setFaqsInfo] = useState(['']);
  const [products, setProducts] = useState(['']);
  const [openingHours, setOpeningHours] = useState([
    {
      dayFrom: '',
      dayTo: '',
      hourFrom: '',
      hourTo: '',
    },
  ]);
  const [email, setEmail] = useState(['']);
  const [phone, setPhone] = useState(['']);
  const [physicLocation, setPhysicLocation] = useState(['']);
  const [faqsInput, setFaqsInput] = useState([
    {
      question: '',
      answer: '',
    },
  ]);

  useEffect(() => {
    (async () => {
      if (!companyId || !selectedCompany?.id) return;
      if ('draft' in selectedCompany && selectedCompany.draft) return;
      const { extraBusinessInfo, faqs } = await getCompany(companyId, intl.locale);
      if (faqs) {
        setFaqsInput(faqs);
        setFaqsInfo(faqs);
      }
      if (extraBusinessInfo) {
        setProducts(extraBusinessInfo.products);
        setOpeningHours(
          extraBusinessInfo.openingHours.map((x) => ({
            dayFrom: x.from.day,
            dayTo: x.to.day,
            hourFrom: x.from.hour,
            hourTo: x.to.hour,
          })),
        );
        setPhysicLocation([extraBusinessInfo.physicLocation]);
        setEmail([extraBusinessInfo.email]);
        setPhone([extraBusinessInfo.phone]);
      }
    })();
  }, [companyId]);

  return (
    <>
      <Form>
        <CommunityInput
          placeholder={intl.formatMessage({
            id: 'community.config.companyName.placeholder',
            defaultMessage: 'E.g. Something',
          })}
          label={intl.formatMessage({
            id: 'community.config.products.label',
            defaultMessage: 'Products',
          })}
          addMore
          addMoreLimit={3}
          getter={products}
          setter={setProducts}
        />
        <CommunityInputDate
          label={intl.formatMessage({
            id: 'community.config.atentionHours.label',
            defaultMessage: 'Atention hours',
          })}
          addMore
          addMoreLimit={7}
          getter={openingHours}
          setter={setOpeningHours}
        />
        <Contact>
          <ContactColumn>
            <CommunityInput
              placeholder={intl.formatMessage({
                id: 'community.config.emailContact.placeholder',
                defaultMessage: 'E.g. Something',
              })}
              label={intl.formatMessage({
                id: 'community.config.emailContact.label',
                defaultMessage: 'Email contact',
              })}
              getter={email}
              setter={setEmail}
            />
          </ContactColumn>
          <ContactColumn>
            <CommunityInput
              placeholder={intl.formatMessage({
                id: 'community.config.phoneContact.placeholder',
                defaultMessage: 'E.g. Something',
              })}
              label={intl.formatMessage({
                id: 'community.config.phoneContact.label',
                defaultMessage: 'Phone contact',
              })}
              getter={phone}
              setter={setPhone}
            />
          </ContactColumn>
        </Contact>
        <CommunityInput
          placeholder={intl.formatMessage({
            id: 'community.config.addressContact.placeholder',
            defaultMessage: 'E.g. Something',
          })}
          label={intl.formatMessage({
            id: 'community.config.addressContact.label',
            defaultMessage: 'Address contact',
          })}
          getter={physicLocation}
          setter={setPhysicLocation}
        />
        <CommunityInputFaq
          placeholderQ={intl.formatMessage({
            id: 'community.config.faqs.placeholderQ',
            defaultMessage: 'E.g. Something',
          })}
          placeholderA={intl.formatMessage({
            id: 'community.config.faqs.placeholderA',
            defaultMessage: 'E.g. Something',
          })}
          label={intl.formatMessage({
            id: 'community.config.faqs.label',
            defaultMessage: 'Faqs',
          })}
          addMore
          addMoreLimit={3}
          getter={faqsInput}
          setter={setFaqsInput}
        />
      </Form>
      <ButtonWrapper>
        <ButtonLink
          disabled={isFetching}
          style={{ marginTop: 20 }}
          onClick={async () => {
            setIsFetching(true);

            const faqs = faqsInput.map(({ __typename, ...f }) => f);
            const faqPromise = BusinessProvider.createOrUpdateFaq({ faqs, companyId });

            try {
              const isFirstEmpty = Object.values(openingHours[0] ?? {}).every((val) => val === '');
              const mapHours = () =>
                openingHours.map((x) => ({
                  from: { day: x.dayFrom, hour: x.hourFrom },
                  to: { day: x.dayTo, hour: x.hourTo },
                }));

              const payload = {
                products,
                openingHours: !isFirstEmpty ? mapHours() : [],
                email: email[0],
                phone: phone[0],
                physicLocation: physicLocation[0],
                companyId: companyId,
              };

              const extraPromise = BusinessProvider.updateExtraBusinessInfo(payload);

              await Promise.all([faqPromise, extraPromise]);

              showAlert(intl.formatMessage({ id: 'community.config.faqs.toast.success' }));
            } catch (err) {
              showError(
                intl.formatMessage(
                  { id: 'k.somethingWentWrong' },
                  {
                    a: (...chunks) => (
                      <SupportEmail href='mailto:support@socialpiper.com'>{chunks}</SupportEmail>
                    ),
                  },
                ),
              );
              console.error(err);
            } finally {
              setIsFetching(false);
            }
          }}
        >
          <FormattedMessage id='k.saveChanges' defaultMessage='Save Changes' />
        </ButtonLink>
      </ButtonWrapper>
    </>
  );
};

AutoResponseSettingsForm.propTypes = {};

export default AutoResponseSettingsForm;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Contact = styled.div`
  width: 100%;
  display: flex;
  gap: 26px;
  ${GlobalTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const ContactColumn = styled.div`
  width: calc(100% - 13px);
`;

const ButtonWrapper = styled.div`
  flex: 0 0 100%;
  ${flexBox}
  justify-content: center;
`;

const SupportEmail = styled.a`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
`;
