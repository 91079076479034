import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import GlobalTheme from '../../styled/GlobalTheme';
import { colors } from '../../styled/theme';
import useResize from '../../hooks/useResize';
import TabsHeaders from './TabsHeaders';

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -48px;
`;

const TabsComponent = styled.div`
  background: ${colors.screenBackground};
  padding-top: 32px;
  ${GlobalTheme.breakpoints.down('lg')}{
    padding-top: 16px;
  }
  ${GlobalTheme.breakpoints.down('md')}{
    padding-top: 24px;
  }
`;

const Tabs = (props) => {
  const {
    tabs,
    defaultTab,
    convertsToDropdown,
    match,
    location,
    gaTitle,
  } = props;

  const [activeHeader, setActiveHeader] = useState(defaultTab);
  const [maximumTabsToDisplay, setMaximumTabsToDisplay] = useState(4);

  const [showTabsMenu, setShowTabsMenu] = useState(false);

  const [tabsComponents, setTabsComponents] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (headers.length > 0) {
      setHeaders([]);
    }
    if (tabs.length > 0) {
      setTabsComponents(tabs);
    }
  }, [tabs]);

  useEffect(() => {
    if (tabsComponents.length > 0) {
      const headersArray = tabsComponents.map((tab) => {
        const { header } = tab;
        if (window.innerWidth < 365 && convertsToDropdown) {
          const [name] = header.name.split(' ');
          header.name = name;
        }
        return header;
      });
      setHeaders(headersArray);
    }
  }, [tabsComponents]);

  const shiftHeaderToFirst = (index) => {
    // Make copy of clicked component
    const selectedHeader = headers[index];
    // Make copy of tabsComponent
    const headersCopy = [...headers];
    // Remove clicked component from tabs copy
    headersCopy.splice(index, 1);
    // Update tabsComponent
    setHeaders([selectedHeader, ...headersCopy]);
  };

  useEffect(() => {
    if (headers.length > 0) {
      let index = headers.map((h) => h.to).indexOf(location.pathname);
      index = index < 0 ? 0 : index;
      setActiveHeader(index);
      if (convertsToDropdown && maximumTabsToDisplay < 4) {
        shiftHeaderToFirst(index);
        // Set active index 0, as the clicked header was moved to that index
        setActiveHeader(0);
      }
    }
  }, [location, headers.length, tabsComponents, maximumTabsToDisplay]);

  const handleResize = () => {
    // Check if its phone
    if (window.innerWidth < GlobalTheme.breakpoints.values.md) {
      // window width is less than 768
      setMaximumTabsToDisplay(1);
      // Check if its tablet portrait
    } else if (window.innerWidth < GlobalTheme.breakpoints.values.lg) {
      // window width is less than 992
      setMaximumTabsToDisplay(2);
    } else if (window.innerWidth < GlobalTheme.breakpoints.values.xl) {
      // window width is less than 1200
      setMaximumTabsToDisplay(3);
    } else {
      setMaximumTabsToDisplay(4);
    }
  };

  useResize(handleResize);

  useEffect(() => {
    handleResize();
  }, [convertsToDropdown]);

  return (
    <TabsContainer>
      <TabsHeaders
        headers={headers}
        activeHeader={activeHeader}
        showTabsMenu={showTabsMenu}
        setShowTabsMenu={setShowTabsMenu}
        convertsToDropdown={convertsToDropdown}
        maximumTabsToDisplay={maximumTabsToDisplay}
        gaTitle={gaTitle}
      />
      <TabsComponent>
        <Route
          exact
          path={match.url}
          component={tabs[0].Component}
        />
        {tabs.map((tab) => (
          <Route
            key={`Route_${tab.header.name}`}
            path={tab.header.to}
            component={tab.Component}
          />
        ))}
      </TabsComponent>
    </TabsContainer>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    Component: PropTypes.node.isRequired,
  })).isRequired,
  defaultTab: PropTypes.number,
  convertsToDropdown: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  gaTitle: PropTypes.string,
};

Tabs.defaultProps = {
  defaultTab: 0,
  convertsToDropdown: false,
  gaTitle: '',
};

export default Tabs;
