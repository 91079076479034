import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "react-stripe-elements";
import styled from "styled-components";

import {
  americanExpressIcon,
  discoverIcon,
  masterCardIcon,
  poweredStripe,
  visaIcon,
} from "../../assets/icons/iconRepository";
import {
  RadioCheck,
  RadioControl,
  RadioUncheck,
} from "../../components/PlanSelector/index.styled";
import usePlan from "../../components/PlanSelector/usePlan";
import { Button } from "../../components/buttons";
import FormController from "../../components/globals/FormController";
import PiperAdvice from "../../components/globals/PiperAdvice";
import { text } from "../../components/text";
import GlobalTheme from "../../styled/GlobalTheme";
import colors from "../../styled/colors";
import { input } from "../../styled/mixins";

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default (props) => {
  const intl = useIntl();
  const { plan } = usePlan();
  const [period, setPeriod] = useState("monthly");
  const [subscribing, setSubscribing] = useState(false);

  const togglePeriod = (p) => () => setPeriod(p);

  const handleSubscribe = async () => {
    setSubscribing(true);

    await timeout(3000);

    setSubscribing(false);
    props.handleExtraSubscribeEvent();
  };

  return (
    <>
      <Hero>
        <GlobalTitle>{text("onboarding.globaltitle")}</GlobalTitle>

        <section>
          <Title>{text("onboarding.billing.selectPlan")}</Title>

          <BillingPeriodWrapper>
            <BillingPeriod onClick={togglePeriod("monthly")}>
              <RadioControl
                value=""
                style={{ marginLeft: 0, marginRight: 0, gap: 0 }}
                onClick={togglePeriod("monthly")}
                control={
                  period === "monthly" ? <RadioCheck /> : <RadioUncheck />
                }
              />
              <PeriodText>{text("k.monthlyPlan")}</PeriodText>
              {/* <CrossedPrice>$99</CrossedPrice> */}
              <Price>USD $149</Price>
            </BillingPeriod>
            <BillingPeriod onClick={togglePeriod("annually")}>
              <RadioControl
                value=""
                style={{ marginLeft: 0, marginRight: 0, gap: 0 }}
                onClick={togglePeriod("annually")}
                control={
                  period === "annually" ? <RadioCheck /> : <RadioUncheck />
                }
              />
              <PeriodText>{text("k.yearlyPlan")}</PeriodText>
              {/* <CrossedPrice>$990</CrossedPrice> */}
              <Price>USD ${149 * 10}</Price>
              <FreeMonthBadget>
                {text("landing.pricing.owner.yearly.freeMonths")}
              </FreeMonthBadget>
            </BillingPeriod>
          </BillingPeriodWrapper>
        </section>

        <section>
          <Title>{text("onboarding.billing.addPayment")}</Title>

          <section style={{ marginBottom: 25 }}>
            <StyledPiperAdvice>{text("addPayment.advice")}</StyledPiperAdvice>

            <CardsImg src={poweredStripe} alt="cards" />
            <CardsImg src={visaIcon} alt="cards" />
            <CardsImg src={masterCardIcon} alt="cards" />
            <CardsImg src={americanExpressIcon} alt="cards" />
            <CardsImg src={discoverIcon} alt="cards" />
          </section>

          <StripeForm>
            <FormElement
              mandatory
              label={text("addPayment.cardDetails.cardNumberShort")}
              requiredTxt={intl.formatMessage({ id: "k.required" })}
              beforeLeft={155}
              col="1/3"
              row="1"
            >
              <StyledStripeElement>
                <CardNumberElement
                  id="card-input-number"
                  style={stripeStyles}
                />
              </StyledStripeElement>
            </FormElement>

            <FormElement
              mandatory
              requiredTxt={intl.formatMessage({ id: "k.required" })}
              beforeLeft={36}
              label={text("addPayment.cardDetails.expirationDateShort")}
              col="1/2"
              row="2"
            >
              <StyledStripeElement>
                <CardExpiryElement
                  id="card-input-expiry"
                  style={stripeStyles}
                />
              </StyledStripeElement>
            </FormElement>

            <FormElement
              mandatory
              requiredTxt={intl.formatMessage({ id: "k.required" })}
              beforeLeft={36}
              label="CVC"
              col="2/3"
              row="2"
            >
              <StyledStripeElement>
                <CardCvcElement id="card-input-cvc" style={stripeStyles} />
              </StyledStripeElement>
            </FormElement>
          </StripeForm>

          <StyledPiperAdvice>
            {text("onboarding.tooltip.pro.cvc.description")}
          </StyledPiperAdvice>
        </section>

        <SubscribeButton handlerClick={handleSubscribe} isLoading={subscribing}>
          {text("onboarding.billing.continueButton.without.trial")}
        </SubscribeButton>
      </Hero>
    </>
  );
};

const SubscribeButton = styled(Button)`
  && {
    width: 100%;
    margin-top: 30px;
  }
`;

const GlobalTitle = styled.h2`
  color: ${(props) => props.theme.colors.black3E};
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 40px;
  max-width: 60%;

  ${GlobalTheme.breakpoints.down("sm")} {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
  }
`;

const FreeMonthBadget = styled.span`
  border-radius: 3px;
  display: grid;
  place-items: center;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 6px;
  background: ${(props) => props.theme.colors.primaryMoreLighter};
  color: ${(props) => props.theme.colors.grayF5};

  ${GlobalTheme.breakpoints.down("md")} {
    font-size: 10px;
  }
`;

const BillingPeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
`;

const BillingPeriod = styled.div`
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const PeriodText = styled.p`
  color: ${(props) => props.theme.colors.black3E};
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;

  ${GlobalTheme.breakpoints.down("md")} {
    font-size: 16px;
  }
`;

const CrossedPrice = styled.i`
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.theme.colors.gray02};

  &:after {
    content: "X";
    position: absolute;
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    color: ${(props) => props.theme.colors.black};
  }

  ${GlobalTheme.breakpoints.down("md")} {
    font-size: 14px;
  }
`;

const Price = styled.i`
  color: ${(props) => props.theme.colors.black3E};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;

  ${GlobalTheme.breakpoints.down("md")} {
    font-size: 14px;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;

  ${GlobalTheme.breakpoints.down("sm")} {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 500;
  }
`;

const StripeForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 20px;
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardsImg = styled.img`
  width: 40px;
  height: 25px;
  margin: 10px 8px;
  ${GlobalTheme.breakpoints.down("sm")} {
    width: 35px;
    height: 20px;
    margin: 10px 4px;
  }
`;

const StyledStripeElement = styled.div`
  ${input}
  color: ${(props) => props.theme.colors.black};
  background-color: white;
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  margin: 0;
  display: flex;
  align-items: center;
`;

const stripeStyles = Object.freeze({
  base: {
    color: colors.black,
    letterSpacing: "normal",
    fontSize: "18px",
    fontFamily: "Open Sans",
    "::placeholder": {
      color: colors.gray90,
    },
  },
  invalid: {
    color: colors.red,
  },
});

const FormElement = styled(FormController)`
  position: relative;
  grid-column: ${(props) => props.col || "unset"};
  grid-row: ${(props) => props.row || "unset"};
  color: ${(props) => props.theme.colors.black};
  margin: 12px 0;

  ${(props) =>
    !props.mandatory
      ? ""
      : `
		&:before {
			content: '* ${props.requiredTxt}';
			color: ${props.theme.colors.orange};
			position: absolute;
			left: ${props.beforeLeft}px;
			top: 0;
			font-size: 12px;
			font-weight: 400;
		}
	`}
`;
