const en = {
  'tutorialModal.skip': 'Skip',
  'tutorialModal.done': 'Done',
  'tutorialModal.slide1.title': 'Now you’re set!',
  'tutorialModal.slide1.masterUser.title': 'Welcome to the Bussiness account!',
  'tutorialModal.slide1.collabUser.title': 'Let’s get started!',
  'tutorialModal.slide1.text': 'Check your Piper Score and see how your business is doing in your Dashboard.',
  'tutorialModal.slide1.masterUser.text': 'Add as many businesses or products as you want, connect your social networks for each one.',
  'tutorialModal.slide1.collabUser.text': 'On the Bussiness plan, you can manage the businessess / products your are asigned to.',
  'tutorialModal.slide2.text': 'Check the Creative Center and choose from my recommendations or from our hundreds of ready-to-use templates',
  'tutorialModal.slide2.masterUser.text': 'Add team members to manage your businesses’s social networks.',
  'tutorialModal.slide2.collabUser.text': 'Check the Creative Center and choose from my recommendations or from our hundreds of ready-to-use templates.',
  'tutorialModal.slide3.text': 'Get my Good time to post recommendations to publish your content, schedule it and organize your content and Ads',
  'tutorialModal.slide3.masterUser.text': 'Supervise shceduled posts and boosts with the approbal system.',
  'tutorialModal.slide3.collabUser.text': 'Get my best time to post recommendations to publish your content, schedule it and organize your content and Ads.',
  'tutorialModal.slide4.text': 'Check at a glance how are your Social Ads performing and manage your advertising Budget',
  'tutorialModal.slide4.masterUser.text': 'Check at a glance how are your Social Ads performing and manage your advertising Budget on each of your business or products.',
  'tutorialModal.slide4.collabUser.text': 'Schedule posts or boosts and send to approval.',
  'tutorialModal.slide5.text': 'Interact with your social media users in a single place by connecting your social profiles',
  'tutorialModal.slide5.masterUser.text': 'Use my recommended templates on the Creative Center. Look for my good time to post to publish, schedule and organize your content for your businessess on the Sheduler section.',
  'tutorialModal.slide5.collabUser.text': 'Check at a glance how are your Social Ads performing on each of your assigned business.',
  'tutorialModal.slide6.masterUser.text': 'Interact with your social media users in a single place by connecting your social profiles for each of your businesses.',
  'tutorialModal.slide6.collabUser.text': 'Interact with your social media users for each business or product in a single place.',
  'tutorialModal.piperTip': "Hi! I'm Piper",
  'tutorialModal.createapp.slide1.title': 'Welcome',
  'tutorialModal.createapp.slide1.text': 'Use the Piper Score and see how your business is doing in your Dashboard.',
  'tutorialModal.createapp.slide2.text': 'Check the Creative Center and choose from my recommendations or from our hundreds of ready-to-use templates.',
  'tutorialModal.createapp.slide3.text': 'Get my Good time to post recommendations to publish your content, schedule it and organize your content and Ads.',
  'tutorialModal.createapp.slide4.text': 'Easily create Social Ads, check their performance and manage your advertising budget.',
  'tutorialModal.createapp.slide5.text': 'Interact with your social media users in a single place by connecting your social profiles.',
};

export default en;
