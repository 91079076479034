import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Dialog as MuiDialog } from '@material-ui/core';
import { Button } from '../../../../../components/buttons';
import Pipers from '../../../../../components/Pipers/Pipers';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import roles from '../../../../../utils/constants/roles';

const StyledModal = styled(MuiDialog)`
&& {
  .MuiPaper-root{
    min-width: 100%;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
    height: inherit;
  }
  ${GlobalTheme.breakpoints.up('lg')} {
    .MuiPaper-root{
      max-height: 530px;
      min-width: 788px;
    }
  }
}
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  ${GlobalTheme.breakpoints.down('sm')}{
    justify-content: center;
  }
`;

const PiperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const PiperImg = styled.div`
  width: 150px;
  height: auto;
  ${GlobalTheme.breakpoints.up('sm')}{
    width: 200px;
  }
`;

const MessageBox = styled.div`
  width: 100%;
  text-align: center;
`;

const Subtitle = styled.p`
  width: 90%;
  margin: 1em auto 0;
  font-weight: 600;
  line-height: 32px;
  font-size: 20px;
  ${GlobalTheme.breakpoints.up('sm')}{
    font-size: 22px;
  }
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 24px;
  }
`;

const Text = styled.div`
  width: 90%;
  margin: 0 auto;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => (theme.colors.text02)};
  font-size: 16px;
  ${GlobalTheme.breakpoints.up('sm')}{
    font-size: 18px;
  }
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 20px;
  }
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
`;

const UpgradeConfirmation = ({ open, setOpen, handleTransition, role }) => {
  
    return (
      <StyledModal
      open={open}
      fullScreen={window.innerWidth <= GlobalTheme.breakpoints.values.sm}  
      >
        <Container>
        <PiperContainer>
          <PiperImg>
              <Pipers.CelebrateConfetti />
          </PiperImg>
        </PiperContainer>
        <MessageBox>
          <Subtitle>
            <FormattedMessage
              id={role === roles.PRO ? 'settings.downgradeToProConfirmation.success.subtitle' : 'settings.upgradeToBusinessConfirmation.success.subtitle'}
              defaultMessage={'Congrats! You have succesfully suscribe to Professional Plan!'}
            />
          </Subtitle>
          <Text>
              <FormattedMessage
                id="settings.upgradeToBusinessConfirmation.success.text"
                defaultMessage=""
              />
          </Text>
        </MessageBox>
        <ButtonContainer>
          <Button onClick={() => handleTransition(setOpen)}>
            <FormattedMessage
              id="k.ok"
              defaultMessage="ok"
            />
          </Button>
        </ButtonContainer>
      </Container>
      </StyledModal>
    )
};

UpgradeConfirmation.propTypes = {
  open: PropTypes.bool, 
  setOpen: PropTypes.func, 
  handleTransition: PropTypes.func,
  role: PropTypes.string
}

export default UpgradeConfirmation;