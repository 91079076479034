import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';
import { FormattedMessage, useIntl } from 'react-intl';
import { colors } from '../../../../../styled/theme';
import { heading3, paragraph } from '../../../../../styled/mixins';
import { capitalize, formatNumberAndUnit } from '../../../../../utils';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import mediaQueries from '../../../../../utils/mediaQueries';

import translationConstants from '../../../../../i18n/translations/global/constants/en';

const Container = styled.div``;

const AudienceChartContainer = styled.div`
  margin: 0 ${(props) => (props.isModal ? '0' : '56px')};
  padding: 16px 8px;
  border: 1px solid ${colors.gray90};
  border-radius: 8px;
  position: relative;
  ${GlobalTheme.breakpoints.down('md')}{
    margin: 0 ${(props) => (props.isModal ? '0' : '15%')};
  }
  @media (max-device-width: 992px) and (orientation: landscape) {
    margin: 0 ${(props) => (props.isOnboarding ? '0' : '5%')};
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    margin: 0;
  }
`;

const Title = styled.h3`
  ${heading3}
  font-size: ${({ isModal }) => (isModal ? '12px' : '14px')};
  margin-bottom: 24px;
  text-align: center;
  ${GlobalTheme.breakpoints.down('md')} {
    font-size: 12px;
  }
`;

const Paragraph = styled.p`
  ${paragraph}
  font-size: 12px;
  text-align: center;
  font-weight: 600;
`;
const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8%;
`;
const SpecificLabel = styled(Paragraph)``;

const RecommendedLabel = styled(Paragraph)`
  width: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40px;
`;

const BroadLabel = styled(Paragraph)``;

const ReachLabel = styled(Paragraph)`
  font-weight: 400;
  margin-top: 8px;
`;

const gaugeStyleMobile = {
  width: 'calc(100% - 60px)',
  textAlign: 'center',
  margin: 'auto',
};

const gaugeStyleDesktop = {
  width: 'calc(100% - 50px)',
  maxWidth: '100%',
  textAlign: 'center',
  margin: 'auto',
};

const GaugeChartContainer = styled.div`
  position: relative;
`;

const AudienceChart = ({
  socialNetwork,
  percent,
  size,
  lowerRange,
  middleRange,
  upperRange,
  isModal,
  className,
}) => {
  const intl = useIntl();
  const [estimatedReach, setEstimatedReach] = useState({
    size: 0,
    unit: '',
  });

  useEffect(() => {
    const [number, unit] = formatNumberAndUnit(size);
    setEstimatedReach({
      size: number,
      unit,
    });
  }, [size]);

  return (
    <Container>
      <AudienceChartContainer
        isModal={isModal}
        className={className}
      >
        <Title
          color={colors[socialNetwork]}
          isModal={isModal}
        >
          <FormattedMessage
            id={`settings.primaryTarget.audience.size.chart.title.${socialNetwork}`}
            defaultMessage={`Potential Audience Size in ${capitalize(socialNetwork)}`}
          />
        </Title>
        <Media queries={mediaQueries}>
          {(matches) => (
            <GaugeChartContainer>
              <RecommendedLabel>
                {middleRange.label}
              </RecommendedLabel>
              <GaugeChart
                id={`audience-gauge-chart-${socialNetwork}`}
                animate
                percent={percent}
                needleColor={colors.black}
                hideText
                arcsLength={[lowerRange.length, middleRange.length, upperRange.length]}
                colors={[lowerRange.color, middleRange.color, upperRange.color]}
                needleBaseColor={colors.black}
                style={matches.smDown ? gaugeStyleMobile : gaugeStyleDesktop}
              />
              <RangeContainer>
                <SpecificLabel>
                  {lowerRange.label}
                </SpecificLabel>
                <BroadLabel>
                  {upperRange.label}
                </BroadLabel>
              </RangeContainer>
              <ReachLabel>
                <FormattedMessage
                  id="settings.primaryTarget.audience.size.chart.reach"
                  defaultMessage="Estimated reach is {size} people"
                  values={{
                    size: estimatedReach.unit ? intl.formatMessage({
                      id: `k.numberUnit.${estimatedReach.unit}`,
                      defaultMessage: translationConstants[`k.numberUnit.${estimatedReach.unit}`],
                    }, {
                      number: estimatedReach.size,
                    }) : estimatedReach.size,
                  }}
                />
              </ReachLabel>
            </GaugeChartContainer>
          )}
        </Media>
      </AudienceChartContainer>
    </Container>
  );
};

AudienceChart.propTypes = {
  socialNetwork: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  lowerRange: PropTypes.instanceOf(Object).isRequired,
  middleRange: PropTypes.instanceOf(Object).isRequired,
  upperRange: PropTypes.instanceOf(Object).isRequired,
  isModal: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

AudienceChart.defaultProps = {
  className: '',
};

export default AudienceChart;
