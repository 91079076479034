import gql from 'graphql-tag';

export const createSocialMediaAttemptMutation = gql`
  mutation createSocialMediaAttempt(
    $companyId: Int!
    $socialMediaType: String
  ) {
    createSocialMediaAttempt(
      data: {
        companyId: $companyId
        socialMediaType: $socialMediaType
      }
    ) {
      id
      companyId,
      socialMediaType
    }
  }
`;