import gql from 'graphql-tag';

export const createUser = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      id
      authId
      settings {
        requiredCard
        currencyCode
      }
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($first_name: String, $last_name: String, $email: String){
    updateUser(data: {
      firstName: $first_name
      lastName: $last_name
      email: $email
    }){
      id,
      updateSession,
    }
  }
`;

export const sendInviteToCollaborator = gql`
  mutation SendCollaboratorInvite($input: EmailInput) {
    sendCollaboratorInvite(input: $input) {
      success
      message
    }
  }
`;

export const updateAuthUser = gql`
  mutation updateAuthUser($given_name: String, $family_name: String, $email: String, $blocked: Boolean){
    updateAuthUser(name: $email, givenName: $given_name, familyName: $family_name, email: $email, blocked: $blocked)
  }
`;

export const updateUserSettings = gql`
  mutation updateUserSettings($data: UserUpdateSettingsInput!){
    updateUserSettings(data: $data){
      success
    }
  }
`;

export const deleteOwnUser = gql`
  mutation deleteOwnUser {
    deleteOwnUser
  }
`;

export const updateUserPassword = gql`
  mutation updateUserPassword($password: String!) {
    updateUserPassword(password: $password)
  }
`;

export const updateUserMonthlyBudget = gql`
  mutation updateUserMonthlyBudget($monthlyBudget: String!) {
    updateUserMonthlyBudget(monthlyBudget: $monthlyBudget)
  }
`;

export const resetPassword = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const updateAuthIdByUser = gql`
  mutation updateAuthIdByUser($email: String, $authId: String){
    getUserByEmail(email: $email, authId: $authId){
      id
      email
      authId
    }
  }
`;

export const updateUserCurrency = gql`
  mutation updateUserCurrency($currencyCode: String){
    updateUserCurrency(currencyCode: $currencyCode){
      currencyCode
      usdExchangeRate
    }
  }
`;
