import React, { useCallback, useContext, useState } from 'react';

import { PiperDogStandAlert } from '../components/Pipers/PiperDogStandAlert';

import BusinessAudienceForm from '../business/components/BusinessAudienceForm';
import BusinessSocialNetworks from '../business/components/BusinessSocialNetworks';
import ManageCollaboratorsForm from '../components/ManageCollaboratorsForm';
import { text } from '../components/text';
import { StepsCtx } from '../views/BusinessOnBoarding/index.provider';
import BusinessInformationForm from '../views/Settings/tabs/Business/components/BusinessInformationForm';

const socialNetworksInitialState = {
  facebook: {
    requireAttention: false,
    toSetup: false,
  },
  instagram: {
    toSetup: false,
    requireAttention: false,
  },
  twitter: {
    avatar: '',
    pageName: '',
    requireAttention: false,
  },
  linkedin: {
    pageName: '',
  },
};

export default () => {
  const { steps, dispatch } = useContext(StepsCtx);
  const [_log, setIsNewLogo] = useState(false);

  const checkNextStep = (nextStep) => {
    dispatch({ type: 'next_step', payload: nextStep });
  };

  const findStepById = (id) => {
    return steps.find((step) => step.id === id);
  };

  const modalWrapper = useCallback(
    (stepId) =>
      [
        <BusinessInformationForm setIsNewLogo={setIsNewLogo} />,
        <>
          <PiperDogStandAlert
            message={text('onboarding.biz.step2.piper.advice')}
            orientation='center'
          />
          <BusinessSocialNetworks />
        </>,
        <BusinessAudienceForm />,
        <ManageCollaboratorsForm />,
      ][stepId - 1],
    [],
  );

  return {
    steps,
    findStepById,
    checkNextStep,
    modalWrapper,
  };
};
