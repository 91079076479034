import React, { createContext, useMemo, useReducer } from "react";
import { reducer } from "./index.reducer";
import struct from "./index.struct";

export const StepsCtx = createContext(null);

const StepsProvider = (props) => {
  const [steps, dispatch] = useReducer(reducer, struct);

  const value = useMemo(() => ({ steps, dispatch }), [steps, dispatch]);

  return <StepsCtx.Provider value={value}>{props.children}</StepsCtx.Provider>;
};

export default StepsProvider;
