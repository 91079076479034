const en = {
  'onboarding.globaltitle': 'About your promo',
  'onboarding.aboutbiz.action1': 'Pre-fill this data from your facebook business profile',
  'onboarding.aboutbiz.goToDashboard': 'Explore the dashboard instead',
  'onboarding.aboutbiz.heading': 'Tell us about your business',
  'onboarding.aboutbiz.heading.variant': 'Tell us about you and your business',
  'onboarding.aboutAudience.heading': 'Tell us about your customer audience',
  'onboarding.aboutbiz.inputUserFirstName': 'Your First Name',
  'onboarding.aboutbiz.inputUserLastName': 'Your Last Name',
  'onboarding.aboutbiz.inputName': 'Your Business Name',
  'onboarding.aboutbiz.inputPlaceholder': 'E.g. Joe\'s BBQ house',
  'onboarding.aboutbiz.text': 'Let us know a bit more about what you do. This information helps us deliver recommendations for your business like what to post, when to post and how much money is needed in advertising to reach more of your audience.',
  'onboarding.aboutbiz.uploadLogo': 'Upload your company logo',
  'onboarding.creteAccount.BoostingPowerParagraph': 'You have $150 left and you can reach your target of 17,000 users with a post tonight.',
  'onboarding.createAccount.BoostingPowerInsights': '<strong>Piper Insights</strong> help you Boost to your biggest audience and take control of your spending with AI-driven messages',
  'onboarding.aboutbiz.logoAdvice': 'Your company logo is optional but highly recommended, since it is used in the templates we have for you; however you can upload it later in the Settings section.',
  'onboarding.billing.heading': 'Start your free {trialDuration}-day trial',
  'onboarding.billing.pro.enjoyFeatures': 'And enjoy unlimited access to all our features.',
  'onboarding.billing.pro.subscribing': 'You\'re subscribing to <b>Pro Plan</b>',
  'onboarding.billing.basic.subscribe': 'Do you want to try the Lite Plan first?',
  'onboarding.billing.selectPlan': 'Select your Billing Plan',
  'onboarding.billing.addPayment': 'Add a Payment Method',
  'onboarding.billing.addPayment.email': 'Billing email address',
  'onboarding.billing.continueButton': 'Start trial',
  'onboarding.billing.continueButton.without.trial': 'Subscribe',
  'onboarding.billing.pro.note': 'You can cancel at any time with no commissions. Your current plan benefits will be usable until the end of your last billed month.',
  'onboarding.billing.charging.note': 'You can cancel at any time with no commissions. Your current plan benefits will continue to be available until the end of your trial period.',
  'onboarding.billing.charging.validateFoundsNote': 'We will charge you $ 1 USD to verify your payment method. Once verified, it will be refunded.',
  'onboarding.tooltip.business.name.title': 'Your business name',
  'onboarding.tooltip.business.name.description': 'Tell us the name by which your customers know your business.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'onboarding.tooltip.business.city.title': 'Your city',
  'onboarding.tooltip.business.city.description': 'What city is your business in? You can even select a state, country or region.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'onboarding.tooltip.business.category.title': 'Type of business',
  'onboarding.tooltip.business.category.description': 'Tell us what you do in your business; You can describe it by choosing one or more categories from the list, just start typing and we will help you to find them quickly.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'onboarding.tooltip.audience.locations.title': 'Audience locations',
  'onboarding.tooltip.audience.locations.description': 'You can choose one or more locations, in addition to a city, you can indicate a state, country or region. We recommend that you only choose the main ones or where your business has the greatest influence, since this information will be used when creating boosts on Facebook.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'onboarding.tooltip.audience.interests.title': 'Audience interests',
  'onboarding.tooltip.audience.interests.description': 'Choose one or more interests that describe best the market you are targeting. We recommend that you choose only those that are the most relevant, since this information will be used when creating boosts on Facebook, and for Piper to give you more accurate recommendations on graphic templates that may interest you to publish.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'onboarding.tooltip.audience.gender.title': 'Gender',
  'onboarding.tooltip.audience.gender.description': 'It is highly recommended that you choose the genre that conforms the majority of your audience for your business, since this information will be used when creating boosts on Facebook.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'onboarding.tooltip.audience.age.title': 'Age',
  'onboarding.tooltip.audience.age.description': 'Here you can choose the age range that conforms the majority of your audience for your business. We recommend that you choose a range within which the majority of your audience is located, since this information will be used when creating boosts on Facebook and thus could be more efficient.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'onboarding.tooltip.audience.potential.title': 'Your potential audience',
  'onboarding.billing.apple.continueButton': 'Pay & Continue',
  'onboarding.mesaage.apple.transaction.fail': 'Sorry, your transaction could not be completed, please validate your card information in the App Store',
  'onboarding.billing.trial.freeTrial': 'Free Trial',
  'onboarding.billing.trial.reminder': '7 day reminder',
  'onboarding.billing.trial.list1': 'We will remind you <blue>7 days before your free trial ends.</blue>',
  'onboarding.billing.trial.list2': 'An email reminder will be sent on <blue>{reminder}.</blue>',
  'onboarding.billing.trial.list3': '<blue>You pay US $ 0 now.</blue> Set up a payment for later.',
  'onboarding.billing.trial.today': 'Today',
  'onboarding.tooltip.pro.cvc.title': 'CVC',
  'onboarding.tooltip.pro.cvc.description': 'CVC are the three numbers of your security code in the back of your card',
  'k.save.category': 'Save and show templates',
  'alert.error.category': 'Select at least one type of business',
  'onboarding.billing.only.credit.card.text': 'Only credit cards',
  'onboarding.billing.select.plan.title': '1. Select how often you want to pay',
  'onboarding.billing.pay.title': '2. Ad a method payment',
  'onboarding.billing.pay.stripe.text': 'Secure payment with',
  'planOption.selected.text.info': 'Billed {period} for {currency} {price}',
  'planOption.selected.period.monthly': 'monthly',
  'planOption.selected.period.yearly': 'yearly',
  'onboarding.billing.trial.info.title': 'How does your {days}-days free trial work?',
  'onboarding.billing.trial.info.date.start': '<blue>Start trial</blue>{linebreak}{date}',
  'onboarding.billing.trial.info.date.end': '<blue>1st payment</blue>{linebreak}{date}',
  'onboarding.billing.trial.info.trial.duration': '{days}-days free trial',
  'onboarding.billing.trial.info.trial.before': 'We will remind you 7 days before',
  'onboarding.billing.trial.info.list.one': 'We will remind you <bold>7 days before</bold> your trial period ends.',
  'onboarding.billing.trial.info.list.two': '<blue>Now you don\'t pay anything</blue>. Setup a payment for later.',
  'onboarding.billing.faq.link.more': 'More questions',
  'onboarding.billing.faq.question.1': 'Can I cancel my account at any time?',
  'onboarding.billing.faq.answer.1': 'Yes, you can cancel your account at any time, just go to settings, click deactivate the account option, and your account will be canceled. If you decide to use SocialPiper again, we can reactivate your account.',
  'onboarding.billing.faq.question.2': 'Can I pay quaterly?',
  'onboarding.billing.faq.answer.2': 'At the moment we only have two options for your recurring subscription, these are monthly and yearly.',
  'onboarding.billing.faq.question.3': 'What happens if there is a problem with my credit card?',
  'onboarding.billing.faq.answer.3': 'Every month we will try to charge you for the subscription. In case there’s a problem, our system will try again to make a charge to your credit card. If the problem persists, we will contact you to let you know there’s a problem with your credit card, so you can check with your bank or update your card information. In case there’s no response, you will be able to use the platform for 7 days, and after this, your account will be locked, and you will be prompted to update your payment information.',
  'onboarding.schedule.date.title1': 'Only one step to go!',
  'onboarding.schedule.date.title2': 'All ready!',
  'onboarding.schedule.date.title1v2': 'Schedule your free consultation',
  'onboarding.schedule.date.title2v2': 'All set!',
  'onboarding.schedule.date.calendly1': 'A Piper Expert will help you set up and use the various features available on your account. ',
  'onboarding.schedule.date.calendly2': 'We guarantee you will have the best experience in Social Piper, and we will resolve any questions you have about how to use this incredible platform. Just select a date and time, then click ‘confirm’ to schedule a video call with a Piper Expert.',
  'onboarding.schedule.date.info0': '{userName}: your consultation with a Piper Expert is scheduled for:',
  'onboarding.schedule.date.info1': 'You will receive the details and zoom link for your video call in your email.',
  'onboarding.schedule.date.info2': 'In the meantime, you can continue exploring Social Piper by reviewing the recommendations from Piper and take a look at the 3,000+ templates available to you.',
  'onboarding.schedule.date.info3': 'If you want to modify or cancel your consultation, you can do so from the email you received confirming your consultation.',
  'onboarding.support.text1': 'If you want to schedule the video call for another time or if you have any questions, please write to us at <link>{emailSupport}</link> or our live chat.',
  'onboarding.schedule.button.goToSocialpiper': 'Go to Social Piper',
  // linked in boost
  'onBoarding.audience.modal': 'Define your clients',
  'onboarding.tooltip.audience.potential.description': 'According to the data you have entered, you will see how this indicator will change. Feel free to change the information you have entered until the needle is in the "Recommended" zone.{linebreak}{linebreak}This is very important, as this way you can create more efficient {socialNetwork} boosts.',
  'onBoarding.facebook.audience.title': 'Your clients on Facebook',
  'onboarding.tooltip.audience.facebook.clients': 'At Facebook, your potential clients share their general interests. Which relate to your business?',
  'onBoarding.linkedin.audience.title': 'Your clients on LinkedIn',
  'onboarding.tooltip.audience.linkedin.clients': 'On Linkedin, you can reach potential clients based on their professional traits; such as: job title, industry and professional interests.',
  'audience.interests.li.warning': 'Your audience size is too small to boost. To broaden it add more interests.',
  'audience.interests.li.suggestions': 'You can use this suggestions:',
  'audience.interests.li.info': 'Your audience is ready to boost!',
  'audience.interests.button.giveme': 'Give me more sugestions',
  'alert.warning.onboarding.billingSection.apps.purchase.invalid': 'The payment process could not be completed. Please try again or contact support@socialpiper.com',
};

export default en;
