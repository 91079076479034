import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GliderComponent from '../../../globals/GliderCarousel';
import StepCreateAdAccount from './StepCreateAdAccount';

const CustomGliderCarousel = styled(GliderComponent)`
  && {
    max-width: 280px;
    .glider-prev,
    .glider-next {
      top: -6px;
    }
    .glider-dot {
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.gray02};
      &.active {
        background: ${({ theme }) => theme.colors.gray02};
      }
    }
  }
`;

const StepsCreateAdAccountMobile = ({ steps }) => (
  <CustomGliderCarousel
    hasArrows
    hasDots
    settings={{
      itemWidth: 280,
      exactWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    }}
  >
    {steps.map((step) => (
      <StepCreateAdAccount step={step} />
    ))}
  </CustomGliderCarousel>
);

StepsCreateAdAccountMobile.propTypes = {
  steps: PropTypes.instanceOf(Array).isRequired,
};

export default StepsCreateAdAccountMobile;
