import gql from 'graphql-tag';

export const upgradeToBusinessUser = gql`
  mutation upgradeToBusinessUser($currencyCode: String, $countryCode: String) {
    upgradeToBusinessUser(currencyCode: $currencyCode, countryCode: $countryCode)
  }
`;

export const downgradeToProUser = gql`
  mutation downgradeToProUser($companyIdToKeep: ID!, $currencyCode: String, $countryCode: String) {
    downgradeToProUser(companyIdToKeep: $companyIdToKeep, currencyCode: $currencyCode, countryCode: $countryCode)
  }
`
