import BusinessProvider from '../services/entities/BusinessProvider';

export const getCompany = async (companyId, locale) => {
  const [businessPromise, extraBusinessInfoPromise, faqsPromise] = await Promise.allSettled([
    BusinessProvider.fetchBusinessInfo(locale, companyId),
    BusinessProvider.getExtraBusinessInfo(companyId),
    BusinessProvider.fetchFaqs(companyId),
  ]);
  let businessInfo, extraBusinessInfo, faqs;

  if (businessPromise.status !== 'rejected') {
    const [information, categories] = businessPromise.value.data;

    businessInfo = {
      ...businessInfo,
      id: information?.id,
      companyName: information?.companyName,
      location:
        information?.location && typeof information?.location === 'string'
          ? JSON.parse(information?.location)
          : '',
      logoPreview: information?.logoUrl,
      logo: information?.logoUrl,
      category: categories,
    };
  }

  // ExtraBusinessInfo
  if (extraBusinessInfoPromise.status !== 'rejected') {
    extraBusinessInfo = extraBusinessInfoPromise.value.data;
  }

  // Faqs
  if (faqsPromise.status !== 'rejected') {
    faqs = faqsPromise.value.data;
  }
  return {
    businessInfo,
    extraBusinessInfo,
    faqs,
  };
};

export default {};
