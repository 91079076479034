import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  InputStyled,
  InputHolder,
  LabelInput,
  Required,
} from './FormController';
import {
  NoViewEye,
  ViewEye,
} from '../../assets/icons/iconRepository';
import { WebViewContext } from '../../contexts/WebViewContext';

const InputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const IconEye = styled.img`
  margin-left: 0px;
  cursor: pointer;
  position: absolute;
  right: 28px;
`;

const PasswordInput = ({
  className,
  label,
  showRequired,
  showRequiredError,
  required,
  id,
  onChange,
  value,
  colorLabelInput,
}) => {
  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const intl = useIntl();
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const onChangeHolder = (evt) => {
    onChange(evt);
  };
  return (
    <InputHolder
      className={className}
    >
      {label && (
        <LabelInput
          requiredText={(showRequired || showRequiredError) && `*${intl.formatMessage({
            id: 'k.required',
            defaultMessage: 'Required',
          })}`}
          error={showRequiredError}
          color={colorLabelInput}
          isApp={isApp || isAppIOS}
        >
          {label}{required && <Required>*</Required>}
        </LabelInput>
      )}
      <InputBox>
        <InputStyled
          onChange={(e) => onChangeHolder(e)}
          value={value || null}
          id={id}
          type={showEyeIcon ? 'text' : 'password'}
          error={showRequiredError}
        />
        <IconEye
          showIcon={showEyeIcon}
          alt="eye"
          onClick={() => setShowEyeIcon(!showEyeIcon)}
          src={showEyeIcon ? ViewEye : NoViewEye}
        />
      </InputBox>
    </InputHolder>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  showRequired: PropTypes.bool,
  showRequiredError: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  colorLabelInput: PropTypes.string,
};

PasswordInput.defaultProps = {
  className: '',
  label: '',
  showRequired: false,
  showRequiredError: false,
  required: false,
  id: '',
  onChange: () => {},
  value: '',
  colorLabelInput: '',
};

export default PasswordInput;
