/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import Async from 'react-select/async';
import styled from 'styled-components';
import { colors } from '../../styled/theme';
import { GLOBAL_DEBOUNCE_DELAY } from '../../utils/constants/globals';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';

const StyledReactSelect = styled(ReactSelect)`
  &&{
    min-height: 40px;
    padding-top: 4px;
    padding-bottom: 4px;
    max-width: 100%;
    [class*="-control"] {
      border-color: ${({ error }) => (error ? 'red' : colors.gray90)};
    }
    [class*="-ValueContainer"]{
      min-height: 24px;
    }
    [class*="-singleValue"]{
      width: calc(100% - 16px);
      margin: 0;
    }
    [class*="-indicatorSeparator"]{
      display: none;
    }
    [class*="-menu"]{
      font-size: 12px;
      margin-top: 0px;
      z-index: 603;
    }
    [class*="-multiValue"]{
      background-color: ${colors.secondaryDark};
      margin: 0 2px;
    }
    ${(props) => (props.isSearchable) && `
      [class*="-indicatorContainer"]{
        &:last-child{
          display: none;
        }
      }
    `}
    font-size: 14px;
  }
`;

const Select = ({
  id,
  onChange,
  isSearchable,
  isCreatable,
  isAsync,
  suffix,
  suffixException,
  loadOptions,
  hasDebounce,
  onBlur,
  onFocus,
  placeholder,
  hasHidingPlaceholder,
  error,
  options,
  ...props
}) => {
  const selectRef = useRef();
  const [debounceFreeze, setDebounceFreeze] = useState(true);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [hidingPlaceholder, setHidingPlaceholder] = useState(placeholder);
  const intl = useIntl();
  const noOptionsMessage = intl.formatMessage({
    id: 'audienceInterestsSelect.noOptionsMessage',
    defaultMessage: 'We couldn’t find what you’re looking for. Try a more general or similar category',
  });
  const handleChange = (selectedOption) => {
    onChange(id, selectedOption, selectRef);
  };

  const handleBlur = (e) => {
    setHidingPlaceholder(placeholder);
    onBlur(e);
  };

  const simulateMouseDown = (element) => {
    element.dispatchEvent(
      new MouseEvent('mousedown', {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    );
  };

  const handleFocus = (e) => {
    simulateMouseDown(e.target);
    if (hasHidingPlaceholder) {
      setHidingPlaceholder(' ');
    }
    onFocus(e);
  };

  useDidUpdateEffect(() => {
    setHidingPlaceholder(placeholder);
  }, [placeholder]);

  const renderImage = (image) => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      flex: '0 0 24px',
      borderRadius: image.isRounded ? '100%' : 0,
      content: '""',
      display: 'block',
      margin: `${image.hasPositionLeft ? '0 8px 0 0' : '0 0 0 8px'}`,
      height: 24,
      background: `url(${image.src})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      order: image.hasPositionLeft ? 0 : 2,
    },
  });

  const renderSuffix = () => ({
    ':after': {
      content: `" ${suffix}"`,
    },
  });

  const renderHelper = (helper) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':after': {
      content: `"${helper}"`,
      fontWeight: 300,
      color: colors.gray90,
      marginLeft: 8,
    },
  });

  const renderCustomStyles = (data) => {
    if (data.image) {
      return renderImage(data.image);
    }
    if (data.helper) {
      return renderHelper(data.helper);
    }
    if (suffix && !suffixException(data)) {
      return renderSuffix();
    }
    return null;
  };

  const customStyles = {
    option: (styles, { data }) => ({ ...styles, ...renderCustomStyles(data) }),
    singleValue: (styles, { data }) => ({ ...styles, ...renderCustomStyles(data) }),
  };

  const loadAsyncOptions = async (inputValue) => {
    if (!inputValue) {
      clearTimeout(debounceTimer);
      return [];
    }
    if (!hasDebounce) {
      return loadOptions(inputValue);
    }
    setDebounceFreeze(true);
    return new Promise((resolve) => {
      if (debounceFreeze) clearTimeout(debounceTimer);
      setDebounceTimer(setTimeout(() => {
        setDebounceFreeze(false);
        loadOptions(inputValue).then((options) => resolve(options));
      }, GLOBAL_DEBOUNCE_DELAY));
    });
  };

  return isAsync ? (
    <StyledReactSelect
      as={Async}
      cacheOptions
      onChange={handleChange}
      isSearchable={isSearchable}
      styles={customStyles}
      loadOptions={loadAsyncOptions}
      onBlur={handleBlur}
      onFocus={handleFocus}
      ref={selectRef}
      placeholder={hidingPlaceholder}
      error={error}
      noOptionsMessage={() => noOptionsMessage}
      id={id}
      options={options}
      {...props}
    />
  ) : (
    <StyledReactSelect
      as={isCreatable ? Creatable : ReactSelect}
      onChange={handleChange}
      isSearchable={isSearchable}
      styles={customStyles}
      onBlur={handleBlur}
      onFocus={handleFocus}
      ref={selectRef}
      placeholder={hidingPlaceholder}
      formatCreateLabel={(inputValue) => inputValue}
      error={error}
      noOptionsMessage={() => noOptionsMessage}
      id={id}
      options={options}
      {...props}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array),
  value: PropTypes.any.isRequired,
  isSearchable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  suffix: PropTypes.string,
  loadOptions: PropTypes.func,
  hasDebounce: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  hasHidingPlaceholder: PropTypes.bool,
  error: PropTypes.bool,
  suffixException: PropTypes.func,
};

Select.defaultProps = {
  options: [],
  isSearchable: false,
  isCreatable: false,
  isAsync: false,
  suffix: null,
  suffixException: () => false,
  loadOptions: () => {},
  hasDebounce: false,
  onBlur: () => {},
  onFocus: () => {},
  placeholder: ' ',
  hasHidingPlaceholder: false,
  error: false,
};

export default Select;
