import * as Sentry from '@sentry/browser';
import axios from 'axios';
import decode from 'unescape';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import {
  createHistoricTemplate,
  saveEditorState,
  saveUserImage,
  saveUserImages,
  acceptPendingPost,
  rejectPendingPost,
} from '../../graphql/mutations';
import {
  generateCaptionByPiper,
  generateImageByPiper,
  getUploadUrl,
  getUploadUrls,
  getPendingPosts,
} from '../../graphql/queries';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import { authAppSync } from '../auth';
import UserProvider from './UserProvider';

export default class PostflowProvider {
  static async uploadImage(name, blob, type) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: getUploadUrl,
        variables: {
          fileName: name,
          fileType: type,
          folder: process.env.REACT_APP_S3_CONTENT_PATH,
        },
        fetchPolicy: 'network-only',
      });

      const { uploadUrl, imageName } = response.data.getUploadUrl;
      const decodedUrl = decode(uploadUrl);

      const options = {
        headers: {
          'Content-Type': type,
          'Cache-Control': 'no-cache',
        },
      };
      await axios.put(decodedUrl, blob, options);
      let imageUrl = '';
      try {
        const splitURL = decodedUrl.split('?');
        [imageUrl] = splitURL;
        // eslint-disable-next-line no-empty
      } catch (error) {}
      result = {
        message: 'success',
        success: true,
        data: {
          imageName,
          imageUrl,
        },
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async uploadImages(images) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const inputs = images.map((img) => ({
        fileName: img.name,
        fileType: img.type,
        folder: process.env.REACT_APP_S3_CONTENT_PATH,
      }));

      const response = await authAppSync.client.query({
        query: getUploadUrls,
        variables: {
          inputs,
        },
      });

      const uploadUrls = response.data.getUploadUrls;
      const payload = uploadUrls.map((input, i) => ({
        decodedUrl: decode(input.uploadUrl),
        options: {
          headers: {
            'Content-Type': images[i].type,
            'Cache-Control': 'no-cache',
          },
        },
        blob: images[i].blob,
      }));
      const promises = [];
      payload.forEach((input) => {
        promises.push(
          axios.put(input.decodedUrl, input.blob, { options: input.options }),
        );
      });
      await Promise.all(promises);
      result = {
        message: 'success',
        success: true,
        data: uploadUrls,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async uploadVideoThumbnail(thumbUrl = false) {
    const thumbImage = thumbUrl || localStorage.getItem('imgThumbVideo');
    const blobThumb = await fetch(thumbImage).then((r) => r.blob());

    const inputsThumb = [
      {
        fileName: 'imgThumb.png',
        fileType: 'image/png',
        folder: process.env.REACT_APP_S3_CONTENT_PATH,
      },
    ];

    const responseThumb = await authAppSync.client.query({
      query: getUploadUrls,
      fetchPolicy: 'no-cache',
      variables: {
        inputs: inputsThumb,
      },
    });

    const uploadUrlsImg = responseThumb.data.getUploadUrls;
    const payloadThumb = uploadUrlsImg.map((input) => ({
      decodedUrl: decode(input.uploadUrl),
      options: {
        headers: {
          'Content-Type': 'image/png',
          'Cache-Control': 'no-cache',
        },
      },
      blob: blobThumb,
    }));

    const promises = [];
    payloadThumb.forEach((input) => {
      promises.push(
        axios.put(input.decodedUrl, input.blob, { options: input.options }),
      );
    });
    await Promise.all(promises);

    const regex = /(.*)\?/gm;
    const matchPath = regex.exec(responseThumb.data.getUploadUrls[0].uploadUrl);
    return {
      previewURL: matchPath[1],
      namePreview: responseThumb.data.getUploadUrls[0].imageName,
    };
  }

  static async uploadVideo(media, thumbnailUrl, ourRequest) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV === ENVIRONMENTS.local) {
        return {
          message: 'success',
          success: true,
          data: [
            'https://cdn.socialpiper.com/content/5f4ec9b9cd0b1e006d0be0ad/72a054a0-9efc-11ec-aa67-79a9c664adae.mp4',
          ],
          previewURL:
            'https://prod-production-socialpiper-cdn.s3.amazonaws.com/content/5f4ec9b9cd0b1e006d0be0ad/d5deac10-9efc-11ec-9249-cdc2e4f422dc.png',
          namePreview: 'd5deac10-9efc-11ec-9249-cdc2e4f422dc.png',
        };
      }
      const uploadVideoThumbnail = await this.uploadVideoThumbnail(
        thumbnailUrl,
      );
      const inputs = media.map((img) => ({
        fileName: img.name,
        fileType: img.type,
        folder: process.env.REACT_APP_S3_CONTENT_PATH,
      }));
      const response = await authAppSync.client.query({
        query: getUploadUrls,
        fetchPolicy: 'no-cache',
        variables: {
          inputs,
        },
      });

      const uploadUrls = response.data.getUploadUrls;
      const payload = uploadUrls.map((input, i) => ({
        decodedUrl: decode(input.uploadUrl),
        options: {
          headers: {
            'Content-Type': media[i].type,
            'Cache-Control': 'no-cache',
          },
        },
        blob: media[i].mediaUrl,
        name: media[i].name,
        type: media[i].type,
      }));
      const input = payload[0];
      return new Promise((resolve) => {
        setTimeout(async () => {
          const blob = await fetch(input.blob).then((r) => r.blob());
          const file = new File([blob], input.name, { type: input.type });
          try {
            const request = await axios.put(input.decodedUrl, file, {
              options: input.options,
              cancelToken: ourRequest.token,
            });
            if (request.status === 200) {
              result = {
                message: 'success',
                success: true,
                data: uploadUrls,
                previewURL: uploadVideoThumbnail.previewURL,
                namePreview: uploadVideoThumbnail.namePreview,
              };
              return resolve(result);
            }
          } catch (error) {
            result = {
              message: 'error',
              success: false,
            };
            return resolve(result);
          }
          result = {
            message: 'error',
            success: false,
          };
          return resolve(result);
        }, 100);
      });
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
      result.success = false;
      return result;
    }
  }

  static async saveImage(
    originalName,
    name,
    folder,
    imageType,
    imageUuid,
    tags,
    contentId,
  ) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: saveUserImage,
        variables: {
          originalName,
          name,
          folder,
          imageType,
          imageUuid,
          tags,
          contentId,
        },
      });
      result.success = true;
      result.data = response.data;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async saveImages(images) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const inputs = images.map((img) => ({
        originalName: img.originalName,
        name: img.name,
        folder: img.folder,
        imageType: img.imageType,
        imageUuid: img.imageUuid,
        tags: img.tags,
        contentId: img.contentId,
      }));
      const response = await authAppSync.client.mutate({
        mutation: saveUserImages,
        variables: {
          inputs,
        },
      });
      result.success = true;
      result.data = response.data;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async save2S3Templates(payload, historicId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const { name, tags, data } = payload;
    const { imageId } = payload;
    let svgId;
    let jpgId;
    if (imageId) {
      svgId = imageId.svgId;
      jpgId = imageId.jpgId;
    }
    const [originalNameSvg, originalNameJpg] = await Promise.all([
      this.uploadImage(`${name}.svg`, data.svg, 'image/svg+xml'),
      this.uploadImage(`${name}.jpg`, data.blob, 'image/jpg'),
    ]);

    if (originalNameJpg.success && originalNameSvg.success) {
      const tagsIds = tags.map(({ id }) => id);
      const imageUuid = uuidv4();
      const saveSvg = await this.saveImage(
        name,
        originalNameSvg.data.imageName,
        process.env.REACT_APP_S3_CONTENT_PATH,
        'svg',
        imageUuid,
        tagsIds,
        svgId,
      );
      const saveJpg = await this.saveImage(
        name,
        originalNameJpg.data.imageName,
        process.env.REACT_APP_S3_CONTENT_PATH,
        'jpg',
        imageUuid,
        tagsIds,
        jpgId,
      );
      if (saveSvg.success && saveJpg.success) {
        result.success = true;
        result.data = {
          svg: saveSvg.data,
          jpg: saveJpg.data,
        };
      }
    }

    if (historicId) {
      await this.createHistoricTemplateFunc(historicId);
    }

    return result;
  }

  static async save2S3TemplatesMultiImages(payload, historicIds = []) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const svgsPayload = payload.map((input) => ({
      name: `${input.name}.svg`,
      blob: input.data.svg,
      type: 'image/svg+xml',
    }));

    const jpgsPayload = payload.map((input) => ({
      name: `${input.name}.jpg`,
      blob: input.data.blob,
      type: 'image/jpg',
    }));

    if (svgsPayload || jpgsPayload) {
      const [originalNameSvgs, originalNameJpgs] = await Promise.all([
        this.uploadImages(svgsPayload),
        this.uploadImages(jpgsPayload),
      ]);

      if (originalNameJpgs.success && originalNameSvgs.success) {
        const imageUuid = uuidv4();
        const svgPayload = originalNameSvgs.data.map((svg, i) => ({
          name: svg.imageName,
          originalName: payload[i].name,
          folder: process.env.REACT_APP_S3_CONTENT_PATH,
          imageType: 'svg',
          imageUuid: `${imageUuid}${i}`,
          tags: payload[i].tags.map(({ id }) => id),
          contentId: payload[i].imageId ? payload[i].imageId.svgId : '',
        }));
        const jpgPayload = originalNameJpgs.data.map((jpg, i) => ({
          name: jpg.imageName,
          originalName: payload[i].name,
          folder: process.env.REACT_APP_S3_CONTENT_PATH,
          imageType: 'jpg',
          imageUuid: `${imageUuid}${i}`,
          tags: payload[i].tags.map(({ id }) => id),
          contentId: payload[i].imageId ? payload[i].imageId.jpgId : '',
        }));
        const [saveSvg, saveJpg] = await Promise.all([
          this.saveImages(svgPayload),
          this.saveImages(jpgPayload),
        ]);
        if (saveSvg.success && saveJpg.success) {
          result.success = true;
          result.data = {
            svg: saveSvg.data,
            jpg: saveJpg.data,
          };
        }
      }
    }

    if (historicIds.length) {
      await this.createHistoricTemplateFunc(null, historicIds);
    }

    return result;
  }

  static async createHistoricTemplateFunc(imageId, imageIds = []) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const ids = imageId ? [imageId] : imageIds;
    try {
      const response = await authAppSync.client.mutate({
        mutation: createHistoricTemplate,
        variables: {
          templateIds: ids,
        },
      });
      result.success = true;
      result.data = response.data;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async saveEditorState(imageId, state, imagesEditor) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: saveEditorState,
        variables: {
          imageId,
          state,
          imagesEditor,
        },
      });
      result.success = response.data.saveEditorState.isValid;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async generateImage(params) {
    try {
      const { data } = await authAppSync.client.query({
        query: generateImageByPiper,
        variables: {
          ...params,
          prompt: params.prompt,
        },
        fetchPolicy: 'no-cache',
      });
      const base64 = data.dallE.data[0].b64Json;
      return {
        data: `data:image/png;base64,${base64}`,
        success: true,
        message: 'success',
      };
    } catch (e) {
      Sentry.captureException(e);
      return {
        data: null,
        success: false,
        message: e.message,
      };
    }
  }

  static async generateCaption(params) {
    try {
      if (!params.charsLimit) {
        const accounts = await UserProvider.fetchConnectedAccounts(false, params.companyId);

        if (accounts.data.twitter) {
          params.charsLimit = 280;
        }
      }

      const { data } = await authAppSync.client.query({
        fetchPolicy: 'no-cache',
        context: { queryDeduplication: false },
        query: generateCaptionByPiper,
        variables: {
          ...params,
          charsLimit: params.charsLimit ?? 280,
          n: params.n ?? 1,
          prompt: 'User Prompt: ' + params.prompt
        },
      });

      const response = data.gpt.choices[0].message.content
        .split('"')
        .filter((x) => x.length)
        .join('"');

      return {
        data: response,
        success: true,
        message: 'success',
      };
    } catch (e) {
      Sentry.captureException(e);
      return {
        data: null,
        success: false,
        message: e.message,
      };
    }
  }

  static async fetchPendingPosts() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV === ENVIRONMENTS.local) {
        return {
          message: 'success',
          success: true,
          data: [{
            id: -1,
            company: {
              id: -1,
              companyName: 'Example company',
            },
            socialMedia: {
              type: 'facebook',
              name: 'Example facebook',
            },
            date: new Date(),
            by: "John Doe",
            caption: "Example caption",
            image: "",
          }]
        };
      }
      const response = await authAppSync.client.query({
        query: getPendingPosts,
        fetchPolicy: 'no-cache',
      });
      const posts = response.data.pendingPosts;
      result = {
        message: 'success',
        success: true,
        data: posts,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async acceptPendingPost(id) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV === ENVIRONMENTS.local) {
        return {
          message: 'success',
          success: true,
        };
      }
      const response = await authAppSync.client.mutate({
        mutation: acceptPendingPost,
        fetchPolicy: 'no-cache',
        variables: { id },
      });
      result = {
        message: 'success',
        success: true,
        data: response,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async rejectPendingPost(id) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV === ENVIRONMENTS.local) {
        return {
          message: 'success',
          success: true,
        };
      }
      const response = await authAppSync.client.mutate({
        mutation: rejectPendingPost,
        fetchPolicy: 'no-cache',
        variables: { id },
      });
      result = {
        message: 'success',
        success: true,
        data: response,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
