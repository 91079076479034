import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormController from '../../../../../components/globals/FormController';
import Select from '../../../../../components/globals/Select';
import { InterestProvider } from '../../../../../services/lookups';

const CustomSelect = styled(Select)`
  && {
    width: 100%;
  }
`;

const AudienceInterestsSelect = ({
  className,
  value,
  setInterests,
  intl,
  required,
  showRequired,
  showRequiredError,
  id,
  selectRef,
  isLinkedin,
}) => {
  const filterInterests = async (inputValue) => {
    const response = await InterestProvider.search(inputValue, intl, isLinkedin);
    if (response.success) {
      return response.data;
    }
    return [];
  };
  const handleInsterestsSelection = (_, locations) => {
    setInterests(locations);
  };

  return (
    <FormController
      className={className}
      label={intl.formatMessage({
        id: 'audienceInterestsSelect.label',
        defaultMessage: 'Keywords related to your business',
      })}
      helper={intl.formatMessage({
        id: 'audienceInterestsSelect.helper',
        defaultMessage: 'Start typing tags you think your audience will be into',
      })}
      required={required}
      showRequired={showRequired}
      showRequiredError={showRequiredError}
    >
      <div
        ref={selectRef}
      >
        <CustomSelect
          value={value}
          onChange={handleInsterestsSelection}
          isSearchable
          isAsync
          hasDebounce
          isMulti
          loadOptions={filterInterests}
          placeholder={intl.formatMessage({
            id: 'audienceInterestsSelect.placeholder',
            defaultMessage: 'E.g. Roast Beef, Mac & cheese',
          })}
          hasHidingPlaceholder
          id={id}
          error={showRequiredError}
        />
      </div>
    </FormController>
  );
};

AudienceInterestsSelect.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.instanceOf(PropTypes.func).isRequired,
  required: PropTypes.bool,
  setInterests: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  showRequired: PropTypes.bool,
  showRequiredError: PropTypes.bool,
  id: PropTypes.string,
  selectRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
  isLinkedin: PropTypes.bool,
};

AudienceInterestsSelect.defaultProps = {
  className: '',
  required: false,
  showRequired: false,
  showRequiredError: false,
  id: '',
  selectRef: null,
  isLinkedin: false,
};

export default AudienceInterestsSelect;
