import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styled/theme';

const TipStyled = styled.div`
  width: 100%;
  max-width: min(100%, 80vw);
  display:flex;
  font-family: ${({ theme }) => theme.font.font2};
  font-weight: 600;
  font-size: 16px;
`;

const triangleSize = '56px';

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 0;
  position: absolute;
  ${({
    tipPosition, offset, bodySize, trianglePosition, backgroundColor,
  }) => {
    switch (tipPosition) {
      case 'right': {
        let css = `
          border-width: 0 ${triangleSize} ${triangleSize} 0;
          border-color: transparent ${backgroundColor || colors.primaryLight} transparent transparent;
          left:-25px;
          top: 16px;
        `;
        if (trianglePosition === 'bottom') {
          css += `
            border-width: ${triangleSize} ${triangleSize} 0 0;
          `;
          if ((parseInt(triangleSize, 10) + parseInt(offset, 10) + 10) < bodySize[1]) {
            css += `
              bottom: ${offset};
              top: 0;
            `;
          } else {
            css += `
              top: 0;
            `;
          }
        }
        return css;
      }
      case 'left': {
        let css = `
          border-width: ${triangleSize} ${triangleSize} 0 0;
          border-color: ${backgroundColor || colors.primaryLight} transparent transparent transparent;
          right: -25px;
          top: 16px;
        `;
        if (trianglePosition === 'bottom') {
          css += `
            border-width: 0 ${triangleSize} ${triangleSize} 0;
            border-color: transparent transparent ${backgroundColor || colors.primaryLight} transparent;
          `;
          if ((parseInt(triangleSize, 10) + parseInt(offset, 10) + 10) < bodySize[1]) {
            css += `
              bottom: ${offset};
              top: auto;
            `;
          } else {
            css += `
              top: 0;
            `;
          }
        }
        return css;
      }
      case 'top': {
        let css = `
          bottom: -25px;
          right: 0;
          border-width: ${triangleSize} ${triangleSize} 0 0;
          border-color: ${backgroundColor || colors.primaryLight} transparent transparent transparent;
        `;
        if (trianglePosition === 'left') {
          css += `
            right: auto;
            left: 0;
            border-width: ${triangleSize} 0 0 ${triangleSize};
          `;
        }
        return css;
      }
      case 'bottom': {
        let css = `
          top: -25px;
          right: 0;
          border-width: ${triangleSize} 0 0 ${triangleSize};
          border-color: transparent transparent transparent ${backgroundColor || colors.primaryLight};
        `;
        if (trianglePosition === 'left') {
          css += `
          `;
        }
        return css;
      }
      default:
        return '';
    }
  }}

`;

const Body = styled.div`
  max-width:500px;
  min-width:110px;
  width: 100%;
  height: fit-content;
  min-height: ${({ minHeight }) => (minHeight)};
  background: ${({ backgroundColor }) => backgroundColor || colors.primaryLight};
  border-radius: 12px;
  position:relative;
  box-sizing:border-box;
  padding: 16px;
  color: ${({ backgroundColor }) => (backgroundColor ? colors.black : colors.white)};
  & span {
    z-index: 1;
    position:relative;
    white-space: pre-line;
  }
  @media (max-width: 320px) {
    padding: 10px;
  }
  @media (max-width: 380px) {
    font-size: 14px;
  }
`;

const Tip = ({
  children, className, position, offset, trianglePosition, minHeight, backgroundColor,
}) => {
  const bodyRef = useRef({
    offsetWidth: 0,
    offsetHeight: 0,
  });
  return (
    <TipStyled position={position} className={`${className} tip`}>
      <Body
        className="bodyTip"
        position={position}
        ref={bodyRef}
        minHeight={minHeight}
        backgroundColor={backgroundColor}
      >
        <Triangle
          tipPosition={position}
          trianglePosition={trianglePosition}
          offset={offset}
          bodySize={[bodyRef.current.offsetWidth, bodyRef.current.offsetHeight]}
          className="triangle"
          backgroundColor={backgroundColor}
        />
        <span>
          {children}
        </span>
      </Body>
    </TipStyled>
  );
};

Tip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
  position: PropTypes.string,
  offset: PropTypes.string,
  trianglePosition: PropTypes.string.isRequired,
  minHeight: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Tip.defaultProps = {
  position: 'right',
  offset: '80px',
  children: null,
  className: '',
  minHeight: '90px',
  backgroundColor: '',
};

export default Tip;
