const en = {
  'alert.error.boostManager.boostBudget.currency': 'Error in getting currency.',
  'alert.error.boostManager.boostBudget.info': 'Error getting boost budget info',
  'alert.error.community.markAsRead': "We couldn't mark your comment{plural} as read. Please try again or contact support@socialpiper.com",
  'alert.error.community.replyComm': "We couldn't send your comment. Please try again or contact support@socialpiper.com",
  'alert.error.community.likeComment': "We couldn't like the comment. Please try again or contact support@socialpiper.com",
  'alert.error.community.replyMsg': "We couldn't send your message. Please try again or contact support@socialpiper.com",
  'alert.error.delete': 'Error encountered while deleting',
  'alert.error.delete.post': 'The post has not been deleted',
  'alert.error.edit': "Your changes couldn't be saved. Please try again or contact support@socialpiper.com.",
  'alert.error.fileTooLarge': 'Your image is too large. Please, add a smaller one, no more than 2MB. ',
  'alert.error.loginEmail': 'Your mail or password is incorrect. Please write again.',
  'alert.error.loginFacebook': "We couldn't connect you with facebook. Please try again or contact support@socialpiper.com.",
  'alert.error.mycreations.delete.': "We couldn't delete your image. Please try again or contact support@socialpiper.com.",
  'alert.error.recoverPassword': "We couldn't send your recover email. Please try again or contact support@socialpiper.com.",
  'alert.error.reschedule': 'Error encountered while rescheduling',
  'alert.error.set.monthlyBudget': "The monthly budget couldn't be set. Please try again or contact support@socialpiper.com.",
  'alert.error.signInEmail': "We couldn't log in. Please try again or contact support@socialpiper.com.",
  'alert.error.signUpEmail': 'There is already an account created with the email you have entered.',
  'alert.error.signInFacebook': "We couldn't log in with Facebook. Please try again or contact support@socialpiper.com.",
  'alert.error.social.connection': 'There was an error connecting your social media account',
  'alert.error.social.disconnection': 'There was an error disconnecting your social media account',
  'alert.error.ad.paused': "The boost couldn't be paused. Please try again or contact support@socialpiper.com.",
  'alert.error.ad.running': "The pause couldn't be removed. Please try again or contact support@socialpiper.com.",
  'alert.error.ad.archived': "The boost couldn't be deleted. Please try again or contact support@socialpiper.com.",
  'alert.error.subscription.canceled': "The subscription couldn't be canceled. Please try again or contact support@socialpiper.com.",
  'alert.error.form.incomplete': 'Please complete the information in red.',
  'alert.error.onboarding.billingSection.selectPlan': 'Please select a plan',
  'alert.error.onboarding.billingSection.requiredInfoIncomplete': 'Please complete the required fields',
  'alert.error.onboarding.billingSection.invalidEmail': 'Please enter a valid email address',
  'alert.error.updateLinkedIn': "We couldn't connect you linkedIn account. Please try again or contact support@socialpiper.com",
  'alert.success.ad.archived': 'The boost has been deleted.',
  'alert.success.ad.paused': 'The boost has been paused.',
  'alert.success.ad.running': 'The pause has been removed.',
  'alert.success.edit': 'Your changes have been saved.',
  'alert.success.create': '{item} created successfully',
  'alert.success.create.sn': '{sn} {item} created successfully',
  'alert.success.create.female': '{item} created successfully',
  'alert.success.delete': '{item} deleted successfully',
  'alert.success.delete.post': 'The post has been deleted',
  'alert.success.delete.female': '{item} deleted successfully.',
  'alert.success.save': '{item} saved successfully',
  'alert.success.save.female': '{item} saved successfully',
  'alert.success.save.card': 'Card saved successfully.',
  'alert.success.set.monthlyBudget': 'Your monthly budget has been set.',
  'alert.success.update': '{item} updated successfully',
  'alert.success.update.monthlyBudget': 'Your monthly budget has been updated.',
  'alert.success.update.female': '{item} updated successfully',
  'alert.error.company.update': 'There was an error updating the company. Please try again.',
  'alert.error.loginApple': "We couldn't connect you with Apple. Please try again or contact support@socialpiper.com.",
  'alert.error.onboarding.billingSection.app.transaction': 'The payment process could not be completed. Please try again or contact support@socialpiper.com.',
  'alert.error.trialEnds.days.remaning': 'Your trial ends in {days} days, add a valid payment method {here}',
  'alert.error.trialEnds.tomorrow': 'Your trial ends tomorrow, add a valid payment method {here}',
  'alert.error.trialEnds.today': 'Your trial ends today, add a valid payment method {here}',
  'alert.error.trialEnds.expired': 'Your trial has expired and we revert your account to our Lite Plan, if you think this is a mistake, please contact the support team {here}',
  'alert.error.paymentMethod': 'Your payment method failed, add a valid credit card {here} to keep your PRO Plan',
  'alert.info.create.video.boost': 'In a few moments your boost will be ready. Here you\'ll be able to check its status.',
  'alert.collaborator.added': 'Collaborator added successfully',
  'alert.collaborator.updated': 'Collaborator updated successfully',
  'alert.collaborator.deleted': 'Collaborator deleted successfully',
  'alert.collaborator.duplicated': 'This email is already registered on SocialPiper. Please try another one.',
  'alert.collaborator.added.error': 'An error occurred while adding the collaborator',
  'alert.collaborator.updated.error': 'An error occurred while updating the collaborator',
  'alert.collaborator.deleted.error': 'An error occurred while deleting the collaborator'
};

export default en;
