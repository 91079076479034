import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import COLOR from '../../../styled/colors';
import { AddIconWhite } from '../../../assets/icons/iconRepository';
import { LOGO_MAX_SIZE_MB } from '../../../utils/constants/globals';
import useCallGA from '../../../hooks/useCallGA';

const Holder = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const Icon = styled.div`
  background-color: ${COLOR.primary};
  background-image: url(${AddIconWhite});
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  width: 64px; 
  height: 64px;
  border-radius: 50%;
  ${Holder}:hover &{
    background-color: ${COLOR.primaryDark}
  }
`;

const LogoImg = styled.img`
  width: 64px; 
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const UploadLogo = ({
  onClick,
  onLoad,
  handleFileTooLarge,
  preview,
  className,
  gaData,
  children,
}) => {
  const callGA = useCallGA();
  /**
   * @deprecated
   * @param {event} e
   */
  // eslint-disable-next-line no-unused-vars
  const onClickHandler = (e) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.click();
    onClick(e);
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (!file || file.size > LOGO_MAX_SIZE_MB * 1000000) {
        handleFileTooLarge();
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => onLoad(img, file, url);
        img.src = url;
      }
    };
  };
  const handleClick = () => {
    callGA(gaData);
  };
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (!file || file.size > LOGO_MAX_SIZE_MB * 1000000) {
      handleFileTooLarge();
    } else {
      const img = new Image();
      const url = URL.createObjectURL(file);
      img.onload = () => onLoad(img, file, url);
      img.src = url;
    }
  };
  return (
    <Holder
      className={className}
    >
      <FileInput type="file" accept="image/*" onChange={handleChange} onClick={handleClick} />
      {preview ? (
        <LogoImg src={preview} alt="logo" />
      ) : (
        <Icon />
      )}
      {children}
    </Holder>

  );
};

UploadLogo.propTypes = {
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  handleFileTooLarge: PropTypes.func,
  preview: PropTypes.string,
  gaData: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

UploadLogo.defaultProps = {
  onClick: () => {},
  onLoad: () => {},
  handleFileTooLarge: () => {},
  preview: '',
  className: '',
  children: null,
};

export default UploadLogo;
