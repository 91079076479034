export default {
	'onboarding.biz.title': '¡Vamos a establecer tu primer negocio / producto!',
	'onboarding.biz.step1.title': 'Crea tu primer negocio / producto',
  'onboarding.biz.step1.modal.title': 'Agrega tu primer negocio / producto',
	'onboarding.biz.step1.button': 'Crear',
	'onboarding.biz.step1.errorForm.companyName': 'Necesitas rellenar el nombre del negocio para continuar',
	'onboarding.biz.step2.title': 'Conecta las cuentas sociales de este negocio / producto',
  'onboarding.biz.step2.modal.title': 'Conecta las cuentas sociales de {businessName}',
  'onboarding.biz.step2.piper.advice': 'Conecta al menos 1 cuenta social. Recuerda, debes tener un perfil de administrador para hacer esto.',
	'onboarding.biz.step2.button': 'Conectar',
	'onboarding.biz.step3.title': 'Define la audiencia de este negocio / producto',
  'onboarding.biz.step3.modal.title': 'Define la audiencia de {businessName}',
	'onboarding.biz.step3.subtitle': 'No es obligatorio modificar la audiencia ahora mismo, ten en cuenta que solo podras hacerlo si vinculas Facebook o Linkedin',
	'onboarding.biz.step3.button': 'Configurar audiencia',
	'onboarding.biz.step4.title': 'Agrega miembros para administrar este negocio / producto',
  'onboarding.biz.step4.modal.title': 'Agrega miembros para gestionar {businessName}',
	'onboarding.biz.step4.social': 'Social',
	'onboarding.biz.step4.restrictions': 'Restricciones',
	'onboarding.biz.step4.restriction.post': 'Necesita autorización para <b>Publicar</b>',
	'onboarding.biz.step4.restriction.boost': 'Puede <b>publicitar</b> inmediatamente',
	'onboarding.biz.step4.restriction.community': 'Acceso a <b>Comunidad</b>',
	'onboarding.biz.step4.subtitle': 'Puedes hacer esto más tarde en la sección de configuración',
	'onboarding.biz.step4.subtitle.2': '{q} miembros agregados',
	'onboarding.biz.step4.subtitle.3': 'Recuerda que puedes configurar esto, luego, desde el panel de administración',
	'onboarding.biz.step4.button': 'Agregar miembros',
	'onboarding.biz.continue': 'Continuar',
	'onboarding.biz.modal.save': 'Siguiente',
	'onboarding.biz.steps.save': 'Guardar  miembro'
}