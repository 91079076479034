import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UserProvider } from '../services/entities';
import { authAppSync } from '../services/auth';
import AuthService from '../services/auth/AuthService';
import routes from '../utils/constants/routes';

/**
 * Global context implemented in App.js for fetching user data
 * @usage const {user, setUser} = useContext(UserContext)
 * @return {React.Context} returns a React context to be used globally
 */
export const UserContext = React.createContext({});

/**
 * useFetchUser
 * @summary set userData context from UserProvider service
 * @return {void}
 * @assign user context
*/

export const useFetchUser = () => {
  const location = useLocation();
  const { state } = location;
  const [reloadState, setReloadState] = useState(false);

  /* TODO expose isAuthenticated to be filled just once in callback */
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({
    stillActive: true,
    remainingTrialDays: 0,
    id: 0,
    currency: 'USD',
    notifications: [],
  });

  useEffect(() => {
    if (state && state.from && state.from === routes.LOGIN) { setReloadState(true); }
  }, []);

  useEffect(() => {
    setAuthenticated(AuthService.isAuthenticated());
  }, [location, reloadState]);

  useEffect(() => {
    if (AuthService.isAuthenticated) {
      const fetchUserInfo = async () => {
        const response = await UserProvider.fetch(`
          id
          email
          firstName
          lastName
          hasTrial
          profileId
          remainingTrialDays
          currency
          hasCurrencySelected
          stillActive
          notifications {
            id
            name
          }
          subscriptionStatus
          haveCagetories
          companyName
          connectedAccounts {
            facebook {
              pageName
            }
            instagram{
              pageName
            }
            twitter{
              pageName
            }
            linkedin{
              pageName
            }
          }
          isOptIn
          analyticsDays
        `);
        if (response.success && response.data) {
          setUser(response.data);
        }
      };
      if (authAppSync.client) {
        fetchUserInfo();
      }
    } else {
      setUser({ stillActive: false, remainingTrialDays: 0, id: 0 });
    }
  }, [isAuthenticated]);

  return [user, setUser];
};

export default useFetchUser;
