import React, { useContext } from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import { DoggoLogo, LettersLogo } from '../../../assets/logos/logoRepository';
import { LogoutIcon } from '../../../assets/icons/iconRepository';
import mediaQueries from '../../../utils/mediaQueries';
import { icon as iconMixin } from '../../../styled/mixins';
import colors from '../../../styled/colors';
import AuthService from '../../../services/auth/AuthService';
import useHotJarTrigger from '../../../hooks/useHotJarTrigger';
import HOTJAR_SURVEYS from '../../../utils/constants/hotjarValues';
import { UserSettingsContext } from '../../../contexts/UserSettingsContext';
import {
  AppBar,
  Toolbar,
  PiperLogo,
  IconsContainer,
} from '../../../components/globals/NavBar/NavBar.styles';
import { WebViewContext } from '../../../contexts/WebViewContext';

const TittleLogoContainer = styled.div`
  display:flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items:center;
  margin-top:8px;
`;
const StyledIconsContainer = styled(IconsContainer)`
  justify-content: flex-end;
`;
const Icon = styled.div`
  ${iconMixin}
`;
const Button = styled.button`
  text-rendering: auto;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.font1};
  padding: ${({ theme }) => theme.space};
  border: none;
  border-radius: 2px;
  outline: none;
  &:hover ${Icon} {
    background: ${({ theme, hoverColor }) => hoverColor || theme.colors.gray02};
  }
`;

const NavBar = (step) => {
  const { locale, isOptIn } = useContext(UserSettingsContext);
  const { deregisterDevice, isApp, isAppIOS } = useContext(WebViewContext);
  const handleClick = async () => {
    if (isApp || isAppIOS) {
      if (!isAppIOS) {
        try {
          window.Android.facebookLogout();
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }
      await deregisterDevice();
    }
    AuthService.logout();
  };
  // eslint-disable-next-line max-len
  const deferedAction = useHotJarTrigger(handleClick, HOTJAR_SURVEYS.leftOnboard, locale, step === 2);
  const logoAction = useHotJarTrigger(() => {}, HOTJAR_SURVEYS.leftOnboard, locale);
  return (
    <>
      <AppBar
        position="relative"
        color="secondary"
      >
        <Toolbar>
          <TittleLogoContainer onClick={logoAction}>
            <PiperLogo src={DoggoLogo} />
            <PiperLogo src={LettersLogo} />
          </TittleLogoContainer>
          <Media queries={mediaQueries}>
            {(matches) => (
              <>
                {matches.xlUp ? (
                  <IconsContainer>
                    <Button
                      type="button"
                      hoverColor={colors.grayMediaLibrary}
                      onClick={!isOptIn ? deferedAction : handleClick}
                    >
                      <Icon size="24px" icon={LogoutIcon} />
                    </Button>
                  </IconsContainer>
                ) : (
                  <StyledIconsContainer>
                    <Button
                      type="button"
                      hoverColor={colors.grayMediaLibrary}
                      onClick={!isOptIn ? deferedAction : handleClick}
                    >
                      <Icon size="24px" icon={LogoutIcon} />
                    </Button>
                  </StyledIconsContainer>
                )}
              </>
            )}
          </Media>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
