import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mediaQueries from '../../../../utils/mediaQueries';

const Step = styled.div``;
const StepContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;
const StepNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media ${mediaQueries.mdUp} {
    width: fit-content;
  }
`;
const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.grayE9};
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  height: 24px;
  width: 24px;
`;
const StepDescription = styled.div`
  color: ${({ theme }) => theme.colors.black3E};
  flex: 1;
  font-size: 14px;
  margin-top: 16px;
  width: 100%;
  text-align: center;
  @media ${mediaQueries.mdUp} {
    font-size: 18px;
    max-width: 572px;
    margin-top: 0;
    text-align: left;
    margin-left: 8px;
  }
`;
const StepBoxImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mdUp} {
    justify-content: flex-start;
    padding-left: 32px;
    ${({ hideImage }) => hideImage && `
      display: none;
    `}
  }
`;
const StepImageContainer = styled.div`
  max-width: ${({ widthMobile }) => widthMobile};
  margin-top: ${({ marginTopMobile }) => marginTopMobile};
  @media ${mediaQueries.mdUp} {
    max-width: 300px;
    margin-top: 20px;
  }
`;
const StepImage = styled.img`
  width: 100%;
`;

const StepCreateAdAccount = ({ step }) => (
  <Step>
    <StepContainer>
      <StepNumberContainer>
        <StepNumber>{step.number}</StepNumber>
      </StepNumberContainer>
      <StepDescription>
        {step.description}
      </StepDescription>
    </StepContainer>
    <StepBoxImage hideImage={step?.hideImage}>
      <StepImageContainer
        widthMobile={step.maxWidthMobile}
        marginTopMobile={step.marginTopMobile}
      >
        <StepImage src={step.image} />
      </StepImageContainer>
    </StepBoxImage>
  </Step>
);

StepCreateAdAccount.propTypes = {
  step: PropTypes.shape({
    number: PropTypes.number,
    description: PropTypes.node,
    image: PropTypes.string,
    maxWidthMobile: PropTypes.string,
    marginTopMobile: PropTypes.string,
    hideImage: PropTypes.bool,
  }).isRequired,
};

export default StepCreateAdAccount;
