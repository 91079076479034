import { useState } from 'react';

const useHotJarTrigger = (cb, trigger, locale, noDefer = false) => {
  const [surveyTrigered, setSurveyTrigered] = useState(noDefer);
  const deferedCallback = () => {
    if (window.hj) {
      if (!surveyTrigered) {
        try {
          window.hj('trigger', `${trigger}_${locale.toUpperCase()}`);
        } catch {
          // only if fail, no tail necesary
        } finally {
          setSurveyTrigered(true);
        }
      } else {
        cb();
      }
    } else {
      cb();
    }
  };
  return deferedCallback;
};

export default useHotJarTrigger;
