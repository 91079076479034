import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Fade,
  Backdrop,
  Modal,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import MissingPaymentWarning from './MissinPaymentWarning';
import { FACEBOOK } from '../../../utils/constants/globals';
import { ButtonLink } from '../../buttons';
import {
  SupportLink,
  AdAccountsNote,
  AdAccountsContainer,
  Information,
  CurrentAdAccount,
  Label,
  Paragraph,
  StyledAdAccountsSelector,
  ModalActions,
  CancelButtonAction,
  SaveButton,
} from './AdAccountsDescription.styled';
import loadFBSDK from '../../../utils/loadFBSDK';
import { SocialProfilesProvider } from '../../../services/entities';
import { AlertsContext } from '../../../contexts/AlertsContext';
import StepsAddPaymentModal from '../linkedIn/StepsAddPaymentMethodModal';

const AdAccountsDescription = ({
  className,
  adAccounts,
  setAdAccounts,
  fetchAdAccounts,
  setOpen,
  socialNetwork,
  setOpenCreateAdAccount,
  setOpenVideoAdAccount,
}) => {
  const intl = useIntl();
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);
  const [activeAdAccount, setActiveAdAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openStepsPaymentModal, setOpenStepsPaymentModal] = useState(false);

  useEffect(() => {
    loadFBSDK();
  }, []);

  const renderAdAccountLabel = (adAccount) => `${adAccount.name} - ${intl.formatMessage({
    id: 'k.currency',
    defaultMessage: 'Currency',
  })}: ${adAccount.currency}`;

  useEffect(() => {
    const activeAccount = adAccounts.find((a) => a.isSelected);
    if (activeAccount) {
      setActiveAdAccount(activeAccount);
    } else {
      const defaultAdAccount = adAccounts.find((a) => a.fundingSourceId);
      setSelectedAdAccount(defaultAdAccount);
    }
  }, [adAccounts]);

  const { showAlert, showBackendError } = useContext(AlertsContext);

  const handleSelectorHeaderClick = async () => {
    if (selectedAdAccount) {
      setLoading(true);
      let response;
      if (socialNetwork === FACEBOOK) {
        response = await SocialProfilesProvider.updateFacebookAdAccount(
          selectedAdAccount,
          adAccounts,
        );
      } else {
        response = await SocialProfilesProvider.updateLinkedinAdAccount(
          selectedAdAccount.linkedinId,
          selectedAdAccount.name,
          selectedAdAccount.currency,
        );
      }
      if (response.success) {
        if (socialNetwork === FACEBOOK) {
          setAdAccounts(response.data);
        } else {
          fetchAdAccounts();
        }
        showAlert(
          intl.formatMessage(
            {
              id: 'alert.success.save.female',
              defaultMessage: '{item} saved successfully',
            },
            {
              item: intl.formatMessage({
                id: 'k.adAccount',
                defaultMessage: 'Ad account',
              }),
            },
          ),
        );
      } else {
        showBackendError(response.message);
      }
      setLoading(false);
      if (setOpen) {
        setOpen(false);
      }
    }
  };

  const handleModal = () => {
    if (socialNetwork === FACEBOOK) {
      setOpenVideoAdAccount(true);
    } else {
      setOpenCreateAdAccount(true);
    }
  };

  const handleCloseStepsPaymentModal = () => {
    setOpenStepsPaymentModal(false);
  };

  return (
    <>
      <AdAccountsContainer
        className={className}
        hasBackground={!activeAdAccount || !activeAdAccount.fundingSourceId}
        isModal={!!setOpen}
      >
        <Information
          paddingTop={
            activeAdAccount && !activeAdAccount.fundingSourceId && '24px'
          }
        >
          {activeAdAccount && (
            <CurrentAdAccount>
              <Label>
                <FormattedMessage
                  id="socialProfiles.facebook.adAccounts.adAccountLabel"
                  defaultMessage="Ad Account"
                />
                :
              </Label>
              <Paragraph fontSize="18px">
                {activeAdAccount && renderAdAccountLabel(activeAdAccount)}
              </Paragraph>
            </CurrentAdAccount>
          )}
          {(!adAccounts.length) && (
            <ButtonLink onClick={handleModal}>Crate Ad Account</ButtonLink>
          )}
          {activeAdAccount && !activeAdAccount.fundingSourceId && (
            <MissingPaymentWarning
              accountId={socialNetwork === FACEBOOK
                ? activeAdAccount.accountId
                : activeAdAccount.linkedinId}
              fetchAdAccounts={fetchAdAccounts}
              socialNetwork={socialNetwork}
              setOpenStepsPaymentModal={setOpenStepsPaymentModal}
            />
          )}
          {!activeAdAccount && adAccounts.length >= 1 && (
            <StyledAdAccountsSelector
              hasBackground={activeAdAccount && activeAdAccount.fundingSourceId}
              activeAdAccount={activeAdAccount}
              adAccounts={adAccounts}
              loading={loading}
              selectedAdAccount={selectedAdAccount}
              setSelectedAdAccount={setSelectedAdAccount}
              handleHeaderClick={handleSelectorHeaderClick}
              fetchAdAccounts={fetchAdAccounts}
              renderAdAccountLabel={renderAdAccountLabel}
              isModal={!!setOpen}
              socialNetwork={socialNetwork}
              setOpenStepsPaymentModal={setOpenStepsPaymentModal}
            />
          )}
        </Information>
      </AdAccountsContainer>
      {adAccounts.length > 1 && (
        <AdAccountsNote>
          {activeAdAccount ? (
            <FormattedMessage
              id="socialProfiles.facebook.adAccounts.active.note"
              defaultMessage="You can change it later, only if it has the same currency as the Ad Account previously selected. If you need assistance, please contact <a>support@socialpiper.com</a>"
              values={{
                a: (...chunks) => (
                  <SupportLink href="mailto:support@socialpiper.com">
                    {chunks}
                  </SupportLink>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="socialProfiles.facebook.adAccounts.note"
              defaultMessage="You can only change to another Ad Account in the same currency. If you need assistance, please contact <a>support@socialpiper.com</a>"
              values={{
                a: (...chunks) => (
                  <SupportLink href="mailto:support@socialpiper.com">
                    {chunks}
                  </SupportLink>
                ),
              }}
            />
          )}
        </AdAccountsNote>
      )}
      {!!setOpen && (
        <ModalActions>
          <CancelButtonAction onClick={() => setOpen(false)}>
            <FormattedMessage id="k.cancel" defaultMessage="Cancel Boost" />
          </CancelButtonAction>
          <SaveButton
            handlerClick={handleSelectorHeaderClick}
            disableElevation
            isLoading={loading}
          >
            <FormattedMessage id="k.confirm" defaultMessage="Confirm" />
          </SaveButton>
        </ModalActions>
      )}

      <Modal
        open={openStepsPaymentModal}
        onClose={handleCloseStepsPaymentModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openStepsPaymentModal}>
          <StepsAddPaymentModal onCloseModal={handleCloseStepsPaymentModal} />
        </Fade>
      </Modal>
    </>
  );
};

AdAccountsDescription.propTypes = {
  className: PropTypes.string,
  adAccounts: PropTypes.instanceOf(Array).isRequired,
  setAdAccounts: PropTypes.func.isRequired,
  fetchAdAccounts: PropTypes.func.isRequired,
  setOpen: PropTypes.func,
  setOpenStepsPaymentModal: PropTypes.func,
  socialNetwork: PropTypes.string,
  setOpenCreateAdAccount: PropTypes.func,
  setOpenVideoAdAccount: PropTypes.func,
};

AdAccountsDescription.defaultProps = {
  className: '',
  setOpen: null,
  setOpenStepsPaymentModal: () => { },
  socialNetwork: FACEBOOK,
  setOpenCreateAdAccount: () => { },
  setOpenVideoAdAccount: () => { },
};

export default AdAccountsDescription;
