import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormController from '../../../../../components/globals/FormController';
import Select from '../../../../../components/globals/Select';
import { LocationProvider } from '../../../../../services/lookups';

const CustomSelect = styled(Select)`
  && {
    width: 100%;
  }
`;

const Container = styled.div``;

const BusinessLocationSelect = ({
  className,
  value,
  setLocation,
  required,
  intl,
  showRequired,
  showRequiredError,
  id,
  selectRef,
}) => {
  const filterCities = async (inputValue) => {
    const response = await LocationProvider.search(inputValue, intl);
    if (response.success) {
      return response.data;
    }
    return [];
  };

  const handleLocationSelection = (_, location) => {
    setLocation(location);
  };

  return (
    <FormController
      className={className}
      label={intl.formatMessage({
        id: 'businessLocationSelect.label',
        defaultMessage: 'Your city',
      })}
      helper={intl.formatMessage({
        id: 'helper.select.isSearchable',
        defaultMessage: 'Start typing and select from the list',
      })}
      required={required}
      showRequired={showRequired}
      showRequiredError={showRequiredError}
    >
      <div
        ref={selectRef}
      >
        <CustomSelect
          value={value}
          onChange={handleLocationSelection}
          isSearchable
          isAsync
          hasDebounce
          loadOptions={filterCities}
          placeholder={intl.formatMessage({
            id: 'helper.select.location.placeholder',
            defaultMessage: 'E.g. San Francisco, California',
          })}
          hasHidingPlaceholder
          id={id}
          error={showRequiredError}
        />
      </div>
    </FormController>
  );
};

BusinessLocationSelect.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.instanceOf(PropTypes.func).isRequired,
  required: PropTypes.bool,
  setLocation: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  showRequired: PropTypes.bool,
  showRequiredError: PropTypes.bool,
  id: PropTypes.string,
  selectRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
};

BusinessLocationSelect.defaultProps = {
  className: '',
  required: false,
  showRequired: false,
  showRequiredError: false,
  id: '',
  selectRef: null,
};

export default BusinessLocationSelect;
