import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import { Dialog } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import GlobalTheme from '../../../styled/GlobalTheme';
import { Icon } from '../../globals/ColoredIcon';
import {
  CloseOutlineIcon,
  InfoWarningColorIcon,
  InstagramWhiteIcon,
} from '../../../assets/icons/iconRepository';
import { INSTAGRAM } from '../../../utils/constants/globals';
import { Button } from '../../buttons';
import mediaQueries from '../../../utils/mediaQueries';

const CustomDialog = styled(Dialog)`
&&{
  .MuiPaper-root{
    margin-top: 130px;
    height: fit-content;
  }
}
${GlobalTheme.breakpoints.up('md')}{
  &&{
    .MuiPaper-root{
      margin-top: 0px;
      max-width: 681px;
      max-height: 90%;
    }
  }
}
`;

const CloseIcon = styled(Icon)`
  margin-right: 15px;
  margin-top: 10px;
  cursor: pointer;
  && {
    color: white;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 1em;
  padding-bottom: 48px;
  ${GlobalTheme.breakpoints.up('md')} {
    padding: 0 66px 64px 66px;
  }
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  :nth-child(2) {
    margin-top: 2em;
  }
`;

const ButtonsHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  ${GlobalTheme.breakpoints.up('md')}{
    margin-top: 48px;
  }
`;

const Item = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  margin-top: 1em;
  line-height: 22px;
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 18px;
    line-height: 25px;
    margin-top: 56px;
  }
`;

const BoxHelp = styled.div`
  background: ${({ theme }) => theme.colors.screenBackground};
  padding: 12px 16px 34px 16px;
  margin-top: 32px;
  width: 100%;
  @media ${mediaQueries.mdUp} {
    display: flex;
    align-items: center;
    margin-top: 28px;
    padding: 16px;
    width: max-content;
  }
`;
const BoxHelpHeader = styled.div`
  display: flex;
  justify-content: center;
`;
const IconHelpContainer = styled.div`
  display: flex;
  width: 24px;
`;
const IconHelp = styled.img`
  width: 100%;
`;

const BoxHelpItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 12px;
  @media ${mediaQueries.mdUp} {
    margin-top: 0;
    margin-left: 12px;
  }
`;

const LinkSteps = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
`;

const TextHighlight = styled.span`
  font-weight: 600;
`;

const CustomButton = styled(Button)`
  && {
    background: ${({ theme }) => theme.colors.instagram};
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    padding-left: 12px;
    padding-right: 12px;
    width: 229px;
    box-shadow: none;
    width: max-content;
    text-align: left;
    &:hover {
      background: ${({ theme }) => theme.colors.instagram};
    }
  }
`;

const SocialIconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  @media ${mediaQueries.mdUp} {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`;
const SocialIcon = styled.img`
  width: 100%;
  height: 100%;
`;
const ButtonText = styled.div`
  flex: 1;
`;

const ContainerVideo = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  @media ${mediaQueries.mdUp} {
    max-width: 567px;
    margin: 0 auto;
  }
  @media ${mediaQueries.xxlUp} {
    max-width: 604px;
  }
`;
const Video = styled.div``;

const IgConnectInfoModal = ({ isOpen, setOpen, cbFunc }) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showVideo, setShowVideo] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const urlPSDomain = 'https://www.socialpiper.com/static/media/videos';

  const triggerClick = () => {
    setOpen(false);
    setShowVideo(false);
    cbFunc(INSTAGRAM);
  };

  const handleShowVideo = () => {
    setShowVideo(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowVideo(false);
  };

  useEffect(() => {
    setVideoURL(
      intl.locale === 'es'
        ? `${urlPSDomain}/InstagramBusinessEsp.mp4`
        : `${urlPSDomain}/InstagramBusinessEng.mp4`,
    );
  }, intl.locale);

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <CustomDialog open={isOpen} fullScreen={fullScreen}>
          <Header>
            <CloseIcon
              src={CloseOutlineIcon}
              color={GlobalTheme.colors.black}
              size="20px"
              onClick={handleClose}
            />
          </Header>
          <Container>
            {showVideo ? (
              <ContainerVideo>
                <Video>
                  <iframe
                    width="277"
                    height="600"
                    src={videoURL}
                    title="Social Piper"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Video>
              </ContainerVideo>
            ) : (
              <Body>
                <Item>
                  <FormattedMessage
                    id="onboarding.launcher.modal.item1"
                    defaultMessage="<TextHighlight>Meta</TextHighlight> requires that your Instagram account has been previously switched to a Business account and it's also linked to a business Facebook page."
                    values={{
                      TextHighlight: (...chunks) => (
                        <TextHighlight>{chunks}</TextHighlight>
                      ),
                    }}
                  />
                </Item>
                <BoxHelp>
                  <BoxHelpHeader>
                    <IconHelpContainer>
                      <IconHelp src={InfoWarningColorIcon} />
                    </IconHelpContainer>
                  </BoxHelpHeader>
                  <BoxHelpItem>
                    <FormattedMessage
                      id="onboarding.launcher.modal.boxHelp.item"
                      defaultMessage="If you haven't switch it, just {lineBreak}<LinkSteps>follow these steps,</LinkSteps> it's very easy."
                      values={{
                        lineBreak: matches.mdDownLess ? <br /> : '',
                        LinkSteps: (...chunks) => (
                          <LinkSteps onClick={handleShowVideo}>
                            {chunks}
                          </LinkSteps>
                        ),
                      }}
                    />
                  </BoxHelpItem>
                </BoxHelp>
              </Body>
            )}
            <ButtonsHolder>
              <CustomButton onClick={() => triggerClick()}>
                <SocialIconContainer>
                  <SocialIcon src={InstagramWhiteIcon} />
                </SocialIconContainer>
                <ButtonText>
                  <FormattedMessage
                    id="onboarding.launcher.modal.button"
                    defaultMessage="Connect Instagram via {lineBreak}Facebook"
                    values={{
                      lineBreak: matches.mdDownLess ? <br /> : '',
                    }}
                  />
                </ButtonText>
              </CustomButton>
            </ButtonsHolder>
          </Container>
        </CustomDialog>
      )}
    </Media>
  );
};

IgConnectInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.bool.isRequired,
  cbFunc: PropTypes.string.isRequired,
};

export default IgConnectInfoModal;
