/** @type {(state: Array<Record<'id' | 'title' | 'subtitle' | 'enabled' | 'button', string | boolean | number>>, action: any) => typeof state | void} */
export const reducer = (state, action) => {
  switch (action.type) {
    case "next_step":
      return state.map((value) => {
        if (value.id === action.payload) value.finished = true;
        if (value.id === action.payload + 1) value.enabled = true;
        return value;
      });
    default:
      throw "unhandled action type!";
  }
};
