const loadFBSDK = () => {
  const { REACT_APP_FACEBOOK_APP_ID } = process.env;
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: `${REACT_APP_FACEBOOK_APP_ID}`,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v3.1',
    });
  };
  /* eslint-disable */
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  } (document, 'script', 'facebook-jssdk'));
  /* eslint-enable */
};

export default loadFBSDK;
