/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import './App.css';
import RocketLoader from './components/globals/RocketLoader';
import CompanyProvider from './contexts/CompanyContext';
import { GA4Context } from './contexts/GA4Context';
import { HJContexts } from './contexts/HJContexts';
import LayoutProvider from './contexts/LayoutContext';
import PermissionsContext from './contexts/PermissionsContext';
import PostFlowProvider from './contexts/PostFlowContext';
import { QueryStringContext } from './contexts/QueryStringContext';
import QuotasContext from './contexts/QuotasContext';
import RoleContext from './contexts/RolesContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import { UserSettingsContext } from './contexts/UserSettingsContext';
import { WebViewProvider } from './contexts/WebViewContext';
import { UnreadCommentsContext, useFetchUnreadComments } from './contexts/useUnreadCommentsContext';
import ApprovalsProvider from './views/Approvals/contexts/ApprovalsContext';
import routes, { redirects } from './routes/index';
import AuthFactory from './services/auth';
import AuthService from './services/auth/AuthService';
import RoleProvider from './services/entities/RoleProvider';
import { ENV, ENVIRONMENTS, INTERCOM_ID } from './utils/constants/globals';
import roles from './utils/constants/roles';
import ROUTES, { AuthRoutes, PublicRoutes } from './utils/constants/routes';

const App = () => {
  const { onboardingDetails, emailVerified, settingsRdy } = useContext(UserSettingsContext);
  const { cleanFirstLocation } = useContext(QueryStringContext);
  const { setLoginInfo } = useContext(GA4Context);
  const { initHotJar, identifyHJUser, identifyClarityUser } = useContext(HJContexts);
  const [startApp, setStartApp] = useState(false);
  const isApp = navigator.userAgent.includes('Convertify');
  const [role, setRole] = useState(roles.PRO);
  const [endDate, setEndDate] = useState(new Date());
  const [publishQuota, setPublishQuota] = useState(0);
  const [limitPublishQuota, setLimitPublishQuota] = useState(10);
  const [competitorsQuota, setCompetitorsQuota] = useState(0);
  const [limitCompetitorsQuota, setLimitCompetitorsQuota] = useState(10);
  const [boostQuota, setBoostQuota] = useState(0);
  const [limitBoostQuota, setLimitBoostQuota] = useState(0);
  const [canUseEditor, setCanUseEditor] = useState(true);
  const [canUseTemplates, setCanUseTemplates] = useState(true);
  const Quotas = {
    publishQuota,
    setPublishQuota,
    limitPublishQuota,
    setLimitPublishQuota,
    competitorsQuota,
    setCompetitorsQuota,
    limitCompetitorsQuota,
    setLimitCompetitorsQuota,
    boostQuota,
    setBoostQuota,
    limitBoostQuota,
    setLimitBoostQuota,
  };

  const Permissions = {
    canUseEditor,
    setCanUseEditor,
    canUseTemplates,
    setCanUseTemplates,
  };

  const update = ({ role: fetchedRole, endDate: fetchEndDate, permissions, quotas }) => {
    setPublishQuota(quotas.canPublish.usage);
    setLimitPublishQuota(quotas.canPublish.limit);
    setBoostQuota(quotas.canBoost.usage);
    setLimitBoostQuota(quotas.canBoost.limit);
    setCompetitorsQuota(quotas.canAddCompetitorFB.usage);
    setLimitCompetitorsQuota(quotas.canAddCompetitorFB.limit);
    setCanUseEditor(permissions.useEditor);
    setCanUseTemplates(permissions.useTemplates);
    setRole(fetchedRole);
    setEndDate(new Date(fetchEndDate));
  };

  const fetchAndUpdate = async () => {
    if (AuthService.isAuthenticated()) {
      const { success, data } = await RoleProvider.fetchRole();
      if (!success) return;
      update(data);
    }
  };

  useEffect(() => {
    initHotJar();
    fetchAndUpdate();
  }, []);

  useEffect(() => {
    if (settingsRdy) {
      setStartApp(true);
      setLoginInfo(true);
      identifyHJUser();
      identifyClarityUser();
    }
  }, [settingsRdy]);

  useEffect(() => {
    const execute = () => {
      if (AuthService.isAuthenticated()) {
        AuthFactory.initAuthAppSync(AuthService.getIdToken());
      }
    };
    return execute;
  }, []);

  const getPath = (route) => `${route.layout !== '/' ? route.layout : ''}${route.path}`;
  const [unreadComments, setUnreadComments] = useFetchUnreadComments();

  const inRouteObject = (route, routeObject) => Object.values(routeObject).indexOf(route) !== -1;

  return (
    <RoleContext.Provider
      value={{
        role,
        setRole,
        endDate,
        setEndDate,
        fetchAndUpdate,
      }}
    >
      <QuotasContext.Provider value={Quotas}>
        <PermissionsContext.Provider value={Permissions}>
          <UnreadCommentsContext.Provider value={{ unreadComments, setUnreadComments }}>
            <IntercomProvider appId={INTERCOM_ID}>
              <Router>
                <WebViewProvider>
                  <CompanyProvider>
                    <SubscriptionProvider>
                      <PostFlowProvider>
                        <ApprovalsProvider>
                          <LayoutProvider>
                            <Suspense fallback={<RocketLoader />}>
                              {startApp && (
                                <Switch>
                                  {routes.map((route) => {
                                    const path = getPath(route);
                                    return (
                                      <Route
                                        path={path}
                                        exact={route.exact}
                                        key={`route${route.layout}${route.path}`}
                                        render={(props) => {
                                          if (ENV !== ENVIRONMENTS.local) {
                                            if (
                                              inRouteObject(path, AuthRoutes) &&
                                              !AuthService.isAuthenticated()
                                            ) {
                                              // Not authenticated user trying
                                              // to enter authenticated route
                                              if (!AuthService.isExpiredTime()) {
                                                if (!isApp) {
                                                  return <Redirect to={ROUTES.LOGIN} />;
                                                }
                                                return <Redirect to={ROUTES.LANDING} />;
                                              }
                                              return <Redirect to={ROUTES.LANDING} />;
                                            }
                                            if (AuthService.isAuthenticated()) {
                                              cleanFirstLocation();
                                              if (!emailVerified && path !== ROUTES.VERIFY_EMAIL) {
                                                return <Redirect to={ROUTES.VERIFY_EMAIL} />;
                                              }
                                              // Authenticated user
                                              if (
                                                emailVerified &&
                                                !onboardingDetails.finished &&
                                                path !== ROUTES.ONBOARDING &&
                                                path !== ROUTES.CALLBACK &&
                                                path !== ROUTES.SOCIAL_LOGIN_ERROR &&
                                                path !== ROUTES.SOCIAL_LOGIN_SUCCESS
                                              ) {
                                                // Haven't completed the onboarding
                                                return (
                                                  <Redirect
                                                    to={{
                                                      pathname: ROUTES.ONBOARDING,
                                                      state: {
                                                        from: ROUTES.CREATE_ACCOUNT,
                                                      },
                                                    }}
                                                  />
                                                );
                                              }
                                              if (inRouteObject(path, PublicRoutes)) {
                                                // Trying to enter public route
                                                return <Redirect to={ROUTES.DASHBOARD} />;
                                              }
                                            }
                                          }
                                          return <route.component {...route.props} {...props} />;
                                        }}
                                      />
                                    );
                                  })}
                                  {redirects.map((route) => (
                                    <Redirect from={route.from} to={route.to} exact={route.exact} />
                                  ))}
                                  <Redirect
                                    to={{
                                      pathname: AuthService.isAuthenticated()
                                        ? ROUTES.DASHBOARD
                                        : ROUTES.LANDING,
                                      state: { redirected: true },
                                    }}
                                  />
                                </Switch>
                              )}
                              {!startApp && <RocketLoader />}
                            </Suspense>
                          </LayoutProvider>
                        </ApprovalsProvider>
                      </PostFlowProvider>
                    </SubscriptionProvider>
                  </CompanyProvider>
                </WebViewProvider>
              </Router>
            </IntercomProvider>
          </UnreadCommentsContext.Provider>
        </PermissionsContext.Provider>
      </QuotasContext.Provider>
    </RoleContext.Provider>
  );
};

export default App;
