import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormController from '../../../../../components/globals/FormController';
import Select from '../../../../../components/globals/Select';
import BusinessProvider from '../../../../../services/entities/BusinessProvider';
import useCallGA from '../../../../../hooks/useCallGA';

const CustomSelect = styled(Select)`
  && {
    width: 100%;
  }
`;

const BusinessCategoriesSelect = ({
  className,
  value,
  setCategories,
  required,
  intl,
  showRequired,
  showRequiredError,
  id,
  selectRef,
  labelCenter,
  placeholder
}) => {
  const callGA = useCallGA();
  const filterCategories = async (inputValue) => {
    const response = await BusinessProvider.searchCategories(inputValue, intl);
    if (response.success) {
      return response.data;
    }
    return [];
  };

  const handleCategoriesSelection = (_, categories) => {
    setCategories(categories);
  };

  return (
    <FormController
      className={className}
      label={intl.formatMessage({
        id: 'businessCategoriesSelect.label',
        defaultMessage: 'Type of business',
      })}
      required={required}
      showRequired={showRequired}
      showRequiredError={showRequiredError}
      labelCenter={labelCenter}
    >
      <div
        ref={selectRef}
      >
        <CustomSelect
          value={value}
          onChange={handleCategoriesSelection}
          isSearchable
          isMulti
          isAsync
          hasDebounce
          loadOptions={filterCategories}
          placeholder={placeholder || intl.formatMessage({
            id: 'businessCategoriesSelect.placeholder',
            defaultMessage: 'Write and select an option',
          })}
          hasHidingPlaceholder
          onMenuClose={() => {
            callGA({ action: 'Set /change business category', label: '(on business & audience)' });
          }}
          id={id}
          error={showRequiredError}
        />
      </div>
    </FormController>
  );
};

BusinessCategoriesSelect.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.instanceOf(PropTypes.func).isRequired,
  required: PropTypes.bool,
  setCategories: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  showRequired: PropTypes.bool,
  showRequiredError: PropTypes.bool,
  id: PropTypes.string,
  selectRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
  labelCenter: PropTypes.bool,
};

BusinessCategoriesSelect.defaultProps = {
  className: '',
  required: false,
  showRequired: false,
  showRequiredError: false,
  id: '',
  selectRef: null,
  labelCenter: false,
};

export default BusinessCategoriesSelect;
