import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  materialShadows,
  borderTop,
  heading3,
  flexBox,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { ButtonLink } from '../../../../../components/buttons';
import PiperAdvice from '../../../../../components/globals/PiperAdvice';
import Pipers from '../../../../../components/Pipers/Pipers';
import CardsSelector from './CardsSelector';
import { BillingProvider } from '../../../../../services/entities';
import { AlertsContext } from '../../../../../contexts/AlertsContext';
import CardLoader from './CardLoader';
import useCallGA from '../../../../../hooks/useCallGA';

const BillingInformationCard = styled.div`
  ${materialShadows};
  ${borderTop('speechBubble')};
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 0;
  ${flexBox}
  flex-direction: column;
  justify-content: flex-start;
  ${GlobalTheme.breakpoints.down('md')}{
    padding: 24px 0;
  }
`;

const Content = styled.div`
  ${flexBox}
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h3`
  ${heading3}
  margin-bottom: 24px;
  padding: 0 32px;
  width: 100%;
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  margin-top: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 32px;
  width: 100%;
`;

const StyledPiper1 = styled(Pipers.Piper1)`
  align-self: center;
  width: 120px;
  height: auto;
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: center;

  &&{
    ${GlobalTheme.breakpoints.down('sm')}{
      margin-top: 20px;
    }
  }
`;

const BillingInformation = ({
  className,
  setShowAddPaymentModal,
  paymentMethods,
  setPaymentMethods,
  isPlanApple,
  isPlanAndroid,
}) => {
  const intl = useIntl();
  const callGA = useCallGA();
  const { showAlert, showBackendError } = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);
  const handleClickCard = async (card) => {
    if (card.isDefaultPayment) return;
    setLoading(true);
    const response = await BillingProvider.updateDefaultMethod(card.id, paymentMethods);
    if (response.success) {
      setPaymentMethods(response.data);
      showAlert(intl.formatMessage({
        id: 'alert.success.save.female',
        defaultMessage: '{item} saved successfully',
      }, {
        item: intl.formatMessage({
          id: 'k.defaultCard',
          defaultMessage: 'Default card',
        }),
      }));
    } else {
      showBackendError(response.message);
    }
    setLoading(false);
  };
  const handleDeleteCard = async (card) => {
    setLoading(true);
    const response = await BillingProvider.deletePaymentMethod(card.id, paymentMethods);
    if (response.success) {
      setPaymentMethods(response.data);
      showAlert(intl.formatMessage({
        id: 'alert.success.delete.female',
        defaultMessage: '{item} deleted successfully.',
      }, {
        item: intl.formatMessage({
          id: 'k.card',
          defaultMessage: 'Card',
        }),
      }));
    } else {
      showBackendError(response.message);
    }
    setLoading(false);
  };

  return (
    <BillingInformationCard
      className={className}
    >
      <CardLoader
        loading={loading}
      />
      <Title>
        <FormattedMessage
          id="settings.billing.billingInformation.title"
          defaultMessage="Your Billing Information"
        />
      </Title>
      <Content>
        <StyledPiperAdvice>
          {(isPlanApple || isPlanAndroid) ? (
            <FormattedMessage
              id="settings.billing.billingInformation.advice.app"
              defaultMessage="This is used only to pay your Social Piper Boost fees."
            />
          ) : (
            <FormattedMessage
              id="settings.billing.billingInformation.advice"
              defaultMessage="This is used to pay your plan fees and Social Piper Boost fees."
            />
          )}
        </StyledPiperAdvice>
        {paymentMethods.length > 0 ? (
          <CardsSelector
            paymentMethods={paymentMethods}
            handleClickCard={handleClickCard}
            handleDeleteCard={handleDeleteCard}
          />
        ) : (
          <StyledPiper1 />
        )}
        <StyledButtonLink
          onClick={() => {
            callGA({ action: 'Add a payment method', label: '(on billing & plan)' });
            setShowAddPaymentModal(true);
          }}
        >
          <FormattedMessage
            id="settings.billing.billingInformation.newPayment"
            defaultMessage="Add new payment method"
          />
        </StyledButtonLink>
      </Content>
    </BillingInformationCard>
  );
};

BillingInformation.propTypes = {
  className: PropTypes.string,
  setShowAddPaymentModal: PropTypes.func.isRequired,
  paymentMethods: PropTypes.instanceOf(Array).isRequired,
  setPaymentMethods: PropTypes.func.isRequired,
  isPlanApple: PropTypes.bool,
  isPlanAndroid: PropTypes.bool,
};

BillingInformation.defaultProps = {
  className: '',
  isPlanApple: false,
  isPlanAndroid: false,
};

export default BillingInformation;
