import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import { getCities, searchLinkedinLocation } from '../../graphql/queries';

export default class LocationProvider {
  static async linkedinSearch(searchValue) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      const response = await authAppSync.client.query({
        query: searchLinkedinLocation,
        variables: {
          wordToSearch: searchValue,
        },
      });
      const data = response.data.searchLinkedinLocation.map(({
        id, name,
      }) => ({
        id,
        value: id,
        label: name,
      }));

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async facebookSearch(searchValue, intl) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      const response = await authAppSync.client.query({
        query: getCities,
        variables: {
          name: searchValue,
          language: intl.locale,
        },
      });
      const data = response.data.cities.map(({
        key,
        name,
        region,
        countryName,
        type,
        regionId,
        countryCode,
        countries,
      }) => {
        const normalizedName = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const normalizedCountryName = countryName && countryName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const isCountry = normalizedName === normalizedCountryName && !region;
        const intlId = `k.${isCountry ? 'country' : type}`;

        const label = `${name.split(',')[0]}${region ? `, ${region}` : ''}${(countryName && !isCountry) ? `, ${countryName}` : ''}`;
        const value = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return {
          id: key,
          value,
          label,
          type,
          helper: intl.formatMessage({
            id: intlId,
            defaultMessage: intlId.split('.')[1],
          }),
          ...(regionId && { regionId }),
          ...(countryCode && { countryCode }),
          ...(countries && { countries }),
        };
      });

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async search(searchValue, intl, isLinkedin = false) {
    if (isLinkedin) {
      return this.linkedinSearch(searchValue);
    }
    return this.facebookSearch(searchValue, intl);
  }
}
