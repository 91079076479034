import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormController from '../../../../../components/globals/FormController';
import Select from '../../../../../components/globals/Select';
import { LocationProvider } from '../../../../../services/lookups';
import { LOCATION_TYPES } from '../../../../../utils/constants/settings';
import AudienceLocationConfirmationModal from './AudienceLocationConfirmationModal';

const CustomSelect = styled(Select)`
  && {
    width: 100%;
  }
`;

const AudienceLocationsSelect = ({
  className,
  value,
  setLocations,
  intl,
  required,
  showRequired,
  showRequiredError,
  id,
  selectRef,
  isLinkedin,
}) => {
  const [alertInfo, setAlertInfo] = useState({
    isOpen: false,
    message: '',
    changeLocations: [],
  });
  const filterCities = async (inputValue) => {
    const response = await LocationProvider.search(inputValue, intl, isLinkedin);
    if (response.success) {
      return response.data;
    }
    return [];
  };

  const handleLocationsSelection = (_, locations) => {
    if (!isLinkedin && locations && value.length < locations.length) {
      // Added location
      const newLocation = locations[locations.length - 1];
      for (let index = 0; index < value.length; index += 1) {
        const oldLocation = value[index];
        /*
         * Check whether the newLocation has different type to any already there.
         * If the newLocation.type is the same as every previous location it can be safely added.
        */
        if (newLocation.type !== oldLocation.type) {
          /*
          * Check whether the newLocation is a country_group
          */
          if (newLocation.type === LOCATION_TYPES.country_group) {
            const changeLocations = [newLocation];
            setAlertInfo({
              isOpen: true,
              message: intl.formatMessage({
                id: 'audienceLocationsSelect.alert.added.countryGroup',
                defaultMessage: 'Do you want to replace your cities and/or countries with {region}?',
              }, {
                region: newLocation.label,
              }),
              changeLocations,
            });
            return;
          }
          /*
           * Check whether the oldLocation is a country_group
          */
          if (oldLocation.type === LOCATION_TYPES.country_group) {
            const changeLocations = [newLocation];
            setAlertInfo({
              isOpen: true,
              message: intl.formatMessage({
                id: 'audienceLocationsSelect.alert.replace.countryGroup',
                defaultMessage: 'Do you want to replace your regions with {location}?',
              }, {
                location: newLocation.label,
              }),
              changeLocations,
            });
            return;
          }
          /*
          * Check whether the newLocation is a country
          * and if there's overlap
          */
          if (
            newLocation.type === LOCATION_TYPES.country
            && (
              newLocation.id === oldLocation.countryCode
              || oldLocation.value.endsWith(newLocation.value)
            )
          ) {
            const changeLocations = value.slice(0, index);
            for (let j = index; j < value.length; j += 1) {
              const ol = value[j];
              if (ol.countryCode) {
                if (ol.countryCode !== newLocation.id) {
                  changeLocations.push(ol);
                }
              } else if (!ol.value.endsWith(newLocation.value)) {
                changeLocations.push(ol);
              }
            }
            changeLocations.push(newLocation);
            setAlertInfo({
              isOpen: true,
              message: intl.formatMessage({
                id: 'audienceLocationsSelect.alert.added.country',
                defaultMessage: '{country} contains at least one location you already selected. Do you want to replace them?',
              }, {
                country: newLocation.label,
              }),
              changeLocations,
            });
            return;
          }
          /*
          * Check whether the newLocation is a region (state)
          * and if there's overlap
          */
          if (
            newLocation.type === LOCATION_TYPES.region
            && (
              `${newLocation.id}` === `${oldLocation.regionId}`
              || oldLocation.value.includes(newLocation.value)
              || newLocation.countryCode === oldLocation.id
            )
          ) {
            const changeLocations = value.slice(0, index);
            for (let j = index; j < value.length; j += 1) {
              const ol = value[j];
              if (ol.type === LOCATION_TYPES.country) {
                if (newLocation.countryCode !== ol.id) {
                  changeLocations.push(ol);
                }
              } else if (LOCATION_TYPES[ol.type] === LOCATION_TYPES.city) {
                if (ol.regionId) {
                  if (`${ol.regionId}` !== `${newLocation.id}`) {
                    changeLocations.push(ol);
                  }
                } else if (!ol.value.includes(newLocation.value)) {
                  changeLocations.push(ol);
                }
              } else {
                changeLocations.push(ol);
              }
            }
            changeLocations.push(newLocation);
            setAlertInfo({
              isOpen: true,
              message: intl.formatMessage({
                id: 'audienceLocationsSelect.alert.added.region',
                defaultMessage: '{region} contains or is contained in at least one location you already selected. Do you want to replace them?',
              }, {
                region: newLocation.label,
              }),
              changeLocations,
            });
            return;
          }
          /*
           * The newLocation is either city, neighborhood, subneighborhood or zip
           * Check if there's overlap
          */
          if (
            `${newLocation.regionId}` === `${oldLocation.id}`
            || newLocation.countryCode === oldLocation.id
          ) {
            const changeLocations = value.slice(0, index);
            for (let j = index; j < value.length; j += 1) {
              const ol = value[j];
              if (ol.type === LOCATION_TYPES.country) {
                if (newLocation.countryCode !== ol.id) {
                  changeLocations.push(ol);
                }
              } else if (ol.type === LOCATION_TYPES.region) {
                if (`${ol.id}` !== `${newLocation.regionId}`) {
                  changeLocations.push(ol);
                }
              } else {
                changeLocations.push(ol);
              }
            }
            changeLocations.push(newLocation);
            setAlertInfo({
              isOpen: true,
              message: intl.formatMessage({
                id: 'audienceLocationsSelect.alert.added.city',
                defaultMessage: '{city} is contained inside at least one location you already selected. Do you want to replace them?',
              }, {
                city: newLocation.label.split(',')[0],
              }),
              changeLocations,
            });
            return;
            /*
             * The location can be safely added
            */
          }
        }
      }
    }
    setLocations(locations);
  };

  const handleModalCancel = () => {
    setAlertInfo({
      isOpen: false,
      message: '',
      newLocation: {},
      changeLocations: [],
    });
  };

  const handleModalChange = () => {
    setLocations(alertInfo.changeLocations);
    handleModalCancel();
  };

  return (
    <>
      <FormController
        className={className}
        label={intl.formatMessage({
          id: 'audienceLocationsSelect.label',
          defaultMessage: 'Where are your clients?',
        })}
        helper={intl.formatMessage({
          id: 'audienceLocationsSelect.helper',
          defaultMessage: 'Start typing the name of the state, city or country and select from the list',
        })}
        required={required}
        showRequired={showRequired}
        showRequiredError={showRequiredError}
      >
        <div
          ref={selectRef}
        >
          <CustomSelect
            value={value}
            onChange={handleLocationsSelection}
            isSearchable
            isAsync
            hasDebounce
            isMulti
            loadOptions={filterCities}
            placeholder={intl.formatMessage({
              id: 'helper.select.location.placeholder',
              defaultMessage: 'E.g. San Francisco, California',
            })}
            hasHidingPlaceholder
            id={id}
            error={showRequiredError}
          />
        </div>
      </FormController>
      <AudienceLocationConfirmationModal
        alertInfo={alertInfo}
        handleCancel={handleModalCancel}
        handleChange={handleModalChange}
      />
    </>
  );
};

AudienceLocationsSelect.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.instanceOf(PropTypes.func).isRequired,
  required: PropTypes.bool,
  setLocations: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  showRequired: PropTypes.bool,
  showRequiredError: PropTypes.bool,
  id: PropTypes.string,
  selectRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
  isLinkedin: PropTypes.bool,
};

AudienceLocationsSelect.defaultProps = {
  className: '',
  required: false,
  showRequired: false,
  showRequiredError: false,
  id: '',
  selectRef: null,
  isLinkedin: false,
};

export default AudienceLocationsSelect;
