import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { colors } from '../../../styled/theme';
import { PostFlowContext } from '../../../contexts/PostFlowContext';

const Container = styled.div`
  position: relative;
  margin-bottom: 5px;
  z-index: 2;
`;

const TextareaAutosizeSty = styled(TextareaAutosize)`
  font-family: ${(props) => props.theme.font.font1};
  width: 100%;
  resize: none;
  font-size: 14px;
  outline: 0;
  border: 0;
  background-color: transparent;
`;

const Highlighter = styled.div`
  font-family: ${(props) => props.theme.font.font1};
  font-size: 14px;
  color: transparent;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  padding: 2px;
  white-space: pre-wrap;
  word-wrap: break-word;
  span {
    background-color: ${colors.red};
    opacity: 0.3;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    padding: 2px 7px;
  }
`;

const NoteOnlyStorie = styled.div`
  display: ${(props) => ((props.show) ? 'inline;' : 'none;')};
  position: absolute;
  background: white;
  width: 100%;
  color: orange;
  font-size: 16px;
  height: 100%;
`;

const CaptionTextArea = ({
  placeholder,
  value,
  onChange,
  onClick,
  maxLength,
  inputTextRef,
}) => {
  const { where2Post } = useContext(PostFlowContext);
  const intl = useIntl();
  const [highlightHtml, setHighlightHtml] = useState(value);
  const [isiPhone] = useState(navigator.userAgent.indexOf('iPhone') !== -1);
  const [showNoteStorieIG, setShowNoteStorieIG] = useState(false);
  const highlight = (val) => {
    let html = val;
    if (val.length > maxLength) {
      html = `${val.slice(0, maxLength)}<span>${val.slice(maxLength)}</span>`;
    }
    setHighlightHtml(html.replace(/\n/g, '<br />'));
  };
  useEffect(() => {
    highlight(value);
  }, [maxLength, value]);
  const handleChange = (val) => {
    onChange(val);
  };
  useEffect(() => {
    const basicNetworkSelect = where2Post.twitter
      || where2Post.facebook
      || where2Post.instagram
      || where2Post.linkedin
      || where2Post.tiktok;

    if (!basicNetworkSelect && where2Post.storiesIG) {
      setShowNoteStorieIG(true);
    } else {
      setShowNoteStorieIG(false);
    }
  }, [where2Post]);
  return (
    <Container>
      <NoteOnlyStorie show={showNoteStorieIG}>
        {intl.formatMessage({
          id: 'postflow.create.placeholder.storiesIG',
          defaultMessage: 'Caption is not required',
        })}
      </NoteOnlyStorie>
      <TextareaAutosizeSty
        ref={inputTextRef}
        placeholder={placeholder}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        onClick={onClick}
        rowsMin={5}
      />
      <Highlighter
        isiPhone={isiPhone}
        dangerouslySetInnerHTML={{ __html: highlightHtml }}
      />
    </Container>
  );
};

CaptionTextArea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  maxLength: PropTypes.number.isRequired,
  inputTextRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.object,
    }),
    PropTypes.func,
  ]),
};

CaptionTextArea.defaultProps = {
  placeholder: '',
  onClick: () => {},
  inputTextRef: null,
};

export default CaptionTextArea;
