import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../styled/theme';
import GlobalTheme from '../../styled/GlobalTheme';

const NavBarButton = styled(Button)`
  && {
    display: inline-flex;
    color: ${(props) => props.theme.colors.black};

    /* identical to box height */
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;

    margin: 0px 2px;

    ${(props) =>
      props.hideDown &&
      `
      ${GlobalTheme.breakpoints.down(props.hideDown)} {
        display: none;
      }
    `}
  }
`;

export default NavBarButton;
