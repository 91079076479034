import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { container } from '../../../../styled/mixins';
import GlobalTheme from '../../../../styled/GlobalTheme';
import PlanInformation from './components/PlanInformation';
import BillingInformation from './components/BillingInformation';
import PaymentHistory from './components/PaymentHistory';
import AddPaymentModal from '../../../../components/AddPaymentModal';
import DisableAccount from './components/DisableAccount';
import { BillingProvider } from '../../../../services/entities';
import { AlertsContext } from '../../../../contexts/AlertsContext';
import PlanProvider from '../../../../services/entities/PlanProvider';
import SubscriptionModal from './components/SubscriptionModal';
import SubscriptionAppsModal from './components/SubscriptionAppsModal';
import useCallGA from '../../../../hooks/useCallGA';
import { PLAN_STATUS } from '../../../../utils/constants/settings';
import routes from '../../../../utils/constants/routes';
import CancelSubscriptionModal from './components/CancelSubscriptionModal';
import { LayoutContext } from '../../../../contexts/LayoutContext';
import SuccessfulSubscriptionModal from './components/SuccessfulSubscriptionModal';
import PromoCodeContext from '../../../../contexts/PromoCodeContext';
import { UserSettingsContext } from '../../../../contexts/UserSettingsContext';
import RocketLoader from '../../../../components/globals/RocketLoader';
import { DELETE_SUB_SURVEY } from '../../../../utils/constants/hotjarValues';
import { sleep } from '../../../../utils';
import UpgradeToBusinessModal from './components/UpgradeToBusinessModal';
import DowngradeToProModal from './components/DowngradeToProModal';
import UserProvider from '../../../../business/services/UserProvider';
import InitAppAccess from '../../../../hooks/permissions/initAppAccess';
import useCompany from '../../../../hooks/useCompany';

const BillingContainer = styled.div`
  ${container};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(330px, auto) auto auto;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  padding-bottom: 70px;
  grid-template-areas: "planInfo billingInfo"
                        "history history"
                        "disable disable";

  ${GlobalTheme.breakpoints.down('sm')}{
    grid-template-rows: repeat(4, auto);
    grid-template-areas: "planInfo planInfo"
                        "billingInfo billingInfo"
                        "history history"
                        "disable disable";
  }
`;

const StyledPlanInformation = styled(PlanInformation)`
  grid-area: planInfo;
`;

const StyledBillingInformation = styled(BillingInformation)`
  grid-area: billingInfo;
`;

const StyledPaymentHistory = styled(PaymentHistory)`
  grid-area: history;
`;

const StyledDisableAccount = styled(DisableAccount)`
  grid-area: disable;
`;

const Billing = () => {
  const intl = useIntl();
  const history = useHistory();
  const { fetchAndUpdate } = InitAppAccess();
  const { companies } = useCompany();
  const { showAlert, showBackendError, showError } = useContext(AlertsContext);
  const {
    user, setUser, showModalSubAppsModal, setShowModalSubAppsModal, setShowUpgradeLoader
  } = useContext(LayoutContext);
  const { PromoCode, setPromoCode } = useContext(PromoCodeContext);
  const { subsCurrency, countryCode } = useContext(UserSettingsContext);
  const [paymentPayload, setPaymentPayload] = useState(null);
  const [addFromSubscribe, setAddFromSubscribe] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [openUpgradeToBusinessModal, setOpenUpgradeToBusinessModal] = useState(false);
  const [openDowngradeToProModal, setOpenDowngradeToProModal] = useState(false);
  const [showSuccessfulModal, setShowSuccessfulModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const callGA = useCallGA();
  const [subscription, setSubscription] = useState({
    subcriptionId: null,
    planName: '',
    planStatus: '',
    planStartDate: '',
    planPrice: 0,
    isMonthly: true,
    isScheduled: false,
    planEndDate: '',
  });
  const [isPlanApple, setIsPlanApple] = useState(false);
  const [isPlanAndroid, setIsPlanAndroid] = useState(false);

  const fetchPlans = async () => {
    const response = await PlanProvider.fetchAllPlans();
    if (response.success) {
      const { plans, promocode: code } = response.data;
      setPromoCode({
        plans,
        code,
        isValid: !!code,
      });
    }
  };

  const fetchPaymentPayload = async () => {
    const response = await PlanProvider.fetchPaymentDates();
    if (!response.success) {
      setPaymentPayload({
        lastPaymentDate: '',
        nextPaymentDate: '',
        paymentAmount: 0.0,
      });
    }
    if (response.success) {
      setPaymentPayload(response.data);
    } else {
      setPaymentPayload({
        lastPaymentDate: '',
        nextPaymentDate: '',
        paymentAmount: 0.0,
      });
    }
  };

  const fetchSubscription = async () => {
    const response = await PlanProvider.fetchSubscription();
    if (!response.success) {
      setPaymentPayload({
        lastPaymentDate: '',
        nextPaymentDate: '',
        paymentAmount: 0.0,
      });
    }

    if (response.success) {
      if (response.data.applyAndroid) {
        setIsPlanAndroid(true);
      }
      if (response.data.applyIOS) {
        setIsPlanApple(true);
      }
      setPaymentPayload({
        lastPaymentDate: response.data.planStartDate,
        nextPaymentDate: response.data.planEndDate,
        paymentAmount: response.data.planPrice,
      });
      setSubscription(response.data);
      if (response.data.planStatus === PLAN_STATUS.ACTIVE
        && !response.data.applyAndroid
        && !response.data.applyIOS) {
        fetchPaymentPayload();
      } else {
        setPaymentPayload({
          lastPaymentDate: '',
          nextPaymentDate: '',
          paymentAmount: 0.0,
        });
      }
    } else {
      setPaymentPayload({
        lastPaymentDate: '',
        nextPaymentDate: '',
        paymentAmount: 0.0,
      });
    }
  };

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const response = await BillingProvider.fetchPaymentMethods();
      if (!response.success) {
        setPaymentMethods([]);
      }
      if (response.success) {
        setPaymentMethods(response.data);
      } else {
        setPaymentMethods([]);
      }
    };

    fetchSubscription();
    fetchPlans();
    fetchPaymentMethods();
  }, []);

  const handleSubscribe = async (setLoading, plan) => {
    /**
     * NOW the backend has the control for retrieve which plans are valid
     * if you wanna change this behaviour you have to change appsync instead
     * fetchAllPlans
     */
    const { id, isMonthly } = plan;
    setLoading(true);
    const response = await PlanProvider.subscribeToPlan(id);
    if (response.success) {
      setUser({ ...user, stillActive: true });
      setSubscription(response.data.subscriptionInfo);
      setPaymentPayload(response.data.payment);
      const periodicity = isMonthly ? 'M' : 'Y';
      callGA({ action: `Make Subscription ${periodicity} ${PromoCode.code}`, label: '(button)' });
      setShowSubscribeModal(false);
      setShowSuccessfulModal(true);
    } else {
      showBackendError(response.message);
    }
    setLoading(false);
  };

  const handleUpgradeToBusiness = async (setLoading, setWasSuccessfullyUpgraded) => {
    setLoading(true);
    setShowUpgradeLoader(true);
    setOpenUpgradeToBusinessModal(false);
    const response = await UserProvider.upgradeToABusinessUser(user.id, subsCurrency, countryCode);
    if (response.success) {
      await fetchAndUpdate();
      setLoading(false);
      setShowUpgradeLoader(false);
      setWasSuccessfullyUpgraded(true);
    } else {
      setShowUpgradeLoader(false);
      showBackendError(response.message);
      setLoading(false);
    }
  };

  const handleDowngradeToPro = async (setLoading, companyIdToKeep, setWasSuccessfullyUpgraded) => {
    setLoading(true);
    setShowUpgradeLoader(true);
    setOpenDowngradeToProModal(false);
    const response = await UserProvider.downgradeToAProUser(user.id, companyIdToKeep, subsCurrency, countryCode);
    if (response.success) {
      setLoading(false);
      setShowUpgradeLoader(false);
      setWasSuccessfullyUpgraded(true);
    } else {
      setShowUpgradeLoader(false);
      showBackendError(response.message);
      setLoading(false);
    }
  }

  const handleTransitionAfterUpgradeOrDowngrade = (setOpen) => {
    history.push(routes.DASHBOARD);
    history.go(0);
    setOpen(false);
  };

  const deleteSubscriptionSurvey = () => {
    const surveyUrl = DELETE_SUB_SURVEY[intl.locale];
    window.open(surveyUrl, '_blank');
  };

  const handleCancel = async (setLoading) => {
    setLoading(true);
    const response = await PlanProvider.unsubscribe();
    if (response.success) {
      fetchPlans();
      showAlert(intl.formatMessage({
        id: 'settings.billing.planInformation.canceled',
        defaultMessage: 'You have canceled your subscription to Social Piper.',
      }));
      setSubscription(response.data.subscriptionInfo);
      callGA({ action: 'Cancel Subscription', label: '(button)' });
      setShowCancelModal(false);
      await sleep(500);
      deleteSubscriptionSurvey();
    } else {
      showError(intl.formatMessage({
        id: 'alert.error.subscription.canceled',
        defaultMessage: 'The subscription couldn\'t be canceled. Please try again or contact support@socialpiper.com.',
      }));
    }
    setLoading(false);
  };

  const handleShowSubscribeModal = () => {
    if (paymentMethods.length) {
      setShowSubscribeModal(true);
    } else {
      setAddFromSubscribe(true);
      setShowAddPaymentModal(true);
    }
  };

  const handleClose = (addedCard = false) => {
    setShowAddPaymentModal(false);
    if (addedCard && addFromSubscribe) {
      // Added payment and was from subscribe button
      setShowSubscribeModal(true);
    }
    setAddFromSubscribe(false);
  };

  const handleShowCancelSubscriptionModal = () => {
    setShowCancelModal(true);
  };

  const handleShowUpgradeToBusinessModal = () => {
    setOpenUpgradeToBusinessModal(true);
  };

  const handleShowDowngradeToProModal = () => {
    setOpenDowngradeToProModal(true);
  }

  if (!paymentMethods || !paymentPayload) {
    return <RocketLoader />;
  }

  return (
    <>
      <BillingContainer>
        <StyledPlanInformation
          subscription={subscription}
          paymentPayload={paymentPayload}
          handleShowSubscribeModal={handleShowSubscribeModal}
          handleShowCancelSubscriptionModal={handleShowCancelSubscriptionModal}
          handleShowUpgradeToBusinessModal={handleShowUpgradeToBusinessModal}
          handleShowDowngradeToProModal={handleShowDowngradeToProModal}
          userProfile={user.profileId}
          intl={intl}
          isPlanApple={isPlanApple}
          isPlanAndroid={isPlanAndroid}
        />
        <StyledBillingInformation
          setShowAddPaymentModal={setShowAddPaymentModal}
          setPaymentMethods={setPaymentMethods}
          paymentMethods={paymentMethods}
          isPlanApple={isPlanApple}
          isPlanAndroid={isPlanAndroid}
        />
        <StyledPaymentHistory />
        <StyledDisableAccount
          isPlanApple={isPlanApple}
          isPlanAndroid={isPlanAndroid}
        />
      </BillingContainer>
      {(showAddPaymentModal) && (
        <AddPaymentModal
          open={showAddPaymentModal}
          handleClose={handleClose}
          setPaymentMethods={setPaymentMethods}
        />
      )}
      {(showSubscribeModal) && (
        <SubscriptionModal
          open={showSubscribeModal}
          setOpen={setShowSubscribeModal}
          handleSubscribe={handleSubscribe}
        />
      )}
      {(showCancelModal) && (
        <CancelSubscriptionModal
          open={showCancelModal}
          setOpen={setShowCancelModal}
          handleCancel={handleCancel}
          isPlanApple={isPlanApple}
        />
      )}
      {(showSuccessfulModal) && (
        <SuccessfulSubscriptionModal
          open={showSuccessfulModal}
          setOpen={setShowSuccessfulModal}
        />
      )}
      <SubscriptionAppsModal
        open={showModalSubAppsModal}
        setOpen={setShowModalSubAppsModal}
        fetchSubscription={fetchSubscription}
      />
      <UpgradeToBusinessModal
        openModal={openUpgradeToBusinessModal}
        setOpenModal={setOpenUpgradeToBusinessModal}
        onUpgrade={handleUpgradeToBusiness}
        handleTransition={handleTransitionAfterUpgradeOrDowngrade}
        />
        <DowngradeToProModal
        open={openDowngradeToProModal}
        setOpen={setOpenDowngradeToProModal}
        handleDowngradeToPro={handleDowngradeToPro}
        companies={companies}
        handleTransition={handleTransitionAfterUpgradeOrDowngrade}
        />
    </>
  );
};

export default Billing;
