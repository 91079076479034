import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Media from 'react-media';
import { flex, borderTop, materialShadows } from '../../../../styled/mixins';
import SocialProfiles from '../../../../components/SocialProfiles';
import PiperAdvice from '../../../../components/globals/PiperAdvice';
import GlobalTheme from '../../../../styled/GlobalTheme';
import { SocialProfilesProvider } from '../../../../services/entities';
import { SocialContext } from '../../../../contexts/SocialContext';
import { LayoutContext } from '../../../../contexts/LayoutContext';
import BusinessProvider from '../../../../services/entities/BusinessProvider';
import MultiaccountModal from '../../../../components/SocialProfiles/facebook/MultiaccountModal';
import ReConnectFacebookModal from '../../../../components/notifications/ReconnectFacebook';
import RocketLoader from '../../../../components/globals/RocketLoader';
import { filterNullValues } from '../../../../utils';
import TrustMarkBox from '../../../SocialLogin/components/TrustMarkBox';
import mediaQueries from '../../../../utils/mediaQueries';

const SocialContainer = styled.div`
  ${flex};
  justify-content: flex-start;
  flex-wrap: wrap;
  ${({ clearSpaces }) => !clearSpaces && 'padding-bottom: 90px; '}
`;

const StyledSocialProfiles = styled(SocialProfiles)`
  ${borderTop('speechBubble')};
  ${materialShadows}
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  width: 100%;
  ${GlobalTheme.breakpoints.down('md')}{
    padding: 24px 16px;
  }
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  margin-top: ${({ marginTop }) => marginTop || '24px'};
  align-items: flex-start;
  display: flex;
  margin-bottom: 0;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 20px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 16px;
  }
  @media ${mediaQueries.xlPlusUp} {
    max-width: 802px;
  }
`;

const CustomTrustMarkBox = styled(TrustMarkBox)`
  && {
    max-width: 360px;
    margin: 32px auto;
    @media ${mediaQueries.mdUp} {
      width: max-content;
      max-width: initial;
      margin-top: 24px;
    }
    @media ${mediaQueries.xlPlusUp} {
      margin: 0;
    }
  }
`;

const SocialContainerHeader = styled.div`
  width: 100%;
  @media ${mediaQueries.xlPlusUp} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
  }
`;

/** @type {(props: any) => JSX.Element} */
const Social = ({ hideCompetitors, clearSpaces, overrideTitle }) => {
  const {
    socialNetworks, setSocialNetworks, fetchSocialNetworksData, setHasConnectedAccounts,
  } = useContext(SocialContext);
  const [companyName, setCompanyName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showReConnectFacebookModal, setShowReConnectFacebookModal] = useState(false);
  const { updateUserStatus } = useContext(LayoutContext);
  
  useEffect(() => {
    const fetchCompanyName = async () => {
      const response = await BusinessProvider.fetch('companyName');
      if (response.success) {
        setCompanyName(response.data.companyName);
      }
    };

    fetchSocialNetworksData();
    fetchCompanyName();
  }, []);

  const hasConnectedSN = (sn) => {
    const accounts = filterNullValues(Object.values(sn));
    const activeAccounts = accounts.filter((x) => !!x.pageName);
    return activeAccounts.length > 0;
  };

  const handleDisconnect = async (socialNetwork) => {
    setLoading(true);
    try {
      const { data } = await SocialProfilesProvider.disconnect(socialNetwork, socialNetworks);
      setSocialNetworks(data);
      const hasActiveAccount = hasConnectedSN(data);
      setHasConnectedAccounts(hasActiveAccount);
      setLoading(false);
      setShowReConnectFacebookModal(false);
      updateUserStatus();
    } catch (error) {
      setLoading(false);
      setShowReConnectFacebookModal(false);
    }
  };

  if (!socialNetworks) {
    return <RocketLoader />;
  }

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <>
          <SocialContainer clearSpaces={clearSpaces}>
            {!clearSpaces && (
              <SocialContainerHeader>
                <StyledPiperAdvice
                  marginTop="0"
                >
                  <FormattedMessage
                    id="settings.social.advice.connect"
                    defaultMessage="Connect up to create content, see your competitors, boost your posts, and track their performance."
                  />
                </StyledPiperAdvice>
                {matches.xlPlusUp && (
                  <CustomTrustMarkBox fromSettings />
                )}
              </SocialContainerHeader>
            )}
            {matches.xlPlusDownLess && (
              <CustomTrustMarkBox />
            )}
            <StyledSocialProfiles
              socialNetworks={socialNetworks}
              companyName={companyName}
              setShowReConnectFacebookModal={setShowReConnectFacebookModal}
              overrideTitle={overrideTitle}
            />
          </SocialContainer>
          <ReConnectFacebookModal
            open={showReConnectFacebookModal}
            handleDisconnect={handleDisconnect}
            loading={loading}
          />
          <MultiaccountModal />
        </>
      )}
    </Media>
  );
};

Social.propTypes = {
  hideCompetitors: PropTypes.bool,
  clearSpaces: PropTypes.bool,
  overrideTitle: PropTypes.string,
};

Social.defaultProps = {
  hideCompetitors: false,
  clearSpaces: false,
  overrideTitle: '',
};

export default Social;
