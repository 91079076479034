const es = {
  'tutorialModal.skip': 'Saltar',
  'tutorialModal.done': 'Hecho',
  'tutorialModal.slide1.title': '¡Ya estás listo!',
  'tutorialModal.slide1.masterUser.title': '¡Bienvenido a tu cuenta Business!',
  'tutorialModal.slide1.collabUser.title': '¡Empecemos!',
  'tutorialModal.slide1.text': 'Checa tu Piper Score en el Dashboard y observa cómo va tu negocio en redes sociales',
  'tutorialModal.slide1.masterUser.text': 'Agrega tantos negocios o productos como quieras, conecta las redes sociales de cada uno.',
  'tutorialModal.slide1.collabUser.text': 'En el Business Plan, puedes administrar los negocios ó productos a los que estás asignado.',
  'tutorialModal.slide2.text': 'Visita el Centro Creativo y elige entre mis recomendaciones para ti o entre los cientos de plantillas listas para usar',
  'tutorialModal.slide2.masterUser.text': 'Agrega miembros a tu equipo para administrar las redes sociales de tus negocios o productos.',
  'tutorialModal.slide2.collabUser.text': 'Visita el Centro Creativo y elige entre mis recomendaciones para ti o entre los cientos de plantillas listas para usar.',
  'tutorialModal.slide3.text': 'Te brindaré recomendaciones de "Los mejores momentos" para publicar o programar tus publicaciones. Así como organizar tu contenido y anuncios.',
  'tutorialModal.slide3.masterUser.text': 'Supervisa las publicaciones programadas y los anuncios publicitarios con el sistema de aprobación.',
  'tutorialModal.slide3.collabUser.text': 'Te brindaré recomendaciones de "Los mejores momentos" para publicar o programar tus publicaciones. Así como organizar tu contenido y anuncios.',
  'tutorialModal.slide4.text': 'Da un vistazo al rendimiento de tus anuncios en redes sociales y administra tu presupuesto de publicidad',
  'tutorialModal.slide4.masterUser.text': 'Comprueba el rendimiento de tus anuncios y administra tu presupuesto publicitario en cada uno de tus negocios o productos.',
  'tutorialModal.slide4.collabUser.text': 'Programa publicaciones o anuncios publicitarios y envíalos a aprobación.',
  'tutorialModal.slide5.text': 'Al conectar tus redes sociales podrás interactuar con tus seguidores desde un solo lugar',
  'tutorialModal.slide5.masterUser.text': 'Usa mis plantillas recomendadas en el Centro creativo. Busca los mejores momentos para publicar, programar y organizar tu contenido en Calendario.',
  'tutorialModal.slide5.collabUser.text': 'Da un vistazo al rendimiento de los anuncios en redes sociales en cada uno de tus negocios asignados.',
  'tutorialModal.slide6.masterUser.text': 'Interactúa con tus seguidores en un solo lugar, conectando las redes sociales de cada uno de tus negocios o productos.',
  'tutorialModal.slide6.collabUser.text': 'Interactúa con los usuarios de las redes sociales de cada negocio ó producto en un solo lugar.',
  'tutorialModal.piperTip': '¡Hola! Soy Piper',
  'tutorialModal.createapp.slide1.title': '¡Bienvenido!',
  'tutorialModal.createapp.slide1.text': 'Utiliza el Piper Score en el Dashboard y observa como va tu negocio en redes sociales.',
  'tutorialModal.createapp.slide2.text': 'Visita el Centro Creativo y elige entre mis recomendaciones para ti o entre los cientos de plantillas listas para usar.',
  'tutorialModal.createapp.slide3.text': 'Te brindaré recomendaciones de "Los mejores momentos" para publicar o programar tus publicaciones. Así como organizar tu contenido y anuncios.',
  'tutorialModal.createapp.slide4.text': 'Crea anuncios fácilmente, revisa su rendimiento en redes sociales y administra tu presupuesto de publidad.',
  'tutorialModal.createapp.slide5.text': 'Al conectar tus redes sociales podrás interactuar con tus seguidores desde un solo lugar.',
};

export default es;
