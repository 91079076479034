import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { LabelInput } from "../../../../components/globals/FormController";
import CaptionTextArea from "../../../postFlowV2/components/CaptionTextArea";
import GlobalTheme from "../../../../styled/GlobalTheme";

export default function CommunityInputFaq({
  label,
  required,
  addMore,
  addMoreLimit,
  getter,
  setter,
  placeholderQ,
  placeholderA,
}) {
  const addInput = () => {
    const newData = [...getter];
    newData.push({
      question: "",
      answer: "",
    });
    setter(newData);
  };

  const removeInput = (i) => {
    setter((value) => value.filter((_, j) => j !== i));
  };

  const printInputs = () =>
    getter.map((x, i) => (
      <InputBox length={getter.length}>
        <Row>
          <Column>
            <Question>
              <FormattedMessage id="k.question" defaultMessage="Question" />
            </Question>
            <TextAreaContainer>
              <CaptionTextArea
                placeholder={placeholderQ}
                value={x.question}
                onChange={(txt) => {
                  setter((value) =>
                    value.map((y, j) => {
                      if (j === i) {
                        return {
                          ...y,
                          question: txt,
                        };
                      }
                      return y;
                    })
                  );
                }}
                maxLength={100}
              />
            </TextAreaContainer>
          </Column>
          <Column>
            <Answer>
              <FormattedMessage id="k.answer" defaultMessage="Answer" />
            </Answer>
            <TextAreaContainer>
              <CaptionTextArea
                placeholder={placeholderA}
                value={x.answer}
                onChange={(txt) => {
                  setter((value) =>
                    value.map((y, j) => {
                      if (j === i) {
                        return {
                          ...y,
                          answer: txt,
                        };
                      }
                      return y;
                    })
                  );
                }}
                maxLength={100}
              />
            </TextAreaContainer>
          </Column>
        </Row>
        {addMore && getter.length > 1 && (
          <RemoveButton onClick={() => removeInput(i)}>-</RemoveButton>
        )}
      </InputBox>
    ));

  return (
    <Field>
      <Labels>
        {label}
        {required && <Required>&nbsp;*</Required>}
      </Labels>
      <Inputs>{printInputs()}</Inputs>
      {addMore && (addMoreLimit > getter.length || addMoreLimit === -1) && (
        <AddMoreButton onClick={() => addInput()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.29167 6.62126V0.57959H6.04167V6.62126H0V7.87126H6.04167V13.9129H7.29167V7.87126H13.3333V6.62126H7.29167Z"
              fill="#027686"
            />
          </svg>
        </AddMoreButton>
      )}
    </Field>
  );
}

CommunityInputFaq.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  addMore: PropTypes.bool,
  addMoreLimit: PropTypes.bool,
  getter: PropTypes.arrayOf(PropTypes.any),
  setter: PropTypes.func,
};

CommunityInputFaq.defaultProps = {
  label: "",
  required: false,
  addMore: false,
  addMoreLimit: -1,
  getter: [],
  setter: null,
};

const Question = styled.p`
  font-size: 14px;
  font-family: "Open Sans";
  color: #535353;
  margin-bottom: 4px;
  font-weight: 600;
`;

const Answer = styled.p`
  font-size: 14px;
  font-family: "Open Sans";
  color: #535353;
  margin-bottom: 4px;
  font-weight: 600;
`;

const Labels = styled(LabelInput)`
  font-size: 16px;
  margin-bottom: -2px;
`;

const Field = styled.div``;

const Required = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputBox = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  ${({ length }) =>
    length > 1
      ? `
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 95% 5%;
    grid-template-rows: 1fr;
    align-items: end;
  `
      : `
    display: flex;
    flex-direction: row;
  `};

  margin-top: 6px;
`;

const AddMoreButton = styled(Button)`
  margin-top: 5px !important;
  border: 2px dashed #027686 !important;
  min-width: 0px !important;
  border-radius: 4px !important;
  height: 39px;
  width: 40px;
`;

const RemoveButton = styled(Button)`
  margin-top: 5px !important;
  border: 2px dashed red !important;
  color: red !important;
  min-width: 0px !important;
  border-radius: 4px !important;
  width: 33px;
  height: calc(100% - 37px);
  margin-top: 0px !important;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 26px;

  ${GlobalTheme.breakpoints.down("sm")} {
    gap: 0;
    flex-direction: column;
  }
`;

const Column = styled.div`
  width: calc(100% - 13px);
`;

const TextAreaContainer = styled.div`
  border: 1px solid #909090;
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  background-color: #fff;
`;
