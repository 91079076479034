import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { heading3 } from '../../styled/mixins';
import FacebookProfile from './facebook/FacebookProfile';
import InstagramProfile from './InstagramProfile';
import TwitterProfile from './TwitterProfile';
import LinkedInProfile from './LinkedInProfile';
import TiktokProfile from './TiktokProfile';

const SocialProfilesContainer = styled.div``;

const Title = styled.h3`
  ${heading3}
`;

const SocialProfiles = ({
  overrideTitle,
  className,
  socialNetworks,
  companyName,
  setShowReConnectFacebookModal,
}) => (
  <SocialProfilesContainer
    className={className}
  >
    <Title>
      {overrideTitle === '' ? (
        <FormattedMessage
          id="socialProfiles.title"
          defaultMessage="Manage your Social Profiles"
        />
      ) : (overrideTitle)}

    </Title>
    <FacebookProfile
      facebookData={socialNetworks.facebook}
      companyName={companyName}
    />
    <InstagramProfile
      instagramData={socialNetworks.instagram}
      facebookData={socialNetworks.facebook}
      companyName={companyName}
      setShowReConnectFacebookModal={setShowReConnectFacebookModal}
    />
    <TiktokProfile
      tiktokData={socialNetworks.tiktok}
      companyName={companyName}
    />
    <TwitterProfile
      twitterData={socialNetworks.twitter}
      companyName={companyName}
    />
    <LinkedInProfile
      linkedinData={socialNetworks.linkedin}
      companyName={companyName}
    />
  </SocialProfilesContainer>
);

SocialProfiles.propTypes = {
  className: PropTypes.string,
  socialNetworks: PropTypes.instanceOf(Object).isRequired,
  companyName: PropTypes.string.isRequired,
  setShowReConnectFacebookModal: PropTypes.func,
  overrideTitle: PropTypes.string,
};

SocialProfiles.defaultProps = {
  className: '',
  setShowReConnectFacebookModal: () => { },
  overrideTitle: '',
};

export default SocialProfiles;
