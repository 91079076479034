import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import AddPaymentModalContent from './AddPaymentModalContent';

const AddPaymentModal = ({
  open,
  handleClose,
  setPaymentMethods,
}) => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
    <Elements
      fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:wght@400' }]}
    >
      <AddPaymentModalContent
        open={open}
        handleClose={handleClose}
        setPaymentMethods={setPaymentMethods}
        dataLayer={{
          pagePath: '/settings/billing/add-payment-method',
          pathTitle: 'Settings - Billing-Plan - Add-Payment-Method',
        }}
      />
    </Elements>
  </StripeProvider>
);

AddPaymentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setPaymentMethods: PropTypes.func.isRequired,
};

export default AddPaymentModal;
