/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { piper } from '../../assets/illustrations/illustrationRepository';
import Piper from './Piper';

const {
  Piper1,
  Piper1NoLine,
  Piper1NoLineNormal,
  Piper2,
  Piper2Background,
  Piper3,
  Calendar,
  Camera,
  Celebrate,
  CelebrateConfetti,
  Head,
  NudgingPaw1,
  NudgingPaw2,
  NudgingPaw2Background,
  NudgingPaw3,
  PiperScheduler,
  RocketGIF,
  PiperCalendarGIF,
  PiperTypingGIF,
  PiperCelebrateGIF,
  Rocket,
  Settings,
  CameraBackground,
  MobileCameraBackground,
  Paw2Background,
  HeadBackground,
  Fail1,
  Fail2,
  PiperMessagesBackground,
  PiperMessagesBackgroundMobile,
  PiperMessagesNoBackground,
  Piper2BigBackground,
  PiperRocket,
  PiperRocketBackground,
  PiperRocketBackgroundTablet,
  PiperPublishSuccess,
  PiperPublishFail,
  Piper1BackgroundSecondary,
  PiperHeadLoginApp,
  PiperProfessor,
  PiperProfessorLeft,
  PiperSad,
  PiperSadNormal,
  PiperLogoRoundedWhite,
  PiperLogo,
  PiperMicrophone,
} = piper;

const Pipers = {
  Piper1: (props) => <Piper image={Piper1} title="Piper" {...props} />,
  Piper1NoLine: (props) => <Piper image={Piper1NoLine} title="Piper" {...props} />,
  Piper1NoLineNormal: (props) => <Piper image={Piper1NoLineNormal} title="Piper" {...props} />,
  Piper1BackgroundSecondary: (props) => <Piper image={Piper1BackgroundSecondary} title="Piper" {...props} />,
  Piper2: (props) => <Piper image={Piper2} title="Piper" {...props} />,
  Piper2Background: (props) => <Piper image={Piper2Background} title="Piper" {...props} />,
  Piper2BigBackground: (props) => <Piper image={Piper2BigBackground} title="Piper" {...props} />,
  Piper3: (props) => <Piper image={Piper3} title="Piper" {...props} />,
  Calendar: (props) => <Piper image={Calendar} title="Piper Calendar" {...props} />,
  Camera: (props) => <Piper image={Camera} title="Piper Camera" {...props} />,
  Celebrate: (props) => <Piper image={Celebrate} title="Piper Celebrate" {...props} />,
  CelebrateConfetti: (props) => <Piper image={CelebrateConfetti} title="Piper Celebrate" {...props} />,
  Head: (props) => <Piper image={Head} title="Piper Head" {...props} />,
  NudgingPaw1: (props) => <Piper image={NudgingPaw1} title="Piper NudgingPaw1" {...props} />,
  NudgingPaw2: (props) => <Piper image={NudgingPaw2} title="Piper NudgingPaw2" {...props} />,
  NudgingPaw2Background: (props) => <Piper image={NudgingPaw2Background} title="Piper NudgingPaw2" {...props} />,
  NudgingPaw3: (props) => <Piper image={NudgingPaw3} title="Piper NudgingPaw3" {...props} />,
  PiperScheduler: (props) => <Piper image={PiperScheduler} title="Piper Scheduler" {...props} />,
  RocketGIF: (props) => <Piper image={RocketGIF} title="Piper Rocket" {...props} />,
  PiperCalendarGIF: (props) => <Piper image={PiperCalendarGIF} title="Piper Calendar GIF" {...props} />,
  PiperTypingGIF: (props) => <Piper image={PiperTypingGIF} title="Piper Typing GIF" {...props} />,
  PiperCelebrateGIF: (props) => <Piper image={PiperCelebrateGIF} title="Piper Calendar GIF" {...props} />,
  Rocket: (props) => <Piper image={Rocket} title="Piper Rocket" {...props} />,
  Settings: (props) => <Piper image={Settings} title="Piper Rocket" {...props} />,
  CameraBackground: (props) => <Piper image={CameraBackground} title="Camera Background" {...props} />,
  MobileCameraBackground: (props) => <Piper image={MobileCameraBackground} title="Camera Background" {...props} />,
  Paw2Background: (props) => <Piper image={Paw2Background} title="Piper Paw" {...props} />,
  HeadBackground: (props) => <Piper image={HeadBackground} title="Piper" {...props} />,
  Fail1: (props) => <Piper image={Fail1} title="Piper" {...props} />,
  Fail2: (props) => <Piper image={Fail2} title="Piper" {...props} />,
  PiperMessagesBackground: (props) => <Piper image={PiperMessagesBackground} title="Piper" {...props} />,
  PiperMessagesBackgroundMobile: (props) => <Piper image={PiperMessagesBackgroundMobile} title="Piper" {...props} />,
  PiperMessagesNoBackground: (props) => <Piper image={PiperMessagesNoBackground} title="Piper" {...props} />,
  PiperRocket: (props) => <Piper image={PiperRocket} title="Piper" {...props} />,
  PiperRocketBackground: (props) => <Piper image={PiperRocketBackground} title="Piper" {...props} />,
  PiperRocketBackgroundTablet: (props) => <Piper image={PiperRocketBackgroundTablet} title="Piper" {...props} />,
  PiperPublishSuccess: (props) => <Piper image={PiperPublishSuccess} title="Piper" {...props} />,
  PiperPublishFail: (props) => <Piper image={PiperPublishFail} title="Piper" {...props} />,
  PiperHeadLoginApp: (props) => <Piper image={PiperHeadLoginApp} title="Piper" {...props} />,
  PiperProfessor: (props) => <Piper image={PiperProfessor} title="Piper" {...props} />,
  PiperProfessorLeft: (props) => <Piper image={PiperProfessorLeft} title="Piper" {...props} />,
  PiperSad: (props) => <Piper image={PiperSad} title="Piper" {...props} />,
  PiperSadNormal: (props) => <Piper image={PiperSadNormal} title="Piper" {...props} />,
  PiperLogoRoundedWhite: (props) => <Piper image={PiperLogoRoundedWhite} title="Piper" {...props} />,
  PiperLogo: (props) => <Piper image={PiperLogo} title="Piper" {...props} />,
  PiperMicrophone: (props) => <Piper image={PiperMicrophone} title="Piper" {...props} />,
};

export default Pipers;
