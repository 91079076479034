import React, { useContext, useState } from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PlansSelector from '../../../../views/CreateAccount/components/PlansSelector';
import PromoCodeContainer from '../../../becomePro/PromoCodeContainer';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import roles from '../../../../utils/constants/roles';
import { PLAN_STATUS } from '../../../../utils/constants/settings';
import GlobalTheme from '../../../../styled/GlobalTheme';
import { colors } from '../../../../styled/theme';
import { lowSpreadShadow } from '../../../../styled/mixins';
import { Button } from '../../../buttons';
import { PAYMENT_DETAILS_STAGE } from '../constants';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 20px 0;
`;
const Selectors = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
`;

const RecurrenceSelector = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.grayE9};
  margin-right: 1px;
  padding: 2px;
  width: 140px;
  flex: 0 0 140px;
  color: ${colors.gray90};
  font-size: 16px;
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 12px;
    width: 80px;
    flex: 0 0 80px;
  }
  ${({ isActive }) => isActive && css`
    color: white;
    border-color: ${colors.green};
    background: ${colors.green};
    ${lowSpreadShadow}
  `}
  span {
    font-size: 10px;
    ${GlobalTheme.breakpoints.down('sm')} {
      font-size: 9px;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({isFullMngt})=>(isFullMngt? 'end' : 'space-betwenn' )};
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-flow: column;
    justify-content: center;
    margin : 0 0 1em;
  }
`;

const CustomButton = styled(Button)`
  && {
    ${GlobalTheme.breakpoints.down('sm')} {
      margin-top: 5em;
    }
  }
`;

const SelectPlan = ({
  setActiveStage,
  isMonthly,
  setIsMonthly,
  continueAction,
  currentUser,
  promoCode, 
  handlePromoCodeChange, 
  promoLock,
  userPlanPastDue
}) => {
  const [planSelected, setPlanSelected] = useState(roles.PRO)
  const { subscriptionInfo } = useContext(SubscriptionContext);

  return (
    <Container>
      <Selectors>
        <RecurrenceSelector
          isActive={isMonthly}
          onClick={() => setIsMonthly(true)}
        >
          <FormattedMessage
            id="k.monthly"
            defaultMessage="Monthly"
          />
        </RecurrenceSelector>
        <RecurrenceSelector
          isActive={!isMonthly}
          onClick={() => setIsMonthly(false)}
        >
          <FormattedMessage
            id="k.yearly"
            defaultMessage="Yearly"
          />
          <span>
            <FormattedMessage
              id="onboarding.createAccount.selectPlan.save"
              defaultMessage="Save 2 months"
            />
          </span>
        </RecurrenceSelector>
      </Selectors>
      <PlansSelector
        isMonthly={isMonthly}
        planSelected={planSelected}
        setPlanSelected={setPlanSelected}
        isModal={true}
        setForcedPromo={handlePromoCodeChange}
        isOptIn={(subscriptionInfo.planStatus === PLAN_STATUS.TRIAL_OPTIN)}
        currentUser={currentUser}
        promoCode={promoCode}
        userPlanPastDue={userPlanPastDue}
      />
      <ButtonContainer
        isFullMngt={(planSelected === roles.BUSINESS_MAIN)}
      >
        {(planSelected !== roles.BUSINESS_MAIN) && (
          <PromoCodeContainer
            promoCode={promoCode}
            isLoading={promoLock}
            trialEnabled
            onChangeHandler={handlePromoCodeChange}
          />
        )}
        <CustomButton onClick={() => {
          continueAction();
          setActiveStage(PAYMENT_DETAILS_STAGE);
        }}
        >
          <FormattedMessage
            id="k.continue"
            defaultMessage="Continue"
          />
        </CustomButton>
      </ButtonContainer>
    </Container>
  );
};

SelectPlan.propTypes = {
  setActiveStage: propTypes.func.isRequired,
  isMonthly: propTypes.bool.isRequired,
  setIsMonthly: propTypes.func.isRequired,
  continueAction: propTypes.func.isRequired,
  currentUser: propTypes.object,
  promoCode: propTypes.object.isRequired, 
  handlePromoCodeChange: propTypes.func.isRequired, 
  promoLock: propTypes.bool.isRequired,
  userPlanPastDue: propTypes.bool.isRequired
};

export default SelectPlan;
