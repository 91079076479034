import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import { flexBox, label } from '../../../styled/mixins';
import AdAccountsSelectorHeader from './AdAccountsSelectorHeader';
import { colors } from '../../../styled/theme';
import MissingPaymentWarning from './MissinPaymentWarning';
import { ButtonLink } from '../../buttons';
import mediaQueries from '../../../utils/mediaQueries';
import { FACEBOOK } from '../../../utils/constants/globals';

const AdAccountsSelectorContainer = styled.div`
  ${flexBox}
  flex-direction: column;
  justify-content: flex-start;
`;

const AdAccountsRadioGroup = styled.div`
  ${flexBox}
  flex-direction: column;
  justify-content: flex-start;
  max-width: 550px;
`;

const AdAccountRadio = styled.div`
  ${flexBox}
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 16px;
  padding-left: 24px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid ${(props) => (props.disabled ? colors.gray90 : colors.primaryLighter)};
    box-sizing: border-box;
  }
  &:after {
    content: '';
    position: absolute;
    display: ${(props) => (props.selected ? 'block' : 'none')};
    width: 8px;
    height: 8px;
    top: 8px;
    left: 4px;
    border-radius: 100%;
    background: ${colors.primaryLighter};
  }
`;

const AdAccountLabel = styled.p`
  ${label}
  color: ${(props) => (props.disabled ? colors.gray90 : colors.text02)};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  width: 100%;
`;

const AdAccountsSelector = ({
  activeAdAccount,
  adAccounts,
  selectedAdAccount,
  setSelectedAdAccount,
  handleHeaderClick,
  renderAdAccountLabel,
  fetchAdAccounts,
  loading,
  isModal,
  className,
  socialNetwork,
  setOpenStepsPaymentModal,
}) => {
  const [options, setOptions] = useState(adAccounts);

  useEffect(() => {
    if (socialNetwork === FACEBOOK) {
      setOptions(
        activeAdAccount
          ? adAccounts.filter((a) => a.accountId !== activeAdAccount.accountId)
          : adAccounts,
      );
    } else {
      setOptions(
        activeAdAccount
          ? adAccounts.filter((a) => a.linkedinId !== activeAdAccount.linkedinId)
          : adAccounts,
      );
    }
  }, [activeAdAccount, adAccounts]);

  const handleSelected = (adAccount) => {
    if (!adAccount.fundingSourceId) return;
    setSelectedAdAccount(adAccount);
  };

  const getKey = (adAccount) => {
    if (socialNetwork === FACEBOOK) {
      return adAccount.accountId;
    }
    return adAccount.linkedinId;
  };

  const getAdAccountSelected = (adAccount) => {
    if (socialNetwork === FACEBOOK) {
      return (
        selectedAdAccount && selectedAdAccount.accountId === adAccount.accountId
      );
    }
    return (
      selectedAdAccount && selectedAdAccount.linkedinId === adAccount.linkedinId
    );
  };

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <AdAccountsSelectorContainer
          className={className}
        >
          {!isModal && (
            <AdAccountsSelectorHeader
              hasSelectedAdAccount={!!selectedAdAccount}
              hasActiveAdAccount={!!activeAdAccount}
              handleClick={handleHeaderClick}
              loading={loading}
              socialNetwork={socialNetwork}
            />
          )}
          <AdAccountsRadioGroup>
            {options.map((adAccount) => (
              <AdAccountRadio
                key={`AdAccount_${getKey(adAccount)}`}
                onClick={() => handleSelected(adAccount)}
                disabled={!adAccount.fundingSourceId}
                selected={getAdAccountSelected(adAccount)}
              >
                <AdAccountLabel
                  disabled={!adAccount.fundingSourceId}
                >
                  {renderAdAccountLabel(adAccount)}
                </AdAccountLabel>
                {
                  !adAccount.fundingSourceId
                  && (
                    <MissingPaymentWarning
                      accountId={socialNetwork === FACEBOOK
                        ? adAccount.accountId
                        : adAccount.linkedinId}
                      marginTop="8px"
                      fetchAdAccounts={fetchAdAccounts}
                      socialNetwork={socialNetwork}
                      setOpenStepsPaymentModal={setOpenStepsPaymentModal}
                    />
                  )
                }
              </AdAccountRadio>
            ))}
          </AdAccountsRadioGroup>
          {matches.sm && (
            <ButtonContainer>
              <ButtonLink
                disabled={!(selectedAdAccount)}
                onClick={handleHeaderClick}
                isLoading={loading}
              >
                {activeAdAccount ? (
                  <FormattedMessage
                    id="k.change"
                    defaultMessage="Change"
                  />
                ) : (
                  <FormattedMessage
                    id="k.confirm"
                    defaultMessage="Confirm"
                  />
                )}
              </ButtonLink>
            </ButtonContainer>
          )}
        </AdAccountsSelectorContainer>
      )}
    </Media>
  );
};

AdAccountsSelector.propTypes = {
  activeAdAccount: PropTypes.instanceOf(Object).isRequired,
  adAccounts: PropTypes.instanceOf(Array).isRequired,
  selectedAdAccount: PropTypes.instanceOf(Object).isRequired,
  setSelectedAdAccount: PropTypes.func.isRequired,
  handleHeaderClick: PropTypes.func.isRequired,
  renderAdAccountLabel: PropTypes.func.isRequired,
  fetchAdAccounts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

AdAccountsSelector.defaultProps = {
  className: '',
};

export default AdAccountsSelector;
