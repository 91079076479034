/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { CheckIcon } from '../../../assets/icons/iconRepository';

const Container = styled.ul`
  font-size: 16px;
  ${(props) => (props.isValid ? `list-style-image: url(${CheckIcon});` : '')}
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

const ValidationText = styled.li`
  margin: 0;
  color: ${({ theme, isValid }) => (isValid ? theme.colors.green : theme.colors.red)};
`;

const ValidationCategory = ({ validation }) => (
  <Container key={validation.id} isSubcategory={validation.isSubcategory} isValid={validation.isValid}>
    <ValidationText isValid={validation.isValid}>
      <FormattedMessage
        id={validation.intlId}
        description={validation.description}
        defaultMessage={validation.defaultMessage}
      />
    </ValidationText>
  </Container>
);

ValidationCategory.propTypes = {
  validation: PropTypes.instanceOf(Object).isRequired,
};

export default ValidationCategory;
