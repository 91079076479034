const es = {
  'adAccounts.modal.title': 'Selecciona tu cuenta publicitaria',
  'boost.monthlyBudget.info': 'Esta es la cantidad de dinero que estas dispuesto a invertir en publicidad para redes sociales.',
  'boost.monthlyBudget.inputLabel': 'Selecciona tu presupuesto mensual',
  'boostDetails.active.description': 'Agregaste una campaña a tu publicación.',
  'boostDetails.active.impressions.title': 'Impresiones estimadas de tu publicación y tu campaña',
  'boostDetails.active.title': 'Detalles de la campaña',
  'boostDetails.boostIt': '¡Crear una campaña!',
  'boostDetails.charges.chargedByFacebook': 'Facebook cobraría en tu cuenta publicitaria',
  'boostDetails.charges.chargedByLinkedin': 'LinkedIn cobraría en tu cuenta publicitaria',
  'boostDetails.charges.note': 'Basado en {boostPerDay} por día. Si tu pausas, finalizas o extienedes tu campaña, las cantidades de cobro pueden variar',
  'boostDetails.charges.socialPiperFee': 'Comisión de servicio de Social Piper ({fee}%)',
  'boostDetails.charges.socialPiperFee2': '0% de comisión por tiempo limitado',
  'boostDetails.charges.title': 'Cargos estimados',
  'boostDetails.description': 'Las campañas pontencían tu publicación.',
  'boostDetails.impressions.noBoost': 'Público potencial sin publicidad',
  'boostDetails.impressions.sliderLabel': 'Si tu inviertes',
  'boostDetails.impressions.title': 'Así es como una campaña afecta las cantidad de impresiones',
  'boostDetails.impressions.withBoost': 'Público potencial con publicidad',
  'boostDetails.piperTip': '<r>{recommended}</r> es el monto óptimo a invertir para obtener nuevas impresiones relevantes de tus clientes.{lineBreak}O puedes personalizarlo de acuerdo a tus necesidades.',
  'boostDetails.piperTip.noAdAccount': 'Conecta tu página de negocio de Facebook y te daremos recomendaciones sobre cuánto invertir en tu campaña',
  'boostDetails.potentialBar.boosted': 'Estimado con campaña',
  'boostDetails.potentialBar.now': 'Tu alcance estimado es',
  'boostDetails.setupBoost': 'CONFIGURA TU CAMPAÑA',
  'boostDetails.title': 'Crea una campaña (opcional)',
  'boostDetails.warnings.facebookAdAccount.missingAdAccount': 'Por favor selecciona la cuenta publicitaria de facebook que deseas usar',
  'boostDetails.warnings.facebookAdAccount.missingPayment': 'Por favor agrega un método de pago a tu suscripción de Social Piper',
  'boostDetails.warnings.facebookAdAccount.missingPaymentMethod': 'Por favor agrega un método de pago a tu cuenta publicitaria en Facebook: <s>{adAccount}</s>',
  'boostingGoals.tips.all': 'Mostrará contenido de todas tus estrategias.',
  'boostingGoals.tips.awareness': 'Tu contenido se mostrará a la mayor cantidad de clientes potenciales de tu negocio.',
  'boostingGoals.tips.conversion': 'No sólo llegarás a más personas, sino que las alentarás a que les guste tu contenido, lo comenten y lo compartan.',
  'boostingGoals.tips.directions': '¿Tienes una tienda física? Llegarás a clientes potenciales que estén cerca de tu tienda y se les indicará cómo llegar allí.',
  'boostingGoals.tips.message': '¿Quieres hablar directamente con tus clientes potenciales? Se agregará el botón "Enviar mensaje" a tu publicación, para que puedan enviarte un mensaje tan pronto como vean tu publicación.',
  'boostingGoals.tips.messenger': '¿Quieres llegar a las bandejas de entrada de tu público? Selecciona este objetivo para entregar el contenido, que estás creando, a través de Facebook Messenger.',
  'boostingGoals.tips.mixAndMatch': 'Este objetivo es útil para llegar a más personas y buscar aumentar el reconocimiento de tu negocio. ¡Más personas sabrán de ti!',
  'boostingGoals.tips.pageLikes': 'Anima a más personas a visitar y darle me gusta a tu página de negocios en Facebook.',
  'boostingGoals.tips.url': 'Si tienes un sitio web, este objetivo te ayudará a generar más visitas.',
  'boostingGoals.title.awareness': 'Llega a más personas',
  'boostingGoals.title.conversion': 'Obtén más interacciones',
  'boostingGoals.title.directions': 'Invita a las personas a visitar tu tienda física',
  'boostingGoals.title.message': 'Invita a las personas a enviarte un mensaje directo',
  'boostingGoals.title.messenger': 'Envía tu publicación a través de Messenger',
  'boostingGoals.title.mixAndMatch': 'Ambas estrategias',
  'boostingGoals.title.pageLikes': 'Motiva a la gente para que de Me gusta a tu página en Facebook',
  'boostingGoals.title.url': 'Promueve tu sitio web',
  'boostOptions.cancelBoost': 'Cancelar campaña',
  'boostOptions.custom.estReach': 'Alcance aprox. {minReach} a {maxReach}',
  'boostOptions.custom.invalidBudget': 'Se requiere un mínimo de {minimum}',
  'boostOptions.placement.note': 'Todas las campañas son entregadas a través de tu cuenta publicitaria en Facebook',
  'boostOptions.resetOptions': 'Reiniciar opciones',
  'boostOptions.save': 'Guardar detalles de la campaña',
  'boostOptions.showIn': 'Mostrar',
  'boostOptions.spend.estReach': '{value} Est. reach: {minReach} to {maxReach}',
  'boostOptions.spend.mobile.estReach': '{value}',
  'boostOptions.spend.mobile.recommended': '<b>{value} (recomendado)</b>',
  'boostOptions.spend.recommended': '<span><b>{value} (recomendado)</b> alcance aprox.: {minReach} a {maxReach}</span>',
  'boostOptions.startOn': 'Iniciar el',
  'boostOptions.startedOn': 'Inició el',
  'boostOptions.fromStartDate': 'desde la fecha de inicio',
  'boostOptions.title': 'Opciones de campaña',
  // boosting v2
  'boost.specs.title': 'Tu campaña',
  'boost.specs.tip': 'Esta es mi <y>mejor recomendación</y> para la campaña de tu publicación. Si deseas, puedes ajustarla a tus necesidades.',
  'boost.specs.tip.custom': '¡Súper! Con esta configuración, este es tu alcance potencial. ¡Vamos a crear tu campaña!',
  'boost.specs.details.budget.custom.invalidBudget': 'De acuerdo a la duración de tu campaña,{space}{socialnetwork} requiere un mínimo de presupuesto de {budget}{asterisk} por día; modifica el monto de tu presupuesto o la duración de la campaña',
  'boost.specs.details.budget.updated': 'Se ha ajustado el presupuesto de la campaña para cumplir com el mínimo requerido por {sn} de acuerdo a la duración de la misma.',
  'boost.specs.details.potential.label': 'Alcance potencial con esta campaña',
  'boost.specs.details.potential.tooltip.label': 'Clientes',
  'boost.specs.details.potential.tooltip.description': 'Con el monto a invertir, la audiencia y el tiempo de duración, este es el estimado de personas que podrían ver tu campaña.',
  'boost.specs.details.strategy.tooltip.label': 'Estrategia',
  'boost.specs.details.strategy.tooltip.description': 'Tell us the name by which your customers know your business.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'boost.specs.details.options.showIn': 'Mostrar en',
  'boost.specs.details.showIn.tooltip.label': 'Mostrar en',
  'boost.specs.details.showIn.tooltip.description': 'Son las redes en dónde se mostrará tu campaña.',
  'boost.specs.details.options.starts': 'Iniciar el',
  'boost.specs.details.starts.tooltip.label': 'Inicio',
  'boost.specs.details.starts.tooltip.description': 'Es la fecha estimada en que inicia tu campaña, siempre y cuando sea posterior a la fecha tu publicación; posteriormente podrás editarla en la sección "Publicidad".',
  'boost.specs.details.duration.tooltip.label': 'Duración',
  'boost.specs.details.duration.tooltip.description': 'Es la duración de tu campaña la cual puede ser de 1 o más días; posteriormente podrás editarla en la sección "Publicidad".',
  'boost.specs.validations.facebookAdAccount.missingAdAccount': 'Selecciona la cuenta publicitaria de facebook que deseas usar',
  'boost.specs.validations.facebookAdAccount.missingPayment': 'Agrega un método de pago a tu cuenta publicitaria en Facebook:{linebreak}{s}',
  'boost.specs.validations.facebookAdAccount.validated': 'Tu cuenta publicitaria:{linebreak}{s}',
  'boost.specs.validations.payment.missing': 'Agrega un método de pago a tu cuenta de Social Piper',
  'boost.specs.validations.payment.validated': 'Tienes un método de pago en tu cuenta de Social Piper',
  'boost.specs.validations.note': 'Todas las campañas son entregadas a través de tu cuenta publicitaria en Facebook',
  'boost.specs.becomePro': '¡Desbloquea el potencial de llegar a más personas! Sigue las recomendaciones de Piper y conviertete en un maestro en redes sociales',
  'boost.modal.boostIt': '¡Crear una campaña!',
  'boost.specs.details.budget.exceeded': '¡Esta cantidad excede su presupuesto mensual! Continúa si quieres o <editLink>edita tu presupuesto.</editLink>',
  // boosting v3
  'boost.info.tip': 'Campaña publicitaria recomendada para ti:',
  'boost.info.potencialReach': 'Alcance estimado de clientes:',
  'boost.info.investment': 'Inversión',
  'boost.info.recomm.split': 'Detalles de tu campaña',
  'boostDetails.charges.sn.note': 'Si tu pausas, finalizas o extiendes tu campaña, las cantidades de cobro pueden variar.',
  'boostDetails.help.continue.message': 'Para continuar, necesitas crear tu Cuenta Publicitaria en FB y Li',
  'boostDetails.settings': 'Personaliza tu campaña para:',
  'boostDetails.collaborator': 'Ten en cuenta que la campaña primero pasará por un período de autorización antes de ser publicada.',
  'boost.form.reach.title': 'Alcance estimado',
  'boost.selector.help.defineAud.label': 'No esta configurada tu audiencia',
  'boost.selector.help.defineAud.description': 'Debes definir una audiencia para poder crear campañas',
  'boost.selector.help.adAccount.label': 'Cuenta Publicitaria',
  'boost.selector.help.adAccount.description': ' Con tu Cuenta Publicitaria podrás promocionar tus publicaciones seleccionando tus clientes potenciales y forma de pago en el administrador comercial de {value}.',
  'boost.selector.help.paymentMethod.label': 'Método de pago',
  'boost.selector.help.paymentMethod.description': 'Este método de pago es específico para tu Cuenta Publicitaria de {value}. Recibirás el cargo de {value} por este medio y no se relaciona con tu comisión o mensualidad en Social Piper.',
  'boost.info.button.message.createAdAccount': 'Para continuar, necesitas crear tu Cuenta Publicitaria en {sn}.',
  'boost.info.button.message.paymentMethod': 'Para continuar, necesitas añadir un método de pago en {sn}.',
  'boost.info.button.message.paymentMethodSP': 'Para poder continuar, necesitas <sn>agregar un metodo de pago a tu cuenta de Social Piper.</sn>',
  'boost.info.button.message.chooseAdAccount': 'Para continuar, necesitas escoger una Cuenta Publicitaria en {sn}.',
  'boost.info.button.message.chooseSn': 'Para continuar, selecciona una campaña publicitaria',
  'boostDetails.charges.rate.tootip': '*Tipo de cambio estimado para hoy.',
  'boostOptions.tooltip.sn.account.exchange': 'Tu cuenta de anuncios en {sn} ({adAccountName}) utiliza una moneda distinta a la que tienes configurada en Social Piper.{lineBreak}El cobro de comisión se hará al ejecutar la campaña, en la moneda que tengas configurada en Social Piper y al tipo de cambio de ese día.',
  'boostOptions.tooltip.both.sn.account.exchange': 'Tus cuentas de anuncios en Facebook y LinkedIn ({adAccountName}, {adAccountNameTwo}) utilizan una moneda distinta a la que tienes configurada en Social Piper.{lineBreak}El cobro de comisión se hará al ejecutar la campaña, en la moneda que tengas configurada en Social Piper y al tipo de cambio de ese día.',
  'boost.cancel.boosting.link': 'No, gracias. Cancelar Campaña.',
  'boost.user.display.currency': 'La moneda que se muestra es en {currency}',
  'boost.user.display.currency.tooltip': 'Puedes cambiar la moneda en Mi Cuenta en Configuración, para una mejor administración en Social Piper. {lineBreak}{lineBreak}Esto no afectará la moneda de tus cuentas publicitarias.',
  'boost.linkedin.selector.tooltip.budget.help': 'La recomendación de inversión en campañas en LinkedIn parece más elevada a comparación de Facebook, debido a que el publico en LinkedIn es más especializado. Es decir, tus clientes en LinkedIn son más específicos en cuanto a intereses y temas que siguen.',
  'boost.info.modal.facebook.policy.title': 'Antes de continuar',
  'boost.info.modal.facebook.policy.subtitle': '<bold>Meta</bold> (Facebook) requiere que aceptes su política de no discriminación para que puedas comenzar a realizar campañas.{linebreak}<italic>Esto sólo lo tendrás que hacer una sola vez.</italic>',
  'boost.info.modal.facebook.policy.text.link': 'Ir a Meta para aceptar',
  'boost.info.modal.facebook.policy.ready': '¡Listo! Ya he aceptado y deseo continuar con mi campaña.',
};

export default es;
