import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Select as BaseSelect } from "../../../../styled/GlobalComponents";
import { LabelInput } from "../../../../components/globals/FormController";
import GlobalTheme from "../../../../styled/GlobalTheme";

export default function CommunityInputDate({
  label,
  required,
  addMore,
  addMoreLimit,
  getter,
  setter,
  placeholder,
}) {
  const intl = useIntl();

  const addInput = () => {
    const newData = [...getter];
    newData.push({
      dayFrom: "",
      dayTo: "",
      hourFrom: "",
      hourTo: "",
    });
    setter(newData);
  };

  const removeInput = (i) => {
    setter((value) => value.filter((_, j) => j !== i));
  };

  const dayInput = (value, i, name) => {
    const options = [];
    for (let j = 0; j < 7; j += 1) {
      options.push(
        <option value={`k.days.${j}`} key={j}>
          {intl.formatMessage({
            id: `k.days.${j}`,
            defaultMessage: "Day",
          })}
        </option>
      );
    }
    return (
      <Select
        value={value}
        onChange={(e) => {
          e.persist();
          setter((x) =>
            x.map((y, j) => {
              if (j === i) {
                const newValue = { ...y };
                newValue[name] = e.target.value;
                return newValue;
              }
              return y;
            })
          );
        }}
      >
        {options}
      </Select>
    );
  };

  const hourInput = (value, i, name) => {
    const options = [];
    for (let j = 0; j < 24; j += 1) {
      options.push(
        <option value={j} key={j}>
          {j}
        </option>
      );
    }
    return (
      <Select
        value={value}
        onChange={(e) => {
          e.persist();
          setter((x) =>
            x.map((y, j) => {
              if (j === i) {
                const newValue = { ...y };
                newValue[name] = e.target.value;
                return newValue;
              }
              return y;
            })
          );
        }}
      >
        {options}
      </Select>
    );
  };

  const printInputs = () =>
    getter.map((x, i) => (
      <InputBox>
        <Item>
          <DateLabel>{intl.locale === "es" ? "De" : "From"}:</DateLabel>
          {dayInput(x.dayFrom, i, "dayFrom")}
        </Item>
        <Item>
          <DateLabel>{intl.locale === "es" ? "A" : "To"}:</DateLabel>
          {dayInput(x.dayTo, i, "dayTo")}
        </Item>
        <Item>
          <DateLabel>{intl.locale === "es" ? "De" : "From"}:</DateLabel>
          {hourInput(x.hourFrom, i, "hourFrom")}
        </Item>
        <Item>
          <DateLabel>{intl.locale === "es" ? "A" : "To"}:</DateLabel>
          {hourInput(x.hourTo, i, "hourTo")}
        </Item>
        {addMore && getter.length > 1 && (
          <RemoveButton onClick={() => removeInput(i)}>-</RemoveButton>
        )}
      </InputBox>
    ));

  return (
    <Field>
      <Labels>
        {label}
        {required && <Required>&nbsp;*</Required>}
      </Labels>
      <Inputs>{printInputs()}</Inputs>
      {addMore && (addMoreLimit > getter.length || addMoreLimit === -1) && (
        <AddMoreButton onClick={() => addInput()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.29167 6.62126V0.57959H6.04167V6.62126H0V7.87126H6.04167V13.9129H7.29167V7.87126H13.3333V6.62126H7.29167Z"
              fill="#027686"
            />
          </svg>
        </AddMoreButton>
      )}
    </Field>
  );
}

CommunityInputDate.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  addMore: PropTypes.bool,
  addMoreLimit: PropTypes.bool,
  getter: PropTypes.arrayOf(PropTypes.string),
  setter: PropTypes.func,
};

CommunityInputDate.defaultProps = {
  label: "",
  required: false,
  addMore: false,
  addMoreLimit: -1,
  getter: [""],
  setter: null,
};

const Labels = styled(LabelInput)`
  font-size: 16px;
  margin-bottom: 14px;
`;

const Field = styled.div``;

const Required = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  ${GlobalTheme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
`;

const AddMoreButton = styled(Button)`
  margin-top: 5px !important;
  border: 2px dashed #027686 !important;
  min-width: 0px !important;
  border-radius: 4px !important;
  width: 40px;
  height: 39px;
`;

const RemoveButton = styled(Button)`
  margin-top: 5px !important;
  border: 2px dashed red !important;
  color: red !important;
  min-width: 0px !important;
  border-radius: 4px !important;
  width: 40px;
  height: 39px;
  margin-top: 0px !important;
`;

const Select = styled(BaseSelect)`
  width: 160px;
  ${GlobalTheme.breakpoints.down("sm")} {
    width: inherit;
  }
`;

const DateLabel = styled.div`
  display: flex;
  align-items: center;
  ${GlobalTheme.breakpoints.down("sm")} {
    width: 40px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;
