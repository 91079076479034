import gql from 'graphql-tag';

export const getPlans = gql`
  query getPlans($codePlan: String) {
    getPlans(codePlan: $codePlan) {
      plans {
        id
        name
        description
        images
        competitors
        scheduleDays
        chatSupport
        simultaneousPosts
        status
        isDefault
        isMonthly
        realPrice
        yearlyMonthlyPrice
        monthlyPrice
        trialDuration
        requiredCard
        currencyCode
        countryCode
        profileId
        defaultRoleId
      }
      isValid
      promocode
      promoCodeId
      isPromoAttached
    }
  }
`;

export const getSubscription = gql`
  query getSubscriptionInfo{
    getSubscriptionInfo{
        userId
        subcriptionId
        planName
        planStatus
        planStartDate
        planPrice
        isMonthly
        isScheduled
        planEndDate
        onTrial
        promoCode
        isPromoActive
        currencyCode
        countryCode
        applyIOS
        applyAndroid
    }
  }
`;

export const getPlansIOS = (attr = `
    plans {
      id
      name
      description
      productIdApp
      images
      competitors
      scheduleDays
      chatSupport
      simultaneousPosts
      status
      isDefault
      isMonthly
      realPrice
      yearlyMonthlyPrice
      monthlyPrice
      trialDuration
      requiredCard
      currencyCode
      countryCode
    }
    promocode
`) => gql`
  query getPlansIOS($currencyCode: String, $countryCode: String) {
    getPlansIOS(currencyCode: $currencyCode, countryCode: $countryCode) {
      ${attr}
    }
  }
`;
export const getPlansAndroid = (attr = `
    plans {
      id
      name
      description
      productIdApp
      images
      competitors
      scheduleDays
      isDefault
      isMonthly
      realPrice
      yearlyMonthlyPrice
      monthlyPrice
      trialDuration
      requiredCard
      currencyCode
      countryCode
    }
    promocode
`) => gql`
  query getPlansAndroid {
    getPlansAndroid {
      ${attr}
    }
  }
`;
