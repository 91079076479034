import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-media";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { CheckIcon, FailIcon, StarIcon } from "../../assets/icons/iconRepository";
import { AnalyticsContext } from "../../contexts/AnalyticsContext";
import PromoCodeContext from "../../contexts/PromoCodeContext";
import { WebViewContext } from "../../contexts/WebViewContext";
import GlobalTheme from "../../styled/GlobalTheme";
import roles from "../../utils/constants/roles";
import routes from "../../utils/constants/routes";
import mediaQueries from "../../utils/mediaQueries";
import sanitizePlans from "../../views/CreateAccount/sanitizePlans";
import { ButtonPrimary } from "../../views/landing/components/Buttons";
import AccordionPricing from "./AccordionPricing";
import {
  getAccordionsValues,
  getExtraFinalValues,
  getTableValues,
} from "./TableItems";

const Row = styled.div`
  padding: ${({ extraPading }) => (!extraPading ? "0" : "0 5px")};
  margin: auto;
  background-color: ${GlobalTheme.colors.white};
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 2fr 2fr;
  justify-items: center;
  align-items: center;
  border-bottom: 1px solid ${GlobalTheme.colors.grayE9};
  height: 60px;
  @media (max-width: 320px) {
    grid-template-columns: 100px 67px 2fr;
  }
  ${GlobalTheme.breakpoints.up("md")} {
    grid-template-columns: 3fr 2fr 3fr;
    column-gap: 30px;
    padding: 0 20px;
  }
  ${GlobalTheme.breakpoints.up("lg")} {
    grid-template-columns: 4fr 2fr 2fr;
    column-gap: 30px;
    padding: ${({ extraPading }) => (!extraPading ? "0" : "0 1em")};
  }
`;

const TitleRow = styled(Row)`
  height: 40px;
  ${GlobalTheme.breakpoints.up("md")} {
    height: 82px;
  }
`;

const ButtonRow = styled(TitleRow)`
  margin: 1em 0;
  border: none;
`;

const TableRowTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  line-height: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.type === "pro-plan" ? GlobalTheme.colors.primaryMoreLighter : props.type === "business-plan" ? GlobalTheme.colors.orange : GlobalTheme.colors.grayMediaLibrary };
  ${GlobalTheme.breakpoints.up("md")} {
    text-align: ${(props) => props.type === "feature-title" ? 'left' : 'center' };
    display: block;
    margin-bottom: 0px;
    font-size: 20px;
  }
  ${GlobalTheme.breakpoints.up("lg")} {
    font-size: 24px;
  }
`;

const TableRowProTitle = styled(TableRowTitle)`
  height: 165%;
  flex-direction: column;
`;

const PopularRectangle = styled.div`
  width: 110px;
  height: 32.13px;
  margin: auto;
  border-radius: 26px;
  align-self: center;
  align-content: center;
  background-color: ${GlobalTheme.colors.secondary};
  color: ${GlobalTheme.colors.grayMediaLibrary};
  flex-direction: row;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-bottom: 20px;
`;

const StarImage = styled.img`
  width: 10px;
  height: 9.48px;
  object-fit: contain;
  align-self: center;
  margin-inline: 5px 5px;
  margin-block: 3px 3px;
`;
const TablePlanTitle = styled.div`
  position: relative;
  font-size: 13px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: ${({ textColor }) => textColor};
  ${({ pricingView }) =>
    pricingView &&
    `
    &:nth-child(3) {
      order: 3;
    }
  `}
  ${GlobalTheme.breakpoints.up("md")} {
    font-size: 20px;
  }
  ${GlobalTheme.breakpoints.up("lg")} {
    font-size: 30px;
  }
`;

const Cell = styled.div`
  font-size: 10px;
  width: 100%;
  text-align: center;
  ${({ isBiz }) => isBiz && `color: ${GlobalTheme.colors.lightBlue};`}
  ${({ isBiz }) => isBiz && "font-weight: 600;"}
  ${({ pricingView }) =>
    pricingView &&
    `
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
  `}
  ${GlobalTheme.breakpoints.up("md")} {
    font-size: 12px;
  }
  ${GlobalTheme.breakpoints.up("lg")} {
    font-size: 16px;
  }
`;

const RowTitle = styled(Cell)`
  text-align: left;
`;

const Check = styled.div`
  display: inline-block;
  width: 24px;
  height: 18px;
  background-image: url(${CheckIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;
const Fail = styled.div`
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: url(${FailIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const RowItem = ({ data, pricingView, exPad, isBiz }) => {
  const { title, isComingSoon } = data;
  let { proValue, bizValue } = data;

  if (typeof proValue === "boolean") proValue = proValue ? <Check /> : <Fail />;
  if (typeof bizValue === "boolean") bizValue = bizValue ? <Check /> : <Fail />;

  return (
    <Row extraPading={exPad}>
      <RowTitle>{title}</RowTitle>

      {/** pro plan 1st */}
      <Cell pricingView={pricingView} isBiz={isBiz}>
        {isComingSoon ? (
          <FormattedMessage id="k.comingSoon" defaultMessage="Coming Soon" />
        ) : (
          bizValue
        )}
      </Cell>

      {/** business plan 2nd */}
      <Cell pricingView={pricingView}>
        {isComingSoon ? (
          <FormattedMessage id="k.comingSoon" defaultMessage="Coming Soon" />
        ) : (
          proValue
        )}
      </Cell>
    </Row>
  );
};

RowItem.propTypes = {
  data: PropTypes.instanceOf(Object),
  pricingView: PropTypes.bool,
  exPad: PropTypes.bool,
  isBiz: PropTypes.bool,
};

RowItem.defaultProps = {
  data: { title: "", isComingSoon: false },
  pricingView: false,
  exPad: false,
  isBiz: false,
};

const CompareTable = ({ show, showButtons, pricingView }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { dataLayerPush } = useContext(AnalyticsContext);
  const { PromoCode } = useContext(PromoCodeContext);
  const [trialDays, setTrialDays] = useState(0);
  const tableValues = getTableValues(intl);
  const accordionValues = getAccordionsValues(intl);
  const extraFinalValues = getExtraFinalValues(intl);
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const onClickHandler = (roleUse) => {
    let isMonthly = true;
    try {
      isMonthly = JSON.parse(window.localStorage.getItem("isMonthly"));
    } catch (err) {
      Sentry.captureException(err);
    }

    dataLayerPush({
      pagePath: `/homepage/compareTable/${roleUse}`,
      pathTitle: "Landing Pricing",
      dataAction: "Choose piper plan",
      event: "action",
      category: isMonthly ? "Monthly" : "Yearly",
      label: "(button)",
    });
    const query = new URLSearchParams(location.search);
    const search = query.get("promocode")
      ? `?promocode=${query.get("promocode")}&plan=${roleUse}`
      : `?plan=${roleUse}`;

    let routeSignUp = routes.CREATE_ACCOUNT;
    if (location.pathname.includes(routes.JOIN_PRICING)) {
      routeSignUp = routes.SIGN_UP;
    }
    window.localStorage.setItem("planSelected", roleUse);
    if (PromoCode.code && PromoCode.code !== "") {
      window.localStorage.setItem("promoCode", PromoCode.code);
    }
    if (isApp || isAppIOS) {
      routeSignUp = routes.CREATE_ACCOUNT_APP;
    }

    history.push({
      pathname: routeSignUp,
      search,
      state: {
        plan: roleUse,
      },
    });
  };

  useEffect(() => {
    const [monthlyPlan] = sanitizePlans(PromoCode);
    setTrialDays(monthlyPlan.trialDuration);
  }, [PromoCode, trialDays]);
  return (
    show && (
      <Media queries={mediaQueries}>
        {(matches) => (
          <>
            {matches.smDown && (
              <>
              <TableRowTitle type="feature-title">
                {intl.formatMessage({
                  id: "comparePlans.compare",
                  defaultMessage: "Compare plans side-by-side",
                })}
              </TableRowTitle>
              <TableRowProTitle type="pro-plan">
                <PopularRectangle>
                  {intl.formatMessage({
                    id: "k.popular",
                    defaultMessage: "Popular"
                  })}
                </PopularRectangle>
                {intl.formatMessage({
                  id: "settings.billing.planInformation.proPlan.title",
                  defaultMessage: "Pro Plan",
                })}
              </TableRowProTitle>
              <TableRowTitle type="business-plan">
                {intl.formatMessage({
                  id: "settings.billing.planInformation.businessPlan.title",
                  defaultMessage: "Business Plan",
                })}
              </TableRowTitle>
              </>
            )}
            <TitleRow>
              <TableRowTitle type="feature-title">
                {!matches.smDown
                  ? intl.formatMessage({
                      id: "comparePlans.compare",
                      defaultMessage: "Compare plans side-by-side",
                    })
                  : ""}
              </TableRowTitle>
              <TableRowProTitle type="pro-plan">
              <PopularRectangle>
              <StarImage src={StarIcon}/>
                {intl.formatMessage({
                  id: "k.popular",
                  defaultMessage: "Popular"
                })}
                <StarImage src={StarIcon}/>
              </PopularRectangle>
              {!matches.smDown 
                ? intl.formatMessage({
                  id: "settings.billing.planInformation.proPlan.title",
                  defaultMessage: "Pro Plan",
                })
                : ""}
              </TableRowProTitle>
              <TableRowTitle type="business-plan">
              {!matches.smDown 
               ? intl.formatMessage({
                  id: "settings.billing.planInformation.businessPlan.title",
                  defaultMessage: "Business Plan",
                })
                : ""}
              </TableRowTitle>
            </TitleRow>
            {tableValues.map((value) => (
              <RowItem data={value} pricingView={pricingView} />
            ))}
            {accordionValues.map(({ accTitle, content }) => (
              <AccordionPricing title={accTitle} hasExpanded>
                {content.map((value) => (
                  <RowItem data={value} pricingView={pricingView} exPad />
                ))}
              </AccordionPricing>
            ))}
            {extraFinalValues.map((value) => (
              <RowItem data={value} pricingView={pricingView} />
            ))}
            {showButtons && (
              <ButtonRow>
                <Cell pricingView={pricingView} />
                <Cell pricingView={pricingView}>
                  {trialDays ? (
                    <ButtonPrimary
                      onClick={() => onClickHandler(roles.PRO)}
                      largeText
                    >
                      {intl.formatMessage({
                        id: "k.cta.freeTrial",
                        defaultMessage: "Start Your Free Trial",
                      })}
                    </ButtonPrimary>
                  ) : null}
                </Cell>
                <ButtonPrimary pricingView={pricingView} largeText  onClick={() => onClickHandler(roles.BUSINESS_MAIN)}>
                  {intl.formatMessage({
                    id: "k.signup.now",
                  })}
                </ButtonPrimary>
              </ButtonRow>
            )}
          </>
        )}
      </Media>
    )
  );
};

CompareTable.propTypes = {
  show: PropTypes.bool,
  showButtons: PropTypes.bool,
  pricingView: PropTypes.bool,
};

CompareTable.defaultProps = {
  show: false,
  showButtons: false,
  pricingView: false,
};

export default CompareTable;
