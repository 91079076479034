import React, { useEffect, useMemo, useState, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import useCompany from '../../hooks/useCompany';
import {
  Avatar,
  ChevronDownTouchable,
  CompanySelectorPopup,
  CompanySeletorContainer,
  InitialAvatar,
  SelectedItem,
  SelectorOption,
} from './index.styled';
import CompaniesProvider from '../../business/services/CompanyProvider';
import styled from 'styled-components';
import { LayoutContext } from '../../contexts/LayoutContext';

const SelectCompany = () => {
  const selectedItemRef = useRef(null);
  const { setCompanyId, companyId, companies, selectedCompany, loading } = useCompany();
  const { setBusinessImage } = useContext(LayoutContext);
  const [isPressed, setIsPressed] = useState(false);
  const [isLoadingId, setIsLoadingId] = useState(null);
  const [selectorPosition, setSelectorPosition] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    const keyListener = (ev) => {
      if (ev.key === 'Escape') {
        setIsPressed(false);
      }
    };

    if (selectedItemRef.current) {
      const rect = selectedItemRef.current.getBoundingClientRect();
      setSelectorPosition(rect);
    }
    // document.addEventListener('click', clickListener);
    document.addEventListener('keyup', keyListener);

    return () => {
      // document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', keyListener);
    };
  }, []);

  const heading = useMemo(() => intl.formatMessage({ id: 'k.business' }), []);

  const handleSelectedItem = () => {
    setIsPressed(!isPressed);
  };

  const handleOption = async (company) => {
    setIsLoadingId(company.id);

    try {
      localStorage.setItem('local:companyId', company.id);
      await CompaniesProvider.setCurrentCompany(company.id);
      setCompanyId(company.id);
      setBusinessImage(company.logoUrl);
      setIsPressed(false);
    } finally {
      setIsLoadingId(null);
    }
  };

  return (
    <>
      <CompanySeletorContainer>
        {!loading && (
          <SelectedItem onClick={handleSelectedItem} ref={selectedItemRef}>
            {!selectedCompany && <></>}
            {selectedCompany && selectedCompany.logoUrl ? (
              <Avatar src={selectedCompany?.logoUrl || ''} width={24} height={24} />
            ) : (
              <InitialAvatar char={selectedCompany?.companyName?.[0]} size={30} />
            )}
            <strong>{selectedCompany?.companyName || ''}</strong>
            <ChevronDownTouchable pressed={isPressed} />
          </SelectedItem>
        )}
        {selectorPosition && (
          <CompanySelectorPopup heading={heading} open={isPressed} onBackdropClick={() => setIsPressed(false)} position={selectorPosition}>
            {companies.map((company, i) => {
              const isLocalLoading = isLoadingId == company.id;

              return (
                <SelectorOption
                  key={i}
                  onClick={() => handleOption(company)}
                  selected={companyId == company.id}
                >
                  {isLocalLoading ? (
                    <CircularLoader />
                  ) : (
                    <>
                      {company.logoUrl && <Avatar src={company.logoUrl} width={24} height={24} />}
                      {!company.logoUrl && <InitialAvatar char={company.companyName[0]} size={24} />}
                    </>
                  )}
                  <p>{company.companyName || ''}</p>
                </SelectorOption>
              );
            })}
          </CompanySelectorPopup>
        )}
      </CompanySeletorContainer>
    </>
  );
};

const CircularLoader = () => {
  return (
    <LdsSpinner>
      <div className='lds-spinner'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LdsSpinner>
  );
};

const LdsSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .lds-spinner {
    /* change color here */
    color: #1c4c5b;
  }
  .lds-spinner,
  .lds-spinner div,
  .lds-spinner div:after {
    box-sizing: border-box;
  }
  .lds-spinner {
    color: currentColor;
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
  }
  .lds-spinner div {
    transform-origin: 12px 12px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 1px;
    left: 11px;
    width: 2px;
    height: 5px;
    border-radius: 20%;
    background: currentColor;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

SelectCompany.propTypes = {};
SelectCompany.defaultProps = {};

export default SelectCompany;
