import React, { useState } from 'react';
import styled from 'styled-components';
import { Backdrop, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import Modal from "../../../../../components/globals/Modal";
import IconPlacer from '../../../../../components/IconPlacer';
import { Button } from '../../../../../components/buttons';
import { text } from '../../../../../components/text';
import UpgradeConfirmation from './UpgradeConfirmation';
import { CloseOutlineIcon, WarningRedIcon } from '../../../../../assets/icons/iconRepository';
import roles from '../../../../../utils/constants/roles';

const DowngradeToProModal = ({ open, setOpen, handleDowngradeToPro, companies, handleTransition }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [confirmedCompanyId, setConfirmedCompanyId] = useState(null);
    const [wasSuccessfullyUpgraded, setWasSuccessfullyUpgraded] = useState(false);
    const disabledCompany = (company) => { return confirmedCompanyId && company.id !== confirmedCompanyId }

    const handleDowngrade = () => {
      handleDowngradeToPro(setIsLoading, confirmedCompanyId, setWasSuccessfullyUpgraded)
    }

    const closeModal = () => {
        setOpen(false);
        setIsConfirmed(false);
        setConfirmedCompanyId(null);
    };

    const disabledDowngradeButton = () => {
        if (!confirmedCompanyId) return false;
        if (!isConfirmed) return false;
        return true;
    }

    return (
      <>
       <ModalContainer
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
       >
        <>
            <IconPlacer
                onClick={closeModal}
                path={CloseOutlineIcon}
                size={25}
                style={{ marginLeft: 'auto', marginRight: 0, cursor: 'pointer' }}
            />
            <IconPlacer
                path={WarningRedIcon}
                size={42}
                style={{ margin: '0 auto', marginBottom: 35 }}
            />
            <AdviceText>
                {text('downgradeToProPlanModal.title', {
                br: <br />,
                b: (...chunks) => <b>{chunks}</b>,
                red: (...chunks) => <RedText>{chunks}</RedText>,
                })}
            </AdviceText>
            <CompaniesContainer>
            {companies.map((company) => (
                <ConfirmationWrapper>
                 <Checkbox
                   color='primary'
                   style={{ padding: 0, width: 20, height: 20 }}
                   checked={company.id === confirmedCompanyId}
                   onChange={() => {
                    if (!confirmedCompanyId) {
                        setConfirmedCompanyId(company.id)
                    }
                    if (confirmedCompanyId === company.id) {
                      setConfirmedCompanyId(null);
                    }
                   }}
                   id='id-company-checkbox'
                   disabled={disabledCompany(company)}
                 />
                 <ConfirmationLabel htmlFor='id-company-checkbox' disabled={disabledCompany(company)}>
                   {company.companyName}
                 </ConfirmationLabel>
               </ConfirmationWrapper>
            ))}
            </CompaniesContainer>
            <AdviceText>
              {text('downgradeToProPlanModal.advice', {
                br: <br />,
                b: (...chunks) => <b>{chunks}</b>
              })}
            </AdviceText>
            <ConfirmationWrapper>
            <Checkbox
              color='primary'
              style={{ padding: 0, width: 20, height: 20 }}
              checked={isConfirmed}
              onChange={() => setIsConfirmed((val) => !val)}
              id='id-confirm-checkbox'
            />
            <ConfirmationLabel htmlFor='id-confirm-checkbox'>
              {text('downgradeToProPlanModal.confirm')}
            </ConfirmationLabel>
          </ConfirmationWrapper>

            <ButtonModalWrapper>
                <ButtonModal disabled={!disabledDowngradeButton()} isLoading={isLoading} onClick={handleDowngrade}>
                    {text('downgradeToProPlanModal.buttons.downgrade')}
                </ButtonModal>
                <ButtonModal onClick={closeModal}>
                    {text('downgradeToProPlanModal.buttons.cancel')}
                </ButtonModal>
            </ButtonModalWrapper>
        </>
       </ModalContainer> 
       <UpgradeConfirmation 
        open={wasSuccessfullyUpgraded}
        setOpen={setWasSuccessfullyUpgraded}
        handleTransition={handleTransition}
        role={roles.PRO}
        />
       </>
    )
};

DowngradeToProModal.propTypes = {
  openModal: PropTypes.bool, 
  setOpenModal: PropTypes.func,
  handleDowngradeToPro: PropTypes.func, 
  companies: PropTypes.array,
  handleTransition: PropTypes.func
};


export default DowngradeToProModal;

const AdviceText = styled.p`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 26px;
`;

const ModalContainer = styled(Modal)`
  [class*='MuiPaper-root'] {
    height: auto;
    width: auto;
  }
`;

const ButtonModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 35px;
  margin-top: 35px;
`;

const ButtonModal = styled(Button)`
  && {
    width: 185px;
    height: 40px;
    position: unset;
    top: 0px;
    right: 0px;
  }
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  column-gap: 6px;
  justify-content: center;
  margin-top: 35px;
`;

const RedText = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-weight: bold;
`;

const ConfirmationLabel = styled.label`
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin-top: -4px;
  color: ${(props) => props.disabled ? "#909090" : "#3E3E3E"};
`;

const CompaniesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 30px;
`