import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { flexBox, label } from '../../../styled/mixins';
import { WarningIcon } from '../../../assets/icons/iconRepository';
import { ButtonLink } from '../../buttons';
import mediaQueries from '../../../utils/mediaQueries';
import { FACEBOOK } from '../../../utils/constants/globals';

const AdAccountsSelectorHeaderContainer = styled.div`
  ${flexBox}
  justify-content: justify-content;
  width: 100%;
`;

const Label = styled.p`
  ${label}
  flex: 1;
  max-width: 550px;
  display: flex;
`;

const WarningImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
  margin-right: 8px;
`;

const AdAccountsSelectorHeader = ({
  hasSelectedAdAccount,
  hasActiveAdAccount,
  handleClick,
  loading,
  socialNetwork,
}) => (
  <Media queries={mediaQueries}>
    {(matches) => (
      <AdAccountsSelectorHeaderContainer>
        <Label>
          {hasActiveAdAccount ? (
            <FormattedMessage
              id="socialProfiles.facebook.adAccounts.change"
              defaultMessage="Change to another Ad Account"
            />
          ) : (
            <>
              <WarningImg src={WarningIcon} alt="warning" />
              {socialNetwork === FACEBOOK ? (
                <FormattedMessage
                  id="socialProfiles.facebook.adAccounts.select"
                  defaultMessage="Please select the Facebook Ad Account that you will use to create your Facebook advertising campaigns from Social Piper"
                />
              ) : (
                <FormattedMessage
                  id="socialProfiles.linkedIn.adAccounts.select"
                  defaultMessage="Plsease select the LinkedIn Ad Account that you will use to create your LinkedIn advertising campaigns from Social Piper"
                />
              )}
            </>
          )}
          :
        </Label>
        {!matches.sm && (
          <ButtonLink
            disabled={!hasSelectedAdAccount}
            onClick={handleClick}
            isLoading={loading}
          >
            {hasActiveAdAccount ? (
              <FormattedMessage
                id="k.change"
                defaultMessage="Change"
              />
            ) : (
              <FormattedMessage
                id="k.confirm"
                defaultMessage="Confirm"
              />
            )}
          </ButtonLink>
        )}
      </AdAccountsSelectorHeaderContainer>
    )}
  </Media>
);

AdAccountsSelectorHeader.propTypes = {
  hasSelectedAdAccount: PropTypes.bool.isRequired,
  hasActiveAdAccount: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  socialNetwork: PropTypes.string.isRequired,
};

export default AdAccountsSelectorHeader;
