import * as Sentry from '@sentry/browser';
import { createSocialMediaAttemptMutation } from '../../graphql/mutations'
import { authAppSync } from '../auth';

export default class SocialMediaAttemptProvider {
  static async create(body) {
    try {
      const data = await authAppSync.client.mutate({
        mutation: createSocialMediaAttemptMutation,
        fetchPolicy: 'no-cache',
        variables: {
          companyId: body.companyId,
          socialMediaType: body.socialMediaType
        },
      });

      return {
        message: '',
        success: true,
        data,
      };
    } catch (error) {
      Sentry.captureException(error);

      return {
        data: null,
        success: false,
        message: error.message,
      };
    }
  }
}