import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Pipers from '../Pipers/Pipers';
import Tip from './Tip';
import GlobalTheme from '../../styled/GlobalTheme';

const PiperTipStyled = styled.div`
  height: fit-content;
  display:grid;
  grid-gap:${(props) => props.gap || '32px'};
  justify-items: ${(props) => (props.justifyCenter ? 'center' : 'end')};
  align-items: end;
  grid-template-areas: "piper tip";
  ${(props) => props.hide && `
      ${GlobalTheme.breakpoints.down(props.hide)}{
        display:none;
      }
  `}
  ${({ position, piperWidth, tipWidth }) => position && `
    ${position === 'right' && `
      grid-gap: 32px;
      grid-template-areas: "piper tip";
      grid-template-columns: ${piperWidth} ${tipWidth};
    `}
    ${position === 'left' && `
      grid-gap:32px;
      grid-template-areas: "tip piper";
      grid-template-columns: ${tipWidth} ${piperWidth};
    `}
    ${position === 'top' && `
      grid-gap:0;
      grid-template-columns: ${tipWidth || '100%'};
      grid-template-areas: "tip" "piper";
      grid-template-rows: 1fr 180px;
    `}
    ${position === 'bottom' && `
      grid-gap:0;
      grid-template-columns:1fr;
      grid-template-areas: "piper" "tip";
      grid-template-rows: 180px 1fr;
    `}
  `}
`;

const CustomTip = styled(Tip)`
  grid-area:tip;
  ${(props) => props.position === 'top' && `
    padding: 0 11px;
    box-sizing: border-box;
    max-width: 100%;
  `}
  ${(props) => props.position === 'left' && `
    padding: 0 11px;
    box-sizing: border-box;
    max-width: 100%;
  `}
  ${GlobalTheme.breakpoints.down('sm')}{
    padding: 0;
  }

  ${(props) => props.isVideo && `
    margin-bottom: 95px;
    margin-left: 20px;
    ${GlobalTheme.breakpoints.down('sm')}{
      margin-bottom: 30px;
      margin-left: 0;
    }
  `}
`;

const PiperWrapper = styled.div`
  margin:0;
  padding:0;
  grid-area:piper;
  max-width:100%;
  height: ${(props) => (props.isEdge ? '100%' : 'fit-content')};
  ${({ position }) => (position === 'top' || position === 'bottom') && `
    justify-self: center;
    align-self: start;
  `}
  ${({ position }) => (position === 'right' || position === 'left') && `
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .piper {
      align-self: flex-end;
    }
  `}
  ${({ position }) => position === 'left' && `
    justify-content: flex-start;
  `}
  ${(props) => props.isVideo && `
    margin-right: 45px;
    ${GlobalTheme.breakpoints.down('sm')}{
      margin-right: 35px;
    }
  `}
`;

const PiperTip = ({
  Piper,
  tip,
  children,
  className,
  hideTip,
  hidePiper,
  position,
  flipPiper,
  piperWidth,
  tipWidth,
  trianglePosition,
  justifyCenter,
  minHeightTip,
  gap,
  backgroundColor,
  isVideo,
}) => {
  const [isEdge, setIsEdge] = useState(false);
  useEffect(() => {
    if (navigator.userAgent.indexOf('Edg') > 1) setIsEdge(true);
  }, []);
  return (
    <PiperTipStyled
      className={`piper-tip ${className}`}
      position={position}
      piperWidth={piperWidth}
      tipWidth={tipWidth}
      justifyCenter={justifyCenter}
      gap={gap}
    >
      <PiperWrapper
        className="piper-wrapper"
        position={position}
        flipPiper={flipPiper}
        isEdge={isEdge}
        isVideo={isVideo}
      >
        <Piper
          className="piper"
          hide={hidePiper}
          flipPiper={flipPiper}
          width={piperWidth} // TODO, review if works || (isVertical ? '100%' : '80px')
        />
      </PiperWrapper>
      <CustomTip
        className="tip"
        hide={hideTip}
        position={position}
        offset={piperWidth}
        trianglePosition={trianglePosition}
        minHeight={minHeightTip}
        backgroundColor={backgroundColor}
        isVideo={isVideo}
      >
        { children || tip}
      </CustomTip>
    </PiperTipStyled>
  );
};

PiperTip.propTypes = {
  Piper: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  tip: PropTypes.string,
  hideTip: PropTypes.string,
  hidePiper: PropTypes.string,
  position: PropTypes.string,
  flipPiper: PropTypes.bool,
  piperWidth: PropTypes.string,
  tipWidth: PropTypes.string,
  trianglePosition: PropTypes.string,
  justifyCenter: PropTypes.bool,
  gap: PropTypes.string,
  minHeightTip: PropTypes.string,
  backgroundColor: PropTypes.string,
  isVideo: PropTypes.bool,
};

PiperTip.defaultProps = {
  Piper: Pipers.Piper1,
  tip: '',
  hideTip: '',
  hidePiper: '',
  className: '',
  position: 'right',
  flipPiper: false,
  piperWidth: '',
  tipWidth: 'auto',
  trianglePosition: 'top',
  justifyCenter: false,
  gap: '',
  minHeightTip: '90px',
  backgroundColor: '',
  isVideo: false,
};

export default PiperTip;
