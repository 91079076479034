import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';
import {
  materialShadows,
  borderTop,
  heading3,
  flexBox,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import PaymentHistoryTable from './PaymentHistoryTable';
import PaymentOverlay from './PaymentOverlay';
import { BillingProvider } from '../../../../../services/entities';

const PaymentHistoryCard = styled.div`
  ${materialShadows};
  ${borderTop('speechBubble')};
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  ${flexBox}
  flex-direction: column;
  justify-content: flex-start;
  ${GlobalTheme.breakpoints.down('md')}{
    padding: 24px 16px;
  }
`;

const Title = styled.h3`
  ${heading3}
  margin-bottom: 16px;
`;

const PaymentHistory = ({
  className,
}) => {
  const intl = useIntl();
  const chargeOptions = [
    {
      value: 'all',
      label: intl.formatMessage({
        id: 'settings.billing.paymentHistory.chargeTypes.all',
        defaultMessage: 'All charge types',
      }),
    },
    {
      value: 'subscription',
      label: intl.formatMessage({
        id: 'settings.billing.paymentHistory.chargeTypes.subscription',
        defaultMessage: 'Plan fee',
      }),
    },
    {
      value: 'boost',
      label: intl.formatMessage({
        id: 'settings.billing.paymentHistory.chargeTypes.boost',
        defaultMessage: 'Boosts fee',
      }),
    },
  ];

  const dateFilterOptions = [
    {
      value: 'currentMonth',
      label: intl.formatMessage({
        id: 'settings.billing.paymentHistory.dateFilter.currentMonth',
        defaultMessage: 'This month',
      }),
    },
  ];

  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const [chargeFilter, setChargeFilter] = useState(chargeOptions[0]);

  const handleChargeChange = (_, value) => {
    setChargeFilter(value);
  };

  const handleDateChange = (_, value) => {
    setDateFilter(value);
  };

  const [payments, setPayments] = useState([]);

  const [paymentOverlay, setPaymentOverlay] = useState(null);

  useEffect(() => {
    const fetchPaymentsHistory = async () => {
      const response = await BillingProvider.fetchPaymentHistory();
      const historyPaymentApple = await BillingProvider.fetchPaymentHistoryApple();
      const historyPaymentAndroid = await BillingProvider.fetchPaymentHistoryAndroid();

      if (response.success || historyPaymentApple.success || historyPaymentAndroid.success) {
        if (response.success && historyPaymentApple.success) {
          const allPayment = response.data.concat(historyPaymentApple.data);
          setPayments(allPayment);
        } else if (response.success && historyPaymentAndroid.success) {
          const allPayment = response.data.concat(historyPaymentAndroid.data);
          setPayments(allPayment);
        } else {
          if (response.success) {
            setPayments(response.data);
          }
          if (historyPaymentApple.success) {
            setPayments(historyPaymentApple.data);
          }
          if (historyPaymentAndroid.success) {
            setPayments(historyPaymentAndroid.data);
          }
        }
      }
    };

    fetchPaymentsHistory();
  }, []);

  return (
    <>
      <PaymentHistoryCard
        className={className}
      >
        <Title>
          <FormattedMessage
            id="settings.billing.paymentHistory.title"
            defaultMessage="Payment History"
          />
        </Title>
        <PaymentHistoryTable
          chargeOptions={chargeOptions}
          chargeFilter={chargeFilter}
          handleChargeChange={handleChargeChange}
          dateFilterOptions={dateFilterOptions}
          dateFilter={dateFilter}
          handleDateChange={handleDateChange}
          payments={payments}
          intl={intl}
          setPaymentOverlay={setPaymentOverlay}
        />
      </PaymentHistoryCard>
      <PaymentOverlay
        payment={paymentOverlay}
        setPayment={setPaymentOverlay}
        intl={intl}
      />
    </>
  );
};

PaymentHistory.propTypes = {
  className: PropTypes.string,
};

PaymentHistory.defaultProps = {
  className: '',
};

export default PaymentHistory;
