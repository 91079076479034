import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import COLOR from '../../styled/colors';
import { input } from '../../styled/mixins';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { colors } from '../../styled/theme';
import { WebViewContext } from '../../contexts/WebViewContext';

export const InputHolder = styled.div`
  width:100%;
  margin-top: 1em;
  font-size: 16px;
  margin-bottom: 1em;
`;

export const LabelInput = styled.div`
  font-weight: 600;
  margin-bottom: .5em;
  display: flex;
  align-items: center;
  ${({ requiredText, isApp }) => requiredText && css`
    &:after {
      content: "*";
      margin-left: 4px;
      font-size: 12px;
      color: ${isApp ? colors.lightPurple : colors.orange};
    }
  `}
  ${({ error }) => error && css`
    color: red;
    &:after {
      color: red;
    }
  `}
  ${({ color }) => color && `
    color: ${color};
  `}
  ${({ labelCenter }) => labelCenter && `
    justify-content: center;
  `}
`;

export const InputStyled = styled.input`
  width:100%;
  ${input}
  color: ${COLOR.black};
  ::placeholder{
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
  };
  :focus{
    border-color:${COLOR.primary};
  }
  ${({ error }) => error && css`
    border-color: red;
  `}

`;

const Warning = styled.div`
  font-size: 14px;
  color: ${COLOR.gray90};
`;

export const Required = styled.div`
  font-family: ${(props) => props.theme.font.font1};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.orange};
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 2px;
`;

const HolderBottom = styled.div`
  font-size: 14px;
  color: #909090;
`;

const FormController = ({
  children,
  onChange,
  helper,
  label,
  value,
  placeholder,
  id,
  type,
  className,
  onFocus,
  onBlur,
  inputRef,
  required,
  hasHidingPlaceholder,
  showRequired,
  showRequiredError,
  labelCenter,
  holderBottom,
  messageHolder,
  colorLabelInput,
  disabled,
}) => {
  const intl = useIntl();
  const [hidingPlaceholder, setHidingPlaceholder] = useState(placeholder);
  const [defaultMessage, setDefaultMessage] = useState(intl.formatMessage({
    id: 'k.required',
    defaultMessage: 'Required',
  }));
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const handleBlur = (e) => {
    setHidingPlaceholder(placeholder);
    onBlur(e);
  };

  const handleFocus = (e) => {
    if (hasHidingPlaceholder) {
      setHidingPlaceholder(' ');
    }
    onFocus(e);
  };
  const onChangeHolder = (evt) => {
    onChange(evt);
  };
  useDidUpdateEffect(() => {
    setHidingPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    if (id === 'mobile') {
      setDefaultMessage(intl.formatMessage({
        id: 'k.invalid.number',
        defaultMessage: 'Invalid number',
      }));
    }
  }, []);
  return (
    <InputHolder
      className={className}
    >
      {label && (
        <LabelInput
          requiredText={(showRequired || showRequiredError) && defaultMessage}
          error={showRequiredError}
          labelCenter={labelCenter}
          color={colorLabelInput}
          isApp={isApp || isAppIOS}
        >
          {label}{required && <Required>*</Required>}
        </LabelInput>
      )}
      {!children ? (
        <InputStyled
          disabled={disabled}
          onChange={(e) => onChangeHolder(e)}
          placeholder={hidingPlaceholder || label}
          value={value/*  || (id === 'mobile') ? value : null */}
          id={id}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          error={showRequiredError}
        />
      ) : children}
      {helper && <Warning>{helper}</Warning>}
      {holderBottom && <HolderBottom>{messageHolder}</HolderBottom>}
    </InputHolder>
  );
};

FormController.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  helper: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  hasHidingPlaceholder: PropTypes.bool,
  showRequired: PropTypes.bool,
  showRequiredError: PropTypes.bool,
  labelCenter: PropTypes.string,
  holderBottom: PropTypes.bool,
  messageHolder: PropTypes.string,
  colorLabelInput: PropTypes.string,
};

FormController.defaultProps = {
  children: null,
  onChange: () => { },
  onFocus: () => { },
  onBlur: () => { },
  helper: null,
  placeholder: ' ',
  label: '',
  value: '',
  id: '',
  type: 'text',
  className: '',
  inputRef: null,
  required: false,
  hasHidingPlaceholder: false,
  showRequired: false,
  showRequiredError: false,
  labelCenter: '',
  holderBottom: false,
  messageHolder: '',
  colorLabelInput: '',
};

export default FormController;
