/* eslint-disable no-template-curly-in-string */
const en = {
  'k.seo.title': '{titlePage}: Schedule and manage your business social media.',
  'k.seo.keywords':
    'social media calendar template, content calendar, social media made easy, social media assistance, trusty social, unlimited social media plans, easy social media, manage all your social media in one place, social media assistance service, all my social media in one place, flooring company social media management, social media companies near me, app that combines all social media, social media marketing near me, social media in mexico, social media boosting site, easy social, social media management companies near me, social media management, assistance with social media, app to manage social media, social media made simple, social media management icon, your social hero, socials app, best times to post instagram stories, social booster pricing, social media managers near me, best social media, social media management pricing, ad center facebook, post to social media simultaneously, app para social media, manage all social media in one place, manage social media, manage social media accounts in one place, managing your social media, best time to post instagram california, best times to post on instagram 2017, best times to post videos on instagram, ig schedule post, add facebook ad account, best ig post times, is it possible to schedule instagram stories, small social media apps, social media management company near me, free social media management, connect your social media accounts',
  'k.seo.description':
    'Create and schedule attractive posts, manage them and boost them! The all-in-one solution for your social media.',
  'k._days': 'days',
  'k._value': '{value}',
  'k.about': 'About Us',
  'k.about.link': 'ABOUT',
  'k.about.social': 'About Social Piper',
  'k.academy': 'Academy',
  'k.account': 'Account',
  'k.adAccount': 'Ad account',
  'k.adCampaign': 'Ad campaign',
  'k.add': 'Add',
  'k.addPaymentMethod': 'Add payment method',
  'k.all': 'All',
  'k.allrights': 'All rights reserved',
  'k.amount': 'Amount',
  'k.aprox': 'Aprox.',
  'k.audience': 'Audience',
  'k.basicPlan': 'Lite Plan',
  'k.upgrade': 'Upgrade!',
  'k.back': 'Back',
  'k.boost': 'Boost',
  'k.boosts': 'Boosts',
  'k.boostBudget': 'My Add Budget',
  'k.boostIt': 'Boost it',
  'k.boosting': 'Boosting',
  'k.boostItAgain': 'Boost it again',
  'k.boostManager': 'Boost Manager',
  'k.businessPlan': 'Business Plan',
  'k.business': 'Business',
  'k.creativityCenter': 'Creative Center',
  'k.cancel': 'Cancel',
  'k.canceled': 'Canceled',
  'k.cancelEdit': 'Cancel Edit',
  'k.caPrivacyRights': 'CA Privacy',
  'k.card': 'Card',
  'k.change': 'Change',
  'k.channel': 'Channel',
  'k.chooseAdAccount': 'Choose Ad Account',
  'k.city': 'City',
  'k.close': 'Close',
  'k.closereturn': 'Close and return to Social Piper',
  'k.comingSoon': 'Coming soon',
  'k.comments': 'Comments',
  'k.community': 'Community',
  'k.approvals': 'Approvals',
  'k.communityManagement': 'Community Management',
  'k.communityManagement.auto': 'Auto-Community Management',
  'k.company': 'Company',
  'k.competitors': 'Competitors',
  'k.myCompetitors': 'My Competitors',
  'k.confirm': 'Confirm',
  'k.confirmBost': 'Confirm Boost',
  'k.connect': 'Connect',
  'k.disconnected': 'Disconnected',
  'k.connectSN': 'Connect your social networks',
  'k.continue': 'Continue',
  'k.copyright': '© Copyright {year} {lineBreak}All rights reserved',
  'k.copyrightApp': '© Copyright {year} All rights reserved',
  'k.address':
    '228 Hamilton Avenue, {lineBreak}3rd floor. Palo Alto, California, 94301, United States of America.',
  'k.address.noAuth': '228 Hamilton Avenue, 3rd floor. Palo Alto, CA, 94301',
  'k.country': 'Country',
  'k.country_group': 'Region',
  'k.createPost': 'Create Post',
  'k.cta.freeTrial': 'Start Your Free Trial',
  'k.createAdAccount': 'Create Ad Account',
  'k.defineAud': 'Setup your audience',
  'k.currency': 'Currency',
  'k.custom': 'Custom',
  'k.customers': 'Customers',
  'k.dashboard': 'Dashboard',
  'k.date': 'Date',
  'k.days': '{daysCount, plural, one {# day} other {# days}}',
  'k.daysLeft': '{daysCount, plural, one {# day left} other {# days left}}',
  'k.trialEnd': 'Your free trial is over.',
  'k.default': 'Default',
  'k.defaultCard': 'Default card',
  'k.delete': 'Delete',
  'k.end': 'End',
  'k.finish': 'Finish',
  'k.description': 'Description',
  'k.dropfile': 'Click here to upload a PDF document or{br} drag it from your device.',
  'k.dropfile.label':
    '“Additionally, you can upload a PDF document with information that you want me to take into account to create the content.”',
  'k.dropfile.success': 'Loaded',
  'k.dropfile.delete': 'Delete',
  'k.dropfile.bad.filetype': 'You try to upload a incompatible file. Please upload a PDF file.',
  'k.errors.pdfConflict': 'The PDF exceeds the maximum allowed size or contains too much text.',
  'k.details.hide': 'Hide details',
  'k.details.show': 'Show details',
  'k.moreFeatures': 'See more features',
  'k.seeMore': 'See more',
  'k.disableAccount': 'Disable Account',
  'k.disconnect': 'Disconnect',
  'k.reconnect': 'Reconnect',
  'k.dontSellPersonal': "Don't sell my info",
  'k.duration': 'Duration',
  'k.durationPlaceHolder': '__ days',
  'k.edit': 'Edit',
  'k.editDetails': 'Edit details',
  'k.email': 'Email',
  'k.ends': 'Ends',
  'k.free': 'Free',
  'k.freeMonths': '{months, plural, one {# MONTH FREE} other {# MONTHS FREE}}',
  'k.freeTrial': '{days} days free trial',
  'k.help': 'Help',
  'k.contactus': 'Contact Us',
  'k.here': 'here',
  'k.inbox': 'Inbox',
  'k.interactions': 'Interactions',
  'k.investInSocialPiper': 'Invest in Social Piper',
  'k.invest': 'Invest',
  'k.learnMore': 'Learn more',
  'k.load.more': 'Load more',
  'k.loading': 'Loading',
  'k.login': 'Log In',
  'k.logout': 'Log out',
  'k.intercom.link.text': 'Live support',
  'k.mainCta': 'Let’s get started',
  'k.mainCtaPromoDefault': 'Let’s get started',
  'k.mainCtaPromo': 'Start your {trialDays} day trial',
  'k.mainCta.mobile': 'Let’s get started',
  'k.mainCtaPromo.mobile': 'Start Your Free Trial',
  'k.mainCtaPromoDefault.mobile': 'Start Your Free Trial',
  'k.mainDescription':
    'Welcome to Social Piper! The first end-to-end social media management platform for Small Businesses.',
  'k.mainTitle': 'Social Piper - Social Media made easy, for business',
  'k.mediaLibrary': 'Media Library',
  'k.metrics': 'My metrics <text>Last 30 days</text>',
  'k.day': 'Day',
  'k.week': 'Week',
  'k.month': 'Month',
  'k.monthly': 'Monthly',
  'k.monthlyPlan': 'Monthly Plan',
  'k.more': 'More',
  'k.myScheduler': 'My Scheduler',
  'k.neighborhood': 'Neighborhood',
  'k.nevermindCancel': 'Nevermind, cancel',
  'k.noThanks': 'No thanks',
  'k.notNow': 'Not now',
  'k.numberUnit.million': '{number} million',
  'k.numberUnit.thousand': '{number} thousand',
  'k.ok': 'OK',
  'k.or': 'or',
  'k.otherLinks': 'Other Links',
  'k.other': 'Other',
  'k.password': 'Password',
  'k.password.length': 'At least 8 characters in length.',
  'k.password.lowerCase': 'Lower case letters (a-z)',
  'k.password.numbers': 'Numbers (i.e. 0-9)',
  'k.password.specialChar': 'characters (e.g. !@#$%^&*)',
  'k.password.typesChar': 'Contain at least 3 of the following 4 types of characters:',
  'k.password.upperCase': 'Upper case letters (A-Z)',
  'k.passwordHelper.title': 'Your password must contain:',
  'k.password.3condition': 'Lowercase and uppercase letters and numbers.',
  'k.pause': 'Pause',
  'k.personalInfo': 'Personal information',
  'k.piperScore': 'Piper Score',
  'k.post': 'Post',
  'k.noPosts': 'No posts found or generated.',
  'k.posts': 'Posts',
  'k.postComments': 'Post Comments',
  'k.postIt': 'Post it',
  'k.preview': 'Preview',
  'k.pricing': 'Pricing',
  'k.price.anually': 'USD ${price} year',
  'k.price.month': 'USD ${price} month',
  'k.price.billed.annually': 'Billed annually',
  'k.price.billed.month': 'Billed monthly',
  'k.privacyPolicy': 'Privacy Policy',
  'k.proPlan': 'Pro Plan',
  'k.reach': 'Reach',
  'k.reaction': 'Reaction',
  'k.reactions': 'Reactions',
  'k.readLess': 'Read Less',
  'k.readMore': 'Read More',
  'k.recommended': 'Recommended',
  'k.mostPopular': 'Most Popular',
  'k.popular': 'Popular',
  'k.andmore': 'and more',
  'k.plan.allFeatures': 'See all Plan features',
  'k.region': 'State',
  'k.remaining': 'Remaining',
  'k.remove': 'Remove',
  'k.required': 'Required',
  'k.invalid.number': 'Invalid number',
  'k.reschedule': 'Reschedule',
  'k.reset': 'Reset',
  'k.resets': 'Resets',
  'k.run': 'Run',
  'k.save': 'Save',
  'k.saveChanges': 'Save Changes',
  'k.saveContinue': 'Save & Continue',
  'k.scheduler': 'Scheduler',
  'k.show.plan': 'Show Plans Details',
  'k.hide.plan': 'Hide Plans Details',
  'k.searchTag': 'Search tag',
  'k.select': 'Select',
  'k.selectAll': 'Select All',
  'k.seeDetails': 'See details',
  'k.send': 'Send',
  'k.send.email': 'Send email',
  'k.settings': 'Settings',
  'k.settings.modal.title': 'Tell Piper about:',
  'k.settings.modal.subtitle': 'Parameters',
  'k.shares': 'Shares',
  'k.show': 'Show',
  'k.signup': 'Sign Up',
  'k.signup.btn': 'Sign Up!',
  'k.signup.now': 'Sign Up now!',
  'k.getStarted': 'Get started today',
  'k.spend': 'Spend',
  'k.spent': 'Spent',
  'k.started': 'Started',
  'k.status': 'Status',
  'k.strategy': 'Strategy',
  'k.subneighborhood': 'Subneighborhood',
  'k.subscribeOnePlan': 'Subscribe to a plan',
  'k.subscribe': 'Subscribe',
  'k.terms': 'Terms of Service',
  'k.today': 'Today',
  'k.tomorrow': 'Tomorrow',
  'k.total': 'Total',
  'k.UpcomingPosts': 'My upcoming posts',
  'k.userProfile': 'User Profile',
  'k.viewAll': 'View All',
  'k.viewMore': 'View More',
  'k.weAreHiring': 'We are hiring',
  'k.whatIsThis': 'What is this?',
  'k.workWithUs': 'Work with us!',
  'k.auto-communityManager.noia': 'Do not use Piper',
  'k.auto-communityManager.auto': 'Let Piper Answer for me',
  'k.auto-communityManager.suggest': 'Let Piper suggest how to answer',
  'k.auto-communityManager.suggest.tooltip':
    'Every time you have a comment in Social Piper, Piper will suggest a cool answer which you can send or modify to your liking before sending it.',
  'k.auto-communityManager.auto.tooltip':
    "Each comment you receive on your social media will be automatically responded by Piper's Artificial Intelligence, except for those that Piper determines do not have a positive sentiment and require your intervention. Keep in mind that a comment that Piper responds cannot be edited, so if you want to change it you will have to delete it and respond to it manually.",
  'k.answer': 'Answer',
  'k.question': 'Question',
  'k.investInUs': 'Invest in us!',
  'k.whoWeAre': 'Who we are',
  'k.workInSocialPiper': 'Join our team ',
  'k.annually': 'Annually',
  'k.annually.save': 'Save 2 months',
  'k.yearly': 'Yearly',
  'k.yearlyPlan': 'Yearly Plan',
  'k.yes.change': 'Yes, change',
  'k.zip': 'Zip',
  'k.infoEmail': 'info@socialpiper.com',
  'k.textPostInstagram': 'Time to post!',
  'k.taskInstagram': 'You scheduled this post on Instagram:',
  'k.taskTiktok': 'You sheduled this post on TikTok:',
  'k.thisIsSocialPiper': 'About the team',
  'k.shareInstagram': 'Share on Instagram',
  'k.textCopied': 'Copied to clipboard!',
  'k.shareInstagramStepOne': 'To publish your post, first:',
  'k.shareInstagramStepOneCopy': 'Copy text to the clipboard',
  'k.shareInstagramStepTwo': 'Click on Share on Instagram to share it to your stories',
  'k.shareInstagramStepTwoIOS':
    'Download the image and share to Instragram, paste the test in te description of your post.',
  'k.textInstagramNotInstalledFirst':
    "Oh! It seems like you don't have the Instagram app. In order to publish, ",
  'k.textInstagramNotInstalledSecond':
    ' Make sure you come back to this window afterwards to share your post.',
  'k.textInstagramNotInstalledLink': 'download the app here.',
  'k.login.message.captcha.error': 'The page will reload to display a new checker.',
  'k.notification.box.title': 'Woof woof!',
  'k.notification.box.message':
    'To make the most of Social Piper, complete this info about your business:',
  'k.notification.box.message.meeting':
    'Do you want to learn how to use Social Piper for your business? {lineBreak}Schedule your consultation today.',
  'k.notification.message.firts':
    'Describe your clients, Where they are, Interests, gender and age.',
  'k.notification.message.second': 'Set your Name, location, and logo of your Business.',
  'k.notification.message.third': 'Set your email to contact you',
  'k.notification.message.fourth': 'Schedule your consultation with a Piper Expert.',
  'k.notification.box.button': 'Define',
  'k.notification.box.button.schedule.meeting': 'Schedule',
  'k.notification.box.button.setup': 'Set up',
  'k.register': 'Register',
  'boostManager.currencyInfo':
    'The currency displayed is in {currency}. <spanI>You can change it in </spanI><link>Personal & Preferences in Settings</link>',
  'boostManager.currencyAlert': ' *Estimated exchange rate as of today',
  'k.notification.tiktok.step.one':
    'First, <scp>Copy text to the clipboard. </scp> You will need it later.',
  'k.notification.tiktok.step.two':
    'Open your TikTok mobile app, go to the inbox section and select the notification that your video is ready. Follow the onscreen steps to finish your post.',
  'k.notification.tiktok.open.button': 'Open TikTok',
  'k.metrics.title': 'Metrics',
  'k.days.0': 'Monday',
  'k.days.1': 'Tuesday',
  'k.days.2': 'Wednesday',
  'k.days.3': 'Thursday',
  'k.days.4': 'Friday',
  'k.days.5': 'Saturday',
  'k.days.6': 'Sunday',
  'k.dontshowagain': 'Don’t show it again',

  'k.approvals.title': 'Approvals',
  'k.approvals.noPendingPosts': 'You have no pending posts for approval',
  'k.approvals.noPendingBoosts': 'You have no pending boosts for approval',
  'k.approvals.caption': 'Caption',
  'k.approvals.image': 'image',
  'k.approvals.businessOrProduct': 'Business / Product',
  'k.approvals.from': 'Start',
  'k.approvals.duration': 'Duration',
  'k.approvals.by': 'By',
  'k.approvals.post.approve': 'Approve',
  'k.approvals.post.edit': 'Edit & Approve',
  'k.approvals.post.delete': 'Delete',
  'k.approvals.boost.approve': 'Approve Boosting',
  'k.approvals.boost.edit': 'Edit & Approve Boosting',
  'k.approvals.boost.delete': 'Delete Boosting',
  'k.approvals.edit.title.post': 'Edit post',
  'k.approvals.edit.title.boost': 'Edit boost',
  'k.approvals.edit.accept': 'Accept',
  'k.approvals.edit.cancel': 'Cancel',
  'k.approvals.confirm.accept.title.post': 'Approve Post',
  'k.approvals.confirm.accept.title.boost': 'Accept boost',
  'k.approvals.confirm.accept.advice.post': 'Are you sure you want to approve this Post?',
  'k.approvals.confirm.accept.advice.boost': 'Are you sure to accept this boost?',
  'k.approvals.confirm.accept.accept': 'Yes',
  'k.approvals.confirm.accept.reject': 'No',
  'k.approvals.confirm.reject.title.post': 'Reject post',
  'k.approvals.confirm.reject.title.boost': 'Reject boost',
  'k.approvals.confirm.reject.advice.post': 'Are you sure you want to reject this post?',
  'k.approvals.confirm.reject.advice.boost': 'Are you sure you want to reject this boost?',
  'k.approvals.confirm.reject.accept': 'Yes',
  'k.approvals.confirm.reject.reject': 'No',
  'k.approvals.post.accept.success': 'Post accepted successfully',
  'k.approvals.post.accept.failed': 'There was an error accepting the post',
  'k.approvals.post.reject.success': 'Post rejected successfully',
  'k.approvals.post.reject.failed': 'There was an error rejecting the post',
  'k.approvals.boost.accept.success': 'Boost accepted successfully',
  'k.approvals.boost.accept.failed': 'There was an error accepting the boost',
  'k.approvals.boost.reject.success': 'Boost rejected successfully',
  'k.approvals.boost.reject.failed': 'There was an error rejecting the boost',
  'k.approvals.statuses.title': 'Status',
  'k.approvals.statuses.pending': 'Pending',
  'k.approvals.statuses.approved': 'Approved',
  'k.approvals.statuses.rejected': 'Rejected by Master',
  'k.approvals.statuses.buttons.dismiss': 'Dismiss',
  'k.approvals.statuses.buttons.reuse': 'Re-use',
  'k.approvals.confirm.collab.reject.advice.post':'Are you sure to delete this post?',
  'k.approvals.post.collab.reject.success': 'Post successfully deleted',
  'k.approvals.post.collab.reject.failed': 'There was an error deleting the post',
  'k.somethingWentWrong': 'An unexpected error has occurred. Please contact <a>support@socialpiper.com</a> if the issue persists. We apologize for the inconvenience.',
};

export default en;
