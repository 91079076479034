/* eslint-disable react/style-prop-object */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import NotificationModal from '../../../../../components/notifications/Modal';
import { heading2, paragraph } from '../../../../../styled/mixins';
import { colors } from '../../../../../styled/theme';
import { ButtonLink, Button } from '../../../../../components/buttons';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import PricingModal from './PricingModal';
import Currency, { currencyFormat } from '../../../../../components/globals/Currency';
import PromoCodeContext from '../../../../../contexts/PromoCodeContext';
import { PricingCancelPrice } from '../../../../CreateAccount/plans/intlPlanConversor';
import { AnalyticsContext } from '../../../../../contexts/AnalyticsContext';

const StyledSubscriptionModal = styled(NotificationModal)`
&&{
  .MuiPaper-root{
    padding: 24px 15px;
    ${GlobalTheme.breakpoints.down('sm')} {
      min-width: 350px;
    }
  }
}
`;

const Container = styled.div`
  width: 100%;
  height: 350px;
  padding: 0 24px;
  ${GlobalTheme.breakpoints.down('sm')}{
    height: auto;
    padding: 0;
  }
`;

const Header = styled.div``;

const Title = styled.h2`
  ${heading2}
  margin-bottom: 8px;
`;

const Title2 = styled(Title)`
  ${GlobalTheme.breakpoints.down('sm')} {
    max-width: 200px;
  }
`;

const Subtitle = styled.p`
  ${paragraph}
  font-size: 18px;
  text-align: ${(props) => (props.textAlign || 'left')};
`;

const Plans = styled.div`
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;
  justify-content: space-around;
  ${GlobalTheme.breakpoints.down('sm')} {
    justify-content: space-between;
  }
`;

const OfferSection = styled.div`
  margin-top: 8px;
  display: flex;
  ${GlobalTheme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const OfferTitle = styled.p`
  ${paragraph}
  font-size: 18px;
  color: ${colors.red};
  margin-right: 16px;
  ${GlobalTheme.breakpoints.down('sm')} {
    margin-right: 0;
    flex: 0 0 100%;
  }
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 190px;
`;

const SubscribeButton = styled(Button)`
  && {
    margin-top: auto;
  }
`;

const PriceSpan = styled.span`
  display: block;
  margin-top: 4px;
  background: ${colors.lightBlue};
  border-radius: 3px;
  padding: 4px;
  color: white;
  font-weight: 600;
  font-size: 12px;
`;

const RegularCost = styled.p`
  margin-top: 4px;
  display: flex;
  font-size: 11px;
  align-items: center;
`;

const CustomPricingCancelPrice = styled(PricingCancelPrice)`
  margin-left: 4px;
  font-size: 11px;
`;

const SubscriptionModal = ({ open, setOpen, handleSubscribe }) => {
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [monthlyLoading, setMonthlyLoading] = useState(false);
  const [yearlyLoading, setYearlyLoading] = useState(false);
  const { PromoCode } = useContext(PromoCodeContext);
  const { dataLayerPush } = useContext(AnalyticsContext);
  const handleEnter = () => {
    dataLayerPush({
      pagePath: '/settings/billing/subscribe',
      pathTitle: 'Settings - Billing - Subscribe',
      event: 'pv',
    });
  };
  return (
    <>
      <StyledSubscriptionModal
        open={open}
        setOpen={setOpen}
        onEntered={handleEnter}
        minWidth="510px"
      >
        <Container>
          <Header>
            <Title2>
              <FormattedMessage
                id="subscriptionModal.title"
                defaultMessage="Subscribe to Social Piper"
              />
            </Title2>
            <Subtitle>
              <FormattedMessage
                id="subscriptionModal.subtitle"
                defaultMessage="Select your desired billing period"
              />
            </Subtitle>
            {PromoCode.code && (
              <OfferSection>
                <OfferTitle>
                  { PromoCode.code === 'COVID_RELIEF' && (
                    <FormattedMessage
                      id="pricing.covid.relief"
                      defaultMessage="COVID Relief Price"
                    />
                  )}
                  { PromoCode.code === 'CICC' && (
                    <>
                      <FormattedMessage
                        id="pricing.cicc.offer"
                        defaultMessage="CICC Offer"
                      />
                    </>
                  )}
                  { PromoCode.code === 'ASEM' && (
                    <>
                      <FormattedMessage
                        id="pricing.asem.offer"
                        defaultMessage="ASEM Offer"
                      />
                    </>
                  )}
                  { PromoCode.code === 'AMAI' && (
                    <>
                      <FormattedMessage
                        id="pricing.amai.offer"
                        defaultMessage="AMAI Offer"
                      />
                    </>
                  )}
                  { PromoCode.code === 'APREN' && (
                    <>
                      <FormattedMessage
                        id="pricing.apren.offer"
                        defaultMessage="APREN Offer"
                      />
                    </>
                  )}
                </OfferTitle>
                <ButtonLink
                  onClick={() => setShowLearnMore(true)}
                >
                  <FormattedMessage
                    id="k.learnMore"
                    defaultMessage="Learn more"
                  />
                  {' >'}
                </ButtonLink>
              </OfferSection>
            )}
          </Header>
          <Plans>
            {PromoCode.plans.map((plan) => (
              <Plan key={`Plan_${plan.id}`}>
                <Title>
                  <FormattedMessage
                    id={`k.${plan.isMonthly ? 'monthly' : 'yearly'}`}
                    defaultMessage={`${plan.isMonthly ? 'Monthly' : 'Yearly'}`}
                  />
                </Title>
                <Subtitle
                  textAlign="center"
                >
                  <FormattedMessage
                    id={`subscriptionModal.${plan.isMonthly ? 'monthly' : 'yearly'}.price`}
                    defaultMessage={plan.isMonthly ? 'US {price} / Month' : 'US {price}'}
                    values={{
                      price: currencyFormat(plan.realPrice, 'USD', 0, 0),
                      lineBreak: <br />,
                      s: (...chunks) => (
                        <PriceSpan>
                          {chunks}
                        </PriceSpan>
                      ),
                    }}
                  />
                </Subtitle>
                {plan.isMonthly && plan.monthlyPrice !== plan.realPrice && (
                  <RegularCost>
                    <FormattedMessage
                      id="subscriptionModal.regularCost"
                      defaultMessage="Regular cost US"
                    />
                    <CustomPricingCancelPrice>
                      <Currency
                        value={plan.monthlyPrice}
                        style="currency"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </CustomPricingCancelPrice>
                  </RegularCost>
                )}
                {!plan.isMonthly && plan.yearlyMonthlyPrice * 12 !== plan.realPrice && (
                  <RegularCost>
                    <FormattedMessage
                      id="subscriptionModal.regularCost"
                      defaultMessage="Regular cost US"
                    />
                    <CustomPricingCancelPrice>
                      <Currency
                        value={plan.yearlyMonthlyPrice * 12}
                        style="currency"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </CustomPricingCancelPrice>
                  </RegularCost>
                )}
                <SubscribeButton
                  isLoading={plan.isMonthly ? monthlyLoading : yearlyLoading}
                  disabled={plan.isMonthly ? yearlyLoading : monthlyLoading}
                  onClick={() => {
                    handleSubscribe(
                      plan.isMonthly ? setMonthlyLoading : setYearlyLoading,
                      plan,
                    );
                  }}
                >
                  <FormattedMessage
                    id="subscriptionModal.subscribe"
                    defaultMessage="Subscribe"
                  />
                </SubscribeButton>
              </Plan>
            ))}
          </Plans>
        </Container>
      </StyledSubscriptionModal>
      <PricingModal
        open={showLearnMore}
        setOpen={setShowLearnMore}
      />
    </>
  );
};

SubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSubscribe: PropTypes.func.isRequired,
};

export default SubscriptionModal;
