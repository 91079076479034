import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SocialProfilesProvider } from '../services/entities';
import AuthService from '../services/auth/AuthService';
import { filterNullValues } from '../utils';
import { set } from 'lodash';

export const SocialContext = React.createContext();

const SocialProvider = ({ children }) => {
  const [socialNetworks, setSocialNetworks] = useState({
    isInitial: true,
    facebook: {
      avatar: '',
      pageName: '',
    },
    instagram: {
      avatar: '',
      pageName: '',
    },
    twitter: {
      avatar: '',
      pageName: '',
    },
    linkedin: {
      avatar: '',
      pageName: '',
    },
    tiktok: {
      avatar: '',
      pageName: '',
    },
  });
  const [showMultiAccount, setShowMultiAccount] = useState(false);
  const [selectorType, setSelectorType] = useState('');
  const [hasConnectedAccounts, setHasConnectedAccounts] = useState(false);
  const [redirectDashboard, setRedirectDashboard] = useState(true);
  const [showRequireAttention, setShowRequireAttention] = useState(false);

  const openPageSelector = (socialNetwork) => {
    setSelectorType(socialNetwork);
    setShowMultiAccount(true);
  };

  const closePageSelector = () => {
    setShowMultiAccount(false);
  };
  const getConnectedAccounts = (sn) => {
    const accounts = filterNullValues(Object.values(sn));
    const activeAccounts = accounts.filter((x) => !!x.pageName);
    return activeAccounts;
  };

  const isNotConnectedAccounts = (snObj) => {
    const snTmp = snObj || socialNetworks;
    const gca = getConnectedAccounts(snTmp);
    if (gca && gca.length) return false;
    return true;
  };

  const verifyRequireAttention = (snObjs) => {
    Object.values(snObjs).forEach((snO) => {
      const { requireAttention: rA } = snO;
      if (rA) {
        setShowRequireAttention(true);
      }
    });
  };

  const fetchSocialNetworksData = async (companyId = null) => {
    let sn = {
      facebook: {
        avatar: '',
        pageName: '',
      },
      instagram: {
        avatar: '',
        pageName: '',
      },
      tiktok: {
        avatar: '',
        pageName: '',
        toSetup: false,
      },
      twitter: {
        avatar: '',
        pageName: '',
      },
      linkedin: {
        avatar: '',
        pageName: '',
      },
    };
    const response = await SocialProfilesProvider.fetchSocialProfilesInformation(companyId);
    if (response && response.success) {
      sn = response.data;
    }
    const accounts = getConnectedAccounts(sn);
    verifyRequireAttention(sn);
    const hasConnectedSN = accounts.length > 0;
    setHasConnectedAccounts(hasConnectedSN);
    setSocialNetworks(sn);
    return sn;
  };
  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      fetchSocialNetworksData();
    }
  }, []);

  return (
    <SocialContext.Provider
      value={{
        socialNetworks,
        setSocialNetworks,
        fetchSocialNetworksData,
        showMultiAccount,
        openPageSelector,
        closePageSelector,
        selectorType,
        hasConnectedAccounts,
        setHasConnectedAccounts,
        getConnectedAccounts,
        isNotConnectedAccounts,
        redirectDashboard,
        setRedirectDashboard,
        showRequireAttention,
        setShowRequireAttention,
      }}
    >
      {children}
    </SocialContext.Provider>
  );
};

SocialProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SocialProvider;
