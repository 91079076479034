import gql from 'graphql-tag';

export const socialLoginResultSubscription = gql`
  subscription sub ($authId: String, $network: SOCIAL_NETWORK){
    socialLoginResult (authId: $authId, network: $network) {
      status
      network
      reason
    }
  }
`;

export default {};
