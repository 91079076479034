import { Backdrop, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { CloseOutlineIcon, WarningRedIcon } from '../../../../../assets/icons/iconRepository';
import IconPlacer from '../../../../../components/IconPlacer';
import { Button } from '../../../../../components/buttons';
import Modal from '../../../../../components/globals/Modal';
import { text } from '../../../../../components/text';
import useCompany from '../../../../../hooks/useCompany';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { borderTop, flexBox, materialShadows } from '../../../../../styled/mixins';
import CardInformationHeader from './CardInformationHeader';

const DeleteBusinessSection = ({ onDelete }) => {
  const { selectedCompany } = useCompany();
  const [openModal, setOpenModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { quantityCompanies } = useCompany();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true)
    onDelete()
  }

  const closeModal = () => {
    setOpenModal(false);
    setTimeout(() => setIsConfirmed(false), 500);
  };

  const toggleModal = () => setOpenModal((s) => !s);

  return (
    <Card>
      <DeleteBusinessModal
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <>
          <IconPlacer
            onClick={closeModal}
            path={CloseOutlineIcon}
            size={25}
            style={{ marginLeft: 'auto', marginRight: 0, cursor: 'pointer' }}
          />
          <IconPlacer
            path={WarningRedIcon}
            size={42}
            style={{ margin: '0 auto', marginBottom: 35 }}
          />
          <AdviceText>
            {text('settings.business.delete.advice', {
              br: <br />,
              b: (...chunks) => <b>{chunks}</b>,
              red: (...chunks) => <RedText>{chunks}</RedText>,
            })}
          </AdviceText>

          <ConfirmationWrapper>
            <Checkbox
              color='primary'
              style={{ padding: 0, width: 20, height: 20 }}
              checked={isConfirmed}
              onChange={() => setIsConfirmed((val) => !val)}
              id='id-checkbox'
            />
            <ConfirmationLabel htmlFor='id-checkbox'>
              {text('settings.business.delete.confirm', {
                companyName: selectedCompany?.companyName,
                b: (...chunks) => <b>{chunks}</b>,
              })}
            </ConfirmationLabel>
          </ConfirmationWrapper>

          <ButtonModalWrapper>
            <ButtonModal onClick={closeModal}>
              {text('settings.business.delete.cancel')}
            </ButtonModal>
            <ButtonModal disabled={!isConfirmed} isLoading={isLoading} onClick={handleDelete}>
              {text('settings.business.delete.biz')}
            </ButtonModal>
          </ButtonModalWrapper>
        </>
      </DeleteBusinessModal>

      <CardInformationHeader
        title={text('settings.business.delete.title')}
        advice={text('settings.business.delete.subtitle')}
      />
      <DeleteButton disabled={quantityCompanies <= 1} onClick={toggleModal}>
        {text('settings.business.delete.title')}
      </DeleteButton>
    </Card>
  );
};

DeleteBusinessSection.propTypes = {
  onDelete: PropTypes.func,
};

export default DeleteBusinessSection;

const DeleteBusinessModal = styled(Modal)`
  [class*='MuiPaper-root'] {
    height: auto;
    width: 808px;
  }
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  column-gap: 6px;
  justify-content: flex-start;
  margin-top: 35px;
`;

const ConfirmationLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-top: -4px;
`;

const AdviceText = styled.p`
  font-size: 18px;
  text-align: center;
  line-height: 26px;
`;

const DeleteButton = styled(Button)`
  && {
    position: absolute;
    top: 80px;
    right: 32px;

    background: ${(props) => props.theme.colors.red};
    color: ${(props) => props.theme.colors.white};

    &:hover {
      background: ${(props) => props.theme.colors.badge};
    }
  }
`;

const ButtonModal = styled(Button)`
  && {
    width: 185px;
    height: 40px;
    position: unset;
    top: 0px;
    right: 0px;
  }
`;

const ButtonModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 35px;
  margin-top: 35px;
`;

const RedText = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-weight: bold;
`;

const Card = styled.div`
  ${materialShadows};
  ${borderTop('speechBubble')};
  ${flexBox}

  padding: 24px 32px;
  flex-wrap: wrap;
  position: relative;

  background: ${(props) => props.theme.colors.white};

  ${GlobalTheme.breakpoints.down('md')} {
    padding: 24px 16px;
  }
`;
