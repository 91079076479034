import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { flexBox } from '../../../../../styled/mixins';
import { colors } from '../../../../../styled/theme';
import AudienceFormFacebook from './AudienceFormFacebook';
import AudienceFormLinkedin from './AudienceFormLinkedin';
import AudienceFormAgeGender from './AudienceFormAgeGender';
import { SocialContext } from '../../../../../contexts/SocialContext';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { ButtonLink } from '../../../../../components/buttons';
import { useIntl } from 'react-intl';

const AudienceFormContainer = styled.div`
  ${flexBox}
  width: 100%;
  margin-bottom: 24px;
  flex-direction: column;
  justify-content: flex-start;
  counter-reset: audienceSteps;
`;

const styledNumbered = css`
  position: relative;
  padding-left: 50px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    counter-increment: audienceSteps;
    content: counter(audienceSteps);
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.secondary};
    border-radius: 100%;
    font-size: 24px;
    font-weight: 700;
    width: 40px;
    height: 40px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    padding-left: 40px;
    &:before{
      width: 32px;
      height: 32px;
      font-size: 18px;
    }
  }
`;

const AudienceContainer = styled.div`
  width: 100%;
  margin: 0 0 24px;
  ${({ showNumbered }) => showNumbered && styledNumbered}
`;

const ButtonWrapper = styled.div`
  flex: 0 0 100%;
  ${flexBox}
  justify-content: center;
`;

const AudienceForm = ({
  audienceInfo,
  liAudienceInfo,
  potentialAudience,
  linkedinPotentialAudience,
  shouldShow,
  setLocations,
  setGender,
  genders,
  setInterests,
  setLinkedinLocations,
  setLinkedinInterests,
  setAges,
  isModal,
  liValid,
  valid,
  isLoading,
  handleSave,
}) => {
  const {
    socialNetworks,
  } = useContext(SocialContext);
  const [showNumbered, setShowNumbered] = useState(false);
  const intl = useIntl()

  useEffect(() => {
    const countShow = Object.values(shouldShow).reduce((total, x) => (x ? total + 1 : total), 0);
    setShowNumbered(countShow > 1 && isModal);
  }, [shouldShow, isModal]);
  return (
    <AudienceFormContainer>
      {socialNetworks?.facebook?.pageName && (!isModal || shouldShow.facebook) && (
        <AudienceContainer
          showNumbered={showNumbered}
        >
          <AudienceFormFacebook
            audienceInfo={audienceInfo}
            setLocations={setLocations}
            setInterests={setInterests}
            potentialAudience={potentialAudience}
            isModal={isModal}
          >
            <AudienceFormAgeGender
              audienceInfo={audienceInfo}
              shouldShow={shouldShow.facebook}
              setGender={(value) => setGender(value, 'facebook')}
              setAges={(_, value) => setAges(value, 'facebook')}
              genders={genders}
              intl={intl}
              isModal={isModal}
            />
          </AudienceFormFacebook>
          <ButtonWrapper>
            <ButtonLink
              onClick={() => handleSave()}
              isLoading={isLoading}
              disabled={!valid}
            >
              {
                intl.formatMessage({
                  id: 'k.saveChanges',
                  defaultMessage: 'Save Changes',
                })
              }
            </ButtonLink>
          </ButtonWrapper>
        </AudienceContainer>
      )}
      {socialNetworks?.linkedin?.pageName && (!isModal || shouldShow.linkedin) && (
        <AudienceContainer
          showNumbered={showNumbered}
        >
          <AudienceFormLinkedin
            audienceInfo={liAudienceInfo}
            setLocations={setLinkedinLocations}
            setInterests={setLinkedinInterests}
            potentialAudience={linkedinPotentialAudience}
            isModal={isModal}
          >
            <AudienceFormAgeGender
              audienceInfo={liAudienceInfo}
              shouldShow={shouldShow.linkedin}
              setGender={(value) => setGender(value, 'linkedin')}
              setAges={(_, value) => setAges(value, 'linkedin')}
              genders={genders}
              intl={intl}
              isModal={isModal}
            />
          </AudienceFormLinkedin>
          <ButtonWrapper>
            <ButtonLink
              onClick={() => handleSave(true)}
              isLoading={isLoading}
              disabled={!liValid}
            >
              {
                intl.formatMessage({
                  id: 'k.saveChanges',
                  defaultMessage: 'Save Changes',
                })
              }
            </ButtonLink>
          </ButtonWrapper>
        </AudienceContainer>
      )
      }
    </AudienceFormContainer>
  );
};

AudienceForm.propTypes = {
  audienceInfo: PropTypes.instanceOf(Object).isRequired,
  liAudienceInfo: PropTypes.instanceOf(Object).isRequired,
  potentialAudience: PropTypes.instanceOf(Object).isRequired,
  linkedinPotentialAudience: PropTypes.instanceOf(Object).isRequired,
  shouldShow: PropTypes.instanceOf(Object).isRequired,
  setLocations: PropTypes.func.isRequired,
  setGender: PropTypes.func.isRequired,
  genders: PropTypes.instanceOf(Array).isRequired,
  setInterests: PropTypes.func.isRequired,
  setLinkedinLocations: PropTypes.func.isRequired,
  setLinkedinInterests: PropTypes.func.isRequired,
  setAges: PropTypes.func.isRequired,
  intl: PropTypes.instanceOf(Object).isRequired,
  isModal: PropTypes.bool,
};

AudienceForm.defaultProps = {
  isModal: false,
};

export default AudienceForm;
