import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import FormController from '../../../../../components/globals/FormController';
import { AlertsContext } from '../../../../../contexts/AlertsContext';
import useCompany from '../../../../../hooks/useCompany';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { flexBox, paragraph } from '../../../../../styled/mixins';
import { colors } from '../../../../../styled/theme';
import { LOGO_MAX_DIMENSIONS } from '../../../../../utils/constants/globals';
import resizeAndEncode from '../../../../../utils/imageProcessing';
import UploadLogo from '../../../../onBoarding/components/UploadLogo';
import BusinessCategoriesSelect from './BusinessCategoriesSelect';
import BusinessLocationSelect from './BusinessLocationSelect';

const BusinessInformationForm = ({ setIsNewLogo }) => {
  const intl = useIntl();
  const { showError } = useContext(AlertsContext);
  const {
    infoState: [businessInfo, setBusinessInfo],
  } = useCompany();

  const handleInputChange = (e) => {
    setBusinessInfo({
      ...businessInfo,
      [e.target.id]: e.target.value,
    });
  };

  const setCategories = (category) => {
    setBusinessInfo({
      ...businessInfo,
      category,
    });
  };

  const setLocation = (location) => {
    setBusinessInfo({
      ...businessInfo,
      location,
    });
  };

  const handleLogoLoad = (img, file, url) => {
    setBusinessInfo({
      ...businessInfo,
      logoPreview: url,
      logo: resizeAndEncode(img, LOGO_MAX_DIMENSIONS),
      logoName: file.name,
      logoType: file.type,
    });
    setIsNewLogo(true);
  };

  const handleFileTooLarge = () => {
    showError(
      intl.formatMessage({
        id: 'alert.error.fileTooLarge',
        defaultMessage: 'Your image is too large. Please, add a smaller one, no more than 2MB.',
      }),
    );
  };

  return (
    <Container>
      <StyledUploadLogo
        preview={businessInfo.logoPreview}
        onLoad={handleLogoLoad}
        handleFileTooLarge={handleFileTooLarge}
        gaData={{
          action: 'Upload company logo',
          label: '(on business & audience)',
        }}
      >
        <ActionText>
          <UpdateText>
            <FormattedMessage
              id='settings.business.businessInformation.updateLogo'
              defaultMessage='<color>Update</color> your business logo'
              values={{
                color: (...chunks) => <PrimaryText>{chunks}</PrimaryText>,
              }}
            />
          </UpdateText>
          <SizeText>
            <FormattedMessage
              id='settings.business.businessInformation.sizeLogo'
              defaultMessage='Photos should be less than 2MB'
            />
          </SizeText>
        </ActionText>
      </StyledUploadLogo>
      <Input
        showRequired
        id='companyName'
        onChange={handleInputChange}
        placeholder={intl.formatMessage({
          id: 'community.config.companyName.placeholder',
          defaultMessage: 'E.g. Something',
        })}
        label={intl.formatMessage({
          id: 'settings.business.businessInformation.name',
          defaultMessage: 'Business name',
        })}
        value={businessInfo.companyName}
      />
      <StyledBusinessCategoriesSelect
        placeholder={intl.formatMessage({
          id: 'community.config.companyCategories.placeholder',
          defaultMessage: 'E.g. Something',
        })}
        value={businessInfo.category}
        setCategories={setCategories}
        intl={intl}
      />
      <StyledBusinessLocationSelect
        placeholder={intl.formatMessage({
          id: 'community.config.companyLocation.placeholder',
          defaultMessage: 'E.g. Something',
        })}
        value={businessInfo.location}
        setLocation={setLocation}
        intl={intl}
      />
    </Container>
  );
};

BusinessInformationForm.propTypes = {
  setIsNewLogo: PropTypes.func.isRequired,
};

const Container = styled.div`
  ${flexBox}
  flex-wrap: wrap;
  width: 100%;
`;

const StyledUploadLogo = styled(UploadLogo)`
  margin: 0 0 24px;
  flex: 0 0 100%;
`;

const ActionText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 8px;
`;

const UpdateText = styled.p`
  ${paragraph}
  font-size: 18px;
  font-weight: 600;
  ${StyledUploadLogo}:hover & {
    text-decoration: underline;
  }
`;

const PrimaryText = styled.span`
  color: ${colors.primary};
`;

const SizeText = styled.p`
  ${paragraph}
  font-size: 14px;
  color: ${colors.gray90};
`;

const Input = styled(FormController)`
  flex: 0 0 calc(50% - 12px);
  margin: 0 0 24px;
  ${GlobalTheme.breakpoints.down('md')} {
    flex: 0 0 100%;
    margin: 0 0 16px;
  }
`;

const StyledBusinessCategoriesSelect = styled(BusinessCategoriesSelect)`
  flex: 0 0 calc(50% - 12px);
  margin: 0 0 24px;
  ${GlobalTheme.breakpoints.down('md')} {
    flex: 0 0 100%;
    margin: 0 0 16px;
  }
`;

const StyledBusinessLocationSelect = styled(BusinessLocationSelect)`
  flex: 0 0 calc(50% - 12px);
  margin: 0 0 24px;
  ${GlobalTheme.breakpoints.down('md')} {
    flex: 0 0 100%;
    margin: 0 0 16px;
  }
`;

export default BusinessInformationForm;
