/* eslint-disable import/no-cycle */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Media from 'react-media';
import { container, label } from '../../../styled/mixins';
import { colors } from '../../../styled/theme';
import GlobalTheme from '../../../styled/GlobalTheme';
import PricingCard from './PricingCard';
import roles from '../../../utils/constants/roles';
import PromoCodeContext from '../../../contexts/PromoCodeContext';
import sanitizePlans from '../../CreateAccount/sanitizePlans';
import { piper } from '../../../assets/illustrations/illustrationRepository';
import { santanderBadge } from '../../../assets/images/imageRepository';
import mediaQueries from '../../../utils/mediaQueries';
import { ButtonSecondary } from '../../landing/components/Buttons';
import FeaturesPlanModal from './FeaturesPlanModal';
import routes from '../../../utils/constants/routes';
import ContactFormModal from './ContactFormModal';

const Wrapper = styled.div`
  padding-left: max(0, env(safe-area-inset-left));
  padding-right: max(0, env(safe-area-inset-right));
  position: relative;
  margin-top: 32px;
  @media ${mediaQueries.lgPlusUp} {
    margin-top: 0;
  }
  ${({ landing }) => landing && `
    background: ${colors.green03};
    padding-top: 46px;
    padding-bottom: 40px;
    margin-top: 0;
    @media ${mediaQueries.lgPlusUp} {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  `}
`;

const Header = styled.div`
  margin-bottom: 28px;
  @media ${mediaQueries.lgPlusUp} {
    margin-bottom: 48px;
  }
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 28px;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
  @media ${mediaQueries.lgPlusUp} {
    font-size: 36px;
    line-height: 48px;
  }
`;
const PricingContainer = styled.div`
  ${container}
  position: relative;
  ${({ isLanding }) => !isLanding && `
    padding-left: max(0,env(safe-area-inset-left));
    padding-right: max(0,env(safe-area-inset-right));
  `}
  @media ${mediaQueries.xlPlusDown} {
    padding-left: max(16px,env(safe-area-inset-left));
    padding-right: max(16px,env(safe-area-inset-right));
  }
  @media ${mediaQueries.xlDown} {
    padding-left: max(30px,env(safe-area-inset-left));
    padding-right: max(30px,env(safe-area-inset-right));
    ${({ isLanding }) => !isLanding && `
      padding-left: max(0,env(safe-area-inset-left));
      padding-right: max(0,env(safe-area-inset-right));
    `}
  }
  ${GlobalTheme.breakpoints.down('md')} {
    padding-left: 16px;
    padding-right: 16px;
    padding-left: max(16px,env(safe-area-inset-left));
    padding-right: max(16px,env(safe-area-inset-right));
  }
`;
const PricingSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  @media ${mediaQueries.lgPlusUp} {
    margin-bottom: 0;
    position: absolute;
    top: calc(50% - 106px);
    left: -280px;
    z-index: 2;
  }
  @media (min-width: 1200px) {
    top: calc(50% - 80px);
  }
`;
const PricingSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.secondaryDark};
  border-radius: 30px;
  padding: 3px 5px;
`;
const PricingPicker = styled.div`
  ${label}
  ${({ hasPadding }) => (hasPadding ? 'padding: 12px 0;' : '')}
  min-width: 110px;
  text-align: center;
  border-radius: 2px;
  color: ${colors.black3E};
  opacity: 0.5;
  background: transparent;
  margin: 0 1px;
  cursor: pointer;
  border-radius: 24px;
  line-height: 24px;
  transition: all 0.2s ease-in-out;
  ${({ active }) => active && css`
    color: ${colors.black3E};
    background: ${colors.white};
    opacity: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  `}
`;
const PricingText = styled.p`
  margin: 0;
`;

const SubTitle = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
`;
const PricingCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0;
  @media (min-width: 630px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  @media ${mediaQueries.lgPlusUp} {
    flex-direction: row;
    align-items: baseline;
  }
`;

export const ShowPricingDetails = styled.div`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  @media ${mediaQueries.lgPlusUp} {
    margin-top: 48px;
  }
`;
const BadgeContainer = styled.div`
  position: absolute;
  top:0;
  right: 0;
  @media ${mediaQueries.lgPlusUp} {
    width: 230px;
  }
  @media ${mediaQueries.xlPlusUp} {
    width: 327px;
  }
`;
const Badge = styled.img`
  width: 100%;
`;

const PricingSection = ({ landing }) => {
  const history = useHistory();
  const [isMonthly, setIsMonthly] = useState(true);
  const { PromoCode } = useContext(PromoCodeContext);
  const [priceY, setPriceY] = useState([]);
  const [priceM, setPriceM] = useState([]);
  const [decimalM, setDecimalM] = useState([]);
  const [decimalY, setDecimalY] = useState([]);
  const [trialDaysM, setTrialDaysM] = useState(0);
  const [trialDaysY, setTrialDaysY] = useState(0);
  const [codePromo, setCodePromo] = useState('');
  const [applyPromo, setApplyPromo] = useState(false);
  const [currencyCode, setCurrencyCode] = useState('USD');
  const [hasMorePricingDetails] = useState(false);
  const intl = useIntl();
  const [bizPrice, setBizPrice] = useState('49');
  const [openFeaturesModal, setOpenFeaturesModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(roles.PRO);
  const [openContactFormModal, setOpenContactFormModal] = useState(false);

  useEffect(() => {
    const { code, isValid } = PromoCode;
    setCodePromo(code);
    setApplyPromo(isValid);
    const [monthlyPlan, yearlyPlan] = sanitizePlans(PromoCode, false, false, true);
    const findProMonthlyPlan = monthlyPlan.find((plan) => plan.defaultRoleId === 1);
    const findProYearlyPlan = yearlyPlan.find((plan) => plan.defaultRoleId === 1);
    const findBusinessMonthlyPlan = monthlyPlan.find((plan) => plan.defaultRoleId === 3);
    const findBusinessYearlyPlan = yearlyPlan.find((plan) => plan.defaultRoleId === 3);
    const textPriceM = [`${findProMonthlyPlan?.realPrice}`, `${findBusinessMonthlyPlan?.realPrice}`];
    const arrayPriceM = [textPriceM[0].split('.'), textPriceM[1].split('.')];
    const [priceIntegerMonthlyPro, priceIntegerMonthlyBusiness, priceDecimalMonthlyPro, priceDecimalMonthlyBusiness] = arrayPriceM;
    setPriceM([priceIntegerMonthlyPro, priceIntegerMonthlyBusiness]);
    setDecimalM([priceDecimalMonthlyPro, priceDecimalMonthlyBusiness]);
    const textPriceY = [`${findProYearlyPlan?.realPrice}`, `${findBusinessYearlyPlan?.realPrice}`];
    const arrayPriceY = [textPriceY[0].split('.'), textPriceY[1].split('.')];
    const [priceIntegerYearlyPro, priceIntegerYearlyBusiness, priceDecimalYearlyPro, priceDecimalYearlyBusiness] = arrayPriceY;
    setPriceY([priceIntegerYearlyPro, priceIntegerYearlyBusiness]);
    setDecimalY([priceDecimalYearlyPro, priceDecimalYearlyBusiness]);
    setTrialDaysM(monthlyPlan.trialDuration);
    setTrialDaysY(yearlyPlan.trialDuration);
    setCurrencyCode(monthlyPlan.currencyCode);
    window.localStorage.setItem('isMonthly', 'true');
  }, [PromoCode]);

  useEffect(() => {
    switch (currencyCode) {
      case 'USD':
        setBizPrice('49');
        break;
      case 'EUR':
        setBizPrice('45');
        break;
      case 'GBP':
        setBizPrice('36');
        break;
      default:
        setBizPrice('49');
        break;
    }
  }, [currencyCode]);

  const plans = [
    {
      color: colors.black3E,
      name: intl.formatMessage({
        id: 'newPricing.proPlan.title',
        defaultMessage: 'Individual Plan',
      }),
      description: intl.formatMessage({
        id: 'newPricing.plan.description.pro',
        defaultMessage: 'For small business owners',
      }),
      role: roles.PRO,
      contents: [
        intl.formatMessage({
          id: 'newPricing.plan.pro.content3',
          defaultMessage: '1 user per plan',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.pro.content1',
          defaultMessage: 'Schedule unlimited posts',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.pro.content2',
          defaultMessage: 'Unlimited ready-to-use {lineBreak}templates and designs',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.pro.content4',
          defaultMessage: '',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.pro.content5',
          defaultMessage: '',
        }, {
          lineBreak: <br />,
        }),
      ],
      priceMonth: priceM[0],
      priceYear: priceY[0],
      trialDaysM,
      trialDaysY,
      currencyCode,
      isRecommended: true,
      isCommingSoon: false,
      piper: piper.Rocket,
      isFree: false,
      decimalMonth: decimalM[0],
      decimalYear: decimalY[0],
      codePromo,
      applyPromo,
    },
    {
      color: colors.black3E,
      name: intl.formatMessage({
        id: 'newPricing.businessPlan.title',
        defaultMessage: 'Business Plan',
      }),
      description: intl.formatMessage({
        id: 'newPricing.plan.description.business',
        defaultMessage: 'For small business owners',
      }),
      role: roles.BUSINESS_MAIN,
      contents: [
        intl.formatMessage({
          id: 'newPricing.plan.business.content3',
          defaultMessage: '1 user per plan',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content1',
          defaultMessage: 'Schedule unlimited posts',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content6',
          defaultMessage: 'Schedule unlimited posts',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content2',
          defaultMessage: 'Unlimited ready-to-use {lineBreak}templates and designs',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content4',
          defaultMessage: '',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content5',
          defaultMessage: '',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content7',
          defaultMessage: '',
        }, {
          lineBreak: <br />,
        })
      ],
      priceMonth: priceM[1],
      priceYear: priceY[1],
      trialDaysM,
      trialDaysY,
      currencyCode,
      isRecommended: true,
      isCommingSoon: false,
      piper: piper.Rocket,
      isFree: false,
      decimalMonth: decimalM[1],
      decimalYear: decimalY[1],
      codePromo,
      applyPromo,
    }
    /* {
      color: colors.black3E,
      name: intl.formatMessage({
        id: 'newPricing.full.managment.title',
        defaultMessage: 'Team Plan',
      }),
      description: intl.formatMessage({
        id: 'newPricing.plan.description.business',
        defaultMessage: 'for business teams',
      }),
      role: roles.BUSINESS_MAIN,
      contents: [
        intl.formatMessage({
          id: 'newPricing.plan.business.content1',
          defaultMessage: 'Multiple users per plan',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content2',
          defaultMessage: 'Manage user permissions',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content3',
          defaultMessage: 'Manage user permissions',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content4',
          defaultMessage: 'Manage user permissions',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content5',
          defaultMessage: 'Manage user permissions',
        }, {
          lineBreak: <br />,
        }),
        intl.formatMessage({
          id: 'newPricing.plan.business.content6',
          defaultMessage: 'Manage user permissions',
        }, {
          lineBreak: <br />,
        }),
      ],
      priceMonth: 99,
      priceYear: 1118,
      currencyCode,
      isRecommended: false,
      isCommingSoon: false,
      piper: piper.PiperProfessor,
      isFree: false,
      decimalMonth: '',
      decimalYear: '',
      codePromo,
      applyPromo: false,
    }, */
  ];

  const renderPlanSlides = (slidePlans) => (
    slidePlans.map((sliderPlan, index) => (
      <PricingCard
      key={index}
      color={sliderPlan.color}
      name={sliderPlan.name}
      description={sliderPlan.description}
      contents={sliderPlan.contents}
      price={isMonthly ? sliderPlan.priceMonth : sliderPlan.priceYear}
      isMonthly={isMonthly}
      isRecommended={sliderPlan.isRecommended}
      isCommingSoon={sliderPlan.isCommingSoon}
      hasMorePricingDetails={hasMorePricingDetails}
      role={sliderPlan.role}
      isValidPromoCode={PromoCode.isValid}
      promoCode={PromoCode.code}
      currencyCode={sliderPlan.currencyCode}
      piper={sliderPlan.piper}
      isFree={sliderPlan.isFree}
      daysTrial={trialDaysM}
      landing={landing}
      decimal={isMonthly ? sliderPlan.decimalMonth : sliderPlan.decimalYear}
      codePromo={sliderPlan.codePromo}
      applyPromo={sliderPlan.applyPromo}
      setOpenFeaturesModal={setOpenFeaturesModal}
      setSelectedRole={setSelectedRole}
      setOpenContactFormModal={setOpenContactFormModal}
    />
    ))
  );

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Wrapper landing={landing}>
          {landing && (
            <Header>
              <Title>
                <FormattedMessage
                  id={
                    matches.lgPlusDownLess
                      ? 'landing.pricing.title.mobile'
                      : 'landing.pricing.title'
                  }
                  defaultMessage="Select a plan that works for your business"
                />
              </Title>
            </Header>
          )}
          <PricingContainer isLanding={landing}>
            {!landing && (
              <PricingSwitch>
                <PricingSwitchContainer>
                  <PricingPicker
                    onClick={() => {
                      setIsMonthly(true);
                      window.localStorage.setItem('isMonthly', 'true');
                    }}
                    active={isMonthly}
                    hasPadding
                  >
                    <PricingText>
                      <FormattedMessage id="k.monthly" defaultMessage="Monthly" />
                    </PricingText>
                  </PricingPicker>
                  <PricingPicker
                    onClick={() => {
                      setIsMonthly(false);
                      window.localStorage.setItem('isMonthly', 'false');
                    }}
                    active={!isMonthly}
                  >
                    <PricingText>
                      <FormattedMessage id="k.annually" defaultMessage="Annually" />
                    </PricingText>
                    <SubTitle>
                      <FormattedMessage
                        id="k.annually.save"
                        defaultMessage="Annually"
                      />
                    </SubTitle>
                  </PricingPicker>
                </PricingSwitchContainer>
              </PricingSwitch>
            )}
            <PricingCards landing={landing}>
              {renderPlanSlides(plans)}
            </PricingCards>
          </PricingContainer>
          {landing && (
            <ButtonContainer>
              <ButtonSecondary onClick={() => history.push(routes.PRICING)}>
                <FormattedMessage
                  id="newPricing.cta.button"
                  defaultMessage="Compare plans"
                />
              </ButtonSecondary>
            </ButtonContainer>
          )}
          {landing && matches.lgPlusUp && (
            <BadgeContainer>
              <Badge
                src={
                  intl.locale === 'es'
                    ? santanderBadge.desktopEs
                    : santanderBadge.desktop
                }
              />
            </BadgeContainer>
          )}
          <FeaturesPlanModal
            open={openFeaturesModal}
            setOpen={setOpenFeaturesModal}
            selectedRole={selectedRole}
          />
        </Wrapper>
      )}
    </Media>
  );
};

PricingSection.propTypes = {
  landing: PropTypes.bool,
};

PricingSection.defaultProps = {
  landing: false,
};

export default PricingSection;
