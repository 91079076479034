/* eslint-disable arrow-body-style */
import AuthFactory, { AuthInstance } from './index';
import UserProvider from '../entities/UserProvider';
import routes from '../../utils/constants/routes';
import {
  ENVIRONMENTS,
  ENV,
} from '../../utils/constants/globals';
import removeLanguageRegion from '../../utils/removeLanguageRegion';

const authResponsekeys = [
  'accessToken',
  'idToken',
  'expiresIn',
  'idTokenPayload',
  'isAuthPerformed',
];

const keysToDelete = [
  ...authResponsekeys,
  'authID',
  'expireTime',
];

const controlKey = 'isAuthPerformed';

const signupDataKey = 'signupData';

const showExpiredTimeModal = 'showExpiredTimeModal';

// WARNING! MAKE A INTERMEDIARY STEP
// FOR IMPROVE SECURITY

export default class AuthService {
  /**
   * This method connects with Auth0
   * service, make the authentication
   * and automatically go to /callback
   * route
   * @param {string} email
   * @param {string} password
   * Note: this method NEVER return, it goes
   * to the Callback route everytime
   */
  static login(email, password, isRemember) {
    window.localStorage.setItem('isRemember', isRemember);
    return new Promise((resolve, reject) => {
      AuthInstance.auth0.login({
        realm: 'Username-Password-Authentication',
        email,
        password,
      }, (err, results) => {
        if (err) reject(err);
        resolve(results);
      });
    });
  }

  static signup({
    email,
    password,
    firstName,
    lastName,
    wantsEmails,
    wantsFree,
    language,
    promocode,
    currencyCode,
    countryCode,
    mobile,
    isOptIn,
    planSelected
  }) {
    return new Promise((resolve, reject) => {
      this.setUserData({
        email,
        firstName,
        lastName,
        wantsEmails,
        wantsFree,
        language,
        promocode,
        currencyCode,
        countryCode,
        mobile,
        isOptIn,
        planSelected
      });
      const languageWithoutRegion = removeLanguageRegion(language);
      AuthInstance.auth0.signup({
        email,
        password,
        connection: 'Username-Password-Authentication',
        given_name: firstName,
        family_name: lastName,
        userMetadata: {
          language: languageWithoutRegion,
        },
      }, (err, result) => {
        if (err) {
          const errorMessage = err.policy ? err.policy : err.description;
          return reject(errorMessage);
        }
        return this.login(email, password, 'true')
          .then(() => resolve(result))
          .catch((error) => reject(error));
      });
    });
  }

  static logout(locationLO) {
    this.unsetAuthData();
    const returnTo = locationLO || `${window.location.origin}${routes.LANDING}`;
    AuthInstance.auth0.logout({
      returnTo,
    });
  }

  static initFacebook(randomString) {
    const urlAuth = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?`
      + `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID
      }&response_type=token%20id_token`
      + `&redirect_uri=${escape(process.env.REACT_APP_AUTH0_CALLBACK_URL)
      }&scope=profile%20email`
      + '&connection=facebook'
      + `&nonce=${randomString}`;
    window.location.href = urlAuth;
  }

  static initApple(randomString) {
    const urlAuth = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?`
      + `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID
      }&response_type=token%20id_token`
      + `&redirect_uri=${escape(process.env.REACT_APP_AUTH0_CALLBACK_URL)
      }&scope=name%20email`
      + '&connection=apple'
      + `&nonce=${randomString}`;
    window.location.href = urlAuth;
  }

  static authCheckSession(isAndroidFlow) {
    return new Promise((resolve) => {
      if (!isAndroidFlow) {
        AuthInstance.auth0.checkSession({}, (err, sessionResult) => {
          if (err) resolve({ session: false });
          resolve({ session: true, sessionResult });
        });
      } else {
        resolve({ session: true, sessionResult: {} });
      }
    });
  }

  /**
   * this method executes before a successful login
   */
  static afterAuth(settingLocation, androidPayload) {
    const data = {};
    AuthService.setPerformedAuth();
    return new Promise((resolve) => {
      if (!androidPayload) {
        AuthInstance.auth0.checkSession({}, (err, result) => {
          if (err) resolve(err);
          const type = result.idTokenPayload.sub.split('|')[0];
          let { email } = result.idTokenPayload;
          if (!email) {
            const authId = result.idTokenPayload.sub.split('|').pop();
            email = `${authId}@${type}auth.com`;
          }
          this.commonAuthFlow(result);
          data.wantsEmails = true;
          data.wantsFree = false;
          data.language = settingLocation.lang.toLowerCase();
          data.currencyCode = settingLocation.subsCurrency;
          data.countryCode = settingLocation.countryCode;
          data.type = type;
          resolve(data);
        });
      } else {
        this.commonAuthFlow(androidPayload, true);
        data.wantsEmails = true;
        data.wantsFree = false;
        data.language = settingLocation.lang.toLowerCase();
        data.currencyCode = settingLocation.subsCurrency;
        data.countryCode = settingLocation.countryCode;
        data.promoCode = '';
        data.type = 'facebook';
        data.mobile = '';
        data.isFacebookAcc = true;
        resolve(data);
      }
    });
  }

  /**
   * @deprecated
   * This method implemets the logic when a user
   * uses their facebook account
   * @param {Auth0Response} sessionResult
   */
  static async authWithFacebook(
    sessionResult, language, PromoCode, setOnboardingDetails, wantsFree, subsCurrency, countryCode,
  ) {
    this.setPerformedAuth();
    this.commonAuthFlow(sessionResult);
    const user = sessionResult.idTokenPayload;
    const authId = user.sub.split('|').pop();
    const type = sessionResult.idTokenPayload.sub.split('|')[0];
    window.localStorage.setItem('type', type);
    AuthFactory.initAuthAppSync(AuthService.getIdToken());
    const result = await UserProvider.fetchByEmail(user.email);
    if (!result.success) {
      // TODO: validate cross account login
      // await UserProvider.updateAuthIdbyUser(user.email, authId);
      if (!user.email) {
        user.email = `${authId}@facebookauth.com`;
      }
      const userData = {
        email: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
        wantsEmails: true,
        language,
        wantsFree,
      };
      if (PromoCode && PromoCode.code && PromoCode.isValid) userData.promocode = PromoCode.code;
      try {
        const plan = PromoCode.plans.find((item) => item.isMonthly === true);
        userData.currencyCode = plan.currencyCode;
        userData.countryCode = plan.countryCode;
      } catch (e) {
        userData.currencyCode = subsCurrency;
        userData.countryCode = countryCode;
      }
      const responseCreation = await UserProvider.create(userData, authId);
      if (responseCreation.success) {
        setOnboardingDetails((details) => ({
          ...details,
          requiredCard: responseCreation.data.requiredCard,
        }));
      }
      return Promise.resolve(routes.ONBOARDING);
    }
    return Promise.resolve(routes.DASHBOARD);
  }

  /**
   * @deprecated
   * This method implemets the logic when a user
   * uses their facebook account
   * @param {Auth0Response} sessionResult
   */
  static async authWithApple(
    sessionResult, language, PromoCode, setOnboardingDetails, wantsFree,
  ) {
    this.setPerformedAuth();
    this.commonAuthFlow(sessionResult);
    const user = sessionResult.idTokenPayload;
    const authId = user.sub.split('|').pop();
    AuthFactory.initAuthAppSync(AuthService.getIdToken());
    const result = await UserProvider.fetchByEmail(user.email);
    if (!result.success) {
      const userData = {
        email: user.email,
        firstName: user.name,
        lastName: user.nickname,
        wantsEmails: true,
        language,
        wantsFree,
        currencyCode: 'USD',
        countryCode: 'US',
      };
      if (PromoCode && PromoCode.code) userData.promocode = PromoCode.code;

      const responseCreation = await UserProvider.create(userData, authId);
      if (responseCreation.success) {
        setOnboardingDetails((details) => ({
          ...details,
          requiredCard: responseCreation.data.requiredCard,
        }));
      }
      return Promise.resolve(routes.ONBOARDING);
    }
    return Promise.resolve(routes.DASHBOARD);
  }

  static commonAuthFlow(result, isAndroidFlow) {
    if (!isAndroidFlow) {
      this.setAuthData(result);
      const authID = result.idTokenPayload.sub.split('|').pop();
      const expireTime = result.idTokenPayload.exp;
      window.localStorage.setItem('authID', authID);
      window.localStorage.setItem('expireTime', expireTime);
    } else {
      this.setAuthData(result);
      window.localStorage.setItem('expireTime', result.expiresIn);
    }
  }

  static setUserData(user) {
    window.localStorage.setItem(signupDataKey, JSON.stringify(user));
  }

  static getUserData() {
    let data = {};
    try {
      const singUpData = window.localStorage.getItem(signupDataKey);
      if (singUpData) {
        data = JSON.parse(singUpData);
      }
    } catch (error) {
      data = {};
    }

    return data;
  }

  static unsetUserData() {
    window.localStorage.removeItem(signupDataKey);
  }

  static setAuthData(result) {
    authResponsekeys.forEach((key) => {
      AuthInstance[key] = result[key];
      window.localStorage.setItem(key, result[key]);
    });
  }

  static unsetAuthData() {
    keysToDelete.forEach((key) => {
      window.localStorage.removeItem(key);
      try {
        if (key in AuthInstance) {
          delete AuthInstance[key];
        }
      // eslint-disable-next-line no-empty
      } catch (error) { }
    });
  }

  /**
   * this method set the flag in true if the user has perfomed
   * a successful auth flow with auth0
   */
  static setPerformedAuth() {
    window.localStorage.setItem(controlKey, 'true');
  }

  /**
   * this method returns true in the flag if the user has perfomed
   * a successful auth flow with auth0
   */
  static hasPerformedAuth() {
    return !!window.localStorage.getItem(controlKey);
  }

  /**
   * this function returns the expire time given by auth0 and
   * adds a 000 for the extra missing milliseconds
   */
  static getExpireTime() {
    return parseInt(`${window.localStorage.getItem('expireTime')}000`, 10);
  }

  static getAccessToken() {
    return window.localStorage.getItem('accessToken');
  }

  static getIdToken() {
    return window.localStorage.getItem('idToken');
  }

  static getAuthID() {
    return window.localStorage.getItem('authID');
  }

  static isAuthenticated() {
    if (ENV !== ENVIRONMENTS.local) {
      return this.hasPerformedAuth() && ((new Date().getTime()) < this.getExpireTime());
    }
    return true;
  }

  /**
   * this method set the flag in true if the user has perfomed
   * a successful auth flow with auth0
   */
  static setExpiredTimeModal() {
    window.localStorage.setItem(showExpiredTimeModal, true);
  }

  static unsetExpiredTimeModal() {
    window.localStorage.removeItem(showExpiredTimeModal);
  }

  static getExpiredTimeModal() {
    return window.localStorage.getItem(showExpiredTimeModal);
  }

  static isExpiredTime() {
    if (ENV !== ENVIRONMENTS.local) {
      if ((new Date().getTime()) < this.getExpireTime()) {
        return true;
      }
      this.setExpiredTimeModal();
      return false;
    }
    return true;
  }

  static getType() {
    return window.localStorage.getItem('type');
  }
}
