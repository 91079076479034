import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import GlobalTheme from '../../../styled/GlobalTheme';
import { colors } from '../../../styled/theme';
import { Button } from '../../../components/buttons';
import Pipers from '../../../components/Pipers/Pipers';

export const Modal = styled(MuiDialog)`
  &&{
    .MuiPaper-root{
      min-width: 446px;
      padding: ${({ theme }) => theme.space * 2}px ${({ theme }) => theme.space * 3}px;
      ${GlobalTheme.breakpoints.down('md')}{
        min-width: unset;
        width: 100%;
        max-width: 690px;
      }
      ${GlobalTheme.breakpoints.down('sm')}{
        background: ${colors.screenBackground};
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin: auto;
  margin: ${(props) => props.theme.space * 4}px auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: fit-content;
  }
`;

const Text = styled.div`
  margin: ${(props) => props.theme.space * 4}px 0;
`;

const Title = styled.h3`
  text-align: center;
  margin: 0;
  margin-bottom: ${(props) => props.theme.space}px;
`;

const Message = styled.p`
  font-size: 18px;
  text-align: center;
  margin: 0;
`;

const PiperWrapper = styled.div`
  max-width: 150px;
  margin: auto;
`;

export default function TransactionFailModal({
  open = false,
  className,
}) {
  return (
    <Modal
      open={open}
      className={className}
    >
      <Container>
        <PiperWrapper>
          <Pipers.Fail2 />
        </PiperWrapper>
        <Text>
          <Title>
            <FormattedMessage
              id="onboarding.mesaage.apple.transaction.fail"
              defaultMessage="Sorry, your transaction could not be completed, please validate your card information in the App Store"
            />
          </Title>
        </Text>
        
        <Button>
        <FormattedMessage
            id="k.ok"
            defaultMessage="OK"
        />
        </Button>
        
      </Container>
    </Modal>
  );
}

TransactionFailModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
};

TransactionFailModal.defaultProps = {
  className: '',
  open: false,
};
