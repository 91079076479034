const es = {
  'comparePlans.ceil1': 'Conecta Facebook, Instagram, Twitter y LinkedIn',
  'comparePlans.oneAccountPN': '1 cuenta por red',
  'comparePlans.1user': '1 usuario por cuenta',
  'comparePlans.multiAccount': 'Multi-cuenta',
  'comparePlans.multiuser': 'Multiples usuarios',
  'comparePlans.multiacc': 'Multiples cuentas',
  'comparePlans.ceil2': 'Usuarios por cuenta',
  'comparePlans.ceil3': 'Programa/publica tu contenido diario, semanal o mensual',
  'comparePlans.ceil2.1': 'Dashboard de métricas',
  'comparePlans.unlimited': 'Ilimitado',
  'comparePlans.ceil4': 'Plantillas creativas listas para publicar y/o editar',
  'comparePlans.ceil4.1': 'Creación de imágenes y textos con IA',
  'comparePlans.piperRecom': 'Solo los recomendados por Piper',
  'comparePlans.ceil5': 'Sigue la actividad en redes sociales de tu competencia',
  'comparePlans.onePerNetwork': '1 por cada red social',
  'comparePlans.ceil6': 'Calendario de gestión de las publicaciones de tu negocio',
  'comparePlans.ceil7': 'Recomendaciones de fechas y horas para publicar tu contenido ',
  'comparePlans.ceil8': 'Responde los comentarios/mensajes de tus clientes desde un solo lugar',
  'comparePlans.ceil9': 'Crea contenido impresionante para tu negocio con nuestro editor gráfico',
  'comparePlans.ceil10': 'Acceso al contenido educacional de Social Piper Academy',
  'comparePlans.ceil11': 'Webinars de soporte a usuarios',
  'comparePlans.ceil12': 'Gestiona la publicidad de tu negocios en redes sociales',
  'comparePlans.ceil13': 'Recomendaciones sobre inversión y alcance de tus campañas',
  'comparePlans.ceil14': 'Reportes del rendimiento de tus anuncios en tiempo real',
  'comparePlans.ceil15': 'Gestiona Social Piper desde la aplicación móvil',
  'comparePlans.ceil16': 'Programación directa en instagram ',
  'comparePlans.ceil17': 'Proceso de aprobaciones internas para manejar tu equipo',
  'comparePlans.ceil17.1': 'Proceso de aprobaciones internas antes de Publicar',
  'comparePlans.ceil17.2': 'Proceso de aprobaciones internas antes de crear campañas',
  'comparePlans.ceil18': 'Soporte Técnico 24/7',
  'comparePlans.ceil19.1': 'Elaboración del plan de trabajo y tu parrilla de contenido.',
  'comparePlans.ceil19.2': 'Elaboración de hasta 16 contenidos mensuales y su publicación en tus redes sociales conectadas a través de Social Piper.',
  'comparePlans.ceil19.3': 'Creación de campañas publicitarias basadas en tu presupuesto para llegar a más personas.',
  'comparePlans.ceil19.4': 'Revisión de resultados.',
  'comparePlans.ceil19.5': 'Responder a los comentarios de tu comunidad en tus redes sociales conectadas a Social Piper.',
  'comparePlans.helpCenter': 'Help Center, Chat e Email',
  'comparePlans.ceil20': 'Métodos de pago',
  'comparePlans.payments': 'Visa, Mastercard, AMEX, Discovery',
  'comparePlans.compare': 'Comparación de planes',
  'comparePlans.pro': 'Plan Individual',
  'comparePlans.basic': 'Servicio completo de gestión',
  'comparePlans.lite': 'Lite Plan',
  'comparePlans.AccTittle1': 'Crea Contenido',
  'comparePlans.AccTittle2': 'Programa',
  'comparePlans.AccTittle3': 'Campañas publicitarias',
  'comparePlans.AccTittle4': 'Ventajas extra',
  'comparePlans.AccTittle5': 'Soporte',
  'comparePlans.AccTittle6': 'Community manager en línea',
  'comparePlans.support.feature1': 'Soporte Técnico 24/7 - Help Center, Chat and Email',
};

export default es;
