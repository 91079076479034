import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { ButtonLink } from "../../../../../components/buttons";
import Select from "../../../../../components/globals/Select";
import { AlertsContext } from "../../../../../contexts/AlertsContext";
import { UserSettingsContext } from "../../../../../contexts/UserSettingsContext";
import useOpenAI from "../../../../../hooks/useOpenAI";
import { UserProvider } from "../../../../../services/entities";
import GlobalTheme from "../../../../../styled/GlobalTheme";
import {
  borderTop,
  flexBox,
  heading3,
  label,
  materialShadows,
  paragraph,
} from "../../../../../styled/mixins";

export const PreferencesCard = styled.div`
  ${materialShadows};
  ${borderTop("speechBubble")};
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  ${flexBox}
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.down("lg")} {
    padding: 16px;
  }
  ${GlobalTheme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.h3`
  ${heading3}
  margin-bottom: 24px;
  flex: 0 0 100%;
  ${GlobalTheme.breakpoints.down("md")} {
    align-self: flex-start;
  }
`;

const PreferenceControl = styled.div`
  flex: 0 0 calc(50% - 12px);
  margin-bottom: 24px;
  ${GlobalTheme.breakpoints.down("md")} {
    flex: 1;
    width: 100%;
  }
`;

const Label = styled.p`
  ${label}
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  ${paragraph}
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  flex: 0 0 100%;
  ${flexBox}
  justify-content: center;
`;

const Preferences = ({ className }) => {
  const intl = useIntl();
  const { locale, setLanguage, currencies, setCurrencies } =
    useContext(UserSettingsContext);
  const { refetch } = useOpenAI()
  const { showError } = useContext(AlertsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [lan, setLan] = useState(locale);
  const [selectedCurrency, setSelectedCurrency] = useState(1);
  const [currencyOptions, setCurrencyOptions] = useState([
    {
      value: 1,
      label: intl.formatMessage({
        id: "k.loading",
        defaultMessage: "k.loading",
      }),
      usdExchangeRate: 0.0,
    },
  ]);
  const languageOptions = [
    {
      value: "en",
      label: intl.formatMessage({
        id: "language.english",
        defaultMessage: "English",
      }),
    },
    {
      value: "es",
      label: intl.formatMessage({
        id: "language.spanish",
        defaultMessage: "Spanish",
      }),
    },
  ];

  const fetchCurrencyOptions = async () => {
    const response = await UserProvider.fetchCurrencyLookUp();
    if (response.success) {
      let languageSufix = lan ? lan.charAt(0).toUpperCase() + lan.slice(1) : "";
      languageSufix = languageSufix !== "En" ? languageSufix : "";
      const currenciesLu = response.data;
      const currenciesByLanguage = currenciesLu.map((currency) => ({
        value: currency.code,
        label: currency[`name${languageSufix}`],
        usdExchangeRate: currency.usdExchangeRate,
      }));
      setCurrencyOptions(currenciesByLanguage);
      const { settings } = currencies;
      if (settings?.currencyCode) {
        setSelectedCurrency(settings?.currencyCode);
      } else {
        setSelectedCurrency("USD");
      }
    }
  };
  useEffect(() => {
    if (currencies?.plan) {
      fetchCurrencyOptions();
    }
  }, [currencies]);

  const handleLanguageChange = (_, option) => {
    setLan(option.value);
  };

  const handlerCurrencyChange = async (_, option) => {
    const { value } = option;
    setSelectedCurrency(value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await UserProvider.updateUserSettings({ language: lan });
    const newCurrency = await UserProvider.updateCurrency(selectedCurrency);
    if (response.success && newCurrency.success) {
      setLanguage(lan);

      setCurrencies({
        ...currencies,
        settings: {
          code: newCurrency.data.currencyCode,
          usdExchangeRate: newCurrency.data.usdExchangeRate,
          currencyCode: newCurrency.data.currencyCode,
        },
      });

      // update ai config each press
      refetch()
    } else {
      showError(
        intl.formatMessage({
          id: "alert.error.edit",
          defaultMessage:
            "Your changes couldn't be saved. Please try again or contact support@socialpiper.com.",
        })
      );
    }
    setIsLoading(false);
  };

  return (
    <PreferencesCard className={className}>
      <Title>
        <FormattedMessage
          id="settings.personal.preferences.title"
          defaultMessage="Preferences"
        />
      </Title>
      <PreferenceControl>
        <Label>
          <FormattedMessage
            id="settings.personal.preferences.language"
            defaultMessage="Website language"
          />
        </Label>
        <Select
          id="lan"
          options={languageOptions}
          value={languageOptions.find((l) => l.value === lan)}
          onChange={handleLanguageChange}
        />
      </PreferenceControl>
      <PreferenceControl>
        <Label>
          <FormattedMessage id="k.currency" defaultMessage="Currency" />
        </Label>
        <Paragraph>
          <Select
            id="currency"
            options={currencyOptions}
            value={currencyOptions.find((c) => c.value === selectedCurrency)}
            onChange={handlerCurrencyChange}
          />
        </Paragraph>
      </PreferenceControl>

      <ButtonWrapper>
        <ButtonLink onClick={handleSave} isLoading={isLoading}>
          <FormattedMessage id="k.saveChanges" defaultMessage="Save Changes" />
        </ButtonLink>
      </ButtonWrapper>
    </PreferencesCard>
  );
};

Preferences.propTypes = {
  className: PropTypes.string,
};

Preferences.defaultProps = {
  className: "",
};

export default Preferences;