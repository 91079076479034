import * as Sentry from '@sentry/browser';
import {
  format,
  isSameMonth,
  subMonths,
  startOfDay,
} from 'date-fns';
import { authAppSync } from '../auth';
import { getAdCampaigns, getAdCampaignsMonths, getAdCampaignsMetrics } from '../../graphql/queries';
import { createAdCampaign, updateAdCampaigns, updateAdCampaignStatus } from '../../graphql/mutations';
import {
  ENV,
  ENVIRONMENTS,
  FACEBOOK,
} from '../../utils/constants/globals';
import adCampaignsMock from '../../mocks/adCampaigns/adCampaigns.mock';
import metricsMock from '../../mocks/adCampaigns/metrics.mock.json';
import { deleteCachedObject, deleteCachedQuery, updateCachedCompromisedBudget } from '../../utils/apollo';
import { AD_STATUS } from '../../views/BoostManager/constants';

export default class FacebookAdsProvider {
  static async fetchMonths(intl) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      const response = await authAppSync.client.query({
        query: getAdCampaignsMonths,
        variables: {
          socialNetwork: FACEBOOK,
        },
      });

      const responseData = response.data.getAdCampaignsMonths;
      const months = responseData.map((halfMonth) => {
        const dateHalfMonth = new Date(halfMonth);
        if (isSameMonth(new Date(), dateHalfMonth)) {
          return {
            value: halfMonth,
            label: intl.formatMessage({
              id: 'boostManager.postsTable.thisMonth',
              defaultMessage: 'This month',
            }),
          };
        }
        if (isSameMonth(subMonths(new Date(), 1), dateHalfMonth)) {
          return {
            value: halfMonth,
            label: intl.formatMessage({
              id: 'boostManager.postsTable.lastMonth',
              defaultMessage: 'Last month',
            }),
          };
        }
        return {
          value: halfMonth,
          label: intl.formatDate(halfMonth, {
            year: 'numeric',
            month: 'long',
          }),
        };
      });

      result = {
        message: 'success',
        success: true,
        data: months,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetch(day, by = 'month', removeDeleted = false, attr = '', removeFailed = false, removeProgress = false) {
    const date = startOfDay(new Date(day));
    const timezone = format(new Date(), 'XXX');
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: attr ? getAdCampaigns(attr) : getAdCampaigns(),
          variables: {
            date,
            timezone,
            by,
            removeDeleted,
            removeFailed,
            removeProgress,
          },
          fetchPolicy: 'no-cache',
        });

        data = response.data.getAdCampaignsV2;

        if (removeDeleted) {
          data = data.filter((ad) => (
            ad.effectiveStatus !== AD_STATUS.ARCHIVED
            && ad.effectiveStatus !== AD_STATUS.DELETED.toLowerCase()
          ));
        }
      } else {
        data = adCampaignsMock;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async create(boostInfo, postId) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      const {
        minStartDate,
        ...boostingSettings
      } = boostInfo;
      await authAppSync.client.mutate({
        mutation: createAdCampaign,
        variables: {
          boostingExistingPost: {
            boostingSettings,
            postId,
          },
        },
      });

      const { budget: adBudget } = boostInfo;

      updateCachedCompromisedBudget({ adBudget });

      deleteCachedObject('Boosting');
      deleteCachedQuery('getAdCampaignsV2');

      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async update(boostInfo) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.mutate({
          mutation: updateAdCampaigns,
          variables: {
            boostingUpdateSettings: {
              ...boostInfo,
            },
          },
        });
      } else {
        response = {
          data: {
            updateAdCampaigns: {
              startDateTime: '2020-08-05T18:00:00Z',
              endDateTime: '2020-08-12T18:00:00Z',
            },
          },
        };
      }

      result = {
        message: 'success',
        success: true,
        data: response.data.updateAdCampaigns,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateStatus(boostInfo) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.mutate({
          mutation: updateAdCampaignStatus,
          variables: {
            newAdCampaignStatus: {
              ...boostInfo,
            },
          },
        });
      } else {
        response = {
          data: {
            updateAdCampaignStatus: {
              effectiveStatus: 'paused',
            },
          },
        };
      }
      const { effectiveStatus } = response.data.updateAdCampaignStatus;

      result = {
        message: 'success',
        success: true,
        data: effectiveStatus,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchMetrics() {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    const date = startOfDay(new Date());
    const timezone = format(new Date(), 'XXX');
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getAdCampaignsMetrics,
          fetchPolicy: 'network-only',
          variables: {
            date,
            timezone,
          },
        });
      } else {
        response = metricsMock;
      }

      result = {
        message: 'success',
        success: true,
        data: response.data.getAdCampaignsMetrics,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
