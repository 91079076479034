import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { AlertsContext } from '../contexts/AlertsContext';
import { CompanyContext, businessInitialState } from '../contexts/CompanyContext';
import { SocialContext } from '../contexts/SocialContext';
import { SocialProfilesProvider, UserProvider } from '../services/entities';
import BusinessProvider from '../services/entities/BusinessProvider';
import { filterNullValues } from '../utils';
import { SEVERITY } from '../utils/constants/alerts';

const getConnectedAccounts = (sn) => {
  const accounts = filterNullValues(Object.values(sn));
  const activeAccounts = accounts.filter((x) => !!x.pageName);
  return activeAccounts;
};

export default () => {
  const { locale, formatMessage } = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [force, forceTrigger] = useReducer((x) => x + 1, 0);
  const {
    companyId,
    setCompanyId,
    companies,
    setCompanies,
    // all business info related
    businessInfo,
    setBusinessInfo,
  } = useContext(CompanyContext);
  const { socialNetworks, setSocialNetworks } = useContext(SocialContext);

  const { showAlert } = useContext(AlertsContext);
  const [allSocialNetworks, setAllSocialNetworks] = useState([]);
  const [isFetchingSocialNetworks, setIsFetchingSocialNetworks] = useState(false);
  const [hasConnectedAccounts, setHasConnectedAccounts] = useState(false);
  const [disableAddCompanyButton, setDisableAddCompanyButton] = useState(false);
  const hasExistingCompanyOnDraft = useMemo(
    () => companies.find((company) => company.draft === true),
    [companies],
  );
  const maxNumberOfCompaniesPerMasterUser = useMemo(
    () => companies.length === 10 ?? false,
    [companies],
  );

  // const databaseCompanyId = useMemo(() => user.currentCompanyId, [user])

  const qty = useMemo(() => companies.length, [companies]);
  const selected = useMemo(() => companies.find((c) => c.id == companyId), [companyId, companies]);

  useEffect(() => {
    const promise = companies.map((company) =>
      SocialProfilesProvider.fetchSocialProfilesInformation(company.id),
    );

    Promise.all(promise).then((res) => {
      const data = res.map((y, i) => ({ companyId: companies[i].id, ...y.data }));
      setAllSocialNetworks(data);
    });
  }, [companies]);

  useEffect(() => {
    (async () => {
      // if no id or is a draft company make nothing
      if (!companyId || !selected?.id) return;
      if ('draft' in selected && selected.draft) return;

      setIsFetchingSocialNetworks(true);

      try {
        const [businessPromise, socialNetworksPromise] = await Promise.allSettled([
          BusinessProvider.fetchBusinessInfo(locale, companyId),
          SocialProfilesProvider.fetchSocialProfilesInformation(companyId),
        ]);

        if (businessPromise.status === 'fulfilled') {
          const [information, categories] = businessPromise.value.data;
          let location = "";
          if (information && information.location) {
            location = information.location
            if (typeof location === 'string') {
              try {
                location = JSON.parse(location)
              } catch(error) {
                console.log(error)
                location = ""
              }
            }
          }
          setBusinessInfo({
            ...businessInfo,
            id: information?.id,
            companyName: information?.companyName,
            location: location,
            logoPreview: information?.logoUrl,
            logo: information?.logoUrl,
            category: categories,
          });
        }

        if (socialNetworksPromise.status === 'fulfilled') {
          const accounts = getConnectedAccounts(socialNetworksPromise.value.data);
          setHasConnectedAccounts(!!accounts.length);
          setSocialNetworks(socialNetworksPromise.value.data);
        }
      } finally {
        setIsFetchingSocialNetworks(false);
      }
    })();
  }, [companyId]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await getAllCompanies();
      } finally {
        setLoading(false);
      }
    })();
  }, [force]);

  const getAllCompanies = useCallback(async (preselectId = null) => {
    const { data, success } = await BusinessProvider.all();
    const { data: userData } = await UserProvider.fetch(`currentCompanyId`);
    const databaseCompanyId = userData?.currentCompanyId;
    const companies = data?.data?.companies;

    if (!success) return Promise.reject(null);

    const defaultId = databaseCompanyId ?? companies[0].id;
    let localCompanyId = localStorage.getItem('local:companyId');

    if (!localCompanyId) {
      localStorage.setItem('local:companyId', defaultId);
    }

    // WEIRD CASE! if another user fetch the companies and doesn't belong to him
    if (!companies.find((c) => c.id == localCompanyId)) {
      localStorage.removeItem('local:companyId');
      localCompanyId = null;
    }

    setCompanies(companies);

    if (preselectId) {
      setCompanyId(preselectId);
    } else {
      setCompanyId(localCompanyId ? Number(localCompanyId) : defaultId);
    }
  }, []);

  /** @type {() => Promise<void>} */
  const remove = async () => {
    try {
      const { success } = await BusinessProvider.deleteById(companyId);

      // new id, cause' you are deleting the company selected
      const nextCompanyId = companies[0].id === companyId ? companies[1].id : companies[0].id;
      setCompanyId(nextCompanyId);
      localStorage.setItem('local:companyId', nextCompanyId);
      if (success) history.go(0);
    } catch (error) {
      console.error('ERROR Trying to delete company deletion', error);
    }
  };

  const addDummy = () => {
    if (hasExistingCompanyOnDraft || maxNumberOfCompaniesPerMasterUser) {
      if (hasExistingCompanyOnDraft)
        showAlert(
          formatMessage(
            {
              id: 'settings.business.company.warning',
              defaultMessage: "Can't add more draft companies",
            },
            SEVERITY.WARNING,
          ),
        );

      if (maxNumberOfCompaniesPerMasterUser)
        showAlert(
          formatMessage(
            {
              id: 'settings.business.company.warning.maxNumber',
              defaultMessage: "Can't add more than 10 companies",
            },
            SEVERITY.WARNING,
          ),
        );
      setDisableAddCompanyButton(true);
      return;
    }
    const overriteValues = {
      id: String(Date.now()),
      companyName: locale === 'en' ? 'Draft' : 'Plantilla',
      location: '',
      category: [],
      draft: true,
    };

    setCompanies((c) => [
      ...c,
      {
        ...businessInitialState,
        ...overriteValues,
      },
    ]);

    setCompanyId(overriteValues.id);

    setBusinessInfo({
      ...businessInitialState,
      ...overriteValues,
    });
  };

  return {
    deleteCompany: remove,
    addDummyCompany: addDummy,
    quantityCompanies: qty,
    getAllCompanies,
    companies,
    companyId,
    loading,
    setCompanyId,
    socialNetworks,
    allSocialNetworks,
    hasConnectedAccounts,
    isFetchingSocialNetworks,
    selectedCompany: selected,
    infoState: [businessInfo, setBusinessInfo],
    hasExistingCompanyOnDraft,
    maxNumberOfCompaniesPerMasterUser,
    disableAddCompanyButton,
  };
};
