import styled from 'styled-components';
import { flexBox, label, paragraph } from '../../../styled/mixins';
import AdAccountsSelector from './AdAccountsSelector';
import { colors } from '../../../styled/theme';
import GlobalTheme from '../../../styled/GlobalTheme';
import { Button, ButtonLink } from '../../buttons';

export const AdAccountsContainer = styled.div`
  ${flexBox}
  padding: 0;
  width: 100%;
  max-width: 780px;
  grid-area: description;
  ${(props) => (!props.isModal && props.hasBackground) && `
    background: ${colors.screenBackground};
    padding: 0 24px 24px;
    ${GlobalTheme.breakpoints.down('sm')}{
      margin: 0 -16px;
      padding: 0 16px 16px;
      width: calc(100% + 32px);
    }
  `}
`;

export const Information = styled.div`
  ${flexBox}
  flex-direction: column;
  flex: 1;
  padding-top: ${({ paddingTop }) => (paddingTop || '0')};
`;

export const CurrentAdAccount = styled.div`
  display: flex;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-direction: column;
  }
`;

export const Label = styled.p`
  ${label}
  margin-right: 8px;
`;

export const Paragraph = styled.p`
  ${paragraph}
`;

export const StyledAdAccountsSelector = styled(AdAccountsSelector)`
  padding-top: 24px;
  width: 100%;
  ${GlobalTheme.breakpoints.down('sm')}{
    padding-top: 16px;
  }
  ${(props) => props.hasBackground && `
    background: ${colors.screenBackground};
    margin-top: 24px;
    padding: 24px;
    ${GlobalTheme.breakpoints.down('sm')}{
      padding: 16px;
      margin-top: 16px;
    }
  `}
`;

export const AdAccountsNote = styled.p`
  ${paragraph}
  font-size: 14px;
  color: ${colors.gray90};
  margin-top: 8px;
  max-width: 780px;
  grid-area: note;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin-bottom: 24px;
  }
`;

export const SupportLink = styled.a`
  text-decoration: none;
  font-style: italic;
  color: ${colors.gray90};
`;

export const ModalActions = styled.div`
  ${flexBox}
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-direction: column-reverse;
  }
`;

export const SaveButton = styled(Button)`
  && {
    margin-left: 32px;
    ${GlobalTheme.breakpoints.down('sm')}{
      width: 100%;
      margin: 0 0 16px;
    }
  }
`;

export const CancelButtonAction = styled(ButtonLink)`
  && {
    ${GlobalTheme.breakpoints.down('sm')}{
      font-size: 16px;
    }
  }
`;
