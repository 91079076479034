export const getTableValues = (intl) => [
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil2",
      defaultMessage: "Users per account",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.multiuser",
      defaultMessage: "Multi-User",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.1user",
      defaultMessage: "1 user per account",
    }),
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil1",
      defaultMessage: "Connect Facebook, Instagram, Twitter & LinkedIn",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.multiacc",
      defaultMessage: "Multi-Account",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.oneAccountPN",
      defaultMessage: "1 Account per network",
    }),
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil2.1",
      defaultMessage: "Metrics Dashboard",
    }),
    proValue: true,
    bizValue: true,
  },
];

const createContentValues = (intl) => [
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil4",
      defaultMessage: "Ready to use/edit social creative templates",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil4.1",
      defaultMessage: "Ready to use/edit social creative templates",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil9",
      defaultMessage:
        "Create awesome content for your business with our graphic editor",
    }),
    proValue: true,
    bizValue: true,
    liteValue: true,
    isComingSoon: false,
  },
];

const personalOnlineAdvisorValues = (intl) => [
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil19.1",
    }),
    proValue: true,
    bizValue: true,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil19.2",
    }),
    proValue: true,
    bizValue: true,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil19.3",
    }),
    proValue: true,
    bizValue: true,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil19.4",
    }),
    proValue: true,
    bizValue: true,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil19.5",
    }),
    proValue: true,
    bizValue: true,
  },
];

const scheduleValues = (intl) => [
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil3",
      defaultMessage: "Schedule/publish your content daily, weekly, or monthly",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
    liteValue: 10,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil6",
      defaultMessage: "Your Business social media posts calendar management",
    }),
    proValue: true,
    bizValue: true,
    isComingSoon: false,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil7",
      defaultMessage: "Recommendations for your best date and time to publish",
    }),
    proValue: true,
    bizValue: true,
    isComingSoon: false,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil5",
      defaultMessage: "Watch your competitor’s social media activity",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.unlimited",
      defaultMessage: "Unlimited",
    }),
  },
];

const socialAdsValues = (intl) => [
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil12",
      defaultMessage: "Manage your business’ Social Media Ads",
    }),
    proValue: true,
    bizValue: true,
    liteValue: false,
    isComingSoon: false,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil13",
      defaultMessage: "Recomendations for boosting to maximize your campaigns",
    }),
    proValue: true,
    bizValue: true,
    liteValue: false,
    isComingSoon: false,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil14",
      defaultMessage: "Social Ads reports in real time",
    }),
    proValue: true,
    bizValue: true,
    liteValue: false,
    isComingSoon: false,
  },
];

const extraPerksValues = (intl) => [
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil10",
      defaultMessage:
        "Access the educational content in our Social Piper Academy",
    }),
    proValue: true,
    bizValue: true,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil11",
      defaultMessage: "Customer Success Webinars",
    }),
    proValue: true,
    bizValue: true,
    liteValue: false,
    isComingSoon: false,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil15",
      defaultMessage: "Manage Social Piper from the mobile app",
    }),
    proValue: true,
    bizValue: true,
    liteValue: false,
    isComingSoon: false,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil8",
      defaultMessage:
        "Reply to your clients comments/direct messages from one place",
    }),
    proValue: true,
    bizValue: true,
    liteValue: true,
    isComingSoon: false,
  },
];

export const getExtraFinalValues = (intl) => [
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil17",
      defaultMessage:
        "Approval workflow to get everyone on board before publishing/advertising",
    }),
    proValue: true,
    bizValue: false,
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil18",
      defaultMessage: "Technical Support 24/7",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.helpCenter",
      defaultMessage: "Help Center, Chat and Email",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.helpCenter",
      defaultMessage: "Help Center, Chat and Email",
    }),
  },
  {
    title: intl.formatMessage({
      id: "comparePlans.ceil20",
      defaultMessage: "Payment Methods",
    }),
    proValue: intl.formatMessage({
      id: "comparePlans.payments",
      defaultMessage: "Visa, Mastercard, AMEX, Discovery",
    }),
    bizValue: intl.formatMessage({
      id: "comparePlans.payments",
      defaultMessage: "Visa, Mastercard, AMEX, Discovery",
    }),
  },
];

export const getAccordionsValues = (intl) => [
  /*   {
    accTitle: intl.formatMessage({
      id: "comparePlans.AccTittle6",
      defaultMessage: "Create Content",
    }),
    content: personalOnlineAdvisorValues(intl),
  }, */
  {
    accTitle: intl.formatMessage({
      id: "comparePlans.AccTittle1",
      defaultMessage: "Create Content",
    }),
    content: createContentValues(intl),
  },
  {
    accTitle: intl.formatMessage({
      id: "comparePlans.AccTittle2",
      defaultMessage: "Schedule",
    }),
    content: scheduleValues(intl),
  },
  {
    accTitle: intl.formatMessage({
      id: "comparePlans.AccTittle3",
      defaultMessage: "Social Advertising",
    }),
    content: socialAdsValues(intl),
  },
  {
    accTitle: intl.formatMessage({
      id: "comparePlans.AccTittle4",
      defaultMessage: "Extra Perks",
    }),
    content: extraPerksValues(intl),
  },
];
