// @ts-check

export const MissingConfig = Object.freeze({
  None: -1,
  NoAppsConnected: 0,
  NoCompanyInfo: 1,
});

export const GenerationType = Object.freeze({
  Generate: 'GENERATE',
  Regenerate: 'REGENERATE',
});

export const isLengthZero = (iterable) => !iterable || iterable.length === 0;

export const parseGender = (genderId) => {
  switch (genderId) {
    case 3:
      return 'All';
    case 2:
      return 'Males';
    case 1:
      return 'Females';
    default:
      return '';
  }
};

export const appsConnectedParser = (raw) =>
  Object.entries(raw)
    .map(([socialNetwork, data]) =>
      data.pageName ? socialNetwork[0].toUpperCase() + socialNetwork.slice(1).toLowerCase() : null,
    )
    .filter((socialNetwork) => socialNetwork !== null);
