import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import PiperTip from '../../PiperTips/PiperTip';
import Pipers from '../../Pipers/Pipers';
import { Select } from '../../globals';
import FormController from '../../globals/FormController';
import { label } from '../../../styled/mixins';
import { Button } from '../../buttons';
import { colors } from '../../../styled/theme';
import { CloseOutlineIcon } from '../../../assets/icons/iconRepository';
import GlobalTheme from '../../../styled/GlobalTheme';
import mediaQueries from '../../../utils/mediaQueries';
import { availableCurrencies } from './constants';
import SocialProfilesProvider from '../../../services/entities/SocialProfilesProvider';
import responseFactory from '../../../utils/responseFactory';

const ModalContainer = styled.div`
  background-color: white;
  left: 50%;
  max-width: 534px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 70px 0 30px;
  height: 100%;
  overflow: scroll;
  ${GlobalTheme.breakpoints.up('sm')} {
    height: auto;
  }
`;

const PiperTipWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 257px;
  & .tip {
    padding: 0;
  }
  & .piper-tip {
    grid-template-rows: auto auto;
  }
  ${GlobalTheme.breakpoints.up('sm')} {
    max-width: 346px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 0 5%;

  ${GlobalTheme.breakpoints.up('sm')} {
    padding: 0 17%;
  }
`;

const InputGroup = styled.div`
  width: 100%;
`;

const Label = styled.label`
  ${label};
`;

const CurrencySuggestion = styled.p`
  font-size: 14px;
  color: ${colors.gray02};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 4px;
`;
const CloseIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const CreateAdAccountModal = ({ onCloseModal, setOpenStepsPaymentModal }) => {
  const [adAccountName, setAdAccountName] = useState('');
  const [currencie, setCurrencie] = useState('USD');
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const createAdAccount = async () => {
    const response = await SocialProfilesProvider.createLinkedinAdAccount(adAccountName, currencie);
    if (response.success) {
      setIsLoading(false);
      onCloseModal();
      setOpenStepsPaymentModal(true);
    }
  };

  const handleCreateAdAccount = () => {
    setIsLoading(true);
    createAdAccount();
  };

  const handleCurrencieChange = (_, option) => {
    setCurrencie(option.value);
  };

  const handleAdAccountName = (e) => setAdAccountName(e.target.value);

  return (
    <ModalContainer>
      <CloseButtonWrapper onClick={onCloseModal}>
        <CloseIcon src={CloseOutlineIcon} alt="Close" />
      </CloseButtonWrapper>
      <PiperTipWrapper>
        <Media queries={mediaQueries}>
          {(matches) => (matches.sm ? (
            <PiperTip
              position="top"
              piperWidth="100px"
              trianglePosition="right"
              Piper={Pipers.Piper2}
            >
              <FormattedMessage
                id="socialProfiles.linkedIn.createAdAccount.Modal.Tip"
                defaultMessage="Let´s create your Linkedin ad account."
                values={{ lineBreak: <br /> }}
              />
            </PiperTip>
          ) : (
            <PiperTip
              position="right"
              piperWidth="100px"
              trianglePosition="bottom"
              Piper={Pipers.Piper2}
            >
              <FormattedMessage
                id="socialProfiles.linkedIn.createAdAccount.Modal.Tip"
                defaultMessage="Let´s create your Linkedin ad account."
                values={{ lineBreak: <br /> }}
              />
            </PiperTip>
          ))}
        </Media>
      </PiperTipWrapper>
      <Content>
        <FormController
          id="adAccountName"
          onChange={handleAdAccountName}
          label={intl.formatMessage({
            id: 'socialProfiles.linkedIn.createAdAccount.name',
            defaultMessage: 'Name for your Linkedin Ad account:',
          })}
        />
        <InputGroup>
          <Label>
            <FormattedMessage
              id="socialProfiles.linkedIn.createAdAccount.currency"
              defaultMessage="Select the currency for you Linkedin ad account:"
            />
          </Label>
          <Select
            id="currency"
            options={availableCurrencies}
            value={availableCurrencies.find((curr) => curr.value === currencie)}
            onChange={handleCurrencieChange}
          />
        </InputGroup>
        <CurrencySuggestion>
          <FormattedMessage
            id="socialProfiles.linkedIn.createAdAccount.Modal.helpCurrency"
            defaultMessage="For better management, use the same type of currency for all your ad accounts"
          />
        </CurrencySuggestion>
      </Content>
      <Footer>
        <Button
          isLoading={isLoading}
          onClick={handleCreateAdAccount}
        >
          <FormattedMessage id="k.continue" defaultMessage="Continue" />
        </Button>
      </Footer>
    </ModalContainer>
  );
};

export default CreateAdAccountModal;
