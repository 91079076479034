import ROUTES from '../utils/constants/routes';
import lazyWithPreload from './utils/lazyWithPreload';
import { POSTFLOW_ROUTES_FOR_ROUTER } from '../views/postFlow/utils/constants';
import { ONBOARDING_ROUTES } from '../views/onBoarding/constants';

const AuthComponents = {
  VerifyEmail: lazyWithPreload(() => import('../views/VerifyEmail' /* webpackChunkName: "verify-email" */)),
  OnBoarding: lazyWithPreload(() => import('../views/onBoarding' /* webpackChunkName: "onboarding" */)),
  OnBoardingLauncher: lazyWithPreload(() => import('../views/onBoarding/OnBoardingLauncher/index' /* webpackChunkName: "onboarding" */)),
  PostFlow: lazyWithPreload(() => import('../views/postFlow' /* webpackChunkName: "postflow" */)),
  Dashboard: lazyWithPreload(() => import('../views/dashboard' /* webpackChunkName: "dashboard" */)),
  Scheduler: lazyWithPreload(() => import('../views/Scheduler' /* webpackChunkName: "scheduler" */)),
  BoostManager: lazyWithPreload(() => import('../views/BoostManager' /* webpackChunkName: "boost-manager" */)),
  CommentsSection: lazyWithPreload(() => import('../views/Community/PostComments/PostCommentsSection' /* webpackChunkName: "community-comments" */)),
  InboxSection: lazyWithPreload(() => import('../views/Community/Inbox/InboxSection' /* webpackChunkName: "community-inbox" */)),
  MediaLibrary: lazyWithPreload(() => import('../views/MediaLibrary' /* webpackChunkName: "media-library" */)),
  Settings: lazyWithPreload(() => import('../views/Settings' /* webpackChunkName: "settings" */)),
  Logout: lazyWithPreload(() => import('../views/auth/Logout' /* webpackChunkName: "logout" */)),
  SocialLoginError: lazyWithPreload(() => import('../views/SocialLogin/Error' /* webpackChunkName: "social-login-error" */)),
  SocialLoginSuccess: lazyWithPreload(() => import('../views/SocialLogin/Success' /* webpackChunkName: "social-login-success" */)),
  Promo9: lazyWithPreload(() => import('../views/Promo/Promocion9' /* webpackChunkName: "promocion9" */)),
  Notification: lazyWithPreload(() => import('../views/Notification' /* webpackChunkName: "notifications" */)),
  NotificationTiktok: lazyWithPreload(() => import('../views/NotificationTiktok' /* webpackChunkName: "notifications-tiktok" */)),
  AboutApp: lazyWithPreload(() => import('../views/AboutApp' /* webpackChunkName: "about-app" */)),
  QuickPost: lazyWithPreload(() => import('../views/postFlowV2/QuickPostView' /* webpackChunkName: "quickpost" */)),
  ScheduleMeeting: lazyWithPreload(() => import('../views/ScheduleMeeting' /* webpackChunkName: "schedule-meeting" */)),
  Metrics: lazyWithPreload(() => import('../views/Metrics' /* webpackChunkName: "metrics" */)),
  BusinessOnBoarding: lazyWithPreload(() => import('../business/views/BusinessOnboarding')),
  Approvals: lazyWithPreload(() => import('../views/Approvals'))
};

export const preloadAuthenticatedComponents = () => {
  Object.keys(AuthComponents).forEach((component) => {
    AuthComponents[component].preload();
  });
};

const routes = [
  {
    layout: ROUTES.DASHBOARD,
    path: '',
    title: 'Dashboard',
    component: AuthComponents.Dashboard,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.BOOST_MANAGER,
    title: 'Boost Manager',
    component: AuthComponents.BoostManager,
    props: {},
    exact: true,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: ROUTES.COMMUNITY,
    path: '/post',
    title: 'Comment',
    component: AuthComponents.CommentsSection,
    props: {},
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: ROUTES.COMMUNITY,
    path: '/inbox',
    title: 'Inbox',
    component: AuthComponents.InboxSection,
    props: {},
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.MEDIA_LIBRARY,
    title: 'Creative Center',
    component: AuthComponents.MediaLibrary,
    props: {},
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.SETTINGS,
    title: 'Settings',
    component: AuthComponents.Settings,
    props: {},
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.LOGOUT,
    title: 'Logout',
    component: AuthComponents.Logout,
    props: {},
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.ONBOARDING,
    title: 'About your Business',
    component: AuthComponents.OnBoarding,
    props: {},
    exact: true,
    menu: false,
    color: 'inherit',
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.BUSINESS_ONBOARDING,
    title: 'On Bording',
    component: AuthComponents.BusinessOnBoarding,
    props: {},
    exact: true,
    menu: true,
    color: 'inherit',
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.VERIFY_EMAIL,
    title: 'Verify Email',
    component: AuthComponents.VerifyEmail,
    props: {},
    exact: true,
    menu: false,
    color: 'inherit',
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ONBOARDING_ROUTES.launcher,
    title: 'Onboarding Launcher',
    component: AuthComponents.OnBoardingLauncher,
    props: {},
    exact: true,
    menu: false,
    color: 'inherit',
    sidebar: false,
    footer: false,
  },
  {
    layout: '/postflow',
    path: POSTFLOW_ROUTES_FOR_ROUTER.select,
    title: 'Pick an Image',
    component: AuthComponents.PostFlow,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/postflow',
    path: POSTFLOW_ROUTES_FOR_ROUTER.create,
    title: 'Create Post',
    component: AuthComponents.PostFlow,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/postflow',
    path: POSTFLOW_ROUTES_FOR_ROUTER.editor,
    title: 'Editor',
    component: AuthComponents.PostFlow,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/postflow',
    path: POSTFLOW_ROUTES_FOR_ROUTER.schedule,
    title: 'Schedule Post',
    component: AuthComponents.PostFlow,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: '/social-login-error',
    title: 'Social login error',
    component: AuthComponents.SocialLoginError,
    props: {},
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: '/social-login-success',
    title: 'Social login success',
    component: AuthComponents.SocialLoginSuccess,
    props: {},
    exact: false,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.SCHEDULER,
    title: 'Scheduler',
    component: AuthComponents.Scheduler,
    props: {},
    exact: true,
    menu: true,
    color: 'inherit',
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.PROMO_9,
    title: 'Promoción',
    component: AuthComponents.Promo9,
    props: {},
    exact: true,
    menu: true,
    color: 'inherit',
    sidebar: false,
    footer: false,
  },
  {
    layout: '/notifications/instagram',
    path: '',
    title: '',
    component: AuthComponents.Notification,
    props: {},
    exact: false,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/notifications/tiktok',
    path: '',
    title: '',
    component: AuthComponents.NotificationTiktok,
    props: {},
    exact: false,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.ABOUTAPP,
    title: 'About',
    component: AuthComponents.AboutApp,
    props: {},
    exact: false,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.QUICKPOST,
    title: 'QuickPost',
    component: AuthComponents.QuickPost,
    props: {},
    exact: false,
    menu: true,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.SCHEDULE_MEETING,
    title: 'Schedule Consultation',
    component: AuthComponents.ScheduleMeeting,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: ROUTES.METRICS,
    path: '',
    title: 'Metrics',
    component: AuthComponents.Metrics,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
  {
    layout: '/',
    path: ROUTES.APPROVALS,
    title: 'Approvals',
    component: AuthComponents.Approvals,
    props: {},
    exact: true,
    menu: false,
    sidebar: false,
    footer: false,
  },
];

export default routes;
