import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import { Button } from '../../../../../components/buttons';
import {
  PiperBlockSocial,
} from '../../../../../assets/icons/iconRepository';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import mediaQueries from '../../../../../utils/mediaQueries';

const ContainerPendingInformation = styled.div`
  display: ${({ shown }) => (shown ? 'flex' : 'none')};
  position: absolute;
  z-index: 100;
  background-color: rgba(255,255,255, 0.7);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const CardBlock = styled.div`
  width: 625px;
  height: 200px;
  background-color: #FF7E4E;
  margin: auto;
  border-radius: 5px;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 90%;
    height: 350px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CardPiper = styled.div`
  position: relative;
  width: 30%;
  height: 115px;
  background-image: url("${PiperBlockSocial}");
  background-size: 68%;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
  margin-top: 60px;
  display: ${({ shown }) => (shown ? 'flex' : 'none')};
  ${GlobalTheme.breakpoints.down('md')}{
    background-size: 90%;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    background-size: 40%;
    width: 100%;
    height: 120px;
    margin-top: 0;
  }
  @media (min-width: 560px) and (max-width: 570px) and (orientation: landscape){
    background-size: 26%;
    z-index: 10;
  }
`;

const CardInformation = styled.div`
  width: 70%;
  height: 275px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
    height: 350px;
  }
`;

const CardTex = styled.div`
  width: 382px;
  background: white;
  height: 130px;
  margin: 10px auto 0 auto;
  border-radius: 5px;
  padding: 15px 20px 0 20px;
  z-index: 2;

  ${GlobalTheme.breakpoints.down('lg')}{
    width: 95%;
    margin-right: 5%;
  }

  ${GlobalTheme.breakpoints.down('sm')}{
    width: 90%;
    height: 120px;
    margin-top: 25px;
    font-size: 12px;
  }

  @media (min-width: 560px) and (max-width: 570px) and (orientation: landscape){
    height: 99px;
  }

  @media (max-width: 408px) {
    padding: 10px 20px;
  }
`;

const CardTexTitle = styled.div`
  font-weight: bold;
  font-size: 20px;

  ${GlobalTheme.breakpoints.down('md')} {
    font-size: 14px;
  }

  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 15px;
  }

  @media (max-width: 408px) {
    font-size: 12px;
  }
`;

const CardTexMessage = styled.div`
  font-size: 16px;

  ${GlobalTheme.breakpoints.down('md')} {
    font-size: 12px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 12px;
  }
`;

const CardButton = styled.div`
  text-align: center;
  z-index: 2;
`;

const ButtonAction = styled(Button)`
  && {
    margin-top: 15px;
    margin-bottom: 30px;
    background: white;
    color: #027686;

    :hover{
      color: white;
    }
  }
`;

const WaveBottom = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: 75px;
  right: 0;
  width: 125%;
  height: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 1;

  ${GlobalTheme.breakpoints.down('sm')} {
    width: 100%;
    right: -1px;
    bottom: 0px;
  }

  svg {
    position: relative;
    display: block;
    height: fit-content;
    width: 100%;
    transform: scale(1);
    @media (min-width: 408px) {
      transform: scale(1);
    }
    ${GlobalTheme.breakpoints.up('sm')} {
      transform: scale(1);
    }
    ${GlobalTheme.breakpoints.up('md')} {
      transform: ${({ isMobile }) => (isMobile ? 'scale(2)' : 'scale(1)')};
      width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
      bottom: ${({ isMobile }) => (isMobile ? '0' : '-15px')};
    }
    ${GlobalTheme.breakpoints.up('lg')} {
      bottom: -1px;
      transform: ${({ isMobile }) => (isMobile ? 'scale(2)' : 'scale(2)')};
    }
    ${GlobalTheme.breakpoints.down('sm')} {
      height: auto;
    }
  }
`;

const TrianglePiper = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 0;
  position: absolute;
  border-width: 0 56px 56px 0;
  border-color: transparent white transparent transparent;
  top: -48px;
  left: 180px;
  transform: rotate(90deg);
  ${GlobalTheme.breakpoints.down('lg')} {
    left: 150px;
  }
  ${GlobalTheme.breakpoints.down('md')} {
    left: 110px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    top: -30px;
    left: 70px;
    transform: rotate(0deg);
  }
`;

const CardPersonalBlock = ({ showBox, setShowBox }) => (

  <Media queries={mediaQueries}>
    {(matches) => (
      <ContainerPendingInformation shown={showBox}>
        <CardBlock>
          <CardContainer>
            <CardPiper shown={!matches.smDown}>
              <TrianglePiper />
            </CardPiper>
            <CardInformation>
              <CardTex>
                <CardTexTitle>
                  <FormattedMessage
                    id="settings.personal.card.block.title"
                    defaultMessage="Let's keep in touch!"
                  />
                </CardTexTitle>
                <CardTexMessage>
                  <FormattedMessage
                    id="settings.personal.card.block.message"
                    defaultMessage="Add your name and a contact email to receive important information and notifications."
                  />
                </CardTexMessage>
              </CardTex>
              <CardPiper shown={matches.smDown}>
                <TrianglePiper />
              </CardPiper>
              <CardButton>
                <ButtonAction onClick={() => setShowBox(false)}>
                  <FormattedMessage
                    id="settings.personal.card.block.button"
                    defaultMessage="Add"
                  />
                </ButtonAction>
              </CardButton>

              <WaveBottom isMobile={false}>
                <svg width="400" height="100" viewBox="0 0 794 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M235.5 70.0381C435 -3.96192 794 0.0380736 794 0.0380736V199.038H0C0 199.038 36 144.038 235.5 70.0381Z" fill="#027686" />
                </svg>
              </WaveBottom>
            </CardInformation>
          </CardContainer>
        </CardBlock>
      </ContainerPendingInformation>
    )}
  </Media>
);

CardPersonalBlock.propTypes = {
  showBox: PropTypes.bool,
  setShowBox: PropTypes.func,
};

CardPersonalBlock.defaultProps = {
  showBox: false,
  setShowBox: () => { },
};

export default CardPersonalBlock;
