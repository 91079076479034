import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Elements, StripeProvider } from 'react-stripe-elements';
import styled from 'styled-components';

import { useHistory } from "react-router-dom"
 
import { text } from '../../components/text';
import { AlertsContext } from '../../contexts/AlertsContext';
import useCollaborators from '../../hooks/useCollaborators';
import useCompany from '../../hooks/useCompany';
import useOnboardingSteps from '../../hooks/useOnboardingSteps';
import GlobalTheme from '../../styled/GlobalTheme';
import PaymentComponent from '../PaymentComponent';
import NavBar from '../onBoarding/components/Navbar';

import {
  ConnectedTinyLogos,
  ContinueButton,
  Hero,
  StandDogVector,
  StepBanner,
  StepBannersWrapper,
  Title,
  TitleDivisor,
} from './index.styled';
import BusinessProvider from '../../services/entities/BusinessProvider';
import { LayoutContext, Layout } from '../../contexts/LayoutContext';
import { SocialContext } from '../../contexts/SocialContext';
import routes from '../../utils/constants/routes';
import RocketLoader from '../../components/globals/RocketLoader';
import { UserProvider } from '../../services/entities';
import { UserSettingsContext } from '../../contexts/UserSettingsContext';

const BusinesOnBoarding = () => {
  const { infoState: [businessInfo] } = useCompany();
  const history = useHistory()

  const { setBusinessImage } = useContext(LayoutContext)
  const alerts = useContext(AlertsContext);
  const { socialNetworks, fetchSocialNetworksData } = useContext(SocialContext);
  const { setOnboardingDetails, onboardingDetails } = useContext(UserSettingsContext)

  const { steps, checkNextStep, modalWrapper } = useOnboardingSteps();
  const { collaborators } = useCollaborators();
  const [screen, setScreen] = useState('onboarding');
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl();

  const fixsubtitle = useCallback(
    (stepId) =>
      [
        businessInfo.companyName || '',
        <ConnectedTinyLogos accounts={['facebook', 'instagram', 'linkedin', 'twitter']} checked={getSocialMediaPresence(socialNetworks)} />,
        text('onboarding.biz.step3.subtitle'),
        !collaborators.length
          ? text('onboarding.biz.step4.subtitle')
          : text('onboarding.biz.step4.subtitle.2', {
              q: collaborators.length,
            }),
      ][stepId - 1],
    [businessInfo.companyName, collaborators],
  );

  const can = useMemo(
    () => ({ continue: steps.filter((step) => !step.skippable).every((step) => step.finished) }),
    [steps],
  );

  const saveCompanyInfo = async () => {
    // const logoUrl = 'https://dev-5d8c82da-socialpiper-cdn.s3.amazonaws.com/logos/6596e2ff34f1c95b45e42e8d/4f659210-cfde-11ee-b1f8-a18d0c117d95.png'
    setIsLoading(true)
    let logoUrl = businessInfo.logoUrl
    if (!businessInfo.logoUrl) {
      logoUrl = await BusinessProvider.uploadLogo(businessInfo)
      logoUrl = logoUrl.data
    }
    const infoResponse = await BusinessProvider.update({ ...businessInfo }, logoUrl)
    if (infoResponse.success) {
      setBusinessImage(logoUrl)
    }
    setIsLoading(false)
  }

  const handleContinue = async () => {
    await UserProvider.updateUserSettings({ onboardingFinished: true });
    setOnboardingDetails({
      ...onboardingDetails,
      finished: true,
      showLauncher: false,
    });
    history.push(routes.DASHBOARD)
  }

  const { facebook, instagram, twitter, linkedin } = socialNetworks
  const condition  = facebook.pageName.trim() || linkedin.pageName.trim()

  const handleModalSaveClick = (n) => async (toggler) => {
    const next = async () => {
      if (n == 2) {
        if (condition) {
          checkNextStep(n)
        } else {
          checkNextStep(n)
          localStorage.setItem("currentStep", n + 1);
          await UserProvider.updateUserSettings({ nextOnboardingStep: n + 1 });
          checkNextStep(n+1)
        }
      } else {
        checkNextStep(n)
      }
    };

    switch (n) {
      case 1: // business
        if (businessInfo.companyName.length) {
          await UserProvider.updateUserSettings({ nextOnboardingStep: 2 });
          await saveCompanyInfo()
          localStorage.setItem("currentStep", 2);
          toggler();
          next();
        } else {
          alerts.showError(
            intl.formatMessage({ id: 'onboarding.biz.step1.errorForm.companyName' }),
          );
        }
        return;
      case 2: // social networks\
        await UserProvider.updateUserSettings({ nextOnboardingStep: 3 });
        localStorage.setItem("currentStep", 3);
        toggler();
        next();
        return;
      case 3: // primary target
        await UserProvider.updateUserSettings({ nextOnboardingStep: 4 });
        localStorage.setItem("currentStep", 4);
        toggler();
        next();
        return;
      case 4: // collaborators
        toggler();
        next();
        return;
      default: // X
        throw 'Unhandled case';
    }
  };

  const handleExtraSubscribeEvent = () => {
    setScreen('onboarding');
  };

  useEffect(() => {
    fetchSocialNetworksData()
    const step = onboardingDetails.nextStep
    if (step > 1) {
      for(let i = 1; i < step; i++) {
        checkNextStep(i || 1)
      }
    }
  }, [])

  useEffect(() => {
    let isSuccessful = window.localStorage.getItem('socialNetSuccessfull')
    switch (isSuccessful) {
      case 'reaload':
        window.localStorage.setItem("socialNetSuccessfull", 'click') 
        return  setTimeout(() => {
          return window.location.reload()
        }, 500)
      case 'click':
        window.localStorage.removeItem("socialNetSuccessfull")
        return setTimeout(() => {
          const button = document.querySelector('#mbutton-2');
          button.click();
        }, 1000)
      default: return
    }
  })

  if (socialNetworks.isInitial) return <RocketLoader />

  return (
    <Hero>
      {screen === 'onboarding' && (
        <>
          <TitleDivisor>
            <StandDogVector />
            <Title>{text('onboarding.biz.title')}</Title>
          </TitleDivisor>

          <StepBannersWrapper>
            {steps.map((step) => (
              <StepBanner
                step={step.id}
                disabled={(step.id === 3 && !condition) ? true : !step.enabled}
                title={text(step.title)}
                button={text(step.button)}
                isFinished={step.finished}
                modalTitle={text(step['modal.title'], {
                  businessName: businessInfo.companyName,
                })}
                subtitle={fixsubtitle(step.id)}
                isLoading={isLoading}
                handleModalSaveClick={handleModalSaveClick(step.id)}
              >
                {modalWrapper(step.id)}
              </StepBanner>
            ))}
          </StepBannersWrapper>

          <ContinueButton onClick={handleContinue} disabled={!can.continue}>
            {text('onboarding.biz.continue')}
          </ContinueButton>
        </>
      )}

      {screen === 'billing' && (
        <StripeSection>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
            <Elements
              css={[
                {
                  paddingRight: 20,
                  width: '100%',
                  justifyContent: 'space-between',
                },
              ]}
              fonts={[
                {
                  cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:wght@400',
                },
              ]}
            >
              <StripeForm>
                <PaymentComponent {...{ handleExtraSubscribeEvent }} />
              </StripeForm>
            </Elements>
          </StripeProvider>
        </StripeSection>
      )}
    </Hero>
  );
};

export default () => <Layout
  title="Onboarding - About your business"
  Header={<NavBar step={1} />}
  accessOnTrialEnds>
    <BusinesOnBoarding />
  </Layout>

const StripeSection = styled.section`
  width: 40%;

  ${GlobalTheme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

const StripeForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

function getSocialMediaPresence(socialMediaObj) {
  const order = ['facebook', 'instagram', 'linkedin', 'twitter'];

  const presenceArray = order.map(network => {
      const details = socialMediaObj[network];
      return details && details.pageName && details.pageName.trim() !== '';
  });

  return presenceArray;
}
