import React from "react";
import styled from "styled-components";
import { Dialog as MuiDialog } from '@material-ui/core';

import { ChevronDownIcon } from "../../assets/icons/iconRepository";

export const SelectorOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  background: ${(props) => (props.selected ? '#02768612' : 'transparent')};
  border-radius: 7px;
  padding: 5px 6px;

  cursor: pointer;

  p {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }

  &:hover {
    background: #02768612;
  }
`;

export const CompanySelectorPopup = styled(MuiDialog)`
  && {
      .MuiPaper-root {
      position: absolute;
      overflow-y: auto;

      display: 'flex';
      flex-direction: column;
      row-gap: 2px; 
    
      // 48px is the height of the SelectedItem
      top: ${(props) => (props.position.bottom - props.position.height) + props.position.top + (props.position.top / 2)}px;
      left: ${(props) => (props.position.left - props.position.height) + props.position.top}px;
      width: 300px;
      min-height: 100px;
      max-height: 250px;
      border-radius: 7px;
      padding: 40px 16px 16px;

      box-shadow:
        48px 30px 16px rgb(0 0 0 / 0%),
        31px 19px 15px rgb(0 0 0 / 1%),
        17px 11px 12px rgb(0 0 0 / 5%),
        8px 5px 9px rgb(0 0 0 / 9%),
        2px 1px 5px rgb(0 0 0 / 5%);

      background: ${(props) => props.theme.colors.white};

      &::before {
        content: '${(props) => props.heading}';
        position: absolute;
        top: 9px;
        left: 16px;
        font-size: 13px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.gray7E};
      }
    }
  }
`;

export const ChevronDownTouchable = styled.span`
  display: block;

  min-width: 16px;
  min-height: 16px;
  margin-right: 6px;

  background-image: url(${ChevronDownIcon});
  background-repeat: no-repeat;
  background-position: center center;
	background-size: contain;

  transform: ${(props) => (props.pressed ? 'rotate(180deg)' : 'unset')};

  pointer-events: none;
`;

export const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  padding: 0px 5px;
  width: 128px;
  height: 43px;

  background: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 100px;

  cursor: pointer;

  strong {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;

    color: ${(props) => props.theme.colors.black};
    white-space: nowrap;
    overflow: hidden;
    max-width: 90px;
    text-overflow: ellipsis;

    pointer-events: none;
  }
`;

export const Avatar = styled.img`
  border-radius: 100%;
  min-width: ${(props) => props.width}px;
  min-height: ${(props) => props.height}px;
  object-fit: cover;

  pointer-events: none;
`;

export const InitialAvatar = styled.span`
  display: block;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;

  background: ${(props) => props.theme.colors.secondary};
  border-radius: 100%;
  display: grid;
  place-items: center;

  &::before {
    content: '${(props) => props.char}';
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
  }

  pointer-events: none;
`;

export const CompanySeletorContainer = styled.section`
  position: relative;
`;