const en = {
    'upgradeToBusinessModal.title': 'Business account',
    'upgradeToBusinessModal.subtitle': 'Advantages:',
    'upgradeToBusinessModal.listItem.1': 'Add as many businesses or products as you want, connect your social networks for each one.',
    'upgradeToBusinessModal.listItem.2': 'Add team members to manage your businesses’s social networks.',
    'upgradeToBusinessModal.listItem.3': 'Supervise shceduled posts and boosts with the approbal system.',
    'upgradeToBusinessModal.listItem.4': 'Check at a glance how are your Social Ads performing and manage your advertising Budget on each of your business or products.',
    'upgradeToBusinessModal.footer.1': 'The first 30 days of your subscription to your Social Piper Business account will be free of charge.',
    'upgradeToBusinessModal.footer.2': 'Upgrading your account to a business account will invalidate any coupons you currently have applied.',
    'upgradeToBusinessModal.buttons.cancel': 'Cancel',
    'upgradeToBusinessModal.buttons.upgrade': 'Upgrade to a business account'
}

export default en;