import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { InstagramLogo } from '../../assets/social/socialRepository';
import BaseProfile from './BaseProfile';
import { INSTAGRAM } from '../../utils/constants/globals';
import BaseInformation from './BaseInformation';
import { capitalize } from '../../utils';
import MultiAccountSelector from './facebook/MultiAccountSelector';

const TipLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryLighter};
  cursor: pointer;
  text-decoration: underline;
`;

const InstagramProfile = ({
  instagramData,
  facebookData,
  companyName,
  businessAssociationCb,
  comingSoon
}) => {
  const intl = useIntl();
  const { toSetup, requireAttention } = instagramData;

  const handleCreateOne = () => {
    const auxWindow = window.open('https://www.instagram.com/accounts/emailsignup/', '_blank');
    auxWindow.focus();
  };

  const helpLink = {
    text: intl.formatMessage({
      id: 'socialProfiles.instagram.help',
      defaultMessage: 'Why is different from a personal Instagram?',
    }),
    handleClick: () => {
      // TODO: redirect user to help
    },
  };

  return (
    <BaseProfile
      type={INSTAGRAM}
      socialLogo={InstagramLogo}
      isConnected={!!instagramData.pageName}
      comingSoon={comingSoon}
    >
      {!toSetup ? (
        <BaseInformation
          type={INSTAGRAM}
          socialLogo={InstagramLogo}
          avatar={instagramData.avatar}
          pageName={instagramData.pageName}
          requireAttention={requireAttention}
          connectionTitle={intl.formatMessage(
            {
              id: companyName
                ? 'socialProfiles.connectionTitle'
                : 'socialProfiles.connectionTitle.noCompanyName',
              defaultMessage: "Connect {companyName}'s {socialNetwork} account",
            },
            {
              companyName,
              socialNetwork: capitalize(INSTAGRAM),
            },
          )}
          hint={
            intl.formatMessage({
              id: facebookData.pageName
                ? 'socialProfiles.instagram.hint.facebookConnected'
                : 'socialProfiles.instagram.hint.noFacebook',
              defaultMessage: `${!facebookData.pageName
                ? 'Will be available once a Facebook page is connected. '
                : 'You can only connect 1 Instagram business account. You don\'t know if you have a business account? <TipLink>Review these steps.</TipLink>'
              }`,
            }, {
              TipLink: (str) => (
                <TipLink
                  href="https://business.instagram.com/getting-started/#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {str}
                </TipLink>
              ),
            })
          }
          handleCreateOne={handleCreateOne}
          helpLink={helpLink}
          businessAssociationCb={businessAssociationCb}
        />
      ) : (
        <MultiAccountSelector
          socialLogo={InstagramLogo}
          type={INSTAGRAM}
        />
      )}
    </BaseProfile>
  );
};

InstagramProfile.propTypes = {
  instagramData: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    toSetup: PropTypes.bool,
    requireAttention: PropTypes.bool,
  }).isRequired,
  facebookData: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default InstagramProfile;
