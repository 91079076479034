import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { authAppSync } from '../../services/auth';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import { currentCompany } from './querys/companies.query';
import { setCurrentCompany } from './mutations/companies.mutation';

export default class CompaniesProvider {
  static async currentCompany() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV === ENVIRONMENTS.local) {
        return {
          data: 1,
          message: 'success',
          success: true,
        };
      }
      const response = await authAppSync.client.query({
        query: currentCompany,
        fetchPolicy: 'no-cache',
      });
      result = {
        message: 'success',
        success: true,
        data: response.data.currentCompany,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async setCurrentCompany(companyId) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV === ENVIRONMENTS.local) {
        return {
          data: 1,
          message: 'success',
          success: true,
        };
      }
      const response = await authAppSync.client.mutate({
        mutation: setCurrentCompany,
        fetchPolicy: 'no-cache',
        variables: {
          companyId
        }
      });
      result = {
        message: 'success',
        success: true,
        data: response.data.setCurrentCompany,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
