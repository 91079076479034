import { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { SocialContext } from '../../contexts/SocialContext';

import useCompany from '../../hooks/useCompany';

import { AudienceProvider } from '../../services/entities';
import { LENGTHS, AGES_BOUNDS, GENRE_BOTH } from '../../utils/constants/settings';
import calculateReachPercentage from '../../utils/calculateReachPercentage';
import { colors } from '../../styled/theme';
import {
  FACEBOOK,
  GLOBAL_DEBOUNCE_DELAY,
  LINKED_IN_MIN_AUDIENCE,
  LINKEDIN,
} from '../../utils/constants/globals';

const useAudienceInfo = (dataLayer) => {
  const intl = useIntl();
  const { dataLayerPush } = useContext(AnalyticsContext);

  const { socialNetworks, hasConnectedAccounts } = useCompany()

  const [valid, setValid] = useState(false);
  const [liValid, setLIValid] = useState(false);
  const [debounceFreeze, setDebounceFreeze] = useState(true);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const initialAudience = {
    gender: GENRE_BOTH,
    ages: AGES_BOUNDS,
    linkedinLocations: [],
    linkedinInterests: [],
    locations: [],
    interests: [],
  };
  const [audienceInfo, setAudienceInfo] = useState(initialAudience);
  const [liAudienceInfo, setLiAudienceInfo] = useState(initialAudience);
  const [shouldShow, setShouldShow] = useState({
    global: true,
    facebook: true,
    linkedin: true,
  });
  const defaultPotentialAudience = {
    size: 0,
    percent: 0,
    lowerRange: {
      length: LENGTHS[0],
      label: intl.formatMessage({
        id: 'settings.business.primaryTarget.tooSpecific',
        defaultMessage: 'Too specific',
      }),
      color: colors.screenBackground,
    },
    middleRange: {
      length: LENGTHS[1],
      label: intl.formatMessage({
        id: 'settings.business.primaryTarget.recommended',
        defaultMessage: 'Recommended',
      }),
      color: colors.screenBackground,
    },
    upperRange: {
      length: LENGTHS[2],
      label: intl.formatMessage({
        id: 'settings.business.primaryTarget.tooBroad',
        defaultMessage: 'Too broad',
      }),
      color: colors.screenBackground,
    },
  };
  const [linkedinPotentialAudience, setLinkedinPotentialAudience] = useState({
    ...defaultPotentialAudience,
  });
  const [potentialAudience, setPotentialAudience] = useState({
    ...defaultPotentialAudience,
  });
  const [isLoading, setIsLoading] = useState(false);
  const genders = [
    {
      value: 3,
      element: intl.formatMessage({
        id: 'onboarding.AboutAudience.GenderAll',
        defaultMessage: 'ALL',
      }),
    },
    {
      value: 1,
      element: intl.formatMessage({
        id: 'onboarding.AboutAudience.GenderFemale',
        defaultMessage: 'FEMALE',
      }),
    },
    {
      value: 2,
      element: intl.formatMessage({
        id: 'onboarding.AboutAudience.GenderMale',
        defaultMessage: 'MALE',
      }),
    },
  ];
  const updateAudienceSizeGraph = (audienceSize) => {
    const [
      percent,
      lowerRangeColor,
      middleColor,
      upperRangeColor,
    ] = calculateReachPercentage(audienceSize, FACEBOOK);
    setPotentialAudience({
      ...potentialAudience,
      percent,
      lowerRange: {
        ...potentialAudience.lowerRange,
        color: lowerRangeColor,
      },
      middleRange: {
        ...potentialAudience.middleRange,
        color: middleColor,
      },
      upperRange: {
        ...potentialAudience.upperRange,
        color: upperRangeColor,
      },
      size: audienceSize,
    });
  };

  const updateLinkedinAudienceSizeGraph = (audienceSize) => {
    const [
      percent,
      lowerRangeColor,
      middleColor,
      upperRangeColor,
    ] = calculateReachPercentage(audienceSize, LINKEDIN);
    setLinkedinPotentialAudience({
      ...linkedinPotentialAudience,
      percent,
      lowerRange: {
        ...linkedinPotentialAudience.lowerRange,
        color: lowerRangeColor,
      },
      middleRange: {
        ...linkedinPotentialAudience.middleRange,
        color: middleColor,
      },
      upperRange: {
        ...linkedinPotentialAudience.upperRange,
        color: upperRangeColor,
      },
      size: audienceSize,
    });
  };

  const debouncedUpdateSize = async (isLinkedin, overrideSnAudience) => {
    let snAudience = isLinkedin ? liAudienceInfo : audienceInfo;
    if (overrideSnAudience) {
      snAudience = overrideSnAudience;
    }
    if (isLinkedin) {
      if (!(snAudience.linkedinLocations && snAudience.linkedinLocations.length)) {
        return 0;
      }
    } else if (!(snAudience.locations && snAudience.locations.length)) {
      return 0;
    }
    const formattedInfo = AudienceProvider.formatSizeInput(snAudience, isLinkedin);
    const response = await AudienceProvider.fetchSize(formattedInfo, isLinkedin);

    if (response.success) {
      if (isLinkedin) {
        updateLinkedinAudienceSizeGraph(response.data);
        return response.data;
      }
      updateAudienceSizeGraph(response.data);
      return response.data;
    }
    return 0;
  };

  const fetchAudiencebySN = async (isLinkedIn) => {
    const response = await AudienceProvider.fetch(intl, null, isLinkedIn);
    if (response.success) {
      const audience = { ...response.data };
      audience.ages[0] = audience.ages[0] || 18;
      audience.ages[1] = audience.ages[1] || 55;
      audience.gender = audience.gender !== undefined ? audience.gender : 3;
      if (isLinkedIn) {
        audience.isValid = (audience?.linkedinLocations?.length
          && audience?.linkedinInterests?.length);
        setLiAudienceInfo(audience);
      } else {
        audience.isValid = (audience?.locations?.length && audience?.interests?.length);
        setAudienceInfo(audience);
      }
      return audience;
    }
    const audience = initialAudience;
    audience.isValid = false;
    if (isLinkedIn) {
      setLiAudienceInfo(audience);
    } else {
      setAudienceInfo(audience);
    }

    return audience;
  };

  const fetchAudience = async () => {
    let fbAudience;
    let liAudience;
    setIsLoading(true);
    const localShouldShow = {
      facebook: false,
      linkedin: false,
    };
    if (socialNetworks?.facebook?.pageName) {
      fbAudience = await fetchAudiencebySN();
      if (!fbAudience.isValid) {
        localShouldShow.facebook = true;
      }
      await debouncedUpdateSize(false, fbAudience);
    }
    if (socialNetworks?.linkedin?.pageName) {
      liAudience = await fetchAudiencebySN(true);
      if (liAudience.isValid) {
        const linSize = await debouncedUpdateSize(true, liAudience);
        if (linSize < LINKED_IN_MIN_AUDIENCE) {
          localShouldShow.linkedin = true;
        }
      } else {
        localShouldShow.linkedin = true;
      }
    }
    setShouldShow(localShouldShow);
    setIsLoading(false);
    return {
      fbAudience,
      liAudience,
    };
  };

  const handleUpdateSize = async (isLinkedin = false) => {
    setDebounceFreeze(true);
    return new Promise((resolve) => {
      if (debounceFreeze) clearTimeout(debounceTimer);
      setDebounceTimer(setTimeout(() => {
        setDebounceFreeze(false);
        debouncedUpdateSize(isLinkedin).then(resolve);
      }, GLOBAL_DEBOUNCE_DELAY));
    });
  };

  const setGender = (value, sn) => {
    dataLayerPush({
      ...dataLayer,
      dataAction: 'Set gender',
      event: 'action',
    });
    if (!value) return;
    if (sn === FACEBOOK) {
      setAudienceInfo({
        ...audienceInfo,
        gender: value,
      });
    }
    if (sn === LINKEDIN) {
      setLiAudienceInfo({
        ...liAudienceInfo,
        gender: value,
      });
    }
  };

  const setLocations = (value) => {
    dataLayerPush({
      ...dataLayer,
      dataAction: 'Set audience location',
      event: 'action',
    });
    setAudienceInfo({
      ...audienceInfo,
      locations: value || [],
    });
  };

  const setInterests = (value) => {
    dataLayerPush({
      ...dataLayer,
      dataAction: 'Set audience interest',
      event: 'action',
    });
    setAudienceInfo({
      ...audienceInfo,
      interests: value || [],
    });
  };

  const setAges = (value, sn) => {
    if (sn === FACEBOOK) {
      setAudienceInfo({
        ...audienceInfo,
        ages: value,
      });
    }
    if (sn === LINKEDIN) {
      setLiAudienceInfo({
        ...liAudienceInfo,
        ages: value,
      });
    }
  };

  const setLinkedinInterests = (value) => {
    dataLayerPush({
      ...dataLayer,
      dataAction: 'Set audience linkedin interest',
      event: 'action',
    });
    setLiAudienceInfo({
      ...liAudienceInfo,
      linkedinInterests: value || [],
    });
  };

  const setLinkedinLocations = (value) => {
    dataLayerPush({
      ...dataLayer,
      dataAction: 'Set audience linkedin interest',
      event: 'action',
    });
    setLiAudienceInfo({
      ...liAudienceInfo,
      linkedinLocations: value || [],
    });
  };

  const validateAudiences = () => {
    console.log("socialNetworks: ", socialNetworks)
    if (hasConnectedAccounts) {
      if (socialNetworks?.facebook?.pageName) {
        const snValid = (audienceInfo
          && audienceInfo.gender > 0
          && audienceInfo.ages?.length > 0
          && audienceInfo.locations?.length > 0
          && audienceInfo.interests?.length > 0
        );
        setValid(snValid);
      }
      if (socialNetworks?.linkedin?.pageName) {
        let snValid = (liAudienceInfo
          && liAudienceInfo.gender
          && liAudienceInfo.ages?.length > 0
          && liAudienceInfo.linkedinLocations?.length > 0
          && liAudienceInfo.linkedinInterests?.length > 0
        );
        snValid = (snValid && (linkedinPotentialAudience.size > LINKED_IN_MIN_AUDIENCE));
        setLIValid(snValid);
      }
    }
  };
  useEffect(() => {
    if (hasConnectedAccounts) {
      fetchAudience();
    }
  }, [hasConnectedAccounts, socialNetworks]);

  useEffect(() => {
    validateAudiences();
  }, [audienceInfo, liAudienceInfo, linkedinPotentialAudience]);

  return {
    audienceInfo,
    liAudienceInfo,
    setAudienceInfo,
    setLiAudienceInfo,
    potentialAudience,
    linkedinPotentialAudience,
    genders,
    isLoading,
    setIsLoading,
    setGender,
    setLocations,
    setInterests,
    setLinkedinInterests,
    setLinkedinLocations,
    setAges,
    shouldShow,
    handleUpdateSize,
    valid,
    liValid,
    debouncedUpdateSize,
    fetchAudience,
  };
};

export default useAudienceInfo;
