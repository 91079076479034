import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  materialShadows,
  borderTop,
  flexBox,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import CardInformationHeader from './CardInformationHeader';
import AutoResponseSettingsForm from './AutoResponseSettingsForm';

const BusinessInformationCard = styled.div`
  ${materialShadows};
  ${borderTop('speechBubble')};
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  ${flexBox}
  flex-wrap: wrap;
  position: relative;
  ${GlobalTheme.breakpoints.down('md')} {
    padding: 24px 16px;
  }
`;

const AutoResponseInformation = ({ className }) => {
  const intl = useIntl();

  return (
    <BusinessInformationCard className={className}>
      <CardInformationHeader
      advice={
        intl.formatMessage({
          id: 'settings.business.businessExtraInformation.advice',
          defaultMessage: 'Filling out these fields will provide Piper with an enhanced ability to respond to your DMs or post comments.',
        })
      }
        title={intl.formatMessage({
          id: 'settings.business.businessExtraInformation.title',
          defaultMessage: 'Business Information',
        })}
      />

      <AutoResponseSettingsForm />
    </BusinessInformationCard>
  );
};

AutoResponseInformation.propTypes = {
  className: PropTypes.string,
};

AutoResponseInformation.defaultProps = {
  className: '',
};

export default AutoResponseInformation;
