import gql from 'graphql-tag';

export const getMostActivePost = gql`
  query getMostActivePost {
    getMostActivePost {
      id
      pageName
      type
      publishTime
      image
      attachmentType
      message
      socialMediaPermalink
      interactions
      campaignsCount
      commentsCount
      reactions
    }
  }
`;

export const getPosts = gql`
  query posts($from: String, $to: String, $limit: Int, $sort: POSTS_ORDER, $published: String) {
    posts(from: $from, to: $to, limit: $limit, sort: $sort, published: $published, v2: true) {
      id
      title
      type
      attachmentType
      publishTime
      scheduleTime
      image
      video
      message
      reactions
      commentsNumber
      publishStatus
      errorReason
      adCampaign {
        id
        isExpired
        startDateTime
        endDateTime
        campaignStatus
      }
    }
  }
`;

export const getPendingPosts = gql`
  query pendingPosts {
    pendingPosts {
      id
      date
      by
      caption
      image
      company {
        id
        companyName
      }
      socialMedia {
        type
        name
      }
      status
    }
  }
`;

export const getMonthlySummaryPosts = gql`
  query getMonthlySummaryPosts($date: AWSDateTime, $timezone: String) {
    getMonthlySummaryPosts(date: $date, timezone: $timezone)
  }
`;

export const getPostStatus = gql`
  query getPostStatus($contentId: ID) {
    getPostStatus(contentId: $contentId)
  }
`;

export const getScheduledPublications = gql`
  query getScheduledPublications($companyId: Int) {
    getScheduledPublications(companyId: $companyId) {
      data {
        date
        posts {
          id
          type
          attachmentType
          publishTime
          scheduleTime
          imageUrl
          message
        }
      }
    }
  }
`;

