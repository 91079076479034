import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../buttons';
import GlobalTheme from '../../../styled/GlobalTheme';
import { SocialContext } from '../../../contexts/SocialContext';
import MultiAccountModal from './MultiaccountModal';

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: left;
`;

const HeadCont = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  ${GlobalTheme.breakpoints.down('md')}{
    align-items: center;
    margin-bottom: .5em;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  ${GlobalTheme.breakpoints.up('md')}{
    flex-flow: row nowrap;
  }
`;
const SocialLogoImg = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
  object-position: center;
  margin-right: 24px;
  margin-bottom: auto;
  ${GlobalTheme.breakpoints.up('md')}{
    display: none;
  }
`;

const Colored = styled.span`
  color: ${({ snType }) => (GlobalTheme.colors[snType])};
`;

const MultiAccountSelector = ({ socialLogo, type }) => {
  const { openPageSelector } = useContext(SocialContext);
  const intl = useIntl();
  return (
    <Container>
      <HeadCont>
        <SocialLogoImg src={socialLogo} />
        <Title>
          <FormattedMessage
            id="socialProfiles.multiAccount.modal.title"
            defaultMessage="Selecciona la <colored>{page}</colored> que quieres usar"
            values={{
              page: (intl.formatMessage({
                id: `socialProfiles.multiAccount.modal.${type}`,
              })),
              colored: (chunks) => (<Colored snType={type}>{chunks}</Colored>),
            }}
          />
        </Title>
      </HeadCont>

      <Button
        onClick={() => openPageSelector(type)}
      >
        Seleccionar
      </Button>
      <MultiAccountModal />
    </Container>
  );
};

MultiAccountSelector.propTypes = {
  socialLogo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MultiAccountSelector;
