import React, { useState, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-media";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../../../styled/theme";
import { StarIcon, CheckIcon } from "../../../assets/icons/iconRepository";
import ColoredIcon from "../../../components/globals/ColoredIcon";
import routes from "../../../utils/constants/routes";
import { AnalyticsContext } from "../../../contexts/AnalyticsContext";
// eslint-disable-next-line import/no-cycle
import { ShowPricingDetails } from "./PricingSection";
import mediaQueries from "../../../utils/mediaQueries";
import useGetCurrencySymbol from "../../../hooks/useGetCurrencySymbol";
import roles from "../../../utils/constants/roles";
import { WebViewContext } from "../../../contexts/WebViewContext";
import PromoCodeContext from "../../../contexts/PromoCodeContext";
import { ButtonPrimary } from "../../landing/components/Buttons";

const Container = styled.div`
  position: relative;
  max-width: 300px;
  width: 100%;
  &:not(:first-child) {
    margin-top: 38px;
  }
  @media ${mediaQueries.lgPlusUp} {
    max-width: 330px;
    &:not(:first-child) {
      margin-top: 0;
      margin-left: 32px;
    }
    ${({ isLanding }) =>
      isLanding &&
      `
      max-width: 620px;
      &:not(:first-child) {
        margin-left: 126px;
      }
    `}
  }
  @media ${mediaQueries.xlUp} {
    max-width: 620px;
    &:not(:first-child) {
      margin-left: 45px;
    }
  }
`;
const Bullet = styled.div`
  display: none;
  border-radius: 20px;
  line-height: 26px;
  text-align: center;
  font-size: 16px;
  width: 150px;
  line-height: 26px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -13px;
  color: ${({ theme }) => theme.colors.grayMediaLibrary};
  background: ${({ theme }) => theme.colors.secondary};

  &::before,
  &::after {
    content: url(${StarIcon});
    display: inline-block;
    transform: scale(0.8);
  }
  &::before {
    margin-right: 4px;
  }
  &::after {
    margin-left: 4px;
  }
`;

const CardBox = styled.div`
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.white};
  border-top: 8px solid ${({ theme }) => theme.colors.primary};
  width: 100%;
  padding-top: 22px;
  padding-bottom: 28px;
  ${({ role }) =>
    role === roles.PRO &&
    `
    height: 100%;
  `}
  @media ${mediaQueries.lgPlusUp} {
    padding-top: 46px;
    padding-bottom: 46px;
    ${({ role }) =>
      role !== roles.PRO &&
      `
      padding-bottom: 62px;
    `}
  }
  @media ${mediaQueries.xlUp} {
    ${({ role }) =>
      role !== roles.PRO &&
      `
      padding-bottom: 58px;
    `}
  }
`;

const CardHeader = styled.div`
  color: ${({ color }) => color};
`;

const CardTitle = styled.h3`
  color: ${({ theme }) => theme.colors.black3E};
  font-weight: 600;
  font-size: 19px;
  line-height: 40px;
  text-align: center;
  @media ${mediaQueries.lgPlusUp} {
    font-size: 28px;
    line-height: 50px;
  }
`;

const CardBody = styled.div`
  height: fit-content;
  border-radius: 0px 0px 6px 6px;
`;

const ContentBox = styled.ul`
  padding: 0;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  @media ${mediaQueries.lgPlusUp} {
    ${({ isBusiness }) =>
      isBusiness &&
      `
      margin-top: 25px;
    `}
    ${({ isBusiness, isSpanish }) =>
      isBusiness &&
      !isSpanish &&
      `
      margin-top: 16px;
      padding: 0 30px;
    `}
  }
`;
const ListContainer = styled.div`
  width: max-content;
`;
const ContentList = styled.li`
  display: flex;
  align-items: flex-start;
  &:not(:first-child) {
    margin-top: 26px;
    @media ${mediaQueries.lgPlusUp} {
      margin-top: 16px;
    }
  }
`;
const CustomColoredIcon = styled(ColoredIcon)`
  && {
    width: 21px;
    height: 16px;
    background-size: 21px;
    -webkit-mask-size: 21px;
    margin-right: 18px;
    margin-top: 5px;
  }
`;
const List = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  flex: 1;
  @media ${mediaQueries.lgPlusUp} {
    font-size: 18px;
    line-height: 25px;
    ${({ isBusiness }) =>
      isBusiness &&
      `
      br {
        display: none;
      }
    `}
  }
`;

const Price = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: -6px;
  ${({ isCommingSoon, theme }) =>
    isCommingSoon ? `color: ${theme.colors.gray90};` : ""}
`;

const PriceContent = styled.div`
  position: relative;
`;
const PriceText = styled.p`
  margin: 0;
  color: ${({ color }) => color};
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  &::before {
    content: "${({ symbol }) => symbol}";
  }
  &:after {
    content: "${({ payTime }) => payTime}";
  }
  @media ${mediaQueries.lgPlusUp} {
    font-size: 40px;
    line-height: 50px;
  }
`;
const PriceDescription = styled.p`
  width: 60% !important;
  margin: 0 auto !important;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  line-height: 19px;
  text-align: center;
  margin: 0;
  @media ${mediaQueries.lgPlusUp} {
    font-size: 18px;
    line-height: 25px;
  }
`;
const BusinessInfo = styled.p`
  font-size: 15px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
  margin-top: 32px;
`;

const CustomButtonPrimary = styled(ButtonPrimary)`
  && {
    @media ${mediaQueries.lgPlusUp} {
      font-size: 18px;
    }
    @media ${mediaQueries.xlPlusUp} {
      font-size: 22px;
    }
    ${({ isSpanish, isCommingSoon }) =>
      isSpanish &&
      !isCommingSoon &&
      `
      line-height: 30px;
      padding-top: 8px;
      padding-bottom: 8px;
    `}
    ${({ largeText }) =>
      largeText &&
      `
      padding-left: 24px;
      padding-right: 24px;
    `}
  }
`;
const ShowPricingBox = styled.div`
  color: ${colors.primary};
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 20px;
  text-decoration: underline;
  display: none;
  @media ${mediaQueries.lgPlusUp} {
    font-size: 14px;
  }
`;
const InfoPLan = styled.div`
  display: block;
  padding: 0 18px;
  margin-top: 12px;
`;

const TextDecimal = styled.span`
  display: ${({ show }) => (show ? "inline" : "none")};
  font-size: 20px;
  bottom: 6px;
  width: 100%;
`;

const ContainerTextApplyPromo = styled.div`
  top: -14px;
  position: absolute;
  width: 100%;
  text-align: center;
`;

const TextApplyPromo = styled.span`
  display: none;
  background-color: ${colors.orange};
  color: white;
  border-radius: 2px;
  padding: 4px 16px;
  font-size: 10px;
  font-weight: 700;
`;

const SkeletonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  align-items: baseline;
  grid-gap: 8px;
  span {
    border-radius: 4px;
  }
`;
const DividerLine = styled.div`
  width: 80%;
  height: 1px;
  background: ${({ theme }) => theme.colors.black};
  margin: 0 auto;
  margin-top: 34px;
`;
const MoreFeaturesLink = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 18px;
`;
const CurrencyCodeText = styled.span`
  color: ${colors.black3E};
  font-size: 26px;
  font-weight: 300;
  margin-left: 4px;
  @media ${mediaQueries.lgPlusUp} {
    font-size: 32px;
  }
`;
const PricingCard = ({
  color,
  name,
  description,
  contents,
  price,
  isMonthly,
  isRecommended,
  isCommingSoon,
  hasMorePricingDetails,
  role,
  isValidPromoCode,
  promoCode,
  currencyCode,
  daysTrial,
  landing,
  decimal,
  codePromo,
  applyPromo,
  setOpenFeaturesModal,
  setSelectedRole,
}) => {
  const history = useHistory();
  const [show] = useState(false);
  const currencySymbol = useGetCurrencySymbol(currencyCode);
  const { dataLayerPush } = useContext(AnalyticsContext);
  const [pathSignup, setPathSignup] = useState(routes.CREATE_ACCOUNT);
  const intl = useIntl();
  const { locale } = intl;
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const { isPricingLoading } = useContext(PromoCodeContext);

  const onClickHandler = () => {
    const typePlan = role === "P" ? roles.PRO : roles.BUSINESS_MAIN;
    dataLayerPush({
      pagePath: `/homepage/pricing/${typePlan}`,
      pathTitle: "Landing Pricing",
      dataAction: "Choose piper plan",
      event: "action",
      category: isMonthly ? "Monthly" : "Yearly",
      label: "(button)",
    });

    window.localStorage.setItem("planSelected", typePlan);
    if (promoCode) {
      window.localStorage.setItem("promoCode", promoCode);
    }

    const search = isValidPromoCode
      ? `?promocode=${promoCode}&plan=${typePlan}`
      : `?plan=${typePlan}`;

    if (role === roles.BUSINESS_MAIN) {
      const search = `?promocode=FULL_MNGT&plan=${typePlan}`;
      history.push({
        pathname: pathSignup,
        search,
        state: {
          plan: role,
        },
      });
    } else {
      history.push({
        pathname: pathSignup,
        search,
        state: {
          plan: role,
        },
      });
    }
  };

  const goToPricing = () => {
    dataLayerPush({
      pagePath: "/homepage",
      pathTitle: "Landing",
      dataAction: "See details plan",
      event: "action",
      category: isMonthly ? "Monthly" : "Yearly",
      label: "(button)",
    });

    history.push({
      pathname: routes.PRICING,
    });
  };

  const handleOpenFeaturesModal = (plan) => {
    setOpenFeaturesModal(true);
    setSelectedRole(plan);
  };

  useEffect(() => {
    const fromSignupPro = window.localStorage.getItem("isLandingPro");
    if (fromSignupPro) {
      setPathSignup(routes.CREATE_ACCOUNT_PRO);
    }
    if (isApp || isAppIOS) setPathSignup(routes.CREATE_ACCOUNT_APP);
  }, []);
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Container role={role} isEnglish={locale === "en"} isLanding={landing}>
          {isRecommended && (
            <Bullet>
              <FormattedMessage
                id="k.mostPopular"
                defaultMessage="Most Popular"
              />
            </Bullet>
          )}
          <CardBox role={role} applyPromo={applyPromo}>
            {!isCommingSoon && applyPromo && (
              <ContainerTextApplyPromo>
                <TextApplyPromo>
                  <FormattedMessage
                    id="newPricing.plan.apply.promo"
                    defaultMessage="Promo code {code} applied"
                    values={{ code: codePromo }}
                  />
                </TextApplyPromo>
              </ContainerTextApplyPromo>
            )}
            <CardHeader color={color} isRecommended={isRecommended} role={role}>
              <CardTitle>{name}</CardTitle>
              <Price isCommingSoon={isCommingSoon}>
                <PriceContent>
                  {isPricingLoading ? (
                    <SkeletonContainer>
                      <Skeleton variant="rect" width={62} height={48} />
                      <Skeleton variant="rect" width={34} height={22} />
                    </SkeletonContainer>
                  ) : (
                    <PriceText
                      color={color}
                      curCode={currencyCode}
                      symbol={currencySymbol}
                      payTime={intl.formatMessage({
                        id: isMonthly
                          ? "newPricing.price.monthly.text"
                          : "newPricing.price.annually.text",
                        defaultMessage: "/mo.",
                      })}
                    >
                      {price}
                      <TextDecimal show={decimal !== ""}>
                        .{decimal}
                      </TextDecimal>
                      {currencyCode === "USD" && (
                        <CurrencyCodeText>{currencyCode}</CurrencyCodeText>
                      )}
                    </PriceText>
                  )}
                </PriceContent>
              </Price>
            </CardHeader>
            <CardBody hasMorePricingDetails={hasMorePricingDetails || show}>
              <InfoPLan>
                <PriceDescription>{description}</PriceDescription>
                {/*role === roles.BUSINESS_MAIN && (
                  <BusinessInfo>
                    <FormattedMessage
                      id="newPricing.plan.business.info"
                      defaultMessage="All Individual benefits,  plus:"
                    />
                  </BusinessInfo>
                )*/}
                <ContentBox
                  isSpanish={locale === "es"}
                  isBusiness={role === roles.BUSINESS_MAIN}
                >
                  <ListContainer>
                    {contents.map((content) => (
                      <ContentList>
                        <CustomColoredIcon
                          src={CheckIcon}
                          color={colors.green04}
                        />
                        <List>
                          {content}
                        </List>
                      </ContentList>
                    ))}
                  </ListContainer>
                </ContentBox>
              </InfoPLan>
              {!landing && matches.mdDownLess && (
                <>
                  <DividerLine />
                  <MoreFeaturesLink
                    onClick={() => handleOpenFeaturesModal(role)}
                  >
                    <FormattedMessage
                      id="k.moreFeatures"
                      defaultMessage="See more features"
                    />
                  </MoreFeaturesLink>
                </>
              )}
              <ButtonBox
                isPro={role === roles.PRO}
                isSpanish={locale === "es"}
                isLanding={landing}
              >
                <CustomButtonPrimary
                  color={colors.primary}
                  onClick={onClickHandler}
                  gaData={{
                    action: "Lets get started",
                    label: "(button)(hero)",
                  }}
                  isCommingSoon={isCommingSoon}
                  isSpanish={locale === "es"}
                  largeText={matches.lgPlusDownLess && locale === "en"}
                >
                  {role === roles.PRO ? (
                    <>
                      {daysTrial > 0 ? (
                        <FormattedMessage
                          id="newPricing.plan.pro.ctaButton"
                          defaultMessage="Start a {daysTrial}-day free trial"
                          values={{
                            daysTrial,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="plan.pro.without.trial"
                          defaultMessage="Create your account"
                        />
                      )}
                    </>
                  ) : (
                    <FormattedMessage
                      id="k.signup.now"
                      defaultMessage="Contact Us"
                    />
                  )}
                </CustomButtonPrimary>
              </ButtonBox>
              {landing && (
                <ShowPricingBox>
                  <ShowPricingDetails
                    color={colors.primary}
                    onClick={goToPricing}
                  >
                    <FormattedMessage
                      id="newPricing.card.seeDetails"
                      defaultMessage="See plan details"
                    />
                  </ShowPricingDetails>
                </ShowPricingBox>
              )}
            </CardBody>
          </CardBox>
        </Container>
      )}
    </Media>
  );
};

PricingCard.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contents: PropTypes.objectOf(Array).isRequired,
  price: PropTypes.number.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  isRecommended: PropTypes.string.isRequired,
  isCommingSoon: PropTypes.bool.isRequired,
  hasMorePricingDetails: PropTypes.bool,
  role: PropTypes.string.isRequired,
  isValidPromoCode: PropTypes.bool.isRequired,
  promoCode: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  daysTrial: PropTypes.number,
  landing: PropTypes.bool,
  decimal: PropTypes.string,
  codePromo: PropTypes.string,
  applyPromo: PropTypes.bool,
  setOpenFeaturesModal: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
};

PricingCard.defaultProps = {
  hasMorePricingDetails: false,
  daysTrial: 0,
  landing: false,
  decimal: "",
  codePromo: "",
  applyPromo: false,
};
export default PricingCard;
