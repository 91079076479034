/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { label } from '../../styled/mixins';
import { ChevronRightIcon, ChevronLeftIcon } from '../../assets/icons/iconRepository';

export const PAGES_TO_DISPLAY = 5;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 34px;
`;
const ChevronBtn = styled.div`
  height: 15px;
  width: 32px;
  cursor: pointer;
  background: ${({ arrowBackground, theme }) => (
    arrowBackground ? theme.colors.white : 'rgb(255,255,255,0.5)'
  )} url(${({ chevron }) => (chevron)}) no-repeat 0 0;
  background-position: center;
`;

const PageBtn = styled.div`
  ${label}
  width: 24px;
  height: 24px;
  margin: 0 10px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  background: ${({ isActive, theme }) => (isActive ? theme.colors.primaryLighter : '')};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.white : '')}
`;

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  handlePageChange,
}) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const pages = Math.ceil(totalItems / itemsPerPage);
    const getPageNumbers = () => {
      const numbers = [];
      if (pages <= PAGES_TO_DISPLAY) {
        for (let i = 1; i <= pages; i += 1) {
          numbers.push(i);
        }
      } else {
        const midPoint = Math.floor(PAGES_TO_DISPLAY / 2);
        let firstPage = Math.max(1, currentPage - midPoint);
        if (pages - firstPage + 1 < PAGES_TO_DISPLAY) {
          firstPage = pages - PAGES_TO_DISPLAY + 1;
        }
        for (let i = firstPage; i <= firstPage + PAGES_TO_DISPLAY - 1; i += 1) {
          numbers.push(i);
        }
      }
      setPageNumbers(numbers);
    };
    setNumberOfPages(pages);
    getPageNumbers();
  }, [currentPage, totalItems]);
  const handleClick = (page) => {
    if (page && page <= numberOfPages) {
      handlePageChange(page);
    }
  };
  return (
    <Container>
      <ChevronBtn
        chevron={ChevronLeftIcon}
        onClick={() => handleClick(currentPage - 1)}
      />
      {pageNumbers.map((page) => (
        <PageBtn
          id={page}
          key={page}
          isActive={page === currentPage}
          onClick={() => (handleClick(page))}
        >
          { page }
        </PageBtn>
      ))}
      <ChevronBtn
        chevron={ChevronRightIcon}
        onClick={() => handleClick(currentPage + 1)}
      />
    </Container>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default Pagination;
