import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useCallGA from '../../hooks/useCallGA';
import { TabsHeaderStyled } from './Tabs.styled';

const TabsHeader = ({
  onClick,
  to,
  isActive,
  marginRight,
  isDropdown,
  isShowingDropdown,
  gaTitle,
  children,
}) => {
  const callGA = useCallGA();
  return (to ? (
    <TabsHeaderStyled
      as={Link}
      to={to}
      isActive={isActive}
      marginRight={marginRight}
      onClick={() => {
        callGA({
          label: '(TabsHeader)',
          action: `go to ${gaTitle}`,
        });
      }}
    >
      {children}
    </TabsHeaderStyled>
  ) : (
    <TabsHeaderStyled
      isActive={isActive}
      marginRight={marginRight}
      isDropdown={isDropdown}
      isShowingDropdown={isShowingDropdown}
      onClick={() => {
        callGA({
          label: '(TabsHeader)',
          action: `go to ${gaTitle}`,
        });
        onClick();
      }}
    >
      {children}
    </TabsHeaderStyled>
  ));
};

TabsHeader.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  isActive: PropTypes.bool,
  marginRight: PropTypes.string,
  isDropdown: PropTypes.bool,
  isShowingDropdown: PropTypes.bool,
  children: PropTypes.node.isRequired,
  gaTitle: PropTypes.string,
};

TabsHeader.defaultProps = {
  onClick: () => {},
  to: null,
  isActive: false,
  marginRight: '8px',
  isDropdown: false,
  isShowingDropdown: false,
  gaTitle: '',
};

export default TabsHeader;
