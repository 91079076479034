import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import {
  TrustIcon,
  FacebookColorIcon,
  InstagramCircleColorIcon,
  TiktokColorIcon,
  TwitterCircleColorIcon,
  LinkedinCircleColorIcon,
} from '../../../assets/icons/iconRepository';
import mediaQueries from '../../../utils/mediaQueries';

const Box = styled.div`
  border: 0.5px solid ${({ theme }) => theme.colors.green};
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 14px;
  padding: 8px 14px 8px 14px;
  margin: 0 auto;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  ${({ isSocialNetworks, fromSettings }) => isSocialNetworks && `
    flex-direction: column;
    @media ${mediaQueries.mdUp} {
      flex-direction: row;
    }
    @media ${mediaQueries.xlPlusUp} {
      ${fromSettings && `
        flex-direction: column;
      `}
    }
  `}
`;
const TrustMarkIconContainer = styled.div`
  display: flex;
  width: 21px;
`;
const Icon = styled.img`
  width: 100%;
`;
const Description = styled.div`
  color: ${({ theme }) => theme.colors.green};
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  margin-left: 5px;
`;
const Text = styled.p`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 6px;
  @media ${mediaQueries.mdUp} {
    margin-bottom: 0;
  }
  @media ${mediaQueries.xlPlusUp} {
    ${({ fromSettings }) => fromSettings && `
      margin-bottom: 6px;
    `}
  }
`;
const SocialNetworks = styled.div`
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-gap: 6px;
  @media ${mediaQueries.mdUp} {
    margin-left: 6px;
  }
`;
const SocialNetwork = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
`;
const SocialIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const TrustMarkBox = ({ className, fromSettings }) => {
  const socialNetworks = [
    {
      name: 'facebook',
      icon: FacebookColorIcon,
    },
    {
      name: 'instagram',
      icon: InstagramCircleColorIcon,
    },
    {
      name: 'tiktok',
      icon: TiktokColorIcon,
    },
    {
      name: 'twitter',
      icon: TwitterCircleColorIcon,
    },
    {
      name: 'linkedin',
      icon: LinkedinCircleColorIcon,
    },
  ];

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Box className={className}>
          <Container>
            <TrustMarkIconContainer>
              <Icon src={TrustIcon} />
            </TrustMarkIconContainer>
            <Description>
              <FormattedMessage
                id="onBoarding.launcher.trustMark.description"
                defaultMessage="Secure {lineBreak}information"
                values={{
                  lineBreak: (matches.mdUp && !fromSettings) ? '' : <br />,
                }}
              />
            </Description>
          </Container>
          <Container
            isSocialNetworks
            fromSettings={fromSettings}
          >
            <Text fromSettings={fromSettings}>
              <FormattedMessage
                id="onBoarding.launcher.trustMark.text"
                defaultMessage="We are an app approved by:"
              />
            </Text>
            <SocialNetworks>
              {socialNetworks.map((socialNetwork) => (
                <SocialNetwork key={socialNetwork.name}>
                  <SocialIcon src={socialNetwork.icon} />
                </SocialNetwork>
              ))}
            </SocialNetworks>
          </Container>
        </Box>
      )}
    </Media>
  );
};

export default TrustMarkBox;
