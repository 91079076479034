/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { heading3, elevation } from '../../../styled/mixins';
import ValidationCategory from './ValidationCategory';
import {
  UPPER_CASE_FORMAT,
  LOWER_CASE_FORMAT,
  NUMBERS_FORMAT,
  MIN_NUM_OF_CHAR,
} from './constants';

const Container = styled.div`
  ${elevation}
  background: ${({ theme }) => (theme.colors.white)};
  padding: 17px 27px;
`;

const Title = styled.h3`
  ${heading3}
  font-size: 16px;
  margin-bottom: 10px;
`;

const PasswordHelper = ({ password, setIsValid, className }) => {
  const [passwordValidations, setPasswordValidations] = useState({
    hasMoreThan8char: false,
    contain3vals: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const validationProperties = [
    {
      intlId: 'k.password.length',
      description: 'Password length validation',
      defaultMessage: 'At least 8 characters in length',
      isSubcategory: false,
      isValid: passwordValidations.hasMoreThan8char,
    },
    {
      intlId: 'k.password.3condition',
      description: 'Password type char validation',
      defaultMessage: 'Lowercase and uppercase letters and numbers.',
      isSubcategory: false,
      isValid: passwordValidations.contain3vals,
    },
  ];

  /**
   *
   * @param {*} validations
   * Checks if the password given has 3 successfully conditions
   */
  const hasMin3Valid = (validations) => {
    const { hasMoreThan8char, ...data } = validations;
    const values = Object.values(data);
    let counter = 0;
    values.forEach((value) => {
      if (value) counter += 1;
    });
    return counter === 3;
  };

  const passwordValidatior = (string) => {
    const validations = {
      hasMoreThan8char: string.length >= MIN_NUM_OF_CHAR,
      hasUpperCase: UPPER_CASE_FORMAT.test(string),
      hasLowerCase: LOWER_CASE_FORMAT.test(string),
      hasNumber: NUMBERS_FORMAT.test(string),
    };
    validations.contain3vals = hasMin3Valid(validations);
    setPasswordValidations(validations);
    const isValid = validations.contain3vals && validations.hasMoreThan8char;
    setIsValid(isValid);
  };
  useEffect(() => {
    passwordValidatior(password);
  }, [password]);

  return (
    <Container
      className={className}
    >
      <Title>
        <FormattedMessage
          id="k.passwordHelper.title"
          description="Password Helper title"
          defaultMessage="Password criteria"
        />
      </Title>
      {validationProperties.map((val) => (
        <ValidationCategory key={val.id} validation={val} />
      ))}
    </Container>
  );
};

PasswordHelper.propTypes = {
  password: PropTypes.string.isRequired,
  setIsValid: PropTypes.func.isRequired,
  className: PropTypes.string,
};

PasswordHelper.defaultProps = {
  className: '',
};

export default PasswordHelper;
