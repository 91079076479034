// @ts-check
import React, { createContext, useMemo, useState, useEffect, useCallback } from 'react';
import ApprovalProvider from '../../../business/services/ApprovalProvider';
import useCompany from '../../../hooks/useCompany';

export const ApprovalsCtx = createContext(null);

/** @type {(props: { children: React.ReactNode}) => JSX.Element} */
const ApprovalsProvider = ({ children }) => {
  const { companies } = useCompany();
  const [posts, setPosts] = useState([]);
  const [boosts, setBoosts] = useState([]);
  const [notifications, setNotifications] = useState({});

  const getNotifications = useCallback(async () => {
    const notificationsResponse = await ApprovalProvider.getApprovalNotifications();
    const notifications = notificationsResponse && notificationsResponse.success ? notificationsResponse?.data?.getApprovalNotifications : {}; 
    return notifications;
  }, []);

  useEffect(() => {
    getNotifications().then((res) => setNotifications(res));
  }, [posts, boosts, companies]);

  const value = useMemo(
    () => ({ posts, setPosts, boosts, setBoosts, notifications, setNotifications }),
    [posts, setPosts, boosts, setBoosts, notifications, setNotifications],
  );

  // @ts-ignore
  return <ApprovalsCtx.Provider value={value}>{children}</ApprovalsCtx.Provider>;
};

export default ApprovalsProvider;
