/**
 * this mapper works to allow you to run a function just when the payload id of the object match with some id in the state
 */
export default (state, action, { anyfunction, iffunction, elsefunction }) => {
  return state.map((value) => {
    if (anyfunction) {
      return anyfunction(value);
    }

    if (value.id === action.payload?.id) {
      return iffunction?.(value);
    }
    return elsefunction?.(value);
  });
};
