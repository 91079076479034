import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TabsHeader from './TabsHeader';
import {
  TabsMenu,
  TabsHeadersContainer,
} from './Tabs.styled';

const TabsHeaders = ({
  headers,
  setShowTabsMenu,
  showTabsMenu,
  activeHeader,
  convertsToDropdown,
  maximumTabsToDisplay,
}) => (
  <TabsHeadersContainer
    alignItems="center"
    justifyContent="flex-start"
  >
    {(!convertsToDropdown || maximumTabsToDisplay > 3)
      ? headers.map((header, index) => (
        <TabsHeader
          key={`TabHeader_${header.name}`}
          isActive={activeHeader === index}
          to={header.to}
          gaTitle={headers[activeHeader] ? headers[activeHeader].name : ''}
        >
          {header.name}
        </TabsHeader>
      ))
      : (
        <>
          {headers.slice(0, maximumTabsToDisplay).map(
            (header, index) => (
              <TabsHeader
                key={`TabHeader_${header.name}`}
                isActive={activeHeader === index}
                to={header.to}
                gaTitle={headers[activeHeader] ? headers[activeHeader].name : ''}
              >
                {header.name}
              </TabsHeader>
            ),
          )}
          <TabsHeader
            onClick={() => setShowTabsMenu(!showTabsMenu)}
            isDropdown
            isShowingDropdown={showTabsMenu}
            gaTitle={headers[activeHeader] ? headers[activeHeader].name : ''}
          >
            <FormattedMessage id="k.more" defaultMessage="More" />
            <TabsMenu
              isShowing={showTabsMenu}
            >
              {headers.slice(maximumTabsToDisplay).map(
                (header) => (
                  <TabsHeader
                    key={`TabHeader_${header.name}`}
                    marginRight="0"
                    to={header.to}
                    gaData={{ label: '(TabsHeaders)' }}
                  >
                    {header.name}
                  </TabsHeader>
                ),
              )}
            </TabsMenu>
          </TabsHeader>
        </>
      )}
  </TabsHeadersContainer>
);

TabsHeaders.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  setShowTabsMenu: PropTypes.func.isRequired,
  showTabsMenu: PropTypes.bool.isRequired,
  activeHeader: PropTypes.number.isRequired,
  convertsToDropdown: PropTypes.bool.isRequired,
  maximumTabsToDisplay: PropTypes.number.isRequired,
};

TabsHeaders.defaultProps = {
};

export default TabsHeaders;
