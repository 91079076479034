import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import * as Sentry from '@sentry/browser';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import translations from '../i18n/locales';
import routes from '../utils/constants/routes';
import AuthService from '../services/auth/AuthService';
import UserProvider from '../services/entities/UserProvider';
import RocketLoader from '../components/globals/RocketLoader';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import { preloadAuthenticatedComponents } from '../routes/authenticated';
import removeLanguageRegion from '../utils/removeLanguageRegion';

const UserSettingsContext = React.createContext();

const UserSettingsProvider = ({ children }) => {
  const didLoadRef = useRef(false);
  const [state, setState] = useState({
    locale: '',
    messages: null,
  });
  const [language, setLanguage] = useState('');
  const [emailVerified, setEmailVerified] = useState(true);
  const [subsCurrency, setSubsCurrency] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isOptIn, setIsOptIn] = useState(false);
  const [settingsRdy, setSettingsRdy] = useState(false);
  const [onboardingDetails, setOnboardingDetails] = useState({
    finished: false,
    nextStep: 0,
    requiredCard: false,
    statusTutorial: '',
    obDateStatus: 'skipped',
    showLauncher: false,
  });
  const [currencies, setCurrencies] = useState({});
  const [hasError, setHasError] = useState(false);
  const [reloadAdCampaign, setReloadAdCampaign] = useState(false);

  const handleLanguageChange = (lan = language) => {
    // Split languages with a region code
    // en-US
    // en-GB
    const languageWithoutRegionCode = removeLanguageRegion(lan);

    // Try full language, fallback to language without region code
    if (translations[lan]) {
      setState({
        locale: lan,
        messages: translations[lan],
      });
    } else if (translations[languageWithoutRegionCode]) {
      setState({
        locale: languageWithoutRegionCode,
        messages: translations[languageWithoutRegionCode],
      });
    } else {
      // Fallback en
      setState({
        locale: 'en',
        messages: translations.en,
      });
    }
  };

  useDidUpdateEffect(() => {
    handleLanguageChange();
  }, [language]);

  const fetchUserCurrencies = async () => {
    if (AuthService.isAuthenticated()) {
      const response = await UserProvider.fetchCurrencies();
      if (response.success) {
        setCurrencies(response.data);
        return response.data;
      }
    }
    return false;
  };

  const getBasedLocationSettings = async () => {
    let response = {};
    try {
      const request = await fetch('/location.json');
      const locationInfo = await request.json();
      if (locationInfo) {
        const { countryCode: countryCode, countryCurrency, countryLang } = locationInfo;
        response = {
          subsCurrency: countryCurrency,
          countryCode: countryCode,
          lang: countryLang,
        };
      } else {
        throw new Error('Location info not found');
      }
    } catch (err) {
      Sentry.captureException(err);
      response = {
        subsCurrency: 'USD',
        countryCode: 'US',
        lang: 'EN',
      };
    }
    return response;
  };

  const fetchUserSettings = async (cached = true) => {
    didLoadRef.current = true;
    let lan = '';
    if (AuthService.isAuthenticated()) {
      preloadAuthenticatedComponents();
      const response = await UserProvider.fetchSettings(null, cached);
      if (response.success && response.data) {
        const { data } = response;
        console.log({ resultUserFetchSettings: data });
        lan = data?.language;
        setEmailVerified(data?.emailVerified);
        setLanguage(data?.language);
        setIsOptIn(data?.isOptIn);
        /* FORCE FOR ELIMINATE STEP 1 FROM ONBOARDING */
        const newOD = {
          finished: data?.onboardingFinished,
          nextStep: data?.nextOnboardingStep,
          requiredCard: data?.requiredCard,
          statusTutorial: data?.statusTutorial,
          obDateStatus: data?.onboardingDateStatus,
        };
        setOnboardingDetails({
          ...onboardingDetails,
          ...newOD,
        });
        setSubsCurrency(data?.currencyCode);
        setCountryCode(data?.countryCode);
      }
    } else {
      const locationInfo = await getBasedLocationSettings();
      setCountryCode(locationInfo.countryCode);
      setLanguage(locationInfo.lang);
      setSubsCurrency(locationInfo.subsCurrency);
      lan = locationInfo.lang;
    }
    if (!lan) {
      lan = (navigator.languages && navigator.languages[0])
        || navigator.language
        || navigator.userLanguage;
      setLanguage(lan);
    }

    if (lan === language && lan !== state.locale) {
      handleLanguageChange(lan);
    }
    await fetchUserCurrencies();
    setSettingsRdy(true);
  };

  const overwriteLocationSetting = async () => {
    if (window.localStorage.getItem('countryCode') && window.localStorage.getItem('currencyCode')) {
      setCountryCode(window.localStorage.getItem('countryCode'));
      setSubsCurrency(window.localStorage.getItem('currencyCode'));
    }
  };

  useEffect(() => {
    fetchUserSettings();
    overwriteLocationSetting();
  }, []);

  return (
    <UserSettingsContext.Provider
      value={{
        locale: state.locale,
        language,
        setLanguage,
        messages: state.messages,
        handleLanguageChange,
        fetchUserSettings,
        onboardingDetails,
        setOnboardingDetails,
        fetchUserCurrencies,
        subsCurrency,
        setSubsCurrency,
        getBasedLocationSettings,
        countryCode,
        emailVerified,
        isOptIn,
        setEmailVerified,
        settingsRdy,
        setCurrencies,
        currencies,
        hasError,
        setHasError,
        reloadAdCampaign,
        setReloadAdCampaign,
      }}
    >
      {((didLoadRef.current && state.locale) || window.location.pathname === routes.CALLBACK) ? (
        <ReactIntlProvider
          locale={state.locale}
          defaultLocale="en"
          messages={state.messages}
        >
          {children}
        </ReactIntlProvider>
      ) : (
        <RocketLoader />
      )}
    </UserSettingsContext.Provider>
  );
};

UserSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserSettingsProvider, UserSettingsContext };
