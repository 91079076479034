import gql from 'graphql-tag';

export const companiesQuery = gql`
  query {
    companies {
      id
      companyName
      logoUrl
    }
  }
`;

/** @type {(attr: TemplateStringsArray | string) => string} */
export const getCompany = (
  attr = `
  companyName
  logoUrl
  location
`,
) => gql`
  query company($companyId: Int)
  {
    company(companyId: $companyId)
    {
      id
      ${attr}
    }
  }
`;

export const getCompanyCategories = gql`
  query companyCategories($companyId: ID) {
    companyCategories(companyId: $companyId) {
      id
      category
      categoryEs
    }
  }
`;

export const searchBusinessCategories = gql`
  query categories($where: String) {
    categories(where: $where) {
      id
      category
      categoryEs
    }
  }
`;

export const fetchFaqs = gql`
  query getFaqsByCompany($companyId: Int) {
    getFaqsByCompany(companyId: $companyId) {
      id
      answer
      question
    }
  }
`;

export const getExtraBusinessInfo = gql`
  query q($companyId: Int) {
    getExtraBusinessInfo(companyId: $companyId) {
      products
      openingHours {
        from {
          day
          hour
        }
        to {
          day
          hour
        }
      }
      physicLocation
      email
      phone
    }
  }
`;
