import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TwitterLogo } from '../../assets/social/socialRepository';
import BaseProfile from './BaseProfile';
import { TWITTER } from '../../utils/constants/globals';
import BaseInformation from './BaseInformation';
import { capitalize } from '../../utils';

const TwitterProfile = ({
  twitterData,
  companyName,
  businessAssociationCb,
  comingSoon
}) => {
  const intl = useIntl();

  const handleCreateOne = () => {
    const auxWindow = window.open('https://twitter.com/i/flow/signup', '_blank');
    auxWindow.focus();
  };

  const helpLink = {
    text: intl.formatMessage({
      id: 'socialProfiles.twitter.help',
      defaultMessage: 'Why should I have a Twitter account?',
    }),
    handleClick: () => {
      // TODO: redirect user to help
    },
  };

  return (
    <BaseProfile
      type={TWITTER}
      socialLogo={TwitterLogo}
      isConnected={!!twitterData.pageName}
      comingSoon={comingSoon}
    >
      <BaseInformation
        type={TWITTER}
        socialLogo={TwitterLogo}
        avatar={twitterData.avatar}
        pageName={twitterData.pageName}
        requireAttention={twitterData.requireAttention}
        connectionTitle={intl.formatMessage({
          id: companyName
            ? 'socialProfiles.connectionTitle'
            : 'socialProfiles.connectionTitle.noCompanyName',
          defaultMessage: 'Connect {companyName}\'s {socialNetwork} account',
        }, {
          companyName,
          socialNetwork: capitalize(TWITTER),
        })}
        hint={intl.formatMessage({
          id: 'socialProfiles.hint.oneAccount',
          defaultMessage: 'At the moment you can connect 1 account',
        })}
        handleCreateOne={handleCreateOne}
        helpLink={helpLink}
        businessAssociationCb={businessAssociationCb}
      />
    </BaseProfile>
  );
};

TwitterProfile.propTypes = {
  twitterData: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    requireAttention: PropTypes.bool,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default TwitterProfile;
