/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import sanitizePlans from '../../../../views/CreateAccount/sanitizePlans';
import GlobalTheme from '../../../../styled/GlobalTheme';
import { colors } from '../../../../styled/theme';
import { Button } from '../../../buttons';
import {
  PAYMENT_DETAILS_STAGE,
  SUBSCRIPTION_ERROR,
  SUBSCRIPTION_SUCCESS,
} from '../constants';
import CardDetails from './CardDetails';
import mediaQueries from '../../../../utils/mediaQueries';
import PlanProvider from '../../../../services/entities/PlanProvider';
import InitAppAccess from '../../../../hooks/permissions/initAppAccess';
import useGetCurrencySymbol from '../../../../hooks/useGetCurrencySymbol';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { PLAN_STATUS } from '../../../../utils/constants/settings';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 20px 0;
`;

const ButtonContainer = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
${GlobalTheme.breakpoints.down('sm')}{
  justify-content: center;
  padding: 30px 0 20px 0;
}
`;

const ButtonStyled = styled(Button)`
  && {
    margin: 0 12px;
  }
`;
const PlanContainer = styled.div`
  width: 100%;
  display: flex;
  ${GlobalTheme.breakpoints.up('sm')}{
    padding-bottom: 230px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 20px;
    flex-direction: column;
  }
`;
const SubTitle = styled.p`
  font-size: 24px;
  line-height: 26px;
  color: ${({ color }) => (color || 'white')};
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 20px;
  }
  ${({ noMargin }) => (noMargin ? 'margin: 0;' : '')}
`;

const Box = styled.div`
  width: 49%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
  }
`;

const Line = styled.div`
  width: 313px;
  height: 0px;
  border: 1px solid ${({ theme }) => (theme.colors.grayE9)};
  margin-bottom: 34px;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin: 25px 0;
  }
`;

const PlanBox = styled.div`
  width: 313px;
  min-height: 79px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme, isFullMng }) => (isFullMng ? theme.colors.orange : theme.colors.lightBlue)};
  color: ${({ theme }) => (theme.colors.white)};
  justify-content: center;
  paddin: .5em 0;
`;
const CardBox = styled.div`
  width: 313px;
  height: 79px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
    justify-content: center;
  }
`;

const SecondaryText = styled.p`
  color: ${({ theme }) => (theme.colors.white)};
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin: 0;
`;

const Link = styled.a`
color: ${(props) => props.theme.colors.primary};
text-decoration: underline;

`;
const UpdateButton = styled(Button)`
  && {
    background-color: ${({ theme }) => (theme.colors.green)};
  }
`;

const CardDetailsStyled = styled(CardDetails)`
&& {
    ${GlobalTheme.breakpoints.down('sm')}{
      justify-content: center;
    }
  }
`;

const ConfirmPlan = ({
  isMonthly, setActiveStage, paymentMethods, continueAction,
  loading, setLoading, promoCode, currentUser
}) => {
  const { fetchAndUpdate } = InitAppAccess();
  const [disableTrial, setDisableTrial] = useState(false);
  const [currency, setCurrency] = useState('');
  const currencySymbol = useGetCurrencySymbol(currency);
  const { subscriptionInfo } = useContext(SubscriptionContext);
  const isOptIn = (subscriptionInfo.planStatus === PLAN_STATUS.TRIAL_OPTIN);

  const [plan, setPlan] = useState({
    name: 'PRO',
    price: 0,
    id: null,
    currency: 'USD',
  });

  useEffect(() => {
    const [monthlyPlan, yearlyPlan] = sanitizePlans(promoCode, true, currentUser.profileId);
    const tmpCur = isMonthly ? monthlyPlan.currencyCode : yearlyPlan.currencyCode;
    setCurrency(tmpCur);
    let trialDuration = isMonthly ? monthlyPlan.trialDuration : yearlyPlan.trialDuration;
    if (isOptIn) {
      const question = (!promoCode.isPromoAttached && promoCode.isValid);
      trialDuration = (question ? monthlyPlan.trialDuration : 0);
      setDisableTrial(!question);
    }
    setPlan({
      ...plan,
      name: promoCode.code === "FULL_MNGT" ? 'FULL': 'PRO',
      price: isMonthly ? monthlyPlan.realPrice : yearlyPlan.realPrice,
      id: isMonthly ? monthlyPlan.id : yearlyPlan.id,
      trialDuration,
      currency: tmpCur,
    });
  }, [promoCode]);

  const subscribe = async () => {
    setLoading(true);
    const response = await PlanProvider.subscribeToPlan(
      plan.id,
      promoCode.promoCodeId,
      disableTrial,
    );
    if (response.success) {
      continueAction();
      setActiveStage(SUBSCRIPTION_SUCCESS);
      await fetchAndUpdate();
      setLoading(false);
    } else {
      setLoading(false);
      setActiveStage(SUBSCRIPTION_ERROR);
    }
  };

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Container>
          <PlanContainer>
            <Box>
              <SubTitle color={colors.green}>
                <FormattedMessage
                  id="proOfferModal.plan"
                  defaultMessage="Plan"
                />
              </SubTitle>
              {matches.smDown ? '' : <Line />}
              <PlanBox
                isFullMng={promoCode.code === "FULL_MNGT"}
              >
                <SubTitle color={colors.white} noMargin>
                  <b>
                  <FormattedMessage
                    id={`planOption.name.${plan.name}`}
                  />
                  </b>
                </SubTitle>
                <SecondaryText>
                  <FormattedMessage
                    id={isMonthly ? 'planOption.price.monthly' : 'planOption.price.yearly'}
                    defaultMessage={isMonthly ? 'USD {price} month' : 'USD {price} year'}
                    values={{
                      price: `${plan.price} /`,
                      cur: `${currencySymbol}`,
                    }}
                  />
                </SecondaryText>
              </PlanBox>
            </Box>
            {matches.smDown ? <Line /> : ''}
            <Box>
              <SubTitle color={colors.green}>
                <FormattedMessage
                  id="proOfferModal.paymentMethod"
                  defaultMessage="Payment Method"
                />
              </SubTitle>
              {matches.smDown ? '' : <Line />}
              {paymentMethods.length && (
                <CardBox>
                  <CardDetailsStyled
                    card={paymentMethods[0]}
                  />
                </CardBox>
              )}
              <Link onClick={() => setActiveStage(PAYMENT_DETAILS_STAGE)}>
                <FormattedMessage
                  id="proOfferModal.change.paymentMethod"
                  defaultMessage="Change payment method"
                />
              </Link>
            </Box>
          </PlanContainer>
          <ButtonContainer>
            <ButtonStyled type="secondary" onClick={() => (setActiveStage(PAYMENT_DETAILS_STAGE))}>
              <FormattedMessage
                id="k.back"
                defaultMessage="Back"
              />
            </ButtonStyled>
            <UpdateButton isLoading={loading} onClick={() => subscribe()}>
              <FormattedMessage
                id="k.subscribe"
                defaultMessage="subscribe"
              />
            </UpdateButton>
          </ButtonContainer>
        </Container>
      )}
    </Media>
  );
};

ConfirmPlan.propTypes = {
  isMonthly: propTypes.bool,
  setActiveStage: propTypes.func.isRequired,
  paymentMethods: propTypes.instanceOf(Array).isRequired,
  continueAction: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  setLoading: propTypes.func.isRequired,
  promoCode: propTypes.object.isRequired,
  currentUser: propTypes.object.isRequired
};

ConfirmPlan.defaultProps = {
  isMonthly: false,
  promoCode: {},
  currentUser: {}
};

export default ConfirmPlan;
