import * as Sentry from '@sentry/browser';
import { format } from 'date-fns';
import { authAppSync } from '../auth';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import {
  getPiperScoreHistoric,
  getPostsPerformanceHistoric,
  getGrowUpPages,
  getLikesCommentsHistoric,
  getPagesPerformance,
} from '../../graphql/queries';
import pageGrowMock from '../../mocks/metrics/PageGrowMock.mock.json';
import piperScoreHistoriMock from '../../mocks/metrics/PiperScorreHistoric.mock.json';
import postsPerformanceHistoriMock from '../../mocks/metrics/PostsPerformanceHistoric.mock.json';
import pagePerformanceMock from '../../mocks/metrics/PagesPerformance.mock.json';
import CommentsHistoricMock from '../../mocks/metrics/CommentsHistoric.mock.json';
import { sleep } from '../../utils';

export default class AnalyticsProvider {
  static async getGrowUpPages(interval) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    const timezone = format(new Date(), 'XXX');
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getGrowUpPages,
          variables: {
            interval,
            timezone,
          },
          fetchPolicy: 'no-cache',
        });
      } else {
        await sleep(5000);
        response = pageGrowMock;
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.getGrowUpPages,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getPiperScoreHistoric(interval) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    const timezone = format(new Date(), 'XXX');
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getPiperScoreHistoric,
          variables: {
            interval,
            timezone,
          },
          fetchPolicy: 'no-cache',
        });
      } else {
        await sleep(5000);
        response = piperScoreHistoriMock;
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.getPiperScoreHistory,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getPostsPerformanceHistoric(interval, companyId) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getPostsPerformanceHistoric,
          variables: {
            interval,
            companyId
          },
          fetchPolicy: 'no-cache',
        });
      } else {
        response = postsPerformanceHistoriMock;
        await sleep(5000);
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.getPostsAnalytics,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getLikesCommentsHistoric(interval) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    const timezone = format(new Date(), 'XXX');
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getLikesCommentsHistoric,
          variables: {
            interval,
            timezone,
          },
          fetchPolicy: 'no-cache',
        });
      } else {
        response = CommentsHistoricMock;
        await sleep(5000);
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.getComentsLikesHistoric,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getPagesPerformance(interval) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    const timezone = format(new Date(), 'XXX');
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getPagesPerformance,
          variables: {
            interval,
            timezone,
          },
          fetchPolicy: 'no-cache',
        });
      } else {
        response = pagePerformanceMock;
        await sleep(5000);
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.getPagesPerformance,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
