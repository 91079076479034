import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@material-ui/core';
import styled from 'styled-components';
import { CloseOutlineIcon } from '../../assets/icons/iconRepository';
import GlobalTheme from '../../styled/GlobalTheme';

const StyledNotificationModal = styled(MuiDialog)`
  &&{
    .MuiPaper-root{
      min-width: ${(props) => (props.minWidth || '978px')};
      padding: 48px 24px;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 450px;
      justify-content: center;
      ${GlobalTheme.breakpoints.down('md')}{
        min-width: 0;
        width: 100%;
        max-width: 690px;
      }
    }
  }
`;

const CloseButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  background-image: url('${CloseOutlineIcon}');
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
`;

const NotificationModal = ({
  open,
  setOpen,
  minWidth,
  children,
  className,
}) => (
  <StyledNotificationModal
    open={open}
    minWidth={minWidth}
    className={className}
  >
    <CloseButton
      onClick={() => setOpen(false)}
    />
    {children}
  </StyledNotificationModal>
);

NotificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  minWidth: PropTypes.string,
  className: PropTypes.string,
};

NotificationModal.defaultProps = {
  minWidth: '',
  className: '',
};

export default NotificationModal;
