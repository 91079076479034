import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PiperAdvice from '../../../../../components/globals/PiperAdvice';
import AudienceChart from './AudienceChart';
import FormTooltip from '../../../../../components/globals/FormTooltip';
import { paragraph } from '../../../../../styled/mixins';
import { colors } from '../../../../../styled/theme';
import { FACEBOOK } from '../../../../../utils/constants/globals';
import { capitalize } from '../../../../../utils';

const AudienceSizeContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  margin: 16px 0 24px;
  font-size: 14px;
  text-align: left;
`;

const Helper = styled.div`
  align-self: center;
  ${paragraph}
  font-size: 12px;
  color: ${colors.speechBubble};
`;

const CustomFormTooltip = styled(FormTooltip)`
  && {
    margin-bottom: 16px;
    width: auto;
    align-self: center;
  }
`;

const AudienceSize = ({
  potentialAudience,
  socialNetwork,
  isModal,
}) => {
  const intl = useIntl();
  return (
    <AudienceSizeContainer>
      <CustomFormTooltip
        title={intl.formatMessage({
          id: 'onboarding.tooltip.audience.potential.title',
          defaultMessage: 'Your potential audience',
        })}
        description={intl.formatMessage({
          id: 'onboarding.tooltip.audience.potential.description',
          defaultMessage: 'According to the data you have entered, you will see how this indicator will change. Feel free to change the information you have entered until the needle is in the "Recommended" zone.{linebreak}{linebreak}This is very important, as this way you can create more efficient {socialNetwork} boosts.',
        }, {
          linebreak: <br />,
          socialNetwork: capitalize(socialNetwork),
        })}
        tooltipWidth="195px"
        onLeft
      >
        <Helper>
          <FormattedMessage
            id="k.whatIsThis"
            defaultMessage="What is this?"
          />
        </Helper>
      </CustomFormTooltip>
      <AudienceChart
        socialNetwork={socialNetwork}
        lowerRange={potentialAudience.lowerRange}
        middleRange={potentialAudience.middleRange}
        upperRange={potentialAudience.upperRange}
        percent={potentialAudience.percent}
        size={potentialAudience.size}
        isModal={isModal}
      />
      {!isModal && (potentialAudience.percent <= 0.2 || potentialAudience.percent >= 0.8) && (
        <StyledPiperAdvice>
          {potentialAudience.percent <= 0.2 ? (
            <FormattedMessage
              id="settings.primaryTarget.audience.size.advice.tooSpecific"
              defaultMessage="In order to reach more people and have a bigger audience, add more interests, expand the audience's age or location. Think about what people like when using your product or service."
            />
          ) : (
            <FormattedMessage
              id="settings.primaryTarget.audience.size.advice.tooBroad"
              defaultMessage="In order to reach the adequate people, be a little more specific on the location, age and interest. With an audience too broad, your posts and boost might deliver to people not really interested."
            />
          )}
        </StyledPiperAdvice>
      )}
    </AudienceSizeContainer>
  );
};
AudienceSize.propTypes = {
  isModal: PropTypes.bool,
  socialNetwork: PropTypes.string,
  potentialAudience: PropTypes.instanceOf(Object).isRequired,
};

AudienceSize.defaultProps = {
  isModal: false,
  socialNetwork: FACEBOOK,
};

export default AudienceSize;
