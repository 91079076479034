import React, { useMemo, useState } from 'react';

import { MissingConfig } from '../utils/openAiHelpers';

export const OpenAIContext = React.createContext();

/**
 * @type {{
 *    language: string,
 *    company: Record<'name' | 'location' | 'categories', | 'logo', any> | null,
 *    appsConnected: string[],
 *    linkedinConfig: { gender: string, topics: string[], clientCountries: string[], ageRange: number[] } | null,
 *    facebookConfig: { gender: string, topics: string[], clientCountries: string[], ageRange: number[] } | null
 * }
 */
const initialState = {
  language: '',
  appsConnected: [],
  company: {
    logo: '',
    name: '',
    location: '',
    categories: [],
  },
  facebookConfig: null,
  linkedinConfig: null,
};

export default function OpenAIProvider({ children }) {
  const [config, setConfig] = useState(initialState);
  const [missingConfig, setMissingConfig] = useState(MissingConfig.None);
  const [isFetching, setIsFetching] = useState(true);

  const value = useMemo(() => {
    return {
      missingConfig,
      setMissingConfig,
      isFetching,
      setIsFetching,
      config,
      setConfig,
    };
  }, [missingConfig, setMissingConfig, isFetching, setIsFetching, config, setConfig]);

  return <OpenAIContext.Provider value={value}>{children}</OpenAIContext.Provider>;
}
