import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  materialShadows,
  borderTop,
  heading3,
  flexBox,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { ButtonLink } from '../../../../../components/buttons';
import FormController from '../../../../../components/globals/FormController';
import useCallGA from '../../../../../hooks/useCallGA';
import {
  FacebookColorIcon,
  AppleColorIcon,
} from '../../../../../assets/icons/iconRepository';
import { FACEBOOK, APPLE } from '../../../../../utils/constants/globals';
import CardPersonalBlock from './CardPersonalBlock';

const PersonalInformationCard = styled.div`
  position: relative;
  ${materialShadows};
  ${borderTop('speechBubble')};
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  ${flexBox}
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.down('lg')}{
    padding: 16px;
  }
  ${GlobalTheme.breakpoints.down('md')}{
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.h3`
  ${heading3}
  margin-bottom: 24px;
  flex: 100%;
  ${GlobalTheme.breakpoints.down('md')}{
    align-self: flex-start;
  }
`;

const Input = styled(FormController)`
  margin: 0 10px 24px;

  ${GlobalTheme.breakpoints.down('lg')}{
    margin: ${(props) => (!props.isNetworkSocial ? '0 84px 20px 0' : '0 84px 20px 24px')};
  }

  ${GlobalTheme.breakpoints.down('sm')}{
    margin: ${(props) => (!props.isNetworkSocial ? '0' : '0 0 24px')};
  }
`;

const ButtonWrapper = styled.div`
  flex: 0 0 100%;
  ${flexBox}
  justify-content: center;
`;

const ContainerFields = styled.div`

  ${(props) => props.isNetworkSocial && `
    display: flex;
    flex-direction: row;

    ${GlobalTheme.breakpoints.down('lg')}{
      display: grid;
      grid-template-columns: 17% 35% 5% 35% 100%;
    }

    ${GlobalTheme.breakpoints.down('sm')}{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    div:nth-child(5) {
      ${GlobalTheme.breakpoints.down('lg')}{
        grid-column: 2/5;
      }
    }
  `}

  ${(props) => !props.isNetworkSocial && `
    width: 100%;
    max-width: 1424px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 32px 24px;

    ${GlobalTheme.breakpoints.down('sm')}{
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  `}
`;

const ContainerSocial = styled.div`
  flex: 0 0 calc(14% - 12px);
  text-align: center;

  ${GlobalTheme.breakpoints.down('sm')}{
    text-align: left;
    flex-direction: row;
    display: flex;
  }
`;

const TitleSocial = styled.div`
  font-weight: 600;
  margin-right: 0;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin-right: 15px;
  }
`;

const SocialIcon = styled.img`
  margin-top:4px;
  margin-bottom: 8px;
  width:20px;
`;

const PersonalInformation = ({
  userInfo,
  className,
  handleSave,
  isNetworkSocial,
  typeSocial,
  loading,
}) => {
  const intl = useIntl();
  const callGA = useCallGA();

  const [personalInfo, setPersonalInfo] = useState(userInfo);
  const [updated, setUpdated] = useState(false);
  const [iconSocial, setIconSocial] = useState(null);
  const [showBox, setShowBox] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');

  useEffect(() => {
    setUpdated(false);
    setPersonalInfo(userInfo);
    const regexFBEmailHide = /@facebookauth/g;
    const regexAppleEmailHide = /@privaterelay/g;
    const isFBEmailHide = personalInfo.email.match(regexFBEmailHide);
    const isAppleEmailHide = personalInfo.email.match(regexAppleEmailHide);
    setCurrentEmail(personalInfo.email);
    setShowBox((isFBEmailHide || isAppleEmailHide));
    if ((isFBEmailHide || isAppleEmailHide)) {
      setCurrentEmail('');
    }
  }, [userInfo]);

  useEffect(() => {
    if (typeSocial === FACEBOOK) {
      setIconSocial(FacebookColorIcon);
    }
    if (typeSocial === APPLE) {
      setIconSocial(AppleColorIcon);
    }
  }, [typeSocial]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'email') { setCurrentEmail(value); }

    if (value !== userInfo[id]) {
      setUpdated(true);
    } else {
      setUpdated(Object.keys(personalInfo).some((k) => {
        const val = k === id ? value : personalInfo[k];
        return val !== userInfo[k];
      }));
    }

    setPersonalInfo({
      ...personalInfo,
      [id]: value,
    });
  };
  return (
    <PersonalInformationCard
      className={className}
    >
      <CardPersonalBlock showBox={showBox} setShowBox={setShowBox} />
      <Title>
        <FormattedMessage
          id="settings.personal.personalInformation.title"
          defaultMessage="Personal Information"
        />
      </Title>
      <ContainerFields isNetworkSocial={isNetworkSocial}>
        {isNetworkSocial && (
          <ContainerSocial>
            <TitleSocial>
              <FormattedMessage
                id="settings.personal.title.social.conection"
                defaultMessage="Logged with"
              />
            </TitleSocial>
            <SocialIcon alt="social icon" src={iconSocial} />
          </ContainerSocial>
        )}

        <Input
          id="firstName"
          onChange={handleInputChange}
          label={intl.formatMessage({
            id: 'settings.personal.personalInformation.firstName',
            defaultMessage: 'First Name',
          })}
          value={personalInfo.firstName}
          isNetworkSocial={isNetworkSocial}
          disabled={loading}
        />
        {isNetworkSocial && (<div />)}
        <Input
          id="lastName"
          onChange={handleInputChange}
          label={intl.formatMessage({
            id: 'settings.personal.personalInformation.lastName',
            defaultMessage: 'Last Name',
          })}
          value={personalInfo.lastName}
          isNetworkSocial={isNetworkSocial}
          disabled={loading}
        />
        <Input
          id="email"
          onChange={handleInputChange}
          label={intl.formatMessage({
            id: 'settings.personal.personalInformation.email',
            defaultMessage: 'E-mail',
          })}
          value={currentEmail}
          type="email"
          holderBottom={isNetworkSocial}
          messageHolder={intl.formatMessage({
            id: 'settings.personal.personalInformation.holder',
            defaultMessage: 'Here you will receive important information about your account and notifications',
          })}
          disabled={loading}
          isNetworkSocial={isNetworkSocial}
        />
      </ContainerFields>

      <ButtonWrapper>
        <ButtonLink
          disabled={!updated}
          isLoading={loading}
          onClick={() => {
            callGA({ action: 'Set preferences on', label: '(on personal & preferences)' });
            handleSave(personalInfo);
          }}
        >
          <FormattedMessage
            id="k.saveChanges"
            defaultMessage="Save Changes"
          />
        </ButtonLink>
      </ButtonWrapper>
    </PersonalInformationCard>
  );
};

PersonalInformation.propTypes = {
  userInfo: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  isNetworkSocial: PropTypes.bool,
  typeSocial: PropTypes.string,
  loading: PropTypes.bool,
};

PersonalInformation.defaultProps = {
  className: '',
  isNetworkSocial: false,
  typeSocial: '',
  loading: false,
};

export default PersonalInformation;
