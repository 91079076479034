// @ts-check
import React, { createContext, useMemo, useReducer } from 'react';

import { reducer } from './index.reducer';

export const CollaboratorCtx = createContext(null);

/** @type {(props: { children: React.ReactNode}) => JSX.Element} */
const CollaboratorProvider = ({ children }) => {
  const [collaborators, dispatch] = useReducer(reducer, []);

  const value = useMemo(() => ({ collaborators, dispatch }), [collaborators, dispatch]);

  // @ts-ignore
  return <CollaboratorCtx.Provider value={value}>{children}</CollaboratorCtx.Provider>;
};

export default CollaboratorProvider;
