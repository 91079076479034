import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import GlobalTheme from '../../../styled/GlobalTheme';
import { Button } from '../../buttons';
import { CloseOutlineIcon } from '../../../assets/icons/iconRepository';
import { colors } from '../../../styled/theme';
import StepsCreateAdAccountMobile from './components/StepsCreateAdAccountMobile';
import StepCreateAdAccount from './components/StepCreateAdAccount';
import {
  fbCreateAdAccountImage,
  fbCreateAdAccountImageEs,
} from '../../../assets/images/imageRepository';
import mediaQueries from '../../../utils/mediaQueries';

const ModalContainer = styled.div`
  background-color: white;
  left: 50%;
  max-width: 837px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: 25px 0 40px;
  overflow: auto;
  @media ${mediaQueries.mdUp} {
    max-width: 754px;
    height: 700px;
  }
`;

const Header = styled.header`
  padding: 0 16px;
  margin-bottom: 20px;
  @media ${mediaQueries.mdUp} {
    padding: 0 40px;
  }
`;
const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 12px;
`;

const SubTitle = styled.h2`
  font-size: 16px;
  color: ${colors.grayMediaLibrary};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  @media ${mediaQueries.mdUp} {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px;
    padding: 0 42px;
  }
`;
const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 32px 26px 0;
  @media ${mediaQueries.mdUp} {
    justify-content: flex-end;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 4px;
  ${GlobalTheme.breakpoints.up('sm')} {
    margin-top: 16px;
    margin-right: 16px;
  }
`;
const CloseIcon = styled.img`
  width: 30px;
  height: 30px;
`;
const Link = styled.a`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryLighter};
  text-decoration: underline;
`;
const HighlightText = styled.span`
  color: ${({ theme }) => theme.colors.black3E};
  font-weight: bold;
`;

const StepsCreateAdAccountModal = ({ onCloseModal }) => {
  const intl = useIntl();
  const [fbImage, setFbImage] = useState({});
  const isMobile = window.matchMedia(mediaQueries.mdDownLess);
  const URL_FACEBOOK = 'https://www.facebook.com/';

  const handleCreateAdAccount = () => {
    window.open(URL_FACEBOOK, '_blank', 'noopener, noreferrer');
  };

  const steps = [
    {
      number: 1,
      description: isMobile ? (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step1.mobile"
          defaultMessage="Go to your business page on <link>Facebook</link> on your dekstop. In the menu on the left, click on Publishing tools, in the Business section."
          values={{
            link: (...chunks) => (
              <Link
                href={URL_FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      ) : (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step1"
          defaultMessage="Sign in to your <link>Campaign Manager.</link>"
          values={{
            link: (...chunks) => (
              <Link
                href={URL_FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      ),
      image: fbImage.step1,
      maxWidthMobile: '200px',
      marginTopMobile: '16px',
    },
    {
      number: 2,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step2"
          defaultMessage="On the menu on the left, click on <h>Settings.</h>"
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step2,
      maxWidthMobile: '165px',
      marginTopMobile: '16px',
    },
    {
      number: 3,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step3"
          defaultMessage="In the submenu, click <h>Business Assets.</h>"
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step3,
      maxWidthMobile: '210px',
      marginTopMobile: '16px',
    },
    {
      number: 4,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step4"
          defaultMessage="In the upper right corner, click <h>Add assets.</h>"
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step4,
      maxWidthMobile: '267px',
      marginTopMobile: '76px',
    },
    {
      number: 5,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step5"
          defaultMessage="In the window that appears, click on <h>Advertising.</h>"
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step5,
      maxWidthMobile: '262px',
      marginTopMobile: '36px',
    },
    {
      number: 6,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step6"
          defaultMessage="In the menu that appears, click on <h>Ad Account.</h>"
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step6,
      maxWidthMobile: '263px',
      marginTopMobile: '78px',
    },
    {
      number: 7,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step7"
          defaultMessage="In the options menu, click on <h>Create a new ad account.</h>"
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step7,
      maxWidthMobile: '279px',
      marginTopMobile: '63px',
    },
    {
      number: 8,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step8"
          defaultMessage="Fill out the information to create your <h>Ad Account.</h> Write the name of the acocunt (for example your business name), select the time zone that best apply for your business, and define the currencu you want to use when advertising."
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step8,
      maxWidthMobile: '264px',
      marginTopMobile: '30px',
    },
    {
      number: 9,
      description: (
        <FormattedMessage
          id="socialProfiles.facebook.createAdAccount.step9"
          defaultMessage="Remember to <h>come back</H> after you create your ad account on Facebook."
          values={{
            h: (...chunks) => <HighlightText>{chunks}</HighlightText>,
          }}
        />
      ),
      image: fbImage.step9,
      maxWidthMobile: '74px',
      marginTopMobile: '96px',
      hideImage: true,
    },
  ];

  useEffect(() => {
    if (intl.locale === 'es') {
      setFbImage(fbCreateAdAccountImageEs);
    } else {
      setFbImage(fbCreateAdAccountImage);
    }
  }, [intl.locale]);
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <ModalContainer>
          <CloseButtonWrapper onClick={onCloseModal}>
            <CloseIcon src={CloseOutlineIcon} alt="Close" />
          </CloseButtonWrapper>
          <Header>
            <Title>
              <FormattedMessage
                id="socialProfiles.facebook.videoModal.adAccount.title"
                defaultMessage="Create Ad Account"
              />
            </Title>
            <SubTitle>
              <FormattedMessage
                id="socialProfiles.facebook.videoModal.adAccount.subtitle"
                defaultMessage="You have previously added an Ad Account on Mexican Pesos."
              />
            </SubTitle>
          </Header>
          <Content>
            {matches.mdDownLess ? (
              <StepsCreateAdAccountMobile steps={steps} />
            ) : (
              <>
                {steps.map((step) => (
                  <StepCreateAdAccount step={step} />
                ))}
              </>
            )}
          </Content>
          <Footer>
            <Button onClick={handleCreateAdAccount}>
              <FormattedMessage id="k.continue" defaultMessage="Continue" />
            </Button>
          </Footer>
        </ModalContainer>
      )}
    </Media>
  );
};

export default StepsCreateAdAccountModal;
