import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { authAppSync } from '../../services/auth';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import { upgradeToBusinessUser, downgradeToProUser } from './mutations/planChanges.mutation';

export default class UserProvider {
    static async upgradeToABusinessUser(userId, currencyCode, countryCode) {
        let result = {
            message: '',
            success: false,
            data: null,
          };
        try {
            if (ENV === ENVIRONMENTS.local) {
                return {
                  data: {},
                  message: 'success',
                  success: true,
                };
              }
              const response = await authAppSync.client.mutate({
                mutation: upgradeToBusinessUser,
                fetchPolicy: 'no-cache',
                variables: { userId, currencyCode, countryCode },
              });
              result = {
                message: 'success',
                success: true,
                data: response.data,
              };
            } catch (err) {
              Sentry.captureException(err);
              result.message = err.message;
            }
            return result;
    }

    static async downgradeToAProUser(userId, companyIdToKeep, currencyCode, countryCode) {
      let result = {
        message: '',
        success: false,
        data: null,
      };
    try {
        if (ENV === ENVIRONMENTS.local) {
            return {
              data: {},
              message: 'success',
              success: true,
            };
          }
          const response = await authAppSync.client.mutate({
            mutation: downgradeToProUser,
            fetchPolicy: 'no-cache',
            variables: { userId, companyIdToKeep, currencyCode, countryCode },
          });
          result = {
            message: 'success',
            success: true,
            data: response.data,
          };
        } catch (err) {
          Sentry.captureException(err);
          result.message = err.message;
        }
        return result;
    }
}