/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiSlider from '@material-ui/core/Slider';
import { colors } from '../../styled/theme';
import { flexBox, paragraph } from '../../styled/mixins';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import Currency from './Currency';

const StyledSlider = styled(MuiSlider)`
  && {
    height: 16px;
    flex: 1 0 auto;
    width: auto;
    .MuiSlider-thumb {
      height: 32px;
      width: 32px;
      background-color: ${colors.lightPurple};
      margin-top: -8px;
      margin-left: -16px;
      &:focus, &:hover, &:active {
        box-shadow: 'inherit';
      }
    }
    .MuiSlider-track {
      color: ${colors.lightPurple};
      height: 16px;
      border-radius: 8px;
    }
    .MuiSlider-valueLabel {
      left: calc(-50% + 10px);
      top: calc(0% - 20px);
      & * {
        background: transparent;
        color: ${colors.text02};
        font-size: 18px;
      }
    }
    .MuiSlider-rail {
      color: ${colors.grayE9};
      opacity: 1;
      height: 16px;
      border-radius: 8px;
    }
  }
`;

const SliderContainer = styled.div`
  ${flexBox}
  padding-bottom: 30px;
`;

const SliderLabel = styled.p`
  ${paragraph}
  font-size: 14px;
  margin-right: 8px;
`;

const Slider = ({
  label,
  currency,
  className,
  ...props
}) => {
  const { dataLayerPush } = useContext(AnalyticsContext);
  const sliderValueFormat = (value) => {
    /*
    Posible bug
    */
    if (currency) {
      return (
        <Currency
          value={value}
          currency={currency}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      );
    }
    return value;
  };

  return (
    <SliderContainer
      alignItems="center"
      className={className}
    >
      {label && (
        <SliderLabel>
          {label}
        </SliderLabel>
      )}
      <StyledSlider
        aria-label="slider"
        getAriaValueText={sliderValueFormat}
        valueLabelFormat={sliderValueFormat}
        onClick={() => {
          dataLayerPush({
            pagePath: '/postflow/schedule',
            pathTitle: 'Post flow',
            dataCategory: 'Post flow',
            dataAction: 'Use the slider on boosting section',
            event: 'action',
          });
        }}
        {...props}
      />
    </SliderContainer>
  );
};

Slider.propTypes = {
  label: PropTypes.string,
  currency: PropTypes.string,
  className: PropTypes.string,
};

Slider.defaultProps = {
  label: null,
  currency: null,
  className: '',
};

export default Slider;
