import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { paragraph } from '../../../../../styled/mixins';
import {
  PaymentRowContainer,
  PaymentGoToCell,
  PaymentCell,
  PaymentMobileOverlayCell,
} from './PaymentRow';

const Header = styled.p`
  ${paragraph}
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
`;

const PaymentHeadersRow = () => (
  <PaymentRowContainer>
    <PaymentCell
      mobileHidden
    >
      <Header>
        <FormattedMessage
          id="k.date"
          defaultMessage="Date"
        />
      </Header>
    </PaymentCell>
    <PaymentCell>
      <Header>
        <FormattedMessage
          id="k.account"
          defaultMessage="Account"
        />
      </Header>
    </PaymentCell>
    <PaymentCell>
      <Header>
        <FormattedMessage
          id="k.description"
          defaultMessage="Description"
        />
      </Header>
    </PaymentCell>
    <PaymentCell
      mobileHidden
    >
      <Header>
        <FormattedMessage
          id="k.amount"
          defaultMessage="Amount"
        />
      </Header>
    </PaymentCell>
    <PaymentGoToCell />
    <PaymentMobileOverlayCell />
  </PaymentRowContainer>
);

export default PaymentHeadersRow;
