import { TRIAL_DURATION } from '../../../../utils/constants/settings';

const en = {
  'pricing.covid.offer': 'COVID Relief{lineBreak}Special Offer',
  'pricing.covid.relief': 'COVID Relief Offer',
  'pricing.cicc.offer': 'CICC Offer',
  'pricing.asem.offer': 'ASEM Offer',
  'pricing.amai.offer': 'AMAI Offer',
  'pricing.apren.offer': 'APREN Offer',
  'pricing.COVIDPricingDisclaimer': '*Social Piper subscription fees will NOT be charged during the free trial. You can cancel at any time. Advertising fees / commissions may apply. <a>See Terms of Service</a>.{lineBreak}1.You can activate your account after you finish your trial period (or earlier if you desire).{lineBreak}2. Social Piper regular monthly subscription cost is {price}/month.{lineBreak}3. SocialPiper regular yearly subscription cost is {yearPrice}/year.',
  'pricing.hero.piperTip': 'Try us out! I’ll give you smart tips and guide you toward business goals...',
  'pricing.onceYouActivate': 'Once you activate your account, we will give you <c>{price} that will be refunded to your credit card.</c> You can use this money to reach more users or cover other needs of your business.',
  'pricing.price': `Start your {lineBreak}${TRIAL_DURATION}-day free trial*`,
  'pricing.hero.price': 'Start your {lineBreak}<strong>7-day</strong> {lineBreak}free trial*',
  'pricing.subtitle': 'The most cost-effective advertising platform for small business... Social media made easy.',
  'pricing.trial.cta': 'Start my Free Trial!',
  'pricing.trial.message': '<p><b>Get your 7 day Free Trial!</b> Try it out and start growing your business on social. You will be charged <b>$99/month</b> if you continue after the free trial.</p><p><b>No risk. No obligation.</b></p>',
  'pricing.trial.messageLong': '<p><b>No risk. No obligation.</b></p><p>You will be charged <b>$99/month</b> if you continue after the free trial.</p>',
  'pricing.trial.no-credit-card-required': 'No risk. No obligation.',
  'pricing.trial.noRisk': 'No risk. No obligation.',
  'pricing.trial.small': '* Social Piper subscription fees will NOT be charged during the free trial. Advertising fees / commissions may apply.',
  'pricing.trial.title': '7 day free trial*',
  'pricing.weWantToHelp': 'We want to help the small businesses who’ve been impacted by this crisis get back to business. Because of this we are reducing our subscription price.<sup>1</sup>',
  'pricing.youWillBeCharged': 'You will be charged when you activate your account.',
  'pricing.plan.description.lite': 'Our limited plan, where you can use our basic functions to post in social media.',
  'pricing.plan.description.pro': 'Take advantage of Social Piper’s full power & features, and make your business thrive in social media.',
  'pricing.plan.description.business': 'All our features bundled together for businesses or small agencies with more than one person dealing with social media or with several products.',
  'pricing.plan.business.content1':  '+1 Account per social network.',
  'pricing.plan.business.content2': 'Multi-user access.',
  'pricing.plan.business.content3': 'Content and boost review and approval.',
  'pricing.plan.business.content4': 'Access via web and mobile app.',
  'pricing.plan.business.content5': 'Multi Company',
  'pricing.plan.pro.content1': 'Access +1000 templates ready to use.',
  'pricing.plan.pro.content2': 'Access to Social Piper Academy. {values}',
  'pricing.plan.pro.content2.2': 'Access to Social Piper Academy.',
  'pricing.plan.pro.content3': 'Create advertising campaigns.',
  'pricing.plan.pro.content4': 'Monitor your competitors.',
  'pricing.plan.pro.content5': 'Access via web and mobile app.',
  'pricing.plan.business.content1': 'Connect +1 business.',
  'pricing.plan.business.content2': 'Multi-user access.',
  'pricing.plan.business.content3': 'Content and boost review and approval.',
  'pricing.plan.business.content4': 'Access via web and mobile app.',
  'pricing.plan.lite.content1': '10 posts per month.',
  'pricing.plan.lite.content2': 'Use Piper recommendation templates.',
  'pricing.plan.lite.content3': 'Access via web.',
  'pricing.plan.content1': 'Connect FB, IG, and TW.',
  'pricing.plan.content2': 'Use recommended templates.',
  'pricing.plan.content3': '10 post per month.',
  'pricing.plan.content4': 'Reply to comments.',
  'pricing.plan.content5': 'Use and edit thousands of templates.',
  'pricing.plan.content6': 'Boost your posts.',
  'pricing.plan.content7': 'Connect +1 account on FB, IG and TW.',
  'pricing.plan.content8': 'Multi-user access.',
  'pricing.plan.content9': 'Content and boost review and approval.',
  'pricing.plan.details.basic1': 'Connect your Facebook, Instagram, and Twitter business accounts (1 for each social network).',
  'pricing.plan.details.basic2': 'Use graphic templates suggested by Piper, your personal assistant, or upload your content.',
  'pricing.plan.details.basic3': 'Piper will recommend you when and where to post! Do it for one or multiple social networks in 3 simple steps (Up to 10 posts per month).',
  'pricing.plan.details.basic4': 'Quickly reply to comments on your social, easily from one place.',
  'pricing.plan.details.basic5': "Access and reply  to messages on your business's Facebook Messenger.",
  'pricing.plan.details.basic6': 'Monitor your competitors and stay up to date on what they do.',
  'pricing.plan.details.basic7': 'Manage posts you have scheduled and easily edit them in the calendar view.',
  'pricing.plan.details.caption.pro': "In addition to what the Basic account includes, you'll also be able to:",
  'pricing.plan.details.pro1': 'Publish with no limit to all your connected social networks.',
  'pricing.plan.details.pro2': 'Access thousands of graphic templates.',
  'pricing.plan.details.pro3': 'Easily edit designs in our online tool.',
  'pricing.plan.details.pro4': 'Effortlessly create Facebook ad boosts and reach more people.',
  'pricing.plan.details.pro5': 'Keep control and visibility of your boosts budget.',
  'pricing.plan.details.pro6': 'Add as many competitors as you want to monitor their activity.',
  'pricing.plan.details.caption.business': 'It includes everything from the Basic and Pro Plan, plus:',
  'pricing.plan.details.business1': 'Multi-user access for your team.',
  'pricing.plan.details.business2': 'Connect more than one Facebook, Instagram, and Twitter account.',
  'pricing.plan.details.business3': 'Content and boost review and approval before being published or activated.',
};

export default en;
