import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import App from './App';
import Rehydrated from './Rehydrated';
import CollaboratorProvider from './components/ManageCollaboratorsForm/index.provider';
import PlanProvider from './components/PlanSelector/index.provider';
import AlertsProvider from './contexts/AlertsContext';
import AnalyticsProvider from './contexts/AnalyticsContext';
import GA4Provider from './contexts/GA4Context';
import HJProvider from './contexts/HJContexts';
import OpenAIProvider from './contexts/OpenAIProiver';
import { QueryStringProvider } from './contexts/QueryStringContext';
import SocialProvider from './contexts/SocialContext';
import { UserSettingsProvider } from './contexts/UserSettingsContext';
import './index.css';
import * as serviceWorker from './serviceWorker';
import AuthFactory, { publicAppSync } from './services/auth';
import AuthService from './services/auth/AuthService';
import GlobalStyles from './styled/GlobalStyles';
import GlobalTheme from './styled/GlobalTheme';
import theme from './styled/theme';
import { ENV, ENVIRONMENTS, GTM_KEY, SENTRY_DNS } from './utils/constants/globals';
import StepsProvider from './views/BusinessOnBoarding/index.provider';
import AICalendarProvider from './views/Scheduler/components/Calendar/contexts/AICalendarContext';

// Init for AppSync and Auth0 clients
AuthFactory.init();
if (AuthService.isAuthenticated()) {
  AuthFactory.initAuthAppSync(AuthService.getIdToken());
}

if (ENV !== ENVIRONMENTS.local) {
  Sentry.init({
    dsn: SENTRY_DNS,
    environment: ENV,
  });
}
TagManager.initialize({
  gtmId: GTM_KEY,
});

const WithProvider = () => (
  <ApolloProvider client={publicAppSync.client}>
    <Rehydrated>
      <QueryStringProvider>
        <UserSettingsProvider>
          <AnalyticsProvider>
            <GA4Provider>
              <HJProvider>
                <React.StrictMode>
                  <StyledThemeProvider theme={theme}>
                    <GlobalStyles />
                    <ThemeProvider theme={GlobalTheme}>
                      <AlertsProvider>
                        <SocialProvider>
                          <OpenAIProvider>
                            <AICalendarProvider>
                              <PlanProvider>
                                <StepsProvider>
                                  <CollaboratorProvider>
                                    <App />
                                  </CollaboratorProvider>
                                </StepsProvider>
                              </PlanProvider>
                            </AICalendarProvider>
                          </OpenAIProvider>
                        </SocialProvider>
                      </AlertsProvider>
                    </ThemeProvider>
                  </StyledThemeProvider>
                </React.StrictMode>
              </HJProvider>
            </GA4Provider>
          </AnalyticsProvider>
        </UserSettingsProvider>
      </QueryStringProvider>
    </Rehydrated>
  </ApolloProvider>
);

ReactDOM.render(<WithProvider />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
