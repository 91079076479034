/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@material-ui/core';
import styled from 'styled-components';
import GlobalTheme from '../../styled/GlobalTheme';
import { colors } from '../../styled/theme';

export const StyledModal = styled(MuiDialog)`
  &&{
    .MuiPaper-root{
      min-width: 446px;
      padding: ${({ theme }) => theme.space * 2}px ${({ theme }) => theme.space * 3}px;
      ${GlobalTheme.breakpoints.down('md')}{
        min-width: unset;
        width: 100%;
        max-width: 690px;
      }
      ${GlobalTheme.breakpoints.down('sm')}{
        background: ${colors.screenBackground};
      }
    }
  }
`;

const Header = styled.div``;
const CloseButton = styled.div``;
const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;

  ${GlobalTheme.breakpoints.down('xs')}{
    padding-top:15px;
  }
`;
export default function Modal({
  open = false,
  children,
  className,
  title,
  ...props
}) {
  return (
    <StyledModal
      fullScreen={window.innerWidth < GlobalTheme.breakpoints.values.md}
      open={open}
      className={className}
      {...props}
    >
      <Header>
        <Title>{title}</Title>
        <CloseButton />
      </Header>
      {children}
    </StyledModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  className: '',
  title: '',
  open: false,
};
