import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import {
  getAdAccounts,
  getFacebookPages2Setup,
  retrieveTwitterTimeline,
  getIGPages2Setup,
  fetchLinkedinAdAccounts,
} from '../../graphql/queries';
import {
  setAdAccount,
  unlinkFacebookAccount,
  unlinkInstagramAccount,
  unlinkTwitterAccount,
  unlinkLinkedinAccount,
  socialLoginResultMirror,
  activateFacebookPage,
  fetchFacebookData,
  fetchInstagramData,
  fetchLinkedInData,
  activateInstagramPage,
  createLinkedinAdAccount,
  updateLinkedinAdAccount,
  unlinkTiktokAccount,
} from '../../graphql/mutations';
import {
  ENV,
  ENVIRONMENTS,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  TIKTOK,
} from '../../utils/constants/globals';
import AuthService from '../auth/AuthService';
import adAccountsMocked from '../../mocks/settings/adAccounts.mock.json';
import igPagesMock from '../../mocks/users/igPages2Setup.mock.json';
import fbPagesMock from '../../mocks/users/fbPages2Setup.mock.json';

import linkedinAdAccountsMocked from '../../mocks/settings/linkedinAdAccounts.mock.json';
import { UserProvider } from '.';

export default class SocialProfilesProvider {
  static async fetchSocialProfilesInformation(companyId) {
    let result = {
      message: '',
      success: false,
      data: null,
    };

    try {
      const response = await UserProvider.fetchConnectedAccounts(false, companyId);
  
      if (!response.success) return result;
      const connectedAccounts = response.data;

      const emptySocial = {
        avatar: '',
        pageName: '',
      };

      let socialNetworks = {
        twitter: { ...emptySocial },
        facebook: { ...emptySocial },
        instagram: { ...emptySocial },
        linkedin: { ...emptySocial },
        tiktok: { ...emptySocial },
      };

      socialNetworks = {
        ...socialNetworks,
        ...connectedAccounts,
      };

      result = {
        message: 'success',
        success: true,
        data: socialNetworks,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
  
    return result;
  }

  static async fetchFacebookAdAccounts(attr = null) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: attr ? getAdAccounts(attr) : getAdAccounts(),
          fetchPolicy: 'network-only',
        });
        data = response.data.getAdAccounts;
      } else {
        data = adAccountsMocked;
      }

      data = data.sort((a) => (a.isSelected ? -1 : 1));

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchFacebookPages2Setup() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getFacebookPages2Setup,
          fetchPolicy: 'network-only',
        });
        data = response.data.facebookPages;
      } else {
        data = fbPagesMock.data.facebookPages;
      }
      if (data) {
        result = {
          message: 'success',
          success: true,
          data,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchIGPages2Setup() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getIGPages2Setup,
          fetchPolicy: 'network-only',
        });
        data = response.data.instagramPages;
      } else {
        data = igPagesMock.data.instagramPages;
      }
      if (data) {
        result = {
          message: 'success',
          success: true,
          data,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async activateFacebookPage(facebookPageId) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.mutate({
          mutation: activateFacebookPage,
          variables: {
            facebookPageId,
          },
        });
      } else {
        response = {
          info: '',
          success: true,
        };
      }
      if (response && response.data) {
        const { activateFacebookPage: afp } = response.data;
        result = {
          message: afp.info,
          success: afp.success,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async activateIGPage(igPageId) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.mutate({
          mutation: activateInstagramPage,
          variables: {
            igPageId,
          },
        });
      } else {
        response = {
          info: '',
          success: true,
        };
      }
      if (response && response.data) {
        const { activateInstagramPage: ap } = response.data;
        result = {
          message: ap.info,
          success: ap.success,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateFacebookAdAccount(selectedAdAccount, adAccounts) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const { id, __typename, isSelected, ...adAccount } = selectedAdAccount;
      const response = await authAppSync.client.mutate({
        mutation: setAdAccount,
        variables: {
          userId: 1, // Necessary for back compatibility with current front
          selectedAdAccount: adAccount,
        },
      });
      const newAdAccount = response.data.setAdAccount;

      const updatedAdAccounts = adAccounts.filter(
        (a) => a.accountId !== newAdAccount.accountId && a.currency === newAdAccount.currency,
      );

      const previousSelected = updatedAdAccounts.find((a) => a.isSelected);
      if (previousSelected) {
        previousSelected.isSelected = false;
      }

      result = {
        message: 'success',
        success: true,
        data: [newAdAccount, ...updatedAdAccounts],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async disconnect(socialNetwork, socialNetworks) {
    const emptySocial = {
      avatar: '',
      pageName: '',
    };
    let result = {
      message: '',
      success: false,
      data: {
        ...socialNetworks,
        [socialNetwork]: { ...emptySocial },
        ...(socialNetwork === FACEBOOK &&
          socialNetworks.instagram.pageName && {
            [INSTAGRAM]: { ...emptySocial },
          }),
      },
    };
    const unlinkMutation = {
      [FACEBOOK]: unlinkFacebookAccount,
      [INSTAGRAM]: unlinkInstagramAccount,
      [TWITTER]: unlinkTwitterAccount,
      [LINKEDIN]: unlinkLinkedinAccount,
      [TIKTOK]: unlinkTiktokAccount,
    };
    try {
      await authAppSync.client.mutate({
        mutation: unlinkMutation[socialNetwork],
        fetchPolicy: 'no-cache',
        variables: {},
      });
      result = {
        ...result,
        message: 'success',
        success: true,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async loginResult(network, status, reason) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      await authAppSync.client.mutate({
        mutation: socialLoginResultMirror,
        variables: {
          authId: AuthService.getAuthID(),
          network: network.toLowerCase(),
          status,
          reason,
        },
      });
      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async initFacebookData() {
    try {
      await authAppSync.client.mutate({
        mutation: fetchFacebookData,
      });
      return true;
    } catch {
      return false;
    }
  }

  static async initTwitterData() {
    try {
      await authAppSync.client.query({
        query: retrieveTwitterTimeline,
      });
      return true;
    } catch {
      return false;
    }
  }

  static async initInstagramData() {
    try {
      await authAppSync.client.mutate({
        mutation: fetchInstagramData,
      });
      return true;
    } catch {
      return false;
    }
  }

  static async initLinkedInData() {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const data = await authAppSync.client.mutate({
        mutation: fetchLinkedInData,
      });
      result.success = true;
      result.data = data.data;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchLinkedinAdAccount() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: fetchLinkedinAdAccounts(),
          fetchPolicy: 'network-only',
        });
        data = response.data.fetchLinkedinAdAccounts;
      } else {
        data = linkedinAdAccountsMocked.data.fetchLinkedinAdAccounts;
      }
      data = data.sort((a) => (a.isSelected ? -1 : 1));

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async createLinkedinAdAccount(accountName, currency = 'USD') {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV !== ENVIRONMENTS.local) {
        await authAppSync.client.mutate({
          mutation: createLinkedinAdAccount,
          variables: {
            accountName,
            currency,
          },
        });
      }
      result = {
        message: 'success',
        success: true,
        data: true,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateLinkedinAdAccount(accountId, accountName, currency) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV !== ENVIRONMENTS.local) {
        await authAppSync.client.mutate({
          mutation: updateLinkedinAdAccount,
          variables: {
            accountId,
            accountName,
            currency,
          },
        });
      }
      result = {
        message: 'success',
        success: true,
        data: true,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
