import { useContext, useEffect, useMemo, useState } from 'react';

import { CollaboratorCtx } from '../components/ManageCollaboratorsForm/index.provider';
import { _deepCollabCloning } from '../components/ManageCollaboratorsForm/index.reducer';
import { UserProvider } from '../services/entities';
import useCompany from './useCompany';
import { AlertsContext } from '../contexts/AlertsContext';
import { useIntl } from 'react-intl';

const initialAssigments = {
  hasBoost: false,
  hasPost: false,
  hasCommunity: false,
  facebookPermission: false,
  instagramPermission: false,
  twitterPermission: false,
  linkedinPermission: false,
};

const initialState = {
  id: null,
  draft: true,
  collapsed: false,
  firstName: '',
  lastName: '',
  email: '',
  assignments: [],
};

export default () => {
  const intl = useIntl();
  const { showError, showAlert } = useContext(AlertsContext);
  const { collaborators, dispatch } = useContext(CollaboratorCtx);
  const { companies } = useCompany();

  const standaloneCollaboratorReset = useMemo(() => {
    const assignments = companies.map((company) => {
      return {
        companyId: company.id,
        ...initialAssigments,
      };
    });

    return {
      ...initialState,
      assignments,
    };
  }, [companies.length]);

  const [standaloneCollaborator, setStandaloneCollaborator] = useState(standaloneCollaboratorReset);

  useEffect(() => {
    UserProvider.allCollaborators()
      .then((response) => {
        if (!response.success) {
          dispatch({ type: 'init', payload: [] });
          return;
        }

        dispatch({ type: 'init', payload: response.data });
      })
      .catch((err) => console.error(err));
  }, [companies.length]);

  /** @type {(args: { isDraft: boolean, body: any }) => Promise<void>} */
  const saveOrUpdate = async ({ isDraft, body }) => {
    const { id, draft, collapsed, ...rest } = body;

    if (isDraft) {
      // this condition if for those collaborators do not exists on db
      const response = await UserProvider.createCollaborator(rest);

      if (!response.success) {
        showError(
          intl.formatMessage({ id: 'alert.collaborator.duplicated' })
        )
        return;
      }

      dispatch({ type: 'create_collaborator', payload: { id, collaborator: body } });
      // dispatch({ type: 'add_draft_collaborator' }); @deprecated
      showAlert(
        intl.formatMessage({ id: 'alert.collaborator.added' })
      )

      return;
    }

    const response = await UserProvider.updateCollaborator({ id, ...rest });

    if (!response.success) {
      showError(
        intl.formatMessage({ id: 'alert.collaborator.updated.error' })
      );
      return;
    }

    showAlert(
      intl.formatMessage({ id: 'alert.collaborator.updated' })
    );
  };

  /** @type {(id: number, args: { path: string, value: any }) => void} */
  const alterById = (id, { path, value }) => {
    dispatch({ type: 'alter_draft_collaborator', payload: { id, path, value } });
  };

  /** @type {(args: { id: number, isDraft: boolean }) => Promise<void>} */
  const deleteById = async ({ id, isDraft }) => {
    let success = true;

    if (!isDraft) {
      const response = await UserProvider.deleteCollaborator(id);
      success = response.success;
    }

    if (success) {
      dispatch({ type: 'destroy_collaborator', payload: { id } });
      showAlert(
        intl.formatMessage({ id: 'alert.collaborator.deleted' })
      );
      return;
    }

    showError(
      intl.formatMessage({ id: 'alert.collaborator.deleted.error' })
    );
  };

  /** @type {(id: number) => void} */
  const collapse = (id) => {
    dispatch({ type: 'collapse', payload: { id } });
  };

  /** @type {(undirtyCollaborator: Collaborator) => void} */
  const cancel = (undirtyCollaborator) => {
    dispatch({ type: 'cancel', payload: undirtyCollaborator });
  };

  const alterStandalone = ({ path, value }) => {
    const o = { ...standaloneCollaborator };
    setStandaloneCollaborator((_) => ({ ..._deepCollabCloning(o, { path, value }) }));
  };

  const resetStandalone = () => {
    setStandaloneCollaborator(standaloneCollaboratorReset);
  };

  return {
    collapse,
    saveOrUpdate,
    alterById,
    deleteById,
    cancel,
    collaborators,
    standalone: {
      collaborator: standaloneCollaborator,
      alter: alterStandalone,
      reset: resetStandalone,
    },
  };
};
