/* eslint-disable react/style-prop-object */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Currency from '../../../components/globals/Currency';
import { colors } from '../../../styled/theme';
import { label, paragraph } from '../../../styled/mixins';
import { WebViewContext } from '../../../contexts/WebViewContext';
import GlobalTheme from '../../../styled/GlobalTheme';

const PlansOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isAppIOS }) => ((isAppIOS) ? 'space-around;' : 'space-between;')};
  width: 100%;
  margin: 0 20px;
  ${GlobalTheme.breakpoints.down('lg')}{
    margin: 0;
  };
  ${GlobalTheme.breakpoints.down('md')}{
    justify-content: space-around;
  };
`;

const Plan = styled.div`
  position: relative;
  background: white;
  border-radius: 5px;
  height: 160px;
  width: 210px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #C4C4C4;
  ${GlobalTheme.breakpoints.down('md')}{
    width: 165px;
  };
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 135px;
    height: 135px;
  };

  &:before {
    content: "";
    position: absolute;
    left: 10px;
    width: 16px;
    height: 16px;
    border: 2px solid ${colors.gray90};
    border-radius: 100%;
    top: 30px;
    bottom: 0;
  }
  ${({ active }) => active && css`
    border-color: ${colors.speechBubble};
    &:before {
      border-color: ${colors.speechBubble};
    }
    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background: ${colors.speechBubble};
      border-radius: 100%;
      top: 36px;
      left: 16px;
      bottom: 0;
    }
  `}
`;

const Type = styled.p`
  ${label}
  font-size: 20px;
  margin-left: 45px;
  margin-top: 25px;
  margin-bottom: 15px;
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 16px;
  };
`;

const RealPrice = styled.p`
  ${paragraph}
  font-size: 18px;
  margin: 0 8px;
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 12px;
  };
`;

const TrialDaysHolder = styled.div`
  color: ${colors.white};
  background-color: ${colors.lightBlue};
  padding: 2px 5px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
  position: absolute;
  top: -10px;
  font-size: 14px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 25px;
  right: 25px;
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 10px;
  };
`;

const TextRef = styled.div`
  font-size: 14px;
  margin: 5px 8px;
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 10px;
  };
`;

const PlanSelection = ({
  plans,
  selectedPlanIndex,
  setSelectedPlanIndex,
  id,
  className,
}) => {
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const intl = useIntl();
  const [textMonthPeriod, setTextMonthPeriod] = useState('');
  const [textYearPeriod, setTextYearPeriod] = useState('');
  useEffect(() => {
    setTextMonthPeriod(intl.formatMessage({
      id: 'planOption.selected.period.monthly',
      defaultMessage: 'monthly',
    }));
    setTextYearPeriod(intl.formatMessage({
      id: 'planOption.selected.period.yearly',
      defaultMessage: 'yearly',
    }));
  }, []);

  return (

    <PlansOptions
      className={className}
      id={id}
      isAppIOS={isAppIOS}
    >
      {plans.map((plan, index) => (
        <Plan
          active={selectedPlanIndex === index}
          key={`Plan_${plan.id}`}
          onClick={() => setSelectedPlanIndex(index)}
        >
          <Type>
            <FormattedMessage
              id={`k.${plan.isMonthly ? 'monthly' : 'yearly'}`}
              defaultMessage={`${plan.isMonthly ? 'Monthly' : 'Yearly'}`}
            />
          </Type>
          <RealPrice>
            {plan.currencyCode}
            {' '}

            {((isApp || isAppIOS) ? (
              <Currency
                value={plan.realPrice}
                style="currency"
                currency={plan.currencyCode}
              />
            ) : (
              <Currency
                value={plan.realPrice}
                style="currency"
                currency={plan.currencyCode}
                minimumFractionDigits={(!Number.isInteger(plan.realPrice)) ? 2 : 0}
                maximumFractionDigits={2}
              />
            ))}
          </RealPrice>
          <TextRef>
            <FormattedMessage
              id="planOption.selected.text.info"
              defaultMessage="Facturado {period} por {currency} {price}"
              values={{
                period: plan.isMonthly ? textMonthPeriod : textYearPeriod,
                currency: plan.currencyCode,
                price: (
                  <Currency
                    value={plan.realPrice}
                    style="currency"
                    currency={plan.currencyCode}
                    minimumFractionDigits={(!Number.isInteger(plan.realPrice)) ? 2 : 0}
                    maximumFractionDigits={2}
                  />
                ),
              }}
            />
          </TextRef>
          {(plan.trialDuration > 0) && (
            <TrialDaysHolder>
              <FormattedMessage
                id="planOption.selectThisPlanTrial"
                defaultMessage="{trialDays} days free"
                values={{ trialDays: (isAppIOS) ? 14 : plan.trialDuration }}
              />
            </TrialDaysHolder>
          )}
        </Plan>
      ))}
    </PlansOptions>
  );
};

PlanSelection.propTypes = {
  plans: PropTypes.instanceOf(Array).isRequired,
  selectedPlanIndex: PropTypes.number.isRequired,
  setSelectedPlanIndex: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

PlanSelection.defaultProps = {
  className: '',
};

export default PlanSelection;
