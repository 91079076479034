/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import GlobalTheme from '../../styled/GlobalTheme';
import { spreadShadow1 } from '../../styled/mixins';
import { colors } from '../../styled/theme';
import PromoCodeContext from '../../contexts/PromoCodeContext';
import sanitizePlans from './sanitizePlans';

import {
  PricingTitle, Span, Text,
} from './CreateAccount.styled';
import { currencyFormat } from '../../components/globals/Currency';
import intlPlanConversor, { PricingRedPrice } from './plans/intlPlanConversor';
import { ChevronDownIcon } from '../../assets/icons/iconRepository';

const PricingContainer = styled.div`
  background-color: white;
  text-align: left;
  ${spreadShadow1}
  padding: 0 16px 16px;
  display: block;
  ${(props) => (props.mobile ? `
    ${GlobalTheme.breakpoints.up('sm')}{
      display: none;
    };`
    : `
    ${GlobalTheme.breakpoints.down('sm')}{
      display: none;
    };`
  )}
  ${GlobalTheme.breakpoints.down('sm')} {
    box-shadow: none;
    border-top: none;
    margin-top: 0px;
  }
  ${(props) => props.isModal && `
    box-shadow: none;
    border-top: none;
    margin-top: 0px;
  `}
`;

const Content = styled.div`
  font-size: 18px;
`;

const Small = styled.div`
  padding: 16px 0;
  font-size: 12px;
  transition: all ease 0.5s;
  border-top: 0.5px solid ${colors.grayE9};
  display: ${({ showing }) => (showing ? 'block' : 'none')};
  a {
    text-decoration: underline;
  }
  ${(props) => (props.mobile && `
    display: none;
  `)}
`;

const DetailsToggler = styled.button`
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0 18px 0 0;
  cursor: pointer;
  display: ${({ showing }) => (showing ? 'block' : 'none')};
  position: relative;
  color: ${colors.black};
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 16px;
    height: 100%;
    background: url(${ChevronDownIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: ${({ showingDetails }) => (showingDetails ? 'rotate(180deg)' : 'none')};
  }
`;

const TextCustom = styled(Text)`
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

export default function PricingBanner({ mobile, className, isModal }) {
  const [showingDetails, setShowingDetails] = useState(false);
  const [promocoCodesExcluded, setPromoCodesExcluded] = useState(false);
  const { PromoCode } = useContext(PromoCodeContext);
  const [priceY, setPriceY] = useState(0);
  const [priceM, setPriceM] = useState(0);

  useEffect(() => {
    const [monthlyPlan, yearlyPlan] = sanitizePlans(PromoCode);
    setPriceM(monthlyPlan.realPrice);
    setPriceY(yearlyPlan.realPrice);
  }, [PromoCode]);

  useEffect(() => {
    setPromoCodesExcluded((
      PromoCode.code !== 'COVID_RELIEF'
      && PromoCode.code !== 'SP100'
    ));
  }, [PromoCode]);

  // eslint-disable-next-line react/prop-types
  const FactoryBanner = ({ promoCodeKey = '' }) => (
    <>
      <>
        <PricingTitle isModal={isModal}>
          <FormattedMessage
            id={`onboarding.pricing.title${promoCodeKey}`}
            defaultMessage="Special Offer:"
          />
        </PricingTitle>
        <Text fontSize="18px">
          <FormattedMessage
            id="onboarding.pricing.OfferMonthly"
            defaultMessage="{lineBreak}This offer is open for all CICC members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}"
            values={intlPlanConversor}
          />
          <PricingRedPrice>
            USD ${priceM}/
            <FormattedMessage
              id="k.month"
              defaultMessage="Month"
            />
          </PricingRedPrice>
          <br />
          <FormattedMessage
            id="onboarding.pricing.OfferYearly"
            defaultMessage="{lineBreak}This offer is open for all CICC members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}"
            values={intlPlanConversor}
          />
          <PricingRedPrice>USD ${priceY}</PricingRedPrice>
        </Text>
        <TextCustom>
          <FormattedMessage
            id={`onboarding.pricing.descriptionOffer${promoCodeKey}`}
            defaultMessage={`This offer is open for all ${promoCodeKey} members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}`}
            values={intlPlanConversor}
          />
        </TextCustom>
      </>
    </>
  );

  return (
    <PricingContainer mobile={mobile} className={className} isModal={isModal}>
      <Content>
        {(!PromoCode.isValid || PromoCode.code === 'COVID_RELIEF') && (
          <>
            <PricingTitle isModal={isModal}>
              <FormattedMessage
                id="pricing.covid.offer"
                defaultMessage="COVID Relief{lineBreak}Special Offer"
                values={{
                  lineBreak: <br />,
                }}
              />
              :
            </PricingTitle>
            <Text>
              <FormattedMessage
                id="pricing.weWantToHelp"
                defaultMessage="We want to help the small businesses who’ve been impacted by this crisis get back to business. Because of this we are reducing our subscription price.<sup>1</sup>"
                values={{
                  c: (...chunks) => (
                    <Span
                      background={colors.red}
                      color="white"
                      fontWeight="600"
                    >
                      {chunks}
                    </Span>
                  ),
                  sup: (...chunks) => <sup>{chunks}</sup>,
                }}
              />
            </Text>
            <Text margin="14px 0 24px" fontSize="18px">
              <FormattedMessage
                id="pricing.covid.price"
                defaultMessage="<title>Monthly Subscription</title>:{lineBreak}<info><no>$99</no> <red>USD $69/Month</red> <small><up>2</up></small></info><title>{lineBreak}{lineBreak}Yearly Subscription:{lineBreak}</title> <info><no>$990</no> <red>USD $690</red> <small><up>3</up></small> <greenIndicator>2 MONTHS FREE</greenIndicator></info>{lineBreak}"
                values={intlPlanConversor}
              />
            </Text>
          </>
        )}
        {(PromoCode.isValid && PromoCode.code === 'CICC') && (
          <>
            <PricingTitle isModal={isModal}>
              <FormattedMessage
                id="onboarding.pricing.titleCICC"
                defaultMessage="Special Offer for CICC Members:"
              />
            </PricingTitle>
            <Text fontSize="18px">
              <FormattedMessage
                id="onboarding.pricing.OfferCICC"
                defaultMessage="{lineBreak}This offer is open for all CICC members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}"
                values={intlPlanConversor}
              />
            </Text>
            <Text>
              <FormattedMessage
                id="onboarding.pricing.descriptionOfferCICC"
                defaultMessage="This offer is open for all CICC members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}"
                values={intlPlanConversor}
              />
            </Text>
          </>
        )}
        {(PromoCode.isValid && PromoCode.code === 'ASEM') && (
          <>
            <PricingTitle isModal={isModal}>
              <FormattedMessage
                id="onboarding.pricing.titleASEM"
                defaultMessage="Special Offer for ASEM Members:"
              />
            </PricingTitle>
            <Text fontSize="18px">
              <FormattedMessage
                id="onboarding.pricing.OfferASEM"
                defaultMessage="{lineBreak}This offer is open for all ASEM members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}"
                values={intlPlanConversor}
              />
            </Text>
            <Text>
              <FormattedMessage
                id="onboarding.pricing.descriptionOfferASEM"
                defaultMessage="This offer is open for all ASEM members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}"
                values={intlPlanConversor}
              />
            </Text>
          </>
        )}
        {(PromoCode.isValid && PromoCode.code === 'SP100') && (
          <>
            {FactoryBanner({ promoCodeKey: PromoCode.code })}
            <br />
          </>
        )}
        {(PromoCode.isValid && PromoCode.code === 'AMAI') && (
          <>
            {FactoryBanner({ promoCodeKey: PromoCode.code })}
            <br />
          </>
        )}
        {(PromoCode.isValid && PromoCode.code === 'APREN') && (
          <>
            {FactoryBanner({ promoCodeKey: PromoCode.code })}
            <br />
          </>
        )}
      </Content>
      <Small mobile={mobile} showing={showingDetails || isModal}>
        {(PromoCode.code === 'COVID_RELIEF') && (
          <FormattedMessage
            id="pricing.COVIDPricingDisclaimer"
            description="Create account pricing info"
            defaultMessage="*Social Piper subscription fees will NOT be charged during the free trial. You can cancel at any time. Advertising fees / commissions may apply. <a>See Terms of Service</a>.{lineBreak}1.You can activate your account after you finish your trial period (or earlier if you desire).{lineBreak}2. Social Piper regular monthly subscription cost is {price}/month.{lineBreak}3. SocialPiper regular yearly subscription cost is {yearPrice}/year."
            values={{
              a: (...chunks) => (
                <a href="https://legal.socialpiper.com/social-piper-terms-of-service-2020-07-31/" target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
              lineBreak: <br />,
              price: currencyFormat(99, 'USD', 0, 0),
              yearPrice: currencyFormat(990, 'USD', 0, 0),
            }}
          />
        )}
        {promocoCodesExcluded && (
          <FormattedMessage
            id="pricing.promocode.modal.legal"
            description="Create account pricing info"
            defaultMessage="*Social Piper subscription fees will NOT be charged during the free trial. You can cancel at any time. Advertising fees / commissions may apply. <a>See Terms of Service</a>.{lineBreak}1.You can activate your account after you finish your trial period (or earlier if you desire).{lineBreak}2. Social Piper regular monthly subscription cost is {price}/month.{lineBreak}3. SocialPiper regular yearly subscription cost is {yearPrice}/year."
            values={intlPlanConversor}
          />
        )}
        {(PromoCode.code === 'SP100') && (
          <FormattedMessage
            id="pricing.promocode.modal.legalReduced"
            defaultMessage="*Advertising fees / commissions may apply. See Terms of Service.<ol><li>You can activate your account after you finish your trial period (or earlier if you desire).</li><li>Social Piper regular monthly subscription cost is $99/month.</li><li>Social Piper regular yearly subscription cost is $990/year.</li></ol>"
            values={intlPlanConversor}
          />
        )}
        {(PromoCode.isValid && PromoCode.code === 'AMAI') && (
          <FormattedMessage
            id="onboarding.pricing.termsOfferAMAI"
            defaultMessage="{lineBreak}Promotion valid until March 2021, Limited to 10,000 redeems. Promotion will be applied once the user activates their account."
            values={intlPlanConversor}
          />
        )}
        {(PromoCode.isValid && PromoCode.code === 'APREN') && (
          <FormattedMessage
            id="onboarding.pricing.termsOfferAPREN"
            defaultMessage="{lineBreak}Promotion valid until March 2021, Limited to 10,000 redeems. Promotion will be applied once the user activates their account."
            values={intlPlanConversor}
          />
        )}
        {(PromoCode.isValid && PromoCode.code === 'CICC') && (
          <>
            <FormattedMessage
              id="onboarding.pricing.termsOfferCICC"
              defaultMessage="{lineBreak}Promotion valid until March 2021, Limited to 2,000 redeems. Promotion will be applied once the user activates their account."
              values={intlPlanConversor}
            />
          </>
        )}
        {(PromoCode.isValid && PromoCode.code === 'ASEM') && (
          <>
            <FormattedMessage
              id="onboarding.pricing.termsOfferASEM"
              defaultMessage="{lineBreak}Promotion valid until March 2021, Limited to 20,000 redeems. Promotion will be applied once the user activates their account."
              values={intlPlanConversor}
            />
          </>
        )}
      </Small>
      <DetailsToggler
        showing={!isModal}
        showingDetails={showingDetails}
        onClick={() => setShowingDetails(!showingDetails)}
      >
        <FormattedMessage
          id={showingDetails ? 'k.details.hide' : 'k.seeDetails'}
          defaultMessage={showingDetails ? 'Hide details' : 'See details'}
        />
      </DetailsToggler>
    </PricingContainer>
  );
}
PricingBanner.propTypes = {
  mobile: PropTypes.bool,
  className: PropTypes.string,
  isModal: PropTypes.bool,
};

PricingBanner.defaultProps = {
  mobile: false,
  className: '',
  isModal: false,
};
