import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  paragraph,
  heading,
  lowSpreadShadow,
} from '../../../../../styled/mixins';
import { colors } from '../../../../../styled/theme';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { Button } from '../../../../../components/buttons';
import BecomeProButton from '../../../../../components/becomePro';

export const PlanDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 0;
  ${GlobalTheme.breakpoints.down('md')}{
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 28px;
  }
`;

const Plan = styled.div`
  flex: 0 0 40%;
  ${GlobalTheme.breakpoints.down('md')}{
    margin-bottom: 24px;
  }
`;

const Upgrade = styled.div`
  flex: 0 0 50%;
  background: ${colors.green};
  border-radius: 6px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${lowSpreadShadow}
`;

export const Paragraph = styled.p`
  ${paragraph}
  font-size: 16px;
  color: ${colors.gray90};
  width: 100%;
  text-align: ${({ textAlign }) => (textAlign || 'left')};
  margin: ${({ margin }) => (margin || '0')};
  ${GlobalTheme.breakpoints.down('md')}{
    text-align: center;
  }
`;

const UpgradeText = styled.p`
  ${paragraph}
  font-size: 12px;
  color: white;
  text-align: center;
  margin-bottom: 8px;
`;

export const Note = styled.span`
  font-size: 10px;
  display: block;
  max-width: 270px;
  margin-left: auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin-top: 4px;
    margin-left: 0;
  }
`;

export const StyledButton = styled(Button)`
  && {
    height: 40px;
    width: ${(props) => props.upgrade ? '290px' : '205px'};
    position: unset;
  }
`;

export const SubscribedHeading = styled.h4`
  ${heading}
  font-size: 20px;
  color: ${colors.primary};
  margin: ${({ margin }) => (margin || '0')};
  ${GlobalTheme.breakpoints.down('md')}{
    text-align: center;
  }
`;

const StyledBecomeProButton = styled(BecomeProButton)`
  && {
    font-size: 14px;
  }
`;

const PlanBasicDetails = ({
  subscription,
}) => {
  const intl = useIntl();
  return (
    <PlanDetailsContainer>
      <CenterContainer>
        <Plan>
          <SubscribedHeading
            margin="0 0 24px"
          >
            <FormattedMessage
              id="k.basicPlan"
              defaultMessage="Lite Plan"
            />
          </SubscribedHeading>
          <Paragraph>
            <FormattedMessage
              id="settings.billing.planInformation.basicPlan.description"
              defaultMessage="Your lite Plan features will be renewed on: {date}"
              values={{
                date: intl.formatDate(new Date(subscription.planEndDate), {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }),
              }}
            />
          </Paragraph>
        </Plan>
        <Upgrade>
          <UpgradeText>
            <FormattedMessage
              id="settings.billing.planInformation.basicPlan.upgrade"
              defaultMessage="No more limitations! Access and edit +1000 exclusive templates, publish as much as you want, unlock the potential to reach more people  and more!"
            />
          </UpgradeText>
          <StyledBecomeProButton />
        </Upgrade>
      </CenterContainer>
    </PlanDetailsContainer>
  );
};

PlanBasicDetails.propTypes = {
  subscription: PropTypes.instanceOf(Object).isRequired,
};

export default PlanBasicDetails;
