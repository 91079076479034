const es = {
  'audienceInterestsSelect.helper': 'Comienza a escribir intereses que consideres puedan definir a tu público',
  'audienceInterestsSelect.label': 'Temas relacionados a tu negocio',
  'audienceInterestsSelect.placeholder': 'Ej. Café orgánico, Matcha',
  'audienceInterestsSelect.noOptionsMessage': 'No encontramos lo que buscas. Intenta con una categoría más general o similar.',
  'audienceLocationsSelect.helper': 'Comienza a escribir el estado, ciudad o país y selecciona de la lista',
  'audienceLocationsSelect.label': '¿Dónde están tus clientes?',
  'audienceLocationsSelect.alert.added.countryGroup': '¿Deseas reemplazar las ciudades y/o países por {region}?',
  'audienceLocationsSelect.alert.replace.countryGroup': '¿Deseas reemplazar las regiones por {location}?',
  'audienceLocationsSelect.alert.added.country': '{country} contiene al menos una ciudad que ya seleccionaste. ¿Deseas reemplazarlas?',
  'audienceLocationsSelect.alert.added.region': '{region} contiene o pertenece a al menos una ubicación que ya seleccionaste. ¿Deseas reemplazarlas?',
  'audienceLocationsSelect.alert.added.city': '{city} pertenece a al menos una ubicación que ya seleccionaste. ¿Deseas reemplazarlas?',
  'businessCategoriesSelect.label': 'Tipo de negocio',
  'businessCategoriesSelect.placeholder': 'Escribe y selecciona una opción',
  'businessLocationSelect.label': 'Tu ciudad',
  'competitors.search.facebook.disabled.placeholder': 'Primero conecta tu página de negocio de Facebook.',
  'competitors.search.facebook.placeholder': 'Escribe el nombre de la página del negocio en Facebook (Ej. “Coca Cola Oficial”)',
  'competitors.search.instagram.disabled.placeholder': 'Por favor conecta tu perfil de negocio de Instagram.',
  'competitors.search.instagram.placeholder': 'Escribe el nombre de usuario de su cuenta en Instagram (Ej. “cocacola”)',
  'competitors.search.helpText': 'Máximo 1 competidor.',
  'connectCompetitors.title': 'Conecta tus competidores',
  'connectCompetitors.title.upsell': '¡Agrega tantos competidores como quieras!',
  'competitors.piperTip.text': 'Agrega competidores para observar su actividad y tener inspiración para tus publicaciones.',
  'competitors.socialprofile.title': 'Competidores de {socialNetwork}',
  'competitors.socialprofile.subtitle': 'Comienza a escribir el nombre exacto del competidor, incluidos los caracteres especiales (como -_ + @) y selecciona entre las opciones que se muestran. <s>Solo se mostrarán las páginas de negocio.</s>',
  'competitors.socialprofile.tip.facebook': '<h>Tip:</h> Para saber si una página es de negocio, revisa que en su página tenga un botón de “Me gusta”.',
  'competitors.socialprofile.tip.instagram': '<h>Tip:</h> Para saber si un perfil es de negocio, revisa que su perfil en IG tenga un botón de contacto. ',
  'competitors.socialprofile.search.button': 'Agregar competidor',
  'competitors.socialprofile.search.helptext': 'Por ejemplo “CocaCola_Official”',
  'settings.billing.billingInformation.advice': 'Este se utiliza para pagar tu suscripción y comisiones de publicidad en Social Piper',
  'settings.billing.billingInformation.advice.app': 'Este sólo se utiliza para pagar las comisiones de publicidad en Social Piper; el pago de la subscripción mensual se realiza a través de la forma de pago asociada a tu cuenta en la App Store.',
  'settings.billing.billingInformation.card.expirationAbbr': 'Exp',
  'settings.billing.billingInformation.newPayment': 'Agregar nuevo método de pago',
  'settings.billing.billingInformation.title': 'Tu información de pago',
  'settings.billing.billingInformation.tooltip.text': 'Haz clic para cambiar esta tarjeta como tu método de pago predeterminado',
  'settings.billing.disableAccount.modal.confirm': 'Si, deshabilitar mi cuenta',
  'settings.billing.disableAccount.modal.piperTip': 'Si deshabilitas tu cuenta, toda tu información será borrada y no podrás consultarla de nuevo.',
  'settings.billing.disableAccount.modal.title': '¡Estás a punto de eliminar tu cuenta!',
  'settings.billing.disableAccount.question': '¿Estás seguro de que quieres eliminar permanentemente tu cuenta? Nosotros te vamos a extrañar',
  'settings.billing.disableAccount.title': 'Deshabilitar tu cuenta',
  'settings.billing.paymentHistory.chargeTypes.all': 'Todos lo tipos de cargos',
  'settings.billing.paymentHistory.chargeTypes.boost': 'Comisión de publicidad',
  'settings.billing.paymentHistory.chargeTypes.subscription': 'Suscripción',
  'settings.billing.paymentHistory.dateFilter.currentMonth': 'Este mes',
  'settings.billing.paymentHistory.payment.concept.boostFees': '{prevMonth} Comisiones por publicidad',
  'settings.billing.paymentHistory.payment.concept.monthlyPlan': 'Plan mensual',
  'settings.billing.paymentHistory.payment.concept.apple.1': 'Plan mensual',
  'settings.billing.paymentHistory.payment.concept.apple.2': 'Plan anual',
  'settings.billing.paymentHistory.payment.concept.android.1': 'Plan mensual',
  'settings.billing.paymentHistory.payment.concept.android.2': 'Plan anual',
  'settings.billing.paymentHistory.title': 'Historial de pagos',
  'settings.billing.payments.empty': 'Cuando comiences la suscripción a un Plan y realices una campaña publicitaria, aquí verás tu historial de pagos',
  'settings.billing.planInformation.upgradeToBusinessPlan': 'Crecer a una cuenta de negocio',
  'settings.billing.planInformation.downgradeToProPlan': 'Cambiar a plan Pro',
  'settings.upgradeToBusiness.loader.title': 'Procesando tu solicitud',
  'settings.upgradeToBusiness.loader.text': 'Esto puede tomar unos minutos, por favor <bold>no cierres la ventana.</bold>',
  'settings.upgradeToBusinessConfirmation.success.subtitle': '¡Felicidades! ¡Tu subscripción al plan business ha sido exitosa!', 
  'settings.upgradeToBusinessConfirmation.success.text': '¡Estamos felices de tenerte a bordo!',
  'settings.downgradeToProConfirmation.success.subtitle': '¡Felicidades! ¡Tu subscripción al plan pro ha sido exitosa!',
  'settings.billing.planInformation.activateAccount': '¡Activa tu suscripción ahora!',
  'settings.billing.planInformation.reActivateAccount': 'Reactiva tu suscripción',
  'settings.billing.planInformation.addPayment': 'Para activar tu suscripción, por favor agrega un método de pago',
  'settings.billing.planInformation.advice': 'Actualmente estás en nuestro periodo Beta. El 1ero de julio, el costo de tu suscripción será $99 USD al mes',
  'settings.billing.planInformation.autoPay': 'Pago recurrente, próximo pago el {date}',
  'settings.billing.planInformation.cancelSubscription': 'Cancelar suscripción',
  'settings.billing.planInformation.description': '¡Sigue disfrutando de tu prueba gratuita! Para continuar usando Social Piper después de que finalice la prueba gratuita, deberás suscribirte por $99USD / mes.',
  'settings.billing.planInformation.monthAbbr': 'mes',
  'settings.billing.planInformation.yearAbbr': 'año',
  'settings.billing.planInformation.plan.manage': 'Gestionar Plan',
  'settings.billing.planInformation.planDescription': 'Actualmente tienes un plan {time}.',
  'settings.billing.planInformation.subscribed.planDescription': 'Tienes un plan {time}.',
  'settings.billing.planInformation.promoCode.title': 'Código promocional:',
  'settings.billing.planInformation.daysLeft': '{daysCount, plural, one {# día restante} other {# días restantes}} de tu prueba gratis',
  'settings.billing.planInformation.subscribed': 'Ahora estás suscrito a Social Piper',
  'settings.billing.planInformation.canceled': 'Has cancelado tu suscripción a Social Piper.',
  'settings.billing.planInformation.title': 'Información de tu plan',
  'settings.billing.planInformation.plansButton': 'Ve los planes de Social Piper',
  'settings.billing.planInformation.trialDescription': 'Actualmente estás dentro{lineBreak} de tu prueba gratuita.',
  'settings.billing.planInformation.canceledSubscription': 'Suscripción cancelada',
  'settings.billing.planInformation.canceled.daysLeft': 'Tu cuenta estará activa por: {daysCount, plural, one {# día} other {# días}}',
  'settings.billing.planInformation.trialName': 'Plan de prueba gratuita',
  'settings.billing.planInformation.basicPlan.description': 'Las características de tu Lite Plan se renovarán el: {date}',
  'settings.billing.planInformation.basicPlan.upgrade': '¡No más limites! Accede y edita +1000 plantillas exclusivas, publica tanto como quieras, desbloquea el potencial de llegar a más personas, ¡y más!',
  'settings.billing.planInformation.professional.title': 'Plan Profesional',
  'settings.billing.planInformation.professional.downgrade.note': 'Al final de tu período, si no activas tu suscripción, tu cuenta será suspendida.',
  'settings.business.advice': 'Esta información me ayuda a ofrecerte recomendaciones para tu negocio, tales como qué publicar y cuándo publicar, y cuánto dinero se necesita en publicidad para llegar a un público mayor.',
  'settings.business.add': 'Agregar Negocio / Producto',
  'settings.business.company.warning': 'Parece que tienes un negocio como borrador en una plantilla, por favor complétalo antes de añadir uno nuevo',
  'settings.business.company.warning.maxNumber': 'Solo puedes tener como máximo 10 negocios por usuario',
  'settings.business.businessInformation.advice': 'Este contenido me ayudara a proveerte tips y recomendaciones especialmente creados para tu negocio.',
  'settings.business.businessInformation.name': 'Nombre del negocio',
  'settings.business.businessInformation.prefill': 'Pre-rellena esta información con los datos de tu página de negocio en Facebook',
  'settings.business.businessInformation.sizeLogo': 'Las imágenes deben pesar menos de 2MB',
  'settings.business.businessInformation.title': 'Información del negocio',
  'settings.business.businessExtraInformation.title': 'Información extra del negocio',
  'settings.business.businessExtraInformation.advice': 'Completar estos campos proporcionará a Piper una capacidad mejorada para responder a tus mensajes directos o comentarios en las publicaciones.',
  'settings.business.businessInformation.updateLogo': '<color>Actualiza</color> el logo de tu negocio',
  'settings.business.delete.title': 'Eliminar negocio / productos',
  'settings.business.delete.biz': 'Eliminar negocio',
  'settings.business.delete.cancel': 'Cancelar',
  'settings.business.delete.subtitle': '¿Deseas eliminar permanentemente tu Negocio / Producto?',
  'settings.business.delete.advice': 'Al eliminar tu negocio, <red>se borrarán todas las actividades realizadas hasta el momento en Social Piper</red>, incluidas las redes sociales enlazadas, publicaciones y publicidades en <b>LinkedIn</b>. Sin embargo, las publicaciones programadas para salir en Facebook en fechas futuras y las campañas que estén actualmente en curso o programadas para correr en Facebook seguirán activas. Deberás gestionarlas directamente desde la plataforma de Facebook.{br}{br}Si hay alguna publicación futura que no deseas que se publique, te recomendamos borrarla ahora. De lo contrario, deberás hacerlo desde la plataforma correspondiente en su momento.{br}{br}<b>Una vez que se elimine el negocio, no se podrá recuperar.</b>',
  'settings.business.delete.confirm': 'Confirmo y acepto que se elimine mi negocio <b>{companyName}</b> de Social Piper, y entiendo lo que conlleva.',
  'settings.business.primaryTarget.advice': 'Para obtener mejores resultados, enfócate en las personas que tienen mayores posibilidades de convertirse en tus clientes.',
  'settings.business.primaryTarget.prefill': 'Pre-rellena los datos de tu público usando tu cuenta de anuncios de Facebook.',
  'settings.business.primaryTarget.recommended': 'Recomendado',
  'settings.business.primaryTarget.title': 'Tu público principal',
  'settings.business.primaryTarget.tooBroad': 'Muy amplio',
  'settings.business.primaryTarget.tooSpecific': 'Muy específico',
  'settings.personal.advice': '¡Mantén actualizados tus perfiles de negocio para que podamos ayudarte a sacar el mayor beneficio de cada publicación!',
  'settings.personal.personalInformation.email': 'Correo electronico',
  'settings.personal.personalInformation.firstName': 'Nombre',
  'settings.personal.personalInformation.lastName': 'Apellido',
  'settings.personal.personalInformation.title': 'Información Personal',
  'settings.personal.preferences.language': 'Idioma',
  'settings.personal.preferences.title': 'Preferencias del sitio',
  'settings.personal.updatePassword.confirm': 'Confirmar nueva contraseña',
  'settings.personal.updatePassword.newPassword': 'Nueva contraseña',
  'settings.personal.updatePassword.title': 'Actualizar contraseña',
  'settings.personal.teams.title': 'Equipo',
  'settings.personal.collaborator.title': 'Permisos',
  'settings.personal.teams.button.delete': 'Remover miembro',
  'settings.primaryTarget.audience.size.advice.tooBroad': 'Para poder llegar a tu público adecuado, te recomendamos ser más específico al detallar tus intereses, edad y ubicación. Con una público demasiado amplia, tus publicaciones y tu publicidad podrían llegar a personas que no están realmente interesadas.',
  'settings.primaryTarget.audience.size.advice.tooSpecific': 'Para llegar a más personas y tener un público más grande, agrega más intereses, expande el rango de edad o la ubicación de la público. Piensa en lo que le gusta a la gente cuando usa tu producto o servicio.',
  'settings.primaryTarget.audience.size.chart.reach': 'El alcance estimado es de {size} personas',
  'settings.primaryTarget.audience.size.chart.title': 'Tamaño potencial de tu público',
  'settings.social.advice.competitors': 'Dime cuales son tus competidores o negocios que te gustaría ver para obtener inspiración y aquí te mostraremos sus últimas publicaciones.',
  'settings.primaryTarget.audience.size.chart.title.facebook': 'Tamaño potencial de tu público en Facebook',
  'settings.primaryTarget.audience.size.chart.title.linkedin': 'Tamaño potencial de tu público en LinkedIn',
  'settings.social.advice.connect': 'Conéctate para poder crear publicaciones, ver tus competidores, promocionar tus publicaciones y conocer tus métricas.',
  'settings.tab.billingAndPlan': 'Plan y facturación',
  'settings.tab.businessAndAudience': 'Público y negocio',
  'settings.tab.personalAndPreferences': 'Mi cuenta',
  'settings.tab.socialProfile': 'Redes vinculadas',
  'settings.tab.competitors': 'Competidores',
  'settings.billing.planInformation.cancelSubscription.app.text': 'Para cancelar tu suscripción, debes hacerlo desde la App Store. ',
  'settings.billing.planInformation.cancelSubscription.app.link': 'Conoce aquí cómo hacerlo.',
  'settings.billing.planInformation.basicPlan.title': 'Lite Plan',
  'settings.billing.planInformation.litePlan.title': 'Plan Lite',
  'settings.billing.planInformation.proPlan.title': 'Plan Pro',
  'settings.billing.planInformation.businessPlan.title': 'Plan Business',
  'settings.business.card.block.title': '¡Complete su perfil comercial!',
  'settings.business.card.block.message': 'Agrega el nombre, la ubicación y la categoría de tu negocio.{br}Sube tu logo para que pueda aplicarlo a las plantillas por ti.',
  'settings.business.card.block.button': 'Completa tu perfil',
  'settings.business.card.block.audience.title': '¿Quieres llegar a más personas?',
  'settings.business.card.block.audience.message': 'Complete esta información sobre sus clientes para configurar anuncios exitosos para su empresa.',
  'settings.business.card.block.audience.button': 'Completa la información',
  'settings.personal.personalInformation.holder': 'Aquí recibirás información importante sobre tu cuenta y notificaciones.',
  'settings.personal.card.block.title': '¡Mantengámonos en contacto!',
  'settings.personal.card.block.message': 'Agregue su nombre y un correo electrónico de contacto para recibir información y notificaciones importantes',
  'settings.personal.card.block.button': 'Agregar',
  'settings.business.card.block.interest': 'Agrega una categoría de tu negocio.',
  'settings.business.card.block.company': 'Agrega el nombre de tu empresa.',
  'settings.business.card.block.logo': 'Sube tu logo para que pueda aplicarlo a las plantillas por ti.',
  'settings.business.card.block.location': 'Agrega la ubicación de tu negocio.',
  'settings.personal.title.social.conection': 'Registrado con',
  'settings.billing.paymentHistory.android.notification.1': 'RECUPERADO',
  'settings.billing.paymentHistory.android.notification.2': 'RENOVADO',
  'settings.billing.paymentHistory.android.notification.3': 'CANCELADO',
  'settings.billing.paymentHistory.android.notification.4': 'COMPRADO',
  'settings.billing.paymentHistory.android.notification.5': 'EN ESPERA',
  'settings.billing.paymentHistory.android.notification.6': 'EN PERÍODO DE GRACIA',
  'settings.billing.paymentHistory.android.notification.7': 'REINICIO',
  'settings.billing.paymentHistory.android.notification.8': 'CAMBIO DE PRECIO CONFIRMADO',
  'settings.billing.paymentHistory.android.notification.9': 'DIFERIDO',
  'settings.billing.paymentHistory.android.notification.10': 'EN PAUSA',
  'settings.billing.paymentHistory.android.notification.11': 'CAMBIOS DE HORARIO DE PAUSA',
  'settings.billing.paymentHistory.android.notification.12': 'REVOCADO',
  'settings.billing.paymentHistory.android.notification.13': 'CADUCADO',
};

export default es;
