/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { subMonths } from 'date-fns';
import styled from 'styled-components';
import cards from '../../../../../assets/cards/cardsRepository';
import { PAYMENT_CONCEPT } from '../../../../../utils/constants/settings';
import { GoToIcon, MoreVerticalIcon } from '../../../../../assets/icons/iconRepository';
import { colors } from '../../../../../styled/theme';
import {
  flexBox,
  paragraph,
} from '../../../../../styled/mixins';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import AnalyticsAnchor from '../../../../../components/buttons/AnalyticsAnchor';
import Currency from '../../../../../components/globals/Currency';

export const PaymentRowContainer = styled.div`
  ${flexBox}
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 40px 0;
  border-bottom: 1px solid ${colors.grayE9};
  &:last-child{
    border-bottom: 0;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    padding: 24px 0;
  }
`;

export const PaymentCell = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${paragraph}
  font-size: 18px;
  ${({ mobileHidden }) => mobileHidden && `
    ${GlobalTheme.breakpoints.down('sm')}{
      display: none;
    }
  `}
`;

const AccountPaymentCell = styled(PaymentCell)`
  display: ${({ typeCharge }) => ((typeCharge === 'Stripe') ? 'inline' : 'flex')};
  flex-direction: row;
  ${GlobalTheme.breakpoints.down('sm')}{
    display: flex;
    flex-direction: column;
  }
`;

const CardBrandImg = styled.img`
  height: 30px;
  width: 55px;
  object-fit: contain;
  object-position: center;
  margin-right: 5px;
  ${GlobalTheme.breakpoints.down('sm')}{
    height: 20px;
    width: 35px;
  }
`;

export const PaymentGoToCell = styled.button`
  flex: 0 0 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: 0;
  ${GlobalTheme.breakpoints.down('sm')}{
    display: none;
  }
`;

const GoToImg = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  object-position: center;
  ${GlobalTheme.breakpoints.down('sm')}{
    display: none;
  }
`;

const ContainerEmpty = styled.div``;

const TextAppType = styled.div`
  text-align: center;
  font-size: 12px;
  color: gray;
`;

export const PaymentMobileOverlayCell = styled(PaymentGoToCell)`
  display: none;
  ${GlobalTheme.breakpoints.down('sm')}{
    display: flex;
  }
`;

const MoreImg = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  object-position: center;
`;

const PaymentRow = ({ payment, intl, setPaymentOverlay }) => (
  <PaymentRowContainer>
    <PaymentCell
      mobileHidden
    >
      <FormattedDate
        value={new Date(payment.date * 1000)}
        year="numeric"
        month="2-digit"
        day="2-digit"
      />
    </PaymentCell>
    <AccountPaymentCell typeCharge={payment.typeCharge}>
      {(payment.typeCharge === 'App') ? (
        <>
          <CardBrandImg
            src={cards[payment.account.brand.toLowerCase()]}
            alt={payment.account.brand}
          />
          <span>
            {payment.account.name}
          </span>
        </>
      ) : (
        <>
          <CardBrandImg
            src={cards[payment.account.brand.toLowerCase()]}
            alt={payment.account.brand}
          />
          <span>{payment.account.name.split(' ')[0]}</span> **{payment.account.last4}
        </>
      )}
    </AccountPaymentCell>
    <PaymentCell>
      <ContainerEmpty>
        <FormattedMessage
          id={`settings.billing.paymentHistory.payment.concept.${payment.concept}`}
          defaultMessage={PAYMENT_CONCEPT[payment.concept]}
          values={{
            prevMonth: intl.formatDate(subMonths(new Date(), 1), {
              month: 'long',
            }),
          }}
        />
        {(payment.typeCharge === 'App' && payment.account.brand === 'googleplay') && (
          <>
            <TextAppType>
              <FormattedMessage
                id={`settings.billing.paymentHistory.android.notification.${payment.typeNotification}`}
                defaultMessage=""
              />
            </TextAppType>
          </>
        )}
      </ContainerEmpty>
    </PaymentCell>
    <PaymentCell
      mobileHidden
    >
      <Currency
        value={payment.amount.value}
        style="currency"
        currency={payment.amount.currency}
      />
    </PaymentCell>
    <PaymentGoToCell>
      {payment.receiptUrl !== '' && (
        <AnalyticsAnchor href={payment.receiptUrl} target="_blank" rel="noreferrer" gaData={{ action: 'Download Payments', label: '(button)' }}>
          <GoToImg src={GoToIcon} alt="download" />
        </AnalyticsAnchor>
      )}
    </PaymentGoToCell>
    <PaymentMobileOverlayCell
      onClick={() => setPaymentOverlay(payment)}
    >
      <MoreImg src={MoreVerticalIcon} alt="more" />
    </PaymentMobileOverlayCell>
  </PaymentRowContainer>
);

PaymentRow.propTypes = {
  payment: PropTypes.instanceOf(Object).isRequired,
  intl: PropTypes.instanceOf(Object).isRequired,
  setPaymentOverlay: PropTypes.func.isRequired,
};

export default PaymentRow;
