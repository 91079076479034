import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import moment from 'moment';
import { differenceInHours } from 'date-fns';
import { colors } from '../../../../../styled/theme';
import { SECONDARY } from '../../../../../components/buttons/constants';
import {
  PlanDetailsContainer,
  CenterContainer,
  Paragraph,
  Note,
  StyledButton,
  SubscribedHeading,
} from './PlanBasicDetails';
import { PLAN_STATUS } from '../../../../../utils/constants/settings';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { flexBox, heading, heading3 } from '../../../../../styled/mixins';
import { LayoutContext } from '../../../../../contexts/LayoutContext';
import { WebViewContext } from '../../../../../contexts/WebViewContext';
import { Button } from '../../../../../components/buttons';
import { Profiles } from '../../..';
import useGetCurrencySymbol from '../../../../../hooks/useGetCurrencySymbol';

const PlanInfo = styled.div`
  flex: 0 0 100%;
  margin-bottom: 16px;
`;

const StyledCenterContainer = styled(CenterContainer)`
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-direction: row;
  }
`;

const Heading3 = styled.h3`
  ${heading3}
  width: 100%;
  opacity: ${({ opacity }) => (opacity || '1')};

  &&{
    ${GlobalTheme.breakpoints.down('sm')}{
      text-align: center;
    }
  }
`;

const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ isCanceled }) => (isCanceled ? '0.25' : '1')};
  ${GlobalTheme.breakpoints.down('md')}{
    flex: 0 0 100%;
  }
`;

const PromoCodeTitle = styled.span`
  color: ${colors.primary};
  font-size: 16px;
`;

const Code = styled.span`
  color: ${colors.gray90};
  font-size: 18px;
`;

const AutoPayDate = styled.span`
  display: inline-block;
`;

const PaymentDetails = styled.div`
  flex: ${({ fullWidth }) => (fullWidth ? '0 0 100%' : '1')};
  ${flexBox}
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 24px 24px;
  ${GlobalTheme.breakpoints.down('md')}{
    margin: 8px 0 24px;
    align-items: flex-start;
    flex: 0 0 100%;
  }
`;

const CanceledHeading = styled.h4`
  ${heading}
  font-size: 20px;
  color: ${colors.red};
  margin: ${({ margin }) => (margin || '0')};
  text-transform: uppercase;
  ${GlobalTheme.breakpoints.down('md')}{
    text-align: center;
  }
`;

const DowngradeText = styled(Paragraph)`
  font-size: 12px;
  max-width: 220px;
`;

const ActivateButton = styled(Button)`
  && {
    font-size: 16px;
    color: white;
    background: ${colors.green};
    padding: 8px;
    &:hover {
      background: ${colors.green};
    }
  }
`;

const ContainerTextCancelApple = styled.div`
  color: #909090;
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 12px;
    text-align: center;
  }
`;

const ContainerLinkCancelApple = styled.div``;

const LinkTextCancelApple = styled.a`
  color: #027686;
  text-decoration: underline;
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 12px;
  }
`;

const SubcriptionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 35px;
  margin-top: 35px;
`;

const PlanDetailsSubscribed = ({
  paymentPayload,
  subscription,
  handleShowCancelSubscriptionModal,
  handleShowUpgradeToBusinessModal,
  handleShowDowngradeToProModal,
  userProfile,
  isPlanApple,
  isPlanAndroid,
}) => {
  const [remainingDays, setRemainingDays] = useState(7);
  const currencySymbol = useGetCurrencySymbol(subscription.currencyCode);
  const [pricePlan, setPricePlan] = useState(subscription.planPrice);
  const { isApp } = useContext(WebViewContext);

  const intl = useIntl();
  const urlLinkApple = intl.locale === 'es' ? 'https://support.apple.com/es-mx/HT202039?out_app' : 'https://support.apple.com/en-us/HT204939?out_app';
  const urlLinkAndroid = intl.locale === 'es' ? 'https://support.google.com/googleplay/answer/7018481?hl=es-419&co=GENIE.Platform%3DAndroid&out_app' : 'https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid&out_app';

  useEffect(() => {
    if (subscription.planEndDate) {
      setRemainingDays(
        Math.ceil(differenceInHours(new Date(subscription.planEndDate), new Date()) / 24),
      );
      if (isPlanApple) {
        let planEndDate = moment(subscription.planEndDate);
        if (subscription.isMonthly) {
          planEndDate = planEndDate.subtract(30, 'days');
        } else {
          planEndDate = planEndDate.subtract(365, 'days');
        }
        const diffDays = moment(planEndDate).startOf('day').diff(moment(new Date(), 'YYYY-MM-DD').startOf('day'), 'days');
        let remainingDaysCalculate = diffDays;
        if (diffDays >= 0) {
          remainingDaysCalculate = Math.abs(diffDays);
        }
        setRemainingDays(remainingDaysCalculate);
      }
    }

    if (!Number.isInteger(subscription.planPrice)) {
      setPricePlan(subscription.planPrice.toFixed(2));
    } else {
      setPricePlan(subscription.planPrice);
    }
  }, [subscription.planEndDate, subscription.planPrice]);
  const { setShowBecomeProModal, setShowModalSubAppsModal, user } = useContext(LayoutContext);

  const date = () => {
    // eslint-disable-next-line max-len
    if (subscription.planStatus === PLAN_STATUS.TRIAL || subscription.planStatus === PLAN_STATUS.TRIAL_OPTIN) {
      return (
        <>
          {(remainingDays >= 1) && (
            <FormattedMessage
              id="settings.billing.planInformation.daysLeft"
              defaultMessage="Free trial, {daysCount, plural, one {# day left} other {# days left}}"
              values={{
                daysCount: remainingDays < 1 ? 0 : remainingDays,
              }}
            />
          )}
          {(remainingDays < 1) && (
            <FormattedMessage
              id="k.trialEnd"
              defaultMessage="Your free trial is over."
            />
          )}
          {(subscription.planStatus === PLAN_STATUS.TRIAL) && (
            <Note>
              <FormattedMessage
                id="onboarding.billing.charging.note"
                defaultMessage="At the end of your trial period, you will be charged automatically. You can cancel at any time."
              />
            </Note>
          )}
        </>
      );
    }
    if (subscription.planStatus === PLAN_STATUS.CANCELED) {
      return (
        <FormattedMessage
          id="k.daysLeft"
          defaultMessage="{daysCount, plural, one {# day left} other {# days left}}"
          values={{
            daysCount: remainingDays < 1 ? 0 : remainingDays,
          }}
        />
      );
    }
    return (
      <>
        {paymentPayload.nextPaymentDate && paymentPayload.nextPaymentDate !== '' && remainingDays >= 0 ? (
          <FormattedMessage
            id="settings.billing.planInformation.autoPay"
            defaultMessage="Auto-pay, next payment {date}"
            values={{
              date: (
                <AutoPayDate>
                  <FormattedDate
                    value={paymentPayload.nextPaymentDate}
                    year="numeric"
                    month="long"
                    day="numeric"
                  />
                </AutoPayDate>
              ),
            }}
          />
        ) : (<> </>)}
      </>
    );
  };

  const showSectionCancel = () => {
    if (isPlanApple || isPlanAndroid) {
      return (
        <>
          <ContainerTextCancelApple>
            <FormattedMessage
              id="settings.billing.planInformation.cancelSubscription.app.text"
              defaultMessage="To cancel your subscription, you must do it from the App Store."
            />
          </ContainerTextCancelApple>

          <ContainerLinkCancelApple>
            <LinkTextCancelApple href={(isPlanApple) ? urlLinkApple : urlLinkAndroid}>
              <FormattedMessage
                id="settings.billing.planInformation.cancelSubscription.app.link"
                defaultMessage="Follow these steps to do it."
              />
            </LinkTextCancelApple>
          </ContainerLinkCancelApple>
        </>
      );
    }

    return (
      <SubcriptionButtonsContainer>
        {![Profiles.MASTER, Profiles.COLLAB].includes(user.profileId) ? (
          <StyledButton
            onClick={handleShowUpgradeToBusinessModal}
            upgrade={true}
          >
            <FormattedMessage
              id="settings.billing.planInformation.upgradeToBusinessPlan"
              defaultMessage="Upgrade subscription"
            />
          </StyledButton>
        ) : (
          <StyledButton
          onClick={handleShowDowngradeToProModal}
          type={SECONDARY}
          upgrade={true}
        >
          <FormattedMessage
            id="settings.billing.planInformation.downgradeToProPlan"
            defaultMessage="Downgrade to Pro Plan"
          />
        </StyledButton>
        )}
        <StyledButton
        onClick={handleShowCancelSubscriptionModal}
        type={SECONDARY}
        >
          <FormattedMessage
            id="settings.billing.planInformation.cancelSubscription"
            defaultMessage="Cancel subscription"
          />
        </StyledButton>
      </SubcriptionButtonsContainer>
    );
  };

  return (
    <PlanDetailsContainer>
      <StyledCenterContainer>
        <PlanInfo>
          {subscription.planStatus === PLAN_STATUS.CANCELED && (
            <CanceledHeading>
              <FormattedMessage
                id="k.canceled"
                defaultMessage="Canceled"
              />
            </CanceledHeading>
          )}
          <SubscribedHeading>
            <FormattedMessage
              id={userProfile === Profiles.MASTER ? "settings.billing.planInformation.businessPlan.title" : "settings.billing.planInformation.professional.title"}
              defaultMessage="Professional Plan"
            />
          </SubscribedHeading>
          {subscription.planStatus === PLAN_STATUS.CANCELED && (
            <DowngradeText>
              <FormattedMessage
                id="settings.billing.planInformation.professional.downgrade.note"
                defaultMessage="At the end of your period, if you don’t activate your subscription, you will be downgrated to Lite Plan."
              />
            </DowngradeText>
          )}
        </PlanInfo>
        {(subscription.promoCode && subscription.isPromoActive) && (
          <PromoContainer
            isCanceled={subscription.planStatus === PLAN_STATUS.CANCELED}
          >
            <PromoCodeTitle>
              <FormattedMessage
                id="settings.billing.planInformation.promoCode.title"
                defaultMessage="Promotional Code:"
              />
            </PromoCodeTitle>
            <Code>
              {subscription.promoCode}
            </Code>
          </PromoContainer>
        )}
        <PaymentDetails>
          <Heading3
            color={colors.gray90}
            textAlign="right"
            opacity={subscription.planStatus === PLAN_STATUS.CANCELED ? '0.25' : '1'}
          >
            {subscription.currencyCode}
            {' '}
            <Heading3
              as="span"
              color={colors.speechBubble}
            >
              {currencySymbol}{pricePlan}/
              <FormattedMessage
                id={subscription.isMonthly ? 'settings.billing.planInformation.monthAbbr' : 'settings.billing.planInformation.yearAbbr'}
                defaultMessage={subscription.isMonthly ? 'mo' : 'yr'}
              />
            </Heading3>
          </Heading3>
          <Paragraph
            textAlign="right"
          >
            {date()}
          </Paragraph>
        </PaymentDetails>
      </StyledCenterContainer>
      {/* eslint-disable-next-line max-len */}
      {(subscription.planStatus === PLAN_STATUS.CANCELED || subscription.planStatus === PLAN_STATUS.TRIAL_OPTIN) ? (
        <ActivateButton
          onClick={() => {
            if (isApp || isPlanApple || isPlanAndroid) {
              setShowModalSubAppsModal(true);
              return;
            }
            setShowBecomeProModal(true);
          }}
        >
          <FormattedMessage
            id="settings.billing.planInformation.activateAccount"
            defaultMessage="Activate your subscription"
          />
        </ActivateButton>
      ) : (showSectionCancel())}
    </PlanDetailsContainer>
  );
};

PlanDetailsSubscribed.propTypes = {
  paymentPayload: PropTypes.instanceOf(Object).isRequired,
  subscription: PropTypes.instanceOf(Object).isRequired,
  handleShowCancelSubscriptionModal: PropTypes.func.isRequired,
  handleShowUpgradeToBusinessModal: PropTypes.func.isRequired,
  handleShowDowngradeToProModal: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  isPlanApple: PropTypes.bool.isRequired,
  isPlanAndroid: PropTypes.bool.isRequired,
};

export default PlanDetailsSubscribed;
