import { useContext, useMemo } from "react";
import { PlanCtx } from "./index.provider";

export default () => {
  const { plans, dispatch } = useContext(PlanCtx);

  const selectedPlan = useMemo(() => plans.find((p) => p.checked), [plans]);

  const togglePeriod = ({ period }) => {
    dispatch({ type: "change_period", payload: { period } });
  };

  const toggleCheck = ({ id }) => {
    dispatch({ type: "set_selected", payload: { id } });
  };

  return {
    plans,
    plan: selectedPlan,
    toggleCheck,
    togglePeriod,
  };
};
