import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import GlobalTheme from '../../styled/GlobalTheme';
import { colors } from '../../styled/theme';
import AnalyticsLink from '../buttons/AnalyticsLink';
import { Button } from '../buttons';
import Pipers from '../Pipers/Pipers';
import ROUTES from '../../utils/constants/routes';

export const Modal = styled(MuiDialog)`
  &&{
    .MuiPaper-root{
      min-width: 446px;
      padding: ${({ theme }) => theme.space * 2}px ${({ theme }) => theme.space * 3}px;
      ${GlobalTheme.breakpoints.down('md')}{
        min-width: unset;
        width: 100%;
        max-width: 690px;
      }
      ${GlobalTheme.breakpoints.down('sm')}{
        background: ${colors.screenBackground};
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin: auto;
  margin: ${(props) => props.theme.space * 4}px auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: fit-content;
  }
`;

const Text = styled.div`
  margin: ${(props) => props.theme.space * 4}px 0;
`;

const Title = styled.h3`
  text-align: center;
  margin: 0;
  margin-bottom: ${(props) => props.theme.space}px;
`;

const PiperWrapper = styled.div`
  max-width: 150px;
  margin: auto;
`;

const UpdateMailSession = ({
  open = false,
  className,
}) => (
  <Modal
    open={open}
    className={className}
  >
    <Container>
      <PiperWrapper>
        <Pipers.Settings />
      </PiperWrapper>
      <Text>
        <Title>
          <FormattedMessage
            id="update.session.modal.message"
            defaultMessage="In order to validate your email, you'll need to login again."
          />
        </Title>
      </Text>
      <AnalyticsLink to={ROUTES.LOGOUT}>
        <Button>
          <FormattedMessage
            id="k.logout"
            defaultMessage="Log out"
          />
        </Button>
      </AnalyticsLink>
    </Container>
  </Modal>
);

UpdateMailSession.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
};

UpdateMailSession.defaultProps = {
  className: '',
  open: false,
};

export default UpdateMailSession;
