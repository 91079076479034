import styled from 'styled-components';
import { Slider } from '@material-ui/core';
import GlobalTheme from '../../../styled/GlobalTheme';
import { h1 } from '../../../styled/mixins';
import COLOR from '../../../styled/colors';
import ButtonBrn from '../../../components/buttons/Button';

const Title = styled.h2`
  ${h1};
  margin: 60px 0 24px;
  letter-spacing: 1px;
  ${GlobalTheme.breakpoints.down('xs')}{
    ${(props) => `
      font-size:${props.long && '24px'};
      line-height:${props.long && '32px'};
      text-align: center;
    `}
  }
`;

const Paragraph = styled.div`
  margin-top: 1em;
  text-align: ${(props) => props.align};
  ${GlobalTheme.breakpoints.down('md')}{
    text-align: center;
  }
`;

const ActionText = styled(Paragraph)`
  color: ${COLOR.primary};
  font-weight: 600;
  cursor:pointer;
  :hover{
    text-decoration:underline;
  }
`;

const ButtonOnBoarding = styled(ButtonBrn)`
  && {
    width:100%;
    margin-top: 1em;
  }
`;

const DescribeYourBusinessSlider = styled(Slider)`
  height: 8px;
  && .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    background-color: ${COLOR.primaryLight};
    border: 2px solid ${COLOR.white};
    }
  && .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
    color: ${COLOR.secondaryDark};
  }
  && .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
    color: ${COLOR.secondary};
    opacity: 1;
  }
`;

const AgeRangeSlider = styled(Slider)`
  && {
    width: calc(100% - 40px);
    margin-left: 10px;
    .MuiSlider-thumb {
      height: 24px;
      width: 24px;
      margin-top: 1px;
      background-color: ${COLOR.primaryLight};
      border: 2px solid white;
      }
    .MuiSlider-track {
      height: 20px;
      border-radius: 4px;
      margin-top: 4px;
      color: ${COLOR.secondaryDark};
    }
    .MuiSlider-rail {
      height: 28px;
      margin-left: -10px;
      width: calc(100% + 30px);
      border-radius: 15px;
      border: solid 1px ${COLOR.black};
      color: ${COLOR.white};
    }
  }
`;

const RequiredCaption = styled.div`
  font-family: ${(props) => props.theme.font.font1};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.orange};
`;

export {
  Title,
  Paragraph,
  ActionText,
  ButtonOnBoarding,
  DescribeYourBusinessSlider,
  AgeRangeSlider,
  RequiredCaption,
};
