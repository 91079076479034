const suggestionWords = {
  suggestionString: '[{"id": "urn:li:interest:1538426","name":"Marketing"}]',
  suggestionObject: [
    { id: 'urn:li:interest:1538426', name: 'Italian food' },
    { id: 'urn:li:skill:1538428', name: 'American food' },
    { id: 'urn:li:seniority:1538123', name: 'Street food' },
    { id: 'urn:li:interest:1538421', name: 'Chinesse food' },
    { id: 'urn:li:skill:15384123', name: 'Foos' },
    { id: 'urn:li:seniority:1538428', name: 'Hamburger' },
    { id: 'urn:li:seniority:1538429', name: 'Pizza' },
    { id: 'urn:li:seniority:1538430', name: 'Restaurant' },
    { id: 'urn:li:seniority:1538431', name: 'Cattering' },
    { id: 'urn:li:seniority:1538432', name: 'Pasta' },
  ],
};

export default suggestionWords;
