import styled, { css } from 'styled-components';
import {
  h1,
  container,
  heading,
  heading2,
  paragraph,
  spreadShadow1,
} from '../../styled/mixins';
import GlobalTheme from '../../styled/GlobalTheme';
import { Button } from '../../components/buttons';
import FormController from '../../components/globals/FormController';

export const Title = styled.h2`
  ${h1};
  margin: 0 0 16px;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 40px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 36px;
  }
`;

export const TrialTitle = styled.h3`
  ${heading}
  font-size: 28px;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 24px;
  }
`;

export const TrialMobileTitle = styled.h2`
  ${heading2}
  color: ${(props) => props.theme.colors.lightBlue};
  ${(props) => props.isModal && `
    display: none;
  `}
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 28px;
    padding: ${(props) => props.theme.space / 2}px 0;
  }
`;

export const PricingTitle = styled.h2`
  ${heading}
  padding: 4px 16px;
  margin: 0 -16px;
  font-size: 24px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.red};
  ${(props) => props.isModal && `
    margin-top: 50px;
    background: ${props.theme.colors.white};
    color: ${props.theme.colors.red};
  `}
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 18px;
  }
`;

export const SubTitle = styled.p`
  ${paragraph}
  margin: 16px 0;
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => (props.color || props.theme.colors.black)};
  ${(props) => props.isModal && `
    display: none;
  `}
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 12px;
  }
`;

export const BigSubTitle = styled(SubTitle)`
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 14px;
  }
`;

export const Span = styled.span`
  font-size: 14px;
  color: ${(props) => (props.color || props.theme.colors.black)};
  background: ${(props) => (props.background || 'transparent')};
  font-weight: ${(props) => (props.fontWeight || '400')};
`;

export const Text = styled.p`
  margin: ${({ margin }) => (margin || '14px 0')};
  font-size: ${(props) => props.fontSize || '14px'};
  color: ${(props) => (props.color || props.theme.colors.black)};
  background: ${(props) => (props.background || 'transparent')};
  ${(props) => props.isModal && `
    display: none;
  `}
`;

export const createAccountContainer = css`
  ${container}
  ${GlobalTheme.breakpoints.down('sm')} {
    padding-right: 21px;
    padding-left: 21px;
  }
`;

export const MobileContainer = styled.div`
  ${createAccountContainer}
`;

export const SpaceContainer = styled(MobileContainer)`
  ${spreadShadow1}
  ${GlobalTheme.breakpoints.down('sm')} {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

export const PaddingContainer = styled.div`
  ${GlobalTheme.breakpoints.down('sm')} {
    padding-right: 5px;
    padding-left: 10px;
  }
`;

export const CreateAccountButton = styled(Button)`
  && {
    line-height: 24px;
    width: 100%;
    min-height: 3rem;
    margin: 30px 0px 25px;
    span {
      font-size: 24px;
      ${GlobalTheme.breakpoints.down('md')}{
        font-size: 18px;
      }
    }
    ${GlobalTheme.breakpoints.down('sm')} {
      margin: 25px 0px 15px;
    }
  }
`;

export const RequiredLabel = styled.p`
  ${paragraph}
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.colors.red};
  margin-top: 16px;
`;

export const NameFormController = styled(FormController)`
  && {
    ${GlobalTheme.breakpoints.down('md')} {
      margin-bottom: 0px;
    }
    @media (max-width: 320px)   {
      width: 85%;
    }
  }
`;

export default {};
