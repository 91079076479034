/* eslint-disable react/style-prop-object */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { subMonths } from 'date-fns';
import { Dialog as MuiDialog } from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { CloseOutlineIcon, GoToIcon } from '../../../../../assets/icons/iconRepository';
import { colors } from '../../../../../styled/theme';
import { flexBox, paragraph } from '../../../../../styled/mixins';
import cards from '../../../../../assets/cards/cardsRepository';
import { PAYMENT_CONCEPT } from '../../../../../utils/constants/settings';
import AnalyticsAnchor from '../../../../../components/buttons/AnalyticsAnchor';
import Currency from '../../../../../components/globals/Currency';

const Overlay = styled(MuiDialog)`
  &&{
    .MuiPaper-root{
      margin: 0 16px;
      background: ${colors.screenBackground};
      padding: 16px 24px;
      width: calc(100vw - 32px);
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  background-image: url('${CloseOutlineIcon}');
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  margin-top: 8px;
  ${flexBox}
  align-items: center;
`;

const Label = styled.p`
  ${paragraph}
  font-weight: 600;
  font-size: 12px;
`;

const Value = styled.p`
  ${paragraph}
  font-size: 18px;
  color: ${colors.text02};
`;

const CardBrandImg = styled.img`
  height: 20px;
  width: 35px;
  object-fit: contain;
  object-position: center;
  margin-right: 5px;
`;

const GoToImg = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
`;

const PaymentOverlay = ({ setPayment, payment, intl }) => payment && (
  <Overlay
    open={!!payment}
  >
    <Header>
      <CloseButton
        onClick={() => setPayment(null)}
      />
    </Header>
    <Content>
      <Row>
        <Label>
          <FormattedMessage
            id="k.date"
            defaultMessage="Date"
          />
        </Label>
        <Value>
          <FormattedDate
            value={new Date(payment.date * 1000)}
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        </Value>
      </Row>
      <Row>
        <Label>
          <FormattedMessage
            id="k.account"
            defaultMessage="Account"
          />
        </Label>
        <Value>
          {(payment.typeCharge === 'App') ? (
            <>
              <span>{payment.account.name}</span>
            </>
          ) : (
            <>
              <CardBrandImg
                src={cards[payment.account.brand.toLowerCase()]}
                alt={payment.account.brand}
              />
              <span>{payment.account.name.split(' ')[0]}</span> **{payment.account.last4}
            </>
          )}
        </Value>
      </Row>
      <Row>
        <Label>
          <FormattedMessage
            id="k.description"
            defaultMessage="Description"
          />
        </Label>
        <Value>
          <FormattedMessage
            id={`settings.billing.paymentHistory.payment.concept.${payment.concept}`}
            defaultMessage={PAYMENT_CONCEPT[payment.concept]}
            values={{
              prevMonth: intl.formatDate(subMonths(new Date(), 1), {
                month: 'long',
              }),
            }}
          />
        </Value>
      </Row>
      <Row>
        <Label>
          <FormattedMessage
            id="k.amount"
            defaultMessage="Amount"
          />
        </Label>
        <Value>
          <Currency
            value={payment.amount.value}
            style="currency"
            currency={payment.amount.currency}
          />
        </Value>
      </Row>

      {(payment.typeCharge === 'App') ? (
        <></>
      ) : (
        <>
          <Row justifyContent="flex-end">
            <AnalyticsAnchor
              href={payment.receiptUrl}
              target="_blank"
              rel="noreferrer"
              gaData={{
                action: 'Download an invoice',
                label: '(on billing & plan)',
              }}
            >
              <GoToImg src={GoToIcon} alt="download" />
            </AnalyticsAnchor>
          </Row>
        </>
      )}
    </Content>
  </Overlay>
);

PaymentOverlay.propTypes = {
  setPayment: PropTypes.func.isRequired,
  payment: PropTypes.instanceOf(Object),
  intl: PropTypes.instanceOf(Object).isRequired,
};

PaymentOverlay.defaultProps = {
  payment: null,
};

export default PaymentOverlay;
