export default {
	'onboarding.biz.title': 'Let’s set up your first business / product!',
	'onboarding.biz.step1.title': 'Create your first Business / Product',
	'onboarding.biz.step1.button': 'Create',
	'onboarding.biz.step1.errorForm.companyName': 'Company name is needed to continue',
	'onboarding.biz.step2.title': 'Connect this business / product socials accounts',
	"onboarding.biz.step2.modal.title": "Connect [{businessName}] social accounts",
	'onboarding.biz.step2.piper.advice': 'Connect at least 1 social account. Remmember, you must  have an admin profile to do this.',
	'onboarding.biz.step2.button': 'Connect',
	'onboarding.biz.step3.title': 'Set this Business / Product audience',
	'onboarding.biz.step3.subtitle': 'It is not mandatory to modify the audience at this time.',
	"onboarding.biz.step3.modal.title": "Set [{businessName}] audience",
	'onboarding.biz.step3.button': 'Set audience',
	'onboarding.biz.step4.title': 'Add members to manage this Business /Product',
	"onboarding.biz.step4.modal.title": "Add members to manage [{businessName}]",
	'onboarding.biz.step4.social': 'Social',
	'onboarding.biz.step4.restrictions': 'Restrictions',
	'onboarding.biz.step4.restriction.post': 'Needs authorization to <b>Post</b>',
	'onboarding.biz.step4.restriction.boost': 'Can <b>boost</b> immediately',
	'onboarding.biz.step4.restriction.community': 'Access to <b>Community</b>',
	'onboarding.biz.step4.subtitle': 'You can do this later on your settings section',
	'onboarding.biz.step4.subtitle.2': '{q} members added',
	'onboarding.biz.step4.button': 'Add members',
	'onboarding.biz.continue': 'Continue',
	'onboarding.biz.modal.save': 'Save',
	'onboarding.biz.steps.save': 'Save member'
}