import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { flexBox } from '../../styled/mixins';
import colors from '../../styled/colors';
import { ChevronRightIcon, ChevronLeftIcon } from '../../assets/icons/iconRepository';
import GlobalTheme, { breakpointValues } from '../../styled/GlobalTheme';

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
  flex-direction: row;
  padding: 0;
  background-color: ${colors.headerTabActive};
  margin-bottom: 32px;
  height: fit-content;
`;

const FlexBoxDiv = styled.div`
  ${flexBox};
`;

const CardContainer = styled.div`
  ${flexBox};
  width: inherit;
  height: fit-content;
  overflow: hidden;
`;

const CardBox = styled.div`
  ${flexBox};
  transform: translateX(${({ translation }) => (translation || '0')}px);
  transition: transform ease-out 0.5s;
`;

const ChevronBtn = styled.div`
  width: ${({ arrowBackground }) => (arrowBackground ? '48px' : '40px')};
  height: ${({ arrowBackground }) => (arrowBackground ? '48px' : '40px')};
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  display: ${({ disable }) => (disable ? 'none' : 'initial')};
  ${GlobalTheme.breakpoints.down('md')}{
    width: ${({ arrowBackground }) => (arrowBackground ? '29px' : '40px')};
    height: ${({ arrowBackground }) => (arrowBackground ? '29px' : '40px')};
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 40px;
    height: 40px;
  }
`;

const ChevronRightBtn = styled(ChevronBtn)`
  margin-left: ${({ arrowBackground }) => (arrowBackground ? '-48px' : '-40px')};
  background: ${({ arrowBackground, theme }) => (
    arrowBackground ? theme.colors.white : 'rgb(255,255,255,0.5)'
  )} url(${ChevronRightIcon}) no-repeat 0 0;
  background-position: center;
  ${GlobalTheme.breakpoints.down('md')}{
  margin-left: ${({ arrowBackground }) => (arrowBackground ? '-29px' : '-40px')};
  }
  ${GlobalTheme.breakpoints.down('sm')}{
  margin-left: -40px;
  }
`;

const ChevronLeftBtn = styled(ChevronBtn)`
  margin-right: ${({ arrowBackground }) => (arrowBackground ? '-48px' : '-40px')};
  background: ${({ arrowBackground, theme }) => (
    arrowBackground ? theme.colors.white : 'rgba(246,246,246,0.75)'
  )} url(${ChevronLeftIcon}) no-repeat 0 0;
  background-position: center;
${GlobalTheme.breakpoints.down('md')}{
  margin-right: ${({ arrowBackground }) => (arrowBackground ? '-29px' : '-40px')};
}
${GlobalTheme.breakpoints.down('sm')}{
  margin-right: -40px;
}
`;

const Carousel = (props) => {
  const { arrowBackground, children, className } = props;
  const screenWidth = window.innerWidth;
  const [carouselWidth, setCarouselWidth] = useState('');
  const [totalIndex, setTotalIndex] = useState(1);
  const [activeIndex, setActiveIndex] = useState(1);
  const [translation, setTranslation] = useState(0);
  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);
  const previous = () => {
    if (activeIndex === 1) {
      setDisableLeft(true);
      setDisableRight(false);
    } else {
      const index = activeIndex - 1;
      const isDisable = index === 1;
      setTranslation(translation + carouselWidth);
      setActiveIndex(index);
      setDisableLeft(isDisable);
      setDisableRight(false);
    }
  };
  const next = () => {
    if ((activeIndex + 1) === totalIndex) {
      setTranslation(translation - carouselWidth);
      setActiveIndex(activeIndex + 1);
      setDisableRight(true);
      setDisableLeft(false);
    } else {
      setTranslation(translation - carouselWidth);
      setActiveIndex(activeIndex + 1);
      setDisableLeft(false);
    }
  };
  const getRemainingWidth = () => {
    let remainingWidth = 24;
    if (screenWidth <= breakpointValues.sm) {
      remainingWidth = 16;
    }
    return remainingWidth;
  };
  const getWidth = (element) => {
    if (element && element.clientWidth) {
      const { clientWidth } = element;
      const remainingWidth = getRemainingWidth();
      setCarouselWidth(clientWidth + remainingWidth);
    }
  };
  const getCardSWidth = (element) => {
    if (element && element.clientWidth && carouselWidth !== '') {
      const { clientWidth } = element;
      const index = Math.round(clientWidth / carouselWidth);
      setTotalIndex(index);
      if (index === 1) {
        setDisableRight(true);
      }
    }
  };

  return (
    <FlexContainer
      className={className}
    >
      <FlexBoxDiv>
        <ChevronLeftBtn
          arrowBackground={arrowBackground}
          onClick={previous}
          disable={disableLeft}
        />
      </FlexBoxDiv>
      <CardContainer ref={getWidth}>
        <CardBox ref={getCardSWidth} translation={translation}>
          {children}
        </CardBox>
      </CardContainer>
      <FlexBoxDiv>
        <ChevronRightBtn
          arrowBackground={arrowBackground}
          onClick={next}
          disable={disableRight}
        />
      </FlexBoxDiv>
    </FlexContainer>
  );
};

Carousel.propTypes = {
  arrowBackground: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Carousel.defaultProps = {
  arrowBackground: false,
  className: '',
};

export default Carousel;
