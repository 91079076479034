import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Tabs from '../../components/NewTabs';
import { Layout, LayoutContext } from '../../contexts/LayoutContext';
import routes from '../../utils/constants/routes';
import SettingsHero from './SettingsHero';
import Billing from './tabs/Billing';
import Business from './tabs/Business';
import Competitors from './tabs/Competitors';
import Personal from './tabs/Personal';
import Social from './tabs/Social';

export const SETTINGS_ROUTES = {
  personal: `${routes.SETTINGS}/personal`,
  social: `${routes.SETTINGS}/social`,
  business: `${routes.SETTINGS}/business`,
  billing: `${routes.SETTINGS}/billing`,
  competitors: `${routes.SETTINGS}/competitors`,
};

export const Profiles = Object.freeze({
  ADMIN: 1,
  DEFAULT: 2,
  CTRL: 3,
  MASTER: 4,
  COLLAB: 5,
});

const Settings = ({ match, location }) => {
  const intl = useIntl();
  const { user } = useContext(LayoutContext);

  const tabs = useMemo(
    () =>
      [
        {
          title: 'Business & Audience',
          allowedProfiles: [Profiles.DEFAULT, Profiles.MASTER],
          header: {
            name: intl.formatMessage({
              id: 'settings.tab.businessAndAudience',
              defaultMessage: 'Business & Audience',
            }),
            to: SETTINGS_ROUTES.business,
          },
          Component: Business,
        },
        {
          title: 'Social Profile',
          allowedProfiles: [Profiles.DEFAULT],
          header: {
            name: intl.formatMessage({
              id: 'settings.tab.socialProfile',
              defaultMessage: 'Social Profile',
            }),
            to: SETTINGS_ROUTES.social,
          },
          Component: Social,
        },
        {
          title: 'Personal & Preferences',
          allowedProfiles: [Profiles.DEFAULT, Profiles.MASTER, Profiles.COLLAB],
          header: {
            name: intl.formatMessage({
              id: 'settings.tab.personalAndPreferences',
              defaultMessage: 'Personal & Preferences',
            }),
            to: SETTINGS_ROUTES.personal,
          },
          Component: Personal,
        },
        {
          title: 'Competitors',
          allowedProfiles: [Profiles.DEFAULT],
          header: {
            name: intl.formatMessage({
              id: 'settings.tab.competitors',
              defaultMessage: 'Competitors',
            }),
            to: SETTINGS_ROUTES.competitors,
          },
          Component: Competitors,
        },
        {
          title: 'Billing & Plan',
          allowedProfiles: [Profiles.DEFAULT, Profiles.MASTER],
          header: {
            name: intl.formatMessage({
              id: 'settings.tab.billingAndPlan',
              defaultMessage: 'Billing & Plan',
            }),
            to: SETTINGS_ROUTES.billing,
          },
          Component: Billing,
        },
      ].filter((t) => t.allowedProfiles.includes(user.profileId)),
    [user.profileId],
  );

  const title = useMemo(() => {
    const tab = tabs.find((t) => t.header.to === location.pathname) || tabs[0];
    return tab?.title || '';
  }, [tabs, user]);

  /** The accessOnTrialEnds makes this view accessible accessible when the trial ends */
  return (
    <Layout title={`Settings - ${title}`} accessOnTrialEnds>
      <SettingsHero />
      <Tabs tabs={tabs} convertsToDropdown match={match} location={location} />
    </Layout>
  );
};

Settings.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default Settings;
