import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { colors } from '../../../../../styled/theme';
import AudienceInterestsSelect from './AudienceInterestsSelect';
import AudienceLocationsSelect from './AudienceLocationsSelect';
import AudienceSize from './AudienceSize';
import { FacebookLogo } from '../../../../../assets/social/socialRepository';
import { heading3 } from '../../../../../styled/mixins';
import FormTooltip from '../../../../../components/globals/FormTooltip';
import { onboardingTooltips } from '../../../../../assets/images/imageRepository';
import GlobalTheme from '../../../../../styled/GlobalTheme';

const Container = styled.div`
  border-top: 1px solid ${colors.gray90};
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${({ isModal }) => isModal && css`
    padding: 12px;
    border: 1px solid ${colors.grayE9};
    border-radius: 6px;
  `}
`;

const Column = styled.div`
  width: ${({ width }) => (width || '45%')};
  flex: 0 0 ${({ width }) => (width || '45%')};
  ${GlobalTheme.breakpoints.down('md')} {
    width: 45%;
    flex: 0 0 45%;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const StyledAudienceLocationsSelect = styled(AudienceLocationsSelect)`
  width: 100%;
  margin: 0 0 24px;
`;

const StyledAudienceInterestsSelect = styled(AudienceInterestsSelect)`
  width: 100%;
  margin: 0 0 24px;
`;

const TitleContainer = styled.div`
  width: 100%;
`;

const StyledFormTooltip = styled(FormTooltip)`
  && {
    width: auto;
    display: inline-block;
    ${GlobalTheme.breakpoints.down('sm')} {
      display: block;
    }
  }
`;

const Title = styled.h3`
  ${heading3}
  color: ${colors.facebook};
  display: inline-flex;
  align-items: center;
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`;

const FacebookImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  margin-right: 8px;
`;

const tooltipBreakpoint = 'sm';

const AudienceFormFacebook = ({
  audienceInfo,
  setLocations,
  setInterests,
  potentialAudience,
  isModal,
  className,
  children,
}) => {
  const intl = useIntl();
  const audienceLocationRef = useRef();
  const audienceInterestsRef = useRef();
  return (
    <Container
      isModal={isModal}
      className={className}
    >
      <TitleContainer>
        <StyledFormTooltip
          description={intl.formatMessage({
            id: 'onboarding.tooltip.audience.facebook.clients',
            defaultMessage: 'At Facebook, your potential clients share their general interests. Which relate to your business?',
          })}
          onLeft
          tooltipBreakpoint={tooltipBreakpoint}
        >
          <Title>
            <FacebookImg src={FacebookLogo} alt="facebook" />
            <FormattedMessage
              id="onBoarding.facebook.audience.title"
              defaultMessage="Your clients on Facebook"
            />
          </Title>
        </StyledFormTooltip>
      </TitleContainer>
      {children}
      <Column
        width={isModal && '60%'}
      >
        <FormTooltip
          title={intl.formatMessage({
            id: 'onboarding.tooltip.audience.locations.title',
            defaultMessage: 'Audience locations',
          })}
          description={intl.formatMessage({
            id: 'onboarding.tooltip.audience.locations.description',
            defaultMessage: 'You can choose one or more locations, in addition to a city, you can indicate a state, country or region. We recommend that you only choose the main ones or where your business has the greatest influence, since this information will be used when creating boosts on Facebook.{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
          }, {
            linebreak: <br />,
          })}
          inputRef={audienceLocationRef}
          image={onboardingTooltips.AudienceLocation[intl.locale]}
          display="block"
          onLeft
          tooltipBreakpoint={tooltipBreakpoint}
        >
          <StyledAudienceLocationsSelect
            value={audienceInfo.locations}
            setLocations={setLocations}
            intl={intl}
            showRequired={isModal}
            selectRef={audienceLocationRef}
          />
        </FormTooltip>
        <FormTooltip
          title={intl.formatMessage({
            id: 'onboarding.tooltip.audience.interests.title',
            defaultMessage: 'Audience interests',
          })}
          description={intl.formatMessage({
            id: 'onboarding.tooltip.audience.interests.description',
            defaultMessage: 'Choose one or more interests that describe best the market you are targeting. We recommend that you choose only those that are the most relevant, since this information will be used when creating boosts on Facebook, and for Piper to give you more accurate recommendations on graphic templates that may interest you to publish.{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
          }, {
            linebreak: <br />,
          })}
          inputRef={audienceInterestsRef}
          image={onboardingTooltips.AudienceInterest[intl.locale]}
          display="block"
          onLeft
          tooltipBreakpoint={tooltipBreakpoint}
        >
          <StyledAudienceInterestsSelect
            value={audienceInfo.interests}
            setInterests={setInterests}
            intl={intl}
            showRequired={isModal}
            selectRef={audienceInterestsRef}
          />
        </FormTooltip>
      </Column>
      <Column
        width={isModal && '30%'}
      >
        <AudienceSize
          potentialAudience={potentialAudience}
          showAdvice={!isModal}
          isModal={isModal}
        />
      </Column>
    </Container>
  );
};

AudienceFormFacebook.propTypes = {
  audienceInfo: PropTypes.instanceOf(Object).isRequired,
  setLocations: PropTypes.func.isRequired,
  setInterests: PropTypes.func.isRequired,
  potentialAudience: PropTypes.instanceOf(Object).isRequired,
  isModal: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

AudienceFormFacebook.defaultProps = {
  className: '',
};

export default AudienceFormFacebook;
