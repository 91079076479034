import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  
} from 'react-stripe-elements';
import styled from 'styled-components';
import { colors } from '../../styled/theme';
import FormController from '../globals/FormController';
import { input } from '../../styled/mixins';
import mediaQueries from '../../utils/mediaQueries';
import FormTooltip from '../globals/FormTooltip';
import GlobalTheme from '../../styled/GlobalTheme';
import { useIntl } from 'react-intl';

const FormElement = styled(FormController)`
  flex: 0 0 ${(props) => (props.width || '100%')};
  color: ${(props) => props.theme.colors.black};
  margin: 12px 0;
`;

const StyledCardNumberElement = styled.div`
  flex: 0 0 100%;
  ${input}
  color: ${(props) => props.theme.colors.black};
  background-color: white;
`;

const stripeStyle = {
  base: {
    color: colors.black,
    letterSpacing: 'normal',
    fontSize: '18px',
    fontFamily: 'Open Sans',
    '::placeholder': {
      color: colors.gray90,
    },
  },
  invalid: {
    color: colors.red,
  },
};

const ContainerCVC = styled.div`
  position: relative;
  width: 85px;
  display: flex;
  ${GlobalTheme.breakpoints.down('md')}{
    width: ${(props) => (props.isBillingSection ? '20%' : '85px')};
  };
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 40%;
    margin-right: 0;
  };
`;

const ContainerToolTip = styled(FormTooltip)`
  margin-top: 30px;
  z-index:100;
  
`;

const BoxToolTip = styled.div`
  position: absolute;
  top: 24px;
  left: 30px;

  ${GlobalTheme.breakpoints.down('sm')}{
    left: 60px;
  }
`;

const StripeCardFormElements = (props) => {
  const intl = useIntl()
  const cvcRef = useRef();

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <>
          <FormElement
            label={
              intl.formatMessage({
                id: 'addPayment.cardDetails.cardNumberShort',
                defaultMessage: 'Card number',
              })
            }
            // eslint-disable-next-line no-nested-ternary
            width={matches.smDown ? '100%' : '46%'}
          >
            <StyledCardNumberElement>
              <CardNumberElement
                id="card-input-number"
                style={stripeStyle}
              />
            </StyledCardNumberElement>
          </FormElement>
          <FormElement
            label={
              intl.formatMessage({
                id: 'addPayment.cardDetails.expirationDateShort',
                defaultMessage: 'Exp',
              })
            }
            // eslint-disable-next-line no-nested-ternary
            width={matches.smDown ? '40%' : '20%'}
          >
            <StyledCardNumberElement>
              <CardExpiryElement
                id="card-input-expiry"
                style={stripeStyle}
              />
            </StyledCardNumberElement>
          </FormElement>
          <ContainerCVC>
            <FormElement
              label="CVC"
              width={matches.smDown ? '60%' : '100%'}
            >
              <StyledCardNumberElement>
                <CardCvcElement
                  id="card-input-cvc"
                  style={stripeStyle}
                  inputRef={cvcRef}
                />
              </StyledCardNumberElement>
            </FormElement>
            <BoxToolTip>
              <ContainerToolTip
                title={intl.formatMessage({
                  id: 'onboarding.tooltip.pro.cvc.title',
                  defaultMessage: 'CVC',
                })}
                description={intl.formatMessage({
                  id: 'onboarding.tooltip.pro.cvc.description',
                  defaultMessage: 'CVC are the three numbers of your security code in the back of your card',
                }, {
                  linebreak: <br />,
                })}
                inputRef={cvcRef}
                tooltipWidth={matches.smDown ? '300px' : '230px'}
                customizable
                onLeft
              />
            </BoxToolTip>
          </ContainerCVC>
        </>
      )}
    </Media>
  );
};

StripeCardFormElements.propTypes = {
  intl: PropTypes.instanceOf(Object),
};

StripeCardFormElements.defaultProps = {
};

export default StripeCardFormElements;
