import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import { getInterests, searchLinkedinInterests, fetchLinkedInSuggestions } from '../../graphql/queries';
import suggestionWords from '../../mocks/audience/suggestion.mock';

export default class InterestProvider {
  static async linkedinSearch(searchValue) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: searchLinkedinInterests,
          variables: {
            wordToSearch: searchValue,
          },
        });
        data = response.data.searchLinkedinInterests.map(({
          id, name,
        }) => ({
          id,
          value: id,
          label: name,
        }));
      } else {
        data = suggestionWords.suggestionObject.map(({
          id, name,
        }) => ({
          id,
          value: id,
          label: name,
        }));
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async facebookSearch(searchValue, intl) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: getInterests,
        variables: {
          query: searchValue,
          language: intl.locale,
        },
      });
      const data = response.data.interests.map(({
        id,
        name,
      }) => ({
        id,
        value: id,
        label: name,
      }));
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async linkedinSearchSuggestion(searchValues = []) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: fetchLinkedInSuggestions,
          variables: {
            interests: searchValues,
          },
        });
        data = response.data.fetchLinkedInSuggestions.map(({
          id, name,
        }) => ({
          id,
          value: id,
          label: name,
        }));
      } else {
        // could be normal
        data = suggestionWords.suggestionObject.map(({
          id, name,
        }) => ({
          id,
          value: id,
          label: name,
        }));
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async search(searchValue, intl, isLinkedin = false) {
    if (isLinkedin) {
      return this.linkedinSearch(searchValue);
    }
    return this.facebookSearch(searchValue, intl);
  }
}
