import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../buttons';
import { CloseOutlineIcon } from '../../../assets/icons/iconRepository';
import { liAddPaymentMethodImage, liAddPaymentMethodImageEs } from '../../../assets/images/imageRepository';
import mediaQueries from '../../../utils/mediaQueries';

const ModalContainer = styled.div`
  background-color: white;
  left: 50%;
  max-width: 754px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: 25px 0 40px;
  overflow: auto;
  @media ${mediaQueries.mdUp} {
    height: 700px;
  }
`;

const Header = styled.header`
  padding: 0 16px;
  margin-bottom: 20px;
  @media ${mediaQueries.mdUp} {
    padding: 0 40px;
  }
`;
const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 12px;
  @media ${mediaQueries.mdUp} {
    font-size: 32px;
  }
`;

const SubTitle = styled.h2`
  font-size: 16px;
  @media ${mediaQueries.mdUp} {
    font-size: 24px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 30px;
  padding: 0 16px;
  @media ${mediaQueries.mdUp} {
    padding: 0 52px;
  }
`;
const Step = styled.div``;
const StepContainer = styled.div`
  display: flex;
  align-items: baseline;
`;
const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.grayE9};
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  height: 24px;
  width: 24px;
`;
const StepDescription = styled.div`
  color: ${({ theme }) => theme.colors.black3E};
  flex: 1;
  font-size: 14px;
  margin-left: 8px;
  @media ${mediaQueries.mdUp} {
    font-size: 18px;
    max-width: 572px;
  }
`;
const StepImageContainer = styled.div`
  max-width: 240px;
  margin-left: 32px;
  margin-top: 12px;
  @media ${mediaQueries.mdUp} {
    max-width: 300px;
  }
`;
const StepImage = styled.img`
  width: 100%;
`;
const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 26px;
  margin-top: 22px;
  @media ${mediaQueries.mdUp} {
    justify-content: flex-end;
    padding: 0 40px;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 4px;
`;
const CloseIcon = styled.img`
  width: 30px;
  height: 30px;
`;
const Link = styled.a`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryLighter};
  text-decoration: underline;
`;
const HighlightText = styled.span`
  color: ${({ theme }) => theme.colors.black3E};
  font-weight: bold;
`;

const StepsAddPaymentMethodModal = ({ onCloseModal }) => {
  const intl = useIntl();
  const [liImage, setLiImage] = useState({});
  const isMobile = window.matchMedia(mediaQueries.mdDownLess);
  const URL_CAMPAIGN_MANAGER = 'https://www.linkedin.com/campaignmanager/accounts';
  const steps = [
    {
      number: 1,
      description: (
        <FormattedMessage
          id="socialProfiles.linkedIn.videoModal.payment.step1"
          defaultMessage="Sign in to your <link>Campaign Manager.</link>"
          values={{
            link: (...chunks) => (
              <Link
                href={URL_CAMPAIGN_MANAGER}
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      ),
      hasImage: false,
    },
    {
      number: 2,
      description: (
        <FormattedMessage
          id="socialProfiles.linkedIn.videoModal.payment.step2"
          defaultMessage="Click on the account name you want to manage."
        />
      ),
      hasImage: true,
      image: liImage.step2,
    },
    {
      number: 3,
      description: isMobile.matches ? (
        <FormattedMessage
          id="socialProfiles.linkedIn.videoModal.payment.mobile.step3"
          defaultMessage="Tap the account name in the top of the page and select <h>Billing center</h> from the dropdown."
          values={{
            h: (...chunks) => (<HighlightText>{chunks}</HighlightText>),
          }}
        />
      ) : (
        <FormattedMessage
          id="socialProfiles.linkedIn.videoModal.payment.step3"
          defaultMessage="Click the account name in the top right corner of the page and select <h>Billing center</> from the dropdown."
          values={{
            h: (...chunks) => (<HighlightText>{chunks}</HighlightText>),
          }}
        />
      ),
      hasImage: true,
      image: isMobile.matches ? liImage.step3Mobile : liImage.step3,
    },
    {
      number: 4,
      description: (
        <FormattedMessage
          id="socialProfiles.linkedIn.videoModal.payment.step4"
          defaultMessage="Click <h>Add credit card</h> in the middle of the page."
          values={{
            h: (...chunks) => (<HighlightText>{chunks}</HighlightText>),
          }}
        />
      ),
      hasImage: true,
      image: liImage.step4,
    },
    {
      number: 5,
      description: (
        <FormattedMessage
          id="socialProfiles.linkedIn.videoModal.payment.step5"
          defaultMessage="Remember to <h>come back</h> after you add a payment method for your Ad Account on LinkedIn."
          values={{
            h: (...chunks) => (<HighlightText>{chunks}</HighlightText>),
          }}
        />
      ),
      hasImage: false,
    },
  ];

  const handleShowPage = () => {
    window.open(URL_CAMPAIGN_MANAGER, '_blank', 'noopener, noreferrer');
  };

  useEffect(() => {
    if (intl.locale === 'es') {
      setLiImage(liAddPaymentMethodImageEs);
    } else {
      setLiImage(liAddPaymentMethodImage);
    }
  }, [intl.locale]);
  return (
    <ModalContainer>
      <CloseButtonWrapper onClick={onCloseModal}>
        <CloseIcon src={CloseOutlineIcon} alt="Close" />
      </CloseButtonWrapper>
      <Header>
        <Title>
          <FormattedMessage
            id="socialProfiles.linkedIn.videoModal.payment.title"
            defaultMessage="Next, add your payment method."
          />
        </Title>
        <SubTitle>
          <FormattedMessage
            id="socialProfiles.linkedIn.videoModal.payment.subtitle"
            defaultMessage="Watch the steps:"
          />
        </SubTitle>
      </Header>
      <Content>
        {steps.map((step) => (
          <Step>
            <StepContainer>
              <StepNumber>{step.number}</StepNumber>
              <StepDescription>
                {step.description}
              </StepDescription>
            </StepContainer>
            {step.hasImage && (
              <StepImageContainer>
                <StepImage src={step.image} />
              </StepImageContainer>
            )}
          </Step>
        ))}
      </Content>
      <Footer>
        <Button onClick={handleShowPage}>
          <FormattedMessage id="k.continue" defaultMessage="Continue" />
        </Button>
      </Footer>
    </ModalContainer>
  );
};

export default StepsAddPaymentMethodModal;
