import styled from 'styled-components';
import PiperAdvice from '../../../../components/globals/PiperAdvice';
import GlobalTheme from '../../../../styled/GlobalTheme';
import { container } from '../../../../styled/mixins';

export const BusinessContainer = styled.div`
  ${container};
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  grid-row-gap: 32px;
  padding-bottom: 70px;
`;

export const StyledPiperAdvice = styled(PiperAdvice)`
  margin: 0;
  align-items: flex-start;
  display: flex;
  ${GlobalTheme.breakpoints.down('md')} {
    font-size: 20px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;

export const BusinessSelectionWrapper = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
`;

export const BusinessItemWrapper = styled.div`
  display: flex;
`;

export const BusinessItem = styled.div`
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.grayE9};
  background: ${(props) =>
    props.selected ? props.theme.colors.secondaryDark : props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
