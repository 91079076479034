import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { flexBox } from '../../../../../styled/mixins';
import AudienceAgeRangeSlider from './AudienceAgeRangeSlider';
import AudienceGenderSwitch from './AudienceGenderSwitch';
import FormTooltip from '../../../../../components/globals/FormTooltip';
import { onboardingTooltips } from '../../../../../assets/images/imageRepository';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { colors } from '../../../../../styled/theme';

const Row = styled.div`
${flexBox}
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;  
  ${GlobalTheme.breakpoints.up('md')} {
    width: calc(100% - 30px);
    margin: 1em 0;
  }
`;

const styledNumbered = css`
  position: relative;
  padding-left: 50px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    counter-increment: audienceSteps;
    content: counter(audienceSteps);
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.secondary};
    border-radius: 100%;
    font-size: 24px;
    font-weight: 700;
    width: 40px;
    height: 40px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    padding-left: 40px;
    &:before{
      width: 32px;
      height: 32px;
      font-size: 18px;
    }
  }
`;

const StyledFormTooltip = styled(FormTooltip)`
  && {
    width: 45%;
    flex: 0 0 45%;
    margin: 0 0 24px;
    ${GlobalTheme.breakpoints.down('md')} {
      width: 60%;
      flex: 0 0 60%;
    }
    ${GlobalTheme.breakpoints.down('sm')} {
      width: 100%;
      flex: 0 0 100%;
    }
  }
`;

const StyledAudienceGenderSwitch = styled(AudienceGenderSwitch)`
  margin: 0 0 24px;  
  ${GlobalTheme.breakpoints.up('md')} {
    margin: 0;
  }
  ${({ showNumbered }) => showNumbered && styledNumbered}
`;

const StyledAudienceAgeRangeSlider = styled(AudienceAgeRangeSlider)`
  margin: 0 0 24px;
  ${GlobalTheme.breakpoints.up('md')} {
    margin: 0;
  }
  ${({ showNumbered }) => showNumbered && styledNumbered}
`;

const tooltipBreakpoint = 'lg';

const AudienceFormAgeGender = ({
  audienceInfo,
  shouldShow,
  setGender,
  setAges,
  genders,
  intl,
  isModal,
  showNumbered,
}) => {
  const audienceGenderRef = useRef();
  const audienceAgeRef = useRef();
  return (
    <>
      {(!isModal || shouldShow) && (
        <Row>
          <StyledFormTooltip
            title={intl.formatMessage({
              id: 'onboarding.tooltip.audience.gender.title',
              defaultMessage: 'Gender',
            })}
            description={intl.formatMessage({
              id: 'onboarding.tooltip.audience.gender.description',
              defaultMessage: 'It is highly recommended that you choose the genre that conforms the majority of your audience for your business, since this information will be used when creating boosts on Facebook.{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
            }, {
              linebreak: <br />,
            })}
            inputRef={audienceGenderRef}
            image={onboardingTooltips.Gender[intl.locale]}
            display="block"
            onLeft
            tooltipBreakpoint={tooltipBreakpoint}
          >
            <StyledAudienceGenderSwitch
              gender={audienceInfo.gender}
              setGender={setGender}
              genders={genders}
              showRequired={isModal}
              switchRef={audienceGenderRef}
              showNumbered={showNumbered}
            />
          </StyledFormTooltip>
          <StyledFormTooltip
            title={intl.formatMessage({
              id: 'onboarding.tooltip.audience.age.title',
              defaultMessage: 'Age',
            })}
            description={intl.formatMessage({
              id: 'onboarding.tooltip.audience.age.description',
              defaultMessage: 'Here you can choose the age range that conforms the majority of your audience for your business. We recommend that you choose a range within which the majority of your audience is located, since this information will be used when creating boosts on Facebook and thus could be more efficient.{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
            }, {
              linebreak: <br />,
            })}
            inputRef={audienceGenderRef}
            image={onboardingTooltips.Age[intl.locale]}
            display="block"
            onLeft
            tooltipBreakpoint={tooltipBreakpoint}
          >
            <StyledAudienceAgeRangeSlider
              ages={audienceInfo.ages}
              setAges={setAges}
              showRequired={isModal}
              rangeRef={audienceAgeRef}
              showNumbered={showNumbered}
            />
          </StyledFormTooltip>
        </Row>
      )}
    </>
  );
};


AudienceFormAgeGender.propTypes = {
  audienceInfo: PropTypes.instanceOf(Object).isRequired,
  shouldShow: PropTypes.bool.isRequired,
  setGender: PropTypes.func.isRequired,
  genders: PropTypes.instanceOf(Array).isRequired,
  setAges: PropTypes.func.isRequired,
  intl: PropTypes.instanceOf(Object).isRequired,
  isModal: PropTypes.bool,
  showNumbered: PropTypes.bool,
};

AudienceFormAgeGender.defaultProps = {
  isModal: false,
  showNumbered: false,
};

export default AudienceFormAgeGender;