import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Button } from '../buttons';
import Pipers from '../Pipers/Pipers';
import GlobalTheme from '../../styled/GlobalTheme';
import { FACEBOOK } from '../../utils/constants/globals';

const textSemiBoldCenter = css`
  font-weight: 600;
  text-align: center;
`;

const ReconnectFacebookModalSty = styled(MuiDialog)`
  && {
    .MuiPaper-root {
      margin-left: 24px;
      margin-right: 24px;
      ${GlobalTheme.breakpoints.up('md')} {
        max-width: 672px;
      }
      ${GlobalTheme.breakpoints.up('xlPlus')} {
        max-width: 809px;
      }
    }
  }
`;
const Wrapper = styled.div`
  padding: 38px 20px 65px;
  ${GlobalTheme.breakpoints.up('md')} {
    display: flex;
    align-items: center;
    padding: 35px 30px;
  }
  ${GlobalTheme.breakpoints.up('xlPlus')} {
    padding: 40px 56px;
  }
`;
const PiperWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const PiperContent = styled.div`
  width: 126px;
  ${GlobalTheme.breakpoints.up('md')} {
    width: 210px;
  }
`;
const PiperNudging = styled(Pipers.NudgingPaw1)`
  transform: scaleX(-1);
`;
const Content = styled.div`
  margin-top: 34px;
  ${GlobalTheme.breakpoints.up('md')} {
    margin-top: 0;
    margin-left: 40px;
  }
  ${GlobalTheme.breakpoints.up('xlPlus')} {
    margin-left: 46px;
  }
`;
const Header = styled.header`
  margin-bottom: 24px;
  ${GlobalTheme.breakpoints.up('xlPlus')} {
    margin-bottom: 20px;
  }
`;
const Title = styled.h3`
  font-size: 18px;
  ${textSemiBoldCenter};
  ${GlobalTheme.breakpoints.up('md')} {
    font-size: 20px;
    line-height: 26px;
  }
  ${GlobalTheme.breakpoints.up('xlPlus')} {
    font-size: 24px;
    line-height: 34px;
  }
`;
const Body = styled.div``;
const ParagraphAdvice = styled.p`
  color: ${({ theme }) => theme.colors.grayMediaLibrary};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin: 0;
  ${GlobalTheme.breakpoints.up('md')} {
    font-size: 18px;
    line-height: 26px;
  }
  ${GlobalTheme.breakpoints.up('xlPlus')} {

  }
`;
const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ReConnectButton = styled(Button)`
  && {
    font-size: 24px;
    padding: 10px 68px;
    ${GlobalTheme.breakpoints.up('md')} {
      padding-left: 87px;
      padding-right: 87px;
    }
  }
`;

const ReconnectFacebookModal = ({ open, handleDisconnect, loading }) => (
  <ReconnectFacebookModalSty open={open}>
    <Wrapper>
      <PiperWrapper>
        <PiperContent>
          <PiperNudging />
        </PiperContent>
      </PiperWrapper>
      <Content>
        <Header>
          <Title>
            <FormattedMessage
              id="notification.reconnect.facebook.title"
              defaultMessage="We have a very big improvement that will allow you to post directly to your Instagram Business Account!"
            />
          </Title>
        </Header>
        <Body>
          <ParagraphAdvice>
            <FormattedMessage
              id="notification.reconnect.facebook.advice"
              defaultMessage="To make this improvement work, you will need to re-connect your Facebook Account before connecting your Instagram Business Account."
            />
          </ParagraphAdvice>
        </Body>
        <Footer>
          <ButtonWrapper>
            <ReConnectButton
              onClick={() => handleDisconnect(FACEBOOK)}
              isLoading={loading}
            >
              <FormattedMessage
                id="k.ok"
                defaultMessage="OK"
              />
            </ReConnectButton>
          </ButtonWrapper>
        </Footer>
      </Content>
    </Wrapper>
  </ReconnectFacebookModalSty>
);

ReconnectFacebookModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDisconnect: PropTypes.func,
  loading: PropTypes.bool,
};

ReconnectFacebookModal.defaultProps = {
  handleDisconnect: () => {},
  loading: false,
};

export default ReconnectFacebookModal;
