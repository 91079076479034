import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import FormController, {
  LabelInput,
  Required,
} from "../../../../components/globals/FormController";

export default function CommunityInput({
  label,
  required,
  addMore,
  addMoreLimit,
  getter,
  setter,
  placeholder,
}) {
  const addInput = () => {
    const newData = [...getter];
    newData.push("");
    setter(newData);
  };

  const removeInput = (i) => {
    setter((value) => value.filter((_, j) => j !== i));
  };

  const printInputs = () =>
    getter.map((x, i) => (
      <InputBox>
        <Input
          value={x}
          placeholder={placeholder}
          required={required}
          onChange={(e) => {
            e.persist();
            setter((value) =>
              value.map((y, j) => (j === i ? e.target.value : y))
            );
          }}
        />
        {addMore && getter.length > 1 && (
          <RemoveButton onClick={() => removeInput(i)}>-</RemoveButton>
        )}
      </InputBox>
    ));

  return (
    <Field>
      <Labels>
        {label} {required && <Required>*</Required>}
      </Labels>
      <Inputs>{printInputs()}</Inputs>
      {addMore && (addMoreLimit > getter.length || addMoreLimit === -1) && (
        <AddMoreButton onClick={() => addInput()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.29167 6.62126V0.57959H6.04167V6.62126H0V7.87126H6.04167V13.9129H7.29167V7.87126H13.3333V6.62126H7.29167Z"
              fill="#027686"
            />
          </svg>
        </AddMoreButton>
      )}
    </Field>
  );
}

CommunityInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  addMore: PropTypes.bool,
  addMoreLimit: PropTypes.bool,
  getter: PropTypes.arrayOf(PropTypes.string),
  setter: PropTypes.func,
};

CommunityInput.defaultProps = {
  label: "",
  required: false,
  addMore: false,
  addMoreLimit: -1,
  getter: [""],
  setter: null,
};

const Labels = styled(LabelInput)`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const Input = styled(FormController)`
  margin: 0px 0px 0px 0px;
`;

const AddMoreButton = styled(Button)`
  margin-top: 5px !important;
  border: 2px dashed #027686 !important;
  min-width: 0px !important;
  border-radius: 4px !important;
  width: 40px;
  height: 39px;
`;

const RemoveButton = styled(Button)`
  width: 40px;
  height: 39px;
  border: 2px dashed red !important;
  color: red !important;
  min-width: 0px !important;
  border-radius: 4px !important;
  margin-top: 0px !important;
`;
