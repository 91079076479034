import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormController from '../../../../../components/globals/FormController';
import { AgeRangeSlider } from '../../../../onBoarding/components/OnboardingComponents';
import { MIN_AGE, MAX_AGE } from '../../../../../utils/constants/settings';
import useCallGA from '../../../../../hooks/useCallGA';

const StyledAgeRangeSlider = styled(AgeRangeSlider)`
  && {
    .MuiSlider-valueLabel {
      left: calc(-50% + 4px);
      top: calc(50% - 5px);
      & * {
        background: transparent;
        color: white;
        font-size: 12px;
      }
    }
  }
`;

const AudienceAgeRangeSlider = ({
  ages,
  setAges,
  className,
  required,
  showRequired,
  rangeRef,
}) => {
  const [sliderAges, setSliderAges] = useState(ages);
  const callGA = useCallGA();
  useEffect(() => {
    setSliderAges(ages);
  }, [ages]);
  return (
    <FormController
      label={(
        <FormattedMessage
          id="onboarding.AboutAudience.age"
          description="Audience Age"
          defaultMessage="Age"
        />
      )}
      required={required}
      className={className}
      showRequired={showRequired}
    >
      <div
        ref={rangeRef}
      >
        <StyledAgeRangeSlider
          value={sliderAges}
          valueLabelDisplay="on"
          onChange={(_, value) => setSliderAges(value)}
          onChangeCommitted={(e, value) => {
            callGA({ action: 'Set / change age range', label: '(on business & audience)' });
            setAges(e, value);
          }}
          aria-labelledby="range-slider"
          min={MIN_AGE}
          max={MAX_AGE}
        />
      </div>
    </FormController>
  );
};

AudienceAgeRangeSlider.propTypes = {
  ages: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  setAges: PropTypes.func.isRequired,
  showRequired: PropTypes.bool,
  rangeRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
};

AudienceAgeRangeSlider.defaultProps = {
  className: '',
  required: false,
  showRequired: false,
  rangeRef: null,
};

export default AudienceAgeRangeSlider;
