import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../../components/buttons';
import PiperAdvice from '../../../../../components/globals/PiperAdvice';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { SECONDARY } from '../../../../../components/buttons/constants';

const StyledModal = styled(MuiDialog)`
  &&{
    .MuiPaper-root{
      width: 450px;
      padding: 24px;
      ${GlobalTheme.breakpoints.down('sm')}{
        width: 90%;
      }
    }
  }
`;

const StyledPiperAdvice = styled(PiperAdvice)`
  display: flex;
  font-size: 14px;
  margin: 0;
  figure {
    width: 40px;
    img {
      width: 40px;
      height: 40px;
      max-width: none;
      max-height: none;
    }
  }
`;

const ModalActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  align-items: center;
  ${GlobalTheme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

const CancelButton = styled(Button)`
  && {
    padding: 4px 8px;
    font-size: 12px;
    ${GlobalTheme.breakpoints.down('sm')} {
      padding: 8px 24px;
      font-size: 16px;
    }
  }
`;

const ChangeButton = styled(Button)`
  && {
    padding: 4px 8px;
    font-size: 12px;
    margin-left: 16px;
    ${GlobalTheme.breakpoints.down('sm')} {
      margin-left: 0;
      margin-bottom: 16px;
      padding: 8px 24px;
      font-size: 16px;
    }
  }
`;

const AudienceLocationConfirmationModal = ({
  alertInfo,
  handleCancel,
  handleChange,
  className,
}) => (
  <StyledModal
    open={alertInfo.isOpen}
    className={className}
  >
    <StyledPiperAdvice>
      {`${alertInfo.message}`}
    </StyledPiperAdvice>
    <ModalActions>
      <CancelButton
        type={SECONDARY}
        onClick={handleCancel}
      >
        <FormattedMessage
          id="k.cancel"
          defaultMessage="Cancel"
        />
      </CancelButton>
      <ChangeButton
        handlerClick={handleChange}
        disableElevation
      >
        <FormattedMessage
          id="k.yes.change"
          defaultMessage="Yes, change"
        />
      </ChangeButton>
    </ModalActions>
  </StyledModal>
);

AudienceLocationConfirmationModal.propTypes = {
  alertInfo: PropTypes.instanceOf(Object).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AudienceLocationConfirmationModal.defaultProps = {
  className: '',
};

export default AudienceLocationConfirmationModal;
