const es = {
    'upgradeToBusinessModal.title': 'Cuenta de negocio',
    'upgradeToBusinessModal.subtitle': 'Ventajas:',
    'upgradeToBusinessModal.listItem.1': 'Agrega tantos negocios o productos como quieras y conecta las redes sociales de cada uno.',
    'upgradeToBusinessModal.listItem.2': 'Agrega miembros a tu equipo para administrar las redes sociales de tus negocios o productos.',
    'upgradeToBusinessModal.listItem.3': 'Supervisa las publicaciones programadas y los anuncios publicitarios con el sistema de aprobación.',
    'upgradeToBusinessModal.listItem.4': 'Comprueba el rendimiento de tus anuncios y administra tu presupuesto publicitario en cada uno de tus negocios o productos.',
    'upgradeToBusinessModal.footer.1': 'Los primeros 30 días de tu suscripción de tu cuenta de Negocio de Social Piper serán gratis.',
    'upgradeToBusinessModal.footer.2': 'Al actualizar tu cuenta a una de negocio, se invalidará cualquier cupón que actualmente hayas aplicado.',
    'upgradeToBusinessModal.buttons.cancel': 'Cancelar',
    'upgradeToBusinessModal.buttons.upgrade': 'Crecer a una cuenta de negocio'
}

export default es;