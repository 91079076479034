import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { colors } from '../../../../../styled/theme';
import AudienceInterestsSelect from './AudienceInterestsSelect';
import AudienceLocationsSelect from './AudienceLocationsSelect';
import AudienceSize from './AudienceSize';
import { LinkedinLogo } from '../../../../../assets/social/socialRepository';
import { heading3 } from '../../../../../styled/mixins';
import FormTooltip from '../../../../../components/globals/FormTooltip';
import { onboardingTooltips } from '../../../../../assets/images/imageRepository';
import { LINKEDIN, LINKED_IN_MIN_AUDIENCE } from '../../../../../utils/constants/globals';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { ButtonLink } from '../../../../../components/buttons';
import { InterestProvider } from '../../../../../services/lookups';

const Container = styled.div`
  border-top: 1px solid ${colors.gray90};
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${({ isModal }) => isModal && css`
    padding: 12px;
    border: 1px solid ${colors.grayE9};
    border-radius: 6px;
  `}
`;

const Column = styled.div`
  width: ${({ width }) => (width || '45%')};
  flex: 0 0 ${({ width }) => (width || '45%')};
  ${GlobalTheme.breakpoints.down('md')} {
    width: 45%;
    flex: 0 0 45%;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const StyledAudienceLocationsSelect = styled(AudienceLocationsSelect)`
  width: 100%;
  margin: 0 0 24px;
`;

const StyledAudienceInterestsSelect = styled(AudienceInterestsSelect)`
  width: 100%;
  margin: 0 0 24px;
`;

const TitleContainer = styled.div`
  width: 100%;
`;

const StyledFormTooltip = styled(FormTooltip)`
  && {
    width: auto;
    display: inline-block;
    ${GlobalTheme.breakpoints.down('sm')} {
      display: block;
    }
  }
`;

const Title = styled.h3`
  ${heading3}
  color: ${colors.linkedin};
  display: inline-flex;
  align-items: center;
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`;

const LinkedinImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  margin-right: 8px;
`;

const SuggestionsTexts = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  ${({ lowReach }) => lowReach && css`
    color: #FF7E4E;
  `}
`;
const SuggestContent = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;
const ListSuggestion = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
`;

const SuggestItem = styled.li`
  color: #3E3E3E;
  cursor: pointer;
  background: #E9E9E9;
  border-radius: 14px;
  margin-right: 10px;
  padding: 2px 15px;
  font-size: 12px;
  max-width: fit-content;
  margin-bottom: 10px;
`;

export const GivemeButton = styled(ButtonLink)`
  && {
    color: #027686;
    font-size: 14px;
    border: 1px solid #027686;
    padding: 4px 8px;
  }
`;

const tooltipBreakpoint = 'sm';

const AudienceFormLinkedin = ({
  audienceInfo,
  setLocations,
  setInterests,
  potentialAudience,
  isModal,
  className,
  children,
}) => {
  const intl = useIntl();
  const audienceLocationRef = useRef();
  const audienceInterestsRef = useRef();
  const [similarLinkedinWords, setSimilarLinkedinWords] = useState([]);
  const [lastInterest, setLastInterest] = useState({});
  const [showLowAudience, setLowAudience] = useState(false);
  const [showReadyAudience, setShowReadyAudience] = useState(false);

  const pushSuggestion = (suggestion) => {
    const { linkedinInterests } = audienceInfo;
    setInterests([
      ...linkedinInterests,
      suggestion,
    ]);
  };
  const fetchSuggestions = async (lastInt, searchInterests) => {
    if (lastInt && lastInterest && lastInt.id !== lastInterest.id) {
      setLastInterest(lastInt);
      if (lastInt) {
        const requestSuggestions = await InterestProvider.linkedinSearchSuggestion([lastInt]);

        const { success, data } = requestSuggestions;
        if (success && data) {
          const filterSuggestions = data.filter((suggestion) => {
            const finELe = searchInterests.find((si) => (si.id === suggestion.id));
            return !(finELe);
          });
          setSimilarLinkedinWords(filterSuggestions);
        }
      }
    }
  };

  useEffect(() => {
    if (audienceInfo && audienceInfo.linkedinInterests) {
      const { linkedinInterests } = audienceInfo;
      const searchInterests = linkedinInterests.map(({ id, label }) => ({
        id,
        name: label,
      }));
      if (searchInterests.length) {
        const [liSi] = searchInterests && searchInterests.slice(-1);
        fetchSuggestions(liSi, searchInterests);
      } else {
        setSimilarLinkedinWords([]);
      }
    }
    if (audienceInfo) {
      setLowAudience(potentialAudience.size <= LINKED_IN_MIN_AUDIENCE);
      setShowReadyAudience(potentialAudience.size > LINKED_IN_MIN_AUDIENCE);
    }
    if (audienceInfo.linkedinInterests.length === 0) {
      setShowReadyAudience(false);
    }
  }, [audienceInfo, potentialAudience]);
  return (
    <Container
      isModal={isModal}
      className={className}
    >
      <TitleContainer>
        <StyledFormTooltip
          description={intl.formatMessage({
            id: 'onboarding.tooltip.audience.linkedin.clients',
            defaultMessage: 'On Linkedin, you can reach potential clients based on their professional traits; such as: job title, industry and professional interests.',
          })}
          onLeft
          tooltipBreakpoint={tooltipBreakpoint}
        >
          <Title>
            <LinkedinImg src={LinkedinLogo} alt="linkedin" />
            <FormattedMessage
              id="onBoarding.linkedin.audience.title"
              defaultMessage="Your clients on Linkedin"
            />
          </Title>
        </StyledFormTooltip>
      </TitleContainer>
      {children}
      <Column
        width={isModal && '60%'}
      >
        <FormTooltip
          title={intl.formatMessage({
            id: 'onboarding.tooltip.audience.locations.title',
            defaultMessage: 'Audience locations',
          })}
          description={intl.formatMessage({
            id: 'onboarding.tooltip.audience.locations.description',
            defaultMessage: 'You can choose one or more locations, in addition to a city, you can indicate a state, country or region. We recommend that you only choose the main ones or where your business has the greatest influence, since this information will be used when creating boosts on Linkedin.{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
          }, {
            linebreak: <br />,
          })}
          inputRef={audienceLocationRef}
          image={onboardingTooltips.AudienceLocation[intl.locale]}
          display="block"
          onLeft
          tooltipBreakpoint={tooltipBreakpoint}
        >
          <StyledAudienceLocationsSelect
            value={audienceInfo.linkedinLocations}
            setLocations={setLocations}
            intl={intl}
            showRequired={isModal}
            selectRef={audienceLocationRef}
            isLinkedin
          />
        </FormTooltip>
        <FormTooltip
          title={intl.formatMessage({
            id: 'onboarding.tooltip.audience.interests.title',
            defaultMessage: 'Audience interests',
          })}
          description={intl.formatMessage({
            id: 'onboarding.tooltip.audience.interests.description',
            defaultMessage: 'Choose one or more interests that describe best the market you are targeting. We recommend that you choose only those that are the most relevant, since this information will be used when creating boosts on Linkedin, and for Piper to give you more accurate recommendations on graphic templates that may interest you to publish.{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
          }, {
            linebreak: <br />,
          })}
          inputRef={audienceInterestsRef}
          image={onboardingTooltips.AudienceInterest[intl.locale]}
          display="block"
          onLeft
          tooltipBreakpoint={tooltipBreakpoint}
        >
          <StyledAudienceInterestsSelect
            value={audienceInfo.linkedinInterests}
            setInterests={setInterests}
            intl={intl}
            showRequired={isModal}
            selectRef={audienceInterestsRef}
            isLinkedin
          />
          <SuggestionsTexts
            lowReach={showLowAudience}
          >
            {showLowAudience
              ? (intl.formatMessage({
                id: 'audience.interests.li.warning',
                defaultMessage: 'Your audience’s  interest is too small to boost. To broaden it add more interests',
              }))
              : ''}
            {showReadyAudience
              ? (intl.formatMessage({
                id: 'audience.interests.li.info',
                defaultMessage: 'Your audience’s, is ready to boost.',
              }))
              : ''}
            {' '}
            {(!!similarLinkedinWords.length) && intl.formatMessage({
              id: 'audience.interests.li.suggestions',
              defaultMessage: 'you can use these suggestions',
            })}
          </SuggestionsTexts>
          <SuggestContent>
            <ListSuggestion>
              {similarLinkedinWords.map((suggestion) => (
                <SuggestItem
                  onClick={() => pushSuggestion(suggestion)}
                >
                  {suggestion.label}
                </SuggestItem>
              ))}
            </ListSuggestion>
          </SuggestContent>
          {/* save until next design eval
          <GivemeButton>
            {intl.formatMessage({
              id: 'audience.interests.button.giveme',
              defaultMessage: 'Give me more sugestions',
            })}
          </GivemeButton> */}

        </FormTooltip>
      </Column>
      <Column
        width={isModal && '30%'}
      >
        <AudienceSize
          potentialAudience={potentialAudience}
          showAdvice={!isModal}
          isModal={isModal}
          socialNetwork={LINKEDIN}
        />
      </Column>
    </Container>
  );
};

AudienceFormLinkedin.propTypes = {
  audienceInfo: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  setLocations: PropTypes.func.isRequired,
  setInterests: PropTypes.func.isRequired,
  potentialAudience: PropTypes.instanceOf(Object).isRequired,
  isModal: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

AudienceFormLinkedin.defaultProps = {
  className: '',
};

export default AudienceFormLinkedin;
