import { Check, Clear } from '@material-ui/icons';
import React, { useContext, useEffect } from 'react';

import { LayoutContext } from '../../contexts/LayoutContext';
import {
  CheckboxWrapper,
  RestrictionName,
  RestrictionWrapper,
  RestrictionsCheckboxesWrapper,
  SocialAndRestictionSubWrapper,
  SocialAndRestrictionsWrapper,
  SocialBrandName,
  SocialBusinessName,
  SocialCheckboxesWrapper,
  SocialOrRestrictionTitle,
  SocialWrapper,
  TinyLogos,
} from '../ManageCollaboratorsForm/index.styled';
import { text } from '../text';

export default () => {
  const { collaboratorPermissions } = useContext(LayoutContext);

  return (
    <SocialAndRestrictionsWrapper>
      <SocialWrapper>
        <SocialOrRestrictionTitle>{text('onboarding.biz.step4.social')}</SocialOrRestrictionTitle>
        <SocialAndRestictionSubWrapper>
          {collaboratorPermissions.permissions.map((perm) => (
            <NetworkPerBusinessCheckboxes
              businessName={perm.companyName}
              permissions={{
                facebook: perm.facebook?.permission,
                twitter: perm.twitter?.permission,
                instagram: perm.instagram?.permission,
                linkedin: perm.linkedin?.permission,
              }}
              socialNetworks={{
                facebook: perm.facebook?.appName,
                twitter: perm.twitter?.appName,
                instagram: perm.instagram?.appName,
                linkedin: perm.linkedin?.appName,
              }}
            />
          ))}
        </SocialAndRestictionSubWrapper>
      </SocialWrapper>
      <RestrictionWrapper>
        <SocialOrRestrictionTitle>
          {text('onboarding.biz.step4.restrictions')}
        </SocialOrRestrictionTitle>
        <SocialAndRestictionSubWrapper lastchild>
          <RestrictionsCheckboxesWrapper style={{ marginTop: 20 }}>
            <CheckboxWrapper>
              {collaboratorPermissions.hasPost ? (
                <Check color='primary' fontSize='small' />
              ) : (
                <Clear color='error' fontSize='small' />
              )}
              <RestrictionName>
                {text('onboarding.biz.step4.restriction.post', {
                  b: (...chunks) => <b>{chunks}</b>,
                })}
              </RestrictionName>
            </CheckboxWrapper>
            <CheckboxWrapper>
              {collaboratorPermissions.hasBoost ? (
                <Check color='primary' fontSize='small' />
              ) : (
                <Clear color='error' fontSize='small' />
              )}
              <RestrictionName>
                {text('onboarding.biz.step4.restriction.boost', {
                  b: (...chunks) => <b>{chunks}</b>,
                })}
              </RestrictionName>
            </CheckboxWrapper>
            <CheckboxWrapper>
              {collaboratorPermissions.hasCommunity ? (
                <Check color='primary' fontSize='small' />
              ) : (
                <Clear color='error' fontSize='small' />
              )}
              <RestrictionName>
                {text('onboarding.biz.step4.restriction.community', {
                  b: (...chunks) => <b>{chunks}</b>,
                })}
              </RestrictionName>
            </CheckboxWrapper>
          </RestrictionsCheckboxesWrapper>
        </SocialAndRestictionSubWrapper>
      </RestrictionWrapper>
    </SocialAndRestrictionsWrapper>
  );
};

const NetworkPerBusinessCheckboxes = ({ businessName, permissions, socialNetworks }) => {
  return (
    <React.Fragment>
      <SocialBusinessName>{businessName}</SocialBusinessName>
      <SocialCheckboxesWrapper>
        {permissions.facebook && socialNetworks.facebook && (
          <CheckboxWrapper>
            <TinyLogos.Facebook />
            <SocialBrandName>{socialNetworks.facebook}</SocialBrandName>
          </CheckboxWrapper>
        )}
        {permissions.instagram && socialNetworks.instagram && (
          <CheckboxWrapper>
            <TinyLogos.Instagram />
            <SocialBrandName>{socialNetworks.instagram}</SocialBrandName>
          </CheckboxWrapper>
        )}
        {permissions.twitter && socialNetworks.twitter && (
          <CheckboxWrapper>
            <TinyLogos.Twitter />
            <SocialBrandName>{socialNetworks.twitter}</SocialBrandName>
          </CheckboxWrapper>
        )}
        {permissions.linkedin && socialNetworks.linkedin && (
          <CheckboxWrapper>
            <TinyLogos.Linkedin />
            <SocialBrandName>{socialNetworks.linkedin}</SocialBrandName>
          </CheckboxWrapper>
        )}
      </SocialCheckboxesWrapper>
    </React.Fragment>
  );
};
