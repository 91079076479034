import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Profiles } from '../..';
import CollaboratorBlock from '../../../../components/CollaboratorBlock';
import ManageCollaboratorsForm from '../../../../components/ManageCollaboratorsForm';
import UpdateSessionModal from '../../../../components/UpdateSessionModal';
import RocketLoader from '../../../../components/globals/RocketLoader';
import { text } from '../../../../components/text';
import { AlertsContext } from '../../../../contexts/AlertsContext';
import { LayoutContext } from '../../../../contexts/LayoutContext';
import UserProvider from '../../../../services/entities/UserProvider';
import GlobalTheme from '../../../../styled/GlobalTheme';
import { container } from '../../../../styled/mixins';
import { AUTH0, FACEBOOK } from '../../../../utils/constants/globals';
import PersonalInformation from './components/PersonalInformation';
import Preferences, { PreferencesCard } from './components/Preferences';
import UpdatePassword, { Title } from './components/UpdatePassword';
import { OptionHint } from '../../../../components/globals/NavBar/NavBar.styles';
import { HelpBlueIcon } from '../../../../assets/icons/iconRepository';

const Personal = () => {
  const intl = useIntl();
  const [userInfo, setUserInfo] = useState(null);
  const [isNetworkSocial, setIsNetworkSocial] = useState(false);
  const [typeSocial, setTypeSocial] = useState('');
  const [loading, setLoading] = useState(false);
  const [showUpdaSModal, setShowUpdateSmodal] = useState(false);
  const { showAlert, showBackendError } = useContext(AlertsContext);
  const { user } = useContext(LayoutContext);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await UserProvider.fetch(`
        email
        firstName
        lastName
        typeAccount
        currency
        hasCurrencySelected
      `);
      if (!response.success) {
        setUserInfo({
          email: '',
          firstName: '',
          lastName: '',
          currency: '',
        });
      }
      if (response.success) {
        setUserInfo(response.data);
        if (response.data.typeAccount !== AUTH0) {
          setIsNetworkSocial(true);
          setTypeSocial(response.data.typeAccount);
        }
      } else {
        setUserInfo({
          email: '',
          firstName: '',
          lastName: '',
          currency: '',
        });
      }
    };
    fetchUserInfo();
  }, [typeSocial]);

  const handleSavePersonalInformation = async (personalInfo) => {
    setLoading(true);
    const { success, data, message } = await UserProvider.update(personalInfo);
    setLoading(false);
    if (success && data) {
      setUserInfo({
        ...userInfo,
        ...personalInfo,
      });
      if (data?.updateSession) {
        setShowUpdateSmodal(true);
      } else {
        showAlert(
          intl.formatMessage(
            {
              id: 'alert.success.update.female',
              defaultMessage: '{item} updated successfully',
            },
            {
              item: intl.formatMessage({
                id: 'k.personalInfo',
                defaultMessage: 'Personal information',
              }),
            },
          ),
        );
      }
    } else {
      showBackendError(message);
    }
  };

  if (!userInfo) {
    return <RocketLoader />;
  }

  return (
    <PersonalContainer facebookAccount={typeSocial === FACEBOOK} isNetworkSocial={isNetworkSocial}>
      <StyledPersonalInformation
        userInfo={{
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
        }}
        handleSave={handleSavePersonalInformation}
        isNetworkSocial={isNetworkSocial}
        typeSocial={typeSocial}
        loading={loading}
      />
      {user.profileId === Profiles.MASTER && (
        <PreferencesCard style={{ gridArea: 'teams', width: '100%', display: 'block' }}>
          <Title>{text('settings.personal.teams.title')}</Title>
          <ManageCollaboratorsForm />
        </PreferencesCard>
      )}
      {user.profileId === Profiles.COLLAB && (
        <PreferencesCard style={{ gridArea: 'teams', width: '100%', display: 'block' }}>
          <TitleWrapper>
            <Title style={{ marginBottom: 0, width: 'max-content' }}>
              {text('settings.personal.collaborator.title')}
            </Title>
            <HelpHint />
          </TitleWrapper>
          <CollaboratorBlock />
        </PreferencesCard>
      )}
      {!isNetworkSocial && <StyledUpdatePassword />}
      <StyledPreferences currency={userInfo.currency} />
      {showUpdaSModal && <UpdateSessionModal open={showUpdaSModal} />}
    </PersonalContainer>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 24px;
`;

const HelpHint = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(${HelpBlueIcon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
`;

const PersonalContainer = styled.div`
  ${container};
  display: grid;
  grid-template-columns: ${({ isNetworkSocial }) => (isNetworkSocial ? '1fr' : '1fr 390px')};
  grid-template-rows: auto 1fr auto;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  padding-bottom: 70px;
  grid-template-areas:
    'advice advice'
    'personalInfo password'
    'teams teams'
    'preferences preferences';
  ${GlobalTheme.breakpoints.down('lg')} {
    grid-template-columns: 1fr 320px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'advice advice'
      'personalInfo personalInfo'
      'password password'
      'teams teams'
      'preferences preferences';
    grid-template-rows: repeat(4, auto);
  }
  ${({ facebookAccount }) =>
    facebookAccount &&
    css`
      grid-template-areas:
        'advice advice'
        'personalInfo personalInfo'
        'teams teams'
        'preferences preferences';
      ${GlobalTheme.breakpoints.down('sm')} {
        grid-template-areas:
          'advice advice'
          'personalInfo personalInfo'
          'teams teams'
          'preferences preferences';
        grid-template-rows: repeat(3, auto);
      }
    `}
`;

const StyledPersonalInformation = styled(PersonalInformation)`
  grid-area: personalInfo;
  ${GlobalTheme.breakpoints.down('lg')} {
    flex-direction: row;
  }
`;

const StyledUpdatePassword = styled(UpdatePassword)`
  grid-area: password;
`;

const StyledPreferences = styled(Preferences)`
  grid-area: preferences;
  ${GlobalTheme.breakpoints.down('lg')} {
    display: table-row;
  }
`;

export default Personal;
