export const PublicRoutes = {
  LANDING: '/',
  LOGIN: '/login',
  LOGIN_APP: '/login-app',
  PRICING: '/pricing',
  JOIN_PRICING: '/join-pricing',
  ABOUT: '/about',
  CREATE_ACCOUNT: '/create-account',
  CREATE_ACCOUNT_APP: '/create-account-app',
  CREATE_ACCOUNT_PRO: '/create-account-pro',
  CREATE_ACCOUNT_IOS: '/create-account-ios',
  ES: '/es',
  EN: '/en',
  SIGN_UP: '/join-signup',
  LANDINGINSTAGRAM: '/instagram',
  TOOLS: '/tools',
  __TEST__: '/__test__',
};

export const AuthRoutes = {
  DASHBOARD: '/dashboard',
  APPROVALS: '/approvals',
  BUSINESS_ONBOARDING: '/business-onboarding',
  ONBOARDING: '/onboarding',
  VERIFY_EMAIL: '/verify-email',
  SCHEDULER: '/scheduler',
  ONBOARDING_SCHEDULE: '/onboarding/scheduler',
  ONBOARDING_LAUNCHER: '/onboarding/launcher',
  BOOST_MANAGER: '/boost-manager',
  LOGOUT: '/logout',
  MEDIA_LIBRARY: '/creative-center',
  SETTINGS: '/settings',
  SETTINGS_BILLING: '/settings/billing',
  SETTINGS_SOCIAL: '/settings/social',
  COMMUNITY: '/community',
  POST: '/community/post',
  INBOX: '/community/inbox',
  POSTFLOW: '/postflow',
  CREATE: '/postflow/create',
  EDITOR: '/postflow/editor',
  SCHEDULE: '/postflow/schedule',
  /* PRICING: '/join-pricing', */
  ABOUT: '/about',
  SOCIAL_LOGIN_SUCCESS: '/social-login-success',
  SOCIAL_LOGIN_ERROR: '/social-login-error',
  PROMO_9: '/promocion-9',
  NOTIFICATIONS: '/notifications',
  ABOUTAPP: '/about-app',
  QUICKPOST: '/quickpost',
  CHAT: '#',
  SCHEDULE_MEETING: '/schedule-meeting',
  METRICS: '/metrics',
};

export const GeneralRoutes = {
  SUCESS_VERIFY_EMAIL: '/success-verify-email',
};

export const FONTS_ROUTES = [
  '/postflow',
  AuthRoutes.MEDIA_LIBRARY,
];

export const DefaultPublicRoute = PublicRoutes.LANDING;
export const DefaultAuthRoute = AuthRoutes.DASHBOARD;

export const ACADEMY_ROUTE = 'https://academy.socialpiper.com';
export const ACADEMY_ROUTE_LOGIN = 'https://academy.socialpiper.com/wp-login';

const routes = {
  ...PublicRoutes,
  ...AuthRoutes,
  ...GeneralRoutes,
  CALLBACK: '/callback',
  ACADEMY: '/academy',
};

export default routes;
