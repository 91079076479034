// TODO: Fix and improve this cleaning fiunction
const numberClean = (value) => {
  if (!Number.isInteger(value)) {
    return value.toFixed(2);
  }
  return value;
};

const sanitizePlans = (PromoCode, forPlanPayment, currentUserProfile, forPricingShowing) => {
  try {
    let monthlyPlan;
    let yearlyPlan;
    const monthlyPlans = PromoCode.plans
      .filter((plan) =>  {
        if (forPlanPayment) {
          return plan.isMonthly && plan.profileId === currentUserProfile;
        } else {
          return plan.isMonthly;
        }
      })
      .map((plan) => ({
        ...plan,
        monthlyPrice: numberClean(plan.monthlyPrice),
        realPrice: numberClean(plan.realPrice),
      }));
      monthlyPlan = forPricingShowing ? [...monthlyPlans] : monthlyPlans[0];
    const yearlyPlans = PromoCode.plans
      .filter((plan) => {
        if (forPlanPayment) {
          return !plan.isMonthly && plan.profileId === currentUserProfile;
        } else {
          return !plan.isMonthly;
        }
      })
      .map((plan) => ({
        ...plan,
        monthlyPrice: numberClean(plan.monthlyPrice),
        realPrice: numberClean(plan.realPrice),
      }));
      yearlyPlan = forPricingShowing ? [...yearlyPlans] : yearlyPlans[0];
    return forPricingShowing ? [monthlyPlan, yearlyPlan] : [{...monthlyPlan}, {...yearlyPlan}];
  } catch (error) {
    throw new Error("There's something wrong with the promo codes info retrieved");
  }
};

export default sanitizePlans;
