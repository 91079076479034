import { useEffect, useState } from 'react';

import { UserProvider } from '../../services/entities';
import useCompany from '../useCompany';

export default () => {
  const { companies, allSocialNetworks } = useCompany();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const getCollabUserPermissions = async () => {
      if (companies.length > 0) {
        await UserProvider.fetch(
          `permissions {
            companyId
            facebookPermission
            instagramPermission
            twitterPermission
            linkedinPermission
            hasPost
            hasBoost
            hasCommunity
          }`,
        ).then((res) => {
          const data = res.data.permissions;
          console.log('COLLAB_DATA: ', data);
          setPermissions(res.success ? data : null);
        });
      }
    };
    getCollabUserPermissions();
  }, [companies]);

  return {
    hasPost: permissions?.length ? permissions[0].hasPost : false,
    hasCommunity: permissions?.length ? permissions[0].hasCommunity : true,
    hasBoost: permissions?.length ? permissions[0].hasBoost : true,
    permissions: (permissions?.length ? permissions : []).map((perm) => {
      const company = companies.find((c) => c.id === perm.companyId);
      const networks = allSocialNetworks.find((n) => n.companyId === perm.companyId);

      return {
        companyName: company.companyName,
        facebook: {
          permission: perm.facebookPermission,
          appName: networks?.facebook.pageName || '',
        },
        twitter: {
          permission: perm.twitterPermission,
          appName: networks?.twitter.pageName || '',
        },
        instagram: {
          permission: perm.instagramPermission,
          appName: networks?.instagram.pageName || '',
        },
        linkedin: {
          permission: perm.linkedinPermission,
          appName: networks?.linkedin.pageName || '',
        },
        tiktok: null,
      };
    }),
  };
};
