import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export const CompanyContext = createContext({});

export const businessInitialState = {
  companyName: '',
  location: null,
  category: [],
  logoPreview: '',
  logo: '',
  logoName: '',
};

const CompanyProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(localStorage.getItem('local:companyId'));
  const [companies, setCompanies] = useState([]);
  const [businessInfo, setBusinessInfo] = useState(businessInitialState);
  const [extraInfo, setExtraInfo] = useState({});
  const [faqs, setFaqs] = useState({});

  const value = useMemo(
    () => ({
      companyId,
      setCompanyId,
      setCompanies,
      companies,
      businessInfo,
      setBusinessInfo,
      extraInfo,
      setExtraInfo,
      faqs,
      setFaqs,
    }),
    [
      companyId,
      setCompanyId,
      setCompanies,
      companies,
      businessInfo,
      setBusinessInfo,
      extraInfo,
      setExtraInfo,
      faqs,
      setFaqs,
    ],
  );

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

CompanyProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default CompanyProvider;
