import gql from 'graphql-tag';

export const updateCompany = gql`
  mutation updateCompany(
    $id: ID
    $companyName: String!
    $location: String
    $strategy: String
    $industryCategory: [String]
    $logoUrl: String
  ) {
    updateCompany(
      data: {
        id: $id
        companyName: $companyName
        location: $location
        strategy: $strategy
        industryCategory: $industryCategory
        logoUrl: $logoUrl
      }
    ) {
      id
    }
  }
`;

export const deleteCompanyMutation = gql`
  mutation deleteCompany($id: Int!) {
    deleteCompany(id: $id) {
      id
    }
  }
`;

export const createCompanyMutation = gql`
  mutation createCompany(
    $companyName: String!
    $location: String
    $companyDescription: String
    $clientsDescription: String
    $strategy: String
    $industryCategory: [String]
    $logoUrl: String
  ) {
    createCompany(
      data: {
        companyName: $companyName
        location: $location
        companyDescription: $companyDescription
        clientsDescription: $clientsDescription
        strategy: $strategy
        industryCategory: $industryCategory
        logoUrl: $logoUrl
      }
    ) {
      id
    }
  }
`;

export const updateAIResponseType = gql`
  mutation updateAIResponseType($type: AutoResponseTypes!) {
    updateAIResponseType(type: $type) {
      autoResponseType
    }
  }
`;

export const createOrUpdateFaq = gql`
  mutation createOrUpdateFaqs($faqs: [FaqsInput!]!, $companyId: ID) {
    createOrUpdateFaqs(faqs: $faqs, companyId: $companyId) {
      success
      message
      data {
        id
        answer
        question
        businessId
      }
    }
  }
`;

export const destroyFaq = gql`
  mutation destroyFaq($id: Int!) {
    destroyFaq(id: $id) {
      success
      message
      count
    }
  }
`;

export const updateExtraBusinessInfo = gql`
  mutation updateExtraBusinessInfo(
    $products: [String]!
    $openingHours: [OpeningHours]!
    $physicLocation: String
    $email: String
    $phone: String
    $companyId: Int
  ) {
    updateExtraBusinessInfo(
      data: {
        products: $products
        openingHours: $openingHours
        physicLocation: $physicLocation
        email: $email
        phone: $phone
        companyId: $companyId
      }
    ) {
      message
      success
    }
  }
`;

export default {};
